import React from 'react';
import { ArrowReturnRight, Bell, Cart, Heart, List, Person, Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { t } from 'i18next';
const StoreNavbar = ({ clientInfo, colors, groupHeaderData }) => {
  // const clientInfo = clientData
  const [isHovered, setIsHovered] = useState();
  const [isHoveredDropDown, setIsHoveredDropDown] = useState();
  const enableHeader = groupHeaderData?.length && groupHeaderData?.filter((x) => x.status === true);
  return (
    <div style={{ backgroundColor: colors?.header_bg_color }} className='relative header'>
      <div className='w-full px-4 md:px-0 md:w-[80%] mx-auto flex items-center justify-between h-full'>
        <div
          style={{ minWidth: `15%`, maxWidth: '15%' }}
          className='toggle block lg:hidden'
          // onClick={() => {
          //     setToggle(!toggle);
          //     setCategoryOpen(false);
          //     setPagesOpen(false);
          // }}
        >
          <button aria-label='list' className='text-[#222222]'>
            <List className='w-6 h-6' />
          </button>
        </div>
        <div className='overflow-hidden max-w-[100px] sm:max-w-[150px] max-h-full h-[80px] flex items-center'>
          {!clientInfo?.business_logo ? (
            <Link className='inline-block'>
              <p className='m-0 text-2xl block'>{clientInfo?.business_name}</p>
            </Link>
          ) : (
            <Link aria-label='logo'>
              <img
                width={150}
                height={80}
                style={{ maxWidth: '100%' }}
                className='max-w-full h-auto max-h-[75px] cursor-pointer'
                onError={(e) => {
                  e.target.src = NOPHOTO_IMAGE;
                }}
                src={clientInfo?.business_logo || NOPHOTO_IMAGE}
                alt='business_logo'
              />
            </Link>
          )}
        </div>
        <div
          style={{ minWidth: `55%`, maxWidth: '55%' }}
          className='lg:flex space-x-3 flex-wrap items-center justify-evenly hidden'
        >
          <div
            style={{
              color:
                isHovered === 'Home'
                  ? colors?.secondary_header_text_color
                  : colors?.primary_header_text_color,
            }}
            className='relative menu-hover flex cursor-pointer items-center transition-all duration-300 h-[90px] px-1'
            onMouseEnter={() => setIsHovered('Home')}
            onMouseLeave={() => setIsHovered('')}
          >
            <Link aria-label='home' className='text-sm font-normal h-[90px] flex items-center'>
              {t('lang_Home')}
            </Link>
            <div
              style={{ backgroundColor: colors?.secondary_header_text_color }}
              className={`absolute transition-all duration-300 bottom-0 h-0.5 ${
                isHovered === 'Home' ? 'w-full' : 'w-0'
              }`}
            ></div>
          </div>
          {enableHeader?.length > 0 ? (
            enableHeader?.map((elem) => {
              return (
                !elem?.notification &&
                !elem?.cart &&
                !elem?.wish_list && (
                  <div
                    style={{
                      color:
                        isHovered === elem?.header_section_title
                          ? colors?.secondary_header_text_color
                          : colors?.primary_header_text_color,
                    }}
                    className='relative menu-hover flex cursor-pointer items-center transition-all duration-300 h-[90px] px-1'
                    onMouseEnter={() => setIsHovered(elem?.header_section_title)}
                    onMouseLeave={() => setIsHovered('')}
                  >
                    <span className='block text-sm font-normal'>{elem?.header_section_title}</span>
                    <div
                      style={{ backgroundColor: colors?.secondary_header_text_color }}
                      className={`absolute transition-all duration-300 bottom-0 h-0.5 ${
                        isHovered === elem?.header_section_title ? 'w-full' : 'w-0'
                      }`}
                    ></div>
                    {elem?.header?.length > 0 && (
                      <div
                        style={{
                          width: `${208 * elem?.column}px`,
                          backgroundColor: colors?.header_bg_color,
                        }}
                        className={`menu-dropdown grid grid-cols-${elem?.column} max-w-screen p-1`}
                      >
                        {elem?.header?.map((x, index) => {
                          const sectionName = x?.parent_category?.split('>');
                          return x?.redirect_type === 'new' && x?.section_link?.length > 0 ? (
                            <a
                              className='block transition-all duration-300 capitalize p-1 w-100 font-normal text-sm list-none px-2 py-1.5 mb-1'
                              style={{
                                color:
                                  isHoveredDropDown === index
                                    ? colors?.secondary_header_text_color
                                    : colors?.primary_header_text_color,
                              }}
                              onMouseEnter={() => setIsHoveredDropDown(index)}
                              onMouseLeave={() => setIsHoveredDropDown('')}
                            >
                              <div className='flex justify-between w-full'>
                                {x.section_name}
                                {x?.product_counter ? (
                                  <span className='font-normal text-sm'>{`(${x.product_counter})`}</span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </a>
                          ) : (
                            <Link
                              aria-label='cat'
                              key={index}
                              className='block transition-all duration-300 capitalize p-1 w-100 font-normal text-sm list-none px-2 py-1.5 mb-1'
                              style={{
                                color:
                                  isHoveredDropDown === index
                                    ? colors?.secondary_header_text_color
                                    : colors?.primary_header_text_color,
                              }}
                              onMouseEnter={() => setIsHoveredDropDown(index)}
                              onMouseLeave={() => setIsHoveredDropDown('')}
                            >
                              <div className='flex justify-between w-full'>
                                <div>
                                  {x?.parent_category?.length ? (
                                    sectionName?.map((name, index) => {
                                      return (
                                        <p
                                          style={{ marginLeft: `${15 * index}px` }}
                                          className='flex items-center w-full'
                                        >
                                          {index > 0 && (
                                            <ArrowReturnRight className='w-3 h-3 me-1.5' />
                                          )}
                                          {name}
                                        </p>
                                      );
                                    })
                                  ) : (
                                    <span>{x?.section_name}</span>
                                  )}
                                </div>
                                {x?.product_counter ? (
                                  <span className='font-normal text-sm'>{`(${x.product_counter})`}</span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )
              );
            })
          ) : (
            <></>
          )}
          <div
            style={{
              color:
                isHovered === 'contact us'
                  ? colors?.secondary_header_text_color
                  : colors?.primary_header_text_color,
            }}
            className='relative menu-hover flex cursor-pointer items-center transition-all duration-300 h-[90px] px-1'
            onMouseEnter={() => setIsHovered('contact us')}
            onMouseLeave={() => setIsHovered('')}
          >
            <Link
              aria-label='contactus'
              className='text-sm font-normal  h-[90px] flex items-center'
            >
              {t('lang_Contact_Us')}
            </Link>
            <div
              style={{ backgroundColor: colors?.secondary_header_text_color }}
              className={`absolute transition-all duration-300 bottom-0 h-0.5 ${
                isHovered === 'contact us' ? 'w-full' : 'w-0'
              }`}
            ></div>
          </div>
        </div>
        <div className='block min-w-[35%] lg:min-w-[20%] max-w-[35%] lg:max-w-[20%]'>
          <ul className='flex list-none w-full justify-evenly items-center'>
            <li className='flex items-center'>
              <Search style={{ color: colors?.primary_header_text_color }} className='w-4 h-4' />
            </li>
            {enableHeader?.length && enableHeader?.find((x) => x?.notification === true) && (
              <li className='flex items-center'>
                <Bell style={{ color: colors?.primary_header_text_color }} className='w-4 h-4' />
              </li>
            )}
            {enableHeader?.length && enableHeader?.find((x) => x?.wish_list === true) && (
              <li className='flex items-center'>
                <Heart style={{ color: colors?.primary_header_text_color }} className='w-4 h-4' />
              </li>
            )}
            {enableHeader?.length && enableHeader?.find((x) => x?.cart === true) && (
              <li className='flex items-center'>
                <Cart style={{ color: colors?.primary_header_text_color }} className='w-4 h-4' />
              </li>
            )}
            {
              <li className='flex items-center'>
                <Person style={{ color: colors?.primary_header_text_color }} className='w-4 h-4' />
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StoreNavbar;
