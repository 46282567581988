import React from 'react';
import EditCartPageTableStyleSection from './EditCartPageTableStyleSection';

const EditCartPageTableStyleContainer = ({
  cartTableSectionDesign,
  setCartTableSectionDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditCartPageTableStyleSection
        setCartTableSectionDesign={setCartTableSectionDesign}
        cartTableSectionDesign={cartTableSectionDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditCartPageTableStyleContainer;
