import { Checkbox, Dialog, FormControlLabel, Popover } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  PlusCircle,
  Trash,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import { IconTab1, IconTab2, IconTab3 } from '../../Assets/StaticData/ReassuranceIcon';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import ReactDragListView from 'react-drag-listview';

const EditReassuranceGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const params = useParams();
  const navigate = useNavigate();
  const [dataErr, setDataErr] = useState(false);
  const [groupTitle, setGroupTitle] = useState('');
  const [storeReassurance, setStoreReassurance] = useState([]);
  const [addReassurance, setAddReassurance] = useState(false);
  const [image, setimage] = useState();
  const [groupStatus, setGroupStatus] = useState(true);
  const [reassuranceStatus, setReassuranceStatus] = useState(true);
  const [reassuranceData, setReassuranceData] = useState({
    reassurance_title: '',
    reassurance_description: '',
    reassurance_redirect_link: '',
  });
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const [iconDD, setIconDD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [EditId, setEditId] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [editReassuranceId, setEditReassuranceId] = useState(null);

  const getPerticulerGroupData = async () => {
    setDataLoading(true);
    try {
      const apicall = await API.get(`/admin/reassurance/getReassuranceGroup/${params?.groupId}`);
      const result = decryptData(apicall?.data);
      setGroupTitle(result?.data?.reassurance_title);
      setGroupStatus(result?.data?.status);
      setStoreReassurance(result?.data?.reassurances);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getPerticulerGroupData();
  }, []);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const handleAddReassurance = async (e) => {
    e.preventDefault();

    if (isEdit) {
      try {
        const payload = encryptData({
          ...reassuranceData,
          reassurance_img: image,
          reassurance_status: reassuranceStatus,
        });
        await API.put(`/admin/reassurance/updateReassurance/${EditId}`, payload);
        setIsEdit(false);
        getPerticulerGroupData();
        setAddReassurance(false);
        setReassuranceData({
          reassurance_title: '',
          reassurance_description: '',
          reassurance_redirect_link: '',
        });
        setimage();
        setReassuranceStatus(true);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const payload = encryptData({
          reassurance_group_id: params?.groupId,
          reassurance: { ...reassuranceData, reassurance_img: image },
        });
        await API.post(`/admin/reassurance/addReassurance`, payload);
        getPerticulerGroupData();
        setAddReassurance(false);
        setReassuranceData({
          reassurance_title: '',
          reassurance_description: '',
          reassurance_redirect_link: '',
        });
        setimage();
        setReassuranceStatus(true);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditReassuranceGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        reassurance_title: groupTitle,
        status: groupStatus,
      });
      await API.put(`/admin/reassurance/updateReassuranceGroup/${params?.groupId}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/reassurance-group',
        title: 'edit reassurance group',
      });
      navigate('/module-setting/reassurance-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleEditReassurance = (e, elem) => {
    e.preventDefault();
    setAddReassurance(true);
    setIsEdit(true);
    setEditId(elem?._id);
    setReassuranceData({
      reassurance_title: elem?.reassurance_title,
      reassurance_description: elem?.reassurance_description,
      reassurance_redirect_link: elem?.reassurance_redirect_link,
    });
    setimage(elem?.reassurance_img);
    setReassuranceStatus(elem?.reassurance_status);
  };
  const UpdateReassuranceStatus = async (id, value) => {
    try {
      const payload = encryptData({
        reassurance_status: value,
      });
      const data = await API.put(`/admin/reassurance/updateReassurance/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getPerticulerGroupData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortReassurance = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/reassurance/updateReassurance/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(storeReassurance);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setStoreReassurance(items);
  //   sortReassurance(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...storeReassurance];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setStoreReassurance(newItems);
      sortReassurance(toIndex, editReassuranceId);
      setEditReassuranceId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeReassurance?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/reassurance/deleteReassurance`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Reassurance Removed SuccesFully!');
        setSeverity('success');
        getPerticulerGroupData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/reassurance-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit reassurance group',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/reassurance-group',
        title: 'edit reassurance group',
      });
    } else {
      navigate('/module-setting/reassurance-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Reassurance'
        subheading='Do you really want to delete this reassurance from this group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Reassurance_Group'), path: '/module-setting/reassurance-group' },
                {
                  name: t('lang_Edit_reassurance_group'),
                  path: '/module-setting/reassurance-group/edit-reassurance-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Edit_reassurance_group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>
                {t('lang_Reassurance_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm ${err ? 'border-red-500' : ''
                  }`}
                placeholder='Reassurance group title'
                value={groupTitle}
                onChange={(e) => {
                  setGroupTitle(e.target.value);
                  setErr(false);
                  setIsChange(true);
                }}
              />
              {err && groupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
              )}
            </div>
            <div className='border w-full main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-2 text-sm gap-2'>
              <p className='text-[13px] font-[500] table-text capitalize'>
                {t('lang_Reassurance_Group_Status')} -
              </p>
              <label className='relative flex ms-2 items-center cursor-pointer'>
                <FormControlLabel
                  checked={groupStatus}
                  control={<MuiSwitch />}
                  onChange={() => {
                    setGroupStatus(!groupStatus);
                    setIsChange(true);
                  }}
                />
              </label>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-end justify-between'>
              <div className='flex items-center justify-between w-full text-sm'>
                {storeReassurance?.length === 0 ? (
                  <div>
                    {t('lang_Add_reassurance_for_show_in_this_group')}
                    <span className='text-red-500 ms-1'>*</span>
                  </div>
                ) : (
                  <div className='flex items-center'>
                    {selectedRowsId && selectedRowsId?.length > 0 && (
                      <>
                        <span className='text-sm px-3'>
                          {selectedRowsId?.length} {t('lang_selected')}
                        </span>
                        |
                        <button
                          className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                          onClick={(e) => {
                            e.preventDefault();
                            setConfirmationPopup(true);
                          }}
                        >
                          <Trash2 className='w-3.5 h-3.5' />
                          <span>{t('lang_delete_selection')}</span>
                        </button>
                      </>
                    )}
                  </div>
                )}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setAddReassurance(true);
                  }}
                  className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                >
                  <PlusCircle className='w-4 me-2 h-4' />
                  {t('lang_Add_Reassurance')}
                </span>
              </div>
            </div>
            {dataLoading && !storeReassurance ? (
              <TableSkeleton />
            ) : storeReassurance?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='px-3 table-head-checkbox'>
                        <Checkbox
                          checked={selectedRowsId?.length === storeReassurance?.length}
                          indeterminate={
                            selectedRowsId?.length !== storeReassurance?.length &&
                            selectedRowsId?.length > 0
                          }
                          onChange={() => {
                            handleSelectRow('all');
                          }}
                        />
                      </td>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_Reassurance_Title')}</td>
                      <td className='min-w-[200px] max-w-[200px] px-3'>{t('lang_Redirect_Url')}</td>
                      <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                        {t('lang_status')}
                      </td>
                      <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <ReactDragListView {...dragProps}>
                      {storeReassurance?.map((row, index) => (
                        <tr
                          style={{
                            // boxShadow: snapshot.isDragging
                            //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                            //   : 'none',
                            background: 'white',
                          }}
                          className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                            } select-none flex w-full`}
                          key={index}
                          onDragStart={() => setEditReassuranceId(row?._id)}
                        >
                          <td className='px-3 table-body-checkbox'>
                            <Checkbox
                              checked={
                                selectedRowsId?.length > 0 &&
                                selectedRowsId?.find((x) => x === row?._id)
                              }
                              className='inline-block'
                              onChange={() => handleSelectRow(row?._id)}
                            />
                          </td>
                          <td className='table-image px-3 min-w-[80px]'>
                            <img
                              width={80}
                              height={50}
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={row.reassurance_img || NOPHOTO_IMAGE}
                              className='p-1 max-w-full object-contain max-h-full'
                              alt={row.reassurance_title}
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{
                                __html: row.reassurance_title || '-',
                              }}
                            ></span>
                          </td>
                          <td className='min-w-[200px] max-w-[200px] px-3'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{
                                __html: row.reassurance_redirect_link || '-',
                              }}
                            ></span>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            <label className='relative flex justify-end w-full'>
                              <FormControlLabel
                                checked={row?.reassurance_status}
                                control={<MuiSwitch />}
                                onChange={() => {
                                  UpdateReassuranceStatus(row?._id, !row.reassurance_status);
                                }}
                              />
                            </label>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            <div className='space-x-2 w-full text-right'>
                              <button onClick={(e) => handleEditReassurance(e, row)}>
                                <Edit className='w-[17px] h-[17px] text-black-500' />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConfirmationPopup(true);
                                  setSelectedRowsId([row?._id]);
                                }}
                              >
                                <Trash className='w-[17px] h-[17px] text-red-500' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </ReactDragListView>
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}

          </div>
        </form>
        <Dialog
          open={addReassurance}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setAddReassurance(false);
            setReassuranceData({
              reassurance_title: '',
              reassurance_description: '',
              reassurance_redirect_link: '',
            });
            setimage();
            setReassuranceStatus(true);
          }}
          aria-describedby='alert-dialog-slide-description'
        >
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='secondary-bg-color w-full md:w-[600px] max-w-[600px] lg:max-w-full border main-border-color  space-y-4 rounded-[6px] p-5'
          >
            <div className='flex text-primary border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl'>
                {isEdit ? 'Edit Reassurance' : 'Add Reassurance'}
              </h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddReassurance(false);
                  setReassuranceData({
                    reassurance_title: '',
                    reassurance_description: '',
                    reassurance_redirect_link: '',
                  });
                  setimage();
                  setReassuranceStatus(true);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor='image'>{t('lang_Reassurance_Image')}</label>
                <button
                  aria-describedby='iconDD'
                  variant='contained'
                  className='text-link text-sm hover:underline'
                  onClick={(e) => {
                    {
                      e.preventDefault();
                      setAnchorEl(e.currentTarget);
                      setIconDD(true);
                    }
                  }}
                >
                  {t('lang_Select_Icon')}
                </button>
              </div>
              <ImageUploader
                alreadyImage={image}
                handleSetImage={handleSetImage}
                folder='reassurance'
                format='image'
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Title')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_title}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                placeholder='e.g. Free Shipping World Wide'
                onChange={(e) =>
                  setReassuranceData({ ...reassuranceData, reassurance_title: e.target.value })
                }
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Description')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_description}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                placeholder='e.g. Free Shipping World Wide'
                onChange={(e) =>
                  setReassuranceData({
                    ...reassuranceData,
                    reassurance_description: e.target.value,
                  })
                }
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Redirect_Link')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_redirect_link}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                placeholder='Add link'
                onChange={(e) =>
                  setReassuranceData({
                    ...reassuranceData,
                    reassurance_redirect_link: e.target.value,
                  })
                }
              />
            </div>
            <button
              className='btn inline-block text-sm text-white'
              onClick={(e) => handleAddReassurance(e)}
            >
              {isEdit ? 'Edit Reassurance' : 'Add Reassurance'}
            </button>
          </form>
          {iconDD && (
            <Popover
              id={'iconDD'}
              open={iconDD}
              anchorEl={anchorEl}
              onClose={() => setIconDD(!iconDD)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className='h-auto w-96 max-w-screen secondary-bg-color  main-border-color  z-50 rounded-[4px]'>
                <ul
                  className='flex items-center mb-0 list-none overflow-x-auto flex-row'
                  role='tablist'
                >
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 1
                          ? ' border-b-2 border-b-[#28282B]'
                          : 'table-text bg-transparent border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle='tab'
                      href='#link1'
                      role='tablist'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab1?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 2
                          ? ' border-b-2 border-b-[#28282B]'
                          : 'table-text bg-transparent border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle='tab'
                      href='#link2'
                      role='tablist'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab2?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 3
                          ? ' border-b-2 border-b-[#28282B]'
                          : 'table-text bg-transparent border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle='tab'
                      href='#link3'
                      role='tablist'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab3?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                </ul>
                {openTab === 1 && (
                  <div className='grid grid-cols-3 mt-4 gap-5'>
                    {IconTab1?.icons?.map((elem) => {
                      return (
                        <div
                          className={`text-center cursor-pointer ${elem === image ? 'bg-gray-100 rounded-[6px]' : ''
                            }`}
                        >
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {openTab === 2 && (
                  <div className='grid grid-cols-3 mt-4 gap-5'>
                    {IconTab2?.icons?.map((elem) => {
                      return (
                        <div className='text-center cursor-pointer'>
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {openTab === 3 && (
                  <div className='grid grid-cols-3 mt-4 gap-5'>
                    {IconTab3?.icons?.map((elem) => {
                      return (
                        <div className='text-center cursor-pointer'>
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className='border-t p-5 text-center'>
                  <button
                    className='text-link'
                    onClick={(e) => {
                      setimage('');
                      setDataErr(false);
                      setIconDD(false);
                    }}
                  >
                    {t('lang_Select_None')}
                  </button>
                </div>
              </div>
            </Popover>
          )}
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-end lg:w-[70%] xl:w-[68%] mx-auto'>
            <div>
              {groupTitle?.length === 0 || !groupTitle || storeReassurance?.length === 0 ? (
                <button
                  type='button'
                  disabled
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_reassurance_group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[200px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleEditReassuranceGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_reassurance_group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReassuranceGroup;
