import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import { Checkbox } from '@mui/material';

const AddRole = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [severity, setSeverity] = useState();
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const initialPermissions = [
    {
      label: t('lang_All_Permissions'),
      value: 'all',
      subPermissions: [],
    },
    {
      label: t('lang_dashboard'),
      value: 'dashboard',
      subPermissions: [{ label: t('lang_dashboard'), value: 'dashboard' }],
    },
    {
      label: t('lang_products'),
      value: 'products',
      subPermissions: [
        { label: t('lang_Add_Products'), value: 'add_Product' },
        { label: t('lang_View_Products'), value: 'get_Product' },
        { label: t('lang_Edit_Products'), value: 'edit_Product' },
        { label: t('lang_Remove_Products'), value: 'delete_Product' },
        // ... other sub-permissions ...
      ],
    },
    {
      label: t('lang_category'),
      value: 'category',
      subPermissions: [
        { label: t('lang_add_category'), value: 'add_Category' },
        { label: t('lang_View_Category'), value: 'get_Category' },
        { label: t('lang_Edit_Category'), value: 'edit_Category' },
        { label: t('lang_Remove_Category'), value: 'delete_Category' },
        // ... other sub-permissions ...
      ],
    },
    {
      label: t('lang_Product_Variant_Group'),
      value: 'product_variant_group',
      subPermissions: [
        { label: t('lang_Add_Product_Variant_Group'), value: 'add_Product_Variant_Group' },
        { label: t('lang_View_Product_Variant_Group'), value: 'get_Product_Variant_Group' },
        { label: t('lang_Edit_Product_Variant_Group'), value: 'edit_Product_Variant_Group' },
        { label: t('lang_Remove_Product_Variant_Group'), value: 'delete_Product_Variant_Group' },
        // ... other sub-permissions ...
      ],
    },
    {
      label: t('lang_Discount_Based_On_Quantity'),
      value: 'discount_specific',
      subPermissions: [
        { label: t('lang_Add_Discount'), value: 'add_Discount' },
        { label: t('lang_View_Discount'), value: 'get_Discount' },
        { label: t('lang_Edit_Discount'), value: 'edit_Discount' },
        { label: t('lang_Remove_Discount'), value: 'delete_Discount' },
      ],
    },
    {
      label: t('lang_Manufacturer'),
      value: 'manufacturer',
      subPermissions: [
        { label: t('lang_Add_Manufacturer'), value: 'add_Manufacturer' },
        { label: t('lang_View_Manufacturer'), value: 'get_Manufacturer' },
        { label: t('lang_Edit_Manufacturer'), value: 'edit_Manufacturer' },
        { label: t('lang_Remove_Manufacturer'), value: 'delete_Manufacturer' },
      ],
    },
    {
      label: t('lang_orders'),
      value: 'orders',
      subPermissions: [
        { label: t('lang_view_orders'), value: 'get_Order' },
        { label: t('lang_Add_Order'), value: 'add_Order' },
        { label: t('lang_Update_Orders'), value: 'edit_Order' },
      ],
    },
    {
      label: t('lang_Blogs'),
      value: 'blogs',
      subPermissions: [
        { label: t('lang_Add_Blogs'), value: 'add_Blog' },
        { label: t('lang_View_Blogs'), value: 'get_Blog' },
        { label: t('lang_Edit_Blogs'), value: 'edit_Blog' },
        { label: t('lang_Remove_Blogs'), value: 'delete_Blog' },
      ],
    },
    {
      label: t('lang_staff'),
      value: 'staff',
      subPermissions: [
        { label: t('lang_Add_Staff'), value: 'add_Staff' },
        { label: t('lang_View_Staff'), value: 'get_Staff' },
        { label: t('lang_Edit_Staff'), value: 'edit_Staff' },
        { label: t('lang_Remove_Staff'), value: 'delete_Staff' },
      ],
    },
    {
      label: t('lang_pages'),
      value: 'pages',
      subPermissions: [
        { label: t('lang_Add_Pages'), value: 'add_Page' },
        { label: t('lang_View_Pages'), value: 'get_Page' },
        { label: t('lang_Edit_Pages'), value: 'edit_Page' },
        { label: t('lang_Remove_Pages'), value: 'delete_Page' },
      ],
    },
    {
      label: t('lang_FAQ'),
      value: 'faq',
      subPermissions: [
        { label: t('lang_Add_FAQ'), value: 'add_FAQ' },
        { label: t('lang_View_FAQ'), value: 'get_FAQ' },
        { label: t('lang_Edit_FAQ'), value: 'edit_FAQ' },
        { label: t('lang_Remove_FAQ'), value: 'delete_FAQ' },
      ],
    },
    {
      label: t('lang_gdpr'),
      value: 'gdpr',
      subPermissions: [
        { label: t('lang_Add_GDPR'), value: 'add_gdpr' },
        { label: t('lang_View_GDPR'), value: 'get_gdpr' },
        { label: t('lang_Edit_GDPR'), value: 'edit_gdpr' },
      ],
    },
    {
      label: t('lang_portfolio'),
      value: 'portfolio',
      subPermissions: [
        { label: t('lang_Add_Portfolio'), value: 'add_Portfolio' },
        { label: t('lang_View_Portfolio'), value: 'get_Portfolio' },
        { label: t('lang_Edit_Portfolio'), value: 'edit_Portfolio' },
        { label: t('lang_Remove_Portfolio'), value: 'delete_Portfolio' },
      ],
    },
    {
      label: t('lang_Reviews'),
      value: 'reviews',
      subPermissions: [
        { label: t('lang_Add_Product_Reviews'), value: 'add_Reviews' },
        { label: t('lang_View_Product_Reviews'), value: 'get_ProductReviews' },
        { label: t('lang_View_Reviews'), value: 'get_AllReviews' },
        { label: t('lang_Update_Reviews'), value: 'edit_UpdateReview' },
      ],
    },
    {
      label: t('lang_roles'),
      value: 'roles',
      subPermissions: [
        { label: t('lang_Add_Roles'), value: 'add_Role' },
        { label: t('lang_View_Roles'), value: 'get_Role' },
        { label: t('lang_Edit_Roles'), value: 'edit_Role' },
        { label: t('lang_Remove_Roles'), value: 'delete_Role' },
      ],
    },
    {
      label: t('lang_filter'),
      value: 'filter',
      subPermissions: [
        { label: t('lang_Add_Filter'), value: 'add_filter' },
        { label: t('lang_Edit_Filter'), value: 'edit_filter' },
        { label: t('lang_View_Filter'), value: 'view_filter' },
      ],
    },
    {
      label: t('lang_Extension'),
      value: 'extension',
      subPermissions: [
        { label: t('lang_Add_Extension'), value: 'add_extension' },
        { label: t('lang_View_Extension'), value: 'view_extension' },
        { label: t('lang_Edit_Extension'), value: 'edit_extension' },
        { label: t('lang_Remove_Extension'), value: 'delete_extension' },
      ],
    },
    {
      label: t('lang_Payment_Method'),
      value: 'payment_method',
      subPermissions: [{ label: t('lang_Access_Payment_Methods'), value: 'get_Payment' }],
    },
    {
      label: t('lang_Shipping_Method'),
      value: 'shipping_method',
      subPermissions: [{ label: t('lang_Access_Shipping_Methods'), value: 'get_ShippingMethod' }],
    },
    {
      label: t('lang_Module_Setting'),
      value: 'module_setting  ',
      subPermissions: [{ label: t('lang_Module_Setting'), value: 'module_setting  ' }],
    },
    {
      label: t('lang_Other'),
      value: 'other',
      subPermissions: [
        { label: t('lang_Chat'), value: 'get_Chat' },
        { label: t('lang_analytics'), value: 'get_Analytics' },
        { label: t('lang_Abandoned_Cart'), value: 'abandoned_cart' },
      ],
    },
  ];
  const [per, setPer] = useState(initialPermissions);
  useEffect(() => {
    const rolePermissions = per.flatMap(({ subPermissions }) =>
      subPermissions.map((sub) => sub.checked && sub.value).filter((x) => x),
    );
    setFormData({ ...FormData, permissions: rolePermissions });
  }, [per]);

  const handlePermissionChange = async (mainValue, subValue, checked) => {
    if (mainValue === 'all' && subValue === 'all') {
      // if (FormData.role_name === 'Manager') {
      //   setFormData({ ...FormData, role_name: '' });
      // }
      const updatedPermissions = per.map((permission) => ({
        ...permission,
        checked,
        subPermissions: permission.subPermissions.map((subPermission) => ({
          ...subPermission,
          checked,
        })),
      }));
      return setPer(updatedPermissions);
    }
    if (subValue === 'all' && mainValue !== 'all') {
      // if (FormData.role_name === 'Manager') {
      //   if (
      //     mainValue === 'dashboard' ||
      //     mainValue === 'product' ||
      //     (mainValue === 'category' && checked === false)
      //   ) {
      //     setFormData({ ...FormData, role_name: '' });
      //   } else if (
      //     mainValue !== 'dashboard' ||
      //     mainValue !== 'product' ||
      //     (mainValue !== 'category' && checked === true)
      //   ) {
      //     setFormData({ ...FormData, role_name: '' });
      //   }
      // }
      const updatedPermissions = per.map((permission) => {
        if (permission.value === mainValue) {
          return {
            ...permission,
            checked,
            subPermissions: permission.subPermissions.map((subPermission) => ({
              ...subPermission,
              checked,
            })),
          };
        }
        return permission;
      });
      return setPer(updatedPermissions);
    }

    // if (FormData.role_name === 'Manager') {
    //   per.map((permission) => {
    //     if (
    //       permission.value === 'dashbaord' ||
    //       permission.value === 'products' ||
    //       (permission.value === 'category' && permission.checked === false)
    //     ) {
    //       setFormData({ ...FormData, role_name: '' });
    //     }
    //   });
    // }

    const upPermissions = per.map((permission) => {
      if (permission.value === mainValue) {
        const subPermissionsChecked = permission.subPermissions.filter(
          (subPermission) => subPermission.checked,
        ).length;
        return {
          ...permission,
          checked: subPermissionsChecked === 1 && !checked ? false : true,
          subPermissions: permission.subPermissions.map((subPermission) => {
            if (subPermission.value === subValue) {
              return {
                ...subPermission,
                checked,
              };
            } else {
              return subPermission;
            }
          }),
        };
      }
      return permission;
    });

    return setPer(upPermissions);
  };

  const [activeTab, setActiveTab] = useState('');

  const handleTabClick = (role) => {
    setActiveTab(role);
    setFormData((prevData) => ({
      ...prevData,
      role_name: role,
    }));
  };

  const [FormData, setFormData] = useState({
    role_name: '',
    description: '',
    permissions: [],
  });
  let { role_name, description, permissions } = FormData;
  const [btnLoading, setBtnLoading] = useState(false);
  // Add Role
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({ name: role_name, description, permissions })
      await API.post(`/admin/role/addRole`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/roles',
        title: 'add role',
      });
      navigate('/roles');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  let lengthMatch =
    initialPermissions?.map((x) => x.subPermissions).flat().length === permissions?.length;
  useEffect(() => {
    if (permissions?.length > 0 || description?.length > 0 || role_name?.length > 0) {
      setSaveChanges({
        backLink: '/roles',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add role',
      });
    } else {
      setSaveChanges({
        backLink: '/roles',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add role',
      });
    }
  }, [permissions, description, role_name]);
  useEffect(() => {
    if (FormData.role_name === 'Sales') {
      const masterPermissions = initialPermissions.map((permission) => {
        if (
          permission.value === 'dashboard' ||
          permission.value === 'products' ||
          permission.value === 'category'
        ) {
          return {
            ...permission,
            checked: true,
            subPermissions: permission?.subPermissions?.map((subPermission) => ({
              ...subPermission,
              checked: true,
            })),
          };
        }
        return permission;
      });
      setPer(masterPermissions);
    }
    if (FormData.role_name === 'Supporter') {
      const masterPermissions = initialPermissions.map((permission) => {
        if (
          permission.value === 'dashboard' ||
          permission.value === 'Manufacturer' ||
          permission.value === 'Orders'
        ) {
          return {
            ...permission,
            checked: true,
            subPermissions: permission?.subPermissions?.map((subPermission) => ({
              ...subPermission,
              checked: true,
            })),
          };
        }
        return permission;
      });
      setPer(masterPermissions);
    }
  }, [FormData.role_name]);
  const checkChanges = () => {
    if (permissions?.length > 0 || description?.length > 0 || role_name?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/roles',
        title: 'add role',
      });
    } else {
      navigate('/roles');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_roles'), path: '/roles' },
                { name: t('lang_Add_New_Role'), path: '/roles/add-role' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Role')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color border main-border-color  rounded-xl p-4 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='role_name' className='capitalize'>
                  {t('lang_name')}<span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border ${err && FormData.role_name === '' ? 'border-red-500' : 'main-border-color'
                    }  rounded-[10px] focus:outline-none`}
                  type='text'
                  id='role_name'
                  name='role_name'
                  value={FormData.role_name}
                  onChange={(e) => setFormData({ ...FormData, role_name: e.target.value })}
                  placeholder='e.g. Manager'
                  required
                />
                {err && FormData.role_name === '' && (
                  <p className='text-xs text-red-500'>{t('lang_Role_Name_Is_Required')}</p>
                )}
              </div>
              <div className='mb-4'>
                <label htmlFor='description' className='capitalize'>
                  {t('lang_description')}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='text'
                  id='description'
                  name='description'
                  onChange={(e) => setFormData({ ...FormData, description: e.target.value })}
                  placeholder='e.g. Manager can access all the store'
                  required
                />
              </div>
              <div>
                <label htmlFor='role_name'>
                  {t('lang_Permissions')}<span className='text-red-500 ms-1'>*</span>
                </label>
                <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[10px] p-1">
                  <a
                    className={`tab ${(activeTab === "Manager" ? 'focus-tab' : 'bg-transparent')}`}
                    data-toggle='tab'
                    href='#link1'
                    onClick={() => { handleTabClick('Manager'); handlePermissionChange("all", 'all', true) }}
                  >
                    {t('lang_Manager')}
                  </a>
                  <a
                    className={`tab ${(activeTab === "Sales" ? 'focus-tab' : 'bg-transparent')}`}
                    data-toggle='tab'
                    href='#link2'
                    onClick={() => handleTabClick('Sales')}
                  >
                    Sales
                  </a>
                  <a
                    className={`tab ${(activeTab === "Supporter" ? 'focus-tab' : 'bg-transparent')}`}
                    data-toggle='tab'
                    href='#link3'
                    onClick={() => handleTabClick('Supporter')}
                  >
                    Supporter
                  </a>
                </div>
                {per.map((permission) => {
                  return (
                    <div
                      className='my-2 primary-bg-color bg-opacity-50 rounded-xl p-3'
                      key={permission?.value}
                    >
                      <label className='flex items-center cursor-pointer text-sm font-normal select-none'>
                        <Checkbox
                          id='vehicle1'
                          name='vehicle'
                          checked={
                            (permission?.subPermissions?.length &&
                              permission?.subPermissions?.every((x) => x.checked)) ||
                            (permission?.value === 'all' && permission.checked) ||
                            lengthMatch
                          }
                          onChange={(e) =>
                            handlePermissionChange(permission?.value, 'all', e.target.checked)
                          }
                          className='w-[12px] h-[12px] ml-5 mt-1.5'
                        />
                        <span className='ml-2 text-base'>{permission?.label}</span>
                      </label>
                      {permission.subPermissions.length > 0 &&
                        permission.subPermissions.map((subPermission) => (
                          <div key={subPermission.value} style={{ marginLeft: '20px' }}>
                            <label className='flex items-center cursor-pointer text-sm font-normal select-none gap-1'>
                              <Checkbox
                                id='vehicle1'
                                name='vehicle'
                                checked={subPermission?.checked ?? false}
                                onChange={(e) =>
                                  handlePermissionChange(
                                    permission?.value,
                                    subPermission?.value,
                                    e.target.checked,
                                  )
                                }
                                className='w-[12px] h-[12px] ml-5 mt-1.5'
                              />
                              {subPermission?.label}
                            </label>
                          </div>
                        ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-full flex items-center justify-between lg:w-[70%] xl:w-[50%] mx-auto'>
          <Link
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_To_Roles')}
          </Link>
          <div>
            {role_name === '' || permissions?.length === 0 ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_New_Role')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[134px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_New_Role')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRole;