import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const EditProductDescription = ({
  productPageData,
  handleEditProductPageData,
  handleUpdateSetting,
}) => {
  return (
    <div className='grid grid-cols-1 gap-5 w-full mb-4'>
      <label className='select-none cursor-pointer items-start text-sm table-text flex'>
        <Checkbox
          value={productPageData?.showProductDescription}
          checked={productPageData?.showProductDescription}
          onChange={() =>
            handleEditProductPageData({
              ...productPageData,
              showProductDescription: Boolean(!productPageData?.showProductDescription),
            })
          }
        />
        <div className='mt-2'>
          <span className='font-medium'>{t('lang_Show_Product_Description')}</span>
          <p className='text-xs table-text'>{t('lang_Show_Product_Description_Details')}</p>
        </div>
      </label>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          onClick={handleUpdateSetting}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductDescription;
