import React from "react";
import SvgSection from "../../controllers/SvgSection";
import { mapPositionToCSS } from "../../controllers/DynamicEditorSwitchCase";

const ButtonSection = ({ obj }) => {
  return (
    <>
      <div
        key={obj?.id}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          display: "flex",
          justifyContent:
            obj?.style?.font_align === "center"
              ? "center"
              : obj?.style?.font_align === "right"
              ? "flex-end"
              : obj?.style?.font_align === "left"
              ? "flex-start"
              : obj?.style?.font_align === "justify"
              ? "normal"
              : "",
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
          background:
            obj?.style?.background_gradient === "gradient"
              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
              : obj?.style?.background_image
              ? `url(${obj?.style?.background_image})`
              : obj?.style?.background,
          backgroundRepeat: obj?.style?.background_repeat,
          backgroundAttachment: obj?.style?.background_attachment,
          objectFit: obj?.style?.background_fit,
          backgroundSize:
            obj?.style?.background_fit === "cover" ||
            obj?.style?.background_fit === "contain"
              ? obj?.style?.background_fit
              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
        }}
      >
        <button
          className="p-3 rounded-lg text-white flex"
          style={{
            paddingTop: `${obj?.style?.button_padding_top}px`,
            paddingBottom: `${obj?.style?.button_padding_bottom}px`,
            paddingLeft: `${obj?.style?.button_padding_left}px`,
            paddingRight: `${obj?.style?.button_padding_right}px`,
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            borderTop: `${obj?.style?.button_border_top}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
            borderBottom: `${obj?.style?.button_border_bottom}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
            borderLeft: `${obj?.style?.button_border_left}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
            borderRight: `${obj?.style?.button_border_right}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
            borderTopLeftRadius: `${obj?.style?.button_border_radius_top}px`,
            borderTopRightRadius: `${obj?.style?.button_border_radius_right}px`,
            borderBottomLeftRadius: `${obj?.style?.button_border_radius_left}px`,
            borderBottomRightRadius: `${obj?.style?.button_border_radius_bottom}px`,
            background:
              obj?.style?.button_background_gradient === "button_gradient"
                ? `linear-gradient(${obj?.style?.button_gradient_angle}deg, ${obj?.style?.button_gradient_color_start} ${obj?.style?.button_gradient_start_position}%, ${obj?.style?.button_gradient_color_end} ${obj?.style?.button_gradient_end_position}%)`
                : obj?.style?.button_background_image
                ? `url(${obj?.style?.button_background_image})`
                : obj?.style?.button_background,
            boxShadow: `${obj?.style?.button_box_shadow_horizontal}px ${obj?.style?.button_box_shadow_vertical}px ${obj?.style?.button_box_shadow_blur}px ${obj?.style?.button_box_shadow_spread}px ${obj?.style?.button_box_shadow_color}`,
          }}
        >
          {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
            React.cloneElement(obj?.content?.icon, {
              width: "24px",
              height: "24px",
            })
          ) : (
            <SvgSection
              svgContent={obj?.content?.icon}
              width="24px"
              height="24px"
              fill={obj?.style?.font_color ? obj?.style?.font_color : "#ffffff"}
              stroke={
                obj?.style?.font_color ? obj?.style?.font_color : "#ffffff"
              }
            />
          )}
          {obj?.content?.text}
        </button>
      </div>
    </>
  );
};

export default ButtonSection;
