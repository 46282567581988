import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from 'react-bootstrap-icons';
function Social({ opentab }) {
  const [links, setLinks] = useState({
    instagram: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    youtube: '',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const HandleClientInfo = async () => {
    try {
      let ApiCall = await API.get(`/admin/client/getClient`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        setLinks({
          instagram: result?.data?.social_media?.instagram,
          facebook: result?.data?.social_media?.facebook,
          twitter: result?.data?.social_media?.twitter,
          linkedin: result?.data?.social_media?.linkedin,
          youtube: result?.data?.social_media?.youtube,
        });
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    HandleClientInfo();
  }, [opentab]);

  const HandleInput = (e) => {
    setLinks({ ...links, [e.target.name]: e.target.value });
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      // links.facebook = !links?.facebook.startsWith('https://') ? `https://${links.facebook}` : links.facebook
      // links.instagram = !links?.instagram.startsWith('https://') ? `https://${links.instagram}` : links.instagram
      // links.youtube = !links?.youtube.startsWith('https://') ? `https://${links.youtube}` : links.youtube
      // links.twitter = !links?.twitter.startsWith('https://') ? `https://${links.twitter}` : links.twitter
      // links.linkedin = !links?.linkedin.startsWith('https://') ? `https://${links.linkedin}` : links.linkedin
      const payload = encryptData({
        clientInfo: {
          social_media: {
            facebook:
              !links?.facebook.startsWith('https://') && links?.facebook?.length > 0
                ? `https://${links.facebook}`
                : links.facebook,
            instagram:
              !links?.instagram.startsWith('https://') && links?.instagram?.length > 0
                ? `https://${links.instagram}`
                : links.instagram,
            youtube:
              !links?.youtube.startsWith('https://') && links?.youtube?.length > 0
                ? `https://${links.youtube}`
                : links.youtube,
            twitter:
              !links?.twitter.startsWith('https://') && links?.twitter?.length > 0
                ? `https://${links.twitter}`
                : links.twitter,
            linkedin:
              !links?.linkedin.startsWith('https://') && links?.linkedin?.length > 0
                ? `https://${links.linkedin}`
                : links.linkedin,
          },
        },
      });
      let ApiCall = await API.put('/admin/client/updateClient', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Social link added succesfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
        <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
          <div className='w-full'>
            <div className='text-base font-medium text-primary'>
              {t('lang_Social_Links')}
              <p className='text-xs table-text font-normal'>
                {t('lang_Manage_social_link_for_view_in_store')}
              </p>
            </div>
          </div>
          <>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='w-full mx-auto pb-4 sm:pb-[25px] rounded-[6px]'
            >
              <div className='mb-4'>
                <label htmlFor='instagram' className='flex items-center gap-1'>
                 <Instagram /> {t('lang_Instagram_link')
                }</label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='instagram'
                  name='instagram'
                  value={links.instagram}
                  onChange={HandleInput}
                  placeholder='instagram account link..'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='facebook' className='flex items-center gap-1'>
                <Facebook />  {t('lang_Facebook_link')}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='businessname'
                  name='facebook'
                  value={links.facebook}
                  onChange={HandleInput}
                  placeholder='facebook account link..'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='twitter' className='flex items-center gap-1'>
                <Twitter />  {t('lang_Twitter_link')}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='twitter'
                  name='twitter'
                  value={links.twitter}
                  onChange={HandleInput}
                  placeholder='twitter account link..'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='linkedIn' className='flex items-center gap-1'>
                <Linkedin />  {t('lang_LinkedIn_link')}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='linkedIn'
                  name='linkedin'
                  value={links.linkedin}
                  onChange={HandleInput}
                  placeholder='linkedIn account link..'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='youTube' className='flex items-center gap-1'>
                <Youtube />  {t('lang_YouTube_link')}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='youTube'
                  name='youtube'
                  value={links.youtube}
                  onChange={HandleInput}
                  placeholder='youTube account link..'
                />
              </div>
              <div>
                {btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[144px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <div className='w-full flex justify-end'>
                    <button
                      onClick={(e) => HandleSubmit(e)}
                      className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                    >
                      {t('lang_Apply_Changes')}
                    </button>
                  </div>
                )}
              </div>
            </form>
          </>
        </div>
    </>
  );
}

export default Social;
