import { t } from 'i18next';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
function UpdateGdpr() {
  const location = useLocation();
  const data = location.state.allGdpr.data;
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [message, setmessage] = useState({
    newslatter: data.newsletter && data.newsletter.message,
    contactus: data.contactus && data.contactus.message,
  });

  const HandleChange = (e) => {
    setmessage({ ...message, [e.target.name]: e.target.value });
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        newsletter: {
          message: message.newslatter ? message.newslatter : '',
          status: data?.newsletter?.status,
        },
        contactus: {
          message: message.contactus ? message.contactus : '',
          status: data?.contactus?.status,
        },
      })
      let ApiCall = await API.put(`/admin/gdpr/updateGdpr/${data._id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        navigate('/gdpr');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='flex items-end justify-between secondary-bg-color absolute top-[80px] z-40 w-full overflow-auto pb-2'>
        <h4 className='text-xl md:text-2xl font-semibold ps-3 text-primary text-center'>
          {t('lang_Update_GDPR')}
        </h4>
      </div>
      <div className='pt-[128px] overflow-x-hidden primary-bg-color h-full w-full'>
        <div className='p-4'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='data-form w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='mb-4'>
              <label htmlFor='description'>{t('lang_newsletter')}</label>
              <textarea
                className='w-full h-20 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='newslatter'
                name='newslatter'
                value={message.newslatter}
                onChange={(e) => HandleChange(e)}
                placeholder='Type your message'
                rows='4'
                cols='50'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='description'>{t('lang_Contact_Us')}</label>
              <textarea
                className='w-full h-20 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='contactus'
                name='contactus'
                value={message.contactus}
                onChange={(e) => HandleChange(e)}
                placeholder='Type your message'
                rows='4'
                cols='50'
              />
            </div>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
            <button
              className='w-full mt-4 block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              type='submit'
              onClick={HandleSubmit}
            >
              {t('lang_Update_GDPR')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateGdpr;
