import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { SelectSection } from '../../../App';
const Manufacture = ({ colors, brandData, manufactureDesign, section_type }) => {
  const limitSlidesPerView =
    brandData?.section?.brand_limit < brandData?.section?.brands?.length
      ? brandData?.section?.brand_limit
      : brandData?.section?.brands?.length;
  const [isHovered, setIsHovered] = useState(false);
  const [setting, setSetting] = useState();
  const { selectSectionName, setSelectSectionName } = useContext(SelectSection);
  useEffect(() => {
    setSetting({
      dots: false,
      infinite: Number(limitSlidesPerView) > 1 ? brandData?.section?.loop_forever : false,
      autoplay: brandData?.section?.auto_play,
      autoplaySpeed: Number(brandData?.section?.carousel_speed_ms),
      slidesToShow:
        window?.innerWidth > 1200
          ? Number(limitSlidesPerView)
          : window?.innerWidth > 991 && window?.innerWidth <= 1200
          ? Number(limitSlidesPerView - 1)
          : window?.innerWidth > 768 && window?.innerWidth <= 991
          ? Number(limitSlidesPerView - 2)
          : window?.innerWidth > 575 && window?.innerWidth <= 768
          ? 2
          : window?.innerWidth > 100 && window?.innerWidth <= 640
          ? 1
          : 1,
      slidesToScroll: 1,
      pauseOnHover: brandData?.section?.pause_on_hover,
      prevArrow: (
        <div className='slick-prev'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={12}
            height={20}
            viewBox='0 0 12 20'
            id='left'
          >
            <g fill='none' fillRule='evenodd'>
              <g
                fill={`${colors?.primary_button_color}`}
                fillRule='nonzero'
                transform='translate(-14 -8)'
              >
                <g transform='translate(14 8)'>
                  <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                </g>
              </g>
            </g>
          </svg>
        </div>
      ),
      nextArrow: (
        <div className='slick-next'>
          <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
            <path
              fill={`${colors?.primary_button_color}`}
              d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
            />
          </svg>
        </div>
      ),
    });
  }, [brandData, limitSlidesPerView, window?.innerWidth]);

  // const [selectedBrand, setSelectedBrand] = useState(null);

  const handleImageClick = (brandSlugName) => {
    // setSelectedBrand(brandSlugName);
    setSelectSectionName(brandSlugName);
  };

  const [primaryColor, setPrimaryColor] = useState('blue');

  console.log(brandData?.section?.brand_section_title,"brandData=-=-=-=->>");


  return (
    brandData &&
    brandData?.section?.brands &&
    brandData?.status && (
      <div
        className={`${manufactureDesign?.border_width > 1 ? 'border' : ''}`}
        style={{
          display: manufactureDesign?.display,
          flexDirection: manufactureDesign?.direction,
          alignItems: manufactureDesign?.align_item,
          gap: manufactureDesign?.gap,
          marginTop: `${manufactureDesign?.margin_top}px`,
          marginBottom: `${manufactureDesign?.margin_bottom}px`,
          marginLeft: `${manufactureDesign?.margin_left}px`,
          marginRight: `${manufactureDesign?.margin_right}px`,
          paddingTop: `${manufactureDesign?.padding_top}px`,
          paddingBottom: `${manufactureDesign?.padding_bottom}px`,
          paddingLeft: `${manufactureDesign?.padding_left}px`,
          paddingRight: `${manufactureDesign?.padding_right}px`,
          height: `${manufactureDesign?.height}${manufactureDesign?.height_unit}`,
          width: `${manufactureDesign?.width}${manufactureDesign?.width_unit}`,
          borderStyle: manufactureDesign?.border_style,
          borderWidth: `${manufactureDesign?.border_width}px`,
          borderColor: manufactureDesign?.border_color,
          float: manufactureDesign?.float,
          maxHeight: `${manufactureDesign?.max_height}${manufactureDesign?.max_height_unit}`,
          maxWidth: `${manufactureDesign?.max_width}${manufactureDesign?.max_width_unit}`,
          minHeight: `${manufactureDesign?.min_height}${manufactureDesign?.min_height_unit}`,
          minWidth: `${manufactureDesign?.min_width}${manufactureDesign?.min_width_unit}`,
        }}
      >
        {brandData?.section?.brand_title_display && (
          <>
            <h3 className='w-full lg:w-[50%] mx-auto font-medium text-center mb-[20px] break-words overflow-hidden'>
              {/* <span
                style={{
                  fontSize: `${manufactureDesign?.font_size}px`,
                }}
              > */}
              {brandData?.section?.brand_section_title}
              {/* </span> */}
            </h3>
          </>
        )}

        <div style={{ width: '90%', display: 'block', margin: '0 auto' }}>
          {brandData?.section?.carousel_status ? (
            <Slider {...setting} className='w-full'>
              {brandData?.section?.brands?.map((elem, index) => {
                return (
                  <div key={index} className='text-center'>
                    <Link
                      aria-label={`/brand-${elem?.brandData?.brand_slug_name}`}
                      className={`flex items-center justify-center mx-auto cursor-pointer`}
                      style={{
                        width: brandData?.section?.brand_image_width,
                        height: brandData?.section?.brand_image_height,
                      }}
                    >
                      <img
                        width={100}
                        height={80}
                        onError={(e) => {
                          e.target.src = noPhoto;
                        }}
                        src={elem?.brandData?.brand_logo || noPhoto}
                        alt='brand Logo'
                        style={{
                          width: brandData?.section?.brand_image_width,
                          maxHeight: brandData?.section?.brand_image_height,
                          objectFit: manufactureDesign?.fit,
                          // border:
                          //   selectSectionName === elem?.brandData?.brand_slug_name
                          //     ? '2px solid #2c6ecb'
                          //     : 'none',
                        }}
                        onClick={() => handleImageClick(elem?.brandData?.brand_slug_name)}
                        className={`inline-block mx-auto cursor-pointer`}
                      />
                    </Link>
                    {brandData?.section?.brand_name_display ? (
                      <div
                        // style={{ color: colors?.primary_body_text_color }}
                        style={{
                          color: colors?.main_title_color,
                          fontSize: `${manufactureDesign?.font_size}px`,
                          lineHeight: `${manufactureDesign?.font_height}px`,
                          fontWeight: manufactureDesign?.font_weight,
                          fontStyle: manufactureDesign?.font_style,
                          color: manufactureDesign?.font_color,
                          textAlign: manufactureDesign?.font_align,
                          textDecoration: manufactureDesign?.font_decoration,
                          textTransform: manufactureDesign?.font_capitalize,
                          wordBreak: manufactureDesign?.font_break,
                          textWrap: manufactureDesign?.font_wrap,
                        }}
                        // className='mt-3 text-[18px] capitalize sm:text-[20px] text-center cursor-pointer font-[400] transition-all line-clamp-2 duration-300'
                      >
                        {elem?.brandData?.brand_name}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div
              className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-${limitSlidesPerView} gap-5 w-full`}
              style={{
                display: manufactureDesign?.display,
                gap: `${manufactureDesign?.gap}px`,
              }}
            >
              {brandData?.section?.brands?.map((elem, index) => {
                return (
                  <div key={index} className='text-center w-full'>
                    <div
                      style={{ height: brandData?.section?.brand_image_height }}
                      className='flex items-center justify-center'
                    >
                      <img
                        width={100}
                        height={80}
                        onError={(e) => {
                          e.target.src = noPhoto;
                        }}
                        src={elem?.brandData?.brand_logo || noPhoto}
                        alt='brand Logo'
                        style={{
                          width: brandData?.section?.brand_image_width,
                          maxHeight: brandData?.section?.brand_image_height,
                          objectFit: manufactureDesign?.fit,
                          border:
                            selectSectionName === elem?.brandData?.brand_slug_name
                              ? '2px solid #2c6ecb'
                              : 'none',
                        }}
                        onClick={() => handleImageClick(elem?.brandData?.brand_slug_name)}
                        className={`inline-block mx-auto cursor-pointer`}
                      />
                    </div>
                    {brandData?.section?.brand_name_display ? (
                      <div
                        // style={{ color: colors?.primary_body_text_color }}
                        style={{
                          color: colors?.main_title_color,
                          fontSize: `${manufactureDesign?.font_size}px`,
                          lineHeight: `${manufactureDesign?.font_height}px`,
                          fontWeight: manufactureDesign?.font_weight,
                          fontStyle: manufactureDesign?.font_style,
                          color: manufactureDesign?.font_color,
                          textAlign: manufactureDesign?.font_align,
                          textDecoration: manufactureDesign?.font_decoration,
                          textTransform: manufactureDesign?.font_capitalize,
                          wordBreak: manufactureDesign?.font_break,
                          textWrap: manufactureDesign?.font_wrap,
                        }}
                        // className='mt-3 text-[18px] capitalize sm:text-[20px] text-center cursor-pointer font-[400] transition-all line-clamp-2 duration-300'
                      >
                        {elem?.brandData?.brand_name}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {brandData?.section?.button_text?.length ? (
            <div className='text-center mt-[25px]'>
              <Link
                aria-label='manufacturers'
                style={{
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                  borderRadius: colors?.button_redius,
                  backgroundColor: isHovered
                    ? colors?.secondary_button_color || ''
                    : colors?.primary_button_color,
                  color: isHovered
                    ? colors?.secondary_button_text_color || ''
                    : colors?.primary_button_text_color,
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`uppercase text-sm inline-block px-9 py-3`}
              >
                {brandData?.section?.button_text}
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  );
};

export default Manufacture;
