import React, { useState } from 'react';
import ElementorData from './ElementorData';
import ElementorComponment from './ElementorComponment';

const AddElementsStyleContainer = ({
  handleAddSection,
  activeIndex,
  dragItems,
  saveElementorData,
  btnLoading,
  handleFlexBoxClick,
  openAddSection,
  setGridValue,
  setOpenTab,
  setClickedItem,
  handleItemClick
}) => {
  const handleDragStart = (item) => (event) => {
    event.dataTransfer.setData('basic-item', JSON.stringify(item));
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...items];
      //   const item = newItems.splice(fromIndex, 1)[0];
      //   newItems.splice(toIndex, 0, item);
      onDrop(newItems);
    },
    nodeSelector: 'div',
    handleSelector: 'p',
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleKeyDown = (event, index) => {
    if (event.key === 'ArrowRight') {
      setActiveTab((prev) => (prev + 1) % 2); // Loop between tabs
    } else if (event.key === 'ArrowLeft') {
      setActiveTab((prev) => (prev === 0 ? 1 : prev - 1)); // Loop backward between tabs
    }
  };

  return (
    <>
      <div className='flex border-b border-gray-200' role='tablist'>
        <button
          role='tab'
          aria-selected={activeTab === 0}
          aria-controls='tab-panel-0'
          id='tab-0'
          tabIndex={activeTab === 0 ? 0 : -1}
          className={`px-4 py-2 transition-colors duration-300 focus:outline-none text-[12px] ${
            activeTab === 0
              ? 'border-b-2 border-blue-400 text-blue-600 bg-blue-50'
              : 'text-gray-500 hover:text-blue-600 hover:bg-gray-100 text-[12px]'
          }`}
          onClick={() => setActiveTab(0)}
          onKeyDown={(e) => handleKeyDown(e, 0)}
        >
          Elementor
        </button>
        <button
          role='tab'
          aria-selected={activeTab === 1}
          aria-controls='tab-panel-1'
          id='tab-1'
          tabIndex={activeTab === 1 ? 0 : -1}
          className={`px-4 py-2 transition-colors duration-300 focus:outline-none text-[12px] ${
            activeTab === 1
              ? 'border-b-2 border-blue-400 text-blue-600 bg-blue-50'
              : 'text-gray-500 hover:text-blue-600 hover:bg-gray-100 text-[12px]'
          }`}
          onClick={() => setActiveTab(1)}
          onKeyDown={(e) => handleKeyDown(e, 1)}
        >
          Component
        </button>
      </div>

      {activeTab === 0 ? (
        <ElementorData
          handleAddSection={handleAddSection}
          activeIndex={activeIndex}
          dragItems={dragItems}
          saveElementorData={saveElementorData}
          btnLoading={btnLoading}
          handleDragStart={handleDragStart}
          dragProps={dragProps}
        />
      ) : (
        <ElementorComponment
          activeIndex={activeIndex}
          dragProps={dragProps}
          handleFlexBoxClick={handleFlexBoxClick}
          handleAddSection={handleAddSection}
          openAddSection={openAddSection}
          setGridValue={setGridValue}
          setOpenTab={setOpenTab}
          setClickedItem={setClickedItem}
          handleItemClick={handleItemClick}
          handleDragStart={handleDragStart}
        />
      )}
    </>
  );
};

export default AddElementsStyleContainer;
