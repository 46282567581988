import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, Trash, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import ReactDragListView from 'react-drag-listview';

const EditProductGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const params = useParams();
  const navigate = useNavigate();
  const [storeProduct, setStoreProduct] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [productGroupTitle, setProductGroupTitle] = useState('');
  const [productData, setProductData] = useState({
    product_limit: 4,
    carousel_status: true,
    status: true,
    product_image_width: '',
    product_image_height: '',
    carousel_speed_ms: '',
    loop_forever: false,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
    is_filter_apply: true,
    filter_type: 'New Arrivals',
  });
  const [editProductId, setEditProductId] = useState(null);

  const handleGetPerticularProductGroup = async () => {
    setDataLoading(true);
    try {
      const apiCall = await API.get(`/admin/dynamicProduct/getProductGroupById/${params?.groupId}`);
      const result = decryptData(apiCall?.data);
      setProductData({
        button_text: result?.data?.button_text,
        button_link: result?.data?.button_link,
        product_limit: result?.data?.product_limit,
        carousel_status: result?.data?.carousel_status,
        status: result?.data?.status,
        product_image_width: result?.data?.product_image_width,
        product_image_height: result?.data?.product_image_height,
        carousel_speed_ms: result?.data?.carousel_speed_ms,
        loop_forever: result?.data?.loop_forever,
        is_filter_apply: result?.data?.is_filter_apply,
        pause_on_hover: result?.data?.pause_on_hover,
        auto_play: result?.data?.auto_play,
        filter_type: result?.data?.filter_type,
      });
      setProductGroupTitle(result?.data?.product_section_title);
      setStoreProduct(result?.data?.products);
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    handleGetPerticularProductGroup();
  }, []);
  const [addProduct, setAddProduct] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddProduct = async () => {
    try {
      const payload = encryptData({
        product_group_id: params?.groupId,
        product_data: selectedProduct,
      });
      await API.post('/admin/dynamicProduct/addProduct', payload);
      setSelectedProduct([]);
      setSearch('');
      setAddProduct(false);
      handleGetPerticularProductGroup();
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedProduct, setSelectedProduct] = useState([]);
  const handleSelectProduct = (product) => {
    if (product) {
      const existProduct = selectedProduct?.find((x) => x?.product === product);
      if (!existProduct) {
        setSelectedProduct([...selectedProduct, { product: product }]);
      } else {
        const removeSelected = selectedProduct?.filter((x) => x?.product !== product);
        setSelectedProduct(removeSelected);
      }
    }
  };
  const [search, setSearch] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const GetProducts = async () => {
    try {
      const payload = encryptData({
        searchkey: search,
        dynamicProductGroup: params?.groupId,
      });
      const apiCall = await API.post(`/admin/product/GetProductsTable`, payload);
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data);
        setAllProducts(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addProduct === true && (search?.length === 0 || search?.length >= 3)) {
      GetProducts();
    }
    if (!addProduct) {
      setSearch('');
      setSelectedProduct([]);
    }
  }, [addProduct, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleUpdateProductGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        product_section_title: productGroupTitle,
        button_text: productData?.button_text,
        button_link: productData?.button_link,
        product_limit: productData?.product_limit,
        carousel_status: productData?.carousel_status,
        status: productData?.status,
        product_image_width: productData?.product_image_width,
        product_image_height: productData?.product_image_height,
        carousel_speed_ms: productData?.carousel_speed_ms,
        loop_forever: productData?.loop_forever,
        auto_play: productData?.auto_play,
        pause_on_hover: !productData?.auto_play ? false : productData?.pause_on_hover,
        is_filter_apply: productData?.is_filter_apply,
        filter_type: productData?.filter_type,
      });
      await API.put(`/admin/dynamicProduct/updateProductGroup/${params?.groupId}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/product-group',
        title: 'edit product category',
      });
      navigate('/module-setting/product-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
      });
      await API.post(`/admin/dynamicProduct/deleteProduct`, payload);
      setIsNotification(true);
      setNotificationMsg('Removed SuccesFully!');
      setSeverity('success');
      handleGetPerticularProductGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/dynamicProduct/updateProduct/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(storeProduct);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setStoreProduct(items);
  //   sortProduct(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...storeProduct];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setStoreProduct(newItems);
      sortProduct(toIndex, editProductId);
      setEditProductId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeProduct?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  const updateFilterApply = async () => {
    let body = {
      is_filter_apply: productData?.is_filter_apply,
    };
    if (productData?.is_filter_apply) {
      body = {
        ...body,
        filter_type: productData?.filter_type,
      };
    }
    try {
      const payload = encryptData(body);
      await API.put(`/admin/dynamicProduct/updateProductGroup/${params?.groupId}`, payload);
      setSelectedProduct([]);
      setStoreProduct([]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/product-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit product category',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/product-group',
        title: 'edit product category',
      });
    } else {
      navigate('/module-setting/product-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Product_Group'), path: '/module-setting/product-group' },
                {
                  name: t('lang_Edit_product_group'),
                  path: '/module-setting/product-group/edit-product-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Edit_product_group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Product_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={productGroupTitle}
                onChange={(e) => {
                  setProductGroupTitle(e.target.value);
                  setIsChange(true);
                }}
                placeholder='Enter title....'
              />
              {dataErr && productGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 w-full'>
              <div className='mb-4 flex items-center gap-2'>
                <label>Product Group Status</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({ ...productData, status: !productData?.status });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>Filter Apply</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.is_filter_apply}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({
                        ...productData,
                        is_filter_apply: !productData?.is_filter_apply,
                      });
                      setIsChange(true);
                      setStoreProduct([]);
                    }}
                    onBlur={() => updateFilterApply()}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Filter_Type')}</label>
                <div
                  className={
                    dataErr && productData?.is_filter_apply && productData?.filter_type === ''
                      ? 'border border-red-500 rounded-[6px] w-full relative'
                      : 'relative'
                  }
                >
                  <select
                    className='w-full'
                    disabled={!productData?.is_filter_apply}
                    value={productData?.filter_type}
                    onClick={() => setDataErr(false)}
                    onChange={(e) => {
                      setProductData({ ...productData, filter_type: e.target.value });
                      setIsChange(true);
                    }}
                  >
                    <option value='New Arrivals'>{t('lang_New_Arrivals')}</option>
                    <option value='Special'>{t('lang_Special')}</option>
                    <option value='Featured'>{t('lang_featured')}</option>
                    <option value='Top Rated'>{t('lang_Top_Rated')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
                {dataErr && productData?.is_filter_apply && productData?.filter_type === '' ? (
                  <p className='text-xs text-red-500'>{t('lang_Please_Select_Filter_Type')}</p>
                ) : (
                  ''
                )}
              </div>
              <div className='w-full'>
                <label>product Limit</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={productData?.product_limit}
                    onChange={(e) => {
                      setProductData({ ...productData, product_limit: e.target.value });
                      setIsChange(true);
                    }}
                  >
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({
                        ...productData,
                        carousel_status: !productData?.carousel_status,
                      });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={productData?.carousel_speed_ms}
                    disabled={productData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setProductData({ ...productData, carousel_speed_ms: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Loop')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({ ...productData, loop_forever: !productData?.loop_forever });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Autoplay')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({ ...productData, auto_play: !productData?.auto_play });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              {productData?.auto_play && (
                <div className='mb-4 flex items-center gap-2'>
                  <label>{t('lang_Pause_On_Hover')}</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={productData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setProductData({
                          ...productData,
                          pause_on_hover: !productData?.pause_on_hover,
                        });
                        setIsChange(true);
                      }}
                    />
                  </label>
                </div>
              )}
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={productData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => {
                    setProductData({ ...productData, button_text: e.target.value });
                    setIsChange(true);
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={productData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => {
                    setProductData({ ...productData, button_link: e.target.value });
                    setIsChange(true);
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Product_Image_Width')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={productData?.product_image_width}
                    onChange={(e) => {
                      setProductData({ ...productData, product_image_width: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 322'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Product_Image_Height')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={productData?.product_image_height}
                    onChange={(e) => {
                      setProductData({ ...productData, product_image_height: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 416'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            {!productData?.is_filter_apply && (
              <>
                <div className='flex items-end w-full justify-between'>
                  <div className='flex items-center justify-between w-full text-sm'>
                    {storeProduct?.length === 0 ? (
                      <div>
                        {t('lang_Select_Products_For_Show_In_This_Group')}
                        <span className='text-red-500 ms-1'>*</span>
                      </div>
                    ) : (
                      <div className='flex items-center'>
                        {selectedRowsId && selectedRowsId?.length > 0 && (
                          <>
                            <span className='text-sm px-3'>
                              {selectedRowsId?.length} {t('lang_selected')}
                            </span>
                            |
                            <button
                              className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                              onClick={(e) => handleDelete(e)}
                            >
                              <Trash2 className='w-3.5 h-3.5' />
                              <span>{t('lang_delete_selection')}</span>
                            </button>
                          </>
                        )}
                      </div>
                    )}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        setAddProduct(true);
                      }}
                      className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                    >
                      {t('lang_Select_Product')}
                    </span>
                  </div>
                </div>
                {dataLoading && !storeProduct ? (
                  <TableSkeleton />
                ) : storeProduct?.length > 0 ? (
                  <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={selectedRowsId?.length === storeProduct?.length}
                              indeterminate={
                                selectedRowsId?.length !== storeProduct?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={() => {
                                handleSelectRow('all');
                              }}
                            />
                          </td>
                          <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                          <td className='w-full px-3'>{t('lang_Product_Name')}</td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <ReactDragListView {...dragProps}>
                          {storeProduct?.map((elem, index) => (
                            <tr
                              style={{
                                // boxShadow: snapshot.isDragging
                                //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                //   : 'none',
                                background: 'white',
                              }}
                              className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                                } select-none flex w-full`}
                              key={index}
                              onDragStart={() => setEditProductId(elem?._id)}
                            >
                              <td className='px-3 table-body-checkbox'>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === elem?._id)
                                  }
                                  className='inline-block'
                                  onChange={() => handleSelectRow(elem?._id)}
                                />
                              </td>
                              <td className='table-image px-3 min-w-[80px]'>
                                <img
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  width={80}
                                  height={50}
                                  src={elem?.productData?.image || NOPHOTO_IMAGE}
                                  className='p-1 max-w-full max-h-full object-contain inline-block'
                                  alt={elem?.productData?.product_name}
                                />
                              </td>
                              <td className='w-full px-3'>
                                <span className='line-clamp-2'>
                                  {elem?.productData?.product_name || '-'}
                                </span>
                              </td>
                              <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                <div className='space-x-2 w-full text-right'>
                                  <button
                                    onClick={(e) => {
                                      handleDelete(e, elem._id);
                                    }}
                                  >
                                    <Trash className='w-[17px] h-[17px] text-red-500' />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </ReactDragListView>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                <hr className='border-t main-border-color my-4'></hr>
              </>
            )}

          </div>
        </form>

        <Dialog
          open={addProduct}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddProduct(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b main-border-color pb-3 text-primary items-center justify-between'>
              <h6 className='font-medium text-xl'>{t('lang_Select_Product')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddProduct(false);
                  setSearch('');
                  setSelectedProduct([]);
                  handleGetPerticularProductGroup();
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm'
              placeholder='Search products..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allProducts?.map((elem) => {
                return (
                  <label className='flex py-2 table-text main-border-color items-start space-x-4'>
                    <input
                      type='checkbox'
                      checked={selectedProduct?.find((x) => x?.product === elem?._id)}
                      value={elem?._id}
                      onChange={(e) => {
                        handleSelectProduct(elem?._id);
                        setIsChange(true);
                      }}
                      className='block mt-1 w-4 h-4'
                    />
                    <div className='flex space-x-2'>
                      <div className='w-[60px] h-[60px] p-1 border main-border-color flex items-center justify-center'>
                        <img
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={elem?.image || NOPHOTO_IMAGE}
                          className='max-w-full max-h-full'
                          alt={elem?.product_name}
                        />
                      </div>
                      <div>
                        <p className='text-base block break-all'>{elem?.product_name}</p>
                        <span className='text-xs font-medium capitalize'>{t('lang_type')} : </span>
                        <span className='text-xs capitalize'>{elem?.product_type}</span>
                      </div>
                    </div>
                  </label>
                );
              })}
            </div>
            <button
              className='btn text-sm text-white'
              onClick={() => {
                handleAddProduct();
              }}
            >
              {t('lang_Add_Product')}
            </button>
          </div>
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-full flex items-center justify-between lg:w-[80%] xl:w-[68%] mx-auto'>
          <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline mt-2 text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              Go To product groups
            </Link>
            <div>
              {productGroupTitle?.length === 0 ||
                (!productData?.is_filter_apply && storeProduct?.length === 0) ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_product_group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[167px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleUpdateProductGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_product_group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProductGroup;
