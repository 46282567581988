import React from 'react';
import { useNavigate } from "react-router-dom";
import { Check } from 'feather-icons-react/build/IconComponents';
import { SHOPEASY_BLACK_LOGO } from '../../Assets/StaticData/StaticImage';
import { Check2 } from 'react-bootstrap-icons';

function DeclinedPage() {
  const navigate = useNavigate();
  return (
    <>
      <div className='bg relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white'>
        <div className="w-full sm:w-8/12 form xl:w-1/3 mx-5 my-10 bg-white p-7 rounded-[6px] border border-gray-300">
            <img className='max-w-[12rem] mx-auto' src={SHOPEASY_BLACK_LOGO} alt='logo'/>
          {/* <hr className='my-4 border-gray-300' /> */}
          <div className='w-full mt-6 px-5 text-center'>
            <h6 className='mb-1 font-semibold text-red-600'>Verification Failed</h6>
            <p className='mb-2 w-full md:w-[80%] mx-auto text-sm text-primary'>
              We are sorry to inform you that your store request has been canceled by Shopeasy team...Or you ran out of verification time..
            </p>
            <h6 className='mt-5 mb-2 text-xl font-bold text-left text-primary '>Possible Reasons:</h6>
            <div className='flex items-center'>
              <Check2 className='w-4 h-4 mt-1 stroke-black me-2' />
              <p className='font-medium inline-block table-text'>In-apropriate email address..</p>
            </div>
            <div className='flex items-center'>
              <Check2 className='w-4 h-4 mt-1 stroke-black me-2' />
              <p className='font-medium inline-block table-text'>In-apropriate store name ..</p>
            </div>
            <div className='flex items-center'>
              <Check2 className='w-4 h-4 mt-1 stroke-black me-2' />
              <p className='font-medium inline-block table-text'>Suspecious activity...</p>
            </div>
            <div className='flex items-center'>
              <Check2 className='w-4 h-4 mt-1 stroke-black me-2' />
              <p className='font-medium inline-block table-text'>Un-wanted spam I'd...</p>
            </div>
            <p className='mt-5 text-base font-medium text-gray-800'>
              Please try again with valid data...Thank you for your kind Co-operation.. 
              <button className='text-link underline font-medium text-base' onClick={() => navigate('/register')}>Create New Store</button>
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
export default DeclinedPage;
