import React from 'react';
import EditProductDesctiptionStyleSection from './EditProductDescriptionStyleContainer';

const EditProductDescriptionStyleContainer = ({
    productDescriptionDesign,
  setProductDescriptionDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductDesctiptionStyleSection
        productDescriptionDesign={productDescriptionDesign}
        setProductDescriptionDesign={setProductDescriptionDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductDescriptionStyleContainer;
