import React from 'react';
import EditImageBoxStyleContainer from './EditImageBoxStyleContainer';

const EditImageBoxStyleSection = ({
  previewImage,
  imageBoxDesign,
  setImageBoxDesign,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditImageBoxStyleContainer
        imageBoxDesign={imageBoxDesign}
        setImageBoxDesign={setImageBoxDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditImageBoxStyleSection;
