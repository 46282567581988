import { Checkbox, Skeleton } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const EditRole = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [nodata, setNodata] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const [severity, setSeverity] = useState();
  const [FormData, setFormData] = useState({ role_name: '', description: '', permissions: '' });
  const [getRole, setGetRole] = useState();
  const [err, setErr] = useState(false);
  const [formSpinner, setFormSpinner] = useState();
  const GetRoleById = async () => {
    try {
      const ApiCall = await API.get(`/admin/role/getRoleById/${params.role_id}`);
      const result = decryptData(ApiCall?.data)
      setGetRole(result?.data);
      setFormData({
        role_name: result?.data?.name,
        description: result?.data?.description,
        permissions: result?.data?.permissions,
      });
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  let { role_name, description, permissions } = FormData;
  useEffect(() => {
    GetRoleById();
  }, []);

  {
    /* //To Save selected Permission in Array */
  }
  const HandleArray = (value) => {
    setIsChange(true);
    const valuesToAdd = value.split(',').map((i) => i);
    const valuesPresent = valuesToAdd.every((value) => permissions.includes(value));
    if (valuesPresent) {
      let filteredArr = permissions.filter((value) => !valuesToAdd.includes(value));
      let uniqueArray = [...new Set(filteredArr)];
      setFormData({ ...FormData, permissions: uniqueArray });
    } else {
      let uniqueArray = [...new Set([...permissions, ...valuesToAdd])];
      setFormData({ ...FormData, permissions: uniqueArray });
    }
  };

  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        name: role_name,
        description,
        permissions,
      })
      await API.put(`/admin/role/updateRole/${getRole?._id}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/roles',
        title: 'edit role',
      });
      navigate('/roles');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };

  let permissions_value = [
    'add_Product',
    'dashboard',
    'get_Analytics',
    'get_SellingProductsChart',
    'add_Blog',
    'get_Blog',
    'edit_Blog',
    'delete_Blog',
    'add_Category',
    'edit_Category',
    'get_Category',
    'delete_Category',
    'edit_Client',
    'add_Staff',
    'get_Staff',
    'edit_Staff',
    'delete_Staff',
    'add_Page',
    'get_Page',
    'edit_Page',
    'delete_Page',
    'get_enquiry',
    'add_Discount',
    'get_Discount',
    'edit_Discount',
    'delete_Discount',
    'add_FAQ',
    'get_FAQ',
    'edit_FAQ',
    'delete_FAQ',
    'add_gdpr',
    'get_gdpr',
    'edit_gdpr',
    'add_Manufacturer',
    'get_Manufacturer',
    'edit_Manufacturer',
    'delete_Manufacturer',
    'get_Order',
    'add_Order',
    'edit_Order',
    'add_Payment',
    'get_Payment',
    'edit_Payment',
    'add_Portfolio',
    'get_Portfolio',
    'edit_Portfolio',
    'delete_Portfolio',
    'get_Product',
    'edit_Product',
    'delete_Product',
    'add_Product_Variant_Group',
    'get_Product_Variant_Group',
    'edit_Product_Variant_Group',
    'delete_Product_Variant_Group',
    'edit_UpdateReview',
    'get_AllReviews',
    'get_ProductReviews',
    'add_Reviews',
    'add_Role',
    'get_Role',
    'edit_Role',
    'delete_Role',
    'add_ShippingMethod',
    'get_ShippingMethod',
    'edit_ShippingMethod',
    'module_setting',
    'abandoned_cart',
    'get_Chat',
    'delete_extension',
    'edit_extension',
    'view_extension',
    'add_extension',
    'view_filter',
    'edit_filter',
    'add_filter',
  ];
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/roles',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit role',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/roles',
        title: 'edit role',
      });
    } else {
      navigate('/roles');
    }
  };
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_roles'), path: '/roles' },
                { name: t('lang_Update_Role'), path: '/roles/update-role' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_Roles')} - {getRole?.name}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[75px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[105px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[105px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[105px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[105px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[105px] mb-1' variant='rounded-[6px]' />
                </div>
              </div>
            ) : (
              <>
                <div className='secondary-bg-color table-text border main-border-color  rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='mb-4'>
                    <label htmlFor='role_name'>{t('lang_name')}<span className='text-red-500 ms-1'>*</span></label>
                    <input
                      className={`w-full px-3 py-2 border ${err && FormData.role_name === '' ? 'border-red-500' : 'main-border-color'
                        }  rounded-[6px] focus:outline-none`}
                      type='text'
                      id='role_name'
                      name='role_name'
                      value={role_name}
                      onChange={(e) => {
                        setFormData({ ...FormData, role_name: e.target.value });
                        setIsChange(true);
                      }}
                      placeholder='e.g. Manager'
                      required
                    />
                    {err && FormData.role_name === '' && (
                      <p className='text-xs text-red-500'>{t('lang_Role_Name_Is_Required')}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='description' className='capitalize'>
                      {t('lang_description')}
                    </label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='description'
                      name='description'
                      value={description}
                      onChange={(e) => {
                        setFormData({ ...FormData, description: e.target.value });
                        setIsChange(true);
                      }}
                      placeholder='e.g. Manager can access all the store'
                      required
                    />
                  </div>
                  <div className='mb-4 text-[13px]'>
                    <label htmlFor='role_name'>{t('lang_Permissions')}<span className='text-red-500 ms-1'>*</span></label>
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        value={permissions_value}
                        onClick={(e) => HandleArray(e.target.value)}
                        checked={permissions_value.every((values) => permissions.includes(values))}
                        name='vehicle'
                      />
                      <span className='ms-2 capitalize text-sm'>{t('lang_All_Permissions')}</span>
                    </div>

                    {/* dashboard Section */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='dashboard'
                        value={'dashboard'}
                        checked={permissions.includes('dashboard')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 capitalize text-sm'>{t('lang_dashboard')}</span>
                    </div>
                    {/* Products Section */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3 p-3'>
                      <Checkbox
                        id='vehicle1'
                        value={['add_Product', 'get_Product', 'edit_Product', 'delete_Product']}
                        onChange={(e) => HandleArray(e.target.value)}
                        checked={[
                          'add_Product',
                          'get_Product',
                          'edit_Product',
                          'delete_Product',
                        ].every((values) => permissions.includes(values))}
                        name='Products'
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_products')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Product'}
                        checked={permissions.includes('add_Product')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Products')}</span>
                      <br />
                      <Checkbox
                        id='product'
                        name='product'
                        value={'get_Product'}
                        checked={permissions.includes('get_Product')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Products')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Product'}
                        checked={permissions.includes('edit_Product')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Products')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Product'}
                        checked={permissions.includes('delete_Product')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Products')}</span>
                      <br />
                    </div>
                    {/* Category  */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_Category', 'get_Category', 'edit_Category', 'delete_Category']}
                        checked={[
                          'add_Category',
                          'get_Category',
                          'edit_Category',
                          'delete_Category',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_category')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Category'}
                        checked={permissions.includes('add_Category')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_add_category')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Category'}
                        checked={permissions.includes('get_Category')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Category')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Category'}
                        checked={permissions.includes('edit_Category')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Category')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Category'}
                        checked={permissions.includes('delete_Category')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Category')}</span>
                      <br />
                    </div>
                    {/* Product Variant Group  */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={[
                          'add_Product_Variant_Group',
                          'get_Product_Variant_Group',
                          'edit_Product_Variant_Group',
                          'delete_Product_Variant_Group',
                        ]}
                        checked={[
                          'add_Product_Variant_Group',
                          'get_Product_Variant_Group',
                          'edit_Product_Variant_Group',
                          'delete_Product_Variant_Group',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>
                        {t('lang_Product_Variant_Group')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Product_Variant_Group'}
                        checked={permissions.includes('add_Product_Variant_Group')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Add_Product_Variant_Group')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Product_Variant_Group'}
                        checked={permissions.includes('get_Product_Variant_Group')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_View_Product_Variant_Group')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Product_Variant_Group'}
                        checked={permissions.includes('edit_Product_Variant_Group')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Edit_Product_Variant_Group')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Product_Variant_Group'}
                        checked={permissions.includes('delete_Product_Variant_Group')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Remove_Product_Variant_Group')}
                      </span>
                      <br />
                    </div>
                    {/* Quantity Discount */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_Discount', 'get_Discount', 'edit_Discount', 'delete_Discount']}
                        checked={[
                          'add_Discount',
                          'get_Discount',
                          'edit_Discount',
                          'delete_Discount',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>
                        {t('lang_Discount_Based_On_Quantity')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Discount'}
                        checked={permissions.includes('add_Discount')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Discount')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Discount'}
                        checked={permissions.includes('get_Discount')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Discount')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Discount'}
                        checked={permissions.includes('edit_Discount')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Discount')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Discount'}
                        checked={permissions.includes('delete_Discount')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Discount')}</span>
                      <br />
                    </div>
                    {/* Manufacturer */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={[
                          'add_Manufacturer',
                          'get_Manufacturer',
                          'edit_Manufacturer',
                          'delete_Manufacturer',
                        ]}
                        checked={[
                          'add_Manufacturer',
                          'get_Manufacturer',
                          'edit_Manufacturer',
                          'delete_Manufacturer',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_Manufacturer')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Manufacturer'}
                        checked={permissions.includes('add_Manufacturer')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Manufacturer')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Manufacturer'}
                        checked={permissions.includes('get_Manufacturer')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Manufacturer')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Manufacturer'}
                        checked={permissions.includes('edit_Manufacturer')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Manufacturer')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Manufacturer'}
                        checked={permissions.includes('delete_Manufacturer')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Remove_Manufacturer')}
                      </span>
                      <br />
                    </div>
                    {/* Orders */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['get_Order', 'add_Order', 'edit_Order']}
                        checked={['get_Order', 'add_Order', 'edit_Order'].every((values) =>
                          permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_orders')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Order'}
                        checked={permissions.includes('get_Order')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_view_orders')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Order'}
                        checked={permissions.includes('add_Order')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Orders')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Order'}
                        checked={permissions.includes('edit_Order')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Update_Orders')}</span>
                      <br />
                    </div>
                    {/* Blogs */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_Blog', 'get_Blog', 'edit_Blog', 'delete_Blog']}
                        checked={['add_Blog', 'get_Blog', 'edit_Blog', 'delete_Blog'].every(
                          (values) => permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_Blogs')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Blog'}
                        checked={permissions.includes('add_Blog')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Blog')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Blog'}
                        checked={permissions.includes('get_Blog')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_view_Blog')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Blog'}
                        checked={permissions.includes('edit_Blog')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Blog')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Blog'}
                        checked={permissions.includes('delete_Blog')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Blog')}</span>
                      <br />
                    </div>
                    {/* Staff */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_Staff', 'get_Staff', 'edit_Staff', 'delete_Staff']}
                        checked={['add_Staff', 'get_Staff', 'edit_Staff', 'delete_Staff'].every(
                          (values) => permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_staff')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Staff'}
                        checked={permissions.includes('add_Staff')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Staff')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Staff'}
                        checked={permissions.includes('get_Staff')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Staff')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Staff'}
                        checked={permissions.includes('edit_Staff')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Staff')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Staff'}
                        checked={permissions.includes('delete_Staff')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Staff')}</span>
                      <br />
                    </div>

                    {/* Pages */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_Page', 'get_Page', 'edit_Page', 'delete_Page']}
                        checked={['add_Page', 'get_Page', 'edit_Page', 'delete_Page'].every(
                          (values) => permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_pages')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Page'}
                        checked={permissions.includes('add_Page')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Pages')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Page'}
                        checked={permissions.includes('get_Page')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Pages')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Page'}
                        checked={permissions.includes('edit_Page')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Pages')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Page'}
                        checked={permissions.includes('delete_Page')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Pages')}</span>
                      <br />
                    </div>
                    {/* FAQ */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_FAQ', 'get_FAQ', 'edit_FAQ', 'delete_FAQ']}
                        checked={['add_FAQ', 'get_FAQ', 'edit_FAQ', 'delete_FAQ'].every((values) =>
                          permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_FAQ')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_FAQ'}
                        checked={permissions.includes('add_FAQ')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_FAQ')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_FAQ'}
                        checked={permissions.includes('get_FAQ')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_FAQ')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_FAQ'}
                        checked={permissions.includes('edit_FAQ')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_FAQ')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_FAQ'}
                        checked={permissions.includes('delete_FAQ')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_FAQ')}</span>
                      <br />
                    </div>
                    {/* GDPR */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_gdpr', 'get_gdpr', 'edit_gdpr']}
                        checked={['add_gdpr', 'get_gdpr', 'edit_gdpr'].every((values) =>
                          permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_gdpr')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_gdpr'}
                        checked={permissions.includes('add_gdpr')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_GDPR')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_gdpr'}
                        checked={permissions.includes('get_gdpr')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_GDPR')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_gdpr'}
                        checked={permissions.includes('edit_gdpr')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_GDPR')}</span>
                      <br />
                    </div>

                    {/* Portfolio */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={[
                          'add_Portfolio',
                          'get_Portfolio',
                          'delete_Portfolio',
                          'edit_Portfolio',
                        ]}
                        checked={[
                          'add_Portfolio',
                          'get_Portfolio',
                          'delete_Portfolio',
                          'edit_Portfolio',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_portfolio')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Portfolio'}
                        checked={permissions.includes('add_Portfolio')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Portfolio')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Portfolio'}
                        checked={permissions.includes('get_Portfolio')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Portfolio')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Portfolio'}
                        checked={permissions.includes('edit_Portfolio')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Portfolio')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Portfolio'}
                        checked={permissions.includes('delete_Portfolio')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Portfolio')}</span>
                      <br />
                    </div>

                    {/* filter */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_filter', 'edit_filter', 'view_filter']}
                        checked={['add_filter', 'edit_filter', 'view_filter'].every((values) =>
                          permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_filter')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_filter'}
                        checked={permissions.includes('add_filter')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Filter')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_filter'}
                        checked={permissions.includes('edit_filter')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Filter')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'view_filter'}
                        checked={permissions.includes('view_filter')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Filter')}</span>
                      <br />
                    </div>

                    {/* Extension */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={[
                          'add_extension',
                          'view_extension',
                          'edit_extension',
                          'delete_extension',
                        ]}
                        checked={[
                          'add_extension',
                          'view_extension',
                          'edit_extension',
                          'delete_extension',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_Extension')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_extension'}
                        checked={permissions.includes('add_extension')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Extension')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'view_extension'}
                        checked={permissions.includes('view_extension')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Extension')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_extension'}
                        checked={permissions.includes('edit_extension')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Extension')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_extension'}
                        checked={permissions.includes('delete_extension')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Extension')}</span>
                      <br />
                    </div>

                    {/* Reviews */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={[
                          'get_ProductReviews',
                          'get_AllReviews',
                          'edit_UpdateReview',
                          'add_Reviews',
                        ]}
                        checked={[
                          'get_ProductReviews',
                          'get_AllReviews',
                          'edit_UpdateReview',
                          'add_Reviews',
                        ].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_Reviews')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_ProductReviews'}
                        checked={permissions.includes('get_ProductReviews')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_View_Product_Reviews')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Reviews'}
                        checked={permissions.includes('add_Reviews')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Add_Product_Reviews')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_AllReviews'}
                        checked={permissions.includes('get_AllReviews')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Reviews')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_UpdateReview'}
                        checked={permissions.includes('edit_UpdateReview')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Update_Reviews')}</span>
                      <br />
                    </div>
                    {/* Role */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['add_Role', 'get_Role', 'edit_Role', 'delete_Role']}
                        checked={['add_Role', 'get_Role', 'edit_Role', 'delete_Role'].every(
                          (values) => permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_roles')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'add_Role'}
                        checked={permissions.includes('add_Role')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Add_Role')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Role'}
                        checked={permissions.includes('get_Role')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_View_Role')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'edit_Role'}
                        checked={permissions.includes('edit_Role')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Edit_Role')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'delete_Role'}
                        checked={permissions.includes('delete_Role')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Remove_Role')}</span>
                      <br />
                    </div>
                    {/* Payment Methods */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['get_Payment']}
                        checked={['get_Payment'].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>
                        {t('lang_Payment_Method')}
                      </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Payment'}
                        checked={permissions.includes('get_Payment')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Get_Payment_Method')}
                      </span>
                      <br />
                    </div>
                    {/* Shipping Method */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['get_ShippingMethod']}
                        checked={['get_ShippingMethod'].every((values) =>
                          permissions.includes(values),
                        )}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>
                        {t('lang_Shipping_Method')}
                      </span>
                      <br />
                      <Checkbox
                        id='get_ShippingMethod'
                        name='get_ShippingMethod'
                        value={'get_ShippingMethod'}
                        checked={permissions.includes('get_ShippingMethod')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Access_Shipping_Methods')}{' '}
                      </span>
                      <br />
                    </div>
                    {/* module setting */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['module_setting']}
                        checked={['module_setting'].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>
                        {t('lang_Module_Setting')}
                      </span>
                      <br />
                      <Checkbox
                        id='module_setting'
                        name='module_setting'
                        value={'module_setting'}
                        checked={permissions.includes('module_setting')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>
                        {t('lang_Access_Module_Setting')}
                      </span>
                      <br />
                    </div>
                    {/* Chat ,Analytics */}
                    <div className='my-2 primary-bg-color rounded-[6px] px-4 py-3'>
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={['get_Chat', 'get_Analytics', 'abandoned_cart']}
                        checked={['get_Chat'].every((values) => permissions.includes(values))}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px]'
                      />
                      <span className='ms-2 my-2 capitalize text-sm'>{t('lang_Other')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Chat'}
                        checked={permissions.includes('get_Chat')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Chat')} </span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'get_Analytics'}
                        checked={permissions.includes('get_Analytics')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_analytics')}</span>
                      <br />
                      <Checkbox
                        id='vehicle1'
                        name='vehicle'
                        value={'abandoned_cart'}
                        checked={permissions.includes('abandoned_cart')}
                        onChange={(e) => HandleArray(e.target.value)}
                        className='w-[12px] h-[12px] ml-5 mt-1.5'
                      />
                      <span className='ms-2 capitalize text-md'>{t('lang_Abandoned_Cart')}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-full flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
          <Link
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='w-fit flex text-link font-medium hover:underline mt-2 text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_To_Roles')}
          </Link>

          <div>
            {FormData?.role_name === '' ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Update_Role')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[122px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_Saving')}</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Update_Role')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
