import React, { useEffect, useState } from 'react';

const ExpirationCountdown = ({ expireDate }) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateCountdown();
        }, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const updateCountdown = () => {
        const now = new Date();
        const expiration = new Date(expireDate);
        const difference = expiration - now;

        if (difference <= 0) {
            setTimeLeft('Expired');
            return;
        }
        const minutes = Math.floor(difference / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 1) {
            setTimeLeft(`in ${days} days`);
        } else if (hours > 0) {
            setTimeLeft(`in ${hours} hours`);
        } else {
            setTimeLeft(`in ${minutes} minutes`);
        }
    };

    useEffect(() => {
        updateCountdown(); // Initial call to set the time left immediately
    }, []);

    return <span className='table-primary inline-block text-sm font-medium'>{timeLeft}</span>;
};
export default ExpirationCountdown
