const PortfolioCategoryChild = ({ category, storeList, handleSelectProduct }) => {
    return (
        <div>
            <label className='flex items-center space-x-4'>
                <input
                    type='checkbox'
                    value={category?._id}
                    checked={storeList?.some((x) => x?.category_id === category?._id)}
                    onClick={(e) =>
                        handleSelectProduct(
                            category?.portfoliocategory_name,
                            `/portfolio/portfolio-category/${category?.portfolio_category_slug_name}`,
                            "portfolio category",
                            category?._id
                        )
                    }
                    className='block w-4 h-4'
                />
                <p className='text-sm  font-normal break-all'>
                    {category?.portfoliocategory_name}
                </p>
            </label>
            {category?.child_category?.length > 0 && (
                <div className='ms-5' >
                    {
                        category?.child_category?.map((subCategory) => (
                            <PortfolioCategoryChild category={subCategory} storeList={storeList?.filter((x) => x?.section_type === "portfolio category")} handleSelectProduct={handleSelectProduct} />
                        ))
                    }
                </div>
            )}
        </div>
    );
};

export default PortfolioCategoryChild;