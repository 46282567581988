import { Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, Code, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import ColorPicker from '../Color/ColorPicker';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
const AddBannerGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const navigate = useNavigate();
  const [status, setstatus] = useState(true);
  const [groupTitle, setGroupTitle] = useState();
  const [image, setimage] = useState('');
  const [value, setValue] = useState();
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [addBanner, setAddBanner] = useState(false);
  const [bannerLimit, setBannerLimit] = useState(1);
  const [bannerParallax, setBannerParallax] = useState(false);
  const [bannerData, setBannerData] = useState({
    banner_button_color: '#818181',
    banner_button_text: '',
    banner_button_text_color: '#000000',
    banner_button_link: '',
    banner_button_hover_color: '#000000',
    banner_button_hover_text_color: '#818181',
    banner_button_redius: '',
    text_position: 'left',
  });
  const [dataErr, setDataErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [storeBanners, setStoreBanners] = useState([]);
  const handleAddBanner = () => {
    if (!image) {
      setDataErr(true);
    } else {
      setDataErr(false);
      if (isVisibleButton) {
        setStoreBanners([
          ...storeBanners,
          {
            ...bannerData,
            banner_img: image,
            banner_text: value,
            text_position: bannerData?.text_position,
            is_button_visible: isVisibleButton,
          },
        ]);
      } else {
        setStoreBanners([
          ...storeBanners,
          {
            banner_img: image,
            banner_text: value,
            text_position: bannerData?.text_position,
            is_button_visible: isVisibleButton,
          },
        ]);
      }
      setBannerData({
        banner_button_color: '#818181',
        banner_button_text: '',
        banner_button_text_color: '#f1f1f1',
        banner_button_link: '',
        banner_button_hover_color: '#f1f1f1',
        banner_button_hover_text_color: '#818181',
        banner_button_redius: '',
        text_position: 'left',
      });
      setIsVisibleButton(false);
      setValue();
      setimage();
      setAddBanner(false);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBannerGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        banner_title: groupTitle,
        banner_limit: bannerLimit,
        banner_parallax: bannerParallax,
        banners: storeBanners,
      })
      await API.post(`/admin/Banner/addBannerGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/banner-group',
        title: 'add banner group',
      });
      navigate('/module-setting/banner-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (
      storeBanners?.length > 0 ||
      bannerParallax === true ||
      bannerLimit !== 1 ||
      groupTitle?.length > 0 ||
      status === false
    ) {
      setSaveChanges({
        backLink: '/module-setting/banner-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add banner group',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/banner-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add banner group',
      });
    }
  }, [storeBanners, bannerParallax, bannerLimit, groupTitle, status]);
  const checkChanges = () => {
    if (
      storeBanners?.length > 0 ||
      bannerParallax === true ||
      bannerLimit !== 1 ||
      groupTitle?.length > 0 ||
      status === false
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/banner-group',
        title: 'add banner group',
      });
    } else {
      navigate('/module-setting/banner-group');
    }
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'banner_button_color') {
      setBannerData({ ...bannerData, banner_button_color: value });
    } else if (openState === 'banner_button_text_color') {
      setBannerData({ ...bannerData, banner_button_text_color: value });
    } else if (openState === 'banner_button_hover_color') {
      setBannerData({ ...bannerData, banner_button_hover_color: value });
    } else if (openState === 'banner_button_hover_text_color') {
      setBannerData({ ...bannerData, banner_button_hover_text_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Banner_Group'), path: '/module-setting/banner-group' },
                {
                  name: t('lang_Add_banner_group'),
                  path: '/module-setting/banner-group/add-banner-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_banner_group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>
                {t('lang_Banner_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm`}
                placeholder='Banner group title'
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
              />
            </div>
            <div className='grid grid-cols-2 mb-4 gap-5'>
              <div className='mb-4 flex items-center gap-2'>
                <label htmlFor='image'>{t('lang_Banner_Parallax')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={bannerParallax}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBannerParallax(!bannerParallax);
                      !bannerParallax && setBannerLimit(1);
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Banner_Limit')}</label>
                <div className='relative'>
                  <select
                    disabled={bannerParallax}
                    value={bannerLimit}
                    onChange={(e) => setBannerLimit(e.target.value)}
                    className='w-full border main-border-color focus:border-gray-500 rounded-[6px] py-1 outline-none px-3'
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
            </div>
            <div className='mb-4 flex items-center gap-2'>
              <label htmlFor='image'>{t('lang_status')} :</label>
              <label className='relative flex ms-2 items-center cursor-pointer'>
                <FormControlLabel
                  checked={status}
                  control={<MuiSwitch />}
                  onChange={() => setstatus(!status)}
                />
              </label>
            </div>
            <hr className='border-t main-border-color my-4'></hr>{' '}
            <div className='flex items-center justify-between w-full text-sm'>
              <div>
                {t('lang_Add_banners_for_show_in_this_group')}
                <span className='text-red-500 ms-1'>*</span>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setAddBanner(true);
                }}
                className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
              >
                <PlusCircle className='w-4 me-2 h-4' />
                {t('lang_Add_Banner')}
              </span>
            </div>
            {storeBanners?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <td className='px-3 thead-image'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_Banner_Text')}</td>
                      <td className='min-w-[300px] max-w-[300px] px-3'>{t('lang_link')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {storeBanners?.map((elem, index) => {
                      return (
                        <tr key={index}>
                          <td className='px-3 table-image'>
                            <img
                              width={80}
                              height={50}
                              src={elem.banner_img}
                              className='p-1 max-w-full max-h-full'
                              alt='banner image'
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span className='line-clamp-2'>
                              {elem?.banner_text ? elem.banner_text.replace(/<[^>]*>/g, ' ') : '-'}
                            </span>
                          </td>
                          <td className='min-w-[300px] max-w-[300px] px-3'>
                            <span
                              className='line-clamp-1'
                              dangerouslySetInnerHTML={{ __html: elem.banner_button_link || '-' }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}

          </div>
        </form>

        {!html?.htmlPP ? (
          <Dialog
            open={addBanner}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAddBanner(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5'
            >
              <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
                <h6 className='font-medium text-xl'>{t('lang_Add_Banner')}</h6>
                <button
                  className='close-btn'
                  onClick={(e) => {
                    e.preventDefault();
                    setAddBanner(false);
                  }}
                >
                  <X className='w-5 h-4' />
                </button>
              </div>
              <div className='mb-4'>
                <label htmlFor='image'>{t('lang_Banner_Image')} <span className='text-red-500 ms-1'>*</span></label>
                <ImageUploader
                  alreadyImage={image?.length > 0 ? image : ''}
                  handleSetImage={handleSetImage}
                  folder='banner'
                  format='image'
                />
              </div>
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='image'>{t('lang_Banner_Text')}</label>
                  <section
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </section>
                </div>

                <ReactQuill
                  modules={module}
                  ref={quillRef}
                  className='w-full h-auto'
                  theme='snow'
                  value={value}
                  onChange={handleQuillChange}
                />
              </div>

              <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full mb-4'>
                <div className='border w-full main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-1 text-sm'>
                  <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
                    {t('lang_Text_Position')}
                  </label>
                  <div className='flex w-full table-text items-center text-sm justify-between px-4'>
                    <span
                      className={`${bannerData?.text_position === 'left'
                        ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                      onClick={() => setBannerData({ ...bannerData, text_position: 'left' })}
                    >
                      {t('lang_Left')}
                    </span>
                    <span
                      className={`${bannerData?.text_position === 'center'
                        ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                      onClick={() => setBannerData({ ...bannerData, text_position: 'center' })}
                    >
                      {t('lang_Center')}
                    </span>
                    <span
                      className={`${bannerData?.text_position === 'right'
                        ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                      onClick={() => setBannerData({ ...bannerData, text_position: 'right' })}
                    >
                      {t('lang_Right')}
                    </span>
                  </div>
                </div>
                <div className='w-full border main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-2 text-sm justify-between'>
                  <p className='text-[13px] font-[500] table-text capitalize'>
                    {t('lang_Visible_Banner_Button')} -
                  </p>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={isVisibleButton === true}
                      control={<MuiSwitch />}
                      onChange={() => setIsVisibleButton(!isVisibleButton)}
                    />
                  </label>
                </div>
              </div>
              {isVisibleButton ? (
                <>
                  <div className='flex space-x-4 w-full mb-4'>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Banner_Button_Text')}</label>
                      <input
                        type='text'
                        name='banner_button_text'
                        className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                        onChange={(e) =>
                          setBannerData({ ...bannerData, banner_button_text: e.target.value })
                        }
                        placeholder='e.g. Click here'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Banner_Button_Link')}</label>
                      <input
                        type='text'
                        name='banner_button_link'
                        value={bannerData.banner_button_link}
                        className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                        placeholder='Add link'
                        onChange={(e) =>
                          setBannerData({ ...bannerData, banner_button_link: e.target.value })
                        }
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Banner_Button_Border_Radius')}</label>
                      <input
                        type='number'
                        name='banner_button_link'
                        value={bannerData.banner_button_redius}
                        className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                        placeholder='e.g. 4'
                        onChange={(e) =>
                          setBannerData({ ...bannerData, banner_button_redius: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex space-x-4 w-full mb-4'>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Banner_Button_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('banner_button_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                            style={{ backgroundColor: bannerData?.banner_button_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {bannerData?.banner_button_color}
                          </span>
                        </div>
                        {openState === 'banner_button_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={bannerData?.banner_button_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Button_Text_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('banner_button_text_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                            style={{ backgroundColor: bannerData?.banner_button_text_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {bannerData?.banner_button_text_color}
                          </span>
                        </div>
                        {openState === 'banner_button_text_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={bannerData?.banner_button_text_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex space-x-4 w-full mb-4'>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Banner_Button_Hover_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('banner_button_hover_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                            style={{ backgroundColor: bannerData?.banner_button_hover_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {bannerData?.banner_button_hover_color}
                          </span>
                        </div>
                        {openState === 'banner_button_hover_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={bannerData?.banner_button_hover_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Button_Hover_Text_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('banner_button_hover_text_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                            style={{ backgroundColor: bannerData?.banner_button_hover_text_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {bannerData?.banner_button_hover_text_color}
                          </span>
                        </div>
                        {openState === 'banner_button_hover_text_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={bannerData?.banner_button_hover_text_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <hr className='border-t main-border-color mt-3'></hr>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAddBanner();
                }}
                className='btn text-sm text-white'
              >
                {t('lang_Add_Banner')}
              </button>
            </form>
          </Dialog>
        ) : (
          html?.htmlPP && (
            <SourceCode
              open={html?.htmlPP}
              htmlCode={html?.code}
              closeCode={closeCode}
              saveHTML={saveHTML}
            />
          )
        )}
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
          <Link
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_to_banner_group')}
          </Link>
          <div>
            {!groupTitle || groupTitle === '' || storeBanners?.length === 0 ? (
              <button
                type='button'
                disabled
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_banner_group')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[161px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => handleAddBannerGroup(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_banner_group')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddBannerGroup;
