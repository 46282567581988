import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  applyFontSizeToTags,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const SixStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.sixContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_content-6',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');
  return (
    <div>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        const description = obj?.content?.description || '-';
        const fontSize = obj?.style?.description_font_size;
        const lineHeight = obj?.style?.description_font_height;
        const styledContent = applyFontSizeToTags(description, fontSize, lineHeight);

        const contentDescription = obj?.content?.content || '-';
        const contentFontSize = obj?.style?.content_font_size;
        const contentLineHeight = obj?.style?.content_font_height;
        const contentStyled = applyFontSizeToTags(
          contentDescription,
          contentFontSize,
          contentLineHeight,
        );

        return (
          <ReactDragListView {...dragProps}>
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_content-6', 'setting');
              }}
            >
              <div
                class='container mx-auto flex flex-col'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='lg:w-4/6 mx-auto'>
                  <div class='rounded-lg h-64 overflow-hidden'>
                    <img
                      alt='content'
                      class='object-cover object-center h-full w-full'
                      src={obj?.content?.image}
                      style={{
                        objectFit: obj?.content?.objectFit,
                      }}
                    />
                  </div>
                  <div class='flex flex-col sm:flex-row mt-10'>
                    <div class='sm:w-1/3 text-center sm:pr-8 sm:py-8'>
                      <div class='w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400'>
                        {obj?.content?.profile &&
                        (obj?.content?.profile.startsWith('data:image/svg+xml') ||
                          obj?.content?.profile.includes('<svg')) ? (
                          <SvgSection
                            svgContent={obj?.content?.profile}
                            width={'50px'}
                            height={'50px'}
                            fill={obj?.content?.profileFill ? '#9ca3af' : ''}
                            stroke={obj?.content?.profileFill ? '#9ca3af' : ''}
                          />
                        ) : (
                          <img
                            alt='profile content'
                            className='object-cover object-center h-full w-full'
                            src={obj?.content?.profile}
                          />
                        )}
                      </div>
                      <div class='flex flex-col items-center text-center justify-center'>
                        {obj?.content?.htmlTag === 'h1' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '40px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '45px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'h2' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '36px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '41px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'h3' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '32px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '37px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'h4' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '26px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '33px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'h5' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '22px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '29px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'h6' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '18px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '25px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'div' ? (
                          <div
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : obj?.content?.htmlTag === 'span' ? (
                          <span
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </span>
                        ) : obj?.content?.htmlTag === 'p' ? (
                          <p
                            class='mt-4'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {obj?.content?.title}
                          </p>
                        ) : (
                          <></>
                        )}
                        <div class='w-12 h-1 bg-indigo-500 rounded mt-2 mb-4'></div>
                        <div
                          style={{
                            fontWeight: obj?.style?.description_font_weight,
                            fontSize: `${obj?.style?.description_font_size}px`,
                            lineHeight: `${obj?.style?.description_font_height}px`,
                            color: obj?.style?.description_font_color,
                            textAlign: obj?.style?.description_font_align,
                            fontStyle: obj?.style?.description_font_style,
                            textDecoration: obj?.style?.description_font_decoration,
                            textTransform: obj?.style?.description_font_capitalize,
                            wordBreak: obj?.style?.description_font_break,
                            textWrap: obj?.style?.description_font_wrap,
                            letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.description_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                            textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                            textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                          }}
                          // className='line-clamp-2'
                          dangerouslySetInnerHTML={{ __html: styledContent }}
                        ></div>
                      </div>
                    </div>
                    <div class='sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left'>
                      <div
                        style={{
                          fontWeight: obj?.style?.content_font_weight,
                          fontSize: `${obj?.style?.content_font_size}px`,
                          lineHeight: `${obj?.style?.content_font_height}px`,
                          color: obj?.style?.content_font_color,
                          textAlign: obj?.style?.content_font_align,
                          fontStyle: obj?.style?.content_font_style,
                          textDecoration: obj?.style?.content_font_decoration,
                          textTransform: obj?.style?.content_font_capitalize,
                          wordBreak: obj?.style?.content_font_break,
                          textWrap: obj?.style?.content_font_wrap,
                          letterSpacing: `${obj?.style?.content_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.content_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.content_text_stroke}px ${obj?.style?.content_stroke_color}`,
                          textStroke: `${obj?.style?.content_text_stroke}px ${obj?.style?.content_stroke_color}`,
                          textShadow: `${obj?.style?.content_text_shadow_horizontal}px ${obj?.style?.content_text_shadow_vertical}px ${obj?.style?.content_text_shadow_blur}px ${obj?.style?.content_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.content_text_rotate}deg)`,
                        }}
                        class='leading-relaxed text-lg mb-4'
                        dangerouslySetInnerHTML={{ __html: contentStyled }}
                      ></div>
                      <a
                        class='inline-flex items-center gap-x-1'
                        style={{
                          color: obj?.style?.button_link_text_color,
                        }}
                      >
                        {obj?.content?.button_link}
                        {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                          React.cloneElement(obj?.content?.icon, {
                            width: '50px',
                            height: '50px',
                            color: '#6366f1',
                          })
                        ) : (
                          <SvgSection
                            svgContent={obj?.content?.icon}
                            width={'24px'}
                            height={'24px'}
                            fill={obj?.content?.isFill ? '#6366f1' : ''}
                            stroke={obj?.content?.isFill ? '#6366f1' : ''}
                          />
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
    </div>
  );
};

export default SixStaticContentSection;
