import { FormControlLabel } from '@mui/material';
import { ArrowLeft, Edit, PlusCircle, Trash } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react';
import { TrashFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Loader from '../Loader/Loader';
import Notification from '../Notification';

const GetHeaderGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [groupHeaderData, setGroupHeaderData] = useState();
  const [pending, setPending] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const getHeaderData = async () => {
    setPending(true);
    try {
      const data = await API.get(`/admin/header/getHeaderGroups`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setGroupHeaderData(result?.data);
        setPending(false);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getHeaderData();
  }, []);
  const [confirmationPopup, setConfirmationPopup] = useState(false)
  const handleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`admin/header/deleteHeaderGroup`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed SuccesFully!');
        setSeverity('success');
        getHeaderData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false)
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value)
  }
  const UpdateFooterStatus = async (row, value) => {
    try {
      const payload = encryptData({
        status: value,
      })
      const data = await API.put(`/admin/header/updateHeaderGroup/${row._id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getHeaderData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const columns = [
    {
      name: 'group name',
      cell: (row) => (
        <span
          className='line-clamp-2'
          dangerouslySetInnerHTML={{ __html: row.header_section_title || '-' }}
        ></span>
      ),
      sortable: true,
      minWidth: '300px',
    },
    {
      name: 'Status',
      width: '100px',
      cell: (row) => (
        <label className="relative flex justify-end w-full">
          <FormControlLabel
            checked={row?.status === true}
            control={<MuiSwitch />}
            onChange={() => {
              UpdateFooterStatus(row, !row?.status);
            }}
          />
        </label>
      ),
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => (
        <div className='space-x-2 w-full text-right'>
          {
            !row?.cart && !row?.notification && !row?.wish_list ?
              <>
                <button onClick={() => navigate(`/module-setting/header-menu/${row?._id}`)}>
                  <Edit className='w-[17px] h-[17px] text-black-500' />
                </button>
                <button onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setSelectedRowsId([row?._id]) }}>
                  <Trash className='w-[17px] h-[17px] text-red-500' />
                </button>
              </> : <></>
          }
        </div>
      ),
    },
  ];
  return (
    <>
      <ConfirmationPopup heading="Delete Header Section" subheading="Do you really want to delete this header section from your store?" confirmationPopup={confirmationPopup} handleCloseConfirm={handleCloseConfirm} runFunction={handleDelete} />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/module-setting') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: 'Dashboard', path: `/dashboard` },
                { name: 'Module setting', path: '/module-setting' },
                { name: 'Header menu', path: '/module-setting/header-menu' },
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>Header Menu</h4>
            </div>
          </div>
          <div>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => {
                navigate('/module-setting/header-menu/add-header-section');
              }}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              Add New Header Menu
            </button>
          </div>
        </div>
        <div className='w-full space-y-1.5'>
          <details
            className={
              selectedRowsId?.length === 0
                ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
                : `cursor-pointer group w-[200px] mb-1.5 relative`
            }
          >
            <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
              <span> Bulk Selection</span>
              <span
                className={
                  selectedRowsId?.length === 0
                    ? 'transition group-open:rotate-0'
                    : 'transition group-open:rotate-180'
                }
              >
                <svg
                  fill='none'
                  height={14}
                  shapeRendering='geometricPrecision'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  width={14}
                >
                  <path d='M6 9l6 6 6-6' />
                </svg>
              </span>
            </summary>
            <div
              className={
                selectedRowsId?.length === 0
                  ? `hidden`
                  : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
              }
            >
              <button
                className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                onClick={(e) => { e.preventDefault(); setConfirmationPopup(true) }}
              >
                <TrashFill className='w-3.5 h-3.5' />
                <span>Delete selection</span>
              </button>
            </div>
          </details>
          <div className='w-full inline-block border border-gray-300 rounded-[6px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              columns={columns}
              data={groupHeaderData}
              selectableRows
              onSelectedRowsChange={({ selectedRows }) => {
                setSelectedRowsId(selectedRows?.map((x) => x._id));
              }}
              selectableRowsHighlight
              highlightOnHover
              progressPending={pending}
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetHeaderGroup;
