import { Dialog } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react';

const DynamicInputField = ({ activeTab, handleTabClick, setFieldArray }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [fieldValues, setFieldValues] = useState({});
  const checkTypeValue = ['text', 'email', 'url', 'textarea'];
  const numberTypeValue = ['tel', 'number', 'range'];

  const dynamicFormFields = [
    {
      labelValue: '',
      inputTable: 'Text',
      type: 'text',
      name: 'textField',
      maxLength: null,
      minLength: null,
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
      selectType: [
        {
          label: 'Text Field',
          value: 'text',
        },
      ],
    },
    {
      labelValue: '',
      inputTable: 'Email',
      type: 'email',
      name: 'emailField',
      maxLength: null,
      minLength: null,
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
      selectType: [
        {
          label: 'Email address Field',
          value: 'email',
        },
      ],
    },
    {
      labelValue: '',
      inputTable: 'Url',
      type: 'url',
      name: 'urlField',
      maxLength: null,
      minLength: null,
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
      selectType: [
        {
          label: 'URL Field',
          value: 'url',
        },
      ],
    },
    {
      labelValue: '',
      inputTable: 'Telephone',
      type: 'tel',
      name: 'telField',
      maxLength: null,
      minLength: null,
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
      selectType: [
        {
          label: 'Telephone Number Field',
          value: 'tel',
        },
      ],
    },
    {
      labelValue: '',
      inputTable: 'Number',
      selectType: [
        {
          label: 'SpinBox',
          value: 'number',
        },
        {
          label: 'Slider',
          value: 'range',
        },
      ],
      type: 'number',
      name: 'numberField',
      min: '',
      max: '',
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
    },
    {
      labelValue: '',
      inputTable: 'Date',
      type: 'date',
      name: 'dateField',
      min: '',
      max: '',
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
      selectType: [
        {
          label: 'Date Field',
          value: 'date',
        },
      ],
    },
    {
      labelValue: '',
      inputTable: 'Text area',
      selectType: [
        {
          label: 'Text Area',
          value: 'textarea',
        },
      ],
      type: 'textarea',
      name: 'textAreaField',
      maxLength: '',
      minLength: '',
      defaultValue: '',
      placeholder: false,
      required: false,
      autoComplete: false,
    },
    {
      labelValue: '',
      inputTable: 'Drop-down Menu',
      selectType: [
        {
          label: 'Drop-down Menu',
          value: 'select',
        },
      ],
      type: 'select',
      name: 'selectValue',
      placeholder: false,
      required: false,
      options: ['Option 1', 'Option 2', 'Option 3'],
    },
    {
      labelValue: '',
      inputTable: 'Checkbox',
      selectType: [
        {
          label: 'Checkbox',
          value: 'checkbox',
        },
      ],
      type: 'checkbox',
      name: 'checkboxValue',
      placeholder: false,
      required: false,
      options: ['Option 1', 'Option 2', 'Option 3'],
    },
    {
      labelValue: '',
      inputTable: 'Radio Button',
      selectType: [
        {
          label: 'Radio Button',
          value: 'radio',
        },
      ],
      type: 'radio',
      name: 'radioButtonValue',
      placeholder: false,
      required: false,
      options: ['Option 1', 'Option 2', 'Option 3'],
    },
    {
      labelValue: '',
      inputTable: 'Acceptence Checkbox',
      selectType: [
        {
          label: 'Acceptence Checkbox',
          value: 'acceptence',
        },
      ],
      type: 'acceptence',
      name: 'acceptenceValue',
      condition: 'Write Terms and Conditions',
      placeholder: false,
      required: false,
    },
    {
      labelValue: '',
      inputTable: 'Quiz',
      selectType: [
        {
          label: 'Quiz',
          value: 'quiz',
        },
      ],
      type: 'quiz',
      name: 'QuizFormValue',
      question: 'Write Your Question',
      options: ['Option 1', 'Option 2', 'Option 3'],
      placeholder: false,
      required: false,
    },
    {
      labelValue: '',
      inputTable: 'Submit',
      selectType: [
        {
          label: 'Submit',
          value: 'submit',
        },
      ],
      type: 'submit',
      name: 'buttonValue',
      buttonName: 'Submit',
    },
    {
      labelValue: '',
      inputTable: 'File',
      selectType: [
        {
          label: 'File Upload',
          value: 'file',
        },
      ],
      type: 'file',
      name: 'fileValue',
      required: false,
      fileTypes: 'audio/*,video/*,image/*',
      limit: '1mb',
    },
  ];

  const getInputField = (e, fieldName) => {
    e.stopPropagation();
    setIsOpen(true);
    setCurrentField(fieldName);
    const selectedField = dynamicFormFields?.find((field) => field?.inputTable === fieldName);
    if (selectedField) {
      setFieldValues(selectedField);
    }
  };

  const handleDialogClose = () => {
    setIsOpen(false);
    setCurrentField(null);
    setFieldValues(null);
  };

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    const numericFields = ['maxLength', 'minLength', 'max', 'min'];
    const updatedValue =
      type === 'checkbox'
        ? checked
        : type === 'date'
        ? value
        : numericFields?.includes(name)
        ? value
        : value;

    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: updatedValue,
    }));
  };

  const handleSelectOption = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      type: value,
    }));
  };

  const handleAddOption = (e) => {
    const { value } = e.target;
    const newOptions = value?.split(',')?.map((option) => option?.trim());

    if (newOptions?.length > 0) {
      setFieldValues((prevValues) => ({
        ...prevValues,
        options: newOptions,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFieldArray((prev) => {
      return [...prev, fieldValues];
    });
    setIsOpen(false);
    setFieldValues(null);
    setCurrentField(null);
  };

  return (
    <>
      <ul className='flex flex-wrap -mb-px'>
        {dynamicFormFields?.map((field, index) => {
          return (
            <li className='me-2' key={index}>
              <button
                onClick={(e) => {
                  handleTabClick(e, field?.inputTable);
                  getInputField(e, field?.inputTable);
                }}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === field?.inputTable
                    ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
              >
                {field?.inputTable}
              </button>
            </li>
          );
        })}
      </ul>
      <Dialog keepMounted open={isOpen}>
        <div className='p-4 w-[700px]'>
          {currentField && (
            <>
              <div className='flex justify-between'>
                <h6>{currentField} field form-tag generator</h6>
                <X className='cursor-pointer' onClick={handleDialogClose} />
              </div>
              <form onSubmit={handleSubmit}>
                <div className='mb-5 mt-5'>
                  <label
                    for='countries'
                    class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Field Type
                  </label>
                  <select
                    name='selectType'
                    type='text'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    onChange={handleSelectOption}
                  >
                    {fieldValues?.selectType?.map((val, index) => {
                      return (
                        <option key={index} value={val?.value}>
                          {val?.label}
                        </option>
                      );
                    })}
                  </select>
                  <div className='flex items-start mb-5 mt-2'>
                    <div className='flex items-center h-5'>
                      <input
                        id='required'
                        name='required'
                        type='checkbox'
                        checked={fieldValues?.required}
                        onChange={handleFieldChange}
                        className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800'
                      />
                    </div>
                    <label
                      htmlFor='required'
                      className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                      This is a required field.
                    </label>
                  </div>
                </div>
                <div className='mb-5 mt-5'>
                  <label
                    htmlFor='fieldType'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Label Type
                  </label>
                  <input
                    type='text'
                    id='fieldType'
                    name='labelValue'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    value={fieldValues?.labelValue}
                    onChange={handleFieldChange}
                  />
                </div>
                <div className='mb-5'>
                  <label
                    htmlFor='fieldName'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Field Name
                  </label>
                  <input
                    type='text'
                    id='fieldName'
                    name='name'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    value={fieldValues?.name}
                    onChange={handleFieldChange}
                  />
                  <div className='flex items-start mb-5 mt-2'>
                    <div className='flex items-center h-5'>
                      <input
                        id='required'
                        name='autoComplete'
                        type='checkbox'
                        checked={fieldValues?.autoComplete}
                        onChange={handleFieldChange}
                        className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800'
                      />
                    </div>
                    <label
                      htmlFor='required'
                      className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                      This field expects the submitter name
                    </label>
                  </div>
                </div>
                {fieldValues?.type === 'date' && (
                  <>
                    <div className='grid grid-cols-2 gap-2'>
                      <div className='mb-5'>
                        <label
                          htmlFor='minDate'
                          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                          Min Date
                        </label>
                        <input
                          type='date'
                          id='minDate'
                          name='min'
                          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                          value={fieldValues?.minDate}
                          onChange={handleFieldChange}
                        />
                      </div>
                      <div className='mb-5'>
                        <label
                          htmlFor='maxDate'
                          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                          Max Date
                        </label>
                        <input
                          type='date'
                          id='maxDate'
                          name='max'
                          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                          value={fieldValues?.maxDate}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>
                  </>
                )}

                {checkTypeValue?.includes(fieldValues?.type) && (
                  <div className='mb-5'>
                    <label
                      htmlFor='minLengthmaxLength'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Length (min Length / max Length)
                    </label>
                    <div className='flex space-x-2'>
                      <input
                        type='number'
                        id='minLength'
                        name='minLength'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder='minLength'
                        value={fieldValues?.minLength}
                        onChange={handleFieldChange}
                      />
                      <input
                        type='number'
                        id='maxLength'
                        name='maxLength'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder='maxLength'
                        value={fieldValues?.maxLength}
                        onChange={handleFieldChange}
                      />
                    </div>
                  </div>
                )}

                {numberTypeValue?.includes(fieldValues?.type) && (
                  <div className='mb-5'>
                    <label
                      htmlFor='minLengthmaxLength'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Length (min Length / max Length)
                    </label>
                    <div className='flex space-x-2'>
                      <input
                        type='number'
                        id='minLength'
                        name='min'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder='min'
                        value={fieldValues?.min}
                        onChange={handleFieldChange}
                      />
                      <input
                        type='number'
                        id='max'
                        name='max'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder='max'
                        value={fieldValues?.max}
                        onChange={handleFieldChange}
                      />
                    </div>
                  </div>
                )}

                {fieldValues?.type === 'date' && (
                  <div className='mb-5'>
                    <label
                      htmlFor='defaultValue'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Default Value
                    </label>
                    <input
                      type='date'
                      id='defaultValue'
                      name='defaultValue'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={fieldValues?.defaultValue}
                      value={fieldValues?.defaultValue}
                      onChange={handleFieldChange}
                    />
                  </div>
                )}

                {(checkTypeValue?.includes(fieldValues?.type) ||
                  numberTypeValue?.includes(fieldValues?.type)) && (
                  <div className='mb-5'>
                    <label
                      htmlFor='defaultValue'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Default Value
                    </label>
                    <input
                      type='text'
                      id='defaultValue'
                      name='defaultValue'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={fieldValues?.defaultValue}
                      value={fieldValues?.defaultValue}
                      onChange={handleFieldChange}
                    />
                    <div className='flex items-start mb-5 mt-2'>
                      <div className='flex items-center h-5'>
                        <input
                          id='required'
                          name='placeholder'
                          type='checkbox'
                          checked={fieldValues?.placeholder}
                          onChange={handleFieldChange}
                          className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800'
                        />
                      </div>
                      <label
                        htmlFor='required'
                        className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        Use as Placeholder
                      </label>
                    </div>
                  </div>
                )}

                {fieldValues?.type === 'quiz' && (
                  <div className='mb-5'>
                    <label
                      htmlFor='fieldName'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Question
                    </label>
                    <input
                      type='text'
                      id='fieldName'
                      name='question'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      value={fieldValues?.question}
                      onChange={handleFieldChange}
                    />
                  </div>
                )}

                {(fieldValues?.type === 'select' ||
                  fieldValues?.type === 'checkbox' ||
                  fieldValues?.type === 'radio' ||
                  fieldValues?.type === 'quiz') && (
                  <div className='mb-5'>
                    <label
                      htmlFor='defaultValue'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Options
                    </label>
                    <textarea
                      id='defaultValue'
                      name='options'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder='Enter a new option'
                      value={fieldValues?.options?.join(', ')}
                      onChange={handleAddOption}
                    />
                  </div>
                )}

                {fieldValues?.type === 'acceptence' && (
                  <div className='mb-5'>
                    <label
                      htmlFor='fieldName'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Condition
                    </label>
                    <input
                      type='text'
                      id='fieldName'
                      name='condition'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      value={fieldValues?.condition}
                      onChange={handleFieldChange}
                    />
                  </div>
                )}

                {fieldValues?.type === 'submit' && (
                  <div className='mb-5'>
                    <label
                      htmlFor='fieldName'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Button Name
                    </label>
                    <input
                      type='text'
                      id='fieldName'
                      name='buttonName'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      value={fieldValues?.buttonName}
                      onChange={handleFieldChange}
                    />
                  </div>
                )}

                {fieldValues?.type === 'file' && (
                  <>
                    <div className='mb-2'>
                      <label
                        htmlFor='fieldName'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Acceptable file types
                      </label>
                      <input
                        type='text'
                        id='fieldName'
                        name='fileTypes'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        value={fieldValues?.fileTypes}
                        onChange={handleFieldChange}
                      />
                    </div>
                    <div className='mb-5'>
                      <label
                        htmlFor='fieldName'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        File size limit
                      </label>
                      <input
                        type='text'
                        id='fieldName'
                        name='limit'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        value={fieldValues?.limit}
                        onChange={handleFieldChange}
                      />
                    </div>
                  </>
                )}

                <button type='submit' className='w-full bg-blue-500 text-white p-2 rounded'>
                  Save Changes
                </button>
              </form>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DynamicInputField;
