import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeft, DollarSign } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import Loader from '../Loader/Loader';
import Notification from '../Notification';
import SettledInformation from './SettledInformation';
const Payouts = () => {
  const navigate = useNavigate();
  const [notSettledPayouts, setNotSettledPayouts] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const getNotSettledPayouts = async () => {
    try {
      let ApiCall = await API.get(`/admin/settlement/notSettledPayouts`);
      const result = decryptData(ApiCall?.data);
      setNotSettledPayouts(result?.data);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'payouts' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const columns = [
    {
      name: 'order id',
      cell: (row) => <p className='line-clamp-2 text-center'>{row?.order_id || '-'}</p>,
      selector: (row) => row.order_id,
      sortable: true,
      minWidth: '150px',
    },
    {
      name: <button className='w-full uppercase text-right'>total price</button>,
      cell: (row) => (
        <p className='line-clamp-2 text-end w-full'>{FormatAmount(row?.total_price)}</p>
      ),
      right: true,
      selector: (row) => row.total_price,
      minWidth: '150px',
      sortable: true,
    },
    {
      name: <button className='w-full uppercase text-right'>shipping charge</button>,
      cell: (row) => (
        <p className='line-clamp-2 text-end w-full'>
          {FormatAmount(row?.shipping_charge ? row?.shipping_charge : 0)}
        </p>
      ),
      minWidth: '150px',
    },
    {
      name: <button className='w-full uppercase text-right'>date</button>,
      cell: (row) =>
        row?.settlement_available_after && (
          <p className='line-clamp-2 text-end w-full'>
            {formatDate(row?.settlement_available_after)}
          </p>
        ),
      right: true,
      selector: (row) => row.settlement_available_after,
      sortable: true,
      minWidth: '150px',
    },
  ];

  useEffect(() => {
    getNotSettledPayouts();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center justify-between pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_payouts'), path: '/payouts' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_payouts')}{' '}
                </h4>
                <a target='blank' href='https://docs.shopeasy.ai/payouts' className='how-it-works'>
                  <QuestionCircleFill className='me-2' />
                  {t('lang_how_to_use')}
                </a>
              </div>
            </div>
          </div>
          <button
            className='btn px-6 py-2.5 font-medium rounded-[6px] text-white text-sm'
            onClick={() => navigate(`/payouts/payout-history`)}
          >
            {t('lang_View_History')}
          </button>
        </div>
        {/* not To be Settled Information */}
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4 gap-y-4 mb-4'>
          <div className='secondary-bg-color flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
            <div className='flex items-center w-full justify-between'>
              <div>
                <p className='font-semibold text-base capitalize'>Received Amount</p>
                <p className='text-2xl font-bold text-primary capitalize'>
                  {FormatAmount(notSettledPayouts?.pay_out_data?.payable_amount || 0)}
                </p>
              </div>
              <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92888 19.0359 10.1253 18.65 10.49L14.45 14.39M2 16L8 22M18.9 9C18.9 10.6016 17.6016 11.9 16 11.9C14.3984 11.9 13.1 10.6016 13.1 9C13.1 7.39837 14.3984 6.1 16 6.1C17.6016 6.1 18.9 7.39837 18.9 9ZM9 5C9 6.65685 7.65685 8 6 8C4.34315 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5Z'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className='secondary-bg-color flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
            <div className='flex items-center w-full justify-between'>
              <div>
                <p className='font-semibold text-base capitalize'>{t('lang_Total_Orders_Amount')}</p>
                <p className='text-2xl font-bold text-primary capitalize'>
                  {FormatAmount(notSettledPayouts?.pay_out_data?.total_orders_amount || 0)}
                </p>
              </div>
              <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92888 19.0359 10.1253 18.65 10.49L14.45 14.39M2 16L8 22M18.9 9C18.9 10.6016 17.6016 11.9 16 11.9C14.3984 11.9 13.1 10.6016 13.1 9C13.1 7.39837 14.3984 6.1 16 6.1C17.6016 6.1 18.9 7.39837 18.9 9ZM9 5C9 6.65685 7.65685 8 6 8C4.34315 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5Z'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className='secondary-bg-color flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
            <div className='flex items-center w-full justify-between'>
              <div>
                <p className='font-semibold text-base capitalize'>{t('lang_Total_Shipping_Charges')}</p>
                <p className='text-2xl font-bold text-primary capitalize'>
                  {FormatAmount(notSettledPayouts?.pay_out_data?.total_shipping_charges || 0)}
                </p>
              </div>
              <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92888 19.0359 10.1253 18.65 10.49L14.45 14.39M2 16L8 22M18.9 9C18.9 10.6016 17.6016 11.9 16 11.9C14.3984 11.9 13.1 10.6016 13.1 9C13.1 7.39837 14.3984 6.1 16 6.1C17.6016 6.1 18.9 7.39837 18.9 9ZM9 5C9 6.65685 7.65685 8 6 8C4.34315 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5Z'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className='secondary-bg-color flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
            <div className='flex items-center w-full justify-between'>
              <div>
                <p className='font-semibold text-base capitalize'>{t('lang_Transaction_Charges')}</p>
                <p className='text-2xl font-bold text-primary capitalize'>
                  {FormatAmount(notSettledPayouts?.pay_out_data?.transaction_charge || 0)}
                </p>
              </div>
              <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92888 19.0359 10.1253 18.65 10.49L14.45 14.39M2 16L8 22M18.9 9C18.9 10.6016 17.6016 11.9 16 11.9C14.3984 11.9 13.1 10.6016 13.1 9C13.1 7.39837 14.3984 6.1 16 6.1C17.6016 6.1 18.9 7.39837 18.9 9ZM9 5C9 6.65685 7.65685 8 6 8C4.34315 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5Z'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className='secondary-bg-color flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
            <div className='flex items-center w-full justify-between'>
              <div>
                <p className='font-semibold text-base capitalize'>{t('lang_Shopeasy_Service_Fee')}</p>
                <p className='text-2xl font-bold text-primary capitalize'>
                  {FormatAmount(notSettledPayouts?.pay_out_data?.shopeasy_service_fee || 0)}
                </p>
              </div>
              <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92888 19.0359 10.1253 18.65 10.49L14.45 14.39M2 16L8 22M18.9 9C18.9 10.6016 17.6016 11.9 16 11.9C14.3984 11.9 13.1 10.6016 13.1 9C13.1 7.39837 14.3984 6.1 16 6.1C17.6016 6.1 18.9 7.39837 18.9 9ZM9 5C9 6.65685 7.65685 8 6 8C4.34315 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5Z'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full border main-border-color category-table inline-block overflow-x-auto overflow-y-hidden rounded-[6px]'>
          <DataTable
            className='order-table'
            columns={columns}
            data={notSettledPayouts?.orders_processed}
            highlightOnHover
            progressComponent={<Loader />}
          />
        </div>
      </div>
    </>
  );
};

export default Payouts;
