import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  applyFontSizeToTags,
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';

const SevenStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.sevenContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_content-7',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');

  return (
    <div>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        return (
          <ReactDragListView {...dragProps}>
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_content-7', 'setting');
              }}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex flex-wrap -mx-4 -mb-10 text-center'>
                  {obj?.content?.item?.map((value) => {
                    const description = value?.description || '-';
                    const fontSize = obj?.style?.description_font_size;
                    const lineHeight = obj?.style?.description_font_height;
                    const styledContent = applyFontSizeToTags(description, fontSize, lineHeight);

                    return (
                      <div
                        class={`${getWidthClass(obj?.content?.gridValue?.toString())} mb-10 px-4`}
                      >
                        <div class='rounded-lg h-64 overflow-hidden'>
                          <img
                            alt='content'
                            class='object-cover object-center h-full w-full'
                            src={value?.image}
                            style={{
                              objectFit: value?.objectFit,
                            }}
                          />
                        </div>
                        {value?.htmlTag === 'h1' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '40px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '45px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'h2' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '36px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '41px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'h3' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '32px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '37px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'h4' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '26px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '33px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'h5' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '22px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '29px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'h6' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: obj?.style?.title_font_size
                                ? `${obj?.style?.title_font_size}px`
                                : '18px',
                              lineHeight: obj?.style?.title_font_height
                                ? `${obj?.style?.title_font_height}px`
                                : '25px',
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'div' ? (
                          <div
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'span' ? (
                          <span
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </span>
                        ) : value?.htmlTag === 'p' ? (
                          <p
                            class='mt-6 mb-3'
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                              transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                            }}
                          >
                            {value?.title}
                          </p>
                        ) : (
                          <></>
                        )}
                        <span
                          style={{
                            fontWeight: obj?.style?.description_font_weight,
                            fontSize: `${obj?.style?.description_font_size}px`,
                            lineHeight: `${obj?.style?.description_font_height}px`,
                            color: obj?.style?.description_font_color,
                            textAlign: obj?.style?.description_font_align,
                            fontStyle: obj?.style?.description_font_style,
                            textDecoration: obj?.style?.description_font_decoration,
                            textTransform: obj?.style?.description_font_capitalize,
                            wordBreak: obj?.style?.description_font_break,
                            textWrap: obj?.style?.description_font_wrap,
                            letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.description_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                            textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                            textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                          }}
                          class='leading-relaxed text-base'
                          dangerouslySetInnerHTML={{ __html: styledContent }}
                        ></span>
                        <button
                          style={{
                            color: obj?.style?.button_text_color,
                            backgroundColor: obj?.style?.button_color,
                          }}
                          class='flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded'
                        >
                          {value?.button}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
    </div>
  );
};

export default SevenStaticContentSection;
