import React from 'react';
import EditProductDisconuntStyleContainer from './EditProductDiscountStyleContainer';

const EditProductDisconuntStyleSection = ({
  productDiscountDesign,
  setProductDiscountDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductDisconuntStyleContainer
        productDiscountDesign={productDiscountDesign}
        setProductDiscountDesign={setProductDiscountDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductDisconuntStyleSection;
