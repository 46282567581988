import { Rating } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { StarFill } from 'react-bootstrap-icons';
import Slider from 'react-slick';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { FormatAmount } from '../../../controllers/FormatAmount';

const RelatedProduct = ({
  product,
  colors,
  clientInfo,
  productPageData,
  selectProductSection,
  productSectionOrderObject,
  relatedSectionDesign,
  handleRatingClick
}) => {
  const [reletedProduct, setRelatedProduct] = useState(
    product?.related_products?.map((el) => {
      return { ...el, isSelected: true, defaultCount: el?.min_order_quantity || 1 };
    }),
  );
  const [isHovered, setIsHovered] = useState();
  return (
    <div
      className='w-full py-5 cursor-pointer'
      style={{
        display: productPageData?.showRelatedProducts ? 'block' : 'none',
        borderColor: colors?.border_color,
        order: productSectionOrderObject?.rating,
        overflow: relatedSectionDesign?.overflow,
      }}
      // onClick={() => selectProductSection('Related Products')}
      onClick={() => {
        handleRatingClick('Related Products');
      }}
    >
      {reletedProduct?.length ? (
        <>
          <div
            className='bg-transparent'
            style={{
              display: relatedSectionDesign?.display,
              flexDirection: relatedSectionDesign?.direction,
              alignItems: relatedSectionDesign?.align_item,
              gap: relatedSectionDesign?.gap,
              marginTop: `${relatedSectionDesign?.margin_top}px`,
              marginBottom: `${relatedSectionDesign?.margin_bottom}px`,
              marginLeft: `${relatedSectionDesign?.margin_left}px`,
              marginRight: `${relatedSectionDesign?.margin_right}px`,
              paddingTop: `${relatedSectionDesign?.padding_top}px`,
              paddingBottom: `${relatedSectionDesign?.padding_bottom}px`,
              paddingLeft: `${relatedSectionDesign?.padding_left}px`,
              paddingRight: `${relatedSectionDesign?.padding_right}px`,
              height: `${relatedSectionDesign?.height}${relatedSectionDesign?.height_unit}`,
              width: `${relatedSectionDesign?.width}${relatedSectionDesign?.width_unit}`,
              borderStyle: relatedSectionDesign?.border_style,
              borderWidth: `${relatedSectionDesign?.border_width}px`,
              borderColor: relatedSectionDesign?.border_color,
              float: relatedSectionDesign?.float,
              maxHeight: `${relatedSectionDesign?.max_height}${relatedSectionDesign?.max_height_unit}`,
              maxWidth: `${relatedSectionDesign?.max_width}${relatedSectionDesign?.max_width_unit}`,
              minHeight: `${relatedSectionDesign?.min_height}${relatedSectionDesign?.min_height_unit}`,
              minWidth: `${relatedSectionDesign?.min_width}${relatedSectionDesign?.min_width_unit}`,
            }}
          >
            <div className='mx-auto'>
              <div
                // className='flex items-center justify-between mb-4'
                style={{
                  textAlign: relatedSectionDesign?.font_align ? relatedSectionDesign?.font_align : 'center',
                  width: '100%',
                  marginBottom: '20px',
                }}
              >
                <span
                  // style={{ color: colors?.main_title_color }}
                  style={{
                    fontSize: relatedSectionDesign?.font_size ? `${relatedSectionDesign?.font_size}px` : '32px',
                    lineHeight: `${relatedSectionDesign?.font_height}px`,
                    fontWeight: relatedSectionDesign?.font_weight ? relatedSectionDesign?.font_weight : 500,
                    fontStyle: relatedSectionDesign?.font_style,
                    color: relatedSectionDesign?.font_color ? relatedSectionDesign?.font_color : '#111111',
                    textDecoration: relatedSectionDesign?.font_decoration,
                    textTransform: relatedSectionDesign?.font_capitalize,
                    wordBreak: relatedSectionDesign?.font_break,
                    textWrap: relatedSectionDesign?.font_wrap,
                  }}
                  // className='font-medium break-words overflow-hidden'
                >
                  {productPageData?.relateProductTitle}
                </span>
              </div>

              <div className='mx-auto product-slider'>
                <div className='pb-20'>
                  <Slider
                    slidesToShow={
                      window?.innerWidth > 1200
                        ? 4
                        : window?.innerWidth > 991 && window?.innerWidth <= 1200
                        ? 3
                        : window?.innerWidth > 768 && window?.innerWidth <= 991
                        ? 2
                        : window?.innerWidth > 575 && window?.innerWidth <= 768
                        ? 2
                        : window?.innerWidth > 100 && window?.innerWidth <= 640
                        ? 1
                        : 1
                    }
                    centerPadding='0'
                    autoplay={true}
                    autoplaySpeed={3000}
                    pauseOnHover={true}
                    swipeToSlide={true}
                    slidesToScroll={1}
                    infinite={reletedProduct?.length > 1 ? true : false}
                    prevArrow={
                      <div className='slick-prev'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={12}
                          height={20}
                          viewBox='0 0 12 20'
                          id='left'
                        >
                          <g fill='none' fillRule='evenodd'>
                            <g
                              fill={`${colors?.primary_button_color}`}
                              fillRule='nonzero'
                              transform='translate(-14 -8)'
                            >
                              <g transform='translate(14 8)'>
                                <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    }
                    nextArrow={
                      <div className='slick-next'>
                        <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
                          <path
                            fill={`${colors?.primary_button_color}`}
                            d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
                          />
                        </svg>
                      </div>
                    }
                  >
                    {reletedProduct.map((elem, index) => {
                      let discount = Math.round(elem.discount_percentage);
                      const date = new Date();
                      const todayDate = date.toISOString();
                      return (
                        <div className='block px-3 relative"' key={index}>
                          <div className='block productHover relative'>
                            {elem.stock_status == 'out_of_stock' ? (
                              <div className='cursor-pointer h-[416px] grayscale'>
                                <img
                                  className='w-full h-full max-h-full min-w-full object-cover object-center grayscale lg:hover:grayscale'
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem.image || NOPHOTO_IMAGE}
                                  alt={elem.product_name}
                                  width={300}
                                  height={400}
                                />
                                {elem.stock_status == 'out_of_stock' && (
                                  <div className='outofstock absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-base w-[100px] h-[100px] flex items-center justify-center p-5 bg-black bg-opacity-80 rounded-full text-white'>
                                    {t('lang_Sold_Out')}
                                  </div>
                                )}
                                {!elem?.is_dummy && token && !clientInfo?.catalog_mode && (
                                  <div className='addtocart w-full'>
                                    <button
                                      aria-label='notifyme'
                                      type='button'
                                      onMouseEnter={(e) => setIsHovered(elem?._id)}
                                      onMouseLeave={(e) => setIsHovered('')}
                                      style={{
                                        color:
                                          isHovered === elem?._id
                                            ? colors?.secondary_button_text_color
                                            : colors?.primary_button_text_color,
                                        backgroundColor:
                                          isHovered === elem?._id
                                            ? colors?.secondary_button_color
                                            : colors?.primary_button_color,
                                      }}
                                      className='w-full block py-3 px-3 text-sm'
                                    >
                                      {t('lang_Notify_Me')}
                                    </button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className='cursor-pointer'>
                                <img
                                  className='w-full h-full max-h-full min-w-full object-center'
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem.image || NOPHOTO_IMAGE}
                                  alt={elem.product_name}
                                  style={{ objectFit: relatedSectionDesign?.fit }}
                                />
                              </div>
                            )}
                            <div className='pt-2 w-full'>
                              <div className='w-full'>
                                <p className='line-clamp-2'>{elem?.product_name}</p>
                                {elem?.average_rating > 0 && (
                                  <Rating
                                    emptyIcon={
                                      <StarFill
                                        fontSize='inherit'
                                        style={{
                                          color: colors?.secondary_button_color,
                                          opacity: 0.55,
                                        }}
                                      />
                                    }
                                    style={{ color: colors?.primary_button_text_color }}
                                    name='read-only'
                                    size='small'
                                    precision={0.5}
                                    value={elem?.average_rating}
                                    readOnly
                                  />
                                )}
                              </div>
                              {elem?.selling_price !== elem?.mrp ? (
                                <p
                                  className='text-base font-medium flex items-center'
                                  style={{ color: colors?.price_color }}
                                >
                                  {FormatAmount(elem?.selling_price)}
                                  {/* <span style={{ borderColor: borderColor }} className="border-t w-[18px] inline-block mx-1"></span> */}
                                  <del
                                    style={{ color: colors?.compare_price_color }}
                                    className='text-base font-[400]'
                                  >
                                    {FormatAmount(elem?.mrp)}
                                  </del>
                                </p>
                              ) : (
                                <p
                                  style={{ color: colors?.price_color }}
                                  className='text-base font-medium'
                                >
                                  {FormatAmount(elem?.mrp)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RelatedProduct;
