import { FormControlLabel } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Loader from '../Loader/Loader';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import ReactDragListView from 'react-drag-listview';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const AddPaymentOption = () => {
  const [isNotification, setIsNotification] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [isActive, setIsActive] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  // Get Shipping Methods
  const PaymentInfo = [
    {name: 'Cash On Delivery', image: 'https://pub-032c6d43db934ca6b027735e13fb15f5.r2.dev/100/product/rupee.webp'},
    // 'UPI',
    // 'PhonePe',
    // 'GooglePay',
    // 'PayPal',
    {name:'Razorpay', image: 'https://pub-032c6d43db934ca6b027735e13fb15f5.r2.dev/100/product/cu1m0xfonul76zeaw0idkq0m.webp'},
    // 'Net Banking',
    // 'Debit/Credit Card',
  ];

  //get Payment method by User
  const [Payments, setPaymentData] = useState();
  const GetAllPaymentMethods = async () => {
    try {
      const getPaymentMethods = await API.get(
        `/admin/paymentmethod/getPaymentMethods?page=1&limit=10&order_by=ASC`,
      );
      const result = decryptData(getPaymentMethods?.data);
      setPaymentData(result?.data?.records);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'payment' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetAllPaymentMethods();
  }, []);

  // SelectPayment from radio
  const SelectPayment = async (index) => {
    try {
      if (!Payments) {
        const payload = encryptData({
          payment_method_name: PaymentInfo[index].name,
          payment_logo: PaymentInfo[index].image,
        });
        const SP = await API.post('/admin/paymentmethod/addPaymentMethod', payload);
      } else {
        const data =
          Payments && Payments?.find((x) => x.payment_method_name === PaymentInfo[index].name);
        if (!data) {
          const payload = encryptData({
            payment_method_name: PaymentInfo[index].name,
            payment_logo: PaymentInfo[index].image,
          });
          const SP = await API.post('/admin/paymentmethod/addPaymentMethod', payload);
        } else {
          setIsNotification(true);
          setNotificationMsg('this method is Already Added in Your Payments');
          setSeverity('warn');
        }
      }
      GetAllPaymentMethods();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // UpdatePayment from radio
  const UpdatePayment = async (id, status, payment_method_name) => {
    let body;
    if (status) {
      body = {
        status: status,
      };
    } else {
      body = {
        default: true,
      };
    }
    try {
      setIsActive(true);
      const payload = encryptData(body);
      const SP = await API.put(`/admin/paymentmethod/updatePaymentMethod/${id}`, payload);
      if (SP.status === 200 || SP.status === 304) {
        GetAllPaymentMethods();
        if (!status?.length) {
          setIsNotification(true);
          setNotificationMsg(`${payment_method_name} is set to default`);
          setSeverity('success');
        } else {
          setIsNotification(true);
          setNotificationMsg(
            status === 'enabled'
              ? `${payment_method_name} is enabled`
              : `${payment_method_name} is disabled`,
          );
          setSeverity('success');
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/paymentmethod/updatePaymentMethod/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(Payments);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setPaymentData(items);
  //   sortProduct(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...Payments];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setPaymentData(newItems);
      sortProduct(toIndex, paymentId);
      setPaymentId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const addpaymentcolumns = [
    {
      name: t('lang_Payment_Providers'),
      cell: (row) => <div className='flex items-center justify-between gap-1'>
        <span className=''><img height={30} width={30} src={row?.image ?? NOPHOTO_IMAGE} /></span>
        <span className=''>{row.name}</span>
      </div>,
      sortable: true,
    },
    {
      name: <div className='flex w-full justify-end pe-1'>{t('lang_action')}</div>,
      width: '150px',
      cell: (row, index) => (
        <div className='flex w-full justify-end'>
          {!Payments?.find((x) => x.payment_method_name === row.name) ? (
            <>
              <button
                href='#'
                className='btn px-4 py-2 font-medium rounded-[6px] text-white text-xs'
                onClick={() => SelectPayment(index)}
              >
                {t('lang_Install')}
              </button>
            </>
          ) : (
            <>
              <button disabled className='bulk-btn cursor-not-allowed'>
                {t('lang_Installed')}
              </button>
            </>
          )}
        </div>
      ),
    },
  ];
  const enabledPaymentMethods = Payments?.map((record) => record.payment_method_name);
  const sortedPaymentInfo = PaymentInfo?.sort((a, b) => {
    const indexA = enabledPaymentMethods?.indexOf(a);
    const indexB = enabledPaymentMethods?.indexOf(b);
    return indexB - indexA;
  });

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className=' h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Payment_Methods'), path: '/payment-methods' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Payment_Providers')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/settings-and-options/payment-methods'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        {/* Add Payment Options */}
        <div className='w-full'>
          {Payments?.length > 0 ? (
            <div className='w-full mb-5 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <td className='w-full px-3'>{t('lang_Payment_Providers')}</td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                      {t('lang_status')}
                    </td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                      {t('lang_default')}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <ReactDragListView {...dragProps}>
                    {Payments?.map((elem, index) => (
                      <tr
                        style={{
                          // boxShadow: snapshot.isDragging
                          //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                          //   : 'none',
                          background: 'white',
                        }}
                        key={index}
                        onDragStart={() => setPaymentId(elem?._id)}
                      >
                        <td className='w-full px-3'>
                          <div className='flex items-center gap-1'>
                          <span className=''><img src={elem?.payment_logo ?? NOPHOTO_IMAGE} height={30} width={30} /></span>
                          <span className=''>{elem.payment_method_name}</span>
                          </div>
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                          {elem?.default ? (
                            <label className='relative flex justify-end w-full'>
                              <FormControlLabel
                                checked={elem.status === 'enabled'}
                                control={<MuiSwitch className={`${isActive || elem.default && 'cursor-not-allowed'}`} />}
                                disabled={isActive || elem.default}
                                onChange={() => {
                                  setIsNotification(true);
                                  setNotificationMsg('You can not disabled default method');
                                  setSeverity('warning');
                                }}
                              />
                            </label>
                          ) : (
                            <>
                              <label className='relative flex justify-end w-full'>
                                <FormControlLabel
                                  checked={elem.status === 'enabled'}
                                  control={<MuiSwitch className={`${isActive || elem.default && 'cursor-not-allowed'}`} />}
                                  disabled={isActive || elem.default}
                                  onChange={() => {
                                    UpdatePayment(
                                      elem._id,
                                      elem.status === 'enabled' ? 'disabled' : 'enabled',
                                      elem?.payment_method_name,
                                    );
                                  }}
                                />
                              </label>
                            </>
                          )}
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-center'>
                          {elem.status === 'disabled' ? (
                            <></>
                          ) : (
                            <>
                              <input
                                id='default-radio-1'
                                type='radio'
                                name='default-radio'
                                checked={elem.default}
                                className='w-4 h-4 inline-block'
                                onChange={() =>
                                  UpdatePayment(elem._id, '', elem.payment_method_name)
                                }
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </ReactDragListView>
                </tbody>
              </table>
            </div>
          ) : (
            <NoRecords />
          )}
          <h6 className='text-xl mb-2'>{t('lang_Recommended_Payment_Providers')}</h6>
          <div className='w-full inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              className='order-table'
              columns={addpaymentcolumns}
              data={sortedPaymentInfo}
              selectableRowsHighlight
              highlightOnHover
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentOption;