import React from 'react';
import EditRatingStyleContainer from './EditRatingContainer';

const EditRatingStyleSection = ({
  previewImage,
  ratingDesign,
  setRatingDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditRatingStyleContainer
        ratingDesign={ratingDesign}
        setRatingDesign={setRatingDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditRatingStyleSection;
