import React, { useState } from 'react';
import API from '../../API';
import { useEffect } from 'react';
import Notification from '../Notification';
import { ChevronDown, Trash2 } from 'feather-icons-react/build/IconComponents';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const CustomSmtp = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [smtpData, setSmtpData] = useState({
    user_name: '',
    user_email: '',
    SMTP_password: '',
    port: '',
    host: '',
  });
  const [isAdd, setIsAdd] = useState(false);
  const [isRemove, setIsRemove] = useState();
  const getSmtpData = async () => {
    try {
      const ApiCall = await API.get(`/admin/client/GetSmtp`);
      const result = decryptData(ApiCall?.data);
      if (result?.data) {
        setIsAdd(false);
        setSmtpData({
          user_name: result?.data?.credentials?.user_name,
          user_email: result?.data?.credentials?.user_email,
          SMTP_password: result?.data?.credentials?.SMTP_password,
          port: result?.data?.credentials?.port,
          host: result?.data?.credentials?.host,
        });
        setIsRemove(true);
      } else {
        setIsAdd(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const addSmtpData = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (isAdd === true) {
      try {
        const payload = encryptData({
          credentials: smtpData,
        });
        await API.post(`/admin/client/AddSmtp`, payload);
        setIsNotification(true);
        setNotificationMsg('STMP info added successfully!');
        setSeverity('success');
        getSmtpData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const payload = encryptData({
          credentials: smtpData,
        });
        await API.put(`/admin/client/UpdateSmtp`, payload);
        setIsNotification(true);
        setNotificationMsg('STMP info updated successfully!');
        setSeverity('success');
        getSmtpData();
      } catch (error) {
        console.log(error);
      }
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    getSmtpData();
  }, []);
  const handleRemoveSTMP = async (e) => {
    e.preventDefault();
    try {
      const ApiCall = await API.delete(`/admin/client/deleteSmtp`);
      getSmtpData();
      setIsNotification(true);
      setNotificationMsg('STMP info removed successfully!');
      setSeverity('success');
      setSmtpData({
        user_name: '',
        user_email: '',
        SMTP_password: '',
        port: '',
        host: '',
      });
      setIsRemove(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Custom_SMTP')}
            <a
              target='blank'
              href='https://help.shopeasy.ai/account/how-to-use-smtp-mail-service-on-shopeasyai'
              className='how-it-works'
            >
              <QuestionCircleFill className='me-2' />
              {t('lang_how_to_use')}
            </a>
            <p className='text-xs table-text font-normal'>
              {t('lang_Setup_your_own_SMTP_server_settings')}
            </p>
          </div>
        </div>
          <>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='w-full mx-auto pb-4 sm:pb-[25px] rounded-[6px]'
              onSubmit={(e) => addSmtpData(e)}
            >
              <div className='mb-4'>
                <label htmlFor='title'>
                  {t('lang_SMTP_Email_Host')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  type='text'
                  value={smtpData?.host}
                  onChange={(e) => setSmtpData({ ...smtpData, host: e.target.value })}
                  name='host'
                  placeholder='Enter SMTP host'
                  required
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='title'>
                  {t('lang_SMTP_Port_TLS')} <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  type='number'
                  value={smtpData?.port}
                  onChange={(e) => setSmtpData({ ...smtpData, port: e.target.value })}
                  name='host'
                  placeholder='Enter SMTP port (usually 587)'
                  required
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='title'>
                  {t('lang_SMTP_Username')} <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  type='text'
                  value={smtpData?.user_name}
                  onChange={(e) => setSmtpData({ ...smtpData, user_name: e.target.value })}
                  name='host'
                  placeholder='Enter SMTP username'
                  required
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='title'>
                  {t('lang_SMTP_Password')} <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  type='password'
                  value={smtpData?.SMTP_password}
                  onChange={(e) => setSmtpData({ ...smtpData, SMTP_password: e.target.value })}
                  name='host'
                  placeholder='Enter password'
                  required
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='title'>
                  {t('lang_Custom_Email_Address')} <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  type='email'
                  value={smtpData?.user_email}
                  onChange={(e) => setSmtpData({ ...smtpData, user_email: e.target.value })}
                  name='host'
                  placeholder='Enter email address'
                  required
                />
              </div>
              <div className='flex items-center justify-between'>
                {smtpData?.host === '' ||
                smtpData?.port === '' ||
                smtpData?.SMTP_password === '' ||
                smtpData?.user_email === '' ||
                smtpData?.user_name === '' ? (
                  <div className='w-full flex justify-end'>
                    <button
                      type='button'
                      className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                    >
                      <span>{isAdd ? 'Verify and connect' : 'Update STMP info'}</span>
                    </button>
                  </div>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[166px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <div className='w-full flex justify-end'>
                    <button
                      type='submit'
                      className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                    >
                      <span>{isAdd ? 'Verify and connect' : 'Update STMP info'}</span>
                    </button>
                  </div>
                )}
                {isRemove && (
                  <button
                    type='button'
                    onClick={(e) => handleRemoveSTMP(e)}
                    className='inline-flex items-center text-red-500 text-[13px] font-medium hover:underline transition duration-300'
                  >
                    <Trash2 className='w-4 h-4 me-1' /> {t('lang_Remove_STMP')}
                  </button>
                )}
              </div>
            </form>
          </>
      </div>
    </>
  );
};

export default CustomSmtp;
