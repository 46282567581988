import React, { useEffect, useState } from 'react';
import API from '../../API';
import { Tooltip, Dialog, Backdrop, CircularProgress } from '@mui/material';
import { ChevronDown, Edit, Trash, X } from 'feather-icons-react/build/IconComponents';
import Notification from '../Notification';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { Transition } from '../../controllers/Transition';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { useContext } from 'react';
import { PlanPermission } from '../../App';
import EditMetaField from './EditMetaField';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const AddMetaFields = () => {
  const [metaData, setMetaData] = useState({
    path: 'home',
    meta_title: '',
    meta_description: '',
  });
  const [editMetaData, setEditMetaData] = useState({
    path: 'home',
    meta_title: '',
    meta_description: '',
  });
  const { planPermission, setPlanPermission } = useContext(PlanPermission);
  const [AiLoader, setAiLoader] = useState(false);
  const [issDescription, setIsDescription] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [storeDetail, setStoreDetail] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [editId, seteditId] = useState('');
  const [metaTags, setMetaTags] = useState();
  const [openEditMeta, setOpenEditMeta] = useState(false);
  const handleChange = (e) => {
    setMetaData({ ...metaData, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEdit) {
      const payload = encryptData(editMetaData);
      let ApiCall = await API.put(`/admin/metatag/updateMetaTags/${editId}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setEditMetaData({
          path: '',
          meta_title: '',
          meta_description: '',
        });
        handleGetMeta();
        setIsNotification(true);
        setNotificationMsg('Meta Field updated succesfully!');
        setSeverity('success');
        seteditId('');
        setOpenEditMeta(false);
        setIsEdit(false);
      }
    } else {
      try {
        const payload = encryptData(metaData);
        let ApiCall = await API.post(`/admin/metatag/addMetaTags`, payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setMetaData({
            path: '',
            meta_title: '',
            meta_description: '',
          });
          handleGetMeta();
          setIsNotification(true);
          setNotificationMsg('Meta Field added succesfully!');
          setSeverity('success');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(`You already added meta for ${metaData?.path} page!`);
        setSeverity('error');
        setMetaData({
          path: '',
          meta_title: '',
          meta_description: '',
        });
      }
    }
  };
  useEffect(() => {
    handleGetMeta();
  }, []);
  const handleGetMeta = async () => {
    try {
      let ApiCall = await API.get(`/admin/metatag/getAllMetaTags`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        setMetaTags(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'category' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleDelete = async () => {
    try {
      let ApiCall = await API.post(`/admin/metatag/deleteMetaTags/${deleteId}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        handleGetMeta();
        setDeleteId();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleEdit = async (elem) => {
    setIsEdit(true);
    setOpenEditMeta(true);
    seteditId(elem?._id);
    setEditMetaData({
      path: elem?.path,
      meta_title: elem?.meta_title,
      meta_description: elem?.meta_description,
    });
  };
  const [err, setErr] = useState();
  const generateMetaData = async (data) => {
    if (isEdit) {
      if (editMetaData?.path?.length === 0) {
        setErr(true);
      } else {
        setErr(false);
        setAiLoader(true);
        try {
          const payload = encryptData({
            name: data,
          });
          let ApiCall = await API.post('/admin/ai/meta_title_pages', payload);
          const result = decryptData(ApiCall?.data);
          setEditMetaData({ ...editMetaData, meta_title: result?.data });
          setOpenEditMeta(true);
        } catch (error) {
          if (error?.response?.status === 429) {
            setIsNotification(true);
            setNotificationMsg(t('lang_Hit_chatgpt_limit'));
            setSeverity('warning');
          } else {
            setIsNotification(true);
            setNotificationMsg(error?.response?.data?.message);
            setSeverity('error');
          }
        } finally {
          setAiLoader(false);
        }
      }
    } else {
      if (metaData?.path?.length === 0) {
        setErr(true);
      } else {
        setErr(false);
        setAiLoader(true);
        try {
          const payload = encryptData({
            name: data,
          });
          let ApiCall = await API.post('/admin/ai/meta_title_pages', payload);
          const result = decryptData(ApiCall?.data);
          setMetaData({ ...metaData, meta_title: result?.data });
        } catch (error) {
          if (error?.response?.status === 429) {
            setIsNotification(true);
            setNotificationMsg(t('lang_Hit_chatgpt_limit'));
            setSeverity('warning');
          } else {
            setIsNotification(true);
            setNotificationMsg(error?.response?.data?.message);
            setSeverity('error');
          }
        } finally {
          setAiLoader(false);
        }
      }
    }
  };
  const handleSubmitAiData = async (e) => {
    e.preventDefault();
    try {
      setAiLoader(true);
      setIsDescription(false);
      const payload = encryptData({
        name: storeDetail,
      });
      let ApiCall = await API.post('/admin/ai/meta_description_pages', payload);
      if (isEdit) {
        const result = decryptData(ApiCall?.data);
        setEditMetaData({ ...editMetaData, meta_description: result?.data });
        setOpenEditMeta(true);
      } else {
        const result = decryptData(ApiCall?.data);
        setMetaData({ ...metaData, meta_description: result?.data });
      }
      setStoreDetail('');
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiLoader(false);
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Meta Field'
        subheading='Do you really want to delete this meta field from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {AiLoader && (
        <>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={AiLoader}
            className='flex flex-col'
          >
            <CircularProgress color='inherit' />
            <p>{t('lang_Please_Wait')}</p>
          </Backdrop>
        </>
      )}
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
        <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
          <div className='w-full'>
            <div className='text-base font-medium text-primary'>
            {t('lang_Meta_Fields')}
            <p className='text-xs table-text font-normal'>{t('lang_Add_meta_fields_for_pages')}</p>
          </div>
          </div>
          <>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='w-full text-sm mx-auto pb-[25px] rounded-[6px]'
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className='mb-4'>
                <label htmlFor='instagram'>{t('lang_Select_Page')}</label>
                <div className='relative'>
                  <select
                    value={metaData?.path}
                    name='path'
                    onChange={(e) => handleChange(e)}
                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                    required
                  >
                    <option value='home'>{t('lang_Home_Page')}</option>
                    <option value='login'>{t('lang_Login_Page')}</option>
                    <option value='register'>{t('lang_Register_Page')}</option>
                    <option value='shop'>{t('lang_Shop_Page')}</option>
                    <option value='blog'>{t('lang_Blog_Page')}</option>
                    <option value='portfolio'>{t('lang_Portfolio_page')}</option>
                    <option value='faq'>{t('lang_FAQ_page')}</option>
                    <option value='contactUs'>{t('lang_Contact_page')}</option>
                    <option value='cart'>{t('lang_Cart_page')}</option>
                    <option value='wishlist'>{t('lang_Wishlist_page')}</option>
                    <option value='order'>{t('lang_Orders_page')}</option>
                    <option value='checkout'>{t('lang_Checkout_page')}</option>
                    <option value='account'>{t('lang_Account_page')}</option>
                    <option value='announcement'>{t('lang_Announcements_page')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='instagram' className='flex items-center justify-between'>
                    {t('lang_meta_title')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(metaData?.path);
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_title')}
                  </span>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='meta_title'
                  value={metaData?.meta_title}
                  name='meta_title'
                  placeholder='Enter meta title'
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='instagram' className='flex items-center justify-between'>
                    {t('lang_meta_description')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => setIsDescription(true)}
                  >
                    <BardSvg />
                    {t('lang_get_meta_description')}
                  </span>
                </div>
                <textarea
                  className='w-full px-3 h-24 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  value={metaData?.meta_description}
                  id='meta_description'
                  name='meta_description'
                  placeholder='Enter meta description'
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              <div className='w-full flex justify-end'>
                <button
                  type='submit'
                  className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
                >
                  {t('lang_Add_Meta_field')}
                </button>
              </div>
            </form>
            {metaTags?.records?.length > 0 && (
              <ul className='mx-4 sm:mx-[25px] pb-4 sm:pb-[25px]'>
                <hr className='border-gray-200 my-4'></hr>
                <h6 className='text-lg font-medium my-4'>{t('lang_Meta_Fields')}</h6>
                {metaTags?.records?.map((elem, index) => {
                  return (
                    <li key={index} className='border main-border-color rounded-[6px] p-3 mt-4'>
                      <div className='flex table-text items-center justify-between'>
                        <div className='flex  items-center space-x-8'>
                          <p>
                            <span className='font-medium capitalize'>{t('lang_Page')} :</span>
                            <span className='ms-2 capitalize'>{elem.path}</span>
                          </p>
                        </div>
                        <div className='flex items-center space-x-4'>
                          <Tooltip title='Edit' arrow>
                            <button onClick={() => handleEdit(elem)}>
                              <Edit className='w-[17px] h-[17px] text-black-500' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirmationPopup(true);
                                setDeleteId(elem?._id);
                              }}
                            >
                              <Trash className='w-[17px] h-[17px] text-red-500' />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      <p className='mt-1 table-text text-left'>
                        <span className='font-medium capitalize'>{t('lang_meta_title')} :</span>
                        <span className='ms-2'>{elem.meta_title}</span>
                      </p>
                      <p className='mt-1 table-text text-left'>
                        <span className='font-medium capitalize'>{t('lang_meta_description')} :</span>
                        <span className='ms-2'>{elem.meta_description}</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        </div>
      <Dialog
        open={issDescription}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsDescription(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between text-primary border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium'>{t('lang_Generate_page_meta_description')}</h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setIsDescription(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex items-center' htmlFor='SKU ID'>
                  {t('lang_Store_details')}
                  <Tooltip
                    title='Add store details for better meta description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={storeDetail}
                  name='features'
                  onChange={(e) => setStoreDetail(e.target.value)}
                  className='w-full border main-border-color rounded-[6px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. The store should be easy to navigate and visually appealing, with high-quality product images and detailed descriptions.'
                  required
                ></textarea>
              </div>
              <button type='submit' className='btn px-5 py-2 text-white rounded-[6px] capitalize'>
                {t('lang_done')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
      {openEditMeta && (
        <EditMetaField
          openEditMeta={openEditMeta}
          setOpenEditMeta={setOpenEditMeta}
          generateMetaData={generateMetaData}
          editMetaData={editMetaData}
          setEditMetaData={setEditMetaData}
          setIsDescription={setIsDescription}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default AddMetaFields;
