import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import { FormControlLabel } from '@mui/material';

const StaticSecondTestimonialSection = ({
  section_id,
  section_type,
  saveElementorData,
  btnLoading,
  handleSecondTestimonialDescriptionChange,
  descriptionContent,
  sectionContent,
  headingLevel,
  handleSecondTestimonialTagChange,
  handleSecondTestimonialTitleValueChange,
  sectionRoleContent,
  handleSecondTestimonialRoleValueChange,
  headingLevelRole,
  handleSecondTestimonialRoleTagChange,
  sectionIconContent,
  sectionIconFillContent,
  handleSecondTestimonialIconFillValueChange,
  handleSecondTestimonialIconChange,
  handleSettingImageUpload
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [roleValue, setRoleValue] = useState(sectionRoleContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [selectedHeadingLevelRole, setSelectedHeadingLevelRole] = useState(
    headingLevelRole || 'h2',
  );
  const [iconValue, setIconValue] = useState(sectionIconContent);
  const [iconFillValue, setIconFillValue] = useState(sectionIconFillContent);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setGroupTitle(sectionContent);
    setRoleValue(sectionRoleContent);
    setShortDescription(descriptionContent);
    setIconValue(sectionIconContent);
    setIconFillValue(sectionIconFillContent);
  }, [
    sectionContent,
    sectionRoleContent,
    descriptionContent,
    sectionIconContent,
    sectionIconFillContent,
  ]);

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleSecondTestimonialTagChange(section_id, newTag);
  };

  const handleHeadingTagChangeRole = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevelRole(newTag);
    handleSecondTestimonialRoleTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSecondTestimonialDescriptionChange(section_id, value);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSecondTestimonialTitleValueChange(section_id, newValue);
  };

  const onContentChangeRole = (e) => {
    const newValue = e.target.value;
    setRoleValue(newValue);
    handleSecondTestimonialRoleValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSecondTestimonialTitleValueChange(section_id, value);
  };

  const handleIconFill = (e) => {
    const { checked } = e.target;
    setIconFillValue(checked);
    handleSecondTestimonialIconFillValueChange(section_id, checked);
  };

  const handleIconSelect = (iconComponent) => {
    setIconValue(iconComponent);
    handleSecondTestimonialIconChange(section_id, iconComponent);
    setIsOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
        <div className='mt-2'>
          <label>Role</label>
          <textarea
            className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
            placeholder='Add your heading text here...'
            value={roleValue}
            onChange={onContentChangeRole}
          />
          <label>Role HTMLTag</label>
          <div className='relative'>
            <select
              className='w-full mt-2'
              value={selectedHeadingLevelRole}
              onChange={handleHeadingTagChangeRole}
            >
              {dropDownValue?.map((obj) => (
                <option key={obj?.value} value={obj?.value}>
                  {obj?.label}
                </option>
              ))}
            </select>
            <div className='select-arrow'></div>
          </div>
        </div>
        <div className='w-full mb-4'>
          <label>Description</label>
          <ReactQuill
            modules={module}
            ref={ShortquillRef}
            className='w-full h-auto table-text rounded-[6px] mt-2'
            theme='snow'
            name='description'
            // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
            value={shortdescription}
            onChange={handleShortDescQuillChange}
            placeholder='Enter description'
            onBlur={() => {
              setMeta((prevMeta) => ({
                ...prevMeta,
                meta_description:
                  shortdescription
                    ?.replace(/<[^>]*>/g, ' ')
                    ?.split(' ')
                    .slice(0, 50)
                    .join(' ') || '',
              }));
            }}
          />
        </div>

        <div className='mt-2'>
          <label htmlFor='text'>Icon</label>
          <div
            className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {iconValue &&
              (React.isValidElement(iconValue) ? (
                React.cloneElement(iconValue, { width: '100px', height: '100px' })
              ) : (
                <SvgSection svgContent={iconValue} width='100px' height='100px' />
              ))}

            {isHovered && (
              <>
                <button
                  className='absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                  onClick={() => handleIconClick('iconList')}
                >
                  Icon library
                </button>
                <button
                  className='absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                  onClick={() => handleSvgClick()}
                >
                  SVG Icon
                </button>
              </>
            )}
          </div>
        </div>

        <div className='w-full mb-4 mt-2'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>Icon Fill</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={iconFillValue || false}
                  onChange={handleIconFill}
                  name='isFill'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>

      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticSecondTestimonialSection;
