import React from 'react';
import EditVideoStyleContainer from './EditVideoStyleContainer';

const EditVideoStyleSection = ({
  videoDesign,
  setVideoDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  previewImage,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditVideoStyleContainer
        videoDesign={videoDesign}
        setVideoDesign={setVideoDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditVideoStyleSection;
