import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const ThirdStaticStepSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.thirdStepComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_step-3',
  );

  return (
    <div className='w-full'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        return (
          <ReactDragListView {...dragProps}>
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_step-3', 'setting');
              }}
            >
              <div
                class='container mx-auto flex flex-wrap'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                {obj?.content?.item?.map((value, index) => {
                  return (
                    <div class='flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto'>
                      <div class='h-full w-6 absolute inset-0 flex items-center justify-center'>
                        <div class='h-full w-1 bg-gray-200 pointer-events-none'></div>
                      </div>
                      <div class='flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm'>
                        {index + 1}
                      </div>
                      <div class='flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row'>
                        <div class='flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center'>
                          {value?.icon && React.isValidElement(value?.icon) ? (
                            React.cloneElement(value?.icon, {
                              width: '50px',
                              height: '50px',
                              color: '#6366f1',
                            })
                          ) : (
                            <SvgSection
                              svgContent={value?.icon}
                              width={'24px'}
                              height={'24px'}
                              fill={value?.isFill ? '#6366F1' : ''}
                              stroke={value?.isFill ? '#6366F1' : ''}
                            />
                          )}
                        </div>
                        <div class='flex-grow sm:pl-6 mt-6 sm:mt-0'>
                          {value?.htmlTag === 'h1' ? (
                            <h1 class='mb-1'>{value?.title}</h1>
                          ) : value?.htmlTag === 'h2' ? (
                            <h2 class='mb-1'>{value?.title}</h2>
                          ) : value?.htmlTag === 'h3' ? (
                            <h3 class='mb-1'>{value?.title}</h3>
                          ) : value?.htmlTag === 'h4' ? (
                            <h4 class='mb-1'>{value?.title}</h4>
                          ) : value?.htmlTag === 'h5' ? (
                            <h5 class='mb-1'>{value?.title}</h5>
                          ) : value?.htmlTag === 'h6' ? (
                            <h6 class='mb-1'>{value?.title}</h6>
                          ) : value?.htmlTag === 'div' ? (
                            <div class='mb-1'>{value?.title}</div>
                          ) : value?.htmlTag === 'p' ? (
                            <p class='mb-1'>{value?.title}</p>
                          ) : value?.htmlTag === 'span' ? (
                            <span class='mb-1'>{value?.title}</span>
                          ) : (
                            ''
                          )}
                          <span
                            dangerouslySetInnerHTML={{ __html: value?.description || '-' }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </ReactDragListView>
        );
      })}
    </div>
  );
};

export default ThirdStaticStepSection;
