import { Dialog, Skeleton } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOUSER_IMAGE } from '../../Assets/StaticData/StaticImage';
import Loader from '../../Component/Loader/Loader';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { Transition } from '../../controllers/Transition';
import { socket } from '../../utilities/socket';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
function Chat() {
  const [socketConnection, setSocketConnection] = useState(false);
  useEffect(() => {
    if (socket.connected) {
      setSocketConnection(true);
    } else {
      setSocketConnection(false);
    }
  }, [setSocketConnection]);
  const token = localStorage.getItem('token');
  const store_id = localStorage.getItem('store_id');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [loader, setLoader] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  const chatRef = useRef(null);
  const divRef = useRef(null);
  useEffect(() => {
    if (token && socketConnection) {
      socket.emit('addUser', { token });
    }
  }, [token, socketConnection]);

  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [message, setMessage] = useState('');
  const [userMsgs, setUserMsgs] = useState([]);
  const [customer, setCustomer] = useState();
  const [activeChatArray, setActiveChatArray] = useState([]);
  const [endChatArray, setEndChatArray] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [clientData, setClientData] = useState();
  const HandleClientInfo = async () => {
    try {
      const ApiCall = await API.get(`/admin/client/getClient`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setClientData(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const HandleSocket = async () => {
    socket.on('msg_get', async (data) => {
      setUserMsgs((prevChats) => [...prevChats, data]);
    });

    socket.on('msg_receive', async (data) => {
      setUserMsgs((prevChats) => [...prevChats, data]);
    });
    return () => {
      socket.off('msg_get');
      socket.off('msg_receive');
    };
  };
  useEffect(() => {
    chatRef.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  }, [userMsgs]);
  const [staff, setstaff] = useState();
  const [staffDD, setStaffDD] = useState(false);
  const [chatDetails, setChatDetails] = useState();
  const [staffDetails, setStaffDetails] = useState();
  const [nodata, setNodata] = useState(false);
  // Get StaffInfo
  const GetStaff = async () => {
    try {
      if (selectedCustomer?.chatId) {
        let ApiCall = await API.get(`/admin/socketio/GetStaff/${selectedCustomer?.chatId}`);
        if (ApiCall?.data) {
          const result = decryptData(ApiCall?.data)
          setstaff(result);
        }
      }
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    HandleSocket();
  }, [socket]);

  const saveMsg = async () => {
    try {
      if (message && message?.trim() && clientData?.user?._id && selectedCustomer?._id) {
        socket.emit('chat', {
          url: `${process.env.REACT_APP_API_URL || 'http://localhost:8090'
            }/admin/socketio/savechat`,
          message,
          sender: clientData?.user?._id,
          sender_name_for_live: clientData?.user?.name
            ? clientData.user.name
            : clientData?.user?.email,
          customer: selectedCustomer._id,
          headers: {
            token: token,
            storeid: store_id,
            origin: process.env.REACT_APP_ADMIN_URL || 'http://localhost:3000',
          },
        });
      }
      setMessage('');
      if (chatDetails?.data?.end_chat === false) {
        getMsgs();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const getMsg = useCallback(async () => {
    setLoader(true);
    if (nextPage || page === 1) {
      try {
        if (selectedCustomer?._id) {
          const ApiCall = await API.get(
            `/admin/socketio/getchat/${selectedCustomer?._id}?limit=${limit}&page=${page}`,
          );
          if (ApiCall.status === 200 || ApiCall.status === 304) {
            // getMsgs();
            // setChatDetails(ApiCall?.data);
            // setStaffDetails(ApiCall?.data?.data?.assign_staff)
            // setUserMsgs(ApiCall?.data?.data?.chat ? ApiCall?.data?.data?.chat : '');
            const result = decryptData(ApiCall?.data)
            if (page === 1) {
              setUserMsgs(result?.data?.chat);
            } else {
              setUserMsgs((prevChats) => [...result?.data?.chat, ...prevChats]);
            }
            if (result?.data?.chat) {
              setNextPage(true);
            } else {
              setNextPage(false);
            }
          }
        }
      } catch (error) {
        if (error?.response?.status === 882) {
          setPlanPermission({ isPlanPermissions: false, forModule: 'chat' });
        }
        setNodata(true);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setLoader(false);
      }
    }
  }, [page, selectedCustomer]);

  const getMsgs = async () => {
    setDataLoading(true);
    try {
      const ApiCall = await API.get(`/admin/socketio/getchats`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setCustomer(result);
        setActiveChatArray(result?.data.filter((item) => !item?.end_chat));
        setEndChatArray(result?.data.filter((item) => item?.end_chat === true));
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'chat' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const assignStaff = async (userID) => {
    if (!chatDetails?.data?.assign_staff?.includes(userID)) {
      socket.emit('assignStaff', {
        userId: selectedCustomer?._id,
        staff_ids: chatDetails?.data?.assign_staff
          ? [...new Set([...chatDetails?.data?.assign_staff, userID])]
          : [clientData?.user?._id],
      });

      try {
        const payload = encryptData({
          assign_staff: chatDetails?.data?.assign_staff?.length
            ? [...new Set([...chatDetails?.data?.assign_staff, userID])]
            : [clientData?.user?._id],
        })
        let response = await API.put(`/admin/socketio/UpdateChat/${chatDetails?.data?._id}`, payload);
        // response = await response.json();
        const result = decryptData(response?.data)
        setChatDetails(result);
      } catch (error) {
        console.log(error);
      }
      setAssignId();
      setIsAssignChat(false);
      setMessage('');
    }
  };
  const handleEndChat = async () => {
    try {
      const payload = encryptData({
        end_chat: true,
      })
      await API.put(`/admin/socketio/UpdateChat/${selectedCustomer?.chatId}`, payload);
      getMsgs();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    HandleClientInfo();
    getMsgs();
  }, []);

  useEffect(() => {
    getMsg();
    // getMsgs();
  }, [getMsg]);

  useEffect(() => {
    assignStaff(clientData?.user?._id);
  }, [chatDetails]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleScroll = () => {
    if (divRef.current.scrollTop === 0) {
      // setLimit(limit + 20)
      setPage(page + 1);
      // getMsg()
    }
  };

  const navigate = useNavigate();
  const [isAssignChat, setIsAssignChat] = useState(false);
  const [openAssignStaff, setOpenAssignStaff] = useState(false);
  const [assignId, setAssignId] = useState();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <div className='flex items-center space-x-2 pb-[30px]'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Customer_Support'), path: '/customer-support' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_Customer_Support')}
                </h4>
                <a
                  target='blank'
                  href='https://docs.shopeasy.ai/customer-chat'
                  className='how-it-works'
                >
                  <QuestionCircleFill className='me-2' />
                  {t('lang_how_to_use')}
                </a>
              </div>
            </div>
          </div>
          <div className='flex h-full antialiased table-text'>
            <div className='lg:flex h-full w-full overflow-hidden'>
              <div
                className={`${selectedCustomer ? 'hidden lg:block' : 'block'
                  } w-full lg:w-1/4 h-full lg:h-[700px] rounded-[6px] secondary-bg-color border main-border-color`}
              >
                <div className='flex items-center px-4 pt-4'>
                  <div className='w-[60px] h-[60px] bg-gray-500 rounded-full overflow-hidden'>
                    <img
                      className='w-full h-full object-cover object-top'
                      onError={(e) => {
                        e.target.src = NOUSER_IMAGE;
                      }}
                      src={clientData?.user?.profile_picture || NOUSER_IMAGE}
                      alt='logo'
                    />
                  </div>
                  <div className='pl-3'>
                    <p className='text-md '>{t('lang_Welcome')}!</p>
                    <p className='capitalize text-xl text-primary font-medium'>
                      {clientData?.user?.name}
                    </p>
                  </div>
                </div>
                <hr className='main-border-color my-4' />
                <div className='flex flex-col max-h-full overflow-auto'>
                  {activeChatArray?.length > 0 && (
                    <span className='font-medium px-4 flex flex-row items-center justify-between text-xs'>
                      {t('lang_Active_Conversations')} (&nbsp;{activeChatArray?.length}&nbsp;)
                    </span>
                  )}
                  <div className='pb-[135px]'>
                    {dataLoading && !customer ? (
                      <>
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          className='w-full block rounded-[6px] min-h-[60px]'
                        />
                      </>
                    ) : (
                      <>
                        {activeChatArray?.length > 0 && (
                          <div className='flex flex-col px-1 space-y-1 my-2 max-h-full'>
                            {activeChatArray?.map((elem, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedCustomer?._id === elem?.user?._id
                                      ? 'flex w-full items-center cursor-pointer justify-between primary-bg-color rounded-[6px] transition-all duration-150 px-2 py-2'
                                      : 'flex hover:primary-bg-color cursor-pointer justify-between w-full items-center secondary-bg-color rounded-[6px] transition-all duration-150 px-2 py-2'
                                  }
                                  onClick={() => {
                                    setPage(1);
                                    setSelectedCustomer({ ...elem?.user, chatId: elem?._id });
                                  }}
                                >
                                  <div className='flex  table-text items-center'>
                                    <div className=' items-center justify-center uppercase flex overflow-hidden h-10 w-10 bg-gray-300 rounded-full'>
                                      {elem?.user?.profile_picture ? (
                                        <img
                                          onError={(e) => {
                                            e.target.src = NOUSER_IMAGE;
                                          }}
                                          src={elem?.user?.profile_picture || NOUSER_IMAGE}
                                          className='object-cover object-top w-full h-full'
                                          alt='profile picture'
                                        />
                                      ) : (
                                        <span className='font-medium uppercase'>
                                          {elem?.user?.name?.charAt(0)}
                                        </span>
                                      )}
                                    </div>
                                    <div className=' ml-2 text-sm font-medium capitalize'>
                                      {elem?.user?.name}
                                    </div>
                                  </div>
                                  {elem?.unreadCount > 0 && (
                                    <div className='w-5 h-5 text-xs bg-btn inline-flex items-center justify-center rounded-full text-white'>
                                      <span className='text-xs inline-block'>
                                        {elem?.unreadCount}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {endChatArray?.length > 0 && (
                          <>
                            {activeChatArray?.length > 0 && (
                              <hr className='main-border-color border-t' />
                            )}
                            <span className='font-medium px-4 my-2 flex flex-row items-center justify-between text-xs'>
                              {t('lang_End_Conversations')} (&nbsp;{endChatArray?.length}&nbsp;)
                            </span>
                            <div className='flex flex-col px-1 space-y-1 my-2 max-h-full'>
                              {endChatArray?.map((elem, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={
                                      selectedCustomer?._id === elem?.user?._id
                                        ? 'flex w-full items-center cursor-pointer justify-between primary-bg-color rounded-[6px] transition-all duration-150 px-2 py-2'
                                        : 'flex hover:primary-bg-color cursor-pointer justify-between w-full items-center secondary-bg-color rounded-[6px] transition-all duration-150 px-2 py-2'
                                    }
                                    onClick={() => {
                                      setPage(1);
                                      setSelectedCustomer({ ...elem?.user, chatId: elem?._id });
                                    }}
                                  >
                                    <div className='flex  table-text items-center'>
                                      <div className=' items-center justify-center uppercase flex overflow-hidden h-10 w-10 bg-gray-300 rounded-full'>
                                        {elem?.user?.profile_picture ? (
                                          <img
                                            onError={(e) => {
                                              e.target.src = NOUSER_IMAGE;
                                            }}
                                            src={elem?.user?.profile_picture || NOUSER_IMAGE}
                                            className='object-cover object-top w-full h-full'
                                            alt='profile picture'
                                          />
                                        ) : (
                                          <span className='font-medium uppercase'>
                                            {elem?.user?.name?.charAt(0)}
                                          </span>
                                        )}
                                      </div>
                                      <div className=' ml-2 text-sm font-medium capitalize'>
                                        {elem?.user?.name}
                                      </div>
                                    </div>
                                    {elem?.unreadCount > 0 && (
                                      <div className='w-5 h-5 text-xs bg-btn inline-flex items-center justify-center rounded-full text-white'>
                                        <span className='text-xs inline-block'>
                                          {elem?.unreadCount}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {selectedCustomer ? (
                <>
                  <div className='w-full lg:px-2'>
                    <div
                      className='flex flex-col flex-auto h-[700px] rounded-[6px] border main-border-color secondary-bg-color'
                      id='scrollableDiv'
                    >
                      <div className='flex primary-bg-color flex-row items-center h-16 rounded-t-lg  w-full px-4 mb-1 py-02'>
                        {
                          <div className='w-full'>
                            {selectedCustomer?._id !== clientData?.user?._id ? (
                              <div className='flex w-full items-center justify-between'>
                                <div className='flex items-center'>
                                  <button
                                    className='text-black inline-block lg:hidden me-2'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedCustomer();
                                    }}
                                  >
                                    <ArrowLeft className='w-5 h-5' />
                                  </button>
                                  <span className='flex items-center justify-center uppercase h-9 w-9 text-lg overflow-hidden rounded-full bg-primary text-white'>
                                    {selectedCustomer?.profile_picture ? (
                                      <img
                                        src={selectedCustomer?.profile_picture || NOUSER_IMAGE}
                                        onError={(e) => {
                                          e.target.src = NOUSER_IMAGE;
                                        }}
                                        className='object-cover w-full h-full object-top'
                                        alt='image'
                                      />
                                    ) : (
                                      <p>{selectedCustomer?.name?.charAt(0)}</p>
                                    )}
                                  </span>
                                  <p className='ml-2 text-primary capitalize text-left font-medium'>
                                    {selectedCustomer?.name}
                                  </p>
                                </div>
                                <div className='relative space-x-3'>
                                  {!chatDetails?.data?.end_chat && (
                                    <button
                                      className='bulk-btn text-[13px] rounded-[6px]'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleEndChat();
                                      }}
                                    >
                                      {t('lang_End_Chat')}
                                    </button>
                                  )}
                                  <button
                                    className='bulk-btn text-[13px] rounded-[6px]'
                                    onClick={() => {
                                      getMsg();
                                      setOpenAssignStaff(true);
                                    }}
                                  >
                                    {t('lang_View_Assign_Staff')}
                                  </button>
                                  <button
                                    className='bulk-btn text-[13px] rounded-[6px]'
                                    onClick={() => {
                                      GetStaff();
                                      setStaffDD(!staffDD);
                                      setIsAssignChat(true);
                                    }}
                                  >
                                    {t('lang_Assign_Chat')}
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                      </div>
                      {loader ? (
                        <Loader />
                      ) : (
                        <div
                          className='flex flex-col h-full overflow-x-auto mb-4 px-3'
                          ref={divRef}
                          onScroll={handleScroll}
                        >
                          {/* <div className='flex flex-col h-full'>
                                <div className='w-full'>
                                  <div className='flex justify-between rounded-lg w-full'>
                                    <div className='w-full'>
                                      <div className='w-full h-full flex flex-col justify-end px-3' ref={chatRef} > */}
                          {userMsgs?.length > 0 ? (
                            userMsgs?.map((elem, index) => {
                              return selectedCustomer?._id === elem?.sender ||
                                selectedCustomer?._id === elem?.sender?._id ? (
                                <div key={index} className='col-start-1 col-end-8 rounded-lg'>
                                  <div className='flex whitespace-pre-wrap break-words flex-row items-center'>
                                    <div className='relative max-w-[80%] mb-1.5 text-sm primary-bg-color py-2 px-4  rounded-t-xl rounded-br-xl'>
                                      <span>{elem.message}</span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div key={index} className='flex flex-col justify-end'>
                                  <span className='max-w-[80%] w-auto mt-2 ml-auto text-sm primary-bg-color py-2 px-4 rounded-t-xl rounded-bl-xl whitespace-pre-wrap break-words'>
                                    {elem.message}
                                  </span>
                                  <p className='flex justify-end text-[11px]'>
                                    {elem?.sender?.name
                                      ? elem.sender.name
                                      : elem?.sender?.email
                                        ? elem.sender.email
                                        : elem?.sender_name_for_live
                                          ? elem.sender_name_for_live
                                          : ''}
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          {/* </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                        </div>
                      )}
                      <div className='flex  flex-row items-center h-auto p-2 primary-bg-color w-full'>
                        <div className='relative flex w-full items-center rounded-[6px] overflow-hidden'>
                          <textarea
                            type='text'
                            className='flex items-center w-full max-h-[500px] px-5 py-2 outline-none'
                            name='message'
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                saveMsg();
                              }
                            }}
                            placeholder='Type your message'
                          />
                        </div>

                        <div className='ml-4 mr-1'>
                          <button
                            className='flex items-center justify-center btn rounded-full text-white h-10 w-10'
                            onClick={() => saveMsg()}
                          >
                            <span className=''>
                              <svg
                                className='w-[17px] h-[17px] transform rotate-90 -mt-px'
                                fill='none'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth={2}
                                  d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='flex items-center justify-center w-full h-[700px]'></div>
              )}
            </div>
          </div>
          <Dialog
            open={isAssignChat}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setIsAssignChat(false);
            }}
            aria-describedby='alert-dialog-slide-description customer-popup'
          >
            <div className='secondary-bg-color w-full lg:w-[700px] border main-border-color  space-y-4 rounded-[6px] p-5'>
              <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
                <h6 className='font-medium  text-xl'>{t('lang_Select_Staff')}</h6>
                <button
                  className='close-btn'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAssignChat(false);
                  }}
                >
                  <X className='w-4 h-4' />
                </button>
              </div>
              {staff?.data?.length > 0 ? (
                <div className='max-h-[450px] overflow-y-auto divide-y'>
                  {staff?.data?.map((elem) => {
                    return (
                      <label className='w-full table-text cursor-pointer main-border-color flex p-3 items-center space-x-4'>
                        <input
                          type='radio'
                          name='customer'
                          checked={elem?._id === assignId}
                          className='w-4 h-4'
                          value={elem?._id}
                          onChange={() => setAssignId(elem?._id)}
                        />
                        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-sm'>
                          <span>{elem?.name || '-'}</span>
                          <span>{elem?.email}</span>
                        </div>
                      </label>
                    );
                  })}
                </div>
              ) : (
                <p className='text-center w-full text-base'>{t('lang_No_Data_Found')}</p>
              )}
              {staff?.data?.length > 0 && (
                <button
                  className='btn text-sm text-white'
                  onClick={(e) => {
                    e.preventDefault();
                    assignStaff(assignId);
                  }}
                >
                  {t('lang_Assign_Chat')}
                </button>
              )}
            </div>
          </Dialog>
          <Dialog
            open={openAssignStaff}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenAssignStaff(false)}
            aria-describedby='alert-dialog-slide-description customer-popup'
          >
            <div className='secondary-bg-color w-full lg:w-[700px] border main-border-color  space-y-4 rounded-[6px] p-5'>
              <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
                <h6 className='font-medium text-xl'>{t('lang_View_Assign_Staff')}</h6>
                <button
                  className='close-btn'
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenAssignStaff(false);
                  }}
                >
                  <X className='w-4 h-4' />
                </button>
              </div>
              {staffDetails?.length > 0 ? (
                <div className='max-h-[450px] overflow-y-auto divide-y'>
                  {staffDetails?.map((item, index) => {
                    return (
                      <labe className='w-full table-text main-border-color cursor-pointer flex p-3 items-center space-x-4'>
                        <div
                          key={index}
                          className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-sm'
                        >
                          <span>{item?.name || '-'}</span>
                          <span>{item?.email}</span>
                        </div>
                      </labe>
                    );
                  })}
                </div>
              ) : (
                <p className='text-center w-full text-base'>{t('lang_No_Data_Found')}</p>
              )}
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default Chat;
