import { Tooltip } from '@mui/material';
import { Edit, Trash } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
function AddCustomField() {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [fieldData, setFieldData] = useState({
    field_name: '',
    field_type: 'file',
  });
  const handleChange = (e) => {
    setFieldData({ ...fieldData, [e.target.name]: e.target.value });
  };
  const [clientInfo, setClientInfo] = useState();
  const GetClient = async () => {
    await API.get('/admin/client/getClient')
      .then((res) => {
        if (res?.data) {
          const result = decryptData(res?.data)
          setClientInfo(result?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetClient();
  }, []);
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (openTab === 1) {
      try {
        const payload = encryptData({
          custom_fields: [
            {
              custom_field_name: fieldData?.field_name,
              custom_field_type: fieldData?.field_type,
            },
          ],
        })
        const ApiCall = await API.post('/admin/client/AddCustomField', payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          GetClient();
          setFieldData({
            field_name: '',
            field_type: 'file',
          });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setBtnLoading(false);
    } else {
      try {
        const payload = encryptData({
          custom_fields: [
            {
              custom_field_name: fieldData?.field_name,
              custom_field_type: fieldData?.field_type,
            },
          ],
        })
        const ApiCall = await API.post('/admin/client/AddFieldCheckOut', payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          GetClient();
          setFieldData({
            field_name: '',
            field_type: 'file',
          });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setBtnLoading(false);
    }
  };
  const [openTab, setOpenTab] = useState(1);
  const handleEditField = (data) => {
    navigate('/store-setting/editCustomField', { state: { data } });
  };
  const handleEditChackOutField = (data) => {
    navigate('/store-setting/editCheckoutField', { state: { data } });
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleDeleteField = async () => {
    if (openTab === 1) {
      try {
        let Delete = await API.delete(`/admin/client/DeleteCustomField/${deleteId}`);
        if (Delete.status === 200) {
          setIsNotification(true);
          setNotificationMsg('Field removed successfully');
          setSeverity('success');
          GetClient();
          setDeleteId();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        let Delete = await API.delete(`/admin/client/DeleteFieldCheckOut/${deleteId}`);
        if (Delete.status === 200) {
          setIsNotification(true);
          setNotificationMsg('Field removed successfully');
          setSeverity('success');
          GetClient();
          setDeleteId();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <div>
      <ConfirmationPopup
        heading='Delete Custom Field'
        subheading='Do you really want to delete this field from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDeleteField}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='w-full mx-auto rounded-lg'>
        <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1">
          <a
            className={`tab ${(openTab === 1 ? 'focus-tab' : 'bg-transparent')}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
              setFieldData({
                field_name: '',
                field_type: 'file',
              });
            }}
            data-toggle='tab'
            href='#link1'
          >
            {t('lang_Registration')}
          </a>
          <a
            className={`tab ${(openTab === 2 ? 'focus-tab' : 'bg-transparent')}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
              setFieldData({
                field_name: '',
                field_type: 'file',
              });
            }}
            data-toggle='tab'
            href='#link2'
          >
            {t('lang_Checkout')}
          </a>
          <a className='text-xs text-transparent select-none font-bold uppercase border-b main-border-color px-5 py-4 block leading-normal transition-all duration-150'>
            {t('lang_sdf')}
          </a>
        </div>
      </div>
      <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full mx-auto secondary-bg-color p-5 border-x border-b main-border-color'
        >
          <div className='mb-4'>
            <label htmlFor='title'>
              {t('lang_Custom_field_name')}
              <span className='text-red-500 ms-1'>*</span>
            </label>
            <input
              className={
                err && fieldData?.field_name?.length === 0
                  ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                  : `w-full px-3 py-2 border main-border-color rounded-md focus:outline-none`
              }
              type='text'
              name='field_name'
              value={
                fieldData?.field_name?.length
                  ? fieldData?.field_name.split(' ').slice(0, 40).join(' ')
                  : ''
              }
              placeholder='Enter field name'
              onChange={(e) => handleChange(e)}
            />
            <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Only_Words_Allowed')}</p>
            {err && fieldData?.field_name?.length === 0 ? (
              <p className='text-red-500 font-medium text-sm'>
                {t('lang_Please_enter_field_name')}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='mb-4'>
            <label htmlFor='title'>
              {t('lang_Custom_field_type')}
              <span className='text-red-500 ms-1'>*</span>
            </label>
            <div className='relative'>
              <select
                className={
                  err && fieldData?.field_type?.length === 0
                    ? `w-full ps-3 pe-4 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                    : `w-full ps-3 pe-4 py-2 border main-border-color rounded-md focus:outline-none`
                }
                name='field_type'
                onChange={(e) => handleChange(e)}
              >
                <option value='file'>{t('lang_file')}</option>
                <option value='text'>{t('lang_text')}</option>
                <option value='date' className='capitalize'>
                  {t('lang_date')}
                </option>
                <option value='number'>{t('lang_Number')}</option>
              </select>
              <div className='select-arrow'></div>
            </div>
            {err && fieldData?.field_type?.length === 0 ? (
              <p className='text-red-500 font-medium text-sm'>
                {t('lang_Please_enter_field_type')}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div>
            {fieldData?.field_name === '' || fieldData?.field_type === '' ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_registration_custom_field')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[235px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_registration_custom_field')}</span>
              </button>
            )}
          </div>
          {clientInfo?.registration_custom_fields?.length > 0 ? (
            <>
              <hr className='border-gray-200 my-4'></hr>
              <h6 className='text-lg font-medium my-4'>{t('lang_Registration_custom_fields')}</h6>
              <div className='space-y-4'>
                {clientInfo?.registration_custom_fields?.map((field, index) => {
                  return (
                    <li className='flex w-full' key={index}>
                      <div className='flex items-center w-full justify-between rounded-[6px] border main-border-color py-2 px-3 space-x-4 text-sm'>
                        <div className='grid grid-cols-1 table-text lg:grid-cols-2 w-full gap-5'>
                          <p>
                            <span className='font-medium'>{t('lang_Field_Name')} :</span>
                            <span className='ms-2'>{field.custom_field_name}</span>
                          </p>
                          <p>
                            <span className='font-medium'>{t('lang_Field_Type')} :</span>
                            <span className='ms-2'>{field.custom_field_type}</span>
                          </p>
                        </div>
                        <div className='flex items-center space-x-4'>
                          <Tooltip title='Edit' arrow>
                            <button onClick={() => handleEditField(field)}>
                              <Edit className='w-[17px] h-[17px] text-black-500' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirmationPopup(true);
                                setDeleteId(field?._id);
                              }}
                            >
                              <Trash className='w-[17px] h-[17px] text-red-500' />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </form>
      </div>
      <div className={openTab === 2 ? 'block' : 'hidden'} id='link2'>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          method='POST'
          className='w-full mx-auto secondary-bg-color p-5 border-x border-b main-border-color'
        >
          <div className='mb-4'>
            <label htmlFor='title'>
              {t('lang_Custom_field_name')}
              <span className='text-red-500 ms-1'>*</span>
            </label>
            <input
              className={
                err && fieldData?.field_name?.length === 0
                  ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                  : `w-full px-3 py-2 border main-border-color rounded-md focus:outline-none`
              }
              type='text'
              name='field_name'
              value={
                fieldData?.field_name?.length
                  ? fieldData?.field_name.split(' ').slice(0, 40).join(' ')
                  : ''
              }
              placeholder='Field name'
              onChange={(e) => handleChange(e)}
            />
            <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Only_Words_Allowed')}</p>
            {err && fieldData?.field_name?.length === 0 ? (
              <p className='text-red-500 font-medium text-sm'>
                {t('lang_Please_enter_field_name')}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='mb-4'>
            <label htmlFor='title'>
              {t('lang_Custom_field_type')}
              <span className='text-red-500 ms-1'>*</span>
            </label>
            <div className='relative'>
              <select
                className={
                  err && fieldData?.field_type?.length === 0
                    ? `w-full ps-3 pe-4 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                    : `w-full ps-3 pe-4 py-2 border main-border-color rounded-md focus:outline-none`
                }
                name='field_type'
                onChange={(e) => handleChange(e)}
              >
                <option value='file' className='capitalize'>
                  {t('lang_file')}
                </option>
                <option value='text' className='capitalize'>
                  {t('lang_text')}
                </option>
                <option value='date' className='capitalize'>
                  {t('lang_date')}
                </option>
                <option value='number' className='capitalize'>
                  {t('lang_Number')}
                </option>
              </select>
              <div className='select-arrow'></div>
            </div>
            {err && fieldData?.field_type?.length === 0 ? (
              <p className='text-red-500 font-medium text-sm'>
                {t('lang_Please_enter_field_type')}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div>
            {fieldData?.field_name === '' || fieldData?.field_type === '' ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_checkout_custom_field')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[235px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_checkout_custom_field')}</span>
              </button>
            )}
          </div>
          {clientInfo?.checkout_custom_fields?.length > 0 ? (
            <>
              <hr className='border-gray-200 my-4'></hr>
              <h6 className='text-lg font-medium my-4'>{t('lang_Checkout_custom_fields')}</h6>
              <div className='space-y-4'>
                {clientInfo?.checkout_custom_fields?.map((field, index) => {
                  return (
                    <li className='flex w-full' key={index}>
                      <div className='flex items-center w-full justify-between rounded-[6px] border main-border-color py-2 px-3 space-x-4 text-sm'>
                        <div className='grid grid-cols-1 table-text lg:grid-cols-2 w-full gap-5'>
                          <p>
                            <span className='font-medium capitalize'>{t('lang_Field_Name')} :</span>
                            <span className='ms-2'>{field.custom_field_name}</span>
                          </p>
                          <p>
                            <span className='font-medium'>{t('lang_Field_Type')} :</span>
                            <span className='ms-2'>{field.custom_field_type}</span>
                          </p>
                        </div>
                        <div className='flex items-center space-x-4'>
                          <Tooltip title='Edit' arrow>
                            <button onClick={() => handleEditChackOutField(field)}>
                              <Edit className='w-[17px] h-[17px] text-black-500' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirmationPopup(true);
                                setDeleteId(field?._id);
                              }}
                            >
                              <Trash className='w-[17px] h-[17px] text-red-500' />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddCustomField;
