import React from 'react';
import NumberAnimation from '../../../../controllers/NumberAnimation';
import StarRating from '../../../../controllers/StarRating';
import ReactDragListView from 'react-drag-listview';
import SvgSection from '../../../../controllers/SvgSection';
import VideoSection from '../../../../controllers/VideoSection';
import { handleDragOver } from '../../../../controllers/ContextMenuItem';
import HeadingSection from '../../../InputControllSection/HeadingSection';
import ImageSection from '../../../InputControllSection/ImageSection';
import TextEditorSection from '../../../InputControllSection/TextEditorSection';
import VideoContentSection from '../../../InputControllSection/VideoContentSection';
import ButtonSection from '../../../InputControllSection/ButtonSection';
import DividerSection from '../../../InputControllSection/DividerSection';
import SpacerSection from '../../../InputControllSection/SpacerSection';
import MapSection from '../../../InputControllSection/MapSection';
import IconSection from '../../../InputControllSection/IconSection';
import ImageBoxSection from '../../../InputControllSection/ImageBoxSection';
import GeneralIconBox from '../../../InputControllSection/GeneralIconBox';
import GeneralIconList from '../../../InputControllSection/GeneralIconList';
import CounterSection from '../../../InputControllSection/CounterSection';
import TestimonialSection from '../../../InputControllSection/TestimonialSection';
import TabsComponent from '../../../InputControllSection/TabsComponent';
import AccordionSection from '../../../InputControllSection/AccordionSection';
import SocialIconSection from '../../../InputControllSection/SocialIconSection';
import AlertSection from '../../../InputControllSection/AlertSection';
import HtmlSection from '../../../InputControllSection/HtmlSection';
import RatingSection from '../../../InputControllSection/RatingSection';

const AddSectionSixGrid = ({
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  sectionAdd,
  sectionIndex,
  handleHeadingClick,
  activeHeading,
  setSectionAdd,
  selectedIndex,
  handleImageClick,
  selectSection,
  editSection,
  section,
  dragProps,
  gridDestinationId,
  setGridDestinationId,
  dropContainerRef,
  setElementorId,
  setDropPosition,
  setDestinationId,
  dropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const filteredSections = sectionAdd?.subFirstGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_two_for_two' ||
      'grid_three_for_three',
  );

  const filteredSecondSections = sectionAdd?.subSecondGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_two_for_two' ||
      'grid_three_for_three',
  );

  const filteredThreeSections = sectionAdd?.subThirdGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_two_for_two' ||
      'grid_three_for_three',
  );

  const filteredFourSections = sectionAdd?.subForthGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_two_for_two' ||
      'grid_three_for_three',
  );

  const filteredFiveSections = sectionAdd?.subFifthGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_two_for_two' ||
      'grid_three_for_three',
  );

  const filteredSixSections = sectionAdd?.subSixGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_two_for_two' ||
      'grid_three_for_three',
  );

  const getAlertClass = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green-100 border-green-400 text-green-800';
      case 'warning':
        return 'bg-yellow-100 border-yellow-400 text-yellow-800';
      case 'danger':
        return 'bg-red-100 border-red-400 text-red-800';
      case 'info':
      default:
        return 'bg-blue-100 border-blue-400 text-black-800';
    }
  };
  console.log('section_type', section?.section_type);
  return (
    <>
      <div
        className='relative grid grid-rows-[200px_minmax(1000,_1fr)_100px] grid-cols-3 items-center text-center'
        ref={dropContainerRef}
      >
        <div
          className={`${
            editSection?.section_type === 'subFirstGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subFirstGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subFirstGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subFirstGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(e, section?.id || section?.section_id, 'subFirstGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subFirstGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subFirstGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subFirstGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subFirstGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredSections.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;

                    const currentActiveSection =
                      gridDestinationId === itemIndex &&
                      editSection?.section_type === 'subFirstGrid' &&
                      editSection?.section_index === sectionIndex;

                    const abovePosition =
                      dropPosition === 'above' && itemIndex === gridDestinationId;
                    const belowPosition =
                      dropPosition === 'below' && itemIndex === gridDestinationId;

                    const alertType = obj?.content?.alertType || 'info';
                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    return (
                      <div
                        key={itemIndex}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex),
                            openAddSection(e, 'subFirstGrid', 'setting');
                          handleCopyItem(e, obj, 'subFirstGrid', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'subFirstGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'subFirstGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                            setGridDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setGridDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'subFirstGrid', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subFirstGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'subFirstGrid',
                            type: 'setting',
                          });
                        }}
                        className={`cursor-pointer
                          ${
                            abovePosition || belowPosition
                              ? ''
                              : isActive
                              ? 'border-2 border-blue-500'
                              : 'border-2 border-transparent'
                          }
                          ${
                            abovePosition && currentActiveSection
                              ? 'border-t-2 border-red-700 border-dashed'
                              : ''
                          }
                          ${
                            belowPosition && currentActiveSection
                              ? 'border-b-2 border-green-700 border-dashed'
                              : ''
                          }
                          draggable-item hover:border-2 hover:border-blue-500`}
                      >
                        {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSecondGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSecondGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subSecondGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSecondGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(e, section?.id || section?.section_id, 'subSecondGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSecondGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSecondGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subSecondGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSecondGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSecondSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredSecondSections.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    const alertType = obj?.content?.alertType || 'info';

                    const currentActiveSection =
                      gridDestinationId === itemIndex &&
                      editSection?.section_type === 'subSecondGrid' &&
                      editSection?.section_index === sectionIndex;

                    const abovePosition =
                      dropPosition === 'above' && itemIndex === gridDestinationId;
                    const belowPosition =
                      dropPosition === 'below' && itemIndex === gridDestinationId;
                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    return (
                      <div
                        key={itemIndex}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex),
                            openAddSection(e, 'subSecondGrid', 'setting');
                          handleCopyItem(e, obj, 'subSecondGrid', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'subSecondGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'subSecondGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                            setGridDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setGridDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'subSecondGrid', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSecondGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'subSecondGrid',
                            type: 'setting',
                          });
                        }}
                        className={`cursor-pointer
                          ${
                            abovePosition || belowPosition
                              ? ''
                              : isActive
                              ? 'border-2 border-blue-500'
                              : 'border-2 border-transparent'
                          }
                          ${
                            abovePosition && currentActiveSection
                              ? 'border-t-2 border-red-700 border-dashed'
                              : ''
                          }
                          ${
                            belowPosition && currentActiveSection
                              ? 'border-b-2 border-green-700 border-dashed'
                              : ''
                          }
                          draggable-item hover:border-2 hover:border-blue-500`}
                      >
                          {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subThirdGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subThirdGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subThirdGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subThirdGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(e, section?.id || section?.section_id, 'subThirdGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subThirdGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subThirdGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subThirdGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subThirdGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredThreeSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredThreeSections.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    const alertType = obj?.content?.alertType || 'info';
                    const currentActiveSection =
                      gridDestinationId === itemIndex &&
                      editSection?.section_type === 'subThirdGrid' &&
                      editSection?.section_index === sectionIndex;

                    const abovePosition =
                      dropPosition === 'above' && itemIndex === gridDestinationId;
                    const belowPosition =
                      dropPosition === 'below' && itemIndex === gridDestinationId;

                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    return (
                      <div
                        key={itemIndex}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          handleCopyItem(e, obj, 'subThirdGrid', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'subThirdGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                          openAddSection(e, 'subThirdGrid', 'setting');
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'subThirdGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                            setGridDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setGridDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'subThirdGrid', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subThirdGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'subThirdGrid',
                            type: 'setting',
                          });
                        }}
                        className={`cursor-pointer
                          ${
                            abovePosition || belowPosition
                              ? ''
                              : isActive
                              ? 'border-2 border-blue-500'
                              : 'border-2 border-transparent'
                          }
                          ${
                            abovePosition && currentActiveSection
                              ? 'border-t-2 border-red-700 border-dashed'
                              : ''
                          }
                          ${
                            belowPosition && currentActiveSection
                              ? 'border-b-2 border-green-700 border-dashed'
                              : ''
                          }
                          draggable-item hover:border-2 hover:border-blue-500`}
                      >
                         {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subForthGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subForthGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subForthGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subForthGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(e, section?.id || section?.section_id, 'subForthGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subForthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subForthGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subForthGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subForthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredFourSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredFourSections.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;

                    const currentActiveSection =
                      gridDestinationId === itemIndex &&
                      editSection?.section_type === 'subForthGrid' &&
                      editSection?.section_index === sectionIndex;

                    const abovePosition =
                      dropPosition === 'above' && itemIndex === gridDestinationId;
                    const belowPosition =
                      dropPosition === 'below' && itemIndex === gridDestinationId;

                    const alertType = obj?.content?.alertType || 'info';
                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    return (
                      <div
                        key={itemIndex}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'subForthGrid', 'setting');
                          handleCopyItem(e, obj, 'subForthGrid', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'subForthGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'subForthGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                            setGridDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setGridDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'subForthGrid', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subForthGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'subForthGrid',
                            type: 'setting',
                          });
                        }}
                        className={`cursor-pointer
                          ${
                            abovePosition || belowPosition
                              ? ''
                              : isActive
                              ? 'border-2 border-blue-500'
                              : 'border-2 border-transparent'
                          }
                          ${
                            abovePosition && currentActiveSection
                              ? 'border-t-2 border-red-700 border-dashed'
                              : ''
                          }
                          ${
                            belowPosition && currentActiveSection
                              ? 'border-b-2 border-green-700 border-dashed'
                              : ''
                          }
                          draggable-item hover:border-2 hover:border-blue-500`}
                      >
                          {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subFifthGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subFifthGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subFifthGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subFifthGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(e, section?.id || section?.section_id, 'subFifthGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subFifthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subFifthGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subFifthGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subFifthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredFiveSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredFiveSections.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;

                    const currentActiveSection =
                      gridDestinationId === itemIndex &&
                      editSection?.section_type === 'subFifthGrid' &&
                      editSection?.section_index === sectionIndex;

                    const abovePosition =
                      dropPosition === 'above' && itemIndex === gridDestinationId;
                    const belowPosition =
                      dropPosition === 'below' && itemIndex === gridDestinationId;

                    const alertType = obj?.content?.alertType || 'info';
                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    return (
                      <div
                        key={itemIndex}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'subFifthGrid', 'setting');
                          handleCopyItem(e, obj, 'subFifthGrid', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'subFifthGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'subFifthGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                            setGridDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setGridDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'subFifthGrid', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subFifthGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'subFifthGrid',
                            type: 'setting',
                          });
                        }}
                        className={`cursor-pointer
                          ${
                            abovePosition || belowPosition
                              ? ''
                              : isActive
                              ? 'border-2 border-blue-500'
                              : 'border-2 border-transparent'
                          }
                          ${
                            abovePosition && currentActiveSection
                              ? 'border-t-2 border-red-700 border-dashed'
                              : ''
                          }
                          ${
                            belowPosition && currentActiveSection
                              ? 'border-b-2 border-green-700 border-dashed'
                              : ''
                          }
                          draggable-item hover:border-2 hover:border-blue-500`}
                      >
                          {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSixGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subSixGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(e, section?.id || section?.section_id, 'subSixGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixGrid', 'add_section'),
              selectSection(e, section?.id || section?.section_id, 'subSixGrid', sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSixSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredSixSections.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    const currentActiveSection =
                      gridDestinationId === itemIndex &&
                      editSection?.section_type === 'subSixGrid' &&
                      editSection?.section_index === sectionIndex;

                    const abovePosition =
                      dropPosition === 'above' && itemIndex === gridDestinationId;
                    const belowPosition =
                      dropPosition === 'below' && itemIndex === gridDestinationId;

                    const alertType = obj?.content?.alertType || 'info';
                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    return (
                      <div
                        key={itemIndex}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'subSixGrid', 'setting');
                          handleCopyItem(e, obj, 'subSixGrid', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'subSixGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'subSixGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                            setGridDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setGridDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'subSixGrid', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'subSixGrid',
                            type: 'setting',
                          });
                        }}
                        className={`cursor-pointer
                          ${
                            abovePosition || belowPosition
                              ? ''
                              : isActive
                              ? 'border-2 border-blue-500'
                              : 'border-2 border-transparent'
                          }
                          ${
                            abovePosition && currentActiveSection
                              ? 'border-t-2 border-red-700 border-dashed'
                              : ''
                          }
                          ${
                            belowPosition && currentActiveSection
                              ? 'border-b-2 border-green-700 border-dashed'
                              : ''
                          }
                          draggable-item hover:border-2 hover:border-blue-500`}
                      >
                         {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        {/* <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, sectionIndex)} className='mr-2'>
            +
          </button>
          <button
            onClick={() => removeSection(sectionIndex, 'grid_three_for_three')}
            className='ml-2'
          >
            ×
          </button>
        </div> */}
      </div>
    </>
  );
};

export default AddSectionSixGrid;
