import React, { useEffect, useState } from 'react';
import API from '../../API';
import { ChevronDown, Edit, Trash, X } from 'feather-icons-react/build/IconComponents';
import { Autocomplete, Checkbox, Dialog, TextField, Tooltip } from '@mui/material';
import { Plus } from 'react-bootstrap-icons';
import { Transition } from '../../controllers/Transition';
import { t } from 'i18next';
import Notification from '../Notification';
import Loader from '../Loader/Loader';
import DataTable from 'react-data-table-component';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
const SelectAttribute = ({ groupAttribute, setGroupAttribute }) => {
    const [allAttributesGroup, setAllAttributesGroup] = useState([]);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState('');
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const [selectGroup, setSelectGroup] = useState('');
    const [groupValue, setGroupValue] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [openAttributeValue, setOpenAttributeValue] = useState(false);
    const [openAttributeGroup, setOpenAttributeGroup] = useState(false);
    const [attributeName, setAttributeName] = useState('')
    const [groupValueArray, setGroupValueArray] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState('');
    let attributesId = allAttributesGroup?.find(attribute => attribute.attribute_name === selectGroup)?._id || ''
    let attributes = allAttributesGroup?.filter(attribute => attribute._id === attributesId) || []
    let attributes_values = attributes[0]?.attribute_value || []
    const [groupValueName, setGroupValueName] = useState([])
    // save Group Values In Array
    const editAttribute = (row, index) => {
        setEditIndex(index)
        setSelectGroup(row?.attribute_name);
        setSelectedOptions(row?.attribute_value);
    }
    const addAttributesGroup = () => {
        if(isEdit){
            // setGroupAttribute([...groupAttribute,{ attribute_name: selectGroup, attribute_value: selectedOptions }])
            const updatedPersons = groupAttribute?.filter((person, idx) => idx !== editIndex);
            updatedPersons.push({ attribute_name: selectGroup, attribute_value: selectedOptions });
            setGroupAttribute(updatedPersons);
            setIsEdit(false);
            setSelectGroup();
            setSelectedOptions([]);
        } else {
            setGroupAttribute([...groupAttribute,{ attribute_name: selectGroup, attribute_value: selectedOptions }])
            setSelectGroup();
            setSelectedOptions([]);
        }
    }
    const columns = [
        {
          name: t('lang_Attribute_name'),
          minWidth: '150px',
          cell: (row) => <p className='line-clamp-2'>{row.attribute_name || '-'}</p>,
        },
        {
          name: t('lang_Attribute_value'),
          minWidth: '200px',
          cell: (row) => <div className='flex overflow-auto flex-wrap line-clamp-1'>
          {row?.attribute_value?.map((value, index) => {
            return (
              <span
                key={index}
                className='primary-bg-color me-1 my-0.5 inline-block whitespace-nowrap  text-[13px] text-center px-3 py-1.5 rounded-[6px]'
              >
                {value}
              </span>
            );
          })}
        </div>,
        },
        {
          name: <span className='w-full block text-right capitalize'>{t('lang_action')}</span>,
          minWidth: '150px',
          cell: (row, index) => (
            <div className='space-x-3 w-full flex justify-end items-center'>
              <button href='#' className='text-xs font-thin text-black-500 hover:text-black-600'>
                <div>
                  <Tooltip title='Edit' arrow>
                    <button
                    type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEdit(true);
                        editAttribute(row, index);
                      }}
                    >
                      <Edit className='w-[17px] h-[17px]' />
                    </button>
                  </Tooltip>
                </div>
              </button>
              <button
                type='button'
                className='text-xs font-thin text-red-500 hover:text-red-600'
                onClick={(e) => {
                  e.preventDefault();
                  if(isEdit){
                    setIsEdit(false);
                  }
                  let arr = groupAttribute?.filter((attribute, idx) => idx !== index)
                  setGroupAttribute(arr)
                }}
              >
                <div>
                  <Tooltip title='Delete' arrow>
                    <button className=''>
                      <Trash className='w-[17px] h-[17px]' />
                    </button>
                  </Tooltip>
                </div>
              </button>
            </div>
          ),
        },
      ];
    const attributeOpen = () => {
        if (openAttributeGroup) {
            setOpenAttributeGroup(false);
        }
        if (selectGroup) {
            setOpenAttributeValue(true)
        } else {
            setIsNotification(true);
            setNotificationMsg("please select attribute name");
            setSeverity('warning');
        }
    }
    const attributeGroup = () => {
        if (openAttributeValue) {
            setOpenAttributeValue(false);
        }
        setOpenAttributeGroup(true)
    }
    const SaveGroupValue = () => {
        if (groupValueName.length > 0) {
            if (groupValueName.includes(',')) {
                let TagSeperator = groupValueName.split(',');
                let finalArray = [];
                let productTags = [...groupValueArray, ...TagSeperator];
                productTags.filter((tag) => {
                    const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
                    let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
                    if (!exists) {
                        finalArray.push(tag.trim());
                    }
                });
                setGroupValueArray(finalArray);
            } else {
                const searchRegex = new RegExp(`^${groupValueName}$`, 'i');
                const exists = groupValueArray.some((tag) => searchRegex.test(tag));
                if (!exists) {
                    setGroupValueArray((prev) => [...prev, groupValueName?.trim()]);
                }
            }
            setGroupValueName('');
        }
    };
    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            groupValueArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setGroupValueArray([...groupValueArray]);
    };
    const addAttributeValue = async () => {
        try {
            const payload = encryptData({
                attribute_name: selectGroup,
                attribute_value: [...attributes_values, ...groupValueArray?.filter((x) => x !== '')]
            })
            const ApiCall = await API.put(`/admin/product/updateProductAttributeGroup/${attributesId}`, payload)
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                attributesId = ''
                attributes = []
                setGroupValueArray([]);
                getAttributesGroup();
                setIsNotification(true);
                setNotificationMsg("Attribute value successfully!");
                setSeverity('success');
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setOpenAttributeValue(false);
        }
    }
    const addAttributes = async () => {
        try {
            const payload = encryptData({
                attribute_name: attributeName?.trim(),
                attribute_value: groupValueArray?.filter((x) => x !== '')
            })
            const ApiCall = await API.post('/admin/product/addProductAttributeGroup', payload)
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                setGroupValueArray([]);
                getAttributesGroup();
                setAttributeName('');
                setIsNotification(true);
                setNotificationMsg("Attribute value successfully!");
                setSeverity('success');
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setOpenAttributeGroup(false);
        }
    }
    const getAttributesGroup = async () => {
        try {
            const data = await API.get(`/admin/product/getProductAttributeGroup`);
            if (data.status === 200 || data.status === 304) {
                const result = decryptData(data?.data);
                setAllAttributesGroup(result?.data?.records);
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    };

    useEffect(() => {
        if (selectGroup) {
            const selectedGroup = allAttributesGroup.find((x) => x?.attribute_name === selectGroup);
            if (selectedGroup) {
                setGroupValue(['Select All', ...selectedGroup?.attribute_value]);
            } else {
                setGroupValue([]);
            }
        } else {
            setGroupValue([]);
        }
    }, [selectGroup, allAttributesGroup]);

    const handleChange = (value) => {
        if (value.includes('Select All')) {
            if (selectedOptions.includes('Select All')) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions(['Select All', ...groupValue.slice(1)]);
            }
        } else {
            setSelectedOptions(value.filter(option => option !== 'Select All'));
        }
    };
    useEffect(() => {
        getAttributesGroup();
    }, []);

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <details className='bg-white group shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                <summary className='text-base table-text flex items-center justify-between font-medium'>
                    <div className=' text-primary'>
                        {t('lang_Product_attributes')}
                        <p className='text-xs table-text font-normal'>
                            {t('lang_Select_product_attributes_for_show_product_details')}
                        </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                    </span>
                </summary>
                <div className='mb-4'>
                    <label className='flex items-center justify-between'>
                        <div className='flex items-center'>
                            {t('lang_Select_attributes_name')}
                        </div>
                        <button
                            className='text-link hover:underline font-medium flex items-center rounded-[6px] space-x-2 transition-all duration-200 text-sm '
                            type='button'
                            onClick={attributeGroup}
                        >
                            <Plus />
                            {t('lang_add_new', { module: 'Attributes' })}
                        </button>
                    </label>
                    <Autocomplete
                        className='autocomplete'
                        options={allAttributesGroup || []}
                        getOptionLabel={(x) => x?.attribute_name}
                        value={allAttributesGroup?.find((x) => x.attribute_name === selectGroup) || null}
                        onChange={(e, v) => setSelectGroup(v ? v.attribute_name : '')}
                        renderInput={(option) => (
                            <TextField
                                placeholder='Select Attributes Name'
                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                className='cursor-pointer'
                                {...option}
                            />
                        )}
                    />
                </div>
                <div className='mb-4'>
                    <label className='flex items-center justify-between'>
                        <div className='flex items-center'>
                            {t('lang_Select_attributes_value')}
                        </div>
                        <button
                            className='text-link hover:underline font-medium flex items-center rounded-[6px] space-x-2 transition-all duration-200 text-sm '
                            type='button'
                            onClick={attributeOpen}
                        >
                            <Plus />
                            {t('lang_Add_new_attributes_value')}
                        </button>
                    </label>
                    <Autocomplete
                        className='autocomplete'
                        multiple
                        options={groupValue}
                        disableCloseOnSelect
                        value={selectedOptions}
                        onChange={(e, v) => handleChange(v)}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                                <li key={key} {...optionProps}>
                                    <Checkbox checked={selected} style={{ marginRight: 8 }} />
                                    {option}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Favorites" />
                        )}
                    />
                    <button
                    type='button'
                    className={`inline-block text-white text-sm font-medium mt-2 py-2 px-8 rounded-[6px] transition duration-300 capitalize ${selectedOptions?.length <= 0 ? 'cursor-not-allowed opacity-50 border border-secondary text-link' : 'secondary-btn'}`}
                    disabled={selectedOptions?.length <= 0}
                    onClick={addAttributesGroup}
                >
                    {isEdit ? t('lang_update') : t('lang_Add')}
                </button>
                {groupAttribute?.length > 0 && <>
                    <hr className='my-4' />
                    <div className='w-full transition-all duration-100 inline-block overflow-auto border main-border-color rounded-[6px] mt-2'>
                        <DataTable
                            columns={columns}
                            data={groupAttribute}
                            selectableRowsHighlight
                            highlightOnHover
                            progressComponent={<Loader />}
                        />
                    </div>
                </>}
                </div>                
            </details>
            <Dialog
                open={openAttributeValue}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenAttributeValue(false)}
                aria-describedby='alert-dialog-slide-description'
            >
                <div className='p-5 w-[500px] secondary-bg-color'>
                    <div className='flex items-center justify-between'>
                        <h6 className='text-xl font-medium text-primary'>Add Attribute Value</h6>
                        <button onClick={() => setOpenAttributeValue(false)} className='close-btn'>
                            <X className='w-4 h-4' />
                        </button>
                    </div>
                    <hr className='my-4 main-border-color'></hr>
                    <form
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        method='get'
                        className='w-full'
                    >
                        <div>
                            <div className='flex items-center justify-between'>
                                <label htmlFor='lang_group_value'>
                                    {t('lang_Attribute_value')}
                                    <span className='text-red-500 ms-1'>*</span>
                                </label>
                            </div>
                            <input
                                className={
                                    groupValueArray.length === 0
                                        ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                        : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                }
                                type='text'
                                id='group_value'
                                name='group_value'
                                value={groupValueName}
                                onChange={(e) => setGroupValueName(e.target.value)}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' ? (e.preventDefault(), SaveGroupValue()) : '';
                                }}
                                placeholder='e.g. Black'
                            />
                            <p className='w-full text-xs mt-0 text-gray-400'>
                                {t('lang_use')} <b className='text-gray-500'>{t('lang_enter')} {t('lang_OR')} {t('lang_Comma')}</b>{' '}
                                {t('lang_key_to_separate_tags')}
                            </p>
                            {groupValueArray?.length > 0 &&
                                <div className='flex flex-wrap items-center mt-2'>
                                    {groupValueArray.map((elem, index) => {
                                        if (elem !== '')
                                            return (
                                                <span
                                                    key={index}
                                                    className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 py-1.5 rounded-[6px]'
                                                >
                                                    {elem}
                                                    <X
                                                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                                        onClick={() => RemoveTag(index)}
                                                    />
                                                </span>
                                            );
                                    })}
                                </div>
                            }
                        </div>
                        <button
                            className={`w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300 ${groupValueArray?.length <= 0 && 'bg-btn opacity-50 cursor-not-allowed'}`}
                            type='button'
                            disabled={groupValueArray?.length <= 0 ? true : false}
                            onClick={addAttributeValue}
                        >
                            {t('lang_Add_attribute_value')}
                        </button>
                    </form>
                </div>
            </Dialog>
            <Dialog
                open={openAttributeGroup}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenAttributeGroup(false)}
                aria-describedby='alert-dialog-slide-description'
            >
                <div className='p-5 w-[500px] secondary-bg-color'>
                    <div className='flex items-center justify-between'>
                        <h6 className='text-xl font-medium text-primary'>{t('lang_Add_attribute')}</h6>
                        <button onClick={() => setOpenAttributeGroup(false)} className='close-btn'>
                            <X className='w-4 h-4' />
                        </button>
                    </div>
                    <hr className='my-4 main-border-color'></hr>
                    <form
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        method='get'
                        className='w-full'
                    >
                        <div>
                            <div className='mb-4'>
                                <div className='flex w-full justify-between'>
                                    <label htmlFor='product_name'>
                                        {t('lang_Attribute_name')}
                                        <span className='text-red-500 ms-1'>*</span>
                                    </label>
                                </div>
                                <input
                                    className={
                                        attributeName.length === 0
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                            : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                    }
                                    type='text'
                                    id='attributeName'
                                    name='attributeName'
                                    value={attributeName}
                                    onChange={(e) => setAttributeName(e.target.value)}
                                    placeholder={`${t('lang_placeholder', { input: 'attribute name' })}`}
                                />
                            </div>
                            <div className='flex items-center justify-between'>
                                <label htmlFor='lang_group_value'>
                                    {t('lang_Attribute_value')}
                                    <span className='text-red-500 ms-1'>*</span>
                                </label>
                            </div>
                            <input
                                className={
                                    groupValueArray.length === 0
                                        ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                        : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                }
                                type='text'
                                id='group_value'
                                name='group_value'
                                value={groupValueName}
                                onChange={(e) => setGroupValueName(e.target.value)}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' ? (e.preventDefault(), SaveGroupValue()) : '';
                                }}
                                placeholder='e.g. Black'
                            />
                            <p className='w-full text-xs mt-0 text-gray-400'>
                                {t('lang_use')} <b className='text-gray-500'>{t('lang_enter')} {t('lang_OR')} {t('lang_Comma')}</b>{' '}
                                {t('lang_key_to_separate_tags')}
                            </p>
                            {groupValueArray?.length > 0 &&
                                <div className='flex flex-wrap items-center mt-2'>
                                    {groupValueArray.map((elem, index) => {
                                        if (elem !== '')
                                            return (
                                                <span
                                                    key={index}
                                                    className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 py-1.5 rounded-[6px]'
                                                >
                                                    {elem}
                                                    <X
                                                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                                        onClick={() => RemoveTag(index)}
                                                    />
                                                </span>
                                            );
                                    })}
                                </div>
                            }
                        </div>
                        <button
                            className={`w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300 ${groupValueArray?.length <= 0 && 'bg-btn opacity-50 cursor-not-allowed'}`}
                            type='button'
                            disabled={groupValueArray?.length <= 0 ? true : false}
                            onClick={addAttributes}
                        >
                            {t('lang_Add_attribute_value')}
                        </button>
                    </form>
                </div>
            </Dialog>
        </>
    );
};

export default SelectAttribute;
