import React from 'react';
import AddSectionSixGrid from './AddSectionSixGrid';
import AddSecondSectionSixGrid from './AddSecondSectionSixGrid';
import AddThirdSectionSixGrid from './AddThirdSectionSixGrid';
import AddForthSectionSixGrid from './AddForthSectionSixGrid';
import TabsComponent from '../../../../controllers/TabsComponent';
import AccordionSection from '../../../../controllers/AccordionSection';
import NumberAnimation from '../../../../controllers/NumberAnimation';
import StarRating from '../../../../controllers/StarRating';
import ReactDragListView from 'react-drag-listview';
import SvgSection from '../../../../controllers/SvgSection';
import { handleDragOver, RenderContextMenu } from '../../../../controllers/ContextMenuItem';
import HeadingSection from '../../../InputControllSection/HeadingSection';
import ImageSection from '../../../InputControllSection/ImageSection';
import TextEditorSection from '../../../InputControllSection/TextEditorSection';
import VideoContentSection from '../../../InputControllSection/VideoContentSection';
import ButtonSection from '../../../InputControllSection/ButtonSection';
import DividerSection from '../../../InputControllSection/DividerSection';
import SpacerSection from '../../../InputControllSection/SpacerSection';
import MapSection from '../../../InputControllSection/MapSection';
import IconSection from '../../../InputControllSection/IconSection';
import ImageBoxSection from '../../../InputControllSection/ImageBoxSection';
import GeneralIconBox from '../../../InputControllSection/GeneralIconBox';
import GeneralIconList from '../../../InputControllSection/GeneralIconList';
import CounterSection from '../../../InputControllSection/CounterSection';
import TestimonialSection from '../../../InputControllSection/TestimonialSection';
import SocialIconSection from '../../../InputControllSection/SocialIconSection';
import AlertSection from '../../../InputControllSection/AlertSection';
import HtmlSection from '../../../InputControllSection/HtmlSection';
import RatingSection from '../../../InputControllSection/RatingSection';

const TwelveSectionAdd = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  sectionAddSecondGrid,
  sectionIndex,
  section,
  sectionAddThirdGrid,
  sectionAddForthGrid,
  sectionAddFifthGrid,
  sectionAddSixGrid,
  gridValue,
  handleHeadingClick,
  activeHeading,
  setSectionAdd,
  activeHeadingIndex,
  handleSubDivClick,
  selectedIndex,
  selectSection,
  setEditSection,
  dragProps,
  dropContainerRef,
  destinationId,
  setDestinationId,
  gridDestinationId,
  setGridDestinationId,
  setSelectedItem,
  setElementorId,
  dropPosition,
  setDropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const filteredSections = sectionAdd?.firstGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_two_grid_two',
  );

  const filteredSecondSections = sectionAdd?.secondGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_two_grid_two',
  );

  const filteredThirdSections = sectionAdd?.thirdGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_two_grid_two',
  );

  const filteredForthSections = sectionAdd?.forthGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_two_grid_two',
  );

  const getAlertClass = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green-100 border-green-400 text-green-800';
      case 'warning':
        return 'bg-yellow-100 border-yellow-400 text-yellow-800';
      case 'danger':
        return 'bg-red-100 border-red-400 text-red-800';
      case 'info':
      default:
        return 'bg-blue-100 border-blue-400 text-black-800';
    }
  };

  return (
    <>
      <div
        className='relative grid grid-cols-3 items-center text-center group'
        onClick={(e) => {
          openAddSection(e, 'firstGrid', 'add_section');
          handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
          selectSection(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type,
            sectionIndex,
          );
          getPrevSecId(e, sectionIndex);
          setElementorId(section?.id || section?.section_id);
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type || section?.section_type,
            sectionIndex,
            section?.section_type,
          );
        }}
        ref={dropContainerRef}
      >
        <div
          className={`${
            editSection?.section_type === 'flex_two_grid_two_firstGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2  border-dashed border-transparent hover:border-[#9da5ae]'
          } col-span-2 relative m-3 p-5 group/item`}
          onClick={(e) => {
            openAddSection(e, 'firstGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_firstGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_firstGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_firstGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_firstGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            e.preventDefault(),
              handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
            openAddSection(e, 'firstGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_firstGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_firstGrid',
              sectionIndex,
              section?.section_type,
            );
            getPrevSecId(e, sectionIndex);
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredSections?.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    const alertType = obj?.content?.alertType || 'info';

                    const currentActiveSection =
                      destinationId === itemIndex &&
                      editSection?.section_type === 'flex_two_grid_two_firstGrid' &&
                      editSection?.section_index === sectionIndex;
                    const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
                    const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    if (obj?.blockName === 'grid') {
                      return (
                        <div
                          key={itemIndex}
                          className={`cursor-pointer border-[2px] transition-all duration-300 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }  hover:border-2 hover:border-blue-500`}
                          onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                        >
                          <AddSectionSixGrid
                            flexBoxValue={flexBoxValue}
                            setSectionAdd={setSectionAdd}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={sectionIndex}
                            section={section}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleSubDivClick={handleSubDivClick}
                            selectedIndex={selectedIndex}
                            selectSection={selectSection}
                            dragProps={dragProps}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            dropContainerRef={dropContainerRef}
                            setElementorId={setElementorId}
                            setDropPosition={setDropPosition}
                            setDestinationId={setDestinationId}
                            dropPosition={dropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      );
                    }
                    return (
                      <div
                        className={`cursor-pointer
                        ${
                          abovePosition || belowPosition
                            ? ''
                            : isActive
                            ? 'border-2 border-blue-500'
                            : 'border-2 border-transparent'
                        }
                        ${
                          abovePosition && currentActiveSection
                            ? 'border-t-2 border-red-700 border-dashed'
                            : ''
                        }
                        ${
                          belowPosition && currentActiveSection
                            ? 'border-b-2 border-green-700 border-dashed'
                            : ''
                        }
                        draggable-item hover:border-2 hover:border-blue-500`}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'firstGrid', 'setting');
                          handleCopyItem(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'flex_two_grid_two_firstGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                          setElementorId(section?.id || section?.section_id);
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'flex_two_grid_two_firstGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'flex_two_grid_two_firstGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'firstGrid',
                            type: 'setting',
                          });
                        }}
                      >
                        {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'flex_two_grid_two_secondGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2  border-dashed border-transparent hover:border-[#9da5ae]'
          } relative m-3 p-5 group/item`}
          onClick={(e) => {
            openAddSection(e, 'secondGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_secondGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_secondGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_secondGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_secondGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            e.preventDefault(),
              handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
            openAddSection(e, 'secondGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_secondGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_secondGrid',
              sectionIndex,
              section?.section_type,
            );
            getPrevSecId(e, sectionIndex);
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSecondSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredSecondSections?.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    const alertType = obj?.content?.alertType || 'info';

                    const currentActiveSection =
                      destinationId === itemIndex &&
                      editSection?.section_type === 'flex_two_grid_two_secondGrid' &&
                      editSection?.section_index === sectionIndex;
                    const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
                    const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    if (obj?.blockName === 'grid') {
                      return (
                        <div
                          key={itemIndex}
                          className={`cursor-pointer border-[2px] transition-all duration-300 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }  hover:border-2 hover:border-blue-500`}
                          onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                        >
                          <AddSecondSectionSixGrid
                            flexBoxValue={flexBoxValue}
                            setSectionAdd={setSectionAdd}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={sectionIndex}
                            section={section}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleSubDivClick={handleSubDivClick}
                            selectedIndex={selectedIndex}
                            selectSection={selectSection}
                            dragProps={dragProps}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            dropContainerRef={dropContainerRef}
                            setElementorId={setElementorId}
                            setDropPosition={setDropPosition}
                            setDestinationId={setDestinationId}
                            dropPosition={dropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      );
                    }
                    return (
                      <div
                        className={`cursor-pointer
                        ${
                          abovePosition || belowPosition
                            ? ''
                            : isActive
                            ? 'border-2 border-blue-500'
                            : 'border-2 border-transparent'
                        }
                        ${
                          abovePosition && currentActiveSection
                            ? 'border-t-2 border-red-700 border-dashed'
                            : ''
                        }
                        ${
                          belowPosition && currentActiveSection
                            ? 'border-b-2 border-green-700 border-dashed'
                            : ''
                        }
                        draggable-item hover:border-2 hover:border-blue-500`}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'secondGrid', 'setting');
                          handleCopyItem(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'flex_two_grid_two_secondGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                          setElementorId(section?.id || section?.section_id);
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'flex_two_grid_two_secondGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'flex_two_grid_two_secondGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'secondGrid',
                            type: 'setting',
                          });
                        }}
                      >
                        {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'flex_two_grid_two_thirdGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2  border-dashed border-transparent hover:border-[#9da5ae]'
          } relative m-3 p-5 group/item`}
          onClick={(e) => {
            openAddSection(e, 'thirdGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_thirdGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_thirdGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_thirdGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_thirdGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            e.preventDefault(),
              handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
            openAddSection(e, 'thirdGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_thirdGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_thirdGrid',
              sectionIndex,
              section?.section_type,
            );
            getPrevSecId(e, sectionIndex);
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredThirdSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredThirdSections?.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    const alertType = obj?.content?.alertType || 'info';

                    const currentActiveSection =
                      destinationId === itemIndex &&
                      editSection?.section_type === 'flex_two_grid_two_thirdGrid' &&
                      editSection?.section_index === sectionIndex;
                    const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
                    const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    if (obj?.blockName === 'grid') {
                      return (
                        <div
                          key={itemIndex}
                          className={`cursor-pointer border-[2px] transition-all duration-300 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }  hover:border-2 hover:border-blue-500`}
                          onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                        >
                          <AddThirdSectionSixGrid
                            flexBoxValue={flexBoxValue}
                            setSectionAdd={setSectionAdd}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={sectionIndex}
                            section={section}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleSubDivClick={handleSubDivClick}
                            selectedIndex={selectedIndex}
                            selectSection={selectSection}
                            dragProps={dragProps}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            dropContainerRef={dropContainerRef}
                            setElementorId={setElementorId}
                            setDropPosition={setDropPosition}
                            setDestinationId={setDestinationId}
                            dropPosition={dropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      );
                    }
                    return (
                      <div
                        className={`cursor-pointer
                        ${
                          abovePosition || belowPosition
                            ? ''
                            : isActive
                            ? 'border-2 border-blue-500'
                            : 'border-2 border-transparent'
                        }
                        ${
                          abovePosition && currentActiveSection
                            ? 'border-t-2 border-red-700 border-dashed'
                            : ''
                        }
                        ${
                          belowPosition && currentActiveSection
                            ? 'border-b-2 border-green-700 border-dashed'
                            : ''
                        }
                        draggable-item hover:border-2 hover:border-blue-500`}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'thirdGrid', 'setting');
                          handleCopyItem(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'flex_two_grid_two_thirdGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                          setElementorId(section?.id || section?.section_id);
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'flex_two_grid_two_thirdGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'flex_two_grid_two_thirdGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'thirdGrid',
                            type: 'setting',
                          });
                        }}
                      >
                        {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'flex_two_grid_two_forthGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2  border-dashed border-transparent hover:border-[#9da5ae]'
          } col-span-2 relative m-3 p-5 group/item`}
          onClick={(e) => {
            openAddSection(e, 'forthGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_forthGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_forthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_forthGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_forthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            e.preventDefault(),
              handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
            openAddSection(e, 'forthGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'flex_two_grid_two_forthGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'flex_two_grid_two_forthGrid',
              sectionIndex,
              section?.section_type,
            );
            getPrevSecId(e, sectionIndex);
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredForthSections?.length > 0 ? (
              <div className='w-full'>
                <ReactDragListView {...dragProps}>
                  {filteredForthSections?.map((obj, itemIndex) => {
                    const isActive =
                      editSection?.section_type === obj?.blockName &&
                      editSection?.section_id === obj?.id &&
                      editSection?.section_index === itemIndex;
                    console?.log(obj?.content?.imageSize, 'sectionIndex=-=-=-=>>>');
                    const alertType = obj?.content?.alertType || 'info';

                    const currentActiveSection =
                      destinationId === itemIndex &&
                      editSection?.section_type === 'flex_two_grid_two_forthGrid' &&
                      editSection?.section_index === sectionIndex;
                    const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
                    const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                    const handleClick = (e) => {
                      if (
                        e.target.tagName === 'INPUT' ||
                        e.target.type === 'file' ||
                        e.target.tagName === 'A'
                      ) {
                        e.stopPropagation();
                        return;
                      }
                      selectSection(e, obj.id, obj.blockName, itemIndex);
                    };
                    if (obj?.blockName === 'grid') {
                      return (
                        <div
                          key={itemIndex}
                          className={`cursor-pointer border-[2px] transition-all duration-300 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }  hover:border-2 hover:border-blue-500`}
                          onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                        >
                          <AddForthSectionSixGrid
                            flexBoxValue={flexBoxValue}
                            setSectionAdd={setSectionAdd}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={sectionIndex}
                            section={section}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleSubDivClick={handleSubDivClick}
                            selectedIndex={selectedIndex}
                            selectSection={selectSection}
                            dragProps={dragProps}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            dropContainerRef={dropContainerRef}
                            setElementorId={setElementorId}
                            setDropPosition={setDropPosition}
                            setDestinationId={setDestinationId}
                            dropPosition={dropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      );
                    }
                    return (
                      <div
                        className={`cursor-pointer
                        ${
                          abovePosition || belowPosition
                            ? ''
                            : isActive
                            ? 'border-2 border-blue-500'
                            : 'border-2 border-transparent'
                        }
                        ${
                          abovePosition && currentActiveSection
                            ? 'border-t-2 border-red-700 border-dashed'
                            : ''
                        }
                        ${
                          belowPosition && currentActiveSection
                            ? 'border-b-2 border-green-700 border-dashed'
                            : ''
                        }
                        draggable-item hover:border-2 hover:border-blue-500`}
                        onClick={(e) => {
                          selectSection(e, obj?.id, obj?.blockName, itemIndex);
                          openAddSection(e, 'forthGrid', 'setting');
                          handleCopyItem(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex);
                          getContextMenuItem(
                            e,
                            section?.id || section?.section_id,
                            'flex_two_grid_two_forthGrid',
                            sectionIndex,
                            section?.section_type,
                          );
                          setElementorId(section?.id || section?.section_id);
                        }}
                        onDragOver={(e) => {
                          handleDragOver(
                            e,
                            'flex_two_grid_two_forthGrid',
                            sectionIndex,
                            itemIndex,
                            editSection?.section_type,
                            editSection?.section_index,
                            setDropPosition,
                            setDestinationId,
                          );
                        }}
                        onDragLeave={(e) => {
                          e.preventDefault();
                          setDestinationId(null);
                          setDropPosition(null);
                        }}
                        onContextMenu={(e) => {
                          handleRightClick(e, obj, 'flex_two_grid_two', itemIndex, sectionIndex),
                            // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'flex_two_grid_two_forthGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          setEditItem({
                            id: obj?.id,
                            blockName: obj?.blockName,
                            index: itemIndex,
                            sectionType: 'forthGrid',
                            type: 'setting',
                          });
                        }}
                      >
                        {obj?.blockName === 'heading' ? (
                          <>
                            <HeadingSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'image' ? (
                          <div>
                            <ImageSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'textEditor' ? (
                          <div>
                            <TextEditorSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'video' ? (
                          <div>
                            <VideoContentSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'button' ? (
                          <div>
                            <ButtonSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'divider' ? (
                          <div>
                            <DividerSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'spacer' ? (
                          <>
                            <SpacerSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'googleMap' ? (
                          <div>
                            <MapSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'icon' ? (
                          <div>
                            <IconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'imageBox' ? (
                          <div>
                            <ImageBoxSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconBox' ? (
                          <div>
                            <GeneralIconBox obj={obj} />
                          </div>
                        ) : obj?.blockName === 'generalIconList' ? (
                          <div>
                            <GeneralIconList obj={obj} />
                          </div>
                        ) : obj?.blockName === 'counter' ? (
                          <div>
                            <CounterSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'testimonial' ? (
                          <div>
                            <TestimonialSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'tabs' ? (
                          <TabsComponent obj={obj} />
                        ) : obj?.blockName === 'accordion' ? (
                          <AccordionSection section={obj?.content} obj={obj} />
                        ) : obj?.blockName === 'socialIcon' ? (
                          <div className='flex justify-center space-x-4'>
                            <SocialIconSection obj={obj} />
                          </div>
                        ) : obj?.blockName === 'alert' ? (
                          <>
                            <AlertSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'html' ? (
                          <>
                            <HtmlSection obj={obj} />
                          </>
                        ) : obj?.blockName === 'rating' ? (
                          <RatingSection obj={obj} />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </ReactDragListView>
              </div>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>
        <RenderContextMenu
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          handleMenuItemClick={handleMenuItemClick}
          selectedSingleItem={selectedSingleItem}
          setMenuVisible={setMenuVisible}
          setCopiedItem={setCopiedItem}
          handleRemoveSingleItem={handleRemoveSingleItem}
          handleDuplicateSingleItem={handleDuplicateSingleItem}
          sectionIndex={sectionIndex}
          handleCopySingleItem={handleCopySingleItem}
          handleCutSingleItem={handleCutSingleItem}
          newItem={newItem}
          cutItem={cutItem}
          setCutItem={setCutItem}
          copyItem={copyItem}
          selectSection={selectSection}
          openAddSection={openAddSection}
          isEdit={isEdit}
        />
        <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, 'flex_two_grid_two')} className='mr-2'>
            +
          </button>
          <button onClick={() => removeSection(sectionIndex, 'flex_two_grid_two')} className='ml-2'>
            ×
          </button>
        </div>
      </div>
    </>
  );
};

export default TwelveSectionAdd;
