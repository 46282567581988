import { Skeleton } from '@mui/material';
import { ArrowLeft, ChevronDown, Code, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectCategories from '../../controllers/SelectCategories';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
function UpdateTab() {
  const params = useParams();
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState();
  const [err, setErr] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [tabData, setTabData] = useState({
    title: '',
  });
  const [description, setDescription] = useState();
  const [perticulartab, setPerticularTab] = useState();
  const [formSpinner, setFormSpinner] = useState(true);
  const GetPerticularTab = async () => {
    setFormSpinner(true);
    try {
      const ApiCall = await API.get(`/admin/extratab/getparticularExtratab/${params?.tabid}`);
      const result = decryptData(ApiCall?.data)
      setPerticularTab(result?.data);
      setTabData({
        title: result?.data?.tab_title,
      });
      setDescription(result?.data?.tab_description);
      setFormSpinner(false);
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedProduct, setSelectedProduct] = useState([]);
  const handleGetSelectedProduct = async () => {
    try {
      let getProductsForExtraTab = await API.get(
        `/admin/extratab/GetProductsForExtraTab?extratabs=${params?.tabid}&extraTab_exist=1`,
      );
      const result = decryptData(getProductsForExtraTab?.data)
      setSelectedProduct(
        result?.data?.records?.length > 0 &&
        result?.data?.records?.map((x) => {
          return { name: x?.product_name, product: x?._id, image: x?.image };
        }),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleGetSelectedCategory = async () => {
    try {
      let getCatForExtraTab = await API.get(
        `/admin/extratab/GetCategoryForExtraTab?extratabs=${params?.tabid}`,
      );
      const result = decryptData(getCatForExtraTab?.data)
      setSelectCategory(
        result?.data?.records?.length > 0 &&
        result?.data?.records?.map((x) => {
          return { category_name: x?.category_name, _id: x?.category_id, image: x?.image };
        }),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const [selectedRemove, setSelectedRemove] = useState([]);
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    setSelectedRemove([...selectedRemove, id]);
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };
  const [getCategory, setGetCategory] = useState();
  const handleGetCategory = async () => {
    try {
      let getCategoryForExtraTab = await API.get(`/admin/category/GetCategoriesWithAllParent`);
      const result = decryptData(getCategoryForExtraTab?.data)
      setGetCategory(result?.data?.records);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetPerticularTab();
    handleGetSelectedProduct();
    handleGetCategory();
    handleGetSelectedCategory();
  }, []);

  // Quill Emmpty check
  const strippedContent = description?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const handleChange = (e) => {
    setIsChange(true);
    const name = e.target.name;
    const value = e.target.value;
    setTabData({ ...tabData, [name]: value });
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      await addCategoryInExtraTab();
      await addProductInExtraTab();
      const payload = encryptData({
        tab_title: tabData ? tabData.title : '',
        tab_description: description,
        tab_status: perticulartab.tab_status,
        product: selectedProduct?.map((x) => x?.product),
        category: selectCategory?.map((x) => x?._id),
      })
      await API.put(`/admin/extratab/updateExtratab/${perticulartab._id}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/tabs',
        title: 'edit tab',
      });
      navigate('/tabs');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setIsChange(true);
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/tabs',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit tab',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/tabs',
        title: 'edit tab',
      });
    } else {
      navigate('/tabs');
    }
  };
  const [selectCategory, setSelectCategory] = useState([]);
  const handleRemoveCategory = (id) => {
    const removeProduct = selectCategory.filter((x) => x?._id !== id);
    setSelectCategory(removeProduct);
  };
  const addProductInExtraTab = async () => {
    removeExtraTabInProduct();
    if (selectedProduct?.length > 0) {
      try {
        const payload = encryptData({
          extratabs: params?.tabid,
          product: selectedProduct?.map((x) => x?.product),
        })
        await API.post(`/admin/extratab/addExtraTabInProduct`, payload);
        return
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      return
    }
  };
  const addCategoryInExtraTab = async () => {
    if (selectCategory?.length > 0) {
      try {
        const payload = encryptData({
          extratabs: params?.tabid,
          category: selectCategory?.map((x) => x?._id),
        })
        await API.post(`/admin/extratab/addExtraTabInCategory`, payload);
        return
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      return
    }
  };
  const removeExtraTabInProduct = async () => {
    if (selectedRemove?.length > 0) {
      try {
        const payload = encryptData({
          extratabs: params?.tabid,
          id: selectedRemove?.map((x) => x),
        })
        await API.post(`/admin/extratab/removeExtraTabInProduct`, payload);
        return
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      return
    }
  };
  const [openCategoryOptions, setOpenCategoryOptions] = useState(false);
  const closeCategoryModal = () => {
    setOpenCategoryOptions(false);
  };
  const getSelectedCategory = (value) => {
    setSelectCategory(value);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/extratab/GetProductsForExtraTab?extratabs=${params?.tabid}&extraTab_exist=2`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center pb-4 space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_custom_tabs'), path: '/tabs' },
                { name: t('lang_Update_Custom_Tab'), path: '/tabs/update-tab' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_Custom_Tab')} - {perticulartab?.tab_title}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'
          >
            {formSpinner ? (
              <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[150px]' variant='rounded-[6px]' />
                </div>
              </div>
            ) : (
              <>
                <div className='secondary-bg-color rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='mb-4'>
                    <label htmlFor='title' className='capitalize'>
                      {t('lang_title')}<span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-md focus:outline-none'
                      type='text'
                      name='title'
                      placeholder='Enter tab title'
                      value={tabData.title}
                      onChange={(e) => handleChange(e)}
                    />
                    {err && tabData?.title?.length === 0 ? (
                      <p className='text-red-500 text-sm font-medium'>
                        {t('lang_Please_Enter_Tab_Title')}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='mb-4'>
                    <div className='flex justify-between items-center'>
                      <label htmlFor='description'>{t('lang_description')}</label>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={handleViewHtml}
                      >
                        <Code className='w-[15px] h-[15px] me-1 capitalize' />{' '}
                        {t('lang_source_code')}
                      </span>
                    </div>
                    <ReactQuill
                      ref={quillRef}
                      modules={module}
                      theme='snow'
                      value={description}
                      onChange={handleQuillChange}
                    />
                  </div>
                </div>
                <details className='secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div className='capitalize'>
                      {t('lang_categories')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Select_Category_For_Add_In_This_Tab')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>

                  <div className='w-full text-center'>
                    {selectCategory?.length > 0 ? (
                      <div className='max-h-[450px] overflow-y-auto divide-y'>
                        {selectCategory?.map((elem, index) => {
                          return (
                            <div key={index} className='flex items-center py-2 space-x-3'>
                              <button
                                className='close-btn'
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRemoveCategory(elem?._id);
                                }}
                              >
                                <X className='w-[14px] h-[14px] text-red-600' />
                              </button>
                              <div className='w-[50px] h-[50px] p-0.5 border border-gray-200 flex items-center justify-center'>
                                <img
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem?.image || NOPHOTO_IMAGE}
                                  className='max-w-full max-h-full'
                                  alt={elem?.category_name}
                                />
                              </div>
                              <p className='text-sm'>{elem?.category_name}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <button
                        className='bulk-btn mt-4'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCategoryOptions(true);
                        }}
                      >
                        {t('lang_Select_Categories')}
                      </button>
                    )}
                    {selectCategory?.length > 0 && (
                      <>
                        <div className='flex items-center border-t border-gray-300 justify-between pt-2'>
                          <button
                            className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenCategoryOptions(true);
                            }}
                          >
                            + {t('lang_Select_More_Categories')}
                          </button>
                          <button
                            className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectCategory([]);
                            }}
                          >
                            <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                            Remove all categories
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </details>
                <details className='secondary-bg-color cursor-pointer group shadow rounded-[6px] p-5 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_products')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Select_Category_For_Add_In_This_Tab')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='mt-4 main-border-color' />
                  <div className='w-full text-center'>
                    {selectedProduct?.length > 0 ? (
                      <div className='max-h-[450px] overflow-y-auto divide-y'>
                        {selectedProduct?.map((elem) => {
                          return (
                            <div className='flex items-center py-2 main-border-color space-x-3'>
                              <button
                                onClick={(e) => removeSelectProduct(e, elem?.product)}
                                className='close-btn'
                              >
                                <X className='w-[14px] h-[14px] text-red-600' />
                              </button>
                              <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                <img
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem?.image || NOPHOTO_IMAGE}
                                  className='max-w-full max-h-full'
                                  alt={elem?.name}
                                />
                              </div>
                              <p className='text-sm'>{elem?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <button
                        className='bulk-btn mt-4'
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSelectProduct(true);
                        }}
                      >
                        {t('lang_Select_Product')}
                      </button>
                    )}
                    {selectedProduct?.length > 0 && (
                      <>
                        <div className='flex items-center border-t main-border-color justify-between pt-2'>
                          <button
                            className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setIsSelectProduct(true);
                            }}
                          >
                            + {t('lang_select_more_product')}
                          </button>
                          <button
                            className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedRemove(selectedProduct?.map((x) => x?.product));
                              setSelectedProduct([]);
                            }}
                          >
                            <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                            {t('lang_remove_all_product')}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </details>
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Custom_Tabs')}
            </Link>
            <div>
              {tabData?.title === '' || trimmedContent === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Custom_Tab')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <button
                  onClick={(e) => HandleSubmit(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Custom_Tab')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
      {openCategoryOptions && (
        <SelectCategories
          openCategoryOptions={openCategoryOptions}
          closeCategoryModal={closeCategoryModal}
          api={`/admin/category/GetCategoriesWithAllParent`}
          alreadySelectedCategories={selectCategory}
          getSelectedCategory={getSelectedCategory}
        />
      )}
    </>
  );
}

export default UpdateTab;
