import React from 'react';
import EditReassuranceStyleContainer from './EditReassuranceStylecontainer';

const EditReassuranceStyleSection = ({
  reassuranceDesign,
  setReassuranceDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditReassuranceStyleContainer
        reassuranceDesign={reassuranceDesign}
        setReassuranceDesign={setReassuranceDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditReassuranceStyleSection;
