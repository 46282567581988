import { Autocomplete, Backdrop, CircularProgress, Dialog, TextField } from '@mui/material';
import { ArrowLeft, ArrowRight, Check, Copy, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { PRODUCT_SAMPLE_CSV } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import LinearWithValueLabel from '../../controllers/LinearProgressWithLabel';
import { Transition } from '../../controllers/Transition';
import { adminSocket } from '../../utilities/socket';
import Loader from '../Loader/Loader';
import Notification from '../Notification';
const ImportOwnProduct = ({ reGenerateProcess, reGenerateProcessing, setReGenerateProcess, setReGenerateProcessing }) => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  // Import Products From .CSV
  const [loader, setLoader] = useState(false);
  const [csvErr, setCSVErr] = useState(false);
  const [csvFile, setCSVFile] = useState();
  const [csvname, setCSVname] = useState();
  const [importDataSummary, setImportDataSummary] = useState();
  const [existingImportPU, setExistingImportPU] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const [socketConnection, setSocketConnection] = useState(false);

  useEffect(() => {
    if (adminSocket.connected) {
      setSocketConnection(true);
    } else {
      setSocketConnection(false);
    }
  }, [setSocketConnection]);
  useEffect(() => {
    if (socketConnection) {
      adminSocket.on('progress', async (data) => {
        setReGenerateProcessing(true);
        setReGenerateProcess(Number(data?.percentage))
        if (data?.percentage >= 100) {
          setReGenerateProcessing(false);
          setImportLoader(false);
          setExistingImportPU(true);
        }
      });
    } else {
      setImportLoader(false);
    }
    adminSocket.on('progress_result', async (data) => {
      setImportDataSummary(data);
    })

    return () => {
      adminSocket.off('progress');
      adminSocket.off('progress_result');
    };
  }, [socketConnection])
  const HandleSvg = async (file) => {
    if (file) {
      setLoader(true);
      let fileName = file?.name;
      setCSVname('');
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      let parts = file?.name?.split('.');
      let Ext = parts ? parts[parts?.length - 1] : 'jpg';
      if (Ext === 'csv') {
        setCSVErr(false);
        const formData = new FormData();
        formData.append('file', file, fileName);
        formData.append('folder', 'productCSV');
        try {
          const res = await API.post('/admin/fileupload/upload', formData);
          const result = decryptData(res?.data)
          setCSVFile(result?.data[0]);
          setCSVname(file?.name);
          setIsNotification(true);
          setNotificationMsg('CSV uploaded successfully');
          setSeverity('success');
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      } else {
        setCSVErr(true);
      }
      setLoader(false);
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //   Import Products
  const importProducts = async () => {
    setImportLoader(true);
    try {
      const payload = encryptData({
        url: csvFile,
      })
      let ApiCall = await API.post(`/admin/product/ImportProductForOwn`, payload);
      const result = decryptData(ApiCall?.data)
      setImportDataSummary(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  return (
    <div className='h-auto overflow-y-auto relative pb-[130px]'>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {importLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={importLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_Importing_products_Please_wait')}</p>
        </Backdrop>
      )}
      <div className='flex items-center space-x-2 pb-[30px]'>
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate('/products');
          }}
          className='back-arrow'
        >
          <ArrowLeft />
        </button>
        <div>
          <Breadcrumbs
            crumbs={[
              { name: t('lang_dashboard'), path: `/dashboard` },
              { name: t('lang_products'), path: '/products' },
              { name: t('lang_Bulk_upload_product'), path: '/products/bulk-upload' },
            ]}
          />
          <h4 className='text-xl md:text-2xl font-semibold text-primary'>
            {t('lang_Bulk_upload_product')}
          </h4>
        </div>
      </div>
      <div></div>
      <div className='w-full lg:w-[70%] xl:w-[60%] secondary-bg-color p-5 sm:p-[25px] mx-auto rounded-lg'>
        <div>
          <p className='text-lg font-medium'>{t('lang_Upload_products_from_CSV_file')}</p>
          <p className='text-sm'>
            {t('lang_Download_a')}{' '}
            <a className='text-link font-medium hover:underline' href={PRODUCT_SAMPLE_CSV} download>
              {t('lang_Sample_CSV_file')}{' '}
            </a>{' '}
            {t('lang_To_See_an_example_of_the_format_required')}
          </p>
        </div>
        {existingImportPU ? (
          <>
            <div className='primary-bg-color my-4 p-5'>
              <p className='text-xl font-medium'>{t('lang_Imported_Product_Summary')}</p>

              {loader ? (
                <Loader />
              ) : (
                <div className=' mt-2'>
                  {/* Import Summary */}
                  <div className='flex justify-between'>
                    <div className='flex items-center justify-center space-x-1 lowercase text-green-600 bg-green-200 text-sm rounded-[6px] px-2 py-1'>
                      <Check className='w-4' />
                      <span>
                        {t('lang_Successfully_Imported')}:<b>{importDataSummary?.insertedCount}</b>
                      </span>
                    </div>
                    <div className='flex items-center justify-center space-x-1 lowercase text-black-600 bg-blue-200 text-sm rounded-[6px] px-2 py-1'>
                      <Copy className='w-4' />
                      <span>
                        {t('lang_Existing_Products')}:
                        <b>{importDataSummary?.existingProductCount}</b>
                      </span>
                    </div>
                    <div className='flex items-center justify-center space-x-1 lowercase text-red-600 bg-red-200 text-sm rounded-[6px] px-2 py-1'>
                      <X className='w-4' />
                      <span>
                        {t('lang_Failed_Imports')}:<b>{importDataSummary?.failedCount}</b>
                      </span>
                    </div>
                    <div className='flex items-center justify-center space-x-1 lowercase text-red-600 bg-red-200 text-sm rounded-[6px] px-2 py-1'>
                      <X className='w-4' />
                      <span>
                        {t('lang_Failed_Image_Imports')}:
                        <b>{importDataSummary?.failedImageCount}</b>
                      </span>
                    </div>
                  </div>
                  <hr className='mt-1' />

                  {/* Failed Imports Message */}
                  {importDataSummary?.failedCount > 0 && (
                    <div className=' my-4'>
                      <span className='text-xl font-medium'>
                        {t('lang_Possible_Failed_Import_Reasons')}:
                      </span>
                      <Autocomplete
                        disablePortal
                        className='my-2 lowercase autocomplete'
                        id='combo-box-demo'
                        options={importDataSummary?.failedProducts}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            {...params}
                            label='Failed products'
                          />
                        )}
                      />
                      <ul className='pl-1'>
                        <li className='flex space-x-2 font-medium'>
                          <ArrowRight className='w-4' />
                          <p>{t('lang_Not_proper_or_corrupted_csv_file')} </p>
                        </li>
                        <li className='flex space-x-2 font-medium'>
                          <ArrowRight className='w-4' />
                          <p>{t('lang_InApropriate_title')}</p>
                        </li>
                        <li className='flex space-x-2 font-medium'>
                          <ArrowRight className='w-4' />
                          <p>{t('lang_InApropriate_Variant_price_field')}</p>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Link
              to={`/products`}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_to_Products')}
            </Link>
          </>
        ) : (
          <>
            <label
              htmlFor='dropzone-file'
              className='flex flex-col file-tag-hover items-center my-4 p-3 justify-center border border-dashed hover:border-gray-400 main-border-color rounded-[6px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
            >
              <label className='text-center cursor-pointer w-full'>
                {csvFile ? (
                  <div className='w-full flex justify-between items-center whitespace-nowrap text-center'>
                    <p className='font-medium table-text capitalize'>
                      {csvname} {t('lang_uploaded')}
                    </p>
                    <button className='bulk-btn' onClick={() => setCSVFile('')}>
                      {t('lang_IReplac_csv')}
                    </button>
                  </div>
                ) : (
                  <>
                    {loader ? (
                      <div className='nb-spinner'></div>
                    ) : (
                      <>
                        <svg
                          className='w-8 h-8 mb-1 mx-auto table-text dark:text-gray-400'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 20 16'
                        >
                          <path
                            stroke='currentColor'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                          />
                        </svg>
                        <p className='mb-1 text-sm table-text dark:text-gray-400'>
                          <span className='font-medium'>{t('lang_Click_To_Upload')}</span>{' '}
                          {t('lang_or_drag_and_drop')}
                        </p>
                        {/* <p className='text-md font-medium mb-1 table-text dark:text-gray-400'>
                          {t('lang_Drop_Your_CSV_File_Here')}
                        </p> */}
                        <div className='flex flex-wrap gap-1 items-center justify-center'>
                          <span className='text-md font-medium mb-1 table-text dark:text-gray-400'>{t('lang_Drop_Your')}</span> <span className='tab focus-tab inline-block rounded-full'>{t('lang_CSV')}</span><span className='text-md font-medium mb-1 table-text dark:text-gray-400'>{t('lang_File_Here')}</span>
                        </div>
                      </>
                    )}{' '}
                    <input
                      id='dropzone-file'
                      type='file'
                      name='image'
                      onChange={(e) => {
                        HandleSvg(e.target.files[0]);
                      }}
                      className='hidden'
                    />
                  </>
                )}
              </label>
              {csvErr && (
                <span className='text-xs bg-yellow-100 px-5 py-1 rounded-[4px]'>
                  <b className='uppercase'>{t('lang_note')} : </b>{' '}
                  {t('lang_File_must_be_csv_format')}
                </span>
              )}
            </label>
            <div className='inline-flex w-full justify-between'>
              <Link
                to={`/products`}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_to_Products')}
              </Link>
              {csvFile && !reGenerateProcessing ? (
                <button onClick={() => importProducts()} className='btn text-sm text-white'>
                  {t('lang_import_product')}
                </button>
              ) : (
                <button className='cursor-not-allowed btn opacity-60 text-sm text-white'>
                  {t('lang_import_product')}
                </button>
              )}
            </div>
          </>
        )}
      </div>
      {/* {reGenerateProcessing &&
        <Dialog
          open={reGenerateProcessing}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='w-full secondary-bg-color sm:w-[600px] p-6 rounded-[6px]'>
            <div className='flex text-primary items-center justify-between'>
              <p className='text-base table-text'>Please wait for a minitues while image uploading!</p>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <LinearWithValueLabel progress={reGenerateProcess} />
            <p className='text-base table-text'>Do Not Leave the Window while processing! </p>
          </div>
        </Dialog>
      } */}
    </div>
  );
};

export default ImportOwnProduct;
