import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { ArrowLeft, Edit } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { DataLoad } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import AddPortfolioComments from './AddPortfolioComments';
const PerticularPortfolioComment = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [PortfolioComments, setPortfolioComments] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [clearRow, setClearRow] = useState(false);
  const [severity, setSeverity] = useState();
  const GetPortfolioComments = async () => {
    setDataLoading(true);
    try {
      let ApiCall = await API.get(
        `/admin/portfolio/getparticularPortfolioComment/${params?.commentId}`,
      );
      const result = decryptData(ApiCall?.data)
      setPortfolioComments(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  //Disable portfolio Comment
  const DisableComment = async (id, value) => {
    try {
      const payload = encryptData({
        is_visible: value,
      })
      await API.put(`/admin/portfolio/UpdatePortfolioComment/${id}`, payload);
      GetPortfolioComments();
      setIsNotification(true);
      setNotificationMsg(
        value ? 'portfolio comment is set to active' : 'portfolio comment is set to hidden',
      );
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // Disable Many portfolio comments\\
  const UpdateManyComments = async (value) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
        is_visible: value,
      })
      await API.put('/admin/portfolio/UpdateManyPortfolioComment', payload);
      GetPortfolioComments();
      setSelectedRowsId([]);
      setClearRow(!clearRow);
      setIsNotification(true);
      setNotificationMsg(`Comments set to ${value ? 'active' : 'hidden'}`);
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [editCommentData, setEditCommentData] = useState();

  const closeAddComment = (value) => {
    if (value === 'call api') {
      GetPortfolioComments();
      setIsAddComment(false);
    } else {
      setIsAddComment(false);
    }
  };
  useEffect(() => {
    GetPortfolioComments();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [portfolioData, setPortfolioData] = useState();
  useEffect(() => {
    if (PortfolioComments?.data?.records?.length) {
      setPortfolioData(PortfolioComments?.data?.records[0]?.portfolioId);
    }
  }, [PortfolioComments]);
  const [isAddComment, setIsAddComment] = useState(false);
  const commentRef = useRef();

  useEffect(() => {
    if (isAddComment) {
      commentRef.current.focus();
    }
  }, [isAddComment])
  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(
          PortfolioComments?.data?.records?.map((x) => (!x?.default ? x?._id : '')),
        );
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/portfolio-comments');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Portfolio_Comments'), path: '/portfolio-comments' },
                { name: t('lang_View_Comments'), path: '/portfolio-comments/view-comments' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Perticular_Portfolio_comments')}{' '}
            </h4>
          </div>
        </div>
        <div className='w-full'>
          <div className='w-full inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
            <div className='flex p-2 border-b main-border-color bg-white items-center'>
              <div className='h-10 w-10 flex items-center justify-center rounded-full overflow-hidden'>
                <img
                  className='max-w-full max-h-full object-contain object-top'
                  onError={(e) => {
                    e.target.src = NOPHOTO_IMAGE;
                  }}
                  src={portfolioData?.image || NOPHOTO_IMAGE}
                  alt='portfolio image'
                />
              </div>
              <h6 className='text-lg ms-2 font-medium table-primary'>{portfolioData?.title}</h6>
              <div className='flex ms-4 items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={() => UpdateManyComments(false)}
                    >
                      <span>{t('lang_Hide_Comments')}</span>
                    </button>
                    <button
                      className='bulk-btn space-x-2 inline-flex items-center'
                      onClick={() => UpdateManyComments(true)}
                    >
                      <span>{t('lang_Show_Comments')}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
            {dataLoading && !PortfolioComments ? (
              <TableSkeleton forTable='order' />
            ) : PortfolioComments?.data?.records?.length > 0 ? (
              <table className='w-full'>
                <thead>
                  <tr>
                    <td className='px-3 table-head-checkbox'>
                      <Checkbox
                        checked={
                          selectedRowsId?.length === PortfolioComments?.data?.records?.length
                        }
                        indeterminate={
                          selectedRowsId?.length !== PortfolioComments?.data?.records?.length &&
                          selectedRowsId?.length > 0
                        }
                        onChange={() => {
                          handleSelectRow('all');
                        }}
                      />
                    </td>
                    <td className='min-w-[200px] max-w-[200px] px-3 capitalize'>
                      {t('lang_name')}
                    </td>
                    <td className='min-w-[200px] max-w-[200px] px-3 capitalize'>
                      {t('lang_email')}
                    </td>
                    <td className='w-full px-3 capitalize'>{t('lang_comment')}</td>
                    <td className='min-w-[150px] max-w-[150px] px-3 text-right capitalize'>
                      {t('lang_date')}
                    </td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right capitalize'>
                      {t('lang_active')}
                    </td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right capitalize'>
                      {t('lang_action')}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {PortfolioComments?.data?.records?.map((elem, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                          }`}
                      >
                        <td className='px-3 table-body-checkbox'>
                          <Checkbox
                            disabled={elem?.default}
                            checked={
                              selectedRowsId?.length > 0 &&
                              selectedRowsId?.find((x) => x === elem?._id)
                            }
                            onChange={() => handleSelectRow(elem?._id)}
                          />
                        </td>
                        <td className='min-w-[200px] max-w-[200px] px-3'>
                          <p onClick={() => {if(elem?.channel === 'online'){navigate(`/customers/${elem?.user?._id}`)}}}>
                            {elem?.name || '-'}
                          </p>
                        </td>
                        <td className='min-w-[200px] max-w-[200px] px-3'>
                          <p>{elem?.email || '-'}</p>
                        </td>
                        <td className='w-full px-3'>
                          <p className='text-center w-fit'>{elem?.comment ? elem?.comment : '-'}</p>
                        </td>
                        <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                          <div className='flex items-center justify-end w-full text-sm'>
                            <p>{formatDate(elem?.createdAt)}</p>
                          </div>
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                          <div className='w-full text-right'>
                            <label className='relative flex justify-end w-full'>
                              <FormControlLabel
                                checked={elem?.is_visible}
                                control={<MuiSwitch />}
                                onChange={() => {
                                  DisableComment(elem._id, !elem?.is_visible);
                                }}
                              />
                            </label>
                          </div>
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                          <Tooltip title='Edit' arrow>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setIsAddComment(true);
                                setEditCommentData(elem);
                              }}
                            >
                              <Edit className='h-4 w-4 text-black-600 hover:text-black-800' />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <NoRecords />
            )}
          </div>
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate('/portfolio-comments');
            }}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_To_All_Portfolio_Comments')}
          </Link>
        </div>
      </div>
      <AddPortfolioComments
        commentRef={commentRef}
        isAddComment={isAddComment}
        closeAddComment={closeAddComment}
        editCommentData={editCommentData}
        type='edit'
      />
    </>
  );
};

export default PerticularPortfolioComment;
