import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:4040', {
  autoConnect: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 5000,
});

export const adminSocket = io(process.env.REACT_APP_API_URL || 'http://localhost:8090', {
  autoConnect: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 5000,
});
