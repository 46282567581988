import React from 'react';
import PurchaseAdminPlan from '../AdminPurchasePlan/PuchaseAdminPlan';

const PlanUpgrade = ({ permission, closeAdminPlanPopup, isAdminPlanOpen }) => {
  return (
    <div className='w-full h-full flex flex-col items-center mt-10'>
      <h4 className='text-xl md:text-2xl mb-1 font-semibold text-primary'>Upgrade Your Plan</h4>
      <p className='text-center text-base table-text mb-6 font-medium'>Oops! It seems your plan doesn't have the necessary permissions for this page !</p>
      <PurchaseAdminPlan isAdminPlanOpen={isAdminPlanOpen} permission={permission} closeAdminPlanPopup={closeAdminPlanPopup} />
    </div>
  );
};

export default PlanUpgrade;
