import API from "../../API";
import { decryptData, encryptData } from "../../controllers/encryptionUtils";
import { clientData } from "../../controllers/GetClient";
// import Razorpay from 'razorpay';
const AppPlanRazorPay = async (data, planId, planPrice, closePlanPopup, GetModules, callExtention, credentialParams) => {
    const clientInfo = clientData
    const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY_ID;
    if (!razorpayKey) {
        console.error("Razorpay Key is not defined");
        return;
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error("Failed to load Razorpay SDK"));
            };
            document.body.appendChild(script);
        });
    }
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    // const openGooglePopup = () => {
    //     const googleOAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=158518709042-5vbfcavqtfc75hdtb0mg8j2c0720u014.apps.googleusercontent.com&redirect_uri=http://localhost:3000/callback&response_type=token&scope=email%20profile%20openid`;

    //     // Open the Google OAuth popup
    //     const popup = window.open(googleOAuthURL, '_blank', 'width=500,height=600');

    //     const interval = setInterval(() => {
    //         try {
    //             if (
    //                 popup.location.href.includes(
    //                     'http://localhost:3000/callback'
    //                 )
    //             ) {
    //                 clearInterval(interval);

    //                 // Extract the access_token from the URL
    //                 const url = new URL(popup.location);
    //                 const hashParams = new URLSearchParams(url.hash.substr(1));
    //                 const accessToken = hashParams.get('access_token');

    //                 if (accessToken) {
    //                     popup.close();
    //                     closePlanPopup();
    //                     GetModules();
    //                     callExtention();
    //                 }
    //             }
    //         } catch (error) {
    //             console.log(error, 'error');
    //             // Errors will occur until the user is redirected back to your app, so we can ignore them
    //         }
    //     }, 500);
    // };
    if (!res) {
        console.log("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const options = {
        key: razorpayKey, // Replace with your Razorpay Key ID
        amount: Number(planPrice), // Amount in paisa
        currency: 'INR',
        name: 'Shopeasy',
        order_id: planId, // Use the order ID received from backend
        handler: async function (response) {

            let values = {
                extension: data?._id,
                transaction_amount: planPrice,
                razorpay_signature: response.razorpay_signature,
                razorpay_order_id: response.razorpay_order_id,
                transaction_id: response.razorpay_payment_id,
                plan: planId,
                super_extension_id: data?._id,
                extension_name: data?.extension_name
            }
            if (credentialParams) {
                values = { ...values, credentials: credentialParams }
            }
            try {
                const payload = encryptData(values)
                const apicall = await API.post('/admin/extension/AddExtension', payload);
                if (apicall?.data) {
                    if (data?.extension_name === "Google Merchant Store") {
                        try {
                            const googleOAuthPayload = credentialParams.reduce((acc, item) => {
                                acc[item.secret_key] = item.secret_value;
                                return acc;
                            }, {})
                            const payload = encryptData({ credentials: googleOAuthPayload })
                            const googleOAuth2apicall = await API.post(`/admin/extension/GoogleOAuth2`, payload)
                            const result = decryptData(googleOAuth2apicall?.data)
                            if (result?.data) {
                                window.open(result?.data, '_blank')
                                closePlanPopup();
                                GetModules();
                                callExtention()
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        closePlanPopup();
                        GetModules();
                        callExtention()
                    }

                }
            } catch (error) {
                console.log("Error during purchase", error);
                closePlanPopup();
                GetModules();
                callExtention()
            }
        },
        prefill: {
            name: clientInfo?.business_name,
            email: clientInfo?.business_email,
            contact: clientInfo?.business_phone,
        },
        notes: {
            store_id: clientInfo?.store_id,
        },
        theme: {
            color: '#3399cc',
        },
    };


    try {
        const payload = encryptData({
            amount: planPrice,
            extension: data?._id,
            plan: planId
        })
        const createOrderPayment = await API.post("/admin/payment/CreatePaymentExtension", payload);
        if (createOrderPayment.data) {
            const result = decryptData(createOrderPayment?.data)
            options.order_id = result?.id;
            options.amount = result?.amount;
            var rzp1 = new window.Razorpay(options);
            await rzp1.open();
        }
    } catch (error) {
        console.log(error);
    }
}
export default AppPlanRazorPay