import React from 'react';
import EditCrossProductPageStyleContainer from './EditCrossProductPageStyleContainer';

const EditCrossProductPageStyleSection = ({
  crossProductDesign,
  setCrossProductDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditCrossProductPageStyleContainer
        crossProductDesign={crossProductDesign}
        setCrossProductDesign={setCrossProductDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditCrossProductPageStyleSection;
