import { createContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import API from './API';
import './App.css';
import Analytics from './Component/Analytics/Analytics';
import GoogleAnaltics from './Component/Analytics/GoogleAnalytics';
import GoogleCustomeReport from './Component/Analytics/GoogleCustomeReport';
import GoogleRealTime from './Component/Analytics/GoogleReatTime';
import AllAnnounement from './Component/Announcement/AllAnnounement';
import ViewAnnouncement from './Component/Announcement/ViewAnnouncement';
import ChangePassword from './Component/AuthUser/ChangePassword';
import EditProfile from './Component/AuthUser/EditProfile';
import ForgotPassword from './Component/AuthUser/ForgotPassword';
import Login from './Component/AuthUser/Login';
import NewPassword from './Component/AuthUser/NewPassword';
import Profile from './Component/AuthUser/Profile';
import AddBlogCategory from './Component/Blog/AddBCategory';
import AddBlog from './Component/Blog/AddBlog';
import BlogCategories from './Component/Blog/BlogCategory';
import BlogComments from './Component/Blog/BlogComments';
import MyBlogs from './Component/Blog/MyBlogs';
import PerticularBlogComment from './Component/Blog/PerticularBlogComment';
import UpdateBlog from './Component/Blog/UpdateBlog';
import UpdateBlogCategory from './Component/Blog/UpdateBlogCategory';
import Addcategory from './Component/Category/AddCategory';
import CategoryTable from './Component/Category/CategoryTable';
import UpdateCategory from './Component/Category/UpdateCategory';
import AddCustomField from './Component/Custom-field/AddCustomField';
import EditCheckOutField from './Component/Custom-field/EditCheckOutField';
import EditCustomField from './Component/Custom-field/EditCustomField';
import GetCustomers from './Component/Customers/GetCustomers';
import UpdateCustomer from './Component/Customers/UpdateCustomer';
import AddDiscount from './Component/Discount/AddDiscount';
import EditDiscount from './Component/Discount/EditDiscount';
import ViewDiscount from './Component/Discount/ViewDiscount';
import AllEnquirys from './Component/Enquiry/AllEnquirys';
import ShowEnquiry from './Component/Enquiry/ShowEnquiry';
import AddFaq from './Component/Faq/AddFaq';
import Faqs from './Component/Faq/Faqs';
import ShowFaq from './Component/Faq/ShowFaq';
import UpdateFaq from './Component/Faq/UpdateFaq';
import GetFilter from './Component/Filter/GetFilter';
import AddGdpr from './Component/GDPR/AddGdpr';
import UpdateGdpr from './Component/GDPR/UpdateGdpr';
import ViewGdpr from './Component/GDPR/ViewGdpr';
import AddVarient from './Component/GroupVariant/AddVarient';
import AllVariants from './Component/GroupVariant/AllVariants';
import UpdateVarient from './Component/GroupVariant/UpdateVarient';
import Loader from './Component/Loader/Loader';
import CommingSoon from './Component/Main/CommingSoon';
import DashBoard from './Component/Main/DashBoard';
import ErrorPage from './Component/Main/ErrorPage';
import MyStore from './Component/Main/MyStore';
import Navbar from './Component/Main/Navbar';
import PermissionDenied from './Component/Main/PermissionDenied';
import Sidebar from './Component/Main/Sidebar';
import Workspace from './Component/Main/workspace';
import AddManufacturer from './Component/Manufacturer/AddManufacturer';
import AllManufacturer from './Component/Manufacturer/AllManufacturer';
import EditManufacturer from './Component/Manufacturer/EditManufacturer';
import GetMails from './Component/Newslatter/GetMails';
import Newsletter from './Component/Newslatter/Newsletter';
import OrderDetail from './Component/Orders/OrderDetail';
import ReturnOrders from './Component/Orders/ReturnOrders';
import UpdateReturnOrders from './Component/Orders/UpdateReturnOrders';
import ViewOrders from './Component/Orders/ViewOrders';
import MyPages from './Component/Page/MyPages';
import Pages from './Component/Page/Pages';
import UpdatePage from './Component/Page/UpdatePage';
import AddPaymentOption from './Component/Payment/AddPayment';
import AddPCategory from './Component/Portfolio/AddPCategory';
import AddPortfolio from './Component/Portfolio/AddPortfolio';
import MyPortfolios from './Component/Portfolio/MyPortfolios';
import PortfolioCategory from './Component/Portfolio/PortfolioCategory';
import UpdatePortfolio from './Component/Portfolio/UpdatePortfolio';
import UpdatePortfolioCategory from './Component/Portfolio/UpdatePortfolioCategory';
import ProductCatalog from './Component/Product-Catalog/ProductCatalog';
import AddProducts from './Component/Product/AddProducts';
import ProductTable from './Component/Product/ProductTable';
import UpdateProduct from './Component/Product/UpdateProduct';
import UpdateProductDiscount from './Component/Product/UpdateProductDiscount';
import UpdateVariant from './Component/Product/updateVariant';
import ViewProduct from './Component/Product/ViewProduct';
import AddReview from './Component/Reviews/AddReview';
import AllReviews from './Component/Reviews/AllReviews';
import PerticularReviews from './Component/Reviews/PerticularReviews';
import AddRole from './Component/Role/AddRole';
import EditRole from './Component/Role/EditRole';
import GetRole from './Component/Role/GetRole';
import BusinessProfile from './Component/Setting/BusinessProfile';
import Commingsoon from './Component/Setting/CommingSoon';
import ModuleSetting from './Component/Setting/ModuleSetting';
import Setting from './Component/Setting/Setting';
import Social from './Component/Setting/Social';
import StoreSetting from './Component/Setting/StoreSetting';
import AddShipping from './Component/Shipping/AddShipping';
import Chat from './Component/Socket/Chat';
import AddStaff from './Component/Staff/AddTeam';
import UpdateStaff from './Component/Staff/UpdateTeam';
import ViewStaff from './Component/Staff/viewTeam';
import AddTab from './Component/Tab/AddTab';
import UpdateTab from './Component/Tab/UpdateTab';
import ViewTab from './Component/Tab/ViewTab';
// import HelpSupport from './Component/Socket/HelpSupport';
import { Backdrop, Fade, LinearProgress, Modal } from '@mui/material';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Clock, X } from 'feather-icons-react/build/IconComponents';
import { onMessage } from 'firebase/messaging';
import { useRef } from 'react';
import {
  ArrowUp,
  Check2,
  ClipboardPulse,
  ExclamationTriangleFill,
  Hammer,
  LightbulbFill,
} from 'react-bootstrap-icons';
import Countdown from 'react-countdown';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { FAVICON_IMAGE } from './Assets/StaticData/StaticImage';
import PurchaseAdminPlan from './Component/AdminPurchasePlan/PuchaseAdminPlan';
import Mymodules from './Component/App/mymodules';
import AddAttributes from './Component/Attributes/AddAttributes';
import AllAttributes from './Component/Attributes/AllAttributes';
import UpdateAttributes from './Component/Attributes/UpdateAttributes';
import AddBannerGroup from './Component/Banner/AddBannerGroup';
import AllBannerGroup from './Component/Banner/AllBannerGroup';
import EditBannerGroup from './Component/Banner/EditBannerGroup';
import AddBlogComments from './Component/Blog/AddBlogComments';
import AddBlogGroup from './Component/Blog/AddBlogGroup';
import EditBlogGroup from './Component/Blog/EditBlogGroup';
import GetBlogGroup from './Component/Blog/GetBlogGroup';
import ProductBulkEditPage from './Component/BulkEdit/ProductBulkEditPage';
import AddCategoryGroup from './Component/Category/AddCategoryGroup';
import EditCategoryGroup from './Component/Category/EditCategoryGroup';
import GetCategoryGroup from './Component/Category/GetCategoryGroup';
import AddCmsOffer from './Component/CmsOffer/AddCmsOffer';
import AllCmsOffer from './Component/CmsOffer/AllCmsOffer';
import EditCmsOffer from './Component/CmsOffer/EditCmsOffer';
import CustomerProfile from './Component/Customers/CustomerProfile';
import AddFooterGroup from './Component/Footer/AddFooterGroup';
import EditFooterGroup from './Component/Footer/EditFooterGroup';
import FooterCopyright from './Component/Footer/FooterCopyright';
import GetFooterGroup from './Component/Footer/GetFooterGroup';
import AddHeaderGroup from './Component/Header/AddHeaderGroup';
import EditHeaderGroup from './Component/Header/EditHeaderGroup';
import GetHeaderGroup from './Component/Header/GetHeaderGroup';
import EditorNavbar from './Component/Layout/EditorNavbar';
import ThemeCustomizeScreen from './Component/Layout/VisualLayout/ThemeCustomizeScreen';
import AllClientNotification from './Component/Main/AllClientNotification';
import AllStores from './Component/Main/AllStores';
import CreateStore from './Component/Main/CreateStore';
import DeclinedPage from './Component/Main/DeclinedPage';
import DeleteRequestPending from './Component/Main/DeleteRequestPending';
import PlanUpgrade from './Component/Main/PlanUpgrade';
import VerifyEmail from './Component/Main/VerifyEmail';
import AddManufactureGroup from './Component/Manufacturer/AddManufactureGroup';
import EditManufactureGroup from './Component/Manufacturer/EditManufactureGroup';
import GetManufactureGroup from './Component/Manufacturer/GetManufactureGroup';
import ListMetaFieldGroup from './Component/MetaFieldGroup/ListMetaFieldGroup';
import EditorRouter from './Component/NewEditor/EditorRouter';
import Notification from './Component/Notification';
import AbandoneCart from './Component/Orders/AbandoneCart';
import CreateOrder from './Component/Orders/CreateOrder';
import PayoutDetails from './Component/Payment/PayoutDetails';
import PayoutHistory from './Component/Payment/PayoutHistory';
import Payouts from './Component/Payment/Payouts';
import AddPortfolioComments from './Component/Portfolio/AddPortfolioComments';
import PerticularPortfolioComment from './Component/Portfolio/PerticularPortfolioComment';
import PortfolioComments from './Component/Portfolio/PortfolioComments';
import AddProductGroup from './Component/Product/AddProductGroup';
import EditProductGroup from './Component/Product/EditProductGroup';
import GetProductGroup from './Component/Product/GetProductGroup';
import ImportOwnProduct from './Component/Product/ImportOwnProduct';
import ShopifyImport from './Component/Product/ShopifyImport';
import WordpressImport from './Component/Product/WordpressImport';
import AddReassuranceGroup from './Component/Reassurance/AddReassuranceGroup';
import EditReassuranceGroup from './Component/Reassurance/EditReassuranceGroup';
import GetReassuranceGroup from './Component/Reassurance/GetReassuranceGroup';
import CommonSetting from './Component/Setting/CommonSetting';
import DeleteLogs from './Component/Setting/DeleteLogs';
import AddSliderGroup from './Component/Slider/AddSliderGroup';
import AllSliderGroup from './Component/Slider/AllSliderGroup';
import EditSliderGroup from './Component/Slider/EditSliderGroup';
import StaffVarification from './Component/Staff/StaffVarification';
import Subscriptions from './Component/Subscriptions/subscription';
import timeAgo from './controllers/DateTime';
import { decryptData, encryptData } from './controllers/encryptionUtils';
import { clientData, GetClient } from './controllers/GetClient';
import SaveChangesPopup from './controllers/SaveChangesPopup';
import ShortCustFunc from './controllers/ShortCustFunc';
import { generateToken, messaging } from './firebase';
import LinearWithValueLabel from './controllers/LinearProgressWithLabel';
import ProductSubscription from './Component/Product-Subscription/ProductSubscription';
import AddSubscriptionPlan from './Component/Product-Subscription/AddSubscriptionPlan';
import Contract from './Component/Product-Subscription/Contract';
import ViewContract from './Component/Product-Subscription/ViewContract';
import UpdateSubscriptionPlan from './Component/Product-Subscription/UpdateSubscriptionPlan';
import NetworkStatus from './controllers/NetworkStatus';
import PosStaff from './Component/POS/Staff/PosStaff';
import AddPosStaff from './Component/POS/Staff/AddPosStaff';
import UpdatePosStaff from './Component/POS/Staff/UpdatePosStaff';
import PosLocations from './Component/POS/Locations/PosLocations';
import AddPosLocation from './Component/POS/Locations/AddPosLocation';
import UpdatePosLocation from './Component/POS/Locations/UpdatePosLocation';
import Files from './Component/Content/Files';
import ViewDynamicForms from './Component/Dynamic-forms/ViewDynamicForms';
import AddForms from './Component/Dynamic-forms/AddForms';
import UpdateForms from './Component/Dynamic-forms/UpdateForms';
import Register from './Component/AuthUser/Register';
function getFaviconEl() {
  return document.getElementById('favicon');
}
export const ChangePathContext = createContext();
export const PlanPermission = createContext();
export const DataLoad = createContext();
export const SelectSection = createContext();
export const Extention = createContext();
export const StoreDeletePending = createContext();
function App() {
  const location = useLocation();
  const [storeDeleteRequestPending, setStoreDeleteRequestPending] = useState(false);
  const clientInfo = clientData;

  const [shouldShowMessenger, setShouldShowMessenger] = useState(true);

  const restrictedPaths = ['/layout', '/layout/home'];

  const checkIfMessengerShouldShow = (pathname) => {
    return !restrictedPaths.includes(pathname);
  };

  useEffect(() => {
    setShouldShowMessenger(checkIfMessengerShouldShow(location.pathname));
  }, [location.pathname]);

  console.log(window.location.pathname, 'window.location=-=-=->>');

  let token = localStorage.getItem('token');
  // useEffect(() => {
  //   if (token) {
  //     navigate('/allstores')
  //   } else {
  //     navigate('/')
  //   }
  // }, [])
  const [notificationCount, setNotificationCount] = useState();
  useEffect(() => {
    const isInIframe = window !== window.top;
    if (isInIframe) {
      // Take appropriate actions, e.g., redirect or display a warning:
      if (window.top !== window.self) {
        // Check for cross-origin iframe
        window.top.location.href = window.location.href; // Redirect to top-level window
      } else {
        // window.top.location.href = window.top.location.href
        // Display a warning message within the same-origin iframe
        alert('This page is loaded in an iframe. For security reasons, please access it directly.');
      }
    }
  }, []);
  // useEffect(() => {
  //   if (!token) {
  //     navigate('/')
  //   } else if (token && clientInfo?.billing_status === true) {
  //     navigate('/dashboard')
  //   }
  // }, [token])
  useEffect(() => {
    if (token && !localStorage.getItem('fcmToken')) generateToken();
    onMessage(messaging, (payload) => {
      // Customize notification here
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: payload.data.icon,
      });
    });
  }, []);
  const tawkMessengerRef = useRef();
  const endpoints = [
    '/forgot-password',
    '/stores',
    '/createstore',
    '/register',
    '/decline-page',
    '/setnewpassword',
    '/staff-verification',
    '/verify',
  ];
  const checkIfUrlIncluded = (url) => {
    return endpoints.some((endpoint) => url.includes(endpoint));
  };
  const [isIncluded, setIsIncluded] = useState(true);
  useEffect(() => {
    if (location?.pathname?.length > 1) {
      setIsIncluded(checkIfUrlIncluded(location.pathname));
    } else {
      setIsIncluded(true);
    }
  }, [location.pathname]);
  const navigate = useNavigate();
  const [Permissions, setPermissions] = useState();
  const [installedExtensions, setInstalledExtensions] = useState([]);
  const inputs = document.querySelectorAll('input[type="number"]');
  for (const input of inputs) {
    input.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }
  const handleFavicon = () => {
    const favicon = getFaviconEl();
    favicon.href = `${FAVICON_IMAGE}`;
  };
  const getCurrencyRate = async () => {
    try {
      const ApiCall = await API.get(`/admin/commondata/currencyrate`);
      if(ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        localStorage.setItem('currencyrate', JSON.stringify(result?.currency_rate ?? []))
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    GetClient();
    getCurrencyRate();
  }, []);
  handleFavicon();
  let subpopup = sessionStorage.getItem('subpopup');
  // !subpopup || subpopup === 'true' ? subpopup = true : subpopup = false
  const [subscriptionsessionpopup, setSubscriptionSessionPopup] = useState(true);
  const [subscriptionPopUp, setSubscriptionPopUp] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [spinner, setSpinner] = useState(token && !isIncluded ? true : false);
  const [planActive, setPlanActive] = useState(false);
  const inputRef = useRef(null);
  let business_name = localStorage.getItem('business_name');
  const [regisData, setRegisData]  = useState({});
  const CheckPlanStatus = async () => {
    if (business_name?.length > 0) {
      setSpinner(true);
      try {
        await API.get(`/admin/plan/planStatusCheck`);
        setPlanActive(true);
        await GetInstalledExtensions();
        await GetUserPermissions();
        await GetAnnouncemnet();
      } catch (error) {
        if (error?.response?.status === 498) {
          setPlanActive(false);
          setSubscriptionPopUp(true);
        } else {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      } finally {
        setSpinner(false);
      }
    } else {
      setPlanActive(false);
    }
  };
  useEffect(() => {
    CheckPlanStatus();
  }, [token]);

  useEffect(() => {
    if (!token && !isIncluded) {
      navigate('/');
    }
  }, [window.location.href]);

  const GetInstalledExtensions = async () => {
    try {
      const ApiCall = await API.get(`/admin/extension/GetInstalledExtensions`);
      if (ApiCall.data) {
        const result = decryptData(ApiCall?.data);
        setInstalledExtensions(result?.data);
        return result?.data;
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const GetUserPermissions = async () => {
    try {
      let ApiCall = await API.get('/admin/role/getLoginUserRole');
      const result = decryptData(ApiCall?.data);
      setPermissions(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    localStorage.setItem('client', clientInfo?._id);
  }, [clientInfo]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    if (location?.pathname?.includes('layout') && location?.pathname?.split('/')?.length > 2) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [window?.location?.href]);

  const moment = require('moment-timezone');
  const currentDate = moment();
  const subscriptionEndDate = moment(clientInfo?.billing_detail?.next_billing_date);
  const timeLeft = subscriptionEndDate.diff(currentDate, 'days');
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // Get Announcement
  const [announcement, setAnnouncement] = useState(false);
  const [AnnData, setAnnData] = useState();
  const GetAnnouncemnet = async () => {
    try {
      let ApiCall = await API.get(`/admin/announcement/UserGetAnnouncements?type=1&limit=3&page=1`);
      if (ApiCall?.data) {
        const result = decryptData(ApiCall?.data);
        setAnnouncement(true);
        setAnnData(result);
      } else {
        setAnnouncement(false);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // Close Announcement
  const CloseAnnouncement = async () => {
    let body;
    if (AnnData?.data?.records?.length === 1) {
      body = { id: AnnData?.data?.records[0]?._id };
    } else {
      body = { id: AnnData?.data?.records?.map((x) => x._id) };
    }
    try {
      const payload = encryptData(body);
      await API.post(`/admin/announcement/UserReadAnnouncement`, payload);
      setAnnouncement(false);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [crumbs, setCrumbs] = useState([]);
  const handleBreadCrumb = (dataFromChild) => {
    setCrumbs(dataFromChild);
  };
  const [saveChanges, setSaveChanges] = useState({
    isUnsaveChanges: false,
    showChangesPopup: false,
    backLink: '',
    title: '',
  });
  const [planPermission, setPlanPermission] = useState({
    isPlanPermissions: true,
    forModule: null,
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [bulkEditProductLoader, setBulkEditProductLoader] = useState(false);
  const [reGenerateProcessing, setReGenerateProcessing] = useState(false);
  const [reGenerateProcess, setReGenerateProcess] = useState(0);
  useEffect(() => {
    setPlanPermission({ ...PlanPermission, isPlanPermissions: true });
    // setDataLoading(true)
  }, [window?.location?.href]);
  // Google Translate Tool code
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  const [themeSetting, setThemeSetting] = useState(false);
  const handleTheameSetting = (value) => {
    setThemeSetting(value);
  };
  const [page, setPage] = useState();
  const handlepPageChange = (value) => {
    setPage(value);
  };

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then((registration) => {
        // Register Firebase service worker with custom options
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  }
  const callExtention = () => {
    GetInstalledExtensions();
  };
  const installExtentionName =
    installedExtensions?.length > 0 ? installedExtensions?.map((x) => x?.extension_name) : [];
  const [isAdminPlanOpen, setIsAdminPlanOpen] = useState(false);
  useEffect(() => {
    setIsAdminPlanOpen(token && clientInfo?.billing_status === false ? true : false);
  }, [clientInfo, token]);

  // const closeAdminPlanPopup = (success) => {
  //   // setIsAdminPlanOpen(false);
  //   if (success === 'success') {
  //     setIsNotification(true);
  //     setNotificationMsg('Plan Purchase Successfully!');
  //     setSeverity('success');
  //     GetClient();
  //   }
  // };

  const closeAdminPlanPopup = (success, closePopup = true) => {
    if (success === 'success') {
      setIsNotification(true);
      setNotificationMsg('Plan Purchase Successfully!');
      setSeverity('success');
      GetClient();
    }

    if (closePopup) {
      setIsAdminPlanOpen(false); 
    }
  };

  const subscrptionLine =
    (planActive && clientInfo?.billing_detail?.next_billing_date && token && !isIncluded) ||
    (planActive && clientInfo?.billing_detail?.plan_type === 'Free' && !isIncluded);

  return (
    <>
      <StoreDeletePending.Provider
        value={{ storeDeleteRequestPending, setStoreDeleteRequestPending }}
      >
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        {token && clientInfo?.billing_status === false && isAdminPlanOpen ? (
          <PurchaseAdminPlan
            type={'expired'}
            isAdminPlanOpen={isAdminPlanOpen}
            closeAdminPlanPopup={(success) => closeAdminPlanPopup(success, false)} 
            isShowCloseButton={false}
          />
        ) : spinner ? (
          <div className='h-screen w-screen flex items-center justify-center'>
            <Loader />
          </div>
        ) : (
          <div className='relative h-screen w-screen primary-bg-color overflow-hidden'>
            <div
              className={`w-full absolute z-[99] h-full top-0 left-0 ${
                dataLoading || bulkEditProductLoader || reGenerateProcessing ? 'block' : 'hidden'
              }`}
            >
              {reGenerateProcessing ? (
                <LinearWithValueLabel progress={reGenerateProcess} />
              ) : (
                <LinearProgress />
              )}
            </div>
            {/* <div className={`w-full absolute h-full top-0 left-0 ${reGenerateProcessing ? 'block' : 'hidden'}`}>
                  <LinearWithValueLabel progress={reGenerateProcess} />
                </div> */}
            <div className={isIncluded ? `w-screen h-screen` : `w-screen h-screen overflow-hidden`}>
              {subscriptionsessionpopup && subpopup === null && subscrptionLine && !isIncluded && (
                <>
                  {planActive && clientInfo?.billing_detail?.plan_type === 'Free' ? (
                    <div className='z-40 subscription-line border-b border-gray-600 flex items-center px-3 w-full text-white max-w-full h-[5%] text-center top-0 '>
                      <div className='flex flex-wrap items-center w-full justify-center max-w-full text-sm'>
                        <p className='flex mr-1 items-center text-xs sm:text-sm'>
                          Unlock more features by upgrading your plan
                        </p>
                        <button
                          className='inline-block cursor-pointer font-medium lg:-tracking-tighter text-white underline text-xs sm:text-sm  transition duration-300'
                          onClick={() => setIsAdminPlanOpen(true)}
                        >
                          Upgrade Now
                        </button>
                      </div>
                      <button
                        type='button'
                        className='close-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          setSubscriptionSessionPopup(false),
                            sessionStorage.setItem('subpopup', false);
                        }}
                      >
                        <X className='w-4 h-4' />
                      </button>
                    </div>
                  ) : planActive && clientInfo?.billing_detail?.next_billing_date && token ? (
                    <div className='z-40 subscription-line border-b border-gray-600 flex items-center px-3 w-full text-white max-w-full h-[5%] text-center top-0 '>
                      <div className='flex flex-wrap items-center w-full justify-center max-w-full text-sm'>
                        <p className='flex mr-2 items-center text-xs sm:text-sm'>
                          Your <span className='mx-1'>{clientInfo?.billing_detail?.plan_type}</span>{' '}
                          subscription valid till <Clock className='w-4 h-4 ml-2' />
                        </p>
                        {timeLeft > 1 ? (
                          <p>
                            <span className='secondary-bg-color text-black rounded-[6px] w-[18px] h-[18px] sm:w-[22px] sm:h-[22px] me-2 text-xs inline-flex items-center justify-center'>
                              {timeLeft}
                            </span>
                            days
                          </p>
                        ) : timeLeft === 1 ? (
                          `${timeLeft} day `
                        ) : (
                          <Countdown
                            date={new Date(clientInfo?.billing_detail?.next_billing_date)}
                            className='me-2'
                          />
                        )}
                        <button
                          className='inline-block cursor-pointer font-medium lg:-tracking-tighter text-white underline text-xs sm:text-sm  transition duration-300 ms-2'
                          onClick={() => setIsAdminPlanOpen(true)}
                        >
                          Upgrade Now
                        </button>
                      </div>
                      <button
                        type='button'
                        className='close-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          setSubscriptionSessionPopup(false),
                            sessionStorage.setItem('subpopup', false);
                        }}
                      >
                        <X className='w-4 h-4' />
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <ParallaxProvider>
                <ChangePathContext.Provider value={{ saveChanges, setSaveChanges }}>
                  <PlanPermission.Provider value={{ planPermission, setPlanPermission }}>
                    <DataLoad.Provider value={{ dataLoading, setDataLoading }}>
                      <Extention.Provider value={{ installedExtensions, setInstalledExtensions }}>
                        <SaveChangesPopup />
                        <ShortCustFunc inputRef={inputRef} />
                        <div
                          className={`${
                            subpopup === null && subscrptionLine && !isIncluded
                              ? `h-[95%]`
                              : `h-screen`
                          } ${!isIncluded ? 'flex w-full' : 'bg-transparent'}`}
                        >
                          <div
                            className={
                              isSidebarOpen
                                ? 'hidden md:block min-w-[300px] max-w-[300px] transition-all duration-200'
                                : 'min-w-[60px] max-w-[60px] close transition-all duration-200 hidden md:block'
                            }
                          >
                            {planActive && !isIncluded ? (
                              <Sidebar
                                planActive={planActive}
                                isOpen={isSidebarOpen}
                                toggleSidebar={toggleSidebar}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className='w-full h-full overflow-hidden md:rounded-[6px] relative z-40 transition-all duration-500'>
                            {clientInfo && planActive && isIncluded === false ? (
                              <>
                                {location?.pathname?.includes('layout') &&
                                location?.pathname?.split('/')?.length > 2 ? (
                                  <EditorNavbar
                                    themeSetting={themeSetting}
                                    handleTheameSetting={handleTheameSetting}
                                    handlepPageChange={handlepPageChange}
                                  />
                                ) : (
                                  <Navbar
                                    inputRef={inputRef}
                                    notificationCount={notificationCount}
                                    setNotificationCount={setNotificationCount}
                                  />
                                )}
                                <NetworkStatus />
                                {(location.pathname !== '/layout/home') && (
                                  <div className='secondary-bg-color'>
                                    <TawkMessengerReact
                                      propertyId={process.env.REACT_APP_PROPERTY_ID}
                                      widgetId={process.env.REACT_APP_WIDGET_ID}
                                      ref={tawkMessengerRef}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            <div
                              className={`w-full overflow-y-auto primary-bg-color ${
                                !isIncluded ? 'p-[30px] h-full' : ''
                              }`}
                            >
                              {planPermission?.isPlanPermissions && !planPermission?.forModule ? (
                                <Routes>
                                  {/* Auth Routes */}
                                  <Route
                                    path='/'
                                    element={
                                      <Login
                                        subscriptionPopUp={(popupStatus) => {
                                          setSubscriptionPopUp(popupStatus);
                                        }}
                                        token={token}
                                        clientInfo={clientData}
                                        setRegisData={setRegisData}
                                      />
                                    }
                                  />
                                  <Route
                                    path='/profile'
                                    element={
                                      planActive && (
                                        <Profile setIsAdminPlanOpen={setIsAdminPlanOpen} />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/profile/edit-profile'
                                    element={planActive && <EditProfile />}
                                  />
                                  {/* <Route path='/profile/delete-store' element={planActive && <DeleteStore />} /> */}
                                  <Route
                                    path='/change-password'
                                    element={planActive && <ChangePassword />}
                                  />
                                  <Route path='/forgot-password' element={<ForgotPassword />} />
                                  <Route path='/setnewpassword/:id' element={<NewPassword />} />
                                  <Route
                                    path='/staff-verification/:id'
                                    element={<StaffVarification />}
                                  />
                                  <Route path='/dashboard' element={<DashBoard />} />
                                  <Route path='/mystore' element={planActive && <MyStore />} />
                                  <Route path='/stores' element={<AllStores token={token} />} />
                                  <Route
                                    path='/createstore'
                                    element={<CreateStore token={token} regisData={regisData} />}
                                  />
                                  {/* <Route path='/register' element={<CreateStore />} /> */}
                                  <Route path='/register' element={<Register />} />
                                  <Route path='/verify/:secret' element={<VerifyEmail />} />
                                  <Route path='/decline-page' element={<DeclinedPage />} />

                                  <Route
                                    path='/analytics'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Product') ||
                                        Permissions?.data?.permissions === 'all' ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <Analytics />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route path='/workspaces' element={planActive && <Workspace />} />
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Google Analytics') && (
                                      <>
                                        <Route
                                          path='/googlesnapshotreport'
                                          element={planActive && <GoogleAnaltics />}
                                        />
                                        <Route
                                          path='/googlerealtime'
                                          element={planActive && <GoogleRealTime />}
                                        />
                                        <Route
                                          path='/googlecustomereport'
                                          element={planActive && <GoogleCustomeReport />}
                                        />
                                      </>
                                    )}

                                  {/* Product Auth Routes */}
                                  <Route
                                    path='/products/add-products'
                                    element={
                                      planActive && (
                                        <AddProducts
                                          reGenerateProcess={reGenerateProcess}
                                          setReGenerateProcess={setReGenerateProcess}
                                          reGenerateProcessing={reGenerateProcessing}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                        />
                                      )
                                    }
                                  />

                                  <Route
                                    path='/products'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Product') ||
                                        Permissions?.data?.permissions === 'all' ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <ProductTable
                                          bulkEditProductLoader={bulkEditProductLoader}
                                        />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/products/:slugname'
                                    element={
                                      planActive && (
                                        <UpdateProduct
                                          reGenerateProcess={reGenerateProcess}
                                          setReGenerateProcess={setReGenerateProcess}
                                          reGenerateProcessing={reGenerateProcessing}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                        />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/products/bulk-edit/products'
                                    element={
                                      planActive && (
                                        <ProductBulkEditPage
                                          bulkEditProductLoader={bulkEditProductLoader}
                                          setBulkEditProductLoader={setBulkEditProductLoader}
                                        />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/products/update-product/update-variant'
                                    element={planActive && <UpdateVariant />}
                                  />
                                  <Route
                                    path='/products/:slugname/update-discount'
                                    element={planActive && <UpdateProductDiscount />}
                                  />
                                  <Route
                                    path='/products/bulk-upload'
                                    element={
                                      planActive && (
                                        <ImportOwnProduct
                                          reGenerateProcess={reGenerateProcess}
                                          setReGenerateProcess={setReGenerateProcess}
                                          reGenerateProcessing={reGenerateProcessing}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                        />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/products/shopify-product-import'
                                    element={
                                      planActive && (
                                        <ShopifyImport
                                          reGenerateProcess={reGenerateProcess}
                                          setReGenerateProcess={setReGenerateProcess}
                                          reGenerateProcessing={reGenerateProcessing}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                        />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/products/wordpress-product-import'
                                    element={
                                      planActive && (
                                        <WordpressImport
                                          reGenerateProcess={reGenerateProcess}
                                          setReGenerateProcess={setReGenerateProcess}
                                          reGenerateProcessing={reGenerateProcessing}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                        />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/ViewProduct'
                                    element={planActive && <ViewProduct />}
                                  />
                                  <Route
                                    path='/module-setting/product-group'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetProductGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/product-group/:groupId'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditProductGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/product-group/add-product-group'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddProductGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/common-setting'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <CommonSetting />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/slider-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AllSliderGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/slider-group/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditSliderGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/slider-group/add-slider-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddSliderGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* layout section  */}
                                  {/* <Route path='/layout' element={planActive && !Permissions?.data?.permissions && spinner ? <div className='absolute-loader'><Loader /></div> : Permissions?.data?.permissions?.includes('module_setting') || Permissions?.data?.permissions === "all" ? <GetLayout /> : <></>} /> */}
                                  <Route
                                    path='/layout'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <ThemeCustomizeScreen />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/layout/:layoutName'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditorRouter
                                          themeSetting={themeSetting}
                                          handleTheameSetting={handleTheameSetting}
                                          page={page}
                                        />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  {/* <Route path='/layout/:layoutName' element={planActive && !Permissions?.data?.permissions  && spinner ? <div className='absolute-loader'><Loader /></div> : Permissions?.data?.permissions?.includes('module_setting') || Permissions?.data?.permissions === "all" ? <AddLayout /> : <></>} /> */}

                                  {/* setting section */}
                                  <Route
                                    path='/store-profile'
                                    element={
                                      planActive && (
                                        <Setting
                                          reGenerateProcessing={reGenerateProcessing}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                          reGenerateProcess={reGenerateProcess}
                                          setReGenerateProcess={setReGenerateProcess}
                                        />
                                      )
                                    }
                                  />
                                  <Route
                                    path='/store-setting'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('edit_Client') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <StoreSetting />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/store-profile/add-social-links'
                                    element={planActive && <Social />}
                                  />
                                  <Route
                                    path='/store-profile'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('edit_Client') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <BusinessProfile />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/store-profile/addcustomField'
                                    element={planActive && <AddCustomField />}
                                  />
                                  <Route
                                    path='/store-setting/editCustomField'
                                    element={planActive && <EditCustomField />}
                                  />
                                  <Route
                                    path='/store-setting/editCheckoutField'
                                    element={planActive && <EditCheckOutField />}
                                  />
                                  <Route
                                    path='/module-setting'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <ModuleSetting />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/store-profile/product-catalog'
                                    element={planActive && <ProductCatalog />}
                                  />
                                  <Route
                                    path='/commingsoon'
                                    element={planActive && <Commingsoon />}
                                  />
                                  <Route
                                    path='/delete-logs'
                                    element={planActive && <DeleteLogs />}
                                  />

                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Custom Field') && (
                                      <Route
                                        path='/custom-field'
                                        element={planActive && <ListMetaFieldGroup />}
                                      />
                                    )}

                                  {/* Reviews And GDPR */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Review') && (
                                      <>
                                        <Route
                                          path='/reviews'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_AllReviews',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AllReviews
                                                permissionCheck={
                                                  Permissions?.data?.permissions?.includes(
                                                    'add_Reviews',
                                                  ) || Permissions?.data?.permissions === 'all'
                                                }
                                              />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/reviews/add-review'
                                          element={planActive && <AddReview />}
                                        />
                                        <Route
                                          path='/reviews/:reviews_id'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_ProductReviews',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PerticularReviews />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                  <Route path='/add-gdpr' element={planActive && <AddGdpr />} />
                                  <Route
                                    path='/gdpr'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_gdpr') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <ViewGdpr />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/gdpr/update-gdpr'
                                    element={planActive && <UpdateGdpr />}
                                  />
                                  <Route
                                    path='/notifications'
                                    element={
                                      <AllClientNotification
                                        notificationCount={notificationCount}
                                        setNotificationCount={setNotificationCount}
                                      />
                                    }
                                  />
                                  {/* tab section */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Custom Tab') && (
                                      <>
                                        <Route
                                          path='/tabs'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'add_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <ViewTab />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/tabs/add-tab'
                                          element={planActive && <AddTab />}
                                        />
                                        <Route
                                          path='/tabs/:tabid'
                                          element={planActive && <UpdateTab />}
                                        />
                                      </>
                                    )}
                                  {/* Custome form */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Dynamic Forms') && (
                                      <>
                                        <Route
                                          path='/forms'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'module_setting',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <ViewDynamicForms />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/forms/add-form'
                                          element={planActive && <AddForms />}
                                        />
                                        <Route
                                          path='/forms/:formid'
                                          element={planActive && <UpdateForms />}
                                        />
                                      </>
                                    )}

                                  {/* category section */}
                                  <Route
                                    path='/categories/add-category'
                                    element={planActive && <Addcategory />}
                                  />
                                  <Route
                                    path='/categories'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_Category',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <CategoryTable />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/categories/:slugname'
                                    element={planActive && <UpdateCategory />}
                                  />
                                  <Route
                                    path='/module-setting/category-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetCategoryGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/category-group/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditCategoryGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/category-group/add-category-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddCategoryGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* grop varient section */}
                                  <Route
                                    path='/group-variants'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_Product_Variant_Group',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AllVariants />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/group-variants/add-group-variants'
                                    element={planActive && <AddVarient />}
                                  />
                                  <Route
                                    path='/group-variants/:variantsid'
                                    element={planActive && <UpdateVarient />}
                                  />

                                  {/* attributes section */}
                                  <Route
                                    path='/attributes'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions === 'all' ? (
                                        <AllAttributes />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/attributes/add-attributes'
                                    element={planActive && <AddAttributes />}
                                  />
                                  <Route
                                    path='/attributes/:attributesid'
                                    element={planActive && <UpdateAttributes />}
                                  />

                                  {/* Shipping Section */}
                                  <Route
                                    path='/shipping-methods'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_ShippingMethod',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddShipping />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* blog section */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Blog') && (
                                      <>
                                        <Route
                                          path='/blog-post'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Blog',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <MyBlogs />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/blog-post/add-blog-post'
                                          element={planActive && <AddBlog />}
                                        />
                                        <Route
                                          path='/blog-post/:blog_slug'
                                          element={planActive && <UpdateBlog />}
                                        />
                                        <Route
                                          path='/blog-categories'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Blog',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <BlogCategories />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/blog-categories/add-blog-category'
                                          element={planActive && <AddBlogCategory />}
                                        />
                                        <Route
                                          path='/blog-categories/:blogcatid'
                                          element={planActive && <UpdateBlogCategory />}
                                        />
                                        <Route
                                          path='/blog-comments'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Blog',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <BlogComments />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/blog-comments/add-blog-comments'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Blog',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AddBlogComments />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/blog-comments/:commentId'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Blog',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PerticularBlogComment />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/module-setting/blog-group'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'module_setting',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <GetBlogGroup />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/module-setting/blog-group/:groupId'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'module_setting',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <EditBlogGroup />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/module-setting/blog-group/add-blog-group'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'module_setting',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AddBlogGroup />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                  {/* Portfolio Section */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Portfolio') && (
                                      <>
                                        <Route
                                          path='/portfolio-post'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Portfolio',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <MyPortfolios />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/portfolio-post/add-portfolio-post'
                                          element={planActive && <AddPortfolio />}
                                        />
                                        <Route
                                          path='/portfolio-post/:portfolio_slug'
                                          element={planActive && <UpdatePortfolio />}
                                        />
                                        <Route
                                          path='/portfolio-categories'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Portfolio',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PortfolioCategory />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/portfolio-categories/add-portfolio-category'
                                          element={planActive && <AddPCategory />}
                                        />
                                        <Route
                                          path='/portfolio-categories/:portfoliocatid'
                                          element={planActive && <UpdatePortfolioCategory />}
                                        />
                                        <Route
                                          path='/portfolio-comments'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Portfolio',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PortfolioComments />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/portfolio-comments/:commentId'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Portfolio',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PerticularPortfolioComment />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/portfolio-comments/add-portfolio-comments'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Portfolio',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AddPortfolioComments />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                  <Route
                                    path='/module-setting/banner-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AllBannerGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/banner-group/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditBannerGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/banner-group/add-banner-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddBannerGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* footer group */}
                                  <Route
                                    path='/module-setting/footer'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetFooterGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/footer/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditFooterGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/footer/add-footer-section'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddFooterGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/footer/edit-footer-copyright'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <FooterCopyright />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* Payment Section */}
                                  <Route
                                    path='/payment-methods'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Payment') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <AddPaymentOption />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/payouts'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Payment') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <Payouts />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/payouts/payout-history'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Payment') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <PayoutHistory />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/payouts/payoutdetails'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Payment') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <PayoutDetails />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* staff &customers Section */}
                                  <Route
                                    path='/team/add-team'
                                    element={planActive && <AddStaff />}
                                  />
                                  <Route
                                    path='/team'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Staff') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <ViewStaff />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/team/:staff_id'
                                    element={planActive && <UpdateStaff />}
                                  />
                                  <Route
                                    path='/customers'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_customers',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetCustomers />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/customers/:cust_id'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_customers',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <CustomerProfile />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/customers/UpdateCustomer'
                                    element={planActive && <UpdateCustomer />}
                                  />

                                  {/* Roles */}
                                  <Route
                                    path='/roles'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Role') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <GetRole />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/roles/add-role'
                                    element={planActive && <AddRole />}
                                  />
                                  <Route
                                    path='/roles/:role_id'
                                    element={planActive && <EditRole />}
                                  />

                                  {/* manufacturer components */}
                                  <Route
                                    path='/manufacturers/add-manufacturer'
                                    element={planActive && <AddManufacturer />}
                                  />

                                  <Route
                                    path='/manufacturers'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_Manufacturer',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AllManufacturer />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/manufacturers/:slugname'
                                    element={planActive && <EditManufacturer />}
                                  />

                                  <Route
                                    path='/module-setting/manufacture-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetManufactureGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/manufacture-group/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditManufactureGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />
                                  <Route
                                    path='/module-setting/manufacture-group/add-manufacture-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddManufactureGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* orders */}
                                  <Route
                                    path='/orders'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Order') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <ViewOrders
                                          permissionCheck={
                                            Permissions?.data?.permissions?.includes('add_Order') ||
                                            Permissions?.data?.permissions === 'all'
                                          }
                                        />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/order/create-order'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Order') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <CreateOrder />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/order/:order_id'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Order') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <OrderDetail />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/return-orders'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Order') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <ReturnOrders />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/return-order/:order_id'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Order') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <UpdateReturnOrders />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/abandone-cart'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'abandoned_cart',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AbandoneCart />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* discounts  */}
                                  <Route
                                    path='/discounts'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'get_Discount',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <ViewDiscount />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/discounts/add-discount'
                                    element={planActive && <AddDiscount />}
                                  />

                                  <Route
                                    path='/discounts/:discount_id'
                                    element={planActive && <EditDiscount />}
                                  />
                                  {/* Content */}
                                  <Route
                                    path='/files'
                                    element={
                                      planActive &&
                                      !Permissions?.data?.permissions &&
                                      spinner &&
                                      spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Product') ||
                                        Permissions?.data?.permissions === 'all' ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <Files />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* Faqs */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('FAQs') && (
                                      <>
                                        <Route
                                          path='/faqs'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_FAQ',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <Faqs />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/faqs/add-faq'
                                          element={planActive && <AddFaq />}
                                        />
                                        <Route
                                          path='/faqs/update-faq/:faq_id/:fromProduct/:product'
                                          element={planActive && <UpdateFaq />}
                                        />
                                        <Route
                                          path='/faqs/show-faq'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_FAQ',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <ShowFaq />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                  {/* Chat */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes(`Customer's support`) && (
                                      <>
                                        <Route
                                          path='/customer-support'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Chat',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <Chat />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  {/* <Route path='/help-support' element={planActive && !Permissions?.data?.permissions  && spinner ? <div className='absolute-loader'><Loader /></div> : Permissions?.data?.permissions?.includes('get_Chat') || Permissions?.data?.permissions === "all" ? <HelpSupport /> : <></>} /> */}

                                  {/* Newsletter */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes('Newsletter') && (
                                      <>
                                        <Route
                                          path='/newsletter'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner &&
                                            !installedExtensions?.some(
                                              (ext) => ext.extension_name === 'News Letter',
                                            ) ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions === 'all' ? (
                                              <Newsletter />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/newsletter-subscribers'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions === 'all' ? (
                                              <GetMails />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                  {/* reassurance */}
                                  <Route
                                    path='/module-setting/reassurance-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetReassuranceGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/reassurance-group/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditReassuranceGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/reassurance-group/add-reassurance-group'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddReassuranceGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/header-menu'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <GetHeaderGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/header-menu/:groupId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditHeaderGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/header-menu/add-header-section'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddHeaderGroup />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* cms offer */}
                                  <Route
                                    path='/module-setting/cms-offer'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AllCmsOffer />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/cms-offer/:cmsOfferId'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <EditCmsOffer />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/module-setting/cms-offer/add-cms-offer'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes(
                                          'module_setting',
                                        ) || Permissions?.data?.permissions === 'all' ? (
                                        <AddCmsOffer />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* Announcement */}
                                  <Route
                                    path='/announcement'
                                    element={planActive && <AllAnnounement />}
                                  />

                                  <Route
                                    path='/announcement/viewannouncement'
                                    element={planActive && <ViewAnnouncement />}
                                  />

                                  {/* Subscription */}
                                  <Route path='/subscription' element={<Subscriptions />} />

                                  {/* Product Subscription */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes(`Product Subscription`) && (
                                      <>
                                        <Route
                                          path='/product-subscription'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <ProductSubscription />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/product-subscription/add-subscription-plan'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AddSubscriptionPlan />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/product-subscription/:subscription_plan'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <UpdateSubscriptionPlan />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/contracts'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <Contract />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/contract/:view_contract'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <ViewContract />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  {/* POS */}
                                  {installExtentionName?.length > 0 &&
                                    installExtentionName?.includes(`POS`) && (
                                      <>
                                        <Route
                                          path='/pos-staff'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PosStaff />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/pos-staff/add-staff'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AddPosStaff />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/pos-staff/:staff'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <UpdatePosStaff />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/pos-location'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <PosLocations />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/pos-location/add-location'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <AddPosLocation />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                        <Route
                                          path='/pos-location/:location'
                                          element={
                                            planActive &&
                                            !Permissions?.data?.permissions &&
                                            spinner ? (
                                              <div className='absolute-loader'>
                                                <Loader />
                                              </div>
                                            ) : Permissions?.data?.permissions?.includes(
                                                'get_Product',
                                              ) || Permissions?.data?.permissions === 'all' ? (
                                              <UpdatePosLocation />
                                            ) : (
                                              <></>
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                  {/* Enquiry */}
                                  <Route
                                    path='/enquiry'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_enquiry') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <AllEnquirys />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/enquiry/show-enquiry/:enquiryid'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_enquiry') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <ShowEnquiry />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  {/* Pages Path */}
                                  <Route path='/pages/add-page' element={planActive && <Pages />} />
                                  <Route
                                    path='/pages'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('get_Page') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <MyPages />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/pages/:page_id'
                                    element={planActive && <UpdatePage />}
                                  />

                                  {/* filter path */}
                                  <Route
                                    path='/filter'
                                    element={
                                      planActive && !Permissions?.data?.permissions && spinner ? (
                                        <div className='absolute-loader'>
                                          <Loader />
                                        </div>
                                      ) : Permissions?.data?.permissions?.includes('view_filter') ||
                                        Permissions?.data?.permissions === 'all' ? (
                                        <GetFilter />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  />

                                  <Route
                                    path='/apps'
                                    element={
                                      planActive && (
                                        <Mymodules
                                          callExtention={callExtention}
                                          setReGenerateProcessing={setReGenerateProcessing}
                                          setReGenerateProcess={setReGenerateProcess}
                                        />
                                      )
                                    }
                                  />

                                  <Route
                                    path='/commingSoon'
                                    element={planActive && <CommingSoon />}
                                  />

                                  <Route path='/*' element={planActive && <ErrorPage />} />
                                </Routes>
                              ) : (
                                <PlanUpgrade
                                  permission={true}
                                  isAdminPlanOpen={true}
                                  closeAdminPlanPopup={closeAdminPlanPopup}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Extention.Provider>
                    </DataLoad.Provider>
                  </PlanPermission.Provider>
                </ChangePathContext.Provider>
              </ParallaxProvider>
            </div>
          </div>
        )}
        {storeDeleteRequestPending && window.location.href.split('/').pop() !== '/stores' && (
          <DeleteRequestPending />
        )}
        {isAdminPlanOpen && clientInfo?.billing_status ? (
          <PurchaseAdminPlan
            isAdminPlanOpen={isAdminPlanOpen}
            closeAdminPlanPopup={closeAdminPlanPopup}
          />
        ) : (
          <></>
        )}
        {
          // announcement && AnnData?.data?.records?.length > 0 && (
          //   <Modal
          //     aria-labelledby='transition-modal-title'
          //     aria-describedby='transition-modal-description'
          //     open={() => setAnnouncement(true)}
          //     onClose={() => { setAnnouncement(false); CloseAnnouncement() }}
          //     closeAfterTransition
          //     slots={{ backdrop: Backdrop }}
          //     slotProps={{
          //       backdrop: {
          //         timeout: 500,
          //       },
          //     }}
          //   >
          //     <Fade in={open}>
          //       <div className='outline-none absolute max-h-[600px] overflow-y-auto h-fit lg:h-fit lg:overflow-auto top-32 left-32 right-10 bottom-10 lg:top-[50%] z-10 lg:left-[50%] border-none focus:border-none border-white lg:-translate-x-[50%] lg:-translate-y-[50%] max-w-full lg:w-[1000px] mx-auto p-5 secondary-bg-color rounded-[6px]'>
          //         <div>
          //           <div className='flex items-center justify-between pt-2 rounded-t dark:main-border-color mb-4 '>
          //             <h5 className='text-xl font-semibold text-gray-900 dark:text-white capitalize'>
          //               📢 Announcement
          //             </h5>
          //             <div className='flex items-center space-x-4'>
          //               <button onClick={() => { navigate("/announcement"); setAnnouncement(false); CloseAnnouncement() }} className=' text-primary hover:underline text-sm font-medium'>View all</button>
          //               <button
          //                 type='button'
          //                 className='hover:bg-[#F0F0F0] rounded-[6px] w-[22px] h-[22px] text-sm inline-flex items-center justify-center'
          //                 data-modal-hide='staticModal'
          //                 onClick={() => { setAnnouncement(false); CloseAnnouncement() }}
          //               >
          //                 <X className="w-4 h-4" />
          //               </button>
          //             </div>
          //           </div>
          //           <hr />
          //           <div className='divide-y border-gray-200'>
          //             {
          //               AnnData?.data?.records?.map((elem, index) => {
          //                 let timeValue = timeAgo(elem.createdAt)
          //                 return (
          //                   <div className='py-2' >
          //                     <div className='flex items-start justify-between'>
          //                       <div className='flex items-start space-x-3 mt-2 mb-1'>
          //                         {
          //                           elem.tag === "Warning" ?
          //                             <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-yellow-100'>  <ExclamationTriangleFill className='w-7 h-7 text-yellow-500 ' /></div>
          //                             :
          //                             elem.tag === 'Tips' ?
          //                               <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-yellow-100'>  <LightbulbFill className='w-5 h-5 text-yellow-500 ' /></div>
          //                               :
          //                               elem.tag === 'New Version' ?
          //                                 <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-green-100'>  <ArrowUp className='w-6 h-6 text-green-500 ' /></div>
          //                                 :
          //                                 elem.tag === 'New Feature' ?
          //                                   <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-green-100'>  <ClipboardPulse className='w-6 h-6 text-green-500 ' /></div>
          //                                   :
          //                                   elem.tag === 'Success' ?
          //                                     <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-green-100'>  <Check2 className='w-6 h-6 text-green-500 ' /></div>
          //                                     :
          //                                     elem.tag === 'Improvement' ?
          //                                       <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-green-100'>  <Hammer className='w-6 h-6 text-green-500 ' /></div>
          //                                       :
          //                                       <div className='flex items-center justify-center min-w-[50px] max-w-[50px] h-[50px] rounded-full bg-green-100'>  <LightbulbFill className='w-6 h-6 text-green-500 ' /></div>
          //                         }
          //                         <div>
          //                           <div className='flex items-center space-x-3'>
          //                             <span className='text-xl inline-block m-0 font-medium table-text'>{elem.announcement_title}</span>
          //                             <span className='text-sm inline-block table-text'>{timeValue}</span>
          //                           </div>
          //                           <p className='text-[15px] text-sm table-text mb-1'>{elem.short_description}</p>
          //                           <button onClick={() => { navigate("/announcement/viewannouncement", { state: elem?._id }); setAnnouncement(false); CloseAnnouncement() }} className=' text-primary hover:underline text-sm font-medium'>
          //                             Read more
          //                           </button>
          //                         </div>
          //                       </div>
          //                     </div>
          //                   </div>
          //                 )
          //               })
          //             }
          //           </div>
          //         </div>
          //       </div>
          //     </Fade>
          //   </Modal>
          // )
        }
      </StoreDeletePending.Provider>
    </>
  );
}

export default App;
