import { Dialog, FormControlLabel, Popover } from '@mui/material';
import { Edit, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../../API';
import { IconTab1, IconTab2, IconTab3 } from '../../../Assets/StaticData/ReassuranceIcon';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import ImageUploader from '../../../controllers/ImageUploader';
import MuiSwitch from '../../../controllers/MuiSwitch';
import { Transition } from '../../../controllers/Transition';
import NoRecords from '../../Main/NoRecords';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';
import ReactDragListView from 'react-drag-listview';

const EditReassuranceSection = ({ section_id, getSectionData, section_type }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [dataErr, setDataErr] = useState(false);
  const [groupTitle, setGroupTitle] = useState('');
  const [storeReassurance, setStoreReassurance] = useState([]);
  const [addReassurance, setAddReassurance] = useState(false);
  const [image, setimage] = useState();
  const [groupStatus, setGroupStatus] = useState(true);
  const [reassuranceStatus, setReassuranceStatus] = useState(false);
  const [reassuranceData, setReassuranceData] = useState({
    reassurance_title: '',
    reassurance_description: '',
    reassurance_redirect_link: '',
  });
  const [iconDD, setIconDD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [EditId, setEditId] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [filterId, setFilterId] = useState(null);
  const [hoverId, setHoverId] = useState(null);

  let enabledReassurances =
    storeReassurance?.length &&
    storeReassurance?.filter((reassurance) => reassurance?.reassurance_status);
  const getPerticulerGroupData = async () => {
    try {
      const apicall = await API.get(`/admin/reassurance/getReassuranceGroup/${section_id}`);
      const result = decryptData(apicall?.data);
      setGroupTitle(result?.data?.reassurance_title);
      setGroupStatus(result?.data?.status);
      setStoreReassurance(result?.data?.reassurances);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (section_id) {
      getPerticulerGroupData();
    }
  }, [section_id]);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const handleAddReassurance = async (e) => {
    e.preventDefault();

    if (isEdit) {
      try {
        const payload = encryptData({
          ...reassuranceData,
          reassurance_img: image,
          reassurance_status: reassuranceStatus,
        });
        await API.put(`/admin/reassurance/updateReassurance/${EditId}`, payload);
        setIsEdit(false);
        getPerticulerGroupData();
        handleChange();
        setAddReassurance(false);
        setReassuranceData({
          reassurance_title: '',
          reassurance_description: '',
          reassurance_redirect_link: '',
        });
        setimage();
        setReassuranceStatus(false);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const payload = encryptData({
          reassurance_group_id: section_id,
          reassurance: { ...reassuranceData, reassurance_img: image },
        });
        await API.post(`/admin/reassurance/addReassurance`, payload);
        getPerticulerGroupData();
        setAddReassurance(false);
        handleChange();
        setReassuranceData({
          reassurance_title: '',
          reassurance_description: '',
          reassurance_redirect_link: '',
        });
        setimage();
        setReassuranceStatus(false);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleChange = () => {
    getSectionData({
      section: {
        reassurances: storeReassurance,
        status: groupStatus,
      },
      status: groupStatus,
    });
  };
  useEffect(() => {
    handleChange();
  }, [groupStatus, storeReassurance]);
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditReassuranceGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        reassurance_title: groupTitle,
        status: groupStatus,
      });
      await API.put(`/admin/reassurance/updateReassuranceGroup/${section_id}`, payload);
      getSectionData('call layout');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleEditReassurance = (e, elem) => {
    e.preventDefault();
    setAddReassurance(true);
    setIsEdit(true);
    setEditId(elem?._id);
    setReassuranceData({
      reassurance_title: elem?.reassurance_title,
      reassurance_description: elem?.reassurance_description,
      reassurance_redirect_link: elem?.reassurance_redirect_link,
    });
    setimage(elem?.reassurance_img);
    setReassuranceStatus(elem?.reassurance_status);
  };
  const UpdateReassuranceStatus = async (id, value) => {
    try {
      const payload = encryptData({
        reassurance_status: value,
      });
      const data = await API.put(`/admin/reassurance/updateReassurance/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getPerticulerGroupData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortReassurance = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/reassurance/updateReassurance/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(storeReassurance);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setStoreReassurance(items);
  //   sortReassurance(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...storeReassurance];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setStoreReassurance(newItems);
      sortReassurance(toIndex, filterId);
      setFilterId(null);
      setHoverId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/reassurance/deleteReassurance`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Reassurance Removed SuccesFully!');
        setSeverity('success');
        getPerticulerGroupData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Reassurance'
        subheading='Do you really want to delete this reassurance from this group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4'
        >
          <div className='secondary-bg-color w-full space-y-4'>
            <div className='mb-4'>
              <label htmlFor='group title' className='flex justify-between'>
                <label htmlFor='image'>{t('lang_Reassurance_Group_Title')}</label>
                <GenerateLiveEditiorTitle
                  getAiGeneratedTitle={getAiGeneratedTitle}
                  section_type={section_type}
                />
              </label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm ${
                  err ? 'border-red-500' : ''
                }`}
                placeholder='Reassurance group title'
                value={groupTitle}
                onChange={(e) => {
                  setGroupTitle(e.target.value);
                  setErr(false);
                  // handleChange();
                }}
              />
              {err && groupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
              )}
            </div>
            <div className='border w-full main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-2 text-sm justify-between gap-1'>
              <p className='text-[13px] font-[500] table-text capitalize'>
                {t('lang_Reassurance_Group_Status')} -
              </p>
              <label className='relative flex ms-2 items-center cursor-pointer'>
                <FormControlLabel
                  checked={groupStatus}
                  control={<MuiSwitch />}
                  onChange={() => {
                    setGroupStatus(!groupStatus);
                    // handleChange();
                  }}
                />
              </label>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-end justify-between'>
              <button
                className='text-link inline-flex items-center  mb-0 underline text-right font-medium text-sm text-white'
                onClick={(e) => {
                  e.preventDefault();
                  setAddReassurance(true);
                }}
              >
                <PlusCircle className='w-4 me-2 h-4' />
                {t('lang_Add_Reassurance')}
              </button>
            </div>
            {storeReassurance?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_title')}</td>
                      <td className='min-w-[80px] max-w-[80px] px-3 text-right'>
                        {t('lang_status')}
                      </td>
                      <td className='min-w-[100px] max-w-[100px] px-3 text-right capitalize'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <ReactDragListView {...dragProps}>
                      {storeReassurance?.map((row, index) => (
                        <tr
                          style={{
                            boxShadow:
                              hoverId === index ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                            background: 'white',
                          }}
                          className={`${
                            selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                          } select-none flex w-full`}
                          onDragStart={() => setFilterId(row?._id)}
                          onDragOver={() => setHoverId(index)}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setHoverId(null);
                          }}
                        >
                          <td className='table-image px-3 min-w-[80px]'>
                            <img
                              width={40}
                              height={40}
                              src={row.reassurance_img}
                              className='p-1 max-w-full object-contain max-h-full'
                              alt='reassurance image'
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{
                                __html: row.reassurance_title || '-',
                              }}
                            ></span>
                          </td>
                          <td className='min-w-[80px] max-w-[80px] px-3 text-right'>
                            <label className='relative justify-end flex ms-2 items-center cursor-pointer'>
                              <FormControlLabel
                                checked={row?.reassurance_status}
                                control={<MuiSwitch />}
                                onChange={() => {
                                  UpdateReassuranceStatus(row?._id, !row.reassurance_status);
                                }}
                              />
                            </label>
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                            <div className='space-x-2 w-full text-right'>
                              <button onClick={(e) => handleEditReassurance(e, row)}>
                                <Edit className='w-[17px] h-[17px] text-black-500' />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConfirmationPopup(true);
                                  setSelectedRowsId([row?._id]);
                                }}
                              >
                                <Trash className='w-[17px] h-[17px] text-red-500' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </ReactDragListView>
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
            <hr className='border-t main-border-color my-4'></hr>
            <div>
              {groupTitle?.length === 0 || !groupTitle ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleEditReassuranceGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
        <Dialog
          open={addReassurance}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setAddReassurance(false);
            setReassuranceData({
              reassurance_title: '',
              reassurance_description: '',
              reassurance_redirect_link: '',
            });
            setimage();
            setReassuranceStatus(false);
          }}
          aria-describedby='alert-dialog-slide-description'
        >
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='secondary-bg-color w-[700px] max-w-[600px] lg:max-w-full border main-border-color  space-y-4 rounded-[6px] p-5'
          >
            <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl'>
                {isEdit ? 'Edit Reassurance' : 'Add Reassurance'}
              </h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddReassurance(false);
                  setReassuranceData({
                    reassurance_title: '',
                    reassurance_description: '',
                    reassurance_redirect_link: '',
                  });
                  setimage();
                  setReassuranceStatus(false);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor='image'>{t('lang_Reassurance_Image')}</label>
                <button
                  aria-describedby='iconDD'
                  variant='contained'
                  className='text-link text-sm hover:underline'
                  onClick={(e) => {
                    {
                      e.preventDefault();
                      setAnchorEl(e.currentTarget);
                      setIconDD(true);
                    }
                  }}
                >
                  {t('lang_Select_Icon')}
                </button>
              </div>
              <ImageUploader
                alreadyImage={image}
                handleSetImage={handleSetImage}
                folder='reassurance'
                format='image'
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Title')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_title}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                placeholder='e.g. Free Shipping World Wide'
                onChange={(e) =>
                  setReassuranceData({ ...reassuranceData, reassurance_title: e.target.value })
                }
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Description')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_description}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                placeholder='e.g. Free Shipping World Wide'
                onChange={(e) =>
                  setReassuranceData({
                    ...reassuranceData,
                    reassurance_description: e.target.value,
                  })
                }
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Redirect_Link')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_redirect_link}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                placeholder='Add link'
                onChange={(e) =>
                  setReassuranceData({
                    ...reassuranceData,
                    reassurance_redirect_link: e.target.value,
                  })
                }
              />
            </div>
            {/* <div className='border w-full main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-2 text-sm'>
                <p className='text-[13px] font-[500] table-text capitalize'>
                  Reassurance status -
                </p>
                <input
                  className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault'
                  checked={reassuranceStatus}
                  name='sliderbutton'
                  onChange={() => setReassuranceStatus(!reassuranceStatus)}
                />
              </div> */}
            <button
              className='btn inline-block text-sm text-white'
              onClick={(e) => handleAddReassurance(e)}
            >
              {isEdit ? 'Edit Reassurance' : 'Add Reassurance'}
            </button>
          </form>
          {iconDD && (
            <Popover
              id={'iconDD'}
              open={iconDD}
              anchorEl={anchorEl}
              onClose={() => setIconDD(!iconDD)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className='h-auto w-96 max-w-screen secondary-bg-color  border-gray-600  z-50 rounded-[4px]'>
                <ul className='flex items-center mb-0 list-none overflow-x-auto flex-row'>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 1
                          ? ' border-b-2 border-b-[#28282B]'
                          : 'text-gray-600 bg-transparent border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle='tab'
                      href='#link1'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab1?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 2
                          ? ' border-b-2 border-b-[#28282B]'
                          : 'text-gray-600 bg-transparent border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle='tab'
                      href='#link2'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab2?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 3
                          ? ' border-b-2 border-b-[#28282B]'
                          : 'text-gray-600 bg-transparent border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle='tab'
                      href='#link3'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab3?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                </ul>
                {openTab === 1 && (
                  <div className='grid grid-cols-3 mt-4 gap-5'>
                    {IconTab1?.icons?.map((elem) => {
                      return (
                        <div
                          className={`text-center cursor-pointer ${
                            elem === image ? 'bg-gray-100 rounded-[6px]' : ''
                          }`}
                        >
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {openTab === 2 && (
                  <div className='grid grid-cols-3 mt-4 gap-5'>
                    {IconTab2?.icons?.map((elem) => {
                      return (
                        <div className='text-center cursor-pointer'>
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {openTab === 3 && (
                  <div className='grid grid-cols-3 mt-4 gap-5'>
                    {IconTab3?.icons?.map((elem) => {
                      return (
                        <div className='text-center cursor-pointer'>
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className='border-t p-5 text-center'>
                  <button
                    className='text-link'
                    onClick={(e) => {
                      setimage('');
                      setDataErr(false);
                      setIconDD(false);
                    }}
                  >
                    {t('lang_Select_None')}
                  </button>
                </div>
              </div>
            </Popover>
          )}
        </Dialog>
      </div>
    </>
  );
};

export default EditReassuranceSection;
