import React from 'react';

const Reassurance = ({ colors, reassuranceData, reassuranceDesign }) => {
  const enableServices = reassuranceData?.section?.reassurances?.filter(
    (elem) => elem?.reassurance_status,
  );
  const limitPerView = 4 <= enableServices?.length ? 4 : enableServices?.length;

  return (
    reassuranceData?.status === true && (
      <div style={{ display: 'block', margin: '0 auto' }}>
        <div
          className={`grid gap-1 grid-cols-1 sm:grid-cols-2 lg:grid-cols-${limitPerView}`}
          style={{
            display: reassuranceDesign?.display,
            flexDirection: reassuranceDesign?.direction,
            alignItems: reassuranceDesign?.align_item,
            gap: `${reassuranceDesign?.gap}px`,
            marginTop: `${reassuranceDesign?.margin_top}px`,
            marginBottom: `${reassuranceDesign?.margin_bottom}px`,
            marginLeft: `${reassuranceDesign?.margin_left}px`,
            marginRight: `${reassuranceDesign?.margin_right}px`,
            paddingTop: `${reassuranceDesign?.padding_top}px`,
            paddingBottom: `${reassuranceDesign?.padding_bottom}px`,
            paddingLeft: `${reassuranceDesign?.padding_left}px`,
            paddingRight: `${reassuranceDesign?.padding_right}px`,
            height: `${reassuranceDesign?.height}${reassuranceDesign?.height_unit}`,
            width: `${reassuranceDesign?.width}${reassuranceDesign?.width_unit}`,
            borderStyle: reassuranceDesign?.border_style,
            borderWidth: `${reassuranceDesign?.border_width}px`,
            borderColor: reassuranceDesign?.border_color,
            float: reassuranceDesign?.float,
            maxHeight: `${reassuranceDesign?.max_height}${reassuranceDesign?.max_height_unit}`,
            maxWidth: `${reassuranceDesign?.max_width}${reassuranceDesign?.max_width_unit}`,
            minHeight: `${reassuranceDesign?.min_height}${reassuranceDesign?.min_height_unit}`,
            minWidth: `${reassuranceDesign?.min_width}${reassuranceDesign?.min_width_unit}`,
          }}
        >
          {enableServices?.map((elem, index) => {
            return (
              <a
                style={{ color: colors?.primary_body_text_color }}
                key={index}
                href={
                  elem?.reassurance_redirect_link?.length > 0 && elem?.reassurance_redirect_link
                }
                target='blank'
                className='w-full block p-5 text-center'
              >
                <div className='h-[84px] flex items-center justify-center'>
                  {elem?.reassurance_img?.includes('<svg') ? (
                    <div dangerouslySetInnerHTML={{ __html: elem?.reassurance_img }}></div>
                  ) : (
                    <img
                      onError={(e) => {
                        e.target.src = noPhoto;
                      }}
                      src={elem?.reassurance_img || noPhoto}
                      className='max-w-[80px] max-h-[80px] mx-auto inline-block'
                      alt={elem?.reassurance_title}
                      style={{ objectFit: reassuranceDesign?.fit }}
                    />
                  )}
                </div>
                <p
                  // style={{ color: colors?.primary_body_text_color }}
                  className='text-base capitalize font-medium'
                  style={{
                    color: colors?.main_title_color,
                    fontSize: `${reassuranceDesign?.font_size}px`,
                    lineHeight: `${reassuranceDesign?.font_height}px`,
                    fontWeight: reassuranceDesign?.font_weight,
                    fontStyle: reassuranceDesign?.font_style,
                    color: reassuranceDesign?.font_color,
                    textAlign: reassuranceDesign?.font_align,
                    textDecoration: reassuranceDesign?.font_decoration,
                    textTransform: reassuranceDesign?.font_capitalize,
                    wordBreak: reassuranceDesign?.font_break,
                    textWrap: reassuranceDesign?.font_wrap,
                  }}
                >
                  {elem?.reassurance_title}
                </p>
                <p
                  // style={{ color: colors?.primary_body_text_color }}
                  className='text-[13px] line-clamp-2 whitespace-pre-wrap font-normal'
                  style={{
                    fontSize: `${reassuranceDesign?.font_size_description}px`,
                    lineHeight: `${reassuranceDesign?.font_height_description}px`,
                    fontWeight: reassuranceDesign?.font_weight_description,
                    fontStyle: reassuranceDesign?.font_style_description,
                    color: reassuranceDesign?.font_color_description,
                    textAlign: reassuranceDesign?.font_align_description,
                    textDecoration: reassuranceDesign?.font_decoration_description,
                    textTransform: reassuranceDesign?.font_capitalize_description,
                    wordBreak: reassuranceDesign?.font_break_description,
                    textWrap: reassuranceDesign?.font_wrap_description,
                  }}
                >
                  {elem?.reassurance_description}
                </p>
              </a>
            );
          })}
        </div>
      </div>
    )
  );
};

export default Reassurance;
