import { ChevronDown, Eye, EyeOff, Trash2 } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../../API';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import { clientData } from '../../../controllers/GetClient';
import Notification from '../../Notification';
import ReactDragListView from 'react-drag-listview';
import DivideCircle from 'feather-icons-react/build/IconComponents/DivideCircle';
import { getIconByBlockName } from '../../../Assets/StaticData/SubItemIcon';

const ProductPageSectionList = ({
  getLayout,
  selectProductSection,
  editProductSection,
  changeProductPageSectionSortOrder,
  sectionArray,
  setSectionArray,
  otherProductSection,
  setOtherProductSection,
  changeProductPageOtherSectionSortOrder,
  selectProductOtherSection,
  editProductOtherSection,
  UpdateSectionStatus,
  handleRatingClick,
  sectionValue,
  handleGetLayout,
  handleSortOtherSection,
  handleSortSection,
  handleSortProduct,
  // handleSort,
  hoverId,
  setHoverId,
  setFilterId,
  sectionAdd,
  editSection,
  loadingSections,
  handleSubItemSort,
  selectSection,
  setSubSecId,
  openAddSection,
  handleSectionClick,
  setElementorId,
  getPrevSecId,
}) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [sectionDeleteId, setSectionDeleteId] = useState();
  const [openedSections, setOpenedSections] = useState(new Set());
  const [hoverSubId, setHoverSubId] = useState(null);

  const handleDeleteSection = async () => {
    try {
      const data = await API.post(`/admin/layout/deleteSection`, {
        id: [sectionDeleteId],
      });
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Section Removed SuccesFully!');
        setSeverity('success');
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };

  useEffect(() => {
    try {
      if (clientData?.product_section_information) {
        const data = JSON.parse(clientData.product_section_information);
        if (data.sectionArray?.length > 0) {
          const sortedArray = data.sectionArray.sort((a, b) => a.sort_order - b.sort_order);
          setSectionArray(sortedArray);
        }

        if (data.otherProductSection) {
          const sortedOtherSectionArray = data.otherProductSection.sort(
            (a, b) => a.sort_order - b.sort_order,
          );
          setOtherProductSection(sortedOtherSectionArray);
        }
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }, [clientData]);

  const handleToggleSection = (e, key) => {
    e.stopPropagation();
    setOpenedSections((prevOpenedSections) => {
      const newOpenedSections = new Set(prevOpenedSections);
      if (newOpenedSections?.has(key)) {
        newOpenedSections?.delete(key);
      } else {
        newOpenedSections?.add(key);
      }
      return newOpenedSections;
    });
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete Section'
        subheading='Do you really want to delete this section from home layout?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDeleteSection}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <details open className='cursor-pointer group main-border-color'>
        <summary
          htmlFor='Meta Tag Title'
          className='text-base w-full table-text flex flex-col items-center'
        >
          <div className='flex w-full items-center justify-between'>
            <div>
              <span className='text-sm'>{t('lang_Product_Information')}</span>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </div>
        </summary>

        <div className='table-text space-y-0.5 my-2'>
          <ReactDragListView {...handleSortSection}>
            {sectionArray?.map((elem, index) => (
              <div
                style={{
                  // boxShadow: snapshot.isDragging ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                  display:
                    elem?.section_name === 'Description Tab' ||
                    elem?.section_name === 'Review Tab' ||
                    elem?.section_name === 'Custom Tab'
                      ? 'none'
                      : '',
                }}
                key={index}
                // onClick={() => {
                //   handleRatingClick(elem?.section_name);
                // }}
                onClick={(e) => selectProductSection(e, elem?.section_name, elem?.section_name)}
                className={`rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
                  editProductSection?.section_id === elem?.section_name
                    ? 'primary-bg-color text-primary'
                    : 'secondary-bg-color'
                }`}
              >
                <img src={elem?.icon} className='me-1.5' />
                <span>{elem?.section_name}</span>
              </div>
            ))}
          </ReactDragListView>
        </div>
      </details>
      <hr className='mb-3 border-t main-border-color'></hr>
      <details className='cursor-pointer group main-border-color' open>
        <summary
          htmlFor='Meta Tag Title'
          className='text-base w-full table-text flex flex-col items-center'
        >
          <div className='flex w-full items-center justify-between'>
            <div>
              <span className='text-sm'>{t('lang_Product_Tab')}</span>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </div>
        </summary>
        <div className='table-text space-y-0.5 my-2'>
          <div
            className={`rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
              editProductSection?.section_id === 'Description Tab'
                ? 'primary-bg-color text-primary'
                : 'secondary-bg-color'
            }`}
            onClick={(e) => selectProductSection(e, 'Description Tab', 'Description Tab')}
            // onClick={() => {
            //   handleRatingClick('Description Tab');
            // }}
          >
            <p>{t('lang_Description_Tab')}</p>
          </div>
          <div
            className={`rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
              editProductSection?.section_id === 'Review Tab'
                ? 'primary-bg-color text-primary'
                : 'secondary-bg-color'
            }`}
            onClick={(e) => selectProductSection(e, 'Review Tab', 'Review Tab')}
            // onClick={() => {
            //   handleRatingClick('Review Tab');
            // }}
          >
            <p>{t('lang_Review_Tab')}</p>
          </div>
          <div
            className={`rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
              editProductSection?.section_id === 'Custom Tab'
                ? 'primary-bg-color text-primary'
                : 'secondary-bg-color'
            }`}
            onClick={(e) => selectProductSection(e, 'Custom Tab', 'Custom Tab')}
            // onClick={() => {
            //   handleRatingClick('Custom Tab');
            // }}
          >
            <p>{t('lang_Custom_Tab')}</p>
          </div>
        </div>
      </details>
      <hr className='mb-3 border-t main-border-color'></hr>
      <details className='cursor-pointer group main-border-color' open>
        <summary
          htmlFor='Meta Tag Title'
          className='text-base w-full table-text flex flex-col items-center'
        >
          <div className='flex w-full items-center justify-between'>
            <div>
              <span className='text-sm'>{t('lang_Product_Other_Section')}</span>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </div>
        </summary>

        <div className='table-text space-y-0.5  my-2'>
          <ReactDragListView {...handleSortOtherSection}>
            {otherProductSection?.map((elem, index) => {
              return (
                <div
                  style={
                    {
                      // boxShadow: snapshot.isDragging ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                    }
                  }
                  onClick={(e) => selectProductSection(e, elem?.section_name, elem?.section_name)}
                  // onClick={() => {
                  //   handleRatingClick(elem?.section_name);
                  // }}
                  key={index}
                  className={`rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
                    editProductSection?.section_id === elem?.section_name
                      ? 'primary-bg-color text-primary'
                      : 'secondary-bg-color'
                  }`}
                >
                  {/* <div dangerouslySetInnerHTML={{ __html: elem?.icon }}></div> */}
                  <span>{elem?.section_name}</span>
                </div>
              );
            })}
          </ReactDragListView>
        </div>
      </details>
      <hr className='mb-3 border-t main-border-color'></hr>
      {/* <DivideCircle> */}
      <ReactDragListView {...handleSortProduct}>
        {/* {getLayout?.map((elem, index) => {
          return (
            <div
              style={{
                boxShadow: hoverId === index ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
              }}
              key={index}
              onDragStart={() => setFilterId(elem?._id)}
              onDragOver={() => setHoverId(index)}
              onDragLeave={(e) => {
                e.preventDefault();
                setHoverId(null);
              }}
              className={`${
                editProductSection?.section_id === elem?.section_id
                  ? 'primary-bg-color'
                  : 'secondary-bg-color'
              } draggable-main-item rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none p-[10px] text-base headerMenu flex w-full items-center justify-between font-medium`}
            >
              <div
                onClick={(e) => selectProductSection(e, elem?.section_id, elem?.section_type)}
                // onClick={() => {
                //   handleRatingClick(elem?.section_name);
                // }}
                className='w-[80%]'
              >
                <span className='text-[13px] w-full block leading-4 capitalize font-normal'>
                  {elem?.section_type}
                </span>
                <p className='text-xs w-full block leading-4 table-text font-normal'>
                  {elem?.section_type === 'slider'
                    ? elem?.section?.slider_title
                    : elem?.section_type === 'banner'
                    ? elem?.section?.banner_title
                    : elem?.section_type === 'manufacture'
                    ? elem?.section?.brand_section_title
                    : elem?.section_type === 'blog'
                    ? elem?.section?.blog_section_title
                    : elem?.section_type === 'category'
                    ? elem?.section?.category_section_title
                    : elem?.section_type === 'product'
                    ? elem?.section?.product_section_title
                    : elem?.section_type === 'cmsoffer'
                    ? elem?.section?.cms_offer_title
                    : elem?.section_type === 'reassurance'
                    ? elem?.section?.reassurance_title
                    : ''}
                </p>
              </div>
              <div className='relative ms-2 w-[20%] space-x-2 flex items-center justify-end cursor-pointer headerMenuBtn'>
                <div
                  className='table-text hover:text-black transition-all duration-150'
                  onClick={() => {
                    UpdateSectionStatus(elem?._id, !elem.status);
                  }}
                >
                  {elem?.status === true ? (
                    <Eye className='w-4 h-4' />
                  ) : (
                    <EyeOff className='w-4 h-4' />
                  )}
                </div>
                <div
                  className='table-text hover:text-black transition-all duration-150'
                  onClick={() => {
                    setSectionDeleteId(elem?._id);
                    setConfirmationPopup(true);
                  }}
                >
                  <Trash2 className='w-4 h-4' />
                </div>
              </div>
            </div>
          );
        })} */}
        {getLayout?.map((elem, index) => {
          console.log('elemelem', elem);
          let parsedSection = {};
          try {
            parsedSection =
              typeof elem?.section === 'string' ? JSON.parse(elem?.section) : elem?.section;
          } catch (error) {
            console.error('Error parsing section:', error);
          }
          console.log('parsedSection', parsedSection);
          const sectionTypes = [
            'flex_cols',
            'flex_rows',
            'flex_two_grid',
            'flex_grid_two',
            'flex_four_grid',
            'static_content-1',
            'static_content-2',
            'static_content-3',
            'flex_three_grid_three',
            'flex_four_grid_four',
            'flex_two_grid_one',
            'flex_one_grid_two',
            'flex_six_grid_six',
            'flex_five_grid',
            'flex_two_grid_two',
            'grid_two_rows',
            'grid_two_cols',
            'grid_three_cols',
            'grid_three_rows',
            'grid_two_for_two',
            'grid_three_for_three',
            'content-1',
            'content-2',
            'content-3',
            'content-4',
            'content-5',
            'content-6',
            'content-7',
            'content-8',
            'feature-1',
            'feature-2',
            'feature-3',
            'feature-4',
            'feature-5',
            'feature-6',
            'feature-7',
            'feature-8',
            'step-1',
            'step-2',
            'step-3',
            'team-1',
            'team-2',
            'team-3',
            'testimonial-1',
            'testimonial-2',
            'testimonial-3',
            'statistic-1',
            'statistic-2',
            'statistic-3',
            'contact-1',
            'contact-2',
            'contact-3',
          ];
          const isFlexCols = sectionTypes?.includes(elem?.section_type);
          const isParentHovered = hoverId === index;
          const shouldOpen = Object?.keys(sectionAdd)?.find((key) => {
            const hasMatch = parsedSection[key]?.some((block) => {
              const isMatch = block?.id === editSection?.section_id;
              return isMatch;
            });
            return hasMatch;
          });

          return (
            <details
              open={shouldOpen}
              className='cursor-pointer group main-border-color draggable-main-item'
              key={index}
              onDragStart={() => setFilterId(elem?._id)}
              onDragOver={() => setHoverId(index)}
              onDragLeave={(e) => {
                e.preventDefault();
                setHoverId(null);
              }}
            >
              <summary
                className={`${
                  editSection?.section_id === elem?.section_id &&
                  elem?.section_type === editSection?.section_type
                    ? 'primary-bg-color'
                    : 'secondary-bg-color'
                } flex w-full cursor-pointer items-center justify-between`}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    selectSection(e, elem?.section_id, elem?.section_type, elem?.flexBoxIndex);
                  }}
                >
                  <span className='text-sm'>{elem?.section_type}</span>
                </div>
                <div className='relative ms-2 w-[20%] space-x-2 flex items-center justify-end'>
                  {isFlexCols && (
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='w-4 h-5' />
                    </span>
                  )}
                  <div
                    className='table-text hover:text-black transition-all duration-150'
                    onClick={(e) => {
                      UpdateSectionStatus(e, elem?._id, !elem?.status);
                    }}
                  >
                    {loadingSections[elem?._id] ? (
                      <div className='nb-spinner-small'></div>
                    ) : elem?.status === true ? (
                      <Eye className='w-4 h-4' />
                    ) : (
                      <EyeOff className='w-4 h-4' />
                    )}
                  </div>
                  <div
                    className='table-text hover:text-black transition-all duration-150'
                    onClick={() => {
                      setSectionDeleteId(elem?._id);
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-4 h-4' />
                  </div>
                </div>
              </summary>
              <div className={`${isFlexCols ? 'block' : 'hidden'}`}>
                <ReactDragListView {...handleSubItemSort}>
                  {Object.keys(sectionAdd)?.map((key, index) => {
                    const gridItems = parsedSection?.[key];

                    if (gridItems?.length > 0) {
                      const sectionTypeCount = {};

                      Object.keys(sectionAdd)?.forEach((gridKey) => {
                        parsedSection?.[gridKey]?.forEach((item) => {
                          const sectionId = item?.sectionId;
                          if (!sectionTypeCount[sectionId]) {
                            sectionTypeCount[sectionId] = new Set();
                          }
                          sectionTypeCount[sectionId].add(gridKey);
                        });
                      });

                      const hasMultipleKeys = Object.values(sectionTypeCount)?.some(
                        (sectionSet) => sectionSet?.size > 1,
                      );
                      console.log('shouldOpen', shouldOpen);
                      if (hasMultipleKeys) {
                        return (
                          <details
                            open={shouldOpen === key || openedSections?.has(key)}
                            className='cursor-pointer group main-border-color accordion'
                          >
                            <summary className='flex w-full cursor-pointer items-center justify-between'>
                              <div
                                onClick={(e) => {
                                  selectSection(e, elem.section_id, key, elem?.flexBoxIndex);
                                  openAddSection(e, key, 'setting');
                                  handleToggleSection(e, key);
                                }}
                                className={`${
                                  editSection?.section_id === elem?.section_id &&
                                  key === editSection?.section_type
                                    ? 'primary-bg-color'
                                    : 'secondary-bg-color'
                                } flex w-full items-center justify-between rounded-[6px] normal-item-class p-[10px] text-base font-medium`}
                              >
                                <div
                                  onClick={(e) => {
                                    selectSection(e, elem.section_id, key, elem?.flexBoxIndex);
                                    openAddSection(e, key, 'setting');
                                    handleToggleSection(e, key);
                                  }}
                                >
                                  <span className='text-sm'>{key}</span>
                                </div>
                                <span
                                  onClick={(e) => {
                                    selectSection(e, elem.section_id, key, elem?.flexBoxIndex);
                                    openAddSection(e, key, 'setting');
                                    handleToggleSection(e, key);
                                  }}
                                  className={`transition-transform duration-300 ease-in-out transform ${
                                    openedSections?.has(key) || shouldOpen === key
                                      ? 'rotate-180'
                                      : ''
                                  }`}
                                >
                                  <ChevronDown className='w-4 h-5' />
                                </span>
                              </div>
                            </summary>

                            <div className='pl-4'>
                              {gridItems?.map((block, index) => (
                                <div
                                  key={index}
                                  className={`${
                                    editSection?.section_id === block?.id
                                      ? 'primary-bg-color'
                                      : 'secondary-bg-color'
                                  } rounded-[6px] draggable-subMenu-item hover:primary-bg-color transition-all duration-150 group select-none p-[10px] text-base flex w-full items-center justify-between font-medium`}
                                  onClick={(e) => {
                                    selectSection(e, block.id, block.blockName, index);
                                    openAddSection(e, key, 'setting');
                                  }}
                                  onDragStart={(e) => {
                                    setSubSecId(block?.sectionId);
                                  }}
                                  // onMouseEnter={() => setHoverSubId(block.id)}
                                  // onMouseLeave={() => setHoverSubId(null)}
                                  onDragEnter={(e) => {
                                    e.preventDefault();
                                    handleSectionClick(e, elem?.flexBoxIndex, elem?.section_type);
                                    openAddSection(e, key, 'add_section');
                                    selectSection(e, elem?.section_id, key, elem?.flexBoxIndex);
                                    getPrevSecId(e, elem?.flexBoxIndex);
                                    setElementorId(elem?.section_id);
                                  }}
                                  onDragOver={(e) => e.preventDefault()}
                                >
                                  <div className='flex w-[75%] pe-2 items-center'>
                                    <div className='flex space-x-2 text-[13px] table-text w-full leading-4 capitalize font-normal'>
                                      <span>{getIconByBlockName(block?.blockName)}</span>
                                      <span>{block?.blockName}</span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </details>
                        );
                      } else {
                        return (
                          <div key={key} className='normal-div-class'>
                            {gridItems?.map((block, index) => (
                              <div
                                key={index}
                                className={`${
                                  editSection?.section_id === block?.id
                                    ? 'primary-bg-color'
                                    : 'secondary-bg-color'
                                } rounded-[6px] draggable-subMenu-item normal-item-class p-[10px] text-base flex w-full items-center justify-between font-medium`}
                                onClick={(e) => {
                                  selectSection(e, block?.id, block?.blockName, index);
                                  openAddSection(e, key, 'setting');
                                }}
                                onDragStart={() => setSubSecId(block?.sectionId)}
                                onMouseEnter={() => setHoverSubId(block?.id)}
                                onMouseLeave={() => setHoverSubId(null)}
                                onDragEnter={(e) => {
                                  e.preventDefault(),
                                    handleSectionClick(e, elem?.flexBoxIndex, elem?.section_type);
                                  openAddSection(e, key, 'add_section'),
                                    selectSection(
                                      e,
                                      elem?.section_id,
                                      elem?.section_type,
                                      elem?.flexBoxIndex,
                                    );
                                  // getPrevSecId(e, elem?.flexBoxIndex);
                                  // setElementorId(elem?.section_id);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                <div className='flex w-[75%] pe-2 items-center'>
                                  <div className='flex space-x-2 text-[13px] table-text w-full leading-4 capitalize font-normal'>
                                    <span>{getIconByBlockName(block?.blockName)}</span>
                                    <span>{block?.blockName}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      }
                    }

                    return null;
                  })}
                </ReactDragListView>
              </div>
            </details>
          );
        })}
      </ReactDragListView>
      {/* </DivideCircle> */}
    </>
  );
};

export default ProductPageSectionList;
