const BlogCategoryChild = ({ category,storeList, handleSelectProduct }) => {
    return (
        <div>
            <label className='flex items-center space-x-4'>
                <input
                    type='checkbox'
                    value={category?._id}
                    checked={storeList?.some((x) => x?.category_id === category?._id)}
                    onClick={(e) =>
                    handleSelectProduct(
                        category?.blogcategory_name,
                        `/blog/blog-category/${category?.blog_category_slug_name}`,
                        "blog category",
                        category?._id
                    )
                    }
                    className='block w-4 h-4'
                />
                <p className='text-sm  font-normal break-all'>
                    {category?.blogcategory_name}
                </p>
            </label>
            {category?.child_category?.length > 0 && (
                <div  className='ms-5' >
                    {
                        category?.child_category?.map((subCategory) => (
                            <BlogCategoryChild category={subCategory} storeList={storeList?.filter((x) => x?.section_type === "blog category")} handleSelectProduct={handleSelectProduct}/>
                        ))
                    }
                </div>
            )}
        </div>
    );
};

export default BlogCategoryChild;