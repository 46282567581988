import React from 'react';
import EditCrossSellProducts from './EditCrossSellProducts';
import EditProductBrand from './EditProductBrand';
import EditProductBuyButton from './EditProductBuyButton';
import EditProductCounter from './EditProductCounter';
import EditProductCustomFaq from './EditProductCustomFaq';
import EditProductCustomTab from './EditProductCustomTab';
import EditProductDescription from './EditProductDescription';
import EditProductDescriptionTab from './EditProductDescriptionTab';
import EditProductPrice from './EditProductPrice';
import EditProductRating from './EditProductRating';
import EditProductReviewTab from './EditProductReviewTab';
import EditProductSKU from './EditProductSKU';
import EditProductStock from './EditProductStock';
import EditProductTitle from './EditProductTitle';
import EditProductType from './EditProductType';
import EditRelatedProducts from './EditRelatedProducts';
import EditUpSellProducts from './EditUpSellProducts';

const ProductPageEditSection = ({
  editProductSection,
  handleEditProductPageData,
  productPageData,
  selectProductSection,
  selectProductOtherSection,
  editProductOtherSection,
  sectionValue
}) => {
  return (
    <>
      {/* <div className='flex items-center justify-between'>
                <p className='font-medium text-primary text-base capitalize'>Update {editProductSection} Section</p>
                <button onClick={(e) => { e.preventDefault(); selectProductSection(''); selectProductOtherSection('') }} className='close-btn'><X className="w-4 h-4" /></button>
            </div>
            <hr className='my-4 main-border-color' /> */}
      {editProductSection === 'Product name' ? (
        <EditProductTitle
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Rating' ? (
        <EditProductRating
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Description' ? (
        <EditProductDescription
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Price' ? (
        <EditProductPrice
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Category & Brand' ? (
        <EditProductBrand
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Stock' ? (
        <EditProductStock
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Quantity selector' ? (
        <EditProductCounter
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Buy buttons' ? (
        <EditProductBuyButton
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'SKU & Return Policy' ? (
        <EditProductSKU
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Discount OR Variants' ? (
        <EditProductType
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Description Tab' ? (
        <EditProductDescriptionTab
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Review Tab' ? (
        <EditProductReviewTab
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Custom Tab' ? (
        <EditProductCustomTab
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Custom Faq' ? (
        <EditProductCustomFaq
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Related Products' ? (
        <EditRelatedProducts
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Cross Sell Products' ? (
        <EditCrossSellProducts
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : editProductSection === 'Up Sell Products' ? (
        <EditUpSellProducts
          handleEditProductPageData={handleEditProductPageData}
          productPageData={productPageData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductPageEditSection;
