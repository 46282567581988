import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import module from '../../../../../controllers/QuillToolBar';

const StaticFirstCtaSection = ({
  section_id,
  saveElementorData,
  sectionContent,
  btnLoading,
  descriptionContent,
  handleFirstCtaDescriptionChange,
  handleFirstCtaButtonValueChange,
}) => {

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [buttonValue, setButtonValue] = useState(sectionContent);

  const ShortquillRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setShortDescription(descriptionContent);
    setButtonValue(sectionContent);
  }, [descriptionContent, sectionContent]);  

  const onButtonValueChange = (e) => {
    const newValue = e.target.value;
    setButtonValue(newValue);
    handleFirstCtaButtonValueChange(section_id, newValue);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleFirstCtaDescriptionChange(section_id, value);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='w-full mb-4'>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Button</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your button title here...'
          value={buttonValue}
          onChange={onButtonValueChange}
        />
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticFirstCtaSection;
