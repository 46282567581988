import { ArrowLeft, ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ColorPicker from '../Color/ColorPicker';
import Notification from '../Notification';
;
const CommonSetting = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { setPlanPermission } = useContext(PlanPermission);
  const [settingBody, setSettingBody] = useState({});
  const [openState, setOpenState] = useState('');
  const [commonSetting, setCommonSetting] = useState({
    primary_button_color: '#222222',
    primary_button_text_color: '#ffffff',
    secondary_button_color: '#ff4e73',
    secondary_button_text_color: '#ffffff',
    primary_body_text_color: '#666666',
    primary_body_bg_color: '#ffffff',
    primary_header_text_color: '#111111',
    secondary_header_text_color: '#ff4e73',
    price_color: '#111111',
    compare_price_color: '#eeeeee',
    border_color: '#e5e5e5',
    main_title_color: '#111111',
    product_title_color: '#111111',
    header_bg_color: '#ffffff',
    footer_bg_color: '#ffffff',
    primary_footer_title_color: '#111111',
    primary_footer_sub_title_color: '#ff4e73',
    secondary_body_text_color: '#666666',
    secondary_body_bg_color: '#ffffff',
    secondary_footer_sub_title_color: '#222222',
    container_width: 1320,
    section_space: 70,
    button_redius: null,
  });
  const getSettingData = async () => {
    try {
      const apicall = await API.get(`/admin/commonsetting/getCommonSettings`);
      const result = decryptData(apicall?.data)
      setCommonSetting({
        primary_button_color: result?.data?.primary_button_color || '#222222',
        primary_button_text_color: result?.data?.primary_button_text_color || '#ffffff',
        secondary_button_color: result?.data?.secondary_button_color || '#ff4e73',
        secondary_button_text_color: result?.data?.secondary_button_text_color || '#ffffff',
        primary_body_text_color: result?.data?.primary_body_text_color || '#666666',
        primary_body_bg_color: result?.data?.primary_body_bg_color || '#ffffff',
        primary_header_text_color: result?.data?.primary_header_text_color || '#111111',
        secondary_header_text_color: result?.data?.secondary_header_text_color || '#ff4e73',
        price_color: result?.data?.price_color || '#111111',
        compare_price_color: result?.data?.compare_price_color || '#eeeeee',
        border_color: result?.data?.border_color || '#e5e5e5',
        main_title_color: result?.data?.main_title_color || '#111111',
        product_title_color: result?.data?.product_title_color || '#111111',
        header_bg_color: result?.data?.header_bg_color || '#ffffff',
        footer_bg_color: result?.data?.footer_bg_color || '#ffffff',
        primary_footer_title_color: result?.data?.primary_footer_title_color || '#111111',
        primary_footer_sub_title_color:
          result?.data?.primary_footer_sub_title_color || '#ff4e73',
        secondary_body_text_color: result?.data?.secondary_body_text_color || '#000000',
        secondary_body_bg_color: result?.data?.secondary_body_bg_color || '#F0F0F0',
        secondary_footer_sub_title_color:
          result?.data?.secondary_footer_sub_title_color || '#222222',
        container_width: result?.data?.container_width || 1320,
        section_space: result?.data?.section_space || 70,
        button_redius: result?.data?.button_redius || 0,
      });
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'common setting' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getSettingData();
  }, []);

  const handleChange = (value) => {
    if (openState === 'primary_button_color') {
      setSettingBody({ ...settingBody, primary_button_color: value });
      setCommonSetting({ ...commonSetting, primary_button_color: value });
    } else if (openState === 'primary_button_text_color') {
      setSettingBody({ ...settingBody, primary_button_text_color: value });
      setCommonSetting({ ...commonSetting, primary_button_text_color: value });
    } else if (openState === 'secondary_button_color') {
      setSettingBody({ ...settingBody, secondary_button_color: value });
      setCommonSetting({ ...commonSetting, secondary_button_color: value });
    } else if (openState === 'secondary_button_text_color') {
      setSettingBody({ ...settingBody, secondary_button_text_color: value });
      setCommonSetting({ ...commonSetting, secondary_button_text_color: value });
    } else if (openState === 'primary_body_text_color') {
      setSettingBody({ ...settingBody, primary_body_text_color: value });
      setCommonSetting({ ...commonSetting, primary_body_text_color: value });
    } else if (openState === 'primary_body_bg_color') {
      setSettingBody({ ...settingBody, primary_body_bg_color: value });
      setCommonSetting({ ...commonSetting, primary_body_bg_color: value });
    } else if (openState === 'primary_header_text_color') {
      setSettingBody({ ...settingBody, primary_header_text_color: value });
      setCommonSetting({ ...commonSetting, primary_header_text_color: value });
    } else if (openState === 'secondary_header_text_color') {
      setSettingBody({ ...settingBody, secondary_header_text_color: value });
      setCommonSetting({ ...commonSetting, secondary_header_text_color: value });
    } else if (openState === 'price_color') {
      setSettingBody({ ...settingBody, price_color: value });
      setCommonSetting({ ...commonSetting, price_color: value });
    } else if (openState === 'compare_price_color') {
      setSettingBody({ ...settingBody, compare_price_color: value });
      setCommonSetting({ ...commonSetting, compare_price_color: value });
    } else if (openState === 'border_color') {
      setSettingBody({ ...settingBody, border_color: value });
      setCommonSetting({ ...commonSetting, border_color: value });
    } else if (openState === 'main_title_color') {
      setSettingBody({ ...settingBody, main_title_color: value });
      setCommonSetting({ ...commonSetting, main_title_color: value });
    } else if (openState === 'product_title_color') {
      setSettingBody({ ...settingBody, product_title_color: value });
      setCommonSetting({ ...commonSetting, product_title_color: value });
    } else if (openState === 'header_bg_color') {
      setSettingBody({ ...settingBody, header_bg_color: value });
      setCommonSetting({ ...commonSetting, header_bg_color: value });
    } else if (openState === 'footer_bg_color') {
      setSettingBody({ ...settingBody, footer_bg_color: value });
      setCommonSetting({ ...commonSetting, footer_bg_color: value });
    } else if (openState === 'primary_footer_title_color') {
      setSettingBody({ ...settingBody, primary_footer_title_color: value });
      setCommonSetting({ ...commonSetting, primary_footer_title_color: value });
    } else if (openState === 'primary_footer_sub_title_color') {
      setSettingBody({ ...settingBody, primary_footer_sub_title_color: value });
      setCommonSetting({ ...commonSetting, primary_footer_sub_title_color: value });
    } else if (openState === 'secondary_body_text_color') {
      setSettingBody({ ...settingBody, secondary_body_text_color: value });
      setCommonSetting({ ...commonSetting, secondary_body_text_color: value });
    } else if (openState === 'secondary_body_bg_color') {
      setSettingBody({ ...settingBody, secondary_body_bg_color: value });
      setCommonSetting({ ...commonSetting, secondary_body_bg_color: value });
    } else if (openState === 'secondary_footer_sub_title_color') {
      setSettingBody({ ...settingBody, secondary_footer_sub_title_color: value });
      setCommonSetting({ ...commonSetting, secondary_footer_sub_title_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData(settingBody)
      await API.put(`/admin/commonsetting/changeCommonSetting`, payload);
      setIsNotification(true);
      setNotificationMsg('Common setting updated successfully!');
      setSeverity('success');
      setSettingBody({});
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      setSettingBody({});
    }
    setBtnLoading(false);
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto relative overflow-y-auto pb-[130px]'>
        <div className='w-full'>
          <div className='flex items-center space-x-2 pb-[30px]'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/module-setting');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Module_Setting'), path: '/module-setting' },
                  { name: t('lang_Common_Setting'), path: '/module-setting/common-setting' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Common_Setting')}
              </h4>
            </div>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <details
            open
            className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Button_Color')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-5'>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Button_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_button_color')}
                  className='flex cursor-pointer items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_button_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_button_color}
                  </span>
                </div>
                {openState === 'primary_button_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_button_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Button_Text_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_button_text_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_button_text_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_button_text_color}
                  </span>
                </div>
                {openState === 'primary_button_text_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_button_text_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Secondary_Button_Text_Color')}</label>
                <div
                  onClick={() => setOpenState('secondary_button_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.secondary_button_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.secondary_button_color}
                  </span>
                </div>
                {openState === 'secondary_button_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.secondary_button_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Secondary_Button_Color')}</label>
                <div
                  onClick={() => setOpenState('secondary_button_text_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.secondary_button_text_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.secondary_button_text_color}
                  </span>
                </div>
                {openState === 'secondary_button_text_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.secondary_button_text_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full'>
                <label htmlFor='container_width'>{t('lang_Button_Border_Radius')}</label>
                <input
                  className='w-full px-3 py-3 border main-border-color rounded-[6px] focus:outline-none'
                  type='number'
                  id='container_width'
                  name='container_width'
                  value={commonSetting.button_redius}
                  onChange={(e) => {
                    setCommonSetting({ ...commonSetting, button_redius: e.target.value }),
                      e?.target?.value?.length > 0 &&
                      setSettingBody({ ...settingBody, button_redius: e.target.value });
                  }}
                  placeholder='e.g. 4'
                />
              </div>
            </div>
          </details>
          <details open className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'>
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Body_Color')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-5'>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Body_Background_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_body_bg_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_body_bg_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_body_bg_color}
                  </span>
                </div>
                {openState === 'primary_body_bg_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_body_bg_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Body_Text_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_body_text_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_body_text_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_body_text_color}
                  </span>
                </div>
                {openState === 'primary_body_text_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_body_text_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_secondary_Body_Background_Color')}</label>
                <div
                  onClick={() => setOpenState('secondary_body_bg_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.secondary_body_bg_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.secondary_body_bg_color}
                  </span>
                </div>
                {openState === 'secondary_body_bg_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.secondary_body_bg_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_secondary_Body_Text_Color')}</label>
                <div
                  onClick={() => setOpenState('secondary_body_text_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.secondary_body_text_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.secondary_body_text_color}
                  </span>
                </div>
                {openState === 'secondary_body_text_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.secondary_body_text_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Border_Color')}</label>
                <div
                  onClick={() => setOpenState('border_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.border_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.border_color}
                  </span>
                </div>
                {openState === 'border_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.border_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </details>
          <details
            open
            id='headerColor'
            className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Header_Color')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-5'>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Header_Text_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_header_text_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_header_text_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_header_text_color}
                  </span>
                </div>
                {openState === 'primary_header_text_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_header_text_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Secondary_Header_Text_Color')}</label>
                <div
                  onClick={() => setOpenState('secondary_header_text_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.secondary_header_text_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.secondary_header_text_color}
                  </span>
                </div>
                {openState === 'secondary_header_text_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.secondary_header_text_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Header_Background_Color')}</label>
                <div
                  onClick={() => setOpenState('header_bg_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.header_bg_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.header_bg_color}
                  </span>
                </div>
                {openState === 'header_bg_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.header_bg_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </details>
          <details
            open
            id='priceColor'
            className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Price_Color')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 w-full gap-5'>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Price_Color')}</label>
                <div
                  onClick={() => setOpenState('price_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.price_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.price_color}
                  </span>
                </div>
                {openState === 'price_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.price_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Compare_Price_Color')}</label>
                <div
                  onClick={() => setOpenState('compare_price_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.compare_price_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.compare_price_color}
                  </span>
                </div>
                {openState === 'compare_price_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.compare_price_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </details>
          <details open className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'>
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Title_Color')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 w-full gap-5'>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Main_Title_Color')}</label>
                <div
                  onClick={() => setOpenState('main_title_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.main_title_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.main_title_color}
                  </span>
                </div>
                {openState === 'main_title_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.main_title_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Product_Title_Color')}</label>
                <div
                  onClick={() => setOpenState('product_title_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.product_title_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.product_title_color}
                  </span>
                </div>
                {openState === 'product_title_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.product_title_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </details>
          <details
            open
            id='footerColor'
            className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Footer_color')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-5'>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Footer_Background_Color')}</label>
                <div
                  onClick={() => setOpenState('footer_bg_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.footer_bg_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.footer_bg_color}
                  </span>
                </div>
                {openState === 'footer_bg_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.footer_bg_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Footer_Title_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_footer_title_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_footer_title_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_footer_title_color}
                  </span>
                </div>
                {openState === 'primary_footer_title_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_footer_title_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Primary_Footer_Sub_Title_Color')}</label>
                <div
                  onClick={() => setOpenState('primary_footer_sub_title_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.primary_footer_sub_title_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.primary_footer_sub_title_color}
                  </span>
                </div>
                {openState === 'primary_footer_sub_title_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.primary_footer_sub_title_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
              <div className='w-full relative'>
                <label htmlFor='image'>{t('lang_Secondary_Footer_Sub_Title_Color')}</label>
                <div
                  onClick={() => setOpenState('secondary_footer_sub_title_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{ backgroundColor: commonSetting?.secondary_footer_sub_title_color }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {commonSetting?.secondary_footer_sub_title_color}
                  </span>
                </div>
                {openState === 'secondary_footer_sub_title_color' && (
                  <ColorPicker
                    handleChange={handleChange}
                    setting={commonSetting?.secondary_footer_sub_title_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </details>
          <details
            open
            id='footerColor'
            className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              {t('lang_Container_Spacing')}
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <hr className='my-4 border-t main-border-color'></hr>
            <div className='grid grid-cols-1  xl:grid-cols-2 w-full gap-5'>
              <div className='max-w-full mb-4'>
                <label htmlFor='container_width'>{t('lang_Container_Width')} </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='number'
                  id='container_width'
                  name='container_width'
                  value={commonSetting.container_width}
                  onChange={(e) => {
                    setCommonSetting({ ...commonSetting, container_width: e.target.value }),
                      setSettingBody({ ...settingBody, container_width: e.target.value });
                  }}
                  placeholder='Container width..'
                />
              </div>
              <div className='max-w-full mb-4'>
                <label htmlFor='section_space'>{t('lang_Section_Space')} </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='number'
                  id='section_space'
                  name='section_space'
                  value={commonSetting.section_space}
                  onChange={(e) => {
                    setCommonSetting({ ...commonSetting, section_space: e.target.value }),
                      setSettingBody({ ...settingBody, section_space: e.target.value });
                  }}
                  placeholder='Section space..'
                />
              </div>
            </div>
          </details>
        </form>
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-40 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-[90%] flex items-center justify-between sm:w-[80%] xl:w-[68%] mx-auto'>
          <Link
            to={`/module-setting`}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_To_Module_Setting')}
          </Link>
          <div>
            {btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[140px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_Saving')}</span>
              </button>
            ) : (
              <button
                onClick={(e) => handleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Apply_Changes')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonSetting;
