import React from 'react';
import EditProductRatingStyleSection from './EditProductRatingStyleContainer';

const EditProductRatingStyleContainer = ({
  productRatingDesign,
  setProductRatingDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductRatingStyleSection
        productRatingDesign={productRatingDesign}
        setProductRatingDesign={setProductRatingDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductRatingStyleContainer;
