import { Autocomplete, Dialog, Skeleton, TextField } from '@mui/material';
import { ArrowLeft, ChevronDown, Code, Trash2 } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const UpdateFaq = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [elem, setElem] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [nodata, setNodata] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [err, setErr] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [faqtype, setFaqtype] = useState('');
  const [faqData, setFaqData] = useState('');
  const [path, setPath] = useState('');
  const [value, setValue] = useState('');
  const [selectedProduct, setselectedProducts] = useState([]);
  const [confirmationPP, setconfirmationPP] = useState(false);
  const [isChanges, setIsChanges] = useState(false);
  const [formSpinner, setFormSpinner] = useState(true);
  // Get Faq
  const getPerticularFAQ = async () => {
    setFormSpinner(true);
    try {
      let data = await API.get(`/admin/faq/GetFaqsById/${params.faq_id}`);
      const result = decryptData(data?.data)
      setElem(result?.data);
      setFaqData(result?.data?.question);
      setFaqtype(result?.data?.faq_type);
      setValue(result?.data?.answer);
      setselectedProducts(
        result?.data?.product?.length > 0 &&
        result?.data?.product?.map((x) => {
          return { name: x?.product_name, product: x?._id, image: x?.image };
        }),
      );
      setSelectCategory(result?.data?.categories);
      setPath(result?.data?.path);
      setFormSpinner(false);
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setselectedProducts(value);
  };
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setselectedProducts(filterSelectedProduct);
  };

  const [getCategory, setGetCategory] = useState();
  const handleGetCategory = async () => {
    try {
      let getCategoryForExtraTab = await API.get(`/admin/category/GetCategoriesWithAllParent`);
      const result = decryptData(getCategoryForExtraTab?.data)
      setGetCategory(result?.data?.records);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [selectCategory, setSelectCategory] = useState([]);
  const handleSelectCategory = (id, name) => {
    if (id) {
      const cat = selectCategory?.length > 0 && selectCategory?.find((x) => x?._id === id);
      if (!cat) {
        if (selectCategory?.length > 0) {
          setSelectCategory([...selectCategory, { _id: id, category_name: name }]);
        } else {
          setSelectCategory([{ _id: id, category_name: name }]);
        }
      }
    }
  };
  const handleRemoveCategory = (id) => {
    const removeProduct = selectCategory.filter((x) => x?._id !== id);
    setSelectCategory(removeProduct);
  };

  // Quill Emmpty check
  const strippedContent = value?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleUpdate = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (params.fromProduct === 'true' && isChanges && selectedProduct?.length > 1) {
      setconfirmationPP(true);
    } else {
      let body = {
        question: faqData,
        answer: value,
        faq_type: faqtype,
      };
      if (faqtype === 'product') {
        body = {
          ...body,
          product: selectedProduct.map((x) => x.product),
        };
      }
      if (faqtype === 'category') {
        body = {
          ...body,
          categories: selectCategory.map((x) => x._id),
        };
      } else if (faqtype === 'store') {
        body = { ...body };
      } else {
        body = {
          ...body,
          path,
        };
      }
      try {
        const payload = encryptData(body)
        const data = await API.put(`/admin/faq/updateFaq/${params.faq_id}`, payload);
        if (data.status === 200 || data.status === 304) {
          setSaveChanges({
            isUnsaveChanges: false,
            showChangesPopup: false,
            backLink: '/faqs',
            title: 'edit faqs',
          });
          navigate('/faqs');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
    setBtnLoading(false);
  };

  // Update FAQ from Popup
  const updateAll = async () => {
    try {
      const payload = encryptData({
        question: faqData,
        answer: value,
        faq_type: faqtype,
        product: selectedProduct.map((x) => x.product),
      })
      const data = await API.put(`/admin/faq/updateFaq/${params.faq_id}`, payload);
      if (data.status === 200 || data.status === 304) {
        navigate('/faqs');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const updateForSelectedOnly = async () => {
    try {
      const payload = encryptData({
        faq: params?.faq_id,
        product: params?.product,
      })
      let removeProduct = await API.post(`/admin/faq/removeProductFromFAQ`, payload);
      if (removeProduct.status === 200 || removeProduct) {
        const body = encryptData({
          question: faqData,
          answer: value,
          faq_type: faqtype,
          product: [params?.product],
        })
        await API.post('/admin/faq/addFAQ', body);
      }
      navigate('/faqs');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    getPerticularFAQ();
    handleGetCategory();
  }, []);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const setQuillChange = (content) => {
    setValue(content);
    setIsChanges(true);
    setIsChange(true);
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const [warn, setwarn] = useState(false);
  const [AiLoader, setAiloader] = useState(false);
  const generateAnswer = async () => {
    setAiloader(true);
    if (faqData?.length === 0) {
      setwarn(true);
    } else {
      setwarn(false);
      try {
        const payload = encryptData({
          question: faqData,
        })
        let ApiCall = await API.post('/admin/ai/answer_from_questionFAQ', payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          const result = decryptData(getCategoryForExtraTab?.data)
          setValue(result?.data);
        }
      } catch (error) {
        if (error?.response?.status === 429) {
          setIsNotification(true);
          setNotificationMsg(t('lang_Hit_chatgpt_limit'));
          setSeverity('warning');
        } else {
          setIsNotification(true);
          setNotificationMsg(error?.response?.data?.message);
          setSeverity('error');
        }
      } finally {
        setAiloader(false);
      }
    }
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/faqs',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit faqs',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/faqs',
        title: 'edit faqs',
      });
    } else {
      navigate('/faqs');
    }
  };
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.lowercase_category
        } ${option?.parent_category?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <SelectProduct
        isSelectProduct={isSelectProduct}
        api={`/admin/product/getProductsName?`}
        closeSelectProduct={closeSelectProduct}
        getSelectedProduct={getSelectedProduct}
        alreadySelected={selectedProduct}
      />
      <Dialog
        open={confirmationPP}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setconfirmationPP(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[400px] max-h-full rounded-none  relative p-5 secondary-bg-color shadow dark:bg-gray-700'>
          <div className='w-full  mb-4'>
            <div className='flex justify-between'>
              <label className='flex text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                {t('lang_Do_you_want_to_update_FAQ_for_all_products_or_only_for')}{' '}
                {selectedProduct?.length > 0 &&
                  selectedProduct?.find((x) => x.product === params.product)?.product_name}{' '}
                {t('lang_Product')}?<span></span>
              </label>
              <div className='w-8 h-8'>
                <X
                  onClick={() => {
                    setconfirmationPP(false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex justify-between space-x-3'>
            <button
              className='btn text-white w-1/2 text-sm px-3 py-2 rounded-[6px] mx-auto'
              onClick={() => updateAll()}
            >
              {t('lang_For_All_Products')}
            </button>
            <button
              className='btn text-white w-1/2 text-sm  px-3 py-2 rounded-[6px] mx-auto'
              onClick={() => updateForSelectedOnly()}
            >
              {t('lang_For_Perticular_Product')}
            </button>
          </div>
        </div>
      </Dialog>

      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_faqs'), path: '/faqs' },
                { name: t('lang_Update_FAQ'), path: '/faqs/update-faq' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_FAQ')}
            </h4>
          </div>
        </div>
        <div className='flex flex-wrap'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[150px]' variant='rounded-[6px]' />
                </div>
              </div>
            ) : (
              <>
                <div className='secondary-bg-color border main-border-color  rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='mb-4'>
                    <label htmlFor='Qustion'>
                      <p className='text-[13px] lowercase'>
                        {t('lang_Question')}
                        <span className='text-red-500 ms-1'>*</span>
                      </p>
                    </label>
                    <input
                      className={
                        err && question.length === 0
                          ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                          : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                      }
                      type='text'
                      id='question'
                      name='question'
                      value={faqData}
                      onChange={(e) => {
                        setFaqData(e.target.value);
                        setIsChange(true);
                        setIsChanges(true);
                        setwarn(false);
                      }}
                      placeholder='Write your question'
                    />
                    {err && question.length === 0 ? (
                      <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Question')}</p>
                    ) : (
                      <></>
                    )}
                    {warn && (
                      <p className='text-sm text-red-500'>
                        {t('lang_Please_Enter_Question_To_Generate_Answer')}
                      </p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <div className='flex justify-between'>
                      <label htmlFor='Answer'>
                        <p className='text-[13px] lowercase'>
                          {t('lang_Answer')}
                          <span className='text-red-500 ms-1'>*</span>
                        </p>
                      </label>
                      <div className='flex items-center'>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => generateAnswer()}
                        >
                          <BardSvg /> {t('lang_Get_Answer')}
                        </span>
                        <section
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={handleViewHtml}
                        >
                          <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                        </section>
                      </div>
                    </div>
                    <ReactQuill
                      modules={module}
                      ref={quillRef}
                      className='w-full h-auto rounded-[6px]'
                      theme='snow'
                      name='description'
                      value={value}
                      onChange={setQuillChange}
                      placeholder='Write your answer'
                    />
                    {err && trimmedContent === '' ? (
                      <p className='text-red-500 font-medium text-sm'>
                        {t('lang_Please_Enter_Answer')}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='w-full'>
                    <label>
                      <p className='text-[13px]'>
                        {t('lang_FAQ_Type')}
                        <span className='text-red-500 ms-1'>*</span>
                      </p>
                    </label>
                    <div className='w-full relative'>
                      <select
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        name='limit'
                        value={faqtype}
                        onChange={(e) => {
                          setFaqtype(e.target.value);
                          setIsChange(true);
                        }}
                      >
                        <option
                          value='store'
                          className='w-full block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                        >
                          {t('lang_store')}
                        </option>
                        <option
                          value='product'
                          className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                        >
                          {t('lang_Product')}
                        </option>
                        <option
                          value='category'
                          className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                        >
                          {t('lang_category')}
                        </option>
                        <option
                          value='custom'
                          className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                        >
                          {t('lang_custom')}
                        </option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  {faqtype === 'category' && (
                    <>
                      <div className='mt-4'>
                        <label htmlFor='Description'>
                          <p className='text-[13px]'>
                            {t('lang_category')}
                            <span className='text-red-500 ms-1'>*</span>
                          </p>
                        </label>

                        <div>
                          <Autocomplete
                            disableClearable
                            className='autocomplete'
                            options={getCategory ? getCategory : []}
                            filterOptions={filterOptionsForCategory}
                            getOptionLabel={(category) => category?.lowercase_category}
                            noOptionsText={
                              getCategory?.length > 0 && !autocompleteSearch
                                ? 'Please enter at least 3 characters'
                                : 'No option found'
                            }
                            onChange={(e, v) => handleSelectCategory(v._id, v?.categoryName)}
                            renderInput={(Categories) => (
                              <TextField
                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                value={Categories?.inputValue}
                                onChange={(e) => {
                                  const newInputValue = e.target.value;
                                  if (
                                    typeof newInputValue === 'string' &&
                                    newInputValue?.length >= 3
                                  ) {
                                    setAutocompleteSearch(newInputValue);
                                  } else {
                                    setAutocompleteSearch();
                                  }
                                }}
                                placeholder='Select category'
                                className='cursor-pointer'
                                {...Categories}
                              />
                            )}
                          />
                          <div className='flex flex-wrap mt-2'>
                            {selectCategory?.map((elem, index) => {
                              return (
                                <div
                                  key={index}
                                  className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-left px-3 capitalize py-1.5 rounded-[6px]'
                                >
                                  <span className='table-text'>{elem?.category_name}</span>
                                  <X
                                    className='h-[14px] text-red-500 hover:text-red-700 min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                                    onClick={() => handleRemoveCategory(elem?._id)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {faqtype === 'custom' && (
                    <div className='mt-4 w-full'>
                      <label>
                        <p className='text-[13px]'>
                          {t('lang_Page')}
                          <span className='text-red-500 ms-1'>*</span>
                        </p>
                      </label>
                      <div className='w-full relative'>
                        <select
                          className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                          name='path'
                          value={path}
                          onChange={(e) => {
                            setPath(e.target.value);
                            setIsChange(true);
                          }}
                        >
                          <option
                            value='cart'
                            className='w-full block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                          >
                            {t('lang_cart')}
                          </option>
                          <option
                            value='checkout'
                            className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                          >
                            {t('lang_Checkout')}
                          </option>
                          <option
                            value='blog'
                            className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                          >
                            {t('lang_blog')}
                          </option>
                          <option
                            value='portfolio'
                            className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                          >
                            {t('lang_portfolio')}
                          </option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  )}
                </div>
                {faqtype === 'product' && (
                  <details className='secondary-bg-color cursor-pointer group shadow rounded-[6px] p-5 sm:p-[25px]'>
                    <summary className='text-base flex items-center justify-between font-medium '>
                      <div>
                        {t('lang_products')}
                        <p className='text-xs text-gray-500 font-normal'>
                          {t('lang_Select_product_for_show_this_FAQ')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className='w-4 h-5' />
                      </span>
                    </summary>
                    <hr className='mt-4 main-border-color' />
                    <div className='w-full text-center'>
                      {selectedProduct?.length > 0 ? (
                        <div className='max-h-[450px] overflow-y-auto divide-y'>
                          {selectedProduct?.map((elem) => {
                            return (
                              <div className='flex items-center main-border-color py-2 space-x-3'>
                                <button
                                  onClick={(e) => removeSelectProduct(e, elem?.product)}
                                  className='close-btn'
                                >
                                  <X className='w-[14px] h-[14px] text-red-600' />
                                </button>
                                <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                  <img
                                    onError={(e) => {
                                      e.target.src = NOPHOTO_IMAGE;
                                    }}
                                    src={elem?.image || NOPHOTO_IMAGE}
                                    className='max-w-full max-h-full'
                                    alt={elem?.name}
                                  />
                                </div>
                                <p className='text-sm'>{elem?.name}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <button
                          className='bulk-btn mt-4'
                          onClick={(e) => {
                            e.preventDefault();
                            setIsSelectProduct(true);
                          }}
                        >
                          {t('lang_Select_Product')}
                        </button>
                      )}
                      {selectedProduct?.length > 0 && (
                        <>
                          <div className='flex items-center border-t main-border-color justify-between pt-2'>
                            <button
                              className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                              onClick={(e) => {
                                e.preventDefault();
                                setIsSelectProduct(true);
                              }}
                            >
                              + {t('lang_select_more_product')}
                            </button>
                            <button
                              className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                              onClick={(e) => {
                                e.preventDefault();
                                setselectedProducts([]);
                              }}
                            >
                              <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                              {t('lang_remove_all_product')}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </details>
                )}
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_FAQs')}
            </Link>
            {faqData === '' ||
              trimmedContent === '' ||
              (faqtype === 'product' && !selectedProduct?.length) ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Update_FAQ')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[131px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_Saving')}</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleUpdate(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Update_FAQ')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default UpdateFaq;
