import React, { useEffect, useState } from 'react';
import API from '../../../API';
import { Edit, PlusCircle, Trash2 } from 'feather-icons-react/build/IconComponents';
import { Tooltip } from '@mui/material';
import EditNavbarField from '../../NavbarField/EditNavbarField';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import Notification from '../../Notification';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import { result } from 'lodash';

const AnnouncementSection = ({ handleChangeAnnouncement }) => {
  const [storeNavbarField, setStoreNavbarField] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getNavbarFields = async () => {
    try {
      const apiCall = await API.get('/admin/client/GetTopNavBarFields');
      const result = decryptData(apiCall?.data)
      setStoreNavbarField(result?.data);
      handleChangeAnnouncement(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNavbarFields();
  }, []);
  const [openEditNavbarField, setOpenEditNavbarField] = useState(false);
  const [editId, setEditId] = useState();
  const [editNavbarField, setEditNavbarField] = useState({
    title: '',
    redirect_url: '',
  });
  const handleEditField = async (data) => {
    setOpenEditNavbarField(true);
    setEditId(data?._id);
    setEditNavbarField({
      title: data?.title,
      redirect_url: data?.redirect_url,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId?.length > 0 && openEditNavbarField) {
        const payload = encryptData(editNavbarField)
        const apiCall = await API.put(
          `/admin/client/UpdateTopNavBarFields/${editId}`,payload
        );
        if (apiCall.status === 200) {
          getNavbarFields();
          setOpenEditNavbarField(false);
          setEditNavbarField({
            title: '',
            redirect_url: '',
          });
          setEditId();
          setOpenEditNavbarField(false);
          setIsNotification(true);
          setNotificationMsg('Announcement edited successfully!');
          setSeverity('success');
        }
      } else {
        const payload = encryptData({
          top_nav_bar_fields: {
            title: 'Welcome to our store',
            redirect_url: '',
          },
        })
        const apiCall = await API.post('/admin/client/AddTopNavBarFields',payload );
        if (apiCall.status === 200) {
          getNavbarFields();
          setIsNotification(true);
          setNotificationMsg('Announcement added successfully!');
          setSeverity('success');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleDeleteField = async () => {
    try {
      const apiCall = await API.delete(`/admin/client/DeleteTopNavBarFields/${deleteId}`);
      if (apiCall.status === 200) {
        getNavbarFields();
        setIsNotification(true);
        setNotificationMsg('Announcement delete successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  return (
    <div>
      <ConfirmationPopup
        heading='Delete Announcement'
        subheading='Do you really want to delete this announcement from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDeleteField}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='table-text space-y-0.5  mt-2'>
        {storeNavbarField?.map((field, index) => {
          return (
            <div
              key={index}
              className={`rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center justify-between`}
            >
              <p className='overflow-auto'>
                <span className='text-sm'>{field.title}</span>
              </p>
              <div className='flex headerMenuBtn items-center space-x-2'>
                <Tooltip title='Edit' arrow>
                  <button onClick={() => handleEditField(field)}>
                    <Edit className='w-[15px] h-[15px]' />
                  </button>
                </Tooltip>
                <Tooltip title='Delete' arrow>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setDeleteId(field?._id);
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-[15px] h-[15px]' />
                  </button>
                </Tooltip>
              </div>
            </div>
          );
        })}
      </div>
      <button
        onClick={(e) => handleSubmit(e)}
        className='text-link hover:underline mt-2 ps-4 inline-flex items-center text-sm'
      >
        <PlusCircle className='w-4 h-4 me-2' />
        {t('lang_Add_Announcement')}
      </button>
      {openEditNavbarField && (
        <EditNavbarField
          openEditNavbarField={openEditNavbarField}
          setOpenEditNavbarField={setOpenEditNavbarField}
          editNavbarField={editNavbarField}
          setEditNavbarField={setEditNavbarField}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default AnnouncementSection;
