import FV from '../favicon.png'
import NoPhoto from '../nophoto.png'
import NoVideo from '../i-2.webp'
import NoUser from '../user.jpg'
import SEBlackLogo from '../shop-easy-black.png'
import SEWhiteLogo from '../shop-easy-white.png'
import SELogo from '../site-logo.png'


export const FAVICON_IMAGE = FV
export const NOPHOTO_IMAGE = NoPhoto
export const NOVIDEO_THUMBNAIL = NoVideo
export const NOUSER_IMAGE = NoUser
export const PRODUCT_SAMPLE_CSV = "https://cdn.shopeasy.ai/1/productcsv/productlist-956ae310bcca9fee05d37.csv"
export const SHOPEASY_BLACK_LOGO = SEBlackLogo
export const SHOPEASY_WHITE_LOGO = SEWhiteLogo
export const SITE_LOGO = SELogo
export const UFO_GIF = "https://cdn.shopeasy.ai/1/category/ufo.gif"