// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* *{
    line-height: 120%;
} */
.edit-style-section .edit-select{
    padding: 2.9px 15px !important;
    font-size: 12px !important;
    border: none;
    border-radius: 4px;
}
.edit-style-section .edit-input{
    padding: 2.9px 15px !important;
    font-size: 12px !important;
    background-color: transparent !important;
}
.edit-style-section .edit-border{
    border-color: #D9D9D9;
}
.edit-style-section .unit-select{
    position: absolute;
    right: 15px;
    font-size: 12px !important;
    height: 80%;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    text-transform: uppercase;
    padding: 0 10px !important;
}
.edit-style-section .size-box .unit-select{
    right: 2px !important;
    padding: 0 5px !important;
}
.edit-style-section .unit-input{
    border-radius: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
    width: 100% !important;
    height: 28px !important;
    text-align: center;
    display: inline-block;
}
.hovered .path1,
.hovered .path2 {
  stroke: #ffffff;
}

.hover\\:text-white:hover .path1,
.hover\\:text-white:hover .path2 {
  stroke: #ffffff;
}

.active .path1,
.active .path2 {
  stroke: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/EditSectionStyle.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,8BAA8B;IAC9B,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,8BAA8B;IAC9B,0BAA0B;IAC1B,wCAAwC;AAC5C;AACA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,0BAA0B;IAC1B,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,YAAY;IACZ,yBAAyB;IACzB,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B;AACA;IACI,2BAA2B;IAC3B,qBAAqB;IACrB,0BAA0B;IAC1B,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;AACzB;AACA;;EAEE,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB","sourcesContent":["/* *{\r\n    line-height: 120%;\r\n} */\r\n.edit-style-section .edit-select{\r\n    padding: 2.9px 15px !important;\r\n    font-size: 12px !important;\r\n    border: none;\r\n    border-radius: 4px;\r\n}\r\n.edit-style-section .edit-input{\r\n    padding: 2.9px 15px !important;\r\n    font-size: 12px !important;\r\n    background-color: transparent !important;\r\n}\r\n.edit-style-section .edit-border{\r\n    border-color: #D9D9D9;\r\n}\r\n.edit-style-section .unit-select{\r\n    position: absolute;\r\n    right: 15px;\r\n    font-size: 12px !important;\r\n    height: 80%;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n    border: none;\r\n    text-transform: uppercase;\r\n    padding: 0 10px !important;\r\n}\r\n.edit-style-section .size-box .unit-select{\r\n    right: 2px !important;\r\n    padding: 0 5px !important;\r\n}\r\n.edit-style-section .unit-input{\r\n    border-radius: 0 !important;\r\n    padding: 0 !important;\r\n    font-size: 12px !important;\r\n    width: 100% !important;\r\n    height: 28px !important;\r\n    text-align: center;\r\n    display: inline-block;\r\n}\r\n.hovered .path1,\r\n.hovered .path2 {\r\n  stroke: #ffffff;\r\n}\r\n\r\n.hover\\:text-white:hover .path1,\r\n.hover\\:text-white:hover .path2 {\r\n  stroke: #ffffff;\r\n}\r\n\r\n.active .path1,\r\n.active .path2 {\r\n  stroke: #ffffff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
