import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../../API';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import MuiSwitch from '../../../controllers/MuiSwitch';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';

const EditProductSection = ({ section_id, getSectionData, section_type }) => {
  const [storeProduct, setStoreProduct] = useState([]);
  const [productGroupTitle, setProductGroupTitle] = useState('');
  const [productData, setProductData] = useState({
    product_limit: 4,
    carousel_status: true,
    status: true,
    product_image_width: '',
    product_image_height: '',
    carousel_speed_ms: '',
    loop_forever: false,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
    is_filter_apply: true,
    filter_type: 'New Arrivals',
  });
  const [isCall, setIsCall] = useState(false);
  const handleGetPerticularProductGroup = async () => {
    try {
      const apiCall = await API.get(`/admin/dynamicProduct/getProductGroupById/${section_id}`);
      setIsCall(true);
      const result = decryptData(apiCall?.data);
      setProductData({
        button_text: result?.data?.button_text,
        button_link: result?.data?.button_link,
        product_limit: result?.data?.product_limit,
        carousel_status: result?.data?.carousel_status,
        status: result?.data?.status,
        product_image_width: result?.data?.product_image_width,
        product_image_height: result?.data?.product_image_height,
        carousel_speed_ms: result?.data?.carousel_speed_ms,
        loop_forever: result?.data?.loop_forever,
        is_filter_apply: result?.data?.is_filter_apply,
        pause_on_hover: result?.data?.pause_on_hover,
        auto_play: result?.data?.auto_play,
        filter_type: result?.data?.filter_type,
      });
      setProductGroupTitle(result?.data?.product_section_title);
      setStoreProduct(result?.data?.products);
    } catch (error) {
      console.log(error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          button_text: productData?.button_text,
          button_link: productData?.button_link,
          product_limit: productData?.product_limit,
          carousel_status: productData?.carousel_status,
          product_image_width: Number(productData?.product_image_width),
          product_image_height: Number(productData?.product_image_height),
          carousel_speed_ms: productData?.carousel_speed_ms,
          loop_forever: productData?.loop_forever,
          is_filter_apply: productData?.is_filter_apply,
          pause_on_hover: productData?.pause_on_hover,
          auto_play: productData?.auto_play,
          filter_type: productData?.filter_type,
          product_section_title: productGroupTitle,
          products: storeProduct,
        },
        status: productData?.status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [productData, productGroupTitle, storeProduct]);
  useEffect(() => {
    if (section_id) {
      handleGetPerticularProductGroup();
    }
  }, [section_id]);
  const [dataErr, setDataErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleUpdateProductGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        product_section_title: productGroupTitle,
        button_text: productData?.button_text,
        button_link: productData?.button_link,
        product_limit: productData?.product_limit,
        carousel_status: productData?.carousel_status,
        status: productData?.status,
        product_image_width: productData?.product_image_width,
        product_image_height: productData?.product_image_height,
        carousel_speed_ms: productData?.carousel_speed_ms,
        loop_forever: productData?.loop_forever,
        auto_play: productData?.auto_play,
        pause_on_hover: !productData?.auto_play ? false : productData?.pause_on_hover,
        is_filter_apply: productData?.is_filter_apply,
        filter_type: productData?.filter_type,
      });
      await API.put(`/admin/dynamicProduct/updateProductGroup/${section_id}`, payload);
      getSectionData('call layout');
    } catch (error) {
      console.log(error);
    }
    setBtnLoading(false);
  };
  const getAiGeneratedTitle = (value) => {
    setProductGroupTitle(value);
  };
  return (
    <>
      <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='w-full space-y-4'>
        <div className='secondary-bg-color relative w-full overflow-hidden space-y-4'>
          <div className='w-full'>
            <label htmlFor='group title' className='flex justify-between'>
              <label htmlFor='group title'>{t('lang_Product_Group_Title')}</label>
              <GenerateLiveEditiorTitle
                getAiGeneratedTitle={getAiGeneratedTitle}
                section_type={section_type}
              />
            </label>
            <input
              className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
              type='text'
              id='group title'
              name='group title'
              value={productGroupTitle}
              onChange={(e) => {
                setProductGroupTitle(e.target.value);
              }}
              placeholder='Enter title....'
            />
            {dataErr && productGroupTitle?.length === 0 && (
              <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
            )}
          </div>
          <div className='w-full'>
            <label>{t('lang_Filter_Type')}</label>
            <div
              className={
                dataErr && productData?.is_filter_apply && productData?.filter_type === ''
                  ? 'border border-red-500 rounded-[6px] w-full relative'
                  : 'relative'
              }
            >
              <select
                className='w-full'
                disabled={!productData?.is_filter_apply}
                value={productData?.filter_type}
                onClick={() => setDataErr(false)}
                onChange={(e) => {
                  setProductData({ ...productData, filter_type: e.target.value });
                }}
              >
                <option value='New Arrivals'>{t('lang_New_Arrivals')}</option>
                <option value='Special'>{t('lang_Special')}</option>
                <option value='Featured'>{t('lang_featured')}</option>
                <option value='Top Rated'>{t('lang_Top_Rated')}</option>
              </select>
              <div className='select-arrow'></div>
            </div>
            {dataErr && productData?.is_filter_apply && productData?.filter_type === '' ? (
              <p className='text-xs text-red-500'>{t('lang_Please_Select_Filter_Type')}</p>
            ) : (
              ''
            )}
          </div>
          <div className='w-full'>
            <label>{t('lang_Product_Limit')}</label>
            <div className='relative'>
              <select
                className='w-full'
                value={productData?.product_limit}
                onChange={(e) => {
                  setProductData({ ...productData, product_limit: e.target.value });
                }}
              >
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>
          <div className='w-full'>
            <label>{t('lang_Carousel_Speed_Ms')}</label>
            <div className='relative'>
              <input
                type='number'
                value={productData?.carousel_speed_ms}
                disabled={productData?.carousel_status === 'disabled'}
                onChange={(e) => {
                  setProductData({ ...productData, carousel_speed_ms: e.target.value });
                }}
                className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                placeholder='e.g. 5000'
              />
            </div>
          </div>
          <div className='w-full'>
            <label htmlFor='image'>{t('lang_Button_Text')}</label>
            <input
              type='text'
              value={productData?.button_text}
              className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
              onChange={(e) => {
                setProductData({ ...productData, button_text: e.target.value });
              }}
              placeholder='e.g. Explore All'
            />
          </div>
          <div className='w-full'>
            <label htmlFor='image'>{t('lang_Button_Link')}</label>
            <input
              type='text'
              value={productData?.button_link}
              className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
              placeholder='Add link'
              onChange={(e) => {
                setProductData({ ...productData, button_link: e.target.value });
              }}
            />
          </div>
          <div className='w-full'>
            <label>
              {t('lang_Product_Image_Width')}{' '}
              <span className='lowercase text-xs font-normal text-gray-500'>
                ( {t('lang_In_Pixels')} )
              </span>
            </label>
            <div className='relative'>
              <input
                value={productData?.product_image_width}
                onChange={(e) => {
                  setProductData({ ...productData, product_image_width: e.target.value });
                }}
                type='number'
                className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                placeholder='e.g. 318'
              />
            </div>
          </div>
          <div className='w-full'>
            <label>
              {t('lang_Product_Image_Height')}{' '}
              <span className='lowercase text-xs font-normal text-gray-500'>
                ( {t('lang_In_Pixels')} )
              </span>
            </label>
            <div className='relative'>
              <input
                value={productData?.product_image_height}
                onChange={(e) => {
                  setProductData({ ...productData, product_image_height: e.target.value });
                }}
                type='number'
                className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                placeholder='e.g. 192'
              />
            </div>
          </div>
          <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded-[6px] items-center'>
            <p className='table-text inline-block font-medium text-[13px]'>
              {t('lang_Carousel_Status')}
            </p>
            <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
              <FormControlLabel
                checked={productData?.carousel_status}
                control={<MuiSwitch />}
                onChange={(e) => {
                  setProductData({
                    ...productData,
                    carousel_status: !productData?.carousel_status,
                  });
                }}
              />
            </label>
          </div>
          <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
            <p className='table-text font-medium text-[13px]'>{t('lang_Carousel_Loop')}</p>
            <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
              <FormControlLabel
                checked={productData?.loop_forever}
                control={<MuiSwitch />}
                onChange={(e) => {
                  setProductData({ ...productData, loop_forever: !productData?.loop_forever });
                }}
              />
            </label>
          </div>
          <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
            <p className='table-text font-medium text-[13px]'>{t('lang_Autoplay')}</p>
            <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
              <FormControlLabel
                checked={productData?.auto_play}
                control={<MuiSwitch />}
                onChange={(e) => {
                  setProductData({ ...productData, auto_play: !productData?.auto_play });
                }}
              />
            </label>
          </div>
          {productData?.auto_play && (
            <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
              <p className='table-text font-medium text-[13px]'>{t('lang_Pause_On_Hover')}</p>
              <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                <FormControlLabel
                  checked={productData?.pause_on_hover}
                  control={<MuiSwitch />}
                  onChange={(e) => {
                    setProductData({
                      ...productData,
                      pause_on_hover: !productData?.pause_on_hover,
                    });
                  }}
                />
              </label>
            </div>
          )}
          <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
            <p className='table-text font-medium text-[13px]'>{t('lang_Product_Group_Status')}</p>
            <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
              <FormControlLabel
                checked={productData?.status}
                control={<MuiSwitch />}
                onChange={(e) => {
                  setProductData({ ...productData, status: !productData?.status });
                }}
              />
            </label>
          </div>
          <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
            <p className='table-text font-medium text-[13px]'>{t('lang_Filter_Apply')}</p>
            <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
              <FormControlLabel
                checked={productData?.is_filter_apply}
                control={<MuiSwitch />}
                onChange={(e) => {
                  setProductData({
                    ...productData,
                    is_filter_apply: !productData?.is_filter_apply,
                  });
                  setStoreProduct([]);
                }}
              />
            </label>
          </div>

          <hr className='border-t main-border-color my-4'></hr>
        </div>
        <div className='w-full'>
          {productGroupTitle?.length === 0 ? (
            <button
              type='button'
              className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            >
              <span>{t('lang_Apply_Changes')}</span>
            </button>
          ) : btnLoading ? (
            <button
              type='button'
              className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[167px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
            >
              <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
              <span>{t('lang_loading')}...</span>
            </button>
          ) : (
            <button
              onClick={(e) => handleUpdateProductGroup(e)}
              className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            >
              <span>{t('lang_Apply_Changes')}</span>
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default EditProductSection;
