import { AlertCircle, ArrowLeft, ChevronDown, X } from "feather-icons-react/build/IconComponents";
import React, { useState } from "react";
import Countdown from 'react-countdown';
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../../API";
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from "../../controllers/encryptionUtils";
import Notification from '../Notification';
import { clientData } from "../../controllers/GetClient";
import moment from "moment-timezone";
import { Dialog } from "@mui/material";
import { Transition } from "../../controllers/Transition";
const DeleteStore = ({ email, deletestorepopup, setDeleteStorePopup }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const [err, setErr] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [isResend, setIsResend] = useState(false)
  const Completionist = () => setIsResend(true);
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [otpSpinner, setOtpSpinner] = useState(false)
  const [deleteotprequst, setDeleteOtpRequest] = useState()
  const [delete_store_reason, setDelete_store_reason] = useState('');
  const HandleDeleteStoreOtp = async (e) => {
    e.preventDefault();
    setOtpSpinner(true)
    try {
      const ApiCall = await API.post('/admin/user/deleteStore')
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Verification mail send to your email');
        setSeverity('success');
        setDD({ ...DD, OTPSent: true });
        setDeleteOtpRequest(ApiCall.data.data)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setOtpSpinner(false)
    }
  }
  const [storeReason, setStoreReason] = useState();
  const selectReason = (e, name, reason) => {
    e.preventDefault()
    const keyExists = storeReason?.hasOwnProperty(name);

    if (keyExists) {
      const newData = { ...storeReason };
      delete newData[name];
      setStoreReason(newData);
    } else {
      setStoreReason({ ...storeReason, [name]: reason });
    }
  };
  const [DD, setDD] = useState({ OTPSent: false, OTPWarn: false, OTP: ' ', QRCode: '' });
  const HandleDeleteStore = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        otp: DD.OTP,
        delete_store_reason: storeReason
      })
      await API.post('/admin/user/emailVerifyAndDeleteStore', payload)
      localStorage.removeItem("token")
      navigate("/")
    } catch (error) {
      if (error.response.status === 422) {
        setInvalidCredentials(true)
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [clickcheckbox, setClickCheckBox] = useState({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false })
  const { checkbox1, checkbox2, checkbox3, checkbox4 } = clickcheckbox
  return (
    <>
      <Dialog
        open={deletestorepopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setDeleteStorePopup(false); setClickCheckBox({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) }}
        aria-describedby="alert-dialog-slide-description"
      >
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
         <div className="secondary-bg-color rounded-[6px] p-5 sm:p-[25px] w-full">
          <div className='flex items-center justify-between'>
            <h5 className='font-medium text-primary'>Delete store</h5>
            <button className='close-btn' onClick={(e) => { e.preventDefault(); setDeleteStorePopup(false); setClickCheckBox({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) }}><X className="w-4 h-4" /></button>
          </div>
          <hr className="my-4 main-border-color" />
          <div className="flex w-full md:w-[600px] justify-evenly">
            <div>
              <p className='text-[15px] font-medium'>
                Before you delete your store, please review and accept the following conditions
              </p>
              <ul className='my-3 space-y-3 list-none text-sm'>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox1} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox1: !checkbox1 })} /> All your data will be permanently deleted including your products, orders, customers, etc.</li>
                </label>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox2} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox2: !checkbox2 })} /> All your account information will be permanently deleted from our database.</li>
                </label>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox3} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox3: !checkbox3 })} /> You will not be able to recover your store's data back.</li>
                </label>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox4} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox4: !checkbox4 })} /> You have a {moment(clientData?.billing_detail?.next_billing_date).diff(new Date(), 'days')} days left until your plan expire, and please note that the purchase is non-refundable.</li>
                </label>
              </ul>
              <label className='block text-[#484848] text-[13px] font-medium' htmlFor='email'>
                Email Verify
              </label>
              <div className=''>
                <div className="w-full">
                  <input
                    className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                    type='text'
                    id='email'
                    name='email'
                    placeholder='Enter your email'
                    value={email}
                    onClick={() => setErr('you cannot change email..')}
                    onBlur={() => setErr('')}
                  />
                  <p className='text-red-500 font-medium text-xs'>{err}</p>
                </div>
                {!deleteotprequst?.otp_expire_time ?
                  otpSpinner ?
                    <button type='button'
                      className='inline-flex my-4 items-center justify-center space-x-2 bg-btn text-white text-xs font-medium w-[150px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'>
                      <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-yellow-1000 rounded-full">
                      </div>
                      <span>Loading...</span>
                    </button> :
                    <button
                      className='ml-auto btn text-white text-xs my-4'
                      onClick={(e) => HandleDeleteStoreOtp(e)}
                    >
                      Get Otp
                    </button> : <></>
                }
              </div>
              {DD.OTPSent &&
                <div className="my-4">    <label htmlFor='email-address' className='sr-only'>
                  OTP:
                </label>
                  <input
                    id='email-address'
                    name='Phone'
                    type='number'
                    value={DD.OTP}
                    onKeyDown={() => setDD({ ...DD, OTPWarn: false })}
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target.value.length <= 6) {
                        setDD({ ...DD, OTP: e.target.value });
                      }
                    }}
                    required
                    className='relative block w-full border border-gray-300 py-2.5 pl-4  outline-none '
                    placeholder={
                      DD.QRCode ? 'Enter Code From QRCode' : 'Enter Security Code From Email'
                    }
                  />
                  {DD.OTPWarn && (
                    <p className='text-red-500 text-xs font-medium'>
                      OTP You Have Entered Is Either Expired Or Wrong!
                    </p>
                  )}
                  <div className='flex items-center justify-between mt-1'>
                    <div className="flex">
                      <button
                        type='button'
                        className={isResend ? 'font-medium text-sm text-[#28282B] hover:underline' : 'font-medium text-sm text-gray-500 grayscale cursor-not-allowed'}
                        onClick={(e) => { HandleDeleteStoreOtp(e); setIsResend(false) }}
                        disabled={!isResend}
                      >
                        Resend code?
                      </button>
                      <p className='px-2'>
                        {
                          !isResend && new Date() <= new Date(deleteotprequst?.otp_expire_time) &&
                          <Countdown date={new Date(deleteotprequst?.otp_expire_time)} daysInHours={true} onStop={Completionist}>
                            <Completionist />
                          </Countdown>
                        }
                      </p>
                    </div>

                  </div>
                </div>
              }
              {invalidCredentials &&
                <div className='text-xs font-medium text-red-600 mt-2 flex items-center'><AlertCircle className="h-5 w-5 m-1" /><span>Invalid OTP!</span></div>
              }
              <div>
                <div>
                  Shopeasy Store Feedback Form
                  <p className='text-xs table-text font-normal mt-1'>
                    We value your feedback. Please rate your overall experience with Shopeasy Store by considering the following aspects.
                  </p>
                </div>
                <div className='mb-4 w-full mt-2'>
                  <ul className="space-y-2 select-none">
                    <li>
                      <label onClick={(e) => selectReason(e, "reason1", "Technical Problems")} className="cursor-pointer space-x-2 inline-flex items-center">
                        <input type="checkbox" checked={storeReason?.reason1} />
                        <p className="inline-block">Technical Problems</p>
                      </label>
                    </li>
                    <li>
                      <label onClick={(e) => selectReason(e, "reason2", "Found a Better Platform")} className="cursor-pointer space-x-2 inline-flex items-center">
                        <input type="checkbox" checked={storeReason?.reason2} />
                        <p className="inline-block">Found a Better Platform</p>
                      </label>
                    </li>
                    <li>
                      <label onClick={(e) => selectReason(e, "reason3", "Too Expensive")} className="cursor-pointer space-x-2 inline-flex items-center">
                        <input type="checkbox" checked={storeReason?.reason3} />
                        <p className="inline-block">Too Expensive</p>
                      </label>
                    </li>
                    <li>
                      <label onClick={(e) => selectReason(e, "reason4", "Customer Support Experience")} className="cursor-pointer space-x-2 inline-flex items-center">
                        <input type="checkbox" checked={storeReason?.reason4} />
                        <p className="inline-block">Customer Support Experience</p>
                      </label>
                    </li>
                  </ul>
                  <div className="w-full mt-4">
                    <label className='block text-[#484848] text-[13px] font-medium' htmlFor='email'>
                      Other Reason
                    </label>
                    <textarea
                      className='w-full px-3 py-2 border min-h-[100px] border-gray-300 rounded-[6px] focus:outline-none'
                      id='delete_store_reason'
                      name='delete_store_reason'
                      placeholder='Enter your reason'
                      value={storeReason?.other}
                      onChange={(e) => setStoreReason({ ...storeReason, other: e.target.value })}
                    />
                    <p className='text-red-500 font-medium text-xs'>{err}</p>
                  </div>
                </div>
                <div className="flex justify-end mt-3 space-x-4">
                  {!checkbox1 || !checkbox2 || !checkbox3 || !checkbox4 || DD.OTP.length !== 6 || storeReason === undefined ? (
                    <button
                      className='inline-block cursor-not-allowed opacity-50 bg-red-500 text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                      disabled
                      type='button'
                    >
                      Delete Store
                    </button>
                  )
                    :
                    <button
                      className='inline-block bg-red-500 text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                      type='submit'
                      onClick={HandleDeleteStore}
                    >
                      Delete Store
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default DeleteStore;