import { Eye } from 'feather-icons-react';
import { EyeOff } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
function ChangePassword() {
  const [cookie, setCookie, removeCookie] = useCookies(['token']);
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  const [password, setPassword] = useState({
    opassword: '',
    npassword: '',
    cpassword: '',
  });

  const navigate = useNavigate();

  const [err, setErr] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPassword({ ...password, [name]: value });
  };

  const { opassword, npassword, cpassword } = password;
  const [eyeopen, setEyeopen] = useState(false);
  const [neyeopen, setnEyeopen] = useState(false);
  const [ceyeopen, setcEyeopen] = useState(false);
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.npassword !== password.cpassword) {
      setErr(true);
    } else {
      setErr(false);
      setLoading(true)
      try {
        const payload = encryptData({
          old_password: opassword,
          new_password: npassword,
          confirm_password: cpassword,
        })
        await API.post('/admin/user/resetPassword', payload);
        setIsNotification(true)
        setNotificationMsg(`${t("lang_password")} ${t("lang_changed")} ${t("lang_successFully!")}`)
        setSeverity('success')
        setTimeout(() => {
          removeCookie('token');
          navigate('/');
        }, 500);
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity('error')
      }
      setLoading(false)
    }

  };
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  return (
    <>
      {
        isNotification && notificationMsg ? <Notification message={notificationMsg} close={handleClose} severity={severity} /> : <></>
      }
      <div className='flex primary-bg-color h-full pb-[64px] w-full items-center justify-center'>
        <>
          <div className='w-full shadow form sm:w-8/12 xl:w-5/12 my-5 mx-5 secondary-bg-color p-5 sm:p-7 rounded-[6px]'>
            <div className='mb-2 text-center'>
              <span className="text-center block text-base sm:text-2xl font-medium text-primary capitalize">
                {`${t("lang_change")} ${t("lang_your")} ${t("lang_password")}`}
              </span>
              <p className=" text-center text-xs sm:text-[13px] table-text">{t("lang_update_login_credentials")}</p>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='flex flex-col space-y-4' onSubmit={(e) => handleSubmit(e)}>
              <label htmlFor='password'>
                <label className='capitalize'>{`${t("lang_old")} ${t("lang_password")}`}</label>
                <div className='relative'>
                  <input
                    id='opassword'
                    name='opassword'
                    type={eyeopen ? 'text' : 'password'}
                    value={password.opassword}
                    className='w-full py-2 border main-border-color rounded-lg px-3 focus:outline-none'
                    placeholder={`${t("enter")} ${t("lang_old")} ${t("lang_password")}`}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {!eyeopen ? (
                    <Eye
                      className='absolute top-3 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setEyeopen(!eyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className='absolute top-3 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setEyeopen(!eyeopen);
                      }}
                    />
                  )}
                </div>
              </label>
              <label htmlFor='email'>
                <label className='capitalize'>{`${t("lang_new")} ${t("lang_password")}`}</label>
                <div className='relative'>
                  <input
                    id='npassword'
                    name='npassword'
                    type={neyeopen ? 'text' : 'password'}
                    value={password.npassword}
                    className='w-full py-2 border main-border-color rounded-lg px-3 focus:outline-none'
                    placeholder={`${t("lang_enter")} ${t("lang_new")} ${t("lang_password")}`}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {!neyeopen ? (
                    <Eye
                      className='absolute top-3 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setnEyeopen(!neyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className='absolute top-3 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setnEyeopen(!neyeopen);
                      }}
                    />
                  )}
                </div>
              </label>
              <label htmlFor='email'>
                <label className='capitalize'>{`${t("lang_confirm")} ${t("lang_password")}`}</label>
                <div className='relative'>
                  <input
                    id='cpassword'
                    name='cpassword'
                    type={ceyeopen ? 'text' : 'password'}
                    value={password.cpassword}
                    className='w-full py-2 border main-border-color rounded-lg px-3 focus:outline-none'
                    placeholder={`${t("lang_enter")} ${t("lang_confirm")} ${t("lang_password")}`}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {!ceyeopen ? (
                    <Eye
                      className='absolute top-3 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setcEyeopen(!ceyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className='absolute top-3 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setcEyeopen(!ceyeopen);
                      }}
                    />
                  )}
                </div>
                {err ? <p className='text-red-500 text-xs'>{t("lang_password_must_be_same")}</p> : ''}
              </label>
              {
                opassword === '' || npassword === '' || cpassword === '' || password.npassword !== password.cpassword ?
                  <button
                    type='button'
                    className='w-full py-2 text-white opacity-50 cursor-not-allowed rounded-[6px] bg-btn text-[14px]'
                  >
                    <span>{`${t("lang_change")} ${t("lang_password")}`}</span>
                  </button> :
                  loading ?
                    <button type='button'
                      className='w-full cursor-not-allowed py-2 text-white flex items-center justify-center btn text-[14px] space-x-2'>
                      <div class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-yellow-1000 rounded-full">
                      </div>
                      <span>{t("lang_loading")}...</span>
                    </button> :
                    <button
                      type='submit'
                      className='w-full py-2 text-white btn text-[14px] space-x-2'
                    >
                      <span>{`${t("lang_change")} ${t("lang_password")}`}</span>
                    </button>
              }
            </form>
          </div>
        </>
      </div>
    </>
  );
}

export default ChangePassword;
