import React from 'react';
import EditCmsOfferStyleContainer from './EditCmsOfferStyleContainer';

const EditCmsOfferStyleSection = ({ cmsOfferDesign, setCmsOfferDesign, handleUpdateCss }) => {
  return (
    <>
      <EditCmsOfferStyleContainer
        cmsOfferDesign={cmsOfferDesign}
        setCmsOfferDesign={setCmsOfferDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditCmsOfferStyleSection;
