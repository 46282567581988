import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';

import { Autocomplete, Backdrop, CircularProgress, TextField, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import ReactQuill from 'react-quill';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
function AddPCategory() {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [SelectedPortfolioParentCategory, setSelectedPortfolioParentCategory] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [PortfolioCatData, setPortfolioCatData] = useState({
    portfoliocategory_name: '',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
  });
  const [description, setDescription] = useState('');
  const { portfoliocategory_name, meta_tag_title, meta_tag_description, keyword } =
    PortfolioCatData;
  const HandleInput = (e) => {
    setPortfolioCatData({ ...PortfolioCatData, [e.target.name]: e.target.value });
  };

  // get Parents Category
  const [ParentCategory, setParentCategory] = useState([]);
  const GetParentCategory = async () => {
    try {
      const data = await API.get(
        `/admin/PortfolioCategory/GetPortfolioCategoriesWithParent?default=true`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setParentCategory(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugAlready, setSlugAlready] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (portfoliocategory_name?.length > 0) {
      setSlugAlready(true);
    }
    setSlugName(await GenerateSlugName(slug));
  };
  useEffect(() => {
    GetParentCategory();
  }, []);

  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        ...PortfolioCatData,
        description: description,
        portfolio_category_slug_name: slugName,
        ...(SelectedPortfolioParentCategory
          ? { parent_portfoliocategory: SelectedPortfolioParentCategory }
          : ''),
      })
      const data = await API.post('/admin/portfoliocategory/addPortfolioCategory', payload);
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/portfolio-categories',
          title: 'add portfolio category',
        });
        navigate('/portfolio-categories');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [AiLoader, setAiloader] = useState(false);
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setIsNotification(true);
      setNotificationMsg('Please enter description!');
      setSeverity('error');
    } else {
      try {
        setAiloader(true);
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/category_meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setPortfolioCatData({ ...PortfolioCatData, meta_tag_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/category_meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setPortfolioCatData({ ...PortfolioCatData, meta_tag_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        if (error?.response?.status === 429) {
          setIsNotification(true);
          setNotificationMsg(t('lang_Hit_chatgpt_limit'));
          setSeverity('warning');
        } else {
          setIsNotification(true);
          setNotificationMsg(error?.response?.data?.message);
          setSeverity('error');
        }
      } finally {
        setAiloader(false);
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const clientInfo = clientData;
  useEffect(() => {
    if (
      SelectedPortfolioParentCategory?.length > 0 ||
      keyword?.length > 0 ||
      meta_tag_description?.length > 0 ||
      meta_tag_title?.length > 0 ||
      portfoliocategory_name?.length > 0
    ) {
      setSaveChanges({
        backLink: '/portfolio-categories',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add portfolio category',
      });
    } else {
      setSaveChanges({
        backLink: '/portfolio-categories',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add portfolio category',
      });
    }
  }, [
    SelectedPortfolioParentCategory,
    keyword,
    meta_tag_description,
    meta_tag_title,
    portfoliocategory_name,
  ]);
  const checkChanges = () => {
    if (
      SelectedPortfolioParentCategory?.length > 0 ||
      keyword?.length > 0 ||
      meta_tag_description?.length > 0 ||
      meta_tag_title?.length > 0 ||
      portfoliocategory_name?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/portfolio-categories',
        title: 'add portfolio category',
      });
    } else {
      navigate('/portfolio-categories');
    }
  };
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_Please_Wait')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Portfolio_Categories'), path: '/portfolio-categories' },
                {
                  name: t('lang_Add_New_Portfolio_Category'),
                  path: '/portfolio-categories/add-portfolio-category',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Portfolio_Category')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='portfoliocategory_name'>
                  <p className='text-[13px]'>
                    {t('lang_Portfolio_Category_Name')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <input
                  className={
                    err && portfoliocategory_name.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                  }
                  type='text'
                  id='portfoliocategory_name'
                  name='portfoliocategory_name'
                  value={portfoliocategory_name}
                  onChange={HandleInput}
                  placeholder='e.g. Tech'
                  onBlur={() => {
                    handleSlug(portfoliocategory_name);
                    setPortfolioCatData({
                      ...PortfolioCatData,
                      meta_tag_title: portfoliocategory_name,
                    });
                  }}
                />
                {err && portfoliocategory_name?.length === 0 ? (
                  <p className='text-red-500 text-xs'>
                    {t('lang_Please_Enter_Portfolio_Category')}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              {slugAlready && (
                <div className='w-full'>
                  <label htmlFor='category_name'>{t('lang_Portfolio_Category_Slug')}<span className='text-red-500 ms-1'>*</span></label>
                  <div
                    className={`relative flex items-center justify-between w-full border main-border-color rounded-[4px]`}
                  >
                    <input
                      className='w-full focus:outline-none'
                      type='text'
                      name='blog_category_slug_name'
                      placeholder='Enter blog category slug'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => {
                        setSlugName(e.target.value);
                      }}
                    // onBlur={async (e) => setSlugName(await )}
                    />
                    <Tooltip
                      title={`${enableSlug ? t('lang_save') : t('lang_edit')}`}
                      arrow
                      placement='bottom'
                      className='ml-2 -tracking-tighter capitalize'
                    >
                      <button type='button' className='slug-edit w-8 h-8'>
                        {enableSlug ? (
                          <Check
                            onClick={async () => {
                              if (slugName?.length > 0) {
                                setSlugName(await GenerateSlugName(slugName));
                                setEnableSlug(!enableSlug);
                                setErr(false);
                              } else {
                                setIsNotification(true);
                                setNotificationMsg('Slug can not be blank');
                                setSeverity('warning');
                              }
                            }}
                            className='text-green-500 p-1 h-8 w-8'
                          />
                        ) : (
                          <Edit
                            onClick={() => setEnableSlug(!enableSlug)}
                            className='text-black-500 p-1 h-7 w-7'
                          />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                  <p className='w-full text-xs mt-0 text-gray-400'>{`Preview: ${clientInfo?.custom_domain
                    ? clientInfo?.custom_domain
                    : clientInfo?.customer_domain
                    }/portfolio/portfolio-category/${slugName}`}</p>
                  {err && enableSlug && (
                    <p className='text-xsm text-red-500'>{t('lang_save_slug_changes')}</p>
                  )}
                </div>
              )}
              <div className='mb-4'>
                <label htmlFor='description'>{t('lang_Parent_Portfolio_Category')}</label>
                <Autocomplete
                  className='autocomplete'
                  options={ParentCategory?.data?.records ? ParentCategory.data.records : []}
                  filterOptions={filterOptionsForCategory}
                  getOptionLabel={(category) => category.parent_category}
                  onChange={(e, v) =>
                    v
                      ? setSelectedPortfolioParentCategory(v._id)
                      : setSelectedPortfolioParentCategory('')
                  }
                  noOptionsText={
                    ParentCategory?.data?.records?.length > 0 && !autocompleteSearch
                      ? 'Please enter at least 3 characters'
                      : 'No option found'
                  }
                  renderInput={(Categories) => (
                    <TextField
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      placeholder='Select parent category'
                      value={Categories?.inputValue}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                          setAutocompleteSearch(newInputValue);
                        } else {
                          setAutocompleteSearch();
                        }
                      }}
                      className='cursor-pointer'
                      {...Categories}
                    />
                  )}
                />
              </div>
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='description' className='capitalize'>
                    {t('lang_description')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </span>
                </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                  onBlur={() =>
                    setPortfolioCatData({
                      ...PortfolioCatData,
                      meta_tag_description:
                        description
                          ?.replace(/<[^>]*>/g, ' ')
                          ?.split(' ')
                          .slice(0, 50)
                          .join(' ') || '',
                    })
                  }
                />
                <div className='w-full flex justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>{t('lang_Only_Words_allowed', { words: `${description?.split(/\s+/)?.filter(Boolean)?.length || 0}/280` })}</p>
                </div>
              </div>
            </div>
            <details className='secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <summary
                htmlFor='Meta Tag Title'
                className='text-base w-full flex flex-col items-center font-medium '
              >
                <div className='flex w-full items-center justify-between'>
                  <div>
                    <span>{t('lang_search_engine_listing')}</span>
                    <p className='text-xs table-text font-normal'>
                      {t('lang_search_engine_listing_detail')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </div>
                {PortfolioCatData?.meta_tag_title?.length > 0 && (
                  <div className='text-left font-normal mt-2 w-full'>
                    <p className='text-blue-700 text-base'>{PortfolioCatData?.meta_tag_title}</p>
                    <p className='flex text-xs text-green-700'>
                      {`${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                        }/portfolio/portfolio-category/${slugName}`}{' '}
                    </p>
                    <p
                      className='table-text text-sm'
                      dangerouslySetInnerHTML={{ __html: PortfolioCatData?.meta_tag_description }}
                    ></p>
                  </div>
                )}
              </summary>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='Meta Tag Title' className='flex items-center justify-between'>
                    {t('lang_meta_title')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(description, 'title');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_title')}
                  </span>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='Meta Tag Title'
                  name='meta_tag_title'
                  value={meta_tag_title}
                  onChange={HandleInput}
                  placeholder='Enter meta title'
                />
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label
                    htmlFor='Meta Tag description'
                    className='flex items-center justify-between'
                  >
                    {t('lang_meta_description')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(description, 'description');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_description')}
                  </span>
                </div>
                <textarea
                  className='w-full block h-24 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='Meta Tag description'
                  name='meta_tag_description'
                  value={meta_tag_description.replace(/<[^>]+>/g, '')}
                  onChange={HandleInput}
                  placeholder='Enter meta description'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='keyword'>{t('lang_meta_keywords')}</label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='keyword'
                  name='keyword'
                  value={keyword}
                  onChange={HandleInput}
                  placeholder='e.g. Cloth fashion'
                />
              </div>
            </details>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Portfolio_Categories')}
            </Link>
            <div>
              {portfoliocategory_name === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Portfolio_Category')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => HandleSubmit(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Portfolio_Category')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
}

export default AddPCategory;
