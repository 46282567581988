import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Clock,
  Code,
  Edit,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CalendarFill, QuestionCircleFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import UpdateCustomMetaField from '../../controllers/UpdateCustomMetaField';
import Notification from '../Notification';
import ImageGeneratePrompt from '../../controllers/ImageGeneratePrompt';
import ImageConfirmation from '../../controllers/ImageConfirmation';
import GenerateUniqueFilename from '../../controllers/GenerateUniqueFilename';
const UpdateBlog = () => {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const params = useParams();
  const [warn, setwarn] = useState(false);
  const [elem, setElem] = useState();
  // image upload logic
  const [image, setimage] = useState();
  const [scheduleTimeDate, setScheduleTimeDate] = useState();
  const [meta, setMeta] = useState({ meta_description: '', meta_title: '' });
  const { meta_description, meta_title } = meta;
  const [SelectCategory, setSelectCategory] = useState([]);
  const [description, setDescription] = useState();
  const [secondDescription, setSecondDescription] = useState();
  const [title, setTitle] = useState();
  const [err, setErr] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState({ id: '', name: '' });
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const clientInfo = clientData;
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/blog`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }

  // image upload logic
  const handleSetImage = (value) => {
    setIsChange(true);
    setimage(value);
  };
  const [formSpinner, setFormSpinner] = useState(true);
  const [isChangeDate, setIsChangeDate] = useState(false);
  const getPerticularBlog = async () => {
    setFormSpinner(true);
    try {
      const res = await API.get(`/admin/blog/getparticularBlog/${params.blog_slug}`);
      const result = decryptData(res?.data)
      setElem(result?.data);
      setimage(result?.data?.image);
      setSelectCategory(result?.data.blogcategory ? result?.data.blogcategory : '');
      setDefaultCategory({
        id: result?.data?.default_blogcategory?._id,
        name: result?.data?.default_blogcategory?.blogcategory_name,
      });
      setMeta({
        meta_title: result?.data?.meta_title || '',
        meta_description: result?.data?.meta_description || '',
      });
      setSecondDescription(result?.data?.description ? `${result?.data?.description}` : '');
      setTitle(result?.data?.title ? `${result?.data.title}` : '');
      setSlugName(result?.data?.blog_slug_name ? `${result?.data.blog_slug_name}` : '');
      setBlogTagsArray(result?.data?.blog_tags);
      setScheduleTimeDate(
        new Date(result?.data?.blog_publish_date) > new Date()
          ? new Date(result?.data?.blog_publish_date)
          : '',
      );
      setmeta_fields(result?.data?.custom_fields);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setFormSpinner(false);
  };
  useEffect(() => {
    getPerticularBlog();
  }, [params?.blog_slug]);

  // get Parents Category
  const [Category, setCategory] = useState([]);
  const GetCategory = async () => {
    try {
      const data = await API.get(`/admin/blogcategory/GetBlogCategoriesWithParent`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setCategory(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [slugName, setSlugName] = useState();
  useEffect(() => {
    GetCategory();
    getMetaField();
  }, []);

  const [AiLoader, setAiloader] = useState(false);
  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [generateDescriptionAiPopup, setGenareteDescriptionAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    features: '',
  });
  const [imageGenerateAiPopup, setImageGenareteAiPopup] = useState(false);
  const [imageGenerateAiData, setImageGenerateDataAi] = useState({
    prompt: '',
    size: '1024x1024',
  });
  const [isConfirmationImageOpen, setIsConfirmationImageOpen] = useState(false);
  const [imageWarn, setImageWarn] = useState(true);
  const handleChangeAiData = (e) => {
    setIsChange(true);
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };
  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateDesc(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      features: '',
    });
  };
  const generateDesc = async () => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: gerenateAiData?.features,
      })
      let ApiCall = await API.post('/admin/ai/keypoint_generate', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setAiloader(false);
        setDescription(result?.data);
        setGenareteDescriptionAiPopup(true);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
      setAiloader(false);
    }
  };

  const handleChangeImageAiData = (e) => {
    setImageGenerateDataAi({ ...imageGenerateAiData, [e.target.name]: e.target.value });
  };

  const generateImg = async () => {
    setAiloader(true);
    try {
      const payload = encryptData(imageGenerateAiData)
      let ApiCall = await API.post('/admin/ai/image_generator', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = ApiCall?.data
        setimage(result?.data);
        setIsConfirmationImageOpen(true);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setImageGenareteAiPopup(false)
      setAiloader(false);
    }
  };

  const handleSubmitAiImage = (e) => {
    e.preventDefault();
    generateImg(gerenateAiData);
    setImageGenareteAiPopup(false);
    setImageGenerateDataAi({
      prompt: '',
      size: '1024x1024',
    });
  };
  const closeImageConfirmation = () => {
    setIsConfirmationImageOpen(false);
    setimage('');
  }
  const handleImageUpload = async () => {
    setIsConfirmationImageOpen(false);
    try {
      const res = await API.post('/admin/ai/uploadGeneratedImage', { imageUrl: image, folder: "blog" });
      const result = decryptData(res?.data)
      setimage(result?.data)
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }

  const handleBlogCategory = (id, name) => {
    if (id) {
      setIsChange(true);
      const cat = SelectCategory.find((x) => x._id === id);
      if (!cat) {
        setSelectCategory([...SelectCategory, { _id: id, blogcategory_name: name }]);
      }
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleUpdate = async (e, key) => {
    e.preventDefault();
    setBtnLoading(true);
    if (slugName === '') {
      setErr(true);
    } else {
      try {
        let updatebody = {
          blogcategory: SelectCategory?.map((x) => x._id),
          default_blogcategory:
            SelectCategory?.length > 0 && SelectCategory?.length === 1
              ? SelectCategory[0]._id
              : defaultCategory?.id,
          title: title,
          image: image,
          description: secondDescription,
          blog_tags: blogTagsArray,
          ...meta,
          custom_fields: meta_fields,
        };
        if (slugEdited && slugName) {
          updatebody = {
            ...updatebody,
            blog_slug_name: slugName,
          };
        }
        if (key === 'publish') {
          updatebody = {
            ...updatebody,
            is_draft: false,
          };
        }
        if (isChangeDate) {
          updatebody = {
            ...updatebody,
            blog_publish_date: scheduleTimeDate,
          };
        } else {
          updatebody = {
            ...updatebody,
          };
        }
        const payload = encryptData(updatebody)
        const data = await API.put(`/admin/blog/updateBlog/${elem._id}`, payload);
        if (data.status === 200 || data.status === 304) {
          setSaveChanges({
            isUnsaveChanges: false,
            showChangesPopup: false,
            backLink: '/blog-post',
            title: 'edit blog',
          });
          if (key === 'publish') {
            navigate('/blog-post?type=publish');
          } else {
            navigate('/blog-post?type=draft');
          }
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
    setBtnLoading(false);
  };
  const generateMetaData = async (data, key) => {
    if (secondDescription?.length === 0) {
      setIsNotification(true);
      setNotificationMsg('Please enter description!');
      setSeverity('error');
    } else {
      setAiloader(true);
      try {
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/meta_description', payload);
          const result = ApiCall?.data
          setMeta({ ...meta, meta_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/meta_title', payload);
          const result = ApiCall?.data
          setMeta({ ...meta, meta_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        if (error?.response?.status === 429) {
          setIsNotification(true);
          setNotificationMsg(t('lang_Hit_chatgpt_limit'));
          setSeverity('warning');
        } else {
          setIsNotification(true);
          setNotificationMsg(error?.response?.data?.message);
          setSeverity('error');
        }
      } finally {
        setAiloader(false);
      }
    }
  };
  const [blogTagsValue, setBlogTagsValue] = useState([]);
  const [blogTagsArray, setBlogTagsArray] = useState([]);

  // save Group Values In Array
  const SaveblogTagsValue = () => {
    if (blogTagsValue.length > 0) {
      if (blogTagsValue.includes(',')) {
        let TagSeperator = blogTagsValue.split(',');

        let finalArray = [];
        let productTags = [...blogTagsArray, ...TagSeperator];
        productTags.filter((tag) => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
          if (!exists) {
            finalArray.push(tag.trim());
          }
        });
        setBlogTagsArray(finalArray);
      } else {
        const searchRegex = new RegExp(`^${blogTagsValue}$`, 'i');

        const exists = blogTagsArray.some((tag) => searchRegex.test(tag));

        if (!exists) {
          setBlogTagsArray((prev) => [...prev, blogTagsValue]);
        }
      }
      setBlogTagsValue('');
    }
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      blogTagsArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setBlogTagsArray([...blogTagsArray]);
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const removeCategory = (id) => {
    const removeRelated = SelectCategory?.filter((x) => x._id !== id);
    setSelectCategory(removeRelated);
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setSecondDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/blog-post',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit blog',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/blog-post',
        title: 'edit blog',
      });
    } else {
      navigate('/blog-post');
    }
  };
  const filterOptionsForCategory = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()} }`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const [isSchedule, setIsSchedule] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Blog_Post'), path: '/blog-post' },
                { name: t('lang_Update_Blog_Post'), path: '/blog-post/edit-blog-post' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_Blog')} - {elem?.title}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'
          >
            {formSpinner ? (
              <div className='space-y-4'>
                <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div>
                    {t('lang_media')}
                    <p className='text-xs table-text font-normal'>
                      {t('lang_Update_Blog_Details')}
                    </p>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[130px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[130px]' variant='rounded-[6px]' />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='secondary-bg-color group border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='w-full mb-4'>
                    <label htmlFor='title' className='flex items-center justify-between'>
                      <p className='text-[13px]'>
                        {t('lang_Blog_Title')}
                        <span className='text-red-500 ms-1'>*</span>
                      </p>
                    </label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      name='title'
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setwarn(false);
                        setIsChange(true);
                      }}
                      placeholder='e.g. Tech'
                      onBlur={() => setMeta({ ...meta, meta_title: title })}
                    />
                    <div className='w-full flex justify-between'>
                      <p className='text-xs mt-0 text-gray-400'>
                        {t('lang_recommend_character')} {`${title?.length} ${t('lang_characters')}`}
                      </p>
                    </div>

                    {warn && (
                      <p className='text-sm text-red-500'>{t('lang_Please_Enter_Blog_Title')}</p>
                    )}
                  </div>
                  <div className='w-full mb-4'>
                    <label className='flex items-center' htmlFor='blog_slug_name'>
                      {t('lang_Blog_Slug_Name')}<span className='text-red-500 ms-1'>*</span>
                    </label>
                    <div
                      className={`relative flex items-center justify-between w-full border ${err ? 'border-red-500' : 'main-border-color'
                        } rounded-[4px]`}
                    >
                      <input
                        className='w-full  focus:outline-none'
                        type='text'
                        name='blog_slug_name'
                        value={slugName}
                        disabled={!enableSlug}
                        onChange={(e) => {
                          setSlugName(e.target.value);
                          setIsChange(true);
                        }}
                      />
                      <Tooltip
                        title={`${enableSlug ? t('lang_save') : t('lang_edit')}`}
                        arrow
                        placement='bottom'
                        className='ml-2 -tracking-tighter capitalize'
                      >
                        <button type='button' className='slug-edit w-8 h-8'>
                          {enableSlug ? (
                            <Check
                              onClick={async () => {
                                if (slugName?.length > 0) {
                                  setSlugName(await GenerateSlugName(slugName));
                                  setEnableSlug(!enableSlug);
                                  setErr(false);
                                  setSlugEdited(true);
                                } else {
                                  setIsNotification(true);
                                  setNotificationMsg('Slug can not be blank');
                                  setSeverity('warning');
                                }
                              }}
                              className='text-green-500 p-1 h-8 w-8 cursor-pointer'
                            />
                          ) : (
                            <Edit
                              onClick={() => setEnableSlug(!enableSlug)}
                              className='text-black-500 p-1 h-7 w-7 cursor-pointer'
                            />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    {err && (
                      <p className='text-xsm text-red-500'>{t('lang_Please_Add_Slug_Name')}</p>
                    )}
                    <p className='w-full text-xs mt-0 text-gray-400'>{`Preview: ${clientInfo?.custom_domain
                      ? clientInfo?.custom_domain
                      : clientInfo?.customer_domain
                      }/blog/${slugName}`}</p>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='description'>{t('lang_Blog_Category')}</label>
                    <Autocomplete
                      className='autocomplete'
                      options={Category?.data?.records ? Category.data?.records : []}
                      getOptionLabel={(category) => category.parent_category}
                      onChange={(e, v) => (v ? handleBlogCategory(v?._id, v?.categoryName) : '')}
                      filterOptions={filterOptionsForCategory}
                      noOptionsText={
                        Category?.data?.records?.length > 0 && !autocompleteSearch
                          ? 'Please enter at least 3 characters'
                          : 'No option found'
                      }
                      renderInput={(Categories) => (
                        <TextField
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          placeholder='Select blog category'
                          value={Categories?.inputValue}
                          onChange={(e) => {
                            const newInputValue = e.target.value;
                            if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                              setAutocompleteSearch(newInputValue);
                            } else {
                              setAutocompleteSearch();
                            }
                          }}
                          className='cursor-pointer'
                          {...Categories}
                        />
                      )}
                    />
                    {SelectCategory?.map((elem, index) => {
                      return (
                        <span
                          key={index}
                          className='primary-bg-color table-text inline-flex items-center me-2 mt-1 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                        >
                          {elem?.blogcategory_name}
                          <X
                            className='text-red-500 cursor-pointer h-[14px] w-[14px] ms-2'
                            onClick={() => removeCategory(elem?._id)}
                          />
                        </span>
                      );
                    })}
                  </div>
                  {SelectCategory?.length > 1 && (
                    <div>
                      <label>
                        {t('lang_Select_Default_Category')} <span className='text-red-500'>*</span>
                      </label>
                      <Autocomplete
                        className='autocomplete'
                        options={SelectCategory ? SelectCategory : []}
                        getOptionLabel={(category) => category?.blogcategory_name}
                        value={
                          SelectCategory?.find(
                            (x) => x.blogcategory_name === defaultCategory?.name,
                          ) || null
                        }
                        onChange={(e, v) =>
                          setDefaultCategory({ id: v?._id, name: v?.blogcategory_name })
                        }
                        renderInput={(categories) => (
                          <TextField
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            placeholder='Select default category'
                            {...categories}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div className='mb-4'>
                    <div className='flex space-x-3 justify-between items-center mb-1'>
                      <label htmlFor='Description'>{t('lang_Blog_Description')}</label>
                      <div className='flex items-center'>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => {
                            title === ''
                              ? setwarn(true)
                              : (setGenareteAiPopup(true), setwarn(false));
                          }}
                        >
                          <BardSvg /> {t('lang_get_description')}
                        </span>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={handleViewHtml}
                        >
                          <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                        </span>
                      </div>
                    </div>
                    <ReactQuill
                      modules={module}
                      ref={quillRef}
                      className={
                        err && description?.length === 0
                          ? 'border rounded-[6px] border-red-500 w-full h-auto'
                          : 'w-full h-auto'
                      }
                      onBlur={() =>
                        setMeta({
                          ...meta,
                          meta_description:
                            secondDescription
                              ?.replace(/<[^>]*>/g, ' ')
                              ?.split(' ')
                              .slice(0, 50)
                              .join(' ') || '',
                        })
                      }
                      theme='snow'
                      placeholder='Enter blog description'
                      value={secondDescription}
                      onChange={(e) => {
                        setSecondDescription(e);
                        setIsChange(true);
                      }}
                    />
                    {err && secondDescription?.length === 0 && (
                      <p className='text-red-500 text-xs'>
                        {t('lang_Please_Enter_Description_For_Generate_Meta_Data')}
                      </p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='Description'>{t('lang_Blog_Tags')}</label>
                    </div>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='blog_tags'
                      name='blog_tags'
                      value={blogTagsValue}
                      onChange={(e) => {
                        setBlogTagsValue(e.target.value);
                        setIsChange(true);
                      }}
                      onKeyDown={(e) => (e.key === 'Enter' ? SaveblogTagsValue() : '')}
                      placeholder='e.g. Black'
                    />
                    <p className='w-full text-xs mt-0 text-gray-400'>
                      {t('lang_use')} <b className='table-text'>{t('lang_Enter')}</b>{' '}
                      {t('lang_key_to_separate_tags')}
                    </p>
                    <div className='flex mt-1 flex-wrap items-center'>
                      {blogTagsArray &&
                        blogTagsArray.map((elem, index) => {
                          return (
                            <span
                              key={index}
                              className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                            >
                              {elem}
                              <X
                                className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                onClick={() => RemoveTag(index)}
                              />
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <details
                  open
                  className='secondary-bg-color cursor-pointer group border main-border-color rounded-[6px] '
                >
                  <summary className='text-base flex p-5 sm:p-[25px] items-center justify-between font-medium '>
                    <div>
                      <span>
                        {t('lang_media')}
                        <span className='text-red-500 ms-1'>*</span>
                      </span>
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Update_Blog_Details')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='mb-4 sm:mb-[25px] mx-4 main-border-color sm:mx-[25px]' />
                  <div className='px-4 mt-4 sm:px-[25px] pb-4 sm:pb-[25px]'>
                    <label htmlFor='Meta Tag Title' className='flex items-center justify-between'>
                      <p>Image</p>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={() => setImageGenareteAiPopup(true)}
                      >
                        <BardSvg /> Generate Image
                      </span>
                    </label>
                    <ImageUploader
                      alreadyImage={image}
                      handleSetImage={handleSetImage}
                      folder='blog'
                      format='image'
                    />
                  </div>
                </details>
                <details className='secondary-bg-color cursor-pointer group border main-border-color rounded-[6px]'>
                  <summary
                    htmlFor='Meta Tag Title'
                    className='text-base w-full flex flex-col p-5 sm:p-[25px] items-center font-medium '
                  >
                    <div className='flex w-full items-center justify-between'>
                      <div>
                        <span>{t('lang_search_engine_listing')}</span>
                        <p className='text-xs table-text font-normal'>
                          {t('lang_search_engine_listing_detail')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </div>
                    {meta?.meta_title?.length > 0 && (
                      <div className='text-left font-normal mt-2 w-full'>
                        <p className='text-blue-700 text-base'>{meta?.meta_title}</p>
                        <p className='flex text-xs text-green-700'>
                          {`${clientInfo?.custom_domain
                            ? clientInfo?.custom_domain
                            : clientInfo?.customer_domain
                            }/blog/${slugName}`}{' '}
                        </p>
                        <p
                          className='text-gray-800 text-sm'
                          dangerouslySetInnerHTML={{ __html: meta?.meta_description }}
                        ></p>
                      </div>
                    )}
                  </summary>
                  <hr className='mb-4 sm:mb-[25px] mx-4 main-border-color sm:mx-[25px]' />
                  <div className='px-4 mt-4 sm:px-[25px] pb-4 sm:pb-[25px]'>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='Meta Tag Title'
                          className='flex items-center justify-between'
                        >
                          {t('lang_meta_title')}
                        </label>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => {
                            generateMetaData(description, 'title');
                          }}
                        >
                          <BardSvg /> {t('lang_get_meta_title')}
                        </span>
                      </div>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='text'
                        id='Meta Tag Title'
                        name='meta_title'
                        value={meta_title}
                        onChange={(e) => {
                          setMeta({ ...meta, [e.target.name]: e.target.value });
                          setIsChange(true);
                        }}
                        placeholder='Enter meta title'
                      />
                    </div>
                    <div>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='Meta Tag description'
                          className='flex items-center justify-between'
                        >
                          {t('lang_meta_description')}
                        </label>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => {
                            generateMetaData(description, 'description');
                          }}
                        >
                          <BardSvg /> {t('lang_get_meta_description')}
                        </span>
                      </div>
                      <textarea
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='text'
                        id='Meta Tag description'
                        name='meta_description'
                        value={meta_description.replace(/<[^>]+>/g, '')}
                        onChange={(e) => {
                          setMeta({ ...meta, [e.target.name]: e.target.value });
                          setIsChange(true);
                        }}
                        placeholder='e.g. We have best fashionable products'
                      />
                    </div>
                  </div>
                </details>
                {isCustomField && <UpdateCustomMetaField
                  meta_fields={meta_fields}
                  setmeta_fields={setmeta_fields}
                  heading='blog'
                />}
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Blogs')}
            </Link>
            <div className='flex items-center space-x-2'>
              {title === '' ||
                !image?.length ||
                enableSlug ||
                (SelectCategory?.length > 1 && !defaultCategory) ? (
                <div className='flex opacity-50 cursor-not-allowed items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                  <button
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                    disabled
                  >
                    {t('lang_Update_Blog_Post')}
                  </button>
                  {scheduleTimeDate || elem?.is_draft === true ? (
                    <button
                      className='flex cursor-not-allowed bg-btn px-5 py-2 border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                      disabled
                    >
                      <ChevronDown className='w-[18px] h-[18px] stroke-white' />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                  <button
                    className='flex btn rounded-s px-5 py-2.5 text-sm items-center text-white transition duration-300'
                    onClick={(e) =>
                      HandleUpdate(e, elem?.is_draft === true ? 'draft' : 'publish')
                    }
                  >
                    {t('lang_Update_Blog_Post')}
                  </button>
                  {scheduleTimeDate || elem?.is_draft === true ? (
                    <button
                      type='button'
                      className='flex btn border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                      onClick={handleClick}
                    >
                      <ChevronDown className='w-[18px] h-[18px] stroke-white' />
                    </button>
                  ) : (
                    <></>
                  )}
                  <div>
                    <Menu
                      id='basic-menu'
                      anchorEl={menuAnchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={'aria-labelledby'}
                      className='text-sm'
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {scheduleTimeDate && (
                        <MenuItem
                          className='text-sm block'
                          onClick={(e) => {
                            setIsSchedule(true);
                            handleCloseMenu();
                          }}
                        >
                          <Clock className='w-4 h-4' />
                          <span className='ms-2 text-sm'>
                            {t('lang_Update_Publish_Schedule')}
                          </span>
                        </MenuItem>
                      )}
                      {elem?.is_draft === true && (
                        <MenuItem
                          className='text-sm block'
                          onClick={(e) => HandleUpdate(e, 'publish')}
                        >
                          <svg
                            fill='currentcolor'
                            version='1.1'
                            id='Capa_1'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            width='18px'
                            height='18px'
                            viewBox='0 0 31.854 31.854'
                            xmlSpace='preserve'
                          >
                            <g>
                              <path
                                d='M15.94,11.735c0,0.588-0.476,1.064-1.065,1.064H5.298c-0.588,0-1.065-0.476-1.065-1.064c0-0.587,0.477-1.063,1.065-1.063
          h9.577C15.465,10.672,15.94,11.148,15.94,11.735z M21.52,11.087l5.342,4.791L15.535,28.505l-5.34-4.791L21.52,11.087z
           M20.008,16.188l-0.033-0.029c-0.127-0.114-0.293-0.172-0.461-0.163c-0.168,0.009-0.328,0.085-0.439,0.211l-6.079,6.776
          c-0.253,0.281-0.229,0.713,0.052,0.965c0.126,0.115,0.291,0.174,0.461,0.164c0.168-0.01,0.327-0.086,0.44-0.213l6.108-6.81
          C20.293,16.828,20.27,16.423,20.008,16.188z M21.27,13.751l-0.301,0.336c-0.111,0.126-0.17,0.292-0.162,0.461
          c0.01,0.169,0.086,0.328,0.211,0.441l0.035,0.031c0.127,0.113,0.293,0.171,0.461,0.162c0.17-0.009,0.328-0.085,0.44-0.211
          l0.728-0.811l-0.51-0.457C21.91,13.467,21.506,13.489,21.27,13.751z M22.035,10.51l5.341,4.791l1.312-1.462l-5.34-4.791
          L22.035,10.51z M31.047,11.21l-1.877,2.093l-5.341-4.79l1.88-2.093c0.247-0.28,0.6-0.448,0.975-0.468
          c0.375-0.021,0.742,0.109,1.021,0.359l3.234,2.903C31.52,9.735,31.569,10.629,31.047,11.21z M29.996,10.264l-3.233-2.902
          l-0.937,1.042l3.233,2.903L29.996,10.264z M9.557,24.428l5.339,4.791l-6.31,2.586c-0.243,0.098-0.521,0.051-0.716-0.125
          c-0.196-0.177-0.273-0.447-0.201-0.699L9.557,24.428z M10.045,26.13l-0.722,2.507l1.411,1.268l2.412-0.986L10.045,26.13z
           M8.215,24.285l-0.623,2.162H2.859c-1.332,0-2.413-1.08-2.413-2.412V6.493c0-1.332,1.08-2.413,2.413-2.413h2.94V3.906
          c0-0.492,0.399-0.89,0.89-0.89h0.74C7.414,2.909,7.406,2.781,7.406,2.661C7.406,1.194,8.599,0,10.067,0
          c1.469,0,2.661,1.194,2.661,2.661c0,0.12-0.01,0.248-0.023,0.354h0.749c0.492,0,0.89,0.399,0.89,0.89v0.174h2.87
          c1.332,0,2.412,1.081,2.412,2.413v4.63l-2.128,2.372V7.506c0-0.699-0.565-1.265-1.264-1.265h-1.891v1.201
          c0,0.491-0.399,0.89-0.89,0.89H6.69c-0.492,0-0.89-0.399-0.89-0.89V6.241H3.874c-0.699,0-1.265,0.567-1.265,1.265V23.02
          c0,0.701,0.567,1.266,1.265,1.266H8.215z M9.003,2.661c0,0.124,0.023,0.248,0.061,0.355h2.005c0.04-0.107,0.062-0.23,0.062-0.355
          c0-0.587-0.477-1.065-1.064-1.065C9.479,1.596,9.003,2.074,9.003,2.661z M14.949,16.341l0.991-1.105
          c-0.014-0.576-0.484-1.054-1.064-1.054H5.298c-0.588,0-1.065,0.493-1.065,1.082c0,0.587,0.477,1.082,1.065,1.082h9.577
          C14.9,16.344,14.925,16.342,14.949,16.341z M4.233,18.791c0,0.588,0.477,1.062,1.065,1.062H11.8l1.907-2.127H5.298
          C4.71,17.727,4.233,18.203,4.233,18.791z'
                              />
                            </g>
                          </svg>
                          <span className='ms-2 text-sm'>{t('lang_Publish_Blog_Post')}</span>
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[350px] md:w-[500px] max-h-full'>
          <div className='relative secondary-bg-color table-text shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='flex items-center justify-between capitalize  text-primary font-medium gap-1'>
                <BardSvg /> {t('lang_Generate_Blog_Description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[6px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>
              <button type='submit' className='btn px-5 py-2 text-white rounded-[6px]'>
                {t('lang_Done')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={generateDescriptionAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteDescriptionAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[350px] md:w-[500px] max-h-full'>
          <div className='relative secondary-bg-color table-text shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium  text-primary'>
                {t('lang_Generate_Blog_Description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteDescriptionAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <ReactQuill
                  modules={module}
                  className={
                    err && description?.length === 0
                      ? 'border rounded-[6px] border-red-500 w-full h-auto'
                      : 'w-full h-auto'
                  }
                  theme='snow'
                  placeholder='Enter blog description'
                  value={description}
                  onChange={setDescription}
                  onBlur={() => {
                    setMeta({
                      ...meta,
                      meta_description:
                        description
                          ?.replace(/<[^>]*>/g, ' ')
                          ?.split(' ')
                          .slice(0, 50)
                          .join(' ') || '',
                    });
                  }}
                />
              </div>
              <div className='flex justify-between'>
                <button
                  type='button'
                  className='btn px-5 py-2 text-white rounded-[6px]'
                  onClick={() => {
                    setSecondDescription(description);
                    setGenareteDescriptionAiPopup(false);
                  }}
                >
                  {t('lang_Done')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={isSchedule}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsSchedule(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-full md:w-[35rem] p-5 rounded-[6px] secondary-bg-color shadow dark:bg-gray-700'>
          <label
            className='flex items-center justify-between  text-primary text-md font-medium mb-2'
            htmlFor='product_name'
          >
            {t('lang_Update_Publish_Schedule')}
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsSchedule(false);
              }}
              className='close-btn'
            >
              <X className='w-4 h-4' />
            </button>
          </label>
          <hr className='my-4 main-border-color' />
          <div className='w-full h-[332px]'>
            <div className='relative w-full block cursor-pointer'>
              <DatePicker
                className={`${scheduleTimeDate&& "bold-date"} border main-border-color focus:outline-none focus:border-gray-400 w-full`}
                showTimeSelect
                open
                placeholderText='Select product launch date'
                minDate={new Date()}
                minTime={new Date(0, 0, 0, 0)}
                maxTime={new Date(0, 0, 0, 23, 30)}
                selected={scheduleTimeDate}
                onChange={(date) => {
                  setScheduleTimeDate(date);
                  setIsChangeDate(true);
                }}
                dateFormat='MMMM d, yyyy h:mm aa'
              />
              <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                <CalendarFill className='text-gray-600 w-3 h-3' />
              </span>
            </div>
          </div>
          <button
            onClick={(e) => HandleUpdate(e, 'publish', scheduleTimeDate)}
            className='btn text-sm text-white'
          >
            {t('lang_Update_Publish_Schedule')}
          </button>
        </div>
      </Dialog>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
      {imageGenerateAiPopup && (
        <ImageGeneratePrompt
          imageGenerateAiPopup={imageGenerateAiPopup}
          setImageGenareteAiPopup={setImageGenareteAiPopup}
          imageGenerateAiData={imageGenerateAiData}
          imageWarn={imageWarn}
          setImageWarn={setImageWarn}
          handleChangeImageAiData={handleChangeImageAiData}
          handleSubmitAiImage={handleSubmitAiImage}
        />
      )}
      {isConfirmationImageOpen && (
        <ImageConfirmation
          heading='Image Confirmation'
          subheading='Do you want to keep this image in blog?'
          image={image}
          isConfirmationImageOpen={isConfirmationImageOpen}
          closeImageConfirmation={closeImageConfirmation}
          runFunction={handleImageUpload}
        />
      )}
    </>
  );
};

export default UpdateBlog;
