import { Autocomplete, Backdrop, CircularProgress, TextField, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
const AddBlogCategory = () => {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [SelectedBlogParentCategory, setSelectedBlogParentCategory] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [BlogCatData, setBlogCatData] = useState({
    blogcategory_name: '',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
  });
  const [description, setDescription] = useState('');
  const { blogcategory_name, meta_tag_title, meta_tag_description, keyword } = BlogCatData;
  let name, value;
  const HandleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setBlogCatData({ ...BlogCatData, [name]: value });
  };

  // get Parents Category
  const [ParentCategory, setParentCategory] = useState([]);
  const GetParentCategory = async () => {
    try {
      const data = await API.get(`/admin/blogcategory/GetBlogCategoriesWithParent?default=true`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setParentCategory(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetParentCategory();
  }, []);
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugAlready, setSlugAlready] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (blogcategory_name?.length > 0) {
      setSlugAlready(true);
    }
    setSlugName(await GenerateSlugName(slug));
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        ...BlogCatData,
        description: description,
        blog_category_slug_name: slugName,
        ...(SelectedBlogParentCategory ? { parent_blogcategory: SelectedBlogParentCategory } : ''),
      })
      const data = await API.post('/admin/blogcategory/addBlogCategory', payload);
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/blog-categories',
          title: 'add blog category',
        });
        navigate('/blog-categories');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('success');
    }
    setBtnLoading(false);
  };
  const [AiLoader, setAiloader] = useState(false);
  const [desErr, setDesErr] = useState(false);

  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setDesErr(true);
    } else {
      setDesErr(false);
      try {
        setAiloader(true);
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/category_meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setBlogCatData({ ...BlogCatData, meta_tag_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/category_meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setBlogCatData({ ...BlogCatData, meta_tag_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        if (error?.response?.status === 429) {
          setIsNotification(true);
          setNotificationMsg(t('lang_Hit_chatgpt_limit'));
          setSeverity('warning');
        } else {
          setIsNotification(true);
          setNotificationMsg(error?.response?.data?.message);
          setSeverity('error');
        }
      } finally {
        setAiloader(false);
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const clientInfo = clientData;
  useEffect(() => {
    if (
      keyword?.length > 0 ||
      meta_tag_description?.length > 0 ||
      meta_tag_title?.length > 0 ||
      blogcategory_name?.length > 0 ||
      description?.length > 0 ||
      SelectedBlogParentCategory?.length > 0
    ) {
      setSaveChanges({
        backLink: '/blog-categories',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add blog category',
      });
    } else {
      setSaveChanges({
        backLink: '/blog-categories',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add blog category',
      });
    }
  }, [
    keyword,
    meta_tag_description,
    meta_tag_title,
    blogcategory_name,
    description,
    SelectedBlogParentCategory,
  ]);
  const checkChanges = () => {
    if (
      keyword?.length > 0 ||
      meta_tag_description?.length > 0 ||
      meta_tag_title?.length > 0 ||
      blogcategory_name?.length > 0 ||
      description?.length > 0 ||
      SelectedBlogParentCategory?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/blog-categories',
        title: 'add blog category',
      });
    } else {
      navigate('/blog-categories');
    }
  };
  const filterOptionsForCategory = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()} }`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const [err, setErr] = useState(false);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Blog_Categories'), path: '/blog-categories' },
                { name: t('lang_Add_Blog_Category'), path: '/blog-categories/add-blog-category' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Blog_Category')}
              <a target='blank' href='https://docs.shopeasy.ai/blog' className='how-it-works'>
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='blogcategory_name'>
                  <p className='text-[13px]'>
                    {t('lang_Blog_Category_Name')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  type='text'
                  id='blogcategory_name'
                  name='blogcategory_name'
                  value={blogcategory_name}
                  onChange={HandleInput}
                  placeholder='e.g. Tech'
                  onBlur={(e) => {
                    handleSlug(blogcategory_name);
                    setBlogCatData({ ...BlogCatData, meta_tag_title: e.target.value });
                  }}
                />
              </div>
              {slugAlready && (
                <div className='w-full'>
                  <label htmlFor='category_name'>{t('lang_Blog_Category_Slug')}<span className='text-red-500 ms-1'>*</span></label>
                  <div className={`relative flex items-center justify-between w-full border rounded-[4px]`}>
                    <input
                      className='w-full focus:outline-none'
                      type='text'
                      name='blog_category_slug_name'
                      placeholder='Enter blog category slug'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => {
                        setSlugName(e.target.value);
                      }}
                    />
                    <Tooltip
                      title={`${enableSlug ? t('lang_save') : t('lang_edit')}`}
                      arrow
                      placement='bottom'
                      className='ml-2 -tracking-tighter capitalize'
                    >
                      <button type='button' className='slug-edit w-8 h-8'>
                        {enableSlug ? (
                          <Check
                            onClick={async (e) => {
                              if (slugName?.length > 0) {
                                e.preventDefault();
                                setSlugName(await GenerateSlugName(slugName));
                                setEnableSlug(!enableSlug);
                                setErr(false);
                              } else {
                                setIsNotification(true);
                                setNotificationMsg('Slug can not be blank');
                                setSeverity('warning');
                              }
                            }}
                            className='text-green-500 p-1 h-8 w-8'
                          />
                        ) : (
                          <Edit
                            onClick={(e) => {
                              e.preventDefault();
                              setEnableSlug(!enableSlug);
                            }}
                            className='text-black-500 p-1 h-7 w-7'
                          />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                  <p className='w-full text-xs mt-0 text-gray-400'>{`Preview: ${clientInfo?.custom_domain
                    ? clientInfo?.custom_domain
                    : clientInfo?.customer_domain
                    }/blog/blog-category/${slugName}`}</p>
                  {err && enableSlug && (
                    <p className='text-xsm text-red-500'>{t('lang_save_slug_changes')}</p>
                  )}
                </div>
              )}
              <div className='max-w-full mb-4'>
                <label htmlFor='blog Category'>{t('lang_Parent_Blog_Category')}</label>
                <Autocomplete
                  className='autocomplete'
                  options={ParentCategory?.data?.records ? ParentCategory.data.records : []}
                  getOptionLabel={(parent) => parent.parent_category}
                  onChange={(e, v) => {
                    v ? setSelectedBlogParentCategory(v._id) : setSelectedBlogParentCategory('');
                  }}
                  filterOptions={filterOptionsForCategory}
                  noOptionsText={
                    ParentCategory?.data?.records?.length > 0 && !autocompleteSearch
                      ? 'Please enter at least 3 characters'
                      : 'No option found'
                  }
                  renderInput={(blogsCategory) => (
                    <TextField
                      placeholder='Select parent blog category'
                      value={blogsCategory?.inputValue}
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                          setAutocompleteSearch(newInputValue);
                        } else {
                          setAutocompleteSearch();
                        }
                      }}
                      className='cursor-pointer'
                      {...blogsCategory}
                    />
                  )}
                />
              </div>
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='description'>{t('lang_Blog_Category_Description')}</label>
                  <section
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </section>
                </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                  onBlur={() =>
                    setBlogCatData({
                      ...BlogCatData,
                      meta_tag_description:
                        description
                          ?.replace(/<[^>]*>/g, ' ')
                          ?.split(' ')
                          .slice(0, 50)
                          .join(' ') || '',
                    })
                  }
                />
                <div className='w-full flex justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>{t('lang_Only_Words_allowed', { words: `${description?.split(/\s+/)?.filter(Boolean)?.length || 0}/280` })}</p>
                </div>
              </div>
            </div>
            <details className='secondary-bg-color cursor-pointer group shadow rounded-[6px]'>
              <summary className='text-base flex flex-col p-5 sm:p-[25px] items-center font-medium '>
                <div className='flex items-center justify-between w-full'>
                  <div>
                    {t('lang_search_engine_listing')}
                    <p className='text-xs table-text font-normal'>
                      {t('lang_search_engine_listing_detail')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </div>
                {meta_tag_title?.length > 0 && (
                  <div className='text-left font-normal mt-2 w-full'>
                    <p className='text-blue-700 text-base'>{meta_tag_title}</p>
                    <p className='flex text-xs text-green-700'>
                      {`${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                        }/blog/blog-category/${slugName}`}{' '}
                    </p>
                    <p
                      className='table-text text-sm'
                      dangerouslySetInnerHTML={{ __html: meta_tag_description }}
                    ></p>
                  </div>
                )}
              </summary>
              <hr className='mb-4 mx-4 main-border-color sm:mx-[25px]' />
              <div className='mt-4 sm:mt-[25px]'>
                <div className='mb-4'>
                  <div className='flex items-center justify-between'>
                    <label htmlFor='Meta Tag Title' className='flex items-center justify-between'>
                      {t('lang_meta_title')}
                    </label>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={() => {
                        generateMetaData(description, 'title');
                      }}
                    >
                      <BardSvg /> {t('lang_get_meta_title')}
                    </span>
                  </div>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                    type='text'
                    id='Meta Tag Title'
                    name='meta_tag_title'
                    value={meta_tag_title}
                    onChange={HandleInput}
                    placeholder='Enter meta title'
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex items-center justify-between'>
                    <label
                      htmlFor='Meta Tag description'
                      className='flex items-center justify-between'
                    >
                      {t('lang_meta_description')}
                    </label>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={() => {
                        generateMetaData(description, 'description');
                      }}
                    >
                      <BardSvg />
                      {t('lang_get_meta_description')}
                    </span>
                  </div>
                  <textarea
                    className='w-full h-24 block px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                    type='text'
                    id='Meta Tag description'
                    name='meta_tag_description'
                    value={meta_tag_description.replace(/<[^>]+>/g, '')}
                    onChange={HandleInput}
                    placeholder='Enter meta description'
                  ></textarea>
                </div>
                <div>
                  <label htmlFor='keyword'>{t('lang_meta_keywords')}</label>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                    type='text'
                    id='keyword'
                    name='keyword'
                    value={keyword}
                    onChange={HandleInput}
                    placeholder='e.g. Cloth fashion'
                  />
                </div>
              </div>
            </details>

          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Blog_Categories')}
            </Link>
            <div>
              {blogcategory_name === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_New_Blog_Category')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => HandleSubmit(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_New_Blog_Category')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}

    </>
  );
};

export default AddBlogCategory;
