import { Menu, MenuItem, Tooltip } from '@mui/material';
import { ChevronDown, Link } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import ColorPicker from '../../../Color/ColorPicker';

const EditProductDesctiptionStyleSection = ({
  productDescriptionDesign,
  setProductDescriptionDesign,
  handleUpdateCss,
  visualData,
}) => {
  const [openState, setOpenState] = useState('');
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMarginChange = (value) => {
    if (productDescriptionDesign?.margin_all === 'block') {
      setProductDescriptionDesign({
        ...productDescriptionDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
    } else {
      setProductDescriptionDesign((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  };

  const handlePaddingChange = (value) => {
    if (productDescriptionDesign?.padding_all === 'block') {
      setProductDescriptionDesign({
        ...productDescriptionDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
    } else {
      setProductDescriptionDesign((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  };

  const handleColorChange = (value) => {
    if (openState === 'font_color') {
      setProductDescriptionDesign({ ...productDescriptionDesign, font_color: value });
    } else if (openState === 'border_color') {
      setProductDescriptionDesign({ ...productDescriptionDesign, border_color: value });
    }
  };

  const handleCloseColor = () => {
    setOpenState('');
  };

  const gridColsClass =
    visualData?.manufacture?.section?.carousel_status !== false ? 'grid-cols-2' : 'grid-cols-3';

  return (
    <div className='relative'>

      <div className='editor-details py-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_spacing')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='2xl:flex justify-between mt-2 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_margin')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.margin_top}
                  onChange={(e) =>
                    handleMarginChange(
                      productDescriptionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.margin_right}
                  onChange={(e) =>
                    handleMarginChange(
                      productDescriptionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.margin_bottom}
                  onChange={(e) =>
                    handleMarginChange(
                      productDescriptionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.margin_left}
                  onChange={(e) =>
                    handleMarginChange(
                      productDescriptionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    productDescriptionDesign?.margin_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setProductDescriptionDesign((prevState) => ({
                      ...prevState,
                      margin_all: prevState.margin_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
          <div className='2xl:flex justify-between mt-3 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_padding')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.padding_top}
                  onChange={(e) =>
                    handlePaddingChange(
                      productDescriptionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.padding_right}
                  onChange={(e) =>
                    handlePaddingChange(
                      productDescriptionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.padding_bottom}
                  onChange={(e) =>
                    handlePaddingChange(
                      productDescriptionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.padding_left}
                  onChange={(e) =>
                    handlePaddingChange(
                      productDescriptionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    productDescriptionDesign?.padding_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setProductDescriptionDesign((prevState) => ({
                      ...prevState,
                      padding_all: prevState.padding_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_typography')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text- w-[28%] table-text editor-title'>
                {t('lang_weight')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={productDescriptionDesign?.font_weight}
                  onChange={(e) =>
                    setProductDescriptionDesign({ ...productDescriptionDesign, font_weight: e.target.value })
                  }
                >
                  <option value={100}>100-{t('lang_thin')}</option>
                  <option value={200}>200-{t('lang_extra_light')}</option>
                  <option value={300}>300-{t('lang_light')}</option>
                  <option value={400}>400-{t('lang_Normal')}</option>
                  <option value={500}>500-{t('lang_Medium')}</option>
                  <option value={600}>600-{t('lang_semi_bold')}</option>
                  <option value={700}>700-{t('lang_bold')}</option>
                  <option value={800}>800-{t('lang_extra_bold')}</option>
                  <option value={900}>900-{t('lang_black')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>

            <div >
              <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
              {t('lang_height')}
              </p>
              <div className='relative w-full'>
              <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                  value={productDescriptionDesign?.font_height}
                  onChange={(e) =>
                    setProductDescriptionDesign({ ...productDescriptionDesign, font_height: e.target.value })
                  }
                />
              </div>
            </div>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('font_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: productDescriptionDesign?.font_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {productDescriptionDesign?.font_color}
                  </span>
                </div>
                {openState === 'font_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={productDescriptionDesign?.font_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_align')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Left' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_align === 'left'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_align: 'left' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-left'
                      >
                        <line x1='17' y1='10' x2='3' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='17' y1='18' x2='3' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Center' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_align === 'center'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_align: 'center' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-center'
                      >
                        <line x1='18' y1='10' x2='6' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='18' y1='18' x2='6' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Right' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_align === 'right'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_align: 'right' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-right'
                      >
                        <line x1='21' y1='10' x2='7' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='21' y1='18' x2='7' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Justify' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_align === 'justify'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_align: 'justify' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-justify'
                      >
                        <line x1='21' y1='10' x2='3' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='21' y1='18' x2='3' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid gap-2 grid-cols-2 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Normal' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_style === 'normal'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_style: 'normal' })
                      }
                    >
                      I
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Italic' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_style === 'italic'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_style: 'italic' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-italic'
                      >
                        <line x1='19' y1='4' x2='10' y2='4'></line>
                        <line x1='14' y1='20' x2='5' y2='20'></line>
                        <line x1='15' y1='4' x2='9' y2='20'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_decoration')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='None' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_decoration === 'none'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_decoration: 'none' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-slash'
                      >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='line-through' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_decoration === 'line-through'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({
                          ...productDescriptionDesign,
                          font_decoration: 'line-through',
                        })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-type-strikethrough'
                        viewBox='0 0 16 16'
                      >
                        <path d='M6.333 5.686c0 .31.083.581.27.814H5.166a2.8 2.8 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Underline' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_decoration === 'underline'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({
                          ...productDescriptionDesign,
                          font_decoration: 'underline',
                        })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-underline'
                      >
                        <path d='M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3'></path>
                        <line x1='4' y1='21' x2='20' y2='21'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Overline' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_decoration === 'overline'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_decoration: 'overline' })
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-align-top'
                        viewBox='0 0 16 16'
                      >
                        <rect width='4' height='12' rx='1' transform='matrix(1 0 0 -1 6 15)' />
                        <path d='M1.5 2a.5.5 0 0 1 0-1zm13-1a.5.5 0 0 1 0 1zm-13 0h13v1h-13z' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_Capitalize')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='None' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_capitalize === 'none'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_capitalize: 'none' })
                      }
                    >
                      X
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Uppercase' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_capitalize === 'uppercase'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({
                          ...productDescriptionDesign,
                          font_capitalize: 'uppercase',
                        })
                      }
                    >
                      AA
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Capitalize' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_capitalize === 'capitalize'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({
                          ...productDescriptionDesign,
                          font_capitalize: 'capitalize',
                        })
                      }
                    >
                      Aa
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Lowercase' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        productDescriptionDesign?.font_capitalize === 'lowercase'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() =>
                        setProductDescriptionDesign({ ...productDescriptionDesign, font_capitalize: 'lowercase' })
                      }
                    >
                      aa
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_border')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
                {t('lang_width')}
              </p>
              <div className='relative w-full'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-left'
                  value={productDescriptionDesign?.border_width}
                  onChange={(e) =>
                    setProductDescriptionDesign({ ...productDescriptionDesign, border_width: e.target.value })
                  }
                />
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center capitalize ${
                      productDescriptionDesign?.border_style === 'none'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductDescriptionDesign({ ...productDescriptionDesign, border_style: 'none' })
                    }
                  >
                    {t('lang_none')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productDescriptionDesign?.border_style === 'solid'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductDescriptionDesign({ ...productDescriptionDesign, border_style: 'solid' })
                    }
                  >
                    {t('lang_solid')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productDescriptionDesign?.border_style === 'dashed'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductDescriptionDesign({ ...productDescriptionDesign, border_style: 'dashed' })
                    }
                  >
                    {t('lang_dashed')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productDescriptionDesign?.border_style === 'dotted'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductDescriptionDesign({ ...productDescriptionDesign, border_style: 'dotted' })
                    }
                  >
                    {t('lang_dotted')}
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('border_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: productDescriptionDesign?.border_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {productDescriptionDesign?.border_color}
                  </span>
                </div>
                {openState === 'border_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={productDescriptionDesign?.border_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          // onClick={() => handleUpdateCss('manufacture')}
          onClick={handleUpdateCss}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductDesctiptionStyleSection;
