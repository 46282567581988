import { Checkbox } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const AllCmsOffer = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { setPlanPermission } = useContext(PlanPermission);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [groupSliderData, setGroupSliderData] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const getSliderdata = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(`admin/cmsoffer/getCmsOffers`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setGroupSliderData(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'offer' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getSliderdata();
  }, []);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`/admin/cmsoffer/deleteCmsOffer`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed SuccesFully!');
        setSeverity('success');
        getSliderdata();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = groupSliderData?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = groupSliderData?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = groupSliderData?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(groupSliderData?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Cms Offer Banner'
        subheading='Do you really want to delete this cms offer banner from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex flex-wrap items-center justify-between w-full overflow-auto'>
          <div className='flex items-center space-x-2 mb-4'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/module-setting');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Module_Setting'), path: '/module-setting' },
                  { name: t('lang_Cms_Offer'), path: '/module-setting/cms-offer' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Cms_Offer')}
              </h4>
            </div>
          </div>
          <div className='flex items-center mb-4'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => {
                navigate('/module-setting/cms-offer/add-cms-offer');
              }}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              {t('lang_Add_Cms_Offer')}
            </button>
          </div>
        </div>
        <div className='w-full space-y-1.5'>
          <div className='flex items-center'>
            {selectedRowsId && selectedRowsId?.length > 0 && (
              <>
                <span className='text-sm px-3'>
                  {selectedRowsId?.length} {t('lang_selected')}
                </span>
                |
                <button
                  className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmationPopup(true);
                  }}
                >
                  <Trash2 className='w-3.5 h-3.5' />
                  <span>{t('lang_delete_selection')}</span>
                </button>
              </>
            )}
          </div>
          {dataLoading && !groupSliderData ? (
            <TableSkeleton />
          ) : (
            groupSliderData?.length > 0 ?
              <div className='w-full inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='px-3 table-head-checkbox'>
                        <Checkbox
                          checked={selectedRowsId?.length === groupSliderData?.length}
                          indeterminate={
                            selectedRowsId?.length !== groupSliderData?.length &&
                            selectedRowsId?.length > 0
                          }
                          onChange={(e) => {
                            handleSelectRow(e, 'all');
                          }}
                        />
                      </td>
                      <td className='px-3 w-full lowercase'>{t('lang_group_name')}</td>
                      <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {groupSliderData?.map((row, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                            }`}
                          onClick={() => navigate(`/module-setting/cms-offer/${row?._id}`)}
                        >
                          <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                              checked={
                                selectedRowsId?.length > 0 &&
                                selectedRowsId?.find((x) => x === row?._id)
                              }
                              onChange={(e) => handleSelectRow(e, row?._id)}
                            />
                          </td>
                          <td className='px-3 w-full'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{ __html: row.cms_offer_title || '-' }}
                            ></span>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                            <div className='space-x-2 w-full text-right'>
                              <button
                                onClick={() => navigate(`/module-setting/cms-offer/${row?._id}`)}
                              >
                                <Edit className='w-[17px] h-[17px] text-black-500' />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConfirmationPopup(true);
                                  setSelectedRowsId([row?._id]);
                                }}
                              >
                                <Trash className='w-[17px] h-[17px] text-red-500' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              : <NoRecords />
          )}
        </div>
      </div>
    </>
  );
};

export default AllCmsOffer;
