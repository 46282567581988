import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';

const StaticThirdHeroSection = ({
  section_id,
  section_type,
  saveElementorData,
  btnLoading,
  sectionContent,
  firstBtnContent,
  descriptionContent,
  handleThirdHeroTitleValueChange,
  handleThirdHeroTagChange,
  headingLevel,
  handleThirdHeroDescriptionChange,
  handleThirdHeroFirstBtnValueChange,
  secondBtnContent,
  handleThirdHeroSecondBtnValueChange,
  thirdHeroValue,
  setThirdHeroValue,
  handleThirdHeroValue,
  handleSettingImageUpload,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [firstBtnValue, setFirstBtnValue] = useState(firstBtnContent);
  const [secondBtnValue, setSecondBtnValue] = useState(secondBtnContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');

  const ShortquillRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setGroupTitle(sectionContent);
    setFirstBtnValue(firstBtnContent);
    setSecondBtnValue(secondBtnContent);
    setShortDescription(descriptionContent);
  }, [sectionContent, firstBtnContent, secondBtnContent, descriptionContent]);

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleThirdHeroTitleValueChange(section_id, newValue);
  };

  const firstBtnContentChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnValue(newValue);
    handleThirdHeroFirstBtnValueChange(section_id, newValue);
  };

  const secondBtnContentChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnValue(newValue);
    handleThirdHeroSecondBtnValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleThirdHeroTitleValueChange(section_id, value);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleThirdHeroTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleThirdHeroDescriptionChange(section_id, value);
  };

  const handleImageUpload = async (event) => {
    try {
      const result = await handleSettingImageUpload(event);
      const updatedEightContentValue = {
        ...thirdHeroValue,
        image: result,
      };
      setThirdHeroValue(updatedEightContentValue);
      handleThirdHeroValue(section_id, updatedEightContentValue);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    const updatedEightContentValue = {
      ...thirdHeroValue,
      objectFit: backgroundFit,
    };

    setThirdHeroValue(updatedEightContentValue);
    handleThirdHeroValue(section_id, updatedEightContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>
      <div className='w-full mb-4'>
        <label>First Button</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnValue}
          onChange={firstBtnContentChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>Second Button</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnValue}
          onChange={secondBtnContentChange}
        />
      </div>

      <div className='w-full mb-4'>
        <label>Image</label>
        <img
          src={thirdHeroValue?.image}
          alt='Uploaded Preview'
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div
          style={{
            position: 'relative',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            display: 'block',
            marginBottom: '10px',
            maxWidth: '100%',
            padding: '5px',
          }}
        >
          <input
            type='file'
            accept='image/*'
            onChange={(e) => handleImageUpload(e)}
            style={{ width: '100%' }}
          />
        </div>
      </div>

      <div className='flex items-center mt-2'>
        <label htmlFor='sub_title' className='text-[#242323] w-[45%]'>
          {t('lang_fit')}
        </label>
        <div className='relative w-[100%] primary-bg-color'>
          <select
            className='edit-select outline-none h-8 primary-bg-color rounded-[6px] table-text w-full capitalize'
            value={thirdHeroValue?.objectFit}
            onChange={handleBgFitChange}
            name='objectFit'
          >
            <option>{t('lang_fill')}</option>
            <option>{t('lang_contain')}</option>
            <option>{t('lang_cover')}</option>
            <option>{t('lang_none')}</option>
            <option>{t('lang_scale_down')}</option>
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticThirdHeroSection;
