import {
  Autocomplete,
  Backdrop,
  Checkbox,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  ArrowLeft,
  Camera,
  Check,
  ChevronDown,
  Clock,
  Code,
  Edit,
  Repeat,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  CalendarFill,
  CloudUpload,
  PlayCircleFill,
  Plus,
  QuestionCircleFill,
  Trash3Fill,
  Youtube,
} from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, Extention } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { CountryCodes } from '../../Assets/StaticData/countrycode';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, GetCurrencySymbol, formatDate } from '../../controllers/FormatAmount';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import GenerateUniqueFilename from '../../controllers/GenerateUniqueFilename';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import ProductModel from '../../controllers/ProductModel';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectCategories from '../../controllers/SelectCategories';
import SelectProduct from '../../controllers/SelectProduct';
import { Transition } from '../../controllers/Transition';
import UpdateCustomMetaField from '../../controllers/UpdateCustomMetaField';
import VideoLinkUpload from '../../controllers/VideoLinkUpload';
import { adminSocket } from '../../utilities/socket';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
import AddSpecification from './AddSpecification';
import DigitalProduct from './DigitalProduct';
import SelectAttribute from './SelectAttribute';
import LinearWithValueLabel from '../../controllers/LinearProgressWithLabel';
import ReactDragListView from 'react-drag-listview';
import SelectLocation from '../../controllers/SelectLocation';
import SelectVariantQuantity from '../../controllers/SelectVariantQuantity';

const UpdateProduct = ({
  reGenerateProcessing,
  setReGenerateProcessing,
  reGenerateProcess,
  setReGenerateProcess,
}) => {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const { DragColumn } = ReactDragListView;
  const params = useParams();
  const navigate = useNavigate();
  let isPublish = /^publish=/.test(params?.slugname);
  let isDraft = /^darft=/.test(params?.slugname);
  const { installedExtensions } = useContext(Extention);
  const [productSpecs, setProductSpecs] = useState({
    productDiscount: '',
    Brands: [],
    tags: [],
    TagsArray: '',
    selectTabs: [],
  });
  const [ProductStats, setProductStats] = useState({
    selatedBundle: [],
    selatedRelated: [],
    selatedCrossell: [],
    selatedUpsell: [],
    selatedCategory: [],
  });
  const [isYoutubeUrl, setIsYoutubeUrl] = useState(false);
  const [isAddVideoLink, setIsAddVideoLink] = useState(false);
  const closeAddVideoLink = (value) => {
    setIsAddVideoLink(value);
  };
  const [videoURL, setVideoURL] = useState('');
  const [scheduleTimeDate, setScheduleTimeDate] = useState();
  const [isChangeDate, setIsChangeDate] = useState();
  const [selectBran, setSelectedBrands] = useState({ brandName: '', brandId: '' });
  const [meta, setMeta] = useState({ meta_description: '', meta_title: '' });
  const { meta_description, meta_title } = meta;
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [AiLoader, setAiloader] = useState(false);
  const [deletePopup, setDeletePopup] = useState({ index: '', visibility: false });
  const [description, setDescription] = useState(``);
  const [array, setarray] = useState([]);
  const [groupVariant, setGroupVarient] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [groupVariantData, setGroupVariantData] = useState([]);
  const [image, setimage] = useState(``);
  const [Documentationimage, setDocumentationimage] = useState('');
  const [varientGRP, setVarientGRP] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [discountDD, setDiscountDD] = useState(true);
  const [varient, setVarient] = useState({
    group_name: '',
    group_value: '',
    price: '',
    image: '',
    quantity: '',
    sku_id: '',
  });
  const [err, setErr] = useState(false);
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [fileType, setFileType] = useState('url');
  const [fileLoader, setFileLoader] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const [originalFile, setOriginalFile] = useState('');
  const [download_btn_text, setDownload_btn_text] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [digitalQuantity, setDigitalQuantity] = useState('');
  const catRef = useRef();
  const brandRef = useRef();
  const [hoverId, setHoverId] = useState(null);
  const isPOS = installedExtensions?.some(elem => elem?.extension_name === 'POS')
  const handleOriginalFile = async (file) => {
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'product');
    try {
      setFileLoader(true);
      const res = await API.post('/admin/fileupload/fileUpload', formData);
      const result = decryptData(res?.data);
      if (result?.data?.length) setOriginalFile(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setFileLoader(false);
    }
  };
  const handlePreviewFile = async (file) => {
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'product');
    try {
      setFileLoader(true);
      const res = await API.post('/admin/fileupload/fileUpload', formData);
      const result = decryptData(res?.data);
      if (result?.data?.length) setPreviewFile(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setFileLoader(false);
    }
  };
  const clientInfo = clientData;
  let displayGroup = [];
  let groupNamesfilteration = groupVariantData?.map((x) => x.group?.map((y) => y.group_name));
  let usedGroupNames = [...new Set(groupNamesfilteration?.flat())];
  for (const groupName of usedGroupNames) {
    let groupValues = groupVariantData?.map((x) =>
      x.group?.map((y) => (y.group_name === groupName ? y.group_value : '')),
    );
    displayGroup.push({ groupName, groupValues: [...new Set(groupValues?.flat())] });
  }

  const { selatedBundle, selatedRelated, selatedCrossell, selatedUpsell, selatedCategory } =
    ProductStats;
  const [defaultCategory, setDefaultCategory] = useState({ id: '', name: '' });
  const { productDiscount, tags, TagsArray, selectTabs } = productSpecs;
  const [additionalProductInfo, setAdditionalProductInfo] = useState({
    product_id: '',
    product_type: '',
  });
  const [UpdateData, setUpdateData] = useState({
    product_name: ``,
    category_name: ``,
    image: ``,
    product_user_manual: ``,
    additional_images: ``,
    min_order_quantity: ``,
    product_return_policy: ``,
    product_replacement_days: ``,
    sku_id: '',
    mrp: ``,
    model: '',
    selling_price: ``,
    stock_status: ``,
    quantity: ``,
    product_unit: '',
    product_badge: '',
    country_of_origin: '',
    length: ``,
    height: ``,
    breadth: ``,
    weight: ``,
    hsn_code: ``,
    increment_by: 1,
    marketplace: false,
    barcode: '',
    cgst: '',
    sgst: ''
  });
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [affiliateData, setAffiliateData] = useState({
    button_text: '',
    affiliate_url: '',
  });
  const [shortDescription, setShortDescription] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [Discount, setDiscount] = useState({
    quantity: '',
    discount_value: '',
    product_discount_type: `Select discount type`,
  });
  const [preOrder, setPreOrder] = useState(false);
  const [preOrderAmount, setPreOrderAmount] = useState();
  const [preOrderPercentage, setPreOrderPercentage] = useState();
  const [groupAttribute, setGroupAttribute] = useState([]);
  const currencyRate = localStorage.getItem('currencyrate')?.length > 0 && JSON.parse(localStorage.getItem('currencyrate'));
  const ratesArray = Object.keys(currencyRate).map(key => {
    return { name: key, rate: currencyRate[key] };
  }) ?? [];
  const currency = ratesArray && (ratesArray?.find(c => c.name === clientData?.currency_format)?.rate ?? 1);
  const handlePreOrderAmount = (e) => {
    if (e.target.value < Number(selling_price || mrp) && e.target.value >= 1) {
      setPreOrderAmount(Number(e.target.value));
      setPreOrderPercentage(Number((Number(e.target.value) * 100) / Number(selling_price || mrp)));
    }
  };
  const handlePreOrderPercentage = (e) => {
    if (e.target.value < 100 && e.target.value >= 1) {
      setPreOrderPercentage(e.target.value);
      setPreOrderAmount(Number((Number(selling_price || mrp) * e.target.value) / 100));
    }
  };
  // Category upload image
  const [catimage, setcatimage] = useState();
  useEffect(() => {
    if (/https?:\/\/\S+/.test(videoURL)) {
      setIsYoutubeUrl(false);
    } else {
      setIsYoutubeUrl(true);
    }
  }, [videoURL]);
  // image upload logic
  const handleSetImage = (value, key) => {
    setIsChange(true);
    if (key === 'category') {
      setcatimage(value);
    } else if (key === 'manufacture') {
      setBrandimage(value);
    } else if (key === 'product-featured') {
      setimage(value);
    } else if (key === 'product-video') {
      setVideoURL(value);
      if (/https?:\/\/\S+/.test(value)) {
        setIsYoutubeUrl(false);
      } else {
        setIsYoutubeUrl(true);
      }
    }
  };
  const handleSetLink = (value) => {
    setIsYoutubeUrl(true);
    setarray([...array, value]);
  };
  const [modelImageLoader, setModelImageLoader] = useState(false);
  const [productModelImage, setProductModelImage] = useState();
  const [modelImageErr, setModelImageErr] = useState(false);
  const HandleModelImage = async (file) => {
    setModelImageLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts && parts[parts?.length - 1];
    if (Ext === 'glb' || Ext === 'gltf') {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'productModel');
      try {
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data);
        setProductModelImage(result?.data[0]);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setModelImageErr(false);
    } else {
      setModelImageErr(true);
    }
    setModelImageLoader(false);
  };
  const [openCategoryOptions, setOpenCategoryOptions] = useState(false);
  const [Catdata, setCatData] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const HandleCatSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        category_name: Catdata,
        image: catimage,
        category_slug_name: await GenerateSlugName(Catdata),
      });
      let SubmitData = await API.post(`/admin/category/addCategory`, payload);
      if (SubmitData.status === 200 || SubmitData.status === 304) {
        const result = decryptData(SubmitData?.data);
        setIsNotification(true);
        setNotificationMsg('Category added successfully!');
        setSeverity('success');
        setCatData('');
        setcatimage('');
        setAddCategory(false);
        HandleCategory(result?.data?._id, result?.data?.category_name, result?.data?.image);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setAddCategory(false);
    }
  };
  const getSelectedCategory = (value) => {
    setProductStats({ ...ProductStats, selatedCategory: value });
  };
  const closeCategoryModal = () => {
    setOpenCategoryOptions(false);
  };

  // Brand upload image
  const [brandimage, setBrandimage] = useState();

  const [manufacturerdata, setManufacturerData] = useState('');

  const HandleManufacturerSubmit = async (e) => {
    e.preventDefault();
    let body = {
      brand_name: manufacturerdata,
      brand_logo: brandimage,
      brand_slug_name: await GenerateSlugName(manufacturerdata),
    };
    if (manufacturerdata === '' || !brandimage) {
      setErr(true);
      setIsNotification(true);
      setNotificationMsg('Fill category data properly!');
      setSeverity('error');
    } else {
      try {
        const payload = encryptData(body);
        let SubmitData = await API.post(`/admin/manufactures/addManufacturer`, payload);
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          const result = decryptData(SubmitData?.data);
          setSelectedBrands({
            brandName: result?.data?.brand_name,
            brandId: result?.data?._id,
          });
          GetManufactures();
          setManufacturerData('');
          setBrandimage('');
          setIsNotification(true);
          setNotificationMsg('Manufacturer added successfully');
          setSeverity('success');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setAddBrand(false);
    }
  };

  const filterOptionsForBrand = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.brand_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const [selectOption, setSelectOption] = useState();
  const [addBrand, setAddBrand] = useState(false);

  // sorting DnD
  const HandleSort = (result) => {
    if (result && result?.destination) {
      const { source, destination } = result;
      const newStoreList = Array.from(array);
      const [removed] = newStoreList?.splice(source.index, 1);
      newStoreList.splice(destination.index, 0, removed);
      const updatedStoreList = newStoreList?.map((item, index) => item);
      setarray(updatedStoreList);
    }
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...array];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      const updatedStoreList = newItems?.map((item, index) => item);
      setarray(updatedStoreList);
      setHoverId(null);
    },
    nodeSelector: '.draggable-item',
    handleSelector: '.draggable-item',
  };

  // remove perticular image from selected imagesss
  const removeImages = (index, x) => {
    if (index > -1) {
      +(
        // only splice array when item is found
        array.splice(index, 1)
      ); // 2nd parameter means remove one item only
    }
    setarray([...array]);
  };

  useEffect(() => {
    if (addCategory) {
      catRef?.current.focus();
    } else if (addBrand) {
      brandRef?.current.focus();
    }
  }, [addCategory, addBrand]);

  // Save Tags In Array
  const HandleArray = () => {
    if (tags.includes(',')) {
      let TagSeperator = tags.split(',');
      let finalArray = [];
      let productTags = [...TagsArray, ...TagSeperator];
      productTags.filter((tag) => {
        const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
        let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
        if (!exists) {
          finalArray.push(tag.trim());
        }
      });
      setProductSpecs({ ...productSpecs, TagsArray: finalArray, tags: '' });
    } else {
      const searchRegex = new RegExp(`^${tags}$`, 'i');
      const exists = TagsArray.some((tag) => searchRegex.test(tag));
      if (!exists) {
        setProductSpecs({ ...productSpecs, TagsArray: [...TagsArray, tags], tags: '' });
      }
    }
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      const filterTags = TagsArray?.filter((x, id) => id !== index);
      setProductSpecs({ ...productSpecs, TagsArray: filterTags });
    }
  };
  useEffect(() => {
    if (additionalProductInfo?.product_id) {
      GetProductDiscount();
    }
  }, [additionalProductInfo?.product_id]);

  // get Product Discount
  const GetProductDiscount = async () => {
    if (isPublish) {
      try {
        const data = await API.get(
          `/admin/product/getProductDiscount/${additionalProductInfo?.product_id}`,
        );
        if (data.status === 200 || data.status === 304) {
          const result = decryptData(data?.data);
          setProductSpecs({ ...productSpecs, productDiscount: result?.data?.product_discount });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const data = await API.get(
          `/admin/product/getDraftProductDiscount/${additionalProductInfo?.product_id}`,
        );
        if (data.status === 200 || data.status === 304) {
          const result = decryptData(data?.data);
          setProductSpecs({ ...productSpecs, productDiscount: result?.data?.product_discount });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const [alltab, setAllTab] = useState();
  //get tabs from client
  const GetAllTabs = async () => {
    try {
      const data = await API.get(`/admin/extratab/GetExtraTabsForProduct`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setAllTab(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const [locationData, setLocationData] = useState([]);
  const [pos_location, setpos_location] = useState([]);
  const [openLocation, setOpenLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([])
  const getLocation = async () => {
    try {
      let ApiCall = await API.get(
        `/admin/pos/getPOSLocations`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setLocationData(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'pos' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getSelectedLocation = (value) => {
    setSelectedLocation(value)
  }
  const closeLocationModal = () => {
    setOpenLocation(false)
  }
  const handlePOSLocation = (e, elem) => {
    const existingLocationIndex = pos_location?.findIndex(data => data?.pos_location === elem?._id);

    if (existingLocationIndex !== -1) {
      // Update the existing location's quantity
      const updatedLocation = {
        ...pos_location[existingLocationIndex],
        quantity: Number(e.target.value)
      };

      const remainLocation = [...pos_location];
      remainLocation[existingLocationIndex] = updatedLocation;
      setpos_location(remainLocation);
    } else {
      // Add new location if it doesn't exist
      setpos_location([...pos_location, { quantity: Number(e.target.value), pos_location: elem?._id, location_name: elem?.name }]);
    }
  };
  useEffect(() => {
    if (locationData && pos_location) {
      const location = locationData.filter((data) => 
        pos_location.some((pos) => data._id === pos.pos_location)
      );
      setSelectedLocation(location);
    }
  }, [locationData, pos_location]);
  
  useEffect(() => {
    GetAllTabs();
    getPerticularProductInfo();
    GetManufactures();
    GetGroupVarients();
    getLocation();
  }, [params?.slugname]);
  // Add New Discount
  const [disErr, setDisErr] = useState(false);
  const NewProductDiscount = async (e) => {
    e.preventDefault();
    if(additionalProductInfo?.product_type === 'membership') {
      if (
        Discount?.product_discount_type === 'Select discount type' ||
        (Discount?.discount_value?.length === 0 && Discount?.discount_value <= 0) ||
        (Discount?.product_discount_type === 'percentage'
          ? Number(selling_price) <= Number(selling_price) * (Discount?.discount_value / 100)
          : Number(selling_price) <= Discount?.discount_value)
      ) {
        setDisErr(true);
      } else {
        if (isPublish) {
          if (isEditDiscount) {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.put(
                `/admin/product/updateProductDiscount/${editDiscountId}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setIsEditDiscount(false);
                setDisErr(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          } else {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.post(
                `/admin/product/addProductDiscount/${additionalProductInfo?.product_id}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setDisErr(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          }
        } else {
          if (isEditDiscount) {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.put(
                `/admin/product/updateDraftProductDiscount/${editDiscountId}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setDisErr(false);
                setIsEditDiscount(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          } else {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.post(
                `/admin/product/AddDraftProductDiscount/${additionalProductInfo?.product_id}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setDisErr(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          }
        }
      }
    } else {
      if (
        Discount?.product_discount_type === 'Select discount type' ||
        (Discount?.quantity?.length === 0 && Discount?.quantity <= 0) ||
        (Discount?.discount_value?.length === 0 && Discount?.discount_value <= 0) ||
        (Discount?.product_discount_type === 'percentage'
          ? Number(selling_price) <= Number(selling_price) * (Discount?.discount_value / 100)
          : Number(selling_price) <= Discount?.discount_value)
      ) {
        setDisErr(true);
      } else {
        if (isPublish) {
          if (isEditDiscount) {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.put(
                `/admin/product/updateProductDiscount/${editDiscountId}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setIsEditDiscount(false);
                setDisErr(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          } else {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.post(
                `/admin/product/addProductDiscount/${additionalProductInfo?.product_id}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setDisErr(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          }
        } else {
          if (isEditDiscount) {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.put(
                `/admin/product/updateDraftProductDiscount/${editDiscountId}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setDisErr(false);
                setIsEditDiscount(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          } else {
            try {
              const payload = encryptData({
                ...Discount,
                start_date: startDate,
                end_date: endDate,
              });
              const data = await API.post(
                `/admin/product/AddDraftProductDiscount/${additionalProductInfo?.product_id}`,
                payload,
              );
              if (data.status === 200 || data.status === 304) {
                GetProductDiscount();
                setDisErr(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }
            } catch (error) {
              setIsNotification(true);
              setNotificationMsg(error);
              setSeverity('error');
            }
          }
        }
      }
    }
  };
  const [deleteId, setDeleteId] = useState();
  // remove Product DiscountGetProductVariants
  const RemoveProductDiscount = async () => {
    if (isPublish) {
      try {
        const data = await API.delete(`/admin/product/deleteproductdiscount/${deleteId}`);
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Discount Removed SuccessFully!');
          setSeverity('success');
          GetProductDiscount();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setConfirmationPopup(false);
      }
    } else {
      try {
        const data = await API.delete(`/admin/product/deleteDraftProductDiscount/${id}`);
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Discount Removed SuccessFully!');
          setSeverity('success');
          GetProductDiscount();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  const HandleTabs = (id, name) => {
    if (id) {
      const tabs = selectTabs.find((x) => x._id === id);
      if (!tabs) {
        setProductSpecs({
          ...productSpecs,
          selectTabs: [...selectTabs, { _id: id, tab_title: name }],
        });
      }
    }
  };
  const HandleRemoveTabs = (id) => {
    const filtered = selectTabs.filter((x) => x._id !== id);
    setProductSpecs({ ...productSpecs, selectTabs: filtered });
  };
  // get Variants of the product
  const GetProductVariants = async () => {
    if (isPublish) {
      try {
        const data = await API.get(
          `/admin/product/getProductVariant/${additionalProductInfo?.product_id}`,
        );
        if (data.status === 200 || data.status === 304) {
          const result = decryptData(data?.data);
          setGroupVariantData(result?.data);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const data = await API.get(
          `/admin/product/getDraftProductVariantsGroup/${additionalProductInfo?.product_id}`,
        );
        if (data.status === 200 || data.status === 304) {
          const result = decryptData(data?.data);
          setGroupVariantData(result?.data?.variants);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  const GroupValueSet = () => {
    let updatedAttribute = [];
    if (groupVariant.group_value) {
      if (attributes.length === 0) {
        setAttributes([{ ...groupVariant, group_value: [groupVariant.group_value] }]);
      } else {
        let isGroupAlreadyExists = attributes.find((x) => x.group_name === groupVariant.group_name);
        if (isGroupAlreadyExists) {
          let isGroupValueExists = isGroupAlreadyExists.group_value.find(
            (x) => x === groupVariant.group_value,
          );
          if (!isGroupValueExists) {
            updatedAttribute = attributes.filter((x) => x.group_name !== groupVariant.group_name);
            isGroupAlreadyExists.group_value.push(groupVariant.group_value);
            setAttributes([...updatedAttribute, isGroupAlreadyExists]);
          }
        } else {
          setAttributes([
            ...attributes,
            { ...groupVariant, group_value: [groupVariant.group_value] },
          ]);
        }
      }

      setGroupVarient({ ...groupVariant, group_value: '' });
    }
  };

  const groupAttrDivide = async (attributes) => {
    let attrData = [];
    for (let attributeObj of attributes) {
      attrData.push(attributeObj.group_value.map((v) => ({ [attributeObj.group_name]: v })));
    }

    if (attrData.length > 0) {
      attrData = attrData.reduce((a, b) => a.flatMap((d) => b.map((e) => ({ ...d, ...e }))));
    }

    return attrData;
  };

  const SetInsertedGroupsToAttributes = async (groupData) => {
    // Extract inserted groups from groupVariantData
    const insertedGroups = (groupVariantData && groupVariantData.filter((x) => x._id)) || [];

    // Format groupData for update
    const updatedGroups = groupData.map(({ group }) => ({ group }));

    // Generate attribute information
    const attributeInfo = DisplayVarient([...insertedGroups, ...updatedGroups]);

    // Divide attribute information into groups
    const updatedVariants = await groupAttrDivide(attributeInfo);

    // Format updatedVariants into the desired data structure
    const data = updatedVariants.map((attr) => ({
      group: Object.entries(attr).map(([group_name, group_value]) => ({ group_name, group_value })),
      new: true,
    }));

    // Step 1: Remove _id from insertedGroups
    const cleanedInsertedGroups = insertedGroups.map(({ group }) => ({
      group: group.map(({ _id, ...group }) => group),
    }));

    // Step 2: Compare and remove inserted groups from newGroups
    const result = data.filter(
      (newGroup) =>
        !cleanedInsertedGroups.some(
          (insertedGroup) => JSON.stringify(newGroup.group) === JSON.stringify(insertedGroup.group),
        ),
    );

    // Remove duplicates from the result
    let newGroups = _.uniqWith(result, _.isEqual);

    let variantsForUniq = [];

    // Handle cases where groupVariantData has length
    if (groupVariantData?.length) {
      // Extract and filter unique variants with _id
      const removeNewlyCreatedVariantsForUniq = [
        ...new Set(groupVariantData.map((variant) => variant._id && variant).filter((x) => x)),
      ];

      // Append non-new variants to newGroups
      for (const variant of removeNewlyCreatedVariantsForUniq) {
        newGroups.push({ ...variant, new: false });
      }

      // Find the maximum group length
      let groupMaxLength = Math.max(...newGroups.map(({ group }) => group.length));

      // Check if any group in newGroups matches any group in oldGroups; if match, update oldGroup and remove from newGroups
      for (let i = 0; i < newGroups?.length; i++) {
        const isMatchOldGroupWithNewGroup = removeNewlyCreatedVariantsForUniq?.find((oldGroup) =>
          hasMatchingGroup(newGroups[i], oldGroup),
        );

        if (isMatchOldGroupWithNewGroup) {
          removeNewlyCreatedVariantsForUniq[i] = isMatchOldGroupWithNewGroup;
          let copyMatch = { ...isMatchOldGroupWithNewGroup };
          delete copyMatch.group;
          delete newGroups[i].new;
          let copyNewGrp = { ...newGroups[i] };
          newGroups[i] = { ...newGroups[i], ...copyMatch };

          // Update the existing group if it's not a duplicate
          const existingIds = newGroups.filter((newgrp) => newgrp._id === copyNewGrp._id);

          if (existingIds && existingIds?.length > 1) {
            newGroups[i] = copyNewGrp;
          }
        }

        // Remove groups with a different length than the maximum
        if (newGroups[i]?.group?.length !== groupMaxLength) {
          newGroups.splice(i, 1);
        }
      }

      // Sort newGroups by _id
      newGroups = _.sortBy(newGroups, [(o) => o?._id]);

      variantsForUniq = [...newGroups];
    } else {
      variantsForUniq = [...newGroups];
    }

    // Remove duplicates from the result
    let uniqGroups = _.uniqWith(variantsForUniq, _.isEqual);
    // Update the state with the unique groups
    setGroupVariantData(uniqGroups);
  };

  // Function to check if a group in oldGroups matches the criteria in newGroup
  function hasMatchingGroup(newGroup, oldGroup) {
    // Check if all groups in oldGroup have matching counterparts in newGroup
    const allGroupsMatched = oldGroup.group.every((oldGroupItem) => {
      return newGroup.group.some(
        (newGroupItem) =>
          newGroupItem.group_name === oldGroupItem.group_name &&
          newGroupItem.group_value === oldGroupItem.group_value,
      );
    });

    return allGroupsMatched;
  }

  const DisplayVarient = (variants) => {
    let grpvalue = [];
    if (variants.length > 0) {
      for (let e of variants) {
        for (const y of e.group) {
          const grp_exist = grpvalue.find((arr) => arr.group_name === y.group_name);
          if (grp_exist) {
            grpvalue.forEach((a) => {
              if (a.group_name === y.group_name) {
                const valueIndex = a.group_value.indexOf(y.group_value);
                if (valueIndex < 0) {
                  a.group_value.push(y.group_value);
                }
              }
            });
          } else {
            grpvalue.push({
              group_name: y.group_name,
              group_value: [y.group_value],
            });
          }
        }
      }
    }
    return grpvalue;
  };
  const HandleMultipleVariants = async () => {
    let attrs = await groupAttrDivide(attributes);

    const groupData = [];
    for (let attr of attrs) {
      let object = Object.keys(attr);
      let values = Object.values(attr);
      let newArr = [];
      object.map((elem, index) => {
        let obj = {
          group_name: elem,
          group_value: values[index],
        };
        newArr.push(obj);
      });
      groupData.push({
        group: newArr,
      });
    }
    await SetInsertedGroupsToAttributes(groupData);
  };

  useEffect(() => {
    GroupValueSet();
  }, [groupVariant]);
  useEffect(() => {
    if (additionalProductInfo.product_id) {
      GetProductVariants();
    }
  }, [additionalProductInfo]);
  useEffect(() => {
    HandleMultipleVariants();
  }, [attributes]);

  const [openVariantQty, setOpenVariantQty] = useState(false);
  const [openVariantQtyindex, setOpenVariantQtyindex] = useState('');
  const closeVariantQtyModal = () => {
    setOpenVariantQty(false)
  }

  // OpenAI Description
  const generateShortDesc = async (e) => {
    e.preventDefault();
    try {
      setAiloader(true);
      if (!product_name) {
        setwarn(true);
        setAiloader(false);
      } else {
        const payload = encryptData({
          name: product_name,
        });
        let ApiCall = await API.post('/admin/ai/short_description', payload);
        const result = decryptData(ApiCall?.data);
        let cleanedData = result?.data?.replace(/\n/g, '');
        setShortDescription(cleanedData);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    }
    setAiloader(false);
  };
  const generateDesc = async (e) => {
    e.preventDefault();
    setAiloader(true);
    try {
      if (!product_name) {
        setwarn(true);
        setAiloader(false);
      } else {
        const payload = encryptData({
          name: product_name,
        });
        let ApiCall = await API.post('/admin/ai/long_description', payload);
        const result = decryptData(ApiCall?.data);
        setDescription(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };

  // remove item from Varient Data
  const HandleRemove = (index, e) => {
    e.preventDefault();
    const UpdateTable = groupVariantData.filter((elem, ind) => ind !== index);
    setGroupVariantData(UpdateTable);
  };

  // Remove Variant
  const HandleRemoveVarient = async (id, e) => {
    e.preventDefault();
    if (isPublish) {
      try {
        const data = await API.delete(`/admin/product/deleteProductVariant/${id}`);
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Varient successfully removed!');
          setSeverity('success');
          const UpdateVariants = groupVariantData.filter(
            (variant) => String(variant._id) !== String(id),
          );
          setGroupVariantData(UpdateVariants);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const data = await API.delete(`/admin/product/deleteDraftProductDiscount/${id}`);
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Varient successfully removed!');
          setSeverity('success');
          const UpdateVariants = groupVariantData.filter(
            (variant) => String(variant._id) !== String(id),
          );
          setGroupVariantData(UpdateVariants);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const [Documentationloader, setDocumentationLoader] = useState(false);

  const [documentationErr, setdocumentationErr] = useState(false);
  // Product Documentation
  const HandleDocumentation = async (file) => {
    setDocumentationLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts ? parts[parts?.length - 1] : 'jpg';
    if (
      Ext === 'pdf' ||
      Ext === 'zip' ||
      Ext === 'csv' ||
      Ext === 'html' ||
      Ext === 'docx' ||
      Ext === 'xlsx' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'product');
      try {
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data);
        setDocumentationimage(result?.data[0]);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setdocumentationErr(false);
    } else {
      setdocumentationErr(true);
    }
    setDocumentationLoader(false);
  };
  // for multiple Images
  const [imagesLength, setImagesLength] = useState();
  const [Extraimgloader, setextraImgLoader] = useState(false);
  const HandleImages = async (imagess) => {
    if (array?.length + imagess?.length > 30) {
      setIsNotification(true);
      setNotificationMsg('you Cannot Add Greater Than 30 Images');
      setSeverity('warning');
    } else {
      if (imagess?.length > 0) {
        setImagesLength(imagess?.length);
        setextraImgLoader(true);
        const formData = new FormData();
        for (let i of imagess) {
          let fileName = i?.name;
          if (!fileName) {
            fileName = await GenerateUniqueFilename();
          }
          formData.append('file', i, fileName);
        }
        formData.append('folder', 'product');
        try {
          const res = await API.post('/admin/fileupload/upload', formData);
          const result = decryptData(res?.data);
          setarray([...array, ...result?.data]);
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        } finally {
          setextraImgLoader(false);
          setImagesLength();
        }
      }
    }
  };

  const HandleInput = (e) => {
    setIsChange(true);
    setErr(false);
    setUpdateData({ ...UpdateData, [e.target.name]: e.target.value });
  };

  const {
    product_name,
    min_order_quantity,
    product_return_policy,
    product_replacement_days,
    sku_id,
    mrp,
    model,
    stock_status,
    status_message,
    selling_price,
    product_unit,
    country_of_origin,
    product_badge,
    length,
    quantity,
    height,
    breadth,
    weight,
    hsn_code,
    increment_by,
  } = UpdateData;

  // save Category Stats
  const HandleCategory = (id, name, image) => {
    if (id) {
      const cat = selatedCategory.find((x) => x._id === id);
      if (!cat) {
        setProductStats({
          ...ProductStats,
          selatedCategory: [...selatedCategory, { _id: id, category_name: name, image }],
        });
      }
    }
  };
  const handleRemovecategory = (id) => {
    const removecat = selatedCategory.filter((x) => x._id !== id);
    setProductStats({ ...ProductStats, selatedCategory: removecat });
  };
  const handleRemovebundle = (id) => {
    const removecat = selatedBundle.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedBundle: removecat });
  };

  const [brandTest, setBrandTest] = useState([]);
  const GetManufactures = async () => {
    try {
      const data = await API.get(`/admin/manufactures/GetSelectedManufacturer`);
      const result = decryptData(data?.data);
      setBrandTest(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [isCustomField, setIsCustomField] = useState(false);
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/product`);
    const result = decryptData(ApiCall?.data);
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  };
  useEffect(() => {
    getMetaField();
  }, []);
  const [socketConnection, setSocketConnection] = useState(false);

  useEffect(() => {
    if (adminSocket.connected) {
      setSocketConnection(true);
    } else {
      setSocketConnection(false);
    }
  }, [setSocketConnection]);
  useEffect(() => {
    if (socketConnection) {
      adminSocket.on('progress', async (data) => {
        setReGenerateProcessing(true);
        setReGenerateProcess(Number(data?.percentage));
        if (data?.percentage === 100) {
          setReGenerateProcessing(false);
        }
      });
    }

    return () => {
      adminSocket.off('progress');
    };
  }, [socketConnection]);
  const [isEditDiscount, setIsEditDiscount] = useState(false);
  const [editDiscountId, setEditDiscountId] = useState();
  //Edit Discount
  const DiscountChange = (e, diselem) => {
    e.preventDefault();
    setDiscountDD(true);
    setIsEditDiscount(true);
    setEditDiscountId(diselem?._id);
    setDiscount({
      quantity: diselem?.quantity,
      discount_value: diselem?.discount_value,
      product_discount_type: diselem?.product_discount_type,
    });
    setStartDate(new Date(diselem?.start_date));
    setEndDate(new Date(diselem?.end_date));
  };

  // Update Variant
  const [varPP, setVarPP] = useState(false);
  const [Upvariant, setUpvariant] = useState({
    quantity: '',
    price: '',
    sku_id: '',
    image: '',
    selectedIndex: '',
    selectedValue: '',
    group: [],
  });
  const [imgErr, setImgErr] = useState(false);
  // image upload logic
  const HandlevariantImage = async (file, index) => {
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts ? parts[parts?.length - 1] : 'jpg';
    if (
      Ext === 'jpg' ||
      Ext === 'png' ||
      Ext === 'jpeg' ||
      Ext === 'webp' ||
      Ext === 'gif' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      setImgErr(true);
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'variant');
      try {
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data);
        const img = await result?.data[0];
        groupVariantData[index].image = img;
        setGroupVariantData(groupVariantData);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      setImgErr(true);
    }
  };
  const handleVariantPrice = (index, value) => {
    const updatedGroupVariantData = [...groupVariantData];
    updatedGroupVariantData[index].price = value;
    setGroupVariantData(updatedGroupVariantData);
  };
  const handleVariantQty = (index, value) => {
    const updatedGroupVariantData = [...groupVariantData];
    updatedGroupVariantData[index].quantity = value;
    setGroupVariantData(updatedGroupVariantData);
  };
  const handleVariantSkuId = (index, value) => {
    const updatedGroupVariantData = [...groupVariantData];
    updatedGroupVariantData[index].sku_id = value;
    setGroupVariantData(updatedGroupVariantData);
  };
  const [slugName, setSlugName] = useState();

  // GetProduct Info
  const [formSpinner, setFormSpinner] = useState(true);
  const [productName, setProductName] = useState('');
  const [nodata, setNodata] = useState(false);
  const [productData, setProductData] = useState();
  const [meta_fields, setmeta_fields] = useState({});
  
  const getPerticularProductInfo = async () => {
    setFormSpinner(true);
    if (isPublish) {
      try {
        let ApiCall = await API.get(
          `/admin/product/getParticularProduct/${params?.slugname.replace('publish=', '')}`,
        );
        const result = decryptData(ApiCall?.data);
        setProductData(result?.data);
        setProductName(result?.data?.product_name);
        setUpdateData({
          product_name: `${result?.data?.product_name}`,
          category_name: `${result?.data?.category}`,
          image: `${result?.data?.image}`,
          product_user_manual: `${result?.data?.product_user_manual}`,
          additional_images: `${result?.data?.additional_images}`,
          min_order_quantity: `${result?.data?.min_order_quantity}`,
          product_return_policy: `${result?.data?.product_return_policy}`,
          product_replacement_days:
            result?.data?.product_replacement_days !== undefined
              ? `${result?.data?.product_replacement_days}`
              : `7`,
          sku_id: result?.data?.sku_id ? `${result?.data?.sku_id}` : '',
          mrp: result?.data?.mrp
            ? FormatAmount(result?.data?.mrp)?.slice(1)?.includes(',')
              ? FormatAmount(result?.data?.mrp)?.slice(1)?.split(',')?.join('')
              : FormatAmount(result?.data?.mrp)?.slice(1)
            : '',
          model: result?.data?.model ? `${result?.data?.model}` : '',
          selling_price: result?.data?.selling_price
            ? FormatAmount(result?.data?.selling_price)?.slice(1)?.includes(',')
              ? FormatAmount(result?.data?.selling_price)?.slice(1)?.split(',')?.join('')
              : FormatAmount(result?.data?.selling_price)?.slice(1)
            : '',
          stock_status: `${result?.data?.stock_status}`,
          status_message: result?.data?.status_message ? `${result?.data?.status_message}` : '',
          quantity: result?.data?.quantity ? `${result?.data?.quantity}` : 0,
          product_unit: result?.data?.product_unit ? result?.data?.product_unit : '',
          product_badge: result?.data?.product_badge ? `${result?.data?.product_badge}` : '',
          country_of_origin: result?.data?.country_of_origin
            ? `${result?.data?.country_of_origin}`
            : '',
          length: result?.data?.dimension ? `${result?.data?.dimension.length}` : 10,
          height: result?.data?.dimension ? `${result?.data?.dimension.height}` : 10,
          breadth: result?.data?.dimension ? `${result?.data?.dimension.breadth}` : 10,
          weight: result?.data?.weight ? `${result?.data?.weight}` : 0.5,
          hsn_code: result?.data?.hsn_code ? result?.data?.hsn_code : '',
          increment_by: result?.data?.increment_by ? result?.data?.increment_by : '',
          marketplace: result?.data?.marketplace ?? false,
          barcode: result?.data?.barcode ?? '',
          cgst: result?.data?.cgst ?? '',
          sgst: result?.data?.sgst ?? ''
        });
        setScheduleTimeDate(
          new Date(result?.data?.product_publish_date) > new Date()
            ? new Date(result?.data?.product_publish_date)
            : '',
        );
        setPreOrder(result?.data?.pre_order);
        setPreOrderAmount(result?.data?.pre_order_amount);
        setDefaultCategory({
          id: result?.data?.default_category?._id,
          name: result?.data?.default_category?.category_name,
        });
        setAffiliateData({
          button_text: result?.data?.button_text ? `${result?.data?.button_text}` : '',
          affiliate_url: result?.data?.affiliate_url ? `${result?.data?.affiliate_url}` : '',
        });
        setShortDescription(
          result?.data?.short_description ? `${result?.data?.short_description}` : '',
        );
        setDescription(result?.data?.description ? `${result?.data?.description}` : ``);
        setDocumentationimage(
          result?.data?.product_user_manual ? `${result?.data?.product_user_manual}` : '',
        );
        setMeta({
          meta_description: result?.data?.meta_description,
          meta_title: result?.data?.meta_title,
        });
        setSelectedBrands({
          brandName: result?.data?.brand?.brand_name,
          brandId: result?.data?.brand?._id,
        });
        setarray([...result?.data?.additional_images]);
        setimage(result?.data?.image ? `${result?.data?.image}` : '');
        setSlugName(
          result?.data?.product_slug_name
            ? result?.data?.product_slug_name
            : result?.data?.product_name,
        );
        setAdditionalProductInfo({
          product_id: result?.data?._id,
          product_type: result?.data?.product_type,
        });
        setProductSpecs({
          ...productSpecs,
          tags: [],
          TagsArray: result?.data?.product_tags,
          selectTabs: result?.data?.extratabs,
        });
        setProductStats({
          ...ProductStats,
          selatedBundle: result?.data?.grouped_product_ids.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedRelated: result?.data?.related_products.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedCrossell: result?.data?.cross_sell.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedUpsell: result?.data?.up_sell.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedCategory: result?.data?.categories.map((x) => {
            return { _id: x._id, category_name: x.category_name, image: x?.image };
          }),
        });
        setmeta_fields(result?.data?.custom_fields);
        setProductModelImage(result?.data?.product_model);
        setIsDownload(result?.data?.isDownload);
        setDownload_btn_text(result?.data?.download_btn_text);
        setPreviewFile(result?.data?.preview_file);
        setOriginalFile(result?.data?.original_file);
        setExpireDate(result?.data?.expire_date);
        setGroupAttribute(result?.data?.attribute || []);
        setpos_location(result?.data?.pos_quantity ?? []);
        setFormSpinner(false);
      } catch (error) {
        setNodata(true);
      }
    } else {
      try {
        let ApiCall = await API.get(
          `/admin/product/getPerticularDraftProduct/${params?.slugname?.replace('draft=', '')}`,
        );
        const result = decryptData(ApiCall?.data);
        setProductName(result?.data?.product_name);
        setProductData(result?.data);
        setUpdateData({
          product_name: `${result?.data?.product_name}`,
          category_name: `${result?.data?.category}`,
          image: `${result?.data?.image}`,
          product_user_manual: `${result?.data?.product_user_manual}`,
          additional_images: `${result?.data?.additional_images}`,
          min_order_quantity: `${result?.data?.min_order_quantity}`,
          product_return_policy: `${result?.data?.product_return_policy}`,
          product_replacement_days:
            result?.data?.product_replacement_days !== undefined
              ? `${result?.data?.product_replacement_days}`
              : `7`,
          sku_id: result?.data?.sku_id ? `${result?.data?.sku_id}` : '',
          mrp: FormatAmount(result?.data?.mrp)?.slice(1)?.includes(',')
            ? FormatAmount(result?.data?.mrp)?.slice(1)?.split(',')?.join('')
            : FormatAmount(result?.data?.mrp)?.slice(1),
          model: result?.data?.model ? `${result?.data?.model}` : '',
          selling_price: FormatAmount(result?.data?.selling_price)?.slice(1)?.includes(',')
            ? FormatAmount(result?.data?.selling_price)?.slice(1)?.split(',')?.join('')
            : FormatAmount(result?.data?.selling_price)?.slice(1),
          stock_status: `${result?.data?.stock_status}`,
          status_message: result?.data?.status_message ? `${result?.data?.status_message}` : '',
          quantity: result?.data?.quantity ? `${result?.data?.quantity}` : 0,
          product_unit: result?.data?.product_unit ? result?.data?.product_unit : '',
          product_badge: result?.data?.product_badge ? `${result?.data?.product_badge}` : '',
          country_of_origin: result?.data?.country_of_origin
            ? `${result?.data?.country_of_origin}`
            : '',
          length: result?.data?.dimension ? `${result?.data?.dimension.length}` : 10,
          height: result?.data?.dimension ? `${result?.data?.dimension.height}` : 10,
          breadth: result?.data?.dimension ? `${result?.data?.dimension.breadth}` : 10,
          weight: result?.data?.weight ? `${result?.data?.weight}` : 0.5,
          country_of_origin: result?.data?.country_of_origin
            ? `${result?.data?.country_of_origin}`
            : '',
          hsn_code: result?.data?.hsn_code ? result?.data?.hsn_code : '',
          increment_by: result?.data?.increment_by ? result?.data?.increment_by : '',
          marketplace: result?.data?.marketplace ?? false,
          barcode: result?.data?.barcode ?? '',
          cgst: result?.data?.cgst ?? '',
          sgst: result?.data?.sgst ?? ''
        });
        setScheduleTimeDate();
        setDefaultCategory({
          id: result?.data?.default_category?._id,
          name: result?.data?.default_category?.category_name,
        });
        setAffiliateData({
          button_text: result?.data?.button_text ? `${result?.data?.button_text}` : '',
          affiliate_url: result?.data?.affiliate_url ? `${result?.data?.affiliate_url}` : '',
        });
        setShortDescription(
          result?.data?.short_description ? `${result?.data?.short_description}` : '',
        );
        setDescription(result?.data?.description ? `${result?.data?.description}` : ``);
        setDocumentationimage(
          result?.data?.product_user_manual ? `${result?.data?.product_user_manual}` : '',
        );
        setMeta({
          meta_description: result?.data?.meta_description,
          meta_title: result?.data?.meta_title,
        });
        setSelectedBrands({
          brandName: result?.data?.brand?.brand_name,
          brandId: result?.data?.brand?._id,
        });
        setarray([...result?.data?.additional_images]);
        setimage(result?.data?.image ? `${result?.data?.image}` : '');
        setSlugName(
          result?.data?.draft_product_slug_name
            ? result?.data?.draft_product_slug_name
            : result?.data?.product_name,
        );
        setAdditionalProductInfo({
          product_id: result?.data?._id,
          product_type: result?.data?.product_type,
        });
        setProductSpecs({
          ...productSpecs,
          tags: [],
          TagsArray: result?.data?.product_tags,
          selectTabs: result?.data?.extratabs,
        });
        setProductStats({
          ...ProductStats,
          selatedBundle: result?.data?.grouped_product_ids.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedRelated: result?.data?.related_products.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedCrossell: result?.data?.cross_sell.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedUpsell: result?.data?.up_sell.map((x) => {
            return { product: x._id, name: x.product_name, image: x?.image };
          }),
          selatedCategory: result?.data?.categories.map((x) => {
            return { _id: x._id, category_name: x.category_name, image: x?.image };
          }),
        });
        setmeta_fields(result?.data?.data?.custom_fields);
        setProductModelImage(result?.data?.data?.product_model);
        setIsDownload(result?.data?.data?.isDownload);
        setDownload_btn_text(result?.data?.data?.download_btn_text);
        setPreviewFile(result?.data?.data?.preview_file);
        setOriginalFile(result?.data?.data?.original_file);
        setExpireDate(result?.data?.data?.expire_date);
        setGroupAttribute(result?.data?.data?.attribute || []);
        setpos_location(result?.data?.pos_quantity ?? []);
        setFormSpinner(false);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
    setFormSpinner(false);
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const [whichProduct, setWhichProduct] = useState();
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
    setWhichProduct();
  };
  const getSelectedProduct = (value) => {
    if (whichProduct === 'upsell') {
      setProductStats({ ...ProductStats, selatedUpsell: value });
    } else if (whichProduct === 'crosssell') {
      setProductStats({ ...ProductStats, selatedCrossell: value });
    } else if (whichProduct === 'related') {
      setProductStats({ ...ProductStats, selatedRelated: value });
    } else if (whichProduct === 'bundle') {
      setProductStats({ ...ProductStats, selatedBundle: value });
    }
  };
  const removeSelectProduct = (e, id, key) => {
    e.preventDefault();
    if (key === 'upsell') {
      const filterUpsellProduct = ProductStats?.selatedUpsell?.filter((x) => x?.product !== id);
      setProductStats({ ...ProductStats, selatedUpsell: filterUpsellProduct });
    } else if (key === 'crosssell') {
      const filterCrosssellProduct = ProductStats?.selatedCrossell?.filter(
        (x) => x?.product !== id,
      );
      setProductStats({ ...ProductStats, selatedCrossell: filterCrosssellProduct });
    } else if (key === 'related') {
      const filterRelatedProduct = ProductStats?.selatedRelated?.filter((x) => x?.product !== id);
      setProductStats({ ...ProductStats, selatedRelated: filterRelatedProduct });
    } else if (key === 'bundle') {
      const filterBundleProduct = ProductStats?.selatedBundle?.filter((x) => x?.product !== id);
      setProductStats({ ...ProductStats, selatedBundle: filterBundleProduct });
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleUpdate = async (e, key) => {
    e.preventDefault();
    setBtnLoading(true);
    let body = {
      product_name,
      description,
      short_description: shortDescription,
      categories: selatedCategory?.map((x) => x._id),
      default_category:
        selatedCategory?.length > 0 && selatedCategory?.length === 1
          ? selatedCategory[0]._id
          : defaultCategory?.id,
      ...(key === 'publish' && { product_slug_name: slugName }),
      sku_id,
      mrp: mrp / currency,
      youtube_url: videoURL,
      stock_status: additionalProductInfo?.product_type !== 'group' ? stock_status : 'in_stock',
      min_order_quantity,
      product_return_policy,
      product_replacement_days,
      related_products: selatedRelated?.map((x) => x?.product),
      up_sell: selatedUpsell?.map((x) => x?.product),
      cross_sell: selatedCrossell?.map((x) => x?.product),
      extratabs: selectTabs?.map((x) => x._id),
      product_unit,
      country_of_origin,
      product_tags: TagsArray?.length > 0 ? TagsArray : [],
      status_message,
      product_badge,
      product_discount: productDiscount,
      meta_description,
      product_type: additionalProductInfo?.product_type,
      meta_title,
      dimension: { length, breadth, height },
      weight,
      variants: additionalProductInfo?.product_type === 'variant' ? groupVariantData?.map((x) => ({ ...x, price: Number(x.price) / currency })) : [],
      model,
      quantity: additionalProductInfo?.product_type !== 'group' ? quantity : '',
      image: image,
      additional_images: [...array],
      ...(selectBran?.brandId ? { brand: selectBran?.brandId } : ''),
      ...(groupVariantData && groupVariantData.length === 0 ? { quantity: quantity } : ''),
      custom_fields: meta_fields,
      product_model: productModelImage,
      hsn_code: hsn_code,
      increment_by: increment_by < 1 ? 1 : increment_by,
      ...(originalFile?.length > 0 && { isDownload: isDownload }),
      ...(originalFile?.length > 0 && { download_btn_text: download_btn_text }),
      ...(previewFile?.length > 0 && { preview_file: previewFile }),
      ...(originalFile?.length > 0 && { original_file: originalFile }),
      ...(originalFile?.length > 0 && { expire_date: expireDate }),
      ...(pos_location?.length > 0 && { pos_quantity: pos_location }),
      attribute: groupAttribute,
      marketplace: UpdateData?.marketplace,
      barcode: UpdateData?.barcode ?? '',
      cgst: UpdateData?.cgst ?? '',
      sgst: UpdateData?.sgst ?? ''
    };
    if (selling_price && selling_price !== 'null') {
      body.selling_price = selling_price / currency;
    }
    if (isChangeDate) {
      body = {
        ...body,
        product_publish_date: scheduleTimeDate,
        pre_order: preOrder,
      };
    }
    if (additionalProductInfo?.product_type === 'group') {
      body = {
        ...body,
        grouped_product_ids: selatedBundle?.map((x) => x?.product),
      };
    } else if (additionalProductInfo?.product_type === 'affiliate') {
      body = {
        ...body,
        button_text: affiliateData?.button_text,
        affiliate_url: affiliateData?.affiliate_url,
      };
    } else if (Documentationimage) {
      body = {
        ...body,
        product_user_manual: Documentationimage,
      };
    } else if (slugEdited && slugName) {
      body.product_slug_name = slugName;
    } else {
      body = {
        ...body,
      };
    }
    if (key === 'publish') {
      if (
        slugName === '' ||
        product_name === '' ||
        selling_price === '' ||
        (!image && !videoURL) ||
        weight < 0.5 ||
        height < 10 ||
        breadth < 10 ||
        length < 10 ||
        (additionalProductInfo.product_type === 'affiliate'
          ? affiliateData?.button_text === '' || affiliateData.affiliate_url === ''
          : false)
      ) {
        setErr(true);
        if (weight < 0.5 || height < 10 || breadth < 10 || length < 10) {
          setIsNotification(true);
          setNotificationMsg('Please enter valid value of dimention!');
          setSeverity('error');
        }
      } else {
        setErr(false);
        try {
          const payload = encryptData(body);
          const data = await API.post(`/admin/product/addProduct`, payload);
          const draftPayload = encryptData({
            id: [additionalProductInfo?.product_id],
          });
          const deleteDraft = await API.post(`/admin/product/deleteDraftProducts`, draftPayload);
          if (
            (data.status === 200 || data.status === 304) &&
            (deleteDraft?.status === 200 || deleteDraft?.status === 304)
          ) {
            setSaveChanges({
              isUnsaveChanges: false,
              showChangesPopup: false,
              backLink: '/products',
              title: 'edit product',
            });
            navigate('/products?type=publish');
          }
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      }
    } else {
      if (isPublish) {
        if (
          slugName === '' ||
          product_name === '' ||
          selling_price === '' ||
          (!image && !videoURL) ||
          weight < 0.5 ||
          height < 10 ||
          breadth < 10 ||
          length < 10 ||
          (additionalProductInfo.product_type === 'affiliate'
            ? affiliateData?.button_text === '' || affiliateData.affiliate_url === ''
            : false)
        ) {
          setErr(true);
          if (weight < 0.5 || height < 10 || breadth < 10 || length < 10) {
            setIsNotification(true);
            setNotificationMsg('Please enter valid value of dimention!');
            setSeverity('error');
          }
        } else {
          try {
            setErr(false);
            const payload = encryptData(body);
            const data = await API.put(
              `/admin/product/updateProduct/${additionalProductInfo?.product_id}`,
              payload,
            );
            if (data.status === 200 || data.status === 304) {
              navigate('/products?type=all');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
        }
      } else {
        if (product_name === '' || selling_price === '') {
          setErr(true);
        } else {
          try {
            setErr(false);
            const payload = encryptData(body);
            const data = await API.put(
              `/admin/product/updateDraftProducts/${additionalProductInfo?.product_id}`,
              payload,
            );
            if (data.status === 200 || data.status === 304) {
              navigate('/products?type=draft');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
        }
      }
    }
    setBtnLoading(false);
  };
  const HandleDiscountChange = (e) => {
    setDiscount({ ...Discount, [e.target.name]: e.target.value });
  };
  const HandleDefaultChange = async (id) => {
    try {
      const payload = encryptData({
        default: true,
      });
      const ApiCall = await API.put(`/admin/product/updateProductVariant/${id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetProductVariants();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const GetGroupVarients = async (e) => {
    try {
      const data = await API.get('/admin/product/getproductvariantsgroup');
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setVarientGRP(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // add Varient Sections
  const HandleVarientChange = (e) => {
    if (e.target.value !== 'Select Value') {
      setGroupVarient({ ...groupVariant, [e.target.name]: e.target.value });
      setVarient({ ...varient, [e.target.name]: e.target.value });
    }
  };
  const [isDimention, setIsDimention] = useState();

  const inputs = document.querySelectorAll('input[type="number"]');
  for (const input of inputs) {
    input.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }
  const [desErr, setDesErr] = useState(false);
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setDesErr(true);
    } else {
      try {
        setDesErr(false);
        setAiloader(true);
        const payload = encryptData({
          name: data,
        });
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/meta_description', payload);
          const result = decryptData(ApiCall?.data);
          setMeta({ ...meta, meta_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/meta_title', payload);
          const result = decryptData(ApiCall?.data);
          setMeta({ ...meta, meta_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const ShortquillRef = useRef(null);
  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    setIsChange(true);
  };
  const handleLongDescQuillChange = (value) => {
    setDescription(value);
    setIsChange(true);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const [descType, setDescType] = useState();
  const { htmlPP, code } = html;
  const handleViewHtml = (e, type) => {
    e.preventDefault();

    setDescType(type);
    let quill;
    if (type === 1) {
      quill = quillRef.current.getEditor();
    } else {
      quill = ShortquillRef.current.getEditor();
    }
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false });
    if (descType === 1) {
      setDescription(code);
    } else {
      setShortDescription(code);
    }
    setDescType();
  };
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit product',
      });
    } else {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'edit product',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/products',
        title: 'edit product',
      });
    } else {
      navigate('/products');
    }
  };
  const [isSchedule, setIsSchedule] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      <div className='h-auto overflow-y-auto relative pb-[130px]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <ConfirmationPopup
          heading='Delete Discount'
          subheading='Do you really want to delete this discount from this product?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={RemoveProductDiscount}
        />
        {isSelectProduct && (
          <SelectProduct
            isSelectProduct={isSelectProduct}
            api={`/admin/product/GetProductsListForCrossSell?`}
            closeSelectProduct={closeSelectProduct}
            getSelectedProduct={getSelectedProduct}
            alreadySelected={
              whichProduct === 'upsell'
                ? ProductStats?.selatedUpsell
                : whichProduct === 'crosssell'
                  ? ProductStats?.selatedCrossell
                  : whichProduct === 'related'
                    ? ProductStats?.selatedRelated
                    : whichProduct === 'bundle'
                      ? ProductStats?.selatedBundle
                      : []
            }
          />
        )}
        {AiLoader && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={AiLoader}
            className='flex flex-col'
          >
            <CircularProgress color='inherit' />
            <p>{t('lang_ai_generate_content_loader')}</p>
          </Backdrop>
        )}
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_products'), path: '/products' },
                { name: t('lang_Update_product'), path: '/products/update-products' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_product')} - {productName}
            </h4>
          </div>
        </div>
        <div className='flex flex-wrap'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <div className='space-y-4'>
                <div className=' secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className=' secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div>
                    {t('lang_media')}
                    <p className='text-xs table-text font-normal'>
                      {t('lang_Update_Blog_Details')}
                    </p>
                  </div>
                </div>
                <div className='mb-4'>
                  <div className='flex space-x-3 mb-1 justify-between'>
                    <label
                      className='flex items-center justify-between table-text text-[13px] '
                      htmlFor='Description'
                    >
                      {t('lang_Short_Description')}
                    </label>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[130px]' variant='rounded-[6px]' />
                  </div>
                </div>
              </div>
            ) : (
              <div className='w-full space-y-4'>
                <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='w-full mb-4'>
                    <label className='table-text text-[13px]  mb-1' htmlFor='product_name'>
                      {t('lang_Product_Name')}
                      <span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      className={
                        err && product_name?.length === 0
                          ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                          : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                      }
                      type='text'
                      id='product_name'
                      name='product_name'
                      value={product_name}
                      onChange={HandleInput}
                      placeholder='e.g. IPhone 17'
                      onBlur={() => setMeta({ ...meta, meta_title: product_name })}
                    />
                    <div className='w-full flex justify-between'>
                      <p className='text-xs mt-0 text-gray-400'>
                        {t('lang_recommend_character')}{' '}
                        {`${product_name?.length} ${t('lang_characters')}`}
                      </p>
                    </div>
                    {err && product_name?.length === 0 && (
                      <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Product_Name')}</p>
                    )}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='product_slug_name'>{t('lang_Product_Slug_Name')}</label>
                    <div
                      className={`relative flex items-center justify-between w-full border ${err && !slugName ? 'border-red-500' : 'main-border-color'
                        } rounded-[4px]`}
                    >
                      <input
                        className='w-full focus:outline-none'
                        type='text'
                        name='product_slug_name'
                        value={slugName}
                        onChange={(e) => {
                          setSlugName(e.target.value);
                          setIsChange(true);
                        }}
                        disabled={!enableSlug}
                      />
                      <Tooltip
                        title={`${enableSlug ? t('lang_save') : t('lang_edit')}`}
                        arrow
                        placement='bottom'
                        className='ml-2 -tracking-tighter capitalize'
                      >
                        <button type='button' className='slug-edit w-8 h-8'>
                          {enableSlug ? (
                            <Check
                              onClick={async () => {
                                if (slugName?.length > 0) {
                                  setSlugName(await GenerateSlugName(slugName));
                                  setEnableSlug(!enableSlug);
                                  setErr(false);
                                  setSlugEdited(true);
                                } else {
                                  setIsNotification(true);
                                  setNotificationMsg('Slug can not be blank!');
                                  setSeverity('warning');
                                }
                              }}
                              className='cursor-pointer text-green-500 p-1 h-8 w-8 focus:outline-none'
                            />
                          ) : (
                            <Edit
                              onClick={() => setEnableSlug(!enableSlug)}
                              className='cursor-pointer text-black-500 p-1 h-7 w-7'
                            />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    <p className='w-full text-xs mt-0 text-gray-400'>
                      {`Preview: ${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                        }/product/${slugName}`}{' '}
                    </p>
                    {err && !slugName && (
                      <p className='text-xs text-red-500'>{t('lang_Please_Add_Slug_Name')}</p>
                    )}
                  </div>

                  <div className='mb-4'>
                    <div className='flex flex-wrap space-x-3 mb-1 justify-between'>
                      <label htmlFor='Description'>
                        {t('lang_Short_Description')}
                        <span className='text-red-500 ms-1'>*</span>
                      </label>
                      <div className='flex items-center'>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={(e) => generateShortDesc(e)}
                        >
                          <BardSvg /> {t('lang_get_description')}
                        </span>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={(e) => handleViewHtml(e, 2)}
                        >
                          <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                        </span>
                      </div>
                    </div>
                    <ReactQuill
                      modules={module}
                      ref={ShortquillRef}
                      className='w-full h-auto rounded-[6px]'
                      theme='snow'
                      name='description'
                      value={
                        shortDescription?.length &&
                        shortDescription?.split(' ').slice(0, 280).join(' ')
                      }
                      onChange={handleShortDescQuillChange}
                      placeholder='Enter description'
                      onBlur={() =>
                        setMeta({
                          ...meta,
                          meta_description:
                            (shortDescription?.length > 0 &&
                              shortDescription
                                ?.replace(/<[^>]*>/g, ' ')
                                ?.split(' ')
                                .slice(0, 50)
                                .join(' ')) ||
                            '',
                        })
                      }
                    />
                    <div className='w-full flex justify-between'>
                      <p>
                        {t('lang_Only_Words_allowed', {
                          words: `${shortDescription?.split(/\s+/)?.filter(Boolean)?.length || 0
                            }/280`,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className='mb-4'>
                    <div className='flex flex-wrap space-x-3 justify-between items-center mb-1'>
                      <label htmlFor='Description'>{t('lang_Long_Description')}</label>
                      <div className='flex items-center'>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={(e) => generateDesc(e)}
                        >
                          <BardSvg /> {t('lang_get_description')}
                        </span>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={(e) => handleViewHtml(e, 1)}
                        >
                          <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                        </span>
                      </div>
                    </div>
                    <ReactQuill
                      modules={module}
                      className='w-full h-auto'
                      ref={quillRef}
                      theme='snow'
                      name='description'
                      value={
                        description?.length && description?.split(' ').slice(0, 5000).join(' ')
                      }
                      onChange={handleLongDescQuillChange}
                      placeholder='Enter description'
                    />
                    {desErr && description?.length === 0 && (
                      <p className='text-red-500 text-xs'>
                        {t('lang_Please_Enter_Description_For_Generate_Meta_Data')}
                      </p>
                    )}
                  </div>
                  <div className='w-full mb-4'>
                    <label>{t('lang_Product_Type')} </label>
                    <div className='relative'>
                      <select
                        className='border w-full main-border-color px-2  text-sm cursor-pointer outline-none rounded-[6px]'
                        onChange={(e) => {
                          setIsChange(true);
                          if (e.target.value === 'single') {
                            let defaulVariant = groupVariantData?.find((x) => x.default);
                            setUpdateData({
                              ...UpdateData,
                              mrp: String(defaulVariant?.price) || 0,
                              quantity: String(defaulVariant?.quantity) || 0,
                            });
                            setOpenTab(1);
                            setAdditionalProductInfo({
                              ...additionalProductInfo,
                              product_type: e.target.value,
                            });
                          } else {
                            setAdditionalProductInfo({
                              ...additionalProductInfo,
                              product_type: e.target.value,
                            });
                          }
                        }}
                        key='order_status'
                        name='order_status'
                        value={additionalProductInfo.product_type}
                      >
                        <option value='single'>{t('lang_Single_Product')}</option>
                        <option value='variant'>{t('lang_Variant_Product')}</option>
                        <option value='group'>{t('lang_Group_Product')}</option>
                        <option value='affiliate'>{t('lang_Affiliate_Product')}</option>
                        <option value='digital'>{t('lang_digital_product')}</option>
                        <option value='membership'>{t('lang_Membership')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <label className='flex select-none normal-case items-center cursor-pointer'>
                    <Checkbox
                    checked={UpdateData?.marketplace}
                    value={UpdateData?.marketplace}
                    onChange={(e) => setUpdateData({ ...UpdateData, marketplace: e.target.checked })}
                    />
                    {t('lang_MarketPlace')}
                  </label>  
                  {additionalProductInfo?.product_type === 'group' && (
                    <div className='w-full p-5 border main-border-color rounded-[6px]'>
                      <p htmlFor='lang_Group_Products' className='font-medium'>
                        {t('lang_Group_Products')}
                      </p>
                      <hr className='main-border-color mt-3'></hr>
                      {ProductStats?.selatedBundle?.length > 0 ? (
                        <div className='max-h-[450px] overflow-y-auto divide-y'>
                          {ProductStats?.selatedBundle?.map((elem, index) => {
                            return (
                              <div
                                key={index}
                                className='flex main-border-color items-center py-2 space-x-3'
                              >
                                <button
                                  onClick={(e) => removeSelectProduct(e, elem?.product, 'bundle')}
                                  className='close-btn'
                                >
                                  <X className='w-[14px] h-[14px] text-red-600' />
                                </button>
                                <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                  <img
                                    onError={(e) => {
                                      e.target.src = NOPHOTO_IMAGE;
                                    }}
                                    src={elem?.image || NOPHOTO_IMAGE}
                                    className='max-w-full max-h-full'
                                    alt={elem?.name}
                                  />
                                </div>
                                <p className='text-sm table-text'>{elem?.name}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className='text-center mt-4'>
                          <button
                            className='bulk-btn'
                            onClick={(e) => {
                              e.preventDefault();
                              setWhichProduct('bundle');
                              setIsSelectProduct(true);
                            }}
                          >
                            {t('lang_Select_Group_Products')}
                          </button>
                        </div>
                      )}
                      {ProductStats?.selatedBundle?.length > 0 && (
                        <>
                          <div className='flex items-center border-t main-border-color justify-between pt-2'>
                            <button
                              className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                              onClick={(e) => {
                                e.preventDefault();
                                setWhichProduct('bundle');
                                setIsSelectProduct(true);
                              }}
                            >
                              + {t('lang_select_more_product')}
                            </button>
                            <button
                              className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                              onClick={(e) => {
                                e.preventDefault();
                                setProductStats({ ...ProductStats, selatedBundle: [] });
                              }}
                            >
                              <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                              {t('lang_remove_all_product')}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                {additionalProductInfo?.product_type === 'affiliate' && (
                  <details
                    open
                    className=' secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'
                  >
                    <summary className='text-base flex items-center justify-between font-medium '>
                      {t('lang_Affiliate_Details')}
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </summary>
                    <div className='flex items-center mb-4 space-x-4 w-full'>
                      <div className='w-full'>
                        <label className='flex items-center' htmlFor='quantity'>
                          {t('lang_Affiliate_Button_Text')}
                          <span className='text-red-500 ms-1'>*</span>
                        </label>
                        <input
                          className={`w-full px-3 py-2 border ${err && affiliateData?.button_text === ''
                            ? 'border-red-400'
                            : 'main-border-color'
                            } rounded-[6px] focus:outline-none`}
                          type='text'
                          id='button_text'
                          name='button_text'
                          value={affiliateData?.button_text}
                          onChange={(e) => {
                            setAffiliateData({ ...affiliateData, button_text: e.target.value });
                            setIsChange(true);
                          }}
                          placeholder='e.g. Get it Here'
                        />
                        {err && affiliateData?.button_text === '' && (
                          <span className='text-xs text-red-500'>
                            {t('lang_Button_Text_Is_Required')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center mb-4 space-x-4 w-full'>
                      <div className='w-full'>
                        <label className='flex items-center' htmlFor='quantity'>
                          {t('lang_Affiliate_Url')}
                          <span className='text-red-500 ms-1'>*</span>
                        </label>
                        <input
                          className={`w-full px-3 py-2 border ${err && affiliateData?.affiliate_url === ''
                            ? 'border-red-400'
                            : 'main-border-color'
                            } rounded-[6px] focus:outline-none`}
                          type='text'
                          id='affiliate_url'
                          name='affiliate_url'
                          value={affiliateData?.affiliate_url}
                          onChange={(e) => {
                            setAffiliateData({ ...affiliateData, affiliate_url: e.target.value });
                            setIsChange(true);
                          }}
                          placeholder='e.g. Affiliate link'
                        />
                        {err && affiliateData?.affiliate_url === '' && (
                          <span className='text-xs text-red-500'>
                            {t('lang_Affiliate_URL_Is_Required')}
                          </span>
                        )}
                      </div>
                    </div>
                  </details>
                )}
                {additionalProductInfo.product_type === 'digital' && (
                  <DigitalProduct
                    isDownload={isDownload}
                    setIsDownload={setIsDownload}
                    fileType={fileType}
                    setFileType={setFileType}
                    originalFile={originalFile}
                    setOriginalFile={setOriginalFile}
                    previewFile={previewFile}
                    setPreviewFile={setPreviewFile}
                    download_btn_text={download_btn_text}
                    setDownload_btn_text={setDownload_btn_text}
                    handlePreviewFile={handlePreviewFile}
                    handleOriginalFile={handleOriginalFile}
                    expireDate={expireDate}
                    setExpireDate={setExpireDate}
                  />
                )}
                <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='w-full'>
                    <div className='text-base font-medium text-primary'>
                      {t('lang_media')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Affiliate_Upload_Details')}
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:flex gap-3 items-center'>
                    <div className='w-full lg:w-[50%]'>
                      <label htmlFor='product_name'>
                        {t('lang_Featured_Image')}
                        <span className='text-red-500 ms-1'>*</span>
                      </label>
                      <ImageUploader
                        alreadyImage={image}
                        handleSetImage={handleSetImage}
                        folder='product'
                        format='image'
                        multiple='product-featured'
                      />
                    </div>
                    <p>{t('lang_OR')}</p>
                    <div className='w-full lg:w-[50%]'>
                      <div className='flex items-center justify-between'>
                        <label htmlFor='lang_Featured_Video'>
                          {t('lang_Featured_Video')}
                          <span className='text-red-500 ms-1'>*</span>
                        </label>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setVideoURL('');
                            setIsAddVideoLink(true);
                          }}
                          className='text-link hover:underline text-sm font-medium'
                        >
                          <Tooltip
                            title={`Add link`}
                            arrow
                            placement='bottom'
                            className='ml-2 -tracking-tighter'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='20'
                              fill='currentColor'
                              class='bi bi-link'
                              viewBox='0 0 16 16'
                            >
                              <path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
                              <path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
                            </svg>
                          </Tooltip>
                        </button>
                      </div>
                      {videoURL?.length > 0 && isYoutubeUrl ? (
                        <div className='flex relative items-center h-[130px] w-[130px] justify-center border main-border-color rounded-[6px]'>
                          <img
                            onError={(e) => {
                              e.target.src = NOVIDEO_THUMBNAIL;
                            }}
                            src={
                              `https://img.youtube.com/vi/${videoURL}/maxresdefault.jpg` ||
                              NOVIDEO_THUMBNAIL
                            }
                            alt='YouTube Thumbnail'
                          />
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setVideoURL('');
                              handleSetImage('');
                            }}
                            className='absolute top-0 rounded-tr right-0 z-30 w-[25px] h-[25px] flex items-center justify-center text-red-600 primary-bg-color table-text border-s border-b main-border-color text-xs'
                          >
                            <X className='w-4 h-4' />
                          </button>
                        </div>
                      ) : (
                        <ImageUploader
                          alreadyImage={videoURL}
                          handleSetImage={handleSetImage}
                          folder='product-video'
                          format='video'
                          multiple='product-video'
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='product_name'>{t('lang_Additional_Images')}</label>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoURL('');
                          setIsAddVideoLink(true);
                        }}
                        className='text-link hover:underline text-[13px] font-medium'
                      >
                        {t('lang_Upload_Video_From_URL')}
                      </button>
                    </div>
                    {array?.length === 0 && !imagesLength && !Extraimgloader ? (
                      <>
                        <label
                          htmlFor='additional-file'
                          className={`file-tag-hover text-center flex items-center h-[200px] min-w-full rounded px-4`}
                        >
                          <div className='text-center w-full cursor-pointer'>
                            <input
                              id='additional-file'
                              type='file'
                              name='image'
                              accept={`image/jpeg, image/png, image/jpg, image/webp, image/svg, video/*`}
                              multiple
                              onChange={(e) => HandleImages(e.target.files)}
                              className='hidden'
                            />
                            <div className='w-full flex flex-col gap-1 text-center'>
                              <>
                                <CloudUpload className="w-9 mb-1 mx-auto inline-block h-9" />
                                <p className='text-sm block font-medium'>
                                  <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                                </p>
                                <p className='text-sm block font-medium'>
                                  {t('lang_Accepts_Iages_Videos')}
                                </p>
                              </>
                            </div>
                          </div>
                        </label>
                      </>
                    ) : Extraimgloader && imagesLength && array?.length === 0 ? (
                      <div className='flex items-start flex-wrap gap-3'>
                        {Array.from({ length: imagesLength }).map((_, index) => (
                          <Skeleton key={index} variant='rectangular' width={120} height={120} />
                        ))}
                      </div>
                    ) : (
                      <DragColumn {...dragProps}>
                        <div
                          className={`${array?.length === 0
                            ? 'border p-3 rounded-[6px] main-border-color hover:bg-gray-50'
                            : ''
                            } draggable-item gap-2 cursor-pointer  flex items-start flex-wrap`}
                        >
                          {array?.map((x, index) => {
                            let parts = x?.split('.');
                            let ext = parts ? parts[parts?.length - 1] : 'jpg';
                            let isYouTubeVideo;
                            if (/https?:\/\/\S+/.test(x)) {
                              isYouTubeVideo = false;
                            } else {
                              isYouTubeVideo = true;
                            }
                            return (
                              <div
                                style={{
                                  boxShadow:
                                    hoverId === index ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                                  background: 'white',
                                  display: 'flex',
                                  width: hoverId === index ? '120px' : '120px',
                                  height: hoverId === index ? '120px' : '120px',
                                }}
                                key={index}
                                className='draggable-item relative overflow-hidden flex items-center justify-center rounded-[6px] mb-2 me-2 border w-[120px] h-[120px] select-none'
                                onMouseEnter={() => {
                                  setDeletePopup({
                                    index: index,
                                    visibility: true,
                                  });
                                }}
                                onMouseLeave={() => {
                                  setDeletePopup({
                                    index: '',
                                    visibility: false,
                                  });
                                }}
                                onDragOver={() => setHoverId(index)}
                                onDragLeave={(e) => {
                                  e.preventDefault();
                                  setHoverId(null);
                                }}
                              >
                                {isYouTubeVideo ? (
                                  <>
                                    <img
                                      onError={(e) => {
                                        e.target.src = NOVIDEO_THUMBNAIL;
                                      }}
                                      src={
                                        `https://img.youtube.com/vi/${x}/maxresdefault.jpg` ||
                                        NOVIDEO_THUMBNAIL
                                      }
                                      alt='YouTube Thumbnail'
                                      className='inline-block'
                                    />
                                    <div className='h-[120px] flex items-center cursor-pointer justify-center z-10 w-[120px] absolute left-0 top-0 text-white'>
                                      <Youtube className='w-5 h-5' />
                                    </div>
                                  </>
                                ) : ext === 'mp4' ||
                                  ext === 'avi' ||
                                  ext === 'mov' ||
                                  ext === 'mkv' ||
                                  ext === 'webm' ||
                                  ext === 'mpeg' ||
                                  ext === 'flv' ||
                                  ext === 'wmv' ? (
                                  <>
                                    <video src={x} type='video/mp4' className='inline-block'></video>
                                    <div className='h-full flex items-center cursor-pointer justify-center z-10 w-full absolute left-0 top-0 text-white'>
                                      <PlayCircleFill className='w-5 h-5' />
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={x}
                                    className={'w-full inline-block max-h-full object-contain'}
                                    alt='image'
                                  ></img>
                                )}
                                {deletePopup.visibility && deletePopup.index === index && (
                                  <div className='absolute cursor-pointer left-0 top-0 z-10 h-full w-full bg-black bg-opacity-30'>
                                    <div className='top-0 right-0 absolute bg-black h-6 z-20 w-6 flex items-center justify-center'>
                                      <X
                                        className='text-white h-[14px] w-[14px]'
                                        onClick={() => {
                                          removeImages(index, x);
                                          setDeletePopup({
                                            index: '',
                                            visibility: false,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          {Extraimgloader && imagesLength ? (
                            <div className='flex items-start flex-wrap gap-3'>
                              {Array.from({ length: imagesLength }).map((_, index) => (
                                <Skeleton key={index} variant='rectangular' width={120} height={120} />
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                          <label className='inline-flex file-tag-hover relative flex-col items-center border-dashed border w-[120px] h-[120px] justify-center cursor-pointer main-border-color rounded-[6px]'>
                            <Camera className='w-7 h-7' />
                            <span className='absolute bottom-1 text-xs font-medium'>
                              {t('lang_Upload_More')}
                            </span>
                            <input
                              id='additional-file'
                              type='file'
                              name='image'
                              accept={`image/jpeg, image/png, image/jpg, image/webp, image/svg, video/*`}
                              multiple
                              onChange={(e) => HandleImages(e.target.files)}
                              className='hidden'
                            />
                          </label>
                        </div>
                      </DragColumn>
                    )}
                  </div>
                  <div className='w-full md:flex gap-3 mb-4'>
                    <div className='w-full lg:w-[50%]'>
                      <label htmlFor='lang_Product_Documentation'>{t('lang_Product_Documentation')}</label>
                      <label
                        htmlFor='lang_Product_Documentation'
                        className={`${!Documentationimage && !Documentationloader && 'file-tag-hover'} flex items-center h-[200px] min-w-full rounded px-4`}
                      >
                        <label className='text-center w-full block font-medium text-sm cursor-pointer'>
                          <input
                            type='file'
                            name='image'
                            onChange={(e) => HandleDocumentation(e.target.files[0])}
                            className='hidden'
                          />
                          <div className='items-center gap-1 flex flex-col w-full'>
                            {!Documentationimage && !Documentationloader ? (
                              <>
                                <CloudUpload className='w-9 mb-1 inline-block mx-auto h-9' />
                                <p className='text-sm block font-medium'>
                                  <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                                </p>
                                <div className='flex flex-wrap gap-1'>
                                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_PDF')}</span>
                                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_ZIP')}</span>
                                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_HTML')}</span>
                                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_DOCX')}</span>
                                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_CSV')}</span>
                                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_XLSX')}</span>
                                </div>
                              </>
                            ) : Documentationloader === true ? (
                              <div className='nb-spinner'></div>
                            ) : (
                              <div className='text-left'>
                                <p className='font-medium inline-block max-w-full text-gray-600'>
                                  {Documentationimage} {t('lang_uploaded')}
                                </p>
                                <button
                                  className='cursor-pointer inline-block text-link hover:underline text-sm font-medium transition duration-300'
                                  onClick={() => { setDocumentationimage('') }}
                                >
                                  {t('lang_Replace_Documentation')}
                                </button>
                              </div>
                            )}
                          </div>
                        </label>
                        {documentationErr && (
                          <span className='text-sm bg-yellow-100 p-1 rounded-[6px]'>
                            <b className='uppercase'>{t('lang_note')}: </b> {t('lang_File_Details')}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className='w-full lg:w-[50%]'>
                      <ProductModel
                        modelImageLoader={modelImageLoader}
                        productModelImage={productModelImage}
                        setProductModelImage={setProductModelImage}
                        modelImageErr={modelImageErr}
                        HandleModelImage={HandleModelImage}
                      />
                    </div>
                  </div>
                </div>
                <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='w-full'>
                    <div className='text-base font-medium text-primary'>
                      {t('lang_Pricing_Quantity')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Define_pricing_strategies_and_track_stock_availability')}
                      </p>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <div className='flex w-full items-center justify-between flex-col'>
                      <div className='w-full contryCode'>
                        <label
                          className='flex items-center table-text text-[13px]  mb-1'
                          htmlFor='selling_price'
                        >
                          {t('lang_Selling_Price')}
                          <span className='text-red-500 ms-1'>*</span>
                          <Tooltip
                            title='This is the net sales price for your customers.'
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </label>
                        <div className='flex items-center'>
                          <p
                            disabled
                            className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                          >
                            {GetCurrencySymbol()}
                          </p>
                          <input
                            className={
                              err && selling_price === ''
                                ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                            }
                            type='number'
                            min={1}
                            id='selling_price'
                            name='selling_price'
                            value={selling_price?.length > 0 && selling_price?.substring(0, 15)}
                            // onKeyDown={(e) => {
                            //   !(
                            //     /[0-9]/.test(e.key) ||
                            //     e.key === 'Backspace' ||
                            //     e.key === 'Delete' ||
                            //     e.key === 'ArrowLeft' ||
                            //     e.key === 'ArrowRight' ||
                            //     e.key === 'ArrowUp' ||
                            //     e.key === 'ArrowDown'
                            //   )
                            //     ? e.preventDefault()
                            //     : '';
                            // }}
                            onChange={HandleInput}
                            placeholder='Enter your selling price'
                          />
                        </div>
                        {err && selling_price === '' ? (
                          <p className='text-red-500 text-xs'>
                            {t('lang_Please_Enter_Selling_Price')}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {additionalProductInfo?.product_type !== 'variant' && (
                      <div className='w-full contryCode'>
                        <label
                          className='flex items-center table-text text-[13px]  mb-1 uppercase'
                          htmlFor='mrp'
                        >
                          {t('lang_mrp')}
                          <Tooltip
                            title={`Enter the manufacturer's recommended retail price (MRP) for the product.`}
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </label>
                        <div className='flex items-center'>
                          <p
                            disabled
                            className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                          >
                            {GetCurrencySymbol()}
                          </p>
                          <input
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            type='number'
                            min={1}
                            id='mrp'
                            name='mrp'
                            value={mrp?.length > 0 && mrp?.substring(0, 15)}
                            // onKeyDown={(e) => {
                            //   !(
                            //     /[0-9]/.test(e.key) ||
                            //     e.key === 'Backspace' ||
                            //     e.key === 'Delete' ||
                            //     e.key === 'ArrowLeft' ||
                            //     e.key === 'ArrowRight' ||
                            //     e.key === 'ArrowUp' ||
                            //     e.key === 'ArrowDown'
                            //   )
                            //     ? e.preventDefault()
                            //     : '';
                            // }}
                            onChange={HandleInput}
                            placeholder='Enter your price'
                          />
                        </div>
                      </div>
                    )}
                    {additionalProductInfo?.product_type !== 'variant' &&
                      additionalProductInfo?.product_type !== 'group' && (
                        <div className='w-full'>
                          <label
                            className='flex items-center table-text text-[13px]  mb-1'
                            htmlFor='quantity'
                          >
                            {t('lang_quantity')}
                            <span className='text-red-500 ms-1'>*</span>
                            <Tooltip
                              title='How many products should be available for sale?'
                              arrow
                              placement='right'
                              className='ml-2 -tracking-tighter'
                            >
                              <QuestionCircleFill className='info-link cursor-pointer' />
                            </Tooltip>
                          </label>
                          <input
                            className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none ${!UpdateData?.quantity?.length
                              ? ' border-red-500'
                              : 'main-border-color'
                              }`}
                            type='number'
                            min={1}
                            id='quantity'
                            name='quantity'
                            value={quantity?.length > 0 ? quantity?.substring(0, 15) : ''}
                            // onKeyDown={(e) => {
                            //   !(
                            //     /[0-9]/.test(e.key) ||
                            //     e.key === 'Backspace' ||
                            //     e.key === 'Delete' ||
                            //     e.key === 'ArrowLeft' ||
                            //     e.key === 'ArrowRight' ||
                            //     e.key === 'ArrowUp' ||
                            //     e.key === 'ArrowDown'
                            //   )
                            //     ? e.preventDefault()
                            //     : '';
                            // }}
                            onChange={HandleInput}
                            placeholder='e.g. 10,200,500'
                          />
                          {UpdateData?.quantity?.length <= 0 ? (
                            <p className='text-red-500 text-xs'>Please Enter Quantity</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    <div className='w-full'>
                      <label className='block table-text text-[13px]  mb-1' htmlFor='Product Unit'>
                        {t('lang_Product_Unit')}
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='number'
                        id='Product Unit'
                        name='product_unit'
                        value={product_unit?.length > 0 ? product_unit?.substring(0, 15) : ''}
                        onChange={HandleInput}
                        placeholder='e.g. Pieces'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='Product Unit' className='flex items-center'>
                        {t('lang_Increment_By')}
                        <Tooltip
                          title='Specify the unit of increment for the product.'
                          arrow
                          placement='right'
                          className='ml-2 -tracking-tighter'
                        >
                          <QuestionCircleFill className='info-link cursor-pointer' />
                        </Tooltip>
                      </label>
                      <input
                        className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-nonemain-border-color`}
                        type='number'
                        id='increment_by'
                        name='increment_by'
                        value={UpdateData?.increment_by}
                        onChange={(e) => {
                          if (/^\d*$/.test(e.target.value)) {
                            setUpdateData({ ...UpdateData, increment_by: e.target.value });
                          }
                        }}
                        placeholder='e.g. 1'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='barcode' className='flex items-center'>
                        {t('lang_Barcode')}
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='number'
                        id='barcode'
                        name='barcode'
                        value={UpdateData?.barcode}
                        onChange={(e) => {
                          if(e.target.value?.length <= 12) {
                            setUpdateData({ ...UpdateData, barcode: e.target.value });
                          }
                        }}
                        placeholder='e.g. 1'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='cgst' className='flex items-center'>
                        {t('lang_CGST')}
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='number'
                        id='cgst'
                        name='cgst'
                        value={UpdateData?.cgst}
                        onChange={(e) => {
                          setUpdateData({ ...UpdateData, cgst: e.target.value });
                        }}
                        placeholder='e.g. 1'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='sgst' className='flex items-center'>
                        {t('lang_SGST')}
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='number'
                        id='cgst'
                        name='cgst'
                        value={UpdateData?.sgst}
                        onChange={(e) => {
                          setUpdateData({ ...UpdateData, sgst: e.target.value });
                        }}
                        placeholder='e.g. 1'
                      />
                    </div>
                  </div>
                </div>

                {additionalProductInfo?.product_type !== 'group' && additionalProductInfo?.product_type !== 'membership' && (
                  <details className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                    <summary className='text-base flex items-center justify-between font-medium '>
                      <div>
                        {t('lang_Category_Manufacture')}
                        <p className='text-xs table-text font-normal'>
                          {t('lang_Category_Manufacture_Details')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </summary>
                    <hr className='mt-4 main-border-color' />
                    <div className='max-w-full mb-4'>
                      <label
                        className='flex items-center justify-between table-text text-[13px]  mb-1'
                        htmlFor='product_name'
                      >
                        <div className='flex items-center'>
                          {t('lang_categories')}
                          <Tooltip
                            title='Where should the product be available on your site?'
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                        <button
                          className='text-link hover:underline font-medium flex items-center rounded-[6px] space-x-2 transition-all duration-200 text-sm '
                          type='button'
                          onClick={() => setAddCategory(true)}
                        >
                          <Plus />
                          {t('lang_Add_New_Category')}
                        </button>
                      </label>
                      {selatedCategory?.length > 0 && (
                        <div className='text-end'>Select default category</div>
                      )}
                      <div className='w-full text-center'>
                        {selatedCategory?.length > 0 ? (
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {selatedCategory?.map((elem, index) => {
                              return (
                                <div
                                  key={index}
                                  className='flex items-center table-text main-border-color py-2 space-x-3 justify-between'
                                >
                                  <div className='flex items-center space-x-3'>
                                    <button
                                      className='close-btn'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemovecategory(elem._id);
                                      }}
                                    >
                                      <X className='w-[14px] h-[14px] text-red-600' />
                                    </button>
                                    <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                      <img
                                        onError={(e) => {
                                          e.target.src = NOPHOTO_IMAGE;
                                        }}
                                        src={elem?.image || NOPHOTO_IMAGE}
                                        className='max-w-full max-h-full'
                                        alt={elem?.category_name}
                                      />
                                    </div>
                                    <p className='text-sm'>{elem?.category_name}</p>
                                  </div>
                                  <div>
                                    <input
                                      id='default-radio-1'
                                      type='radio'
                                      name='default-radio'
                                      checked={defaultCategory?.id === elem?._id}
                                      onChange={() =>
                                        setDefaultCategory({
                                          id: elem?._id,
                                          name: elem?.category_name,
                                        })
                                      }
                                      className='w-4 h-4 text-black-600 primary-bg-color main-border-color'
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <button
                            className='bulk-btn mt-4'
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenCategoryOptions(true);
                            }}
                          >
                            {t('lang_Select_Categories')}
                          </button>
                        )}
                        {selatedCategory?.length > 0 && (
                          <>
                            <div className='flex items-center border-t main-border-color justify-between pt-2'>
                              <button
                                className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenCategoryOptions(true);
                                }}
                              >
                                + {t('lang_Select_More_Categories')}
                              </button>
                              <button
                                className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductStats({ ...ProductStats, selatedCategory: [] });
                                }}
                              >
                                <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                                {t('lang_remove_all_category')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='mb-4 overflow-y-auto'>
                      <label
                        className='flex items-center justify-between w-full table-text text-[13px]  mb-1'
                        htmlFor='Description'
                      >
                        {t('lang_Manufacture')}
                        <button
                          className='text-link hover:underline font-medium flex items-center rounded-[6px] space-x-2 transition-all duration-200 text-sm '
                          type='button'
                          onClick={() => setAddBrand(true)}
                        >
                          <Plus />
                          {t('lang_Add_New_Manufactures')}
                        </button>
                      </label>
                      {
                        <Autocomplete
                          className='autocomplete'
                          options={brandTest?.data}
                          getOptionLabel={(brand) => brand?.brand_name}
                          value={
                            brandTest?.data?.find((x) => x.brand_name === selectBran?.brandName) ||
                            null
                          }
                          onChange={(e, v) =>
                            v
                              ? setSelectedBrands({ brandName: v?.brand_name, brandId: v?._id })
                              : setSelectedBrands({ brandName: '', brandId: '' })
                          }
                          filterOptions={filterOptionsForBrand}
                          noOptionsText={
                            brandTest?.data?.length > 0 && !autocompleteSearch
                              ? t('lang_enter_three_characters')
                              : t('lang_Not_found')
                          }
                          renderInput={(brands) => (
                            <TextField
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              placeholder='Select manufacture'
                              value={brands?.inputValue}
                              onChange={(e) => {
                                const newInputValue = e.target.value;
                                if (
                                  typeof newInputValue === 'string' &&
                                  newInputValue?.length >= 3
                                ) {
                                  setAutocompleteSearch(newInputValue);
                                } else {
                                  setAutocompleteSearch();
                                }
                              }}
                              className='cursor-pointer'
                              {...brands}
                            />
                          )}
                        />
                      }
                    </div>
                  </details>
                )}
                {additionalProductInfo?.product_type === 'variant' && (
                  <details className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                    <summary className='text-base flex items-center justify-between font-medium '>
                      <div>
                        {t('lang_variants')}
                        <p className='text-xs table-text font-normal'>
                          {t('lang_Enhance_Your_Product_Catalog_With_Variants')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </summary>
                    <>
                      <div className='mb-4'>
                        <label htmlFor='group_name'>{t('lang_group_name')}</label>
                        <div className='relative'>
                          <select
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            id='group_name'
                            name='group_name'
                            value={groupVariant.group_name}
                            onChange={HandleVarientChange}
                            placeholder='e.g. blue,black,red'
                          >
                            <option>{t('lang_Please_Select')}</option>
                            {varientGRP.data &&
                              varientGRP.data.records.map((elem, index) => {
                                return <option key={index}>{elem.group_name}</option>;
                              })}
                          </select>
                          <div className='select-arrow'></div>
                        </div>
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='group_value'>{t('lang_group_value')}</label>
                        <div className='relative'>
                          <select
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            id='group_value'
                            name='group_value'
                            value={groupVariant.group_value}
                            onChange={HandleVarientChange}
                            placeholder='e.g. blue,black,red'
                          >
                            <option>{t('lang_Select_Group_Value')}</option>
                            {varientGRP.data &&
                              varientGRP.data.records.map((x) => {
                                if (x.group_name === groupVariant.group_name) {
                                  return (
                                    x.group_value &&
                                    x.group_value.map((y, index) => {
                                      return (
                                        <>
                                          <option key={index}>{y}</option>
                                        </>
                                      );
                                    })
                                  );
                                }
                              })}
                          </select>
                          <div className='select-arrow'></div>
                        </div>
                      </div>
                      {groupVariantData && groupVariantData?.length > 0 ? (
                        <div>
                          <div className='overflow-x-auto overflow-y-hidden add-variant-table rounded-[6px] w-full border main-border-color'>
                            <table className='w-full text-left  secondary-bg-color'>
                              <thead>
                                <tr>
                                  <td className='px-3 min-w-[150px] max-w-[150px]'>
                                    {t('lang_variants')}
                                  </td>
                                  <td className='w-full thead-image px-3 min-w-[150px]'>
                                    {t('lang_image')}
                                  </td>
                                  <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                                    {t('lang_price')}
                                  </td>
                                  <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                                    {t('lang_quantity')}
                                  </td>
                                  <td className='px-3 min-w-[150px] max-w-[150px]'>
                                    {t('lang_sku_id')}
                                  </td>
                                  <td className='px-3 text-right min-w-[100px] max-w-[100px]'>
                                    {t('lang_default')}
                                  </td>
                                  <td className='px-3 text-right min-w-[100px] max-w-[100px] capitalize'>
                                    {t('lang_remove')}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {groupVariantData.map((data, index) => {
                                  return (
                                    <tr key={index} className='w-full main-border-color'>
                                      <td className='px-3 min-w-[150px] max-w-[150px]'>
                                        {data.group.map((x, i) => {
                                          return i === 0
                                            ? `${x.group_name}:- ${x.group_value}`
                                            : ` / ${x.group_name}:- ${x.group_value}`;
                                        })}
                                        {data.new && (
                                          <p className='bg-blue-100 inline-block rounded-lg text-sm px-2 py-1 ms-2 capitalize'>
                                            {t('lang_new')}
                                          </p>
                                        )}
                                      </td>
                                      <td className='px-3 tbody-image w-full min-w-[150px]'>
                                        <div className='relative max-w-[80px] max-h-[50px]'>
                                          <label
                                            htmlFor={`file-input-${index}`} // Use a unique id for each file input
                                            className='relative w-full h-full cursor-pointer '
                                          >
                                            <input
                                              type='file'
                                              id={`file-input-${index}`} // Use a unique id for each file input
                                              className='hidden'
                                              onChange={async (e) => {
                                                await HandlevariantImage(e.target.files[0], index);
                                              }}
                                            />
                                            <div className='text-white hover:border-0 border-3 top-0 right-0 absolute bg-black h-4 w-4 flex items-center justify-center'>
                                              <Repeat className='text-white stroke-white h-3 w-3' />
                                            </div>
                                          </label>
                                          <img
                                            className='max-w-full mx-auto max-h-[50px] inline-block object-contain'
                                            src={data?.image || NOPHOTO_IMAGE}
                                            alt='image'
                                          />
                                        </div>
                                      </td>
                                      <td className='px-3 min-w-[150px] max-w-[150px]'>
                                        <input
                                          type='number'
                                          min={1}
                                          placeholder='e.g. 500'
                                          value={data.price}
                                          onChange={(e) =>
                                            handleVariantPrice(index, e.target.value)
                                          }
                                          className=' p-2 border main-border-color max-w-full primary-bg-color'
                                        />
                                      </td>
                                      {additionalProductInfo?.product_type === 'variant' && isPOS ?
                                        <td className='px-3 min-w-[200px] max-w-[200px]'>
                                          <button type='button' className='bulk-btn' onClick={() => {setOpenVariantQtyindex(index); setOpenVariantQty(true)}}>{t('lang_Set_Quantity')}</button>
                                        </td>
                                      :
                                        <td className='px-3 min-w-[150px] max-w-[150px]'>
                                          <input
                                            type='number'
                                            min={1}
                                            placeholder='Quantity'
                                            value={data.quantity}
                                            onChange={(e) => handleVariantQty(index, e.target.value)}
                                            className=' p-2 border max-w-full main-border-color primary-bg-color'
                                          />
                                        </td>
                                      }
                                      <td className='px-3 min-w-[150px] max-w-[150px]'>
                                        <input
                                          type='text'
                                          placeholder='Sku Id'
                                          value={data.sku_id}
                                          onChange={(e) =>
                                            handleVariantSkuId(index, e.target.value)
                                          }
                                          className=' p-2 border max-w-full main-border-color primary-bg-color'
                                        />
                                      </td>
                                      <td className='px-3 min-w-[100px] max-w-[100px] text-center'>
                                        <input
                                          type='radio'
                                          name='defaultVar'
                                          placeholder='Quantity'
                                          defaultChecked={data.default}
                                          onClick={(e) => {
                                            setGroupVariantData((prevState) =>
                                              prevState.map((obj, i) => ({
                                                ...obj,
                                                default: i === index ? true : false,
                                              })),
                                            );
                                          }}
                                          className=' p-2 border main-border-color primary-bg-color'
                                        />
                                      </td>
                                      <td className='px-3 min-w-[100px] max-w-[100px] text-right'>
                                        {groupVariantData?.length > 1 && data?._id ? (
                                          <button
                                            href='#'
                                            className='text-red-500 p-2 hover:shadow-lg text-xs font-medium'
                                            onClick={(e) => HandleRemoveVarient(data?._id, e)}
                                          >
                                            <Trash2 className='w-4 h-4' />
                                          </button>
                                        ) : (
                                          groupVariantData?.length > 1 && (
                                            <button
                                              href='#'
                                              className='text-red-500 p-2 hover:shadow-lg text-xs font-medium'
                                              onClick={(e) => HandleRemove(index, e)}
                                            >
                                              <Trash2 className='w-4 h-4' />
                                            </button>
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  </details>
                )}
                {(additionalProductInfo?.product_type === 'single' && additionalProductInfo?.product_type !== 'affiliate' || additionalProductInfo?.product_type === 'membership') && (
                  <details className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                    <summary className='text-base flex items-center justify-between font-medium '>
                      <div>
                        {t('lang_discounts')}
                        <p className='text-xs table-text font-normal'>
                          {t('lang_Unlock_customer_savings_with_exclusive_discounts')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </summary>
                    <>
                      <div>
                        {discountDD && (
                          <div className='pb-1'>
                            {additionalProductInfo?.product_type !== 'membership' && <div className='mb-4'>
                              <label htmlFor='quantity'>
                                {t('lang_Product_Discount_Quantity')}
                              </label>
                              <input
                                className={`w-full px-3 py-2 ${disErr && !isEditDiscount && Discount.quantity <= 0
                                  ? 'border border-red-500 '
                                  : 'border main-border-color '
                                  } rounded-[6px] focus:outline-none `}
                                type='number'
                                min={1}
                                id='quantity'
                                name='quantity'
                                value={!isEditDiscount ? Discount.quantity : ''}
                                onChange={HandleDiscountChange}
                                placeholder='e.g. 1,5,10'
                              />
                              {disErr && Discount.quantity <= 0 && !isEditDiscount && (
                                <p className='text-red-500 text-xs'>
                                  {t('lang_Please_enter_valid_discount_quantity')}
                                </p>
                              )}
                            </div>}
                            <div className='grid grid-cols-1 mb-4 sm:grid-cols-2 gap-5'>
                              <div className='w-full'>
                                <label htmlFor='product_discount_type'>
                                  {t('lang_Product_Discount_Type')}
                                  {additionalProductInfo?.product_type === 'membership' &&<span className='text-red-500 ms-1'>*</span>}
                                </label>
                                <div className='relative'>
                                  <select
                                    className={`w-full px-3 py-2 ${disErr &&
                                      !isEditDiscount &&
                                      Discount.product_discount_type === 'Select discount type'
                                      ? 'border border-red-500 '
                                      : 'border main-border-color '
                                      } rounded-[6px] focus:outline-none `}
                                    name='product_discount_type'
                                    value={!isEditDiscount ? Discount?.product_discount_type : ''}
                                    onChange={HandleDiscountChange}
                                  >
                                    <option>{t('lang_Select_Discount_Type')}</option>
                                    <option value='amount' className='capitalize'>
                                      {t('lang_amount')}
                                    </option>
                                    <option value='percentage' className='capitalize'>
                                      {t('lang_percentage')}
                                    </option>
                                  </select>
                                  <div className='select-arrow'></div>
                                </div>
                                {disErr &&
                                  !isEditDiscount &&
                                  Discount.product_discount_type === 'Select discount type' && (
                                    <p className='text-red-500 text-xs'>
                                      {t('lang_Please_Select_valid_discount_type')}
                                    </p>
                                  )}
                              </div>
                              <div className='w-full contryCode'>
                                <label htmlFor='discount_value'>
                                  {t('lang_Product_Discount_Price_Percentage')}
                                  {additionalProductInfo?.product_type === 'membership' &&<span className='text-red-500 ms-1'>*</span>}
                                </label>
                                <div className='flex items-center'>
                                  <p
                                    disabled
                                    className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                                  >
                                    {Discount?.product_discount_type === 'percentage'
                                      ? '%'
                                      : GetCurrencySymbol()}
                                  </p>
                                  <input
                                    className={`w-full px-3 py-2 ${(disErr &&
                                      !isEditDiscount &&
                                      Number(Discount?.discount_value) === 0) ||
                                      Number(Discount?.discount_value) > Number(selling_price) ||
                                      (Discount.product_discount_type === 'percentage' &&
                                        Number(selling_price) <
                                        Number(selling_price) * (Discount.discount_value / 100))
                                      ? 'border border-red-500 '
                                      : 'border main-border-color '
                                      } rounded-[6px] focus:outline-none `}
                                    type='number'
                                    min={1}
                                    id='discount_value'
                                    name='discount_value'
                                    value={!isEditDiscount ? Discount.discount_value : ''}
                                    onBlur={(e) => {
                                      if (
                                        (Discount.product_discount_type === 'percentage' &&
                                          Number(selling_price) <
                                          Number(selling_price) * (e.target.value / 100)) ||
                                        Number(selling_price) < e.target.value
                                      ) {
                                        setDisErr(true);
                                      }
                                    }}
                                    onChange={HandleDiscountChange}
                                    placeholder='Enter your discount price/percentage'
                                  />
                                </div>
                                {disErr
                                  ? !isEditDiscount &&
                                  (Number(Discount?.discount_value) === 0 ||
                                    (Discount.product_discount_type === 'percentage' &&
                                      Number(selling_price) <
                                      Number(selling_price) *
                                      (Discount.discount_value / 100)) ||
                                    Number(selling_price) < Number(Discount.discount_value)) && (
                                    <p className='text-red-500 text-xs'>
                                      {t(
                                        'lang_Discount_value_should_not_be_greater_than_selling_price',
                                      )}
                                    </p>
                                  )
                                  : ''}
                              </div>
                            </div>
                            <div className='flex w-full mb-4 space-x-4'>
                              <div className='w-full'>
                                <label>{t('lang_start_date')}{additionalProductInfo?.product_type === 'membership' &&<span className='text-red-500 ms-1'>*</span>}</label>
                                <label className='relative w-full block cursor-pointer'>
                                  <DatePicker
                                    className={`${startDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                                    selected={!isEditDiscount ? startDate : new Date()}
                                    onChange={(date) => {
                                      setStartDate(date);
                                      setEndDate(endDate < date ? date : endDate);
                                    }}
                                    selectsEnd
                                    minDate={new Date()}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat='dd-MM-yyyy'
                                  />
                                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                    <CalendarFill className='text-gray-600 w-3 h-3' />
                                  </span>
                                </label>
                              </div>
                              <div className='w-full'>
                                <label>{t('lang_end_date')}{additionalProductInfo?.product_type === 'membership' &&<span className='text-red-500 ms-1'>*</span>}</label>
                                <label className='relative block w-full cursor-pointer'>
                                  <DatePicker
                                    className={`${endDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                                    selected={!isEditDiscount ? endDate : new Date()}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    minDate={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat='dd-MM-yyyy'
                                  />
                                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                    <CalendarFill className='text-gray-600 w-3 h-3' />
                                  </span>
                                </label>
                              </div>
                            </div>
                            <button
                              onClick={NewProductDiscount}
                              disabled={additionalProductInfo?.product_type === 'membership' && productDiscount?.length >= 1}
                              className={`${additionalProductInfo?.product_type === 'membership' && productDiscount?.length >= 1 ?'cursor-not-allowed opacity-50 border border-secondary text-link' : 'secondary-btn'} inline-block text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300`}
                            >
                              {isEditDiscount ? 'Edit discount coupon' : 'Add discount coupon'}
                            </button>
                          </div>
                        )}
                        {productDiscount?.length > 0 && (
                          <>
                            <hr className='border-t my-4 main-border-color'></hr>
                            <div className='overflow-auto mt-4 add-discount-table border main-border-color rounded-[6px] w-full block'>
                              <table className='w-full text-left'>
                                <thead className='w-full  secondary-bg-color'>
                                  <tr className='w-full'>
                                    <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                                      {t('lang_type')}
                                    </td>
                                    <td className='px-3 min-w-[150px] w-full capitalize'>
                                      {t('lang_value')}
                                    </td>
                                    <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                                      {t('lang_quantity')}
                                    </td>
                                    <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                                      {t('lang_start_date')}
                                    </td>
                                    <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                                      {t('lang_end_date')}
                                    </td>
                                    <td className='px-3 min-w-[80px] max-w-[80px] text-right'>
                                      <span className='w-full text-right capitalize'>
                                        {t('lang_action')}
                                      </span>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productDiscount?.map((elem, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className='px-3 min-w-[150px] max-w-[150px]'>
                                          {elem.product_discount_type}{' '}
                                        </td>
                                        <td className='px-3 min-w-[150px] w-full'>
                                          {elem.discount_value}{' '}
                                        </td>
                                        <td className='px-3 min-w-[150px] max-w-[150px]'>
                                          {elem.quantity}{' '}
                                        </td>
                                        <td className='px-3 min-w-[150px] max-w-[150px]'>
                                          {formatDate(elem?.start_date)}
                                        </td>
                                        <td className='px-3 min-w-[150px] max-w-[150px]'>
                                          {formatDate(elem.end_date)}
                                        </td>
                                        <td className='px-3 min-w-[80px] max-w-[80px] text-right'>
                                          <div className='justify-end flex space-x-3'>
                                            {' '}
                                            <button
                                              href='#'
                                              className='text-black-500 hover:text-black-600'
                                              onClick={(e) => DiscountChange(e, elem)}
                                            >
                                              <Edit className='w-4 h-4' />
                                            </button>
                                            <>
                                              {' '}
                                              <button
                                                href='#'
                                                className='text-red-500 hover:text-red-600'
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setConfirmationPopup(true);
                                                  setDeleteId(elem?._id);
                                                }}
                                              >
                                                <Trash3Fill className='w-4 h-4' />
                                              </button>
                                            </>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </details>
                )}
                {additionalProductInfo?.product_type !== 'variant' && isPOS && <details className='bg-white table-text group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div className='text-primary'>
                      {t('lang_POS_Location')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Add_Quantity_To_Your_Locations')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <>
                    <div className='mb-4'>
                      <label htmlFor='pos_location' className='flex items-center justify-between'>
                        {t('lang_quantity')}
                        <button
                          type='button'
                          className='text-link hover:underline font-medium flex items-center rounded-[6px] space-x-2 transition-all duration-200 text-sm '
                          onClick={() => setOpenLocation(true)}
                        >
                          {t('lang_Edit_Location')}
                        </button>
                      </label>
                      {selectedLocation?.length > 0 && selectedLocation?.map((elem, index) => (
                        <div className='flex items-center justify-between' key={elem?._id || index}>
                          <label htmlFor={`pos_location_${index}`}>
                            {elem?.name}
                          </label>
                          <input
                            className='flex items-end px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            type='number'
                            id={`pos_location_${index}`}
                            value={pos_location?.find(pos => pos.pos_location === elem?._id)?.quantity || ''}
                            name='pos_location'
                            onChange={(e) => handlePOSLocation(e, elem)}
                            placeholder='e.g. 100'
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </details>}
                {additionalProductInfo?.product_type !== 'membership' && <details className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_Additional_Information')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_additional_information_product')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full'>
                    <>
                      <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 mb-4'>
                        <div className='w-full'>
                          <label className='flex table-text text-[13px]  mb-1' htmlFor='SKU ID'>
                            {t('lang_sku_id')}
                          </label>
                          <input
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            type='text'
                            id='sku_id'
                            name='sku_id'
                            value={sku_id}
                            onChange={HandleInput}
                            placeholder='e.g. 10123'
                          />
                        </div>
                        <div className='w-full'>
                          <label htmlFor='hsn_code' className='flex items-center justify-between '>
                            <p className='text-xs/[13px]'>{t('lang_HSN_Code')}</p>
                            <a
                              className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                              href='https://www.zoho.com/in/books/gst/hsn-sac-finder/'
                              rel='noreferrer'
                              target='_blank'
                            >
                              {t('lang_Get_HSN_Code')}
                            </a>
                          </label>
                          <input
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            type='number'
                            id='hsn_code'
                            name='hsn_code'
                            value={hsn_code}
                            onChange={HandleInput}
                            placeholder='e.g. 102123'
                          />
                        </div>
                        <div className='w-full'>
                          <label
                            className='flex items-center table-text text-[13px]  mb-1'
                            htmlFor='min_order_quantity'
                          >
                            {t('lang_minimum_order_quantity')}
                            <Tooltip
                              title='The minimum quantity required to buy this product (set to 1 to disable this feature). E.g. if set to 3, customers will be able to purchase the product only if they take at least 3 in quantity.'
                              arrow
                              placement='right'
                              className='ml-2 -tracking-tighter'
                            >
                              <QuestionCircleFill className='info-link cursor-pointer' />
                            </Tooltip>
                          </label>
                          <input
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            type='number'
                            min={1}
                            id='min_order_quantity'
                            name='min_order_quantity'
                            value={min_order_quantity}
                            onChange={HandleInput}
                            placeholder='e.g. 1'
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 mb-4'>
                        <div className='w-full'>
                          <label
                            className='block table-text text-[13px]  mb-1 capitalize'
                            htmlFor='model'
                          >
                            {t('lang_model')}
                          </label>
                          <input
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            type='text'
                            id='model'
                            name='model'
                            value={model}
                            onChange={HandleInput}
                            placeholder='e.g. Model name'
                          />
                        </div>
                        <div className='w-full'>
                          <label
                            className='block table-text text-[13px]  mb-1'
                            htmlFor='product_badge'
                          >
                            {t('lang_Product_Badge')}
                          </label>
                          <input
                            type='text'
                            maxLength={20}
                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                            name='product_badge'
                            value={product_badge}
                            onChange={HandleInput}
                            placeholder='e.g. Product Of The Month'
                          />
                        </div>
                        <div className='w-full'>
                          <label
                            className='block table-text text-[13px]  mb-1'
                            htmlFor='CountryOfOrigin'
                          >
                            {t('lang_country_of_origin')}
                          </label>
                          <div>
                            <Autocomplete
                              className='autocomplete cursor-pointer'
                              options={CountryCodes ? CountryCodes : []}
                              getOptionLabel={(x) => x.name}
                              onChange={(e, v) =>
                                setUpdateData({
                                  ...UpdateData,
                                  country_of_origin: v?.name ? v.name : '',
                                })
                              }
                              value={
                                CountryCodes?.find(
                                  (x) => x?.name === UpdateData?.country_of_origin,
                                ) || null
                              }
                              renderInput={(name) => (
                                <TextField
                                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                  placeholder='Country of origin'
                                  className='select-none cursor-pointer border-black'
                                  {...name}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-between space-x-4 mb-4 w-full'>
                        {additionalProductInfo?.product_type !== 'group' && (
                          <div className='w-full'>
                            <label
                              className='block table-text text-[13px] mb-1 capitalize'
                              htmlFor='Stock'
                            >
                              {t('lang_stock_status')}
                            </label>
                            <div className='relative'>
                              <select
                                className='w-full px-3 py-2 border main-border-color rounded-[6px] outline-none'
                                name='stock_status'
                                value={stock_status}
                                onChange={HandleInput}
                              >
                                <option value='in_stock'>{t('lang_in_stock')}</option>
                                <option value='out_of_stock'>{t('lang_out_of_stock')}</option>
                                <option value='available'>{t('lang_available')}</option>
                              </select>
                              <div className='select-arrow'></div>
                            </div>
                          </div>
                        )}
                        {/* <div className='w-full'>
                              <label
                                className='block table-text text-[13px]  mb-1'
                                htmlFor='status_message'
                              >
                                Status Message
                              </label>
                              <input
                                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                type='text'
                                name='status_message'
                                value={status_message}
                                onChange={HandleInput}
                                placeholder='e.g. Product Stock will be Available In 7 Days'
                              />
                            </div> */}
                        <div className='w-full'>
                          <label
                            className='block table-text text-[13px]  mb-1'
                            htmlFor='product_return_policy'
                          >
                            {t('lang_Return_Status')}
                          </label>
                          <div className='relative'>
                            <select
                              className='w-full px-3 py-2 border main-border-color rounded-[6px] outline-none'
                              name='product_return_policy'
                              value={product_return_policy}
                              onChange={HandleInput}
                            >
                              <option value='non_returnable'>{t('lang_non_returnable')}</option>
                              <option value='returnable'>{t('lang_returnable')}</option>
                            </select>
                            <div className='select-arrow'></div>
                          </div>
                        </div>
                        {product_return_policy === 'non_returnable' ? (
                          <></>
                        ) : (
                          <div className='w-full'>
                            <label
                              className='block table-text text-[13px]  mb-1'
                              htmlFor='product_replacement_days'
                            >
                              {`${t('lang_replacement_validity')} (${t('lang_days')})`}
                            </label>

                            <input
                              className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                              type='number'
                              id='product_replacement_days'
                              name='product_replacement_days'
                              value={product_replacement_days}
                              onChange={HandleInput}
                              placeholder='e.g. 2'
                            />
                          </div>
                        )}
                      </div>
                    </>
                    {alltab?.records ? (
                      <div className='w-full mb-4'>
                        <label htmlFor='product_name'>{t('lang_custom_tabs')}</label>
                        <Autocomplete
                          className='autocomplete'
                          options={alltab?.records}
                          getOptionLabel={(tab) => tab?.tab_title}
                          onChange={(e, v) => HandleTabs(v?._id, v?.tab_title)}
                          renderInput={(tabs) => (
                            <TextField
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              placeholder='Select custom tabs'
                              {...tabs}
                            />
                          )}
                        />
                        <div className='flex flex-wrap mt-1'>
                          {selectTabs?.map((elem, index) => {
                            return (
                              <div
                                key={index}
                                className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs px-3 capitalize py-1.5 rounded-[6px]'
                              >
                                <span className='line-clamp-1 table-text'>{elem?.tab_title}</span>
                                <X
                                  className='h-[14px] text-red-500 hover:text-red-700  min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                                  onClick={() => HandleRemoveTabs(elem?._id)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className='mb-4'>
                      <div className='w-full p-5 border main-border-color rounded-[6px]'>
                        <p htmlFor='lang_Related_Products' className='font-medium'>
                          {t('lang_Related_Products')}
                        </p>
                        <hr className='main-border-color mt-3'></hr>
                        {ProductStats?.selatedRelated?.length > 0 ? (
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {ProductStats?.selatedRelated?.map((elem, index) => {
                              return (
                                <div
                                  key={index}
                                  className='flex main-border-color items-center py-2 space-x-3'
                                >
                                  <button
                                    onClick={(e) =>
                                      removeSelectProduct(e, elem?.product, 'related')
                                    }
                                    className='close-btn'
                                  >
                                    <X className='w-[14px] h-[14px] text-red-600' />
                                  </button>
                                  <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                    <img
                                      onError={(e) => {
                                        e.target.src = NOPHOTO_IMAGE;
                                      }}
                                      src={elem?.image || NOPHOTO_IMAGE}
                                      className='max-w-full max-h-full'
                                      alt={elem?.name}
                                    />
                                  </div>
                                  <p className='text-sm table-text'>{elem?.name}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className='text-center mt-4'>
                            <button
                              className='bulk-btn'
                              onClick={(e) => {
                                e.preventDefault();
                                setWhichProduct('related');
                                setIsSelectProduct(true);
                              }}
                            >
                              {t('lang_Select_Related_Products')}
                            </button>
                          </div>
                        )}
                        {ProductStats?.selatedRelated?.length > 0 && (
                          <>
                            <div className='flex items-center border-t main-border-color justify-between pt-2'>
                              <button
                                className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setWhichProduct('related');
                                  setIsSelectProduct(true);
                                }}
                              >
                                + {t('lang_select_more_product')}
                              </button>
                              <button
                                className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductStats({ ...ProductStats, selatedRelated: [] });
                                }}
                              >
                                <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                                {t('lang_remove_all_product')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='mb-4'>
                      <div className='w-full p-5 border main-border-color rounded-[6px]'>
                        <p htmlFor='lang_Upsell_Products' className='font-medium'>
                          {t('lang_Upsell_Products')}
                        </p>
                        <hr className='main-border-color mt-3'></hr>
                        {ProductStats?.selatedUpsell?.length > 0 ? (
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {ProductStats?.selatedUpsell?.map((elem, index) => {
                              return (
                                <div
                                  key={index}
                                  className='flex main-border-color items-center py-2 space-x-3'
                                >
                                  <button
                                    onClick={(e) => removeSelectProduct(e, elem?.product, 'upsell')}
                                    className='close-btn'
                                  >
                                    <X className='w-[14px] h-[14px] text-red-600' />
                                  </button>
                                  <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                    <img
                                      onError={(e) => {
                                        e.target.src = NOPHOTO_IMAGE;
                                      }}
                                      src={elem?.image || NOPHOTO_IMAGE}
                                      className='max-w-full max-h-full'
                                      alt={elem?.name}
                                    />
                                  </div>
                                  <p className='text-sm table-text'>{elem?.name}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className='text-center mt-4'>
                            <button
                              className='bulk-btn'
                              onClick={(e) => {
                                e.preventDefault();
                                setWhichProduct('upsell');
                                setIsSelectProduct(true);
                              }}
                            >
                              {t('lang_Select_Upsell_Products')}
                            </button>
                          </div>
                        )}
                        {ProductStats?.selatedUpsell?.length > 0 && (
                          <>
                            <div className='flex items-center border-t main-border-color justify-between pt-2'>
                              <button
                                className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setWhichProduct('upsell');
                                  setIsSelectProduct(true);
                                }}
                              >
                                + {t('lang_select_more_product')}
                              </button>
                              <button
                                className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductStats({ ...ProductStats, selatedUpsell: [] });
                                }}
                              >
                                <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                                {t('lang_remove_all_product')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='mb-4'>
                      <div className='w-full p-5 border main-border-color rounded-[6px]'>
                        <p htmlFor='lang_Cross_Sell_Products' className='font-medium'>
                          {t('lang_Cross_Sell_Products')}
                        </p>
                        <hr className='main-border-color mt-3'></hr>
                        {ProductStats?.selatedCrossell?.length > 0 ? (
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {ProductStats?.selatedCrossell?.map((elem, index) => {
                              return (
                                <div
                                  key={index}
                                  className='flex main-border-color items-center py-2 space-x-3'
                                >
                                  <button
                                    onClick={(e) =>
                                      removeSelectProduct(e, elem?.product, 'crosssell')
                                    }
                                    className='close-btn'
                                  >
                                    <X className='w-[14px] h-[14px] text-red-600' />
                                  </button>
                                  <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                    <img
                                      onError={(e) => {
                                        e.target.src = NOPHOTO_IMAGE;
                                      }}
                                      src={elem?.image || NOPHOTO_IMAGE}
                                      className='max-w-full max-h-full'
                                      alt={elem?.name}
                                    />
                                  </div>
                                  <p className='text-sm table-text'>{elem?.name}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className='text-center mt-4'>
                            <button
                              className='bulk-btn'
                              onClick={(e) => {
                                e.preventDefault();
                                setWhichProduct('crosssell');
                                setIsSelectProduct(true);
                              }}
                            >
                              {t('lang_Select_Cross_Sell_Product')}
                            </button>
                          </div>
                        )}
                        {ProductStats?.selatedCrossell?.length > 0 && (
                          <>
                            <div className='flex items-center border-t main-border-color justify-between pt-2'>
                              <button
                                className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setWhichProduct('crosssell');
                                  setIsSelectProduct(true);
                                }}
                              >
                                + {t('lang_select_more_product')}
                              </button>
                              <button
                                className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductStats({ ...ProductStats, selatedCrossell: [] });
                                }}
                              >
                                <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                                {t('lang_remove_all_product')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label htmlFor='Description'>{t('lang_Product_Tags')}</label>
                        <p className='table-text font-normal text-xs'>
                          {t('lang_use')} <b className='capitalize'>{t('lang_enter')}</b>{' '}
                          {t('lang_key_to_separate_tags')}
                        </p>
                      </div>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='text'
                        id='product_tags'
                        name='product_tags'
                        value={tags}
                        onChange={(e) => setProductSpecs({ ...productSpecs, tags: e.target.value })}
                        onKeyDown={(e) =>
                          e.key === 'Enter' ? (e.preventDefault(), HandleArray()) : ''
                        }
                        placeholder='e.g. ProductTag1,Tag2'
                      />
                      <div className='flex flex-wrap mt-1 items-center'>
                        {TagsArray &&
                          TagsArray.map((elem, index) => {
                            return (
                              <div key={index} className='flex items-center'>
                                {elem !== '' ? (
                                  <span className='primary-bg-color flex items-center me-1 mb-2 min-w-[50px]  text-xs px-3 capitalize py-1.5 rounded-[6px]'>
                                    <span className='line-clamp-1 table-text'>{elem}</span>
                                    <X
                                      className='h-[14px] text-red-500 hover:text-red-700  min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                                      onClick={() => RemoveTag(index)}
                                    />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </details>}
                {additionalProductInfo?.product_type !== 'membership' && <SelectAttribute
                  groupAttribute={groupAttribute}
                  setGroupAttribute={setGroupAttribute}
                />}
                {additionalProductInfo?.product_type !== 'membership' && <details className=' secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <summary
                    htmlFor='Meta Tag Title'
                    className='text-base w-full flex flex-col items-center font-medium '
                  >
                    <div className='flex w-full items-center justify-between'>
                      <div>
                        <span>{t('lang_Specifications_Dimensions')}</span>
                        <p className='text-xs table-text font-normal'>
                          {t('lang_Specifications_Dimensions_Details')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </div>
                  </summary>
                  <div className='mb-4'>
                    <div className='w-full p-5 border main-border-color rounded-[6px]'>
                      <div className='flex items-center justify-between'>
                        <p className='font-medium' htmlFor='lang_Product_Dimensions'>
                          {t('lang_Product_Dimensions')}
                        </p>
                        {isDimention && (
                          <Tooltip title='Remove' arrow>
                            <button className='close-btn' onClick={() => setIsDimention(false)}>
                              <X className='w-4 h-4' />
                            </button>
                          </Tooltip>
                        )}
                      </div>
                      <div className='border-t main-border-color my-3'></div>

                      {!isDimention && (
                        <p
                          className='flex items-center space-x-2 ml-4 text-link font-medium hover:underline cursor-pointer'
                          onClick={() => setIsDimention(true)}
                        >
                          <Plus />
                          {t('lang_Add_dimension_option')}
                        </p>
                      )}
                      {isDimention && (
                        <>
                          <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                            <div className='w-full'>
                              <label htmlFor='length'>{t('lang_Length')}</label>
                              <input
                                className={
                                  err && length < 10
                                    ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                    : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                }
                                type='number'
                                min={1}
                                id='length'
                                name='length'
                                value={length}
                                onChange={HandleInput}
                                placeholder='e.g. 100'
                              />
                              {err && length < 10 ? (
                                <p className='text-red-500 text-xs'>{t('lang_Length_Details')}</p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='height'>{t('lang_Height')}</label>
                              <input
                                className={
                                  err && height < 10
                                    ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                    : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                }
                                type='number'
                                min={1}
                                id='height'
                                name='height'
                                value={height}
                                onChange={HandleInput}
                                placeholder='e.g. 100'
                              />
                              {err && height < 10 ? (
                                <p className='text-red-500 text-xs'>{t('lang_Height_Details')}</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                            <div className='w-full'>
                              <label htmlFor='breadth'>{t('lang_Breadth')}</label>
                              <input
                                className={
                                  err && breadth < 10
                                    ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                    : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                }
                                type='number'
                                min={1}
                                id='breadth'
                                name='breadth'
                                value={breadth}
                                onChange={HandleInput}
                                placeholder='e.g. 100'
                              />
                              {err && breadth < 10 ? (
                                <p className='text-red-500 text-xs'>{t('lang_Breadth_Details')}</p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='weight'>{t('lang_Weight_in_kg')}</label>
                              <input
                                className={
                                  err && weight < 0.5
                                    ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                    : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                }
                                type='number'
                                min={1}
                                id='weight'
                                name='weight'
                                value={weight}
                                onChange={HandleInput}
                                placeholder='e.g. 1'
                              />
                              {err && weight < 0.5 ? (
                                <p className='text-red-500 text-xs'>
                                  {t('lang_Weight_in_kg_Details')}
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </details>}
                {additionalProductInfo?.product_type !== 'membership' && <details className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <summary
                    htmlFor='Meta Tag Title'
                    className='text-base w-full flex flex-col items-center font-medium '
                  >
                    <div className='flex w-full items-center justify-between'>
                      <div>
                        <span>{t('lang_search_engine_listing')}</span>
                        <p className='text-xs table-text font-normal'>
                          {t('lang_search_engine_listing_detail')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </div>
                    {meta?.meta_title?.length > 0 && (
                      <div className='text-left font-normal mt-2 w-full'>
                        <p className='text-blue-700 text-base'>{meta?.meta_title}</p>
                        <p className='flex text-xs text-green-700'>
                          {`${clientInfo?.custom_domain
                            ? clientInfo?.custom_domain
                            : clientInfo?.customer_domain
                            }/product/${slugName}`}{' '}
                        </p>
                        <p
                          className='table-text text-sm'
                          dangerouslySetInnerHTML={{ __html: meta?.meta_description }}
                        ></p>
                      </div>
                    )}
                  </summary>
                  <div className='mb-4'>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='Meta Tag Title' className='flex items-center justify-between'>
                        {t('lang_meta_title')}
                      </label>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={() => {
                          generateMetaData(description, 'title');
                        }}
                      >
                        <BardSvg />
                        {t('lang_get_meta_title')}
                      </span>
                    </div>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='Meta Tag Title'
                      name='meta_title'
                      value={meta_title}
                      onChange={(e) => {
                        setMeta({ ...meta, [e.target.name]: e.target.value });
                      }}
                      placeholder='Enter meta title'
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='Meta Tag description'>{t('lang_meta_description')}</label>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={() => {
                          generateMetaData(description, 'description');
                        }}
                      >
                        <BardSvg />
                        {t('lang_get_meta_description')}
                      </span>
                    </div>
                    <textarea
                      className='w-full h-24 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='Meta Tag description'
                      name='meta_description'
                      value={meta_description}
                      onChange={(e) => {
                        setMeta({ ...meta, [e.target.name]: e.target.value });
                      }}
                      placeholder='Enter meta description'
                    />
                  </div>
                </details>}
                {isCustomField && additionalProductInfo?.product_type !== 'membership' && (
                  <UpdateCustomMetaField
                    meta_fields={meta_fields}
                    setmeta_fields={setmeta_fields}
                    heading='product'
                  />
                )}
              </div>
            )}
          </form>
        </div>
      </div>
      <VideoLinkUpload
        isAddVideoLink={isAddVideoLink}
        closeAddVideoLink={closeAddVideoLink}
        handleSetLink={handleSetLink}
      />
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-full flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
          <Link
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_to_Products')}
          </Link>
          <div className='flex space-x-3 items-center'>
            {/* {
                !isPublish &&
                <button
                  className='flex items-center secondary-btn text-white text-sm font-medium py-2.5 px-16 rounded-[6px] transition duration-300'
                  type='button'
                  onClick={(e) => HandleUpdate(e, "publish")}
                >
                  Publish product
                </button>
              } */}
            <div>
              {slugName === '' ||
                product_name === '' ||
                shortDescription === undefined ||
                shortDescription?.replace(/<[^>]*>/g, ' ')?.length < 3 ||
                selling_price === '' ||
                (!image && !videoURL) ||
                weight < 0.4 ||
                height < 10 ||
                breadth < 10 ||
                length < 10 ||
                (selatedCategory?.length > 1 && defaultCategory?.id?.length === 0) ||
                (additionalProductInfo.product_type === 'affiliate'
                  ? affiliateData?.button_text === '' || affiliateData.affiliate_url === ''
                  : false) ||
                  (additionalProductInfo?.product_type === 'membership'
                    ? productDiscount?.length <= 0
                    : false) ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{isPublish ? 'Update product' : 'Update Draft'}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[143px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                  <button
                    className='flex btn rounded-s px-5 py-2.5 text-sm items-center text-white transition duration-300'
                    onClick={(e) => HandleUpdate(e, !isPublish ? 'draft' : '')}
                  >
                    <span>{isPublish ? 'Update product' : 'Update Draft'}</span>
                  </button>
                  {scheduleTimeDate || productData?.is_draft === true ? (
                    <button
                      type='button'
                      className='flex btn border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                      onClick={handleClick}
                    >
                      <ChevronDown className='w-[18px] h-[18px] stroke-white' />
                    </button>
                  ) : (
                    <></>
                  )}
                  <div>
                    <Menu
                      id='basic-menu'
                      anchorEl={menuAnchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      className='text-sm'
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {scheduleTimeDate && isPublish && (
                        <MenuItem
                          className='text-sm block'
                          onClick={(e) => {
                            setIsSchedule(true);
                            handleCloseMenu();
                          }}
                        >
                          <Clock className='w-4 h-4' />
                          <span className='ms-2 text-sm'>{t('lang_Update_Publish_Schedule')}</span>
                        </MenuItem>
                      )}
                      {productData?.is_draft === true && (
                        <MenuItem
                          className='text-sm block'
                          onClick={(e) => HandleUpdate(e, 'publish')}
                        >
                          <svg
                            fill='currentcolor'
                            version='1.1'
                            id='Capa_1'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            width='18px'
                            height='18px'
                            viewBox='0 0 31.854 31.854'
                            xmlSpace='preserve'
                          >
                            <g>
                              <path
                                d='M15.94,11.735c0,0.588-0.476,1.064-1.065,1.064H5.298c-0.588,0-1.065-0.476-1.065-1.064c0-0.587,0.477-1.063,1.065-1.063
   h9.577C15.465,10.672,15.94,11.148,15.94,11.735z M21.52,11.087l5.342,4.791L15.535,28.505l-5.34-4.791L21.52,11.087z
    M20.008,16.188l-0.033-0.029c-0.127-0.114-0.293-0.172-0.461-0.163c-0.168,0.009-0.328,0.085-0.439,0.211l-6.079,6.776
   c-0.253,0.281-0.229,0.713,0.052,0.965c0.126,0.115,0.291,0.174,0.461,0.164c0.168-0.01,0.327-0.086,0.44-0.213l6.108-6.81
   C20.293,16.828,20.27,16.423,20.008,16.188z M21.27,13.751l-0.301,0.336c-0.111,0.126-0.17,0.292-0.162,0.461
   c0.01,0.169,0.086,0.328,0.211,0.441l0.035,0.031c0.127,0.113,0.293,0.171,0.461,0.162c0.17-0.009,0.328-0.085,0.44-0.211
   l0.728-0.811l-0.51-0.457C21.91,13.467,21.506,13.489,21.27,13.751z M22.035,10.51l5.341,4.791l1.312-1.462l-5.34-4.791
   L22.035,10.51z M31.047,11.21l-1.877,2.093l-5.341-4.79l1.88-2.093c0.247-0.28,0.6-0.448,0.975-0.468
   c0.375-0.021,0.742,0.109,1.021,0.359l3.234,2.903C31.52,9.735,31.569,10.629,31.047,11.21z M29.996,10.264l-3.233-2.902
   l-0.937,1.042l3.233,2.903L29.996,10.264z M9.557,24.428l5.339,4.791l-6.31,2.586c-0.243,0.098-0.521,0.051-0.716-0.125
   c-0.196-0.177-0.273-0.447-0.201-0.699L9.557,24.428z M10.045,26.13l-0.722,2.507l1.411,1.268l2.412-0.986L10.045,26.13z
    M8.215,24.285l-0.623,2.162H2.859c-1.332,0-2.413-1.08-2.413-2.412V6.493c0-1.332,1.08-2.413,2.413-2.413h2.94V3.906
   c0-0.492,0.399-0.89,0.89-0.89h0.74C7.414,2.909,7.406,2.781,7.406,2.661C7.406,1.194,8.599,0,10.067,0
   c1.469,0,2.661,1.194,2.661,2.661c0,0.12-0.01,0.248-0.023,0.354h0.749c0.492,0,0.89,0.399,0.89,0.89v0.174h2.87
   c1.332,0,2.412,1.081,2.412,2.413v4.63l-2.128,2.372V7.506c0-0.699-0.565-1.265-1.264-1.265h-1.891v1.201
   c0,0.491-0.399,0.89-0.89,0.89H6.69c-0.492,0-0.89-0.399-0.89-0.89V6.241H3.874c-0.699,0-1.265,0.567-1.265,1.265V23.02
   c0,0.701,0.567,1.266,1.265,1.266H8.215z M9.003,2.661c0,0.124,0.023,0.248,0.061,0.355h2.005c0.04-0.107,0.062-0.23,0.062-0.355
   c0-0.587-0.477-1.065-1.064-1.065C9.479,1.596,9.003,2.074,9.003,2.661z M14.949,16.341l0.991-1.105
   c-0.014-0.576-0.484-1.054-1.064-1.054H5.298c-0.588,0-1.065,0.493-1.065,1.082c0,0.587,0.477,1.082,1.065,1.082h9.577
   C14.9,16.344,14.925,16.342,14.949,16.341z M4.233,18.791c0,0.588,0.477,1.062,1.065,1.062H11.8l1.907-2.127H5.298
   C4.71,17.727,4.233,18.203,4.233,18.791z'
                              />
                            </g>
                          </svg>
                          <span className='ms-2 text-sm'>{t('lang_Publish_Product')}</span>
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isSchedule}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsSchedule(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-full md:w-[35rem] p-5 rounded-[6px]  secondary-bg-color shadow dark:bg-gray-700'>
          <label
            className='flex items-center justify-between  text-primary text-md font-medium mb-2'
            htmlFor='product_name'
          >
            {t('lang_Update_Publish_Schedule')}
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsSchedule(false);
              }}
              className='close-btn'
            >
              <X className='w-4 h-4' />
            </button>
          </label>
          <hr className='my-4 main-border-color' />
          <div className='w-full h-[332px]'>
            <div className='relative w-full block cursor-pointer'>
              <DatePicker
                className={`${scheduleTimeDate && "bold-date"} border main-border-color focus:outline-none focus:border-gray-400 w-full`}
                showTimeSelect
                open
                placeholderText='Select product launch date'
                minDate={new Date()}
                minTime={new Date(0, 0, 0, 0)}
                maxTime={new Date(0, 0, 0, 23, 30)}
                selected={scheduleTimeDate}
                onChange={(date) => {
                  setScheduleTimeDate(date);
                  setIsChangeDate(true);
                }}
                dateFormat='MMMM d, yyyy h:mm aa'
              />
              <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                <CalendarFill className='text-gray-600 w-3 h-3' />
              </span>
            </div>
          </div>
          <div className='flex gap-1 mb-4'>
            <input
              type='checkbox'
              checked={preOrder}
              onChange={(e) => {
                setIsChangeDate(true);
                setPreOrder(e.target.checked);
              }}
            />
            <label htmlFor='pre-order'>Pre order</label>
          </div>
          {preOrder && (
            <div className='grid grid-cols-1 sm:grid-cols-2 w-full mb-4 gap-5'>
              <div className='w-full contryCode'>
                <label htmlFor='mrp' className='flex items-center text-sm'>
                  Amount
                </label>
                <div className='flex items-center'>
                  <input
                    className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                    type='number'
                    id='discount'
                    name='discount'
                    value={preOrderAmount}
                    onChange={handlePreOrderAmount}
                    placeholder='e.g. 2000'
                  />
                  {preOrderAmount >= Number(selling_price || mrp) && (
                    <p className='text-red-500 text-xs'>
                      Pre order amount will be less than selling price
                    </p>
                  )}
                </div>
              </div>
              <div className='w-full contryCode'>
                <label className='flex items-center text-sm' htmlFor='selling_price'>
                  Percentage
                </label>
                <div className='flex items-center'>
                  <input
                    className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                    type='number'
                    id='discount'
                    name='discount'
                    value={preOrderPercentage}
                    onChange={handlePreOrderPercentage}
                    placeholder='e.g. 20%'
                  />
                </div>
                {preOrderPercentage >= 100 && (
                  <p className='text-red-500 text-xs'>
                    Pre order percentage will be less than 100%
                  </p>
                )}
              </div>
            </div>
          )}
          <button
            onClick={(e) => HandleUpdate(e, '', scheduleTimeDate)}
            className='btn text-sm text-white'
          >
            {t('lang_Update_Publish_Schedule')}
          </button>
        </div>
      </Dialog>
      <Dialog
        open={isEditDiscount}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setIsEditDiscount(false);
          setDiscount({
            quantity: '',
            discount_value: '',
            product_discount_type: `Select discount type`,
          });
          setStartDate(new Date());
          setEndDate(new Date());
        }}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[350px] md:w-[600px]  table-text secondary-bg-color dark:bg-gray-700 p-5'>
          <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
            <h6 className='capitalize font-medium text-primary'>
              {t('lang_Edit_Product_Discount')}
            </h6>
            <button
              type='button'
              className='close-btn'
              onClick={() => {
                setIsEditDiscount(false);
                setDiscount({
                  quantity: '',
                  discount_value: '',
                  product_discount_type: `Select discount type`,
                });
                setStartDate(new Date());
                setEndDate(new Date());
              }}
            >
              <X className='h-4 w-4' aria-hidden='true' />
            </button>
          </div>
          <form>
            {additionalProductInfo?.product_type !== 'membership' && <div className='mb-4'>
              <label htmlFor='quantity'>{t('lang_Product_Discount_Quantity')}</label>
              <input
                className={`w-full px-3 py-2 ${disErr && Discount.quantity <= 0
                  ? 'border border-red-500 '
                  : 'border main-border-color '
                  } rounded-[6px] focus:outline-none `}
                type='number'
                min={1}
                id='quantity'
                name='quantity'
                value={Discount.quantity}
                onChange={HandleDiscountChange}
                placeholder='e.g. 1,5,10'
              />
              {disErr && Discount.quantity <= 0 && (
                <p className='text-red-500 text-xs'>
                  {t('lang_Please_enter_valid_discount_quantity')}
                </p>
              )}
            </div>}
            <div className='grid grid-cols-1 mb-4 sm:grid-cols-2 gap-5'>
              <div className='w-full'>
                <label htmlFor='product_discount_type'>{t('lang_Product_Discount_Type')}</label>
                <div className='relative'>
                  <select
                    className={`w-full px-3 py-2 ${disErr && Discount.product_discount_type === 'Select discount type'
                      ? 'border border-red-500 '
                      : 'border main-border-color '
                      } rounded-[6px] focus:outline-none `}
                    name='product_discount_type'
                    value={Discount?.product_discount_type}
                    onChange={HandleDiscountChange}
                  >
                    <option>{t('lang_Select_Discount_Type')}</option>
                    <option value='amount' className='capitalize'>
                      {t('lang_amount')}
                    </option>
                    <option value='percentage' className='capitalize'>
                      {t('lang_percentage')}
                    </option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
                {disErr && Discount.product_discount_type === 'Select discount type' && (
                  <p className='text-red-500 text-xs'>
                    {t('lang_Please_Select_valid_discount_type')}
                  </p>
                )}
              </div>
              <div className='w-full contryCode'>
                <label htmlFor='discount_value'>
                  {t('lang_Product_Discount_Price_Percentage')}
                </label>
                <div className='flex items-center'>
                  <p
                    disabled
                    className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                  >
                    {Discount?.product_discount_type === 'percentage' ? '%' : GetCurrencySymbol()}
                  </p>
                  <input
                    className={`w-full px-3 py-2 ${(disErr && Number(Discount?.discount_value) === 0) ||
                      Number(Discount?.discount_value) > Number(selling_price) ||
                      (Discount.product_discount_type === 'percentage' &&
                        Number(selling_price) <
                        Number(selling_price) * (Discount.discount_value / 100))
                      ? 'border border-red-500 '
                      : 'border main-border-color '
                      } rounded-[6px] focus:outline-none `}
                    type='number'
                    min={1}
                    id='discount_value'
                    name='discount_value'
                    value={Discount.discount_value}
                    onBlur={(e) => {
                      if (
                        (Discount.product_discount_type === 'percentage' &&
                          Number(selling_price) < Number(selling_price) * (e.target.value / 100)) ||
                        Number(selling_price) < e.target.value
                      ) {
                        setDisErr(true);
                      }
                    }}
                    onChange={HandleDiscountChange}
                    placeholder='Enter your discount price/percentage'
                  />
                </div>
                {disErr
                  ? (Number(Discount?.discount_value) === 0 ||
                    (Discount.product_discount_type === 'percentage' &&
                      Number(selling_price) <
                      Number(selling_price) * (Discount.discount_value / 100)) ||
                    Number(selling_price) < Number(Discount.discount_value)) && (
                    <p className='text-red-500 text-xs'>
                      {t('lang_Discount_value_should_not_be_greater_than_selling_price')}
                    </p>
                  )
                  : ''}
              </div>
            </div>
            <div className='flex w-full mb-4 space-x-4'>
              <div className='w-full'>
                <label>{t('lang_start_date')}</label>
                <label className='relative w-full block cursor-pointer'>
                  <DatePicker
                    className={`${startDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate(endDate < date ? date : endDate);
                    }}
                    selectsEnd
                    minDate={new Date()}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='dd-MM-yyyy'
                  />
                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                    <CalendarFill className='text-gray-600 w-3 h-3' />
                  </span>
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_end_date')}</label>
                <label className='relative block w-full cursor-pointer'>
                  <DatePicker
                    className={`${endDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    minDate={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='dd-MM-yyyy'
                  />
                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                    <CalendarFill className='text-gray-600 w-3 h-3' />
                  </span>
                </label>
              </div>
            </div>
            <button
              onClick={NewProductDiscount}
              className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
            >
              {t('lang_Edit_discount_coupon')}
            </button>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={addBrand}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddBrand(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='p-5 w-[500px] secondary-bg-color'>
          <div className='flex items-center table-text justify-between'>
            <h6 className='text-xl  font-medium  text-primary'>{t('lang_add_manufacture')}</h6>
            <button onClick={() => setAddBrand(false)} className='close-btn'>
              <X className='w-4 h-4' />
            </button>
          </div>
          <hr className='my-4 main-border-color'></hr>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full'
          >
            <div className='mb-4'>
              <label className='flex text-[13px] font-medium mb-1 capitalize' htmlFor='brand_name'>
                {t('lang_manufacture_name')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className={`w-full px-3 py-2 border ${err && !manufacturerdata ? 'border-red-500' : 'main-border-color '
                  } rounded-[6px]  focus:outline-none`}
                type='text'
                id='brand_name'
                name='brand_name'
                value={manufacturerdata}
                ref={brandRef}
                onChange={(e) => setManufacturerData(e.target.value)}
                placeholder='ex.Tech'
                required
              />
              {err && !manufacturerdata && (
                <span className='text-red-500 text-sm'>{t('lang_Brand_Name_Is_Required')}</span>
              )}
            </div>

            <div className='mb-4'>
              <label className='flex text-[13px] font-medium mb-1' htmlFor='image'>
                {t('lang_manufacture_image')} <span className='text-red-500 ms-1'>*</span>
              </label>
              <ImageUploader
                alreadyImage={brandimage}
                handleSetImage={handleSetImage}
                folder='manufacture'
                format='image'
                multiple='manufacture'
              />
            </div>
            {!manufacturerdata || !brandimage || brandimage === '' ? (
              <button
                className='w-full block bg-btn opacity-50 cursor-not-allowed text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300'
                type='button'
                disabled
              >
                {t('lang_add_manufacture')}
              </button>
            ) : (
              <button
                className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300'
                type='submit'
                onClick={HandleManufacturerSubmit}
              >
                {t('lang_add_manufacture')}
              </button>
            )}
          </form>
        </div>
      </Dialog>
      <Dialog
        open={addCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddCategory(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='p-5 w-[500px] secondary-bg-color table-text'>
          <div className='flex items-center  text-primary justify-between'>
            <h6 className='text-xl  font-medium'>{t('lang_add_category')}</h6>
            <button onClick={() => setAddCategory(false)} className='close-btn'>
              <X className='w-4 h-4' />
            </button>
          </div>
          <hr className='my-4 main-border-color'></hr>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='get'
            className='w-full'
          >
            <div className='mb-4'>
              <label htmlFor='category_name'>
                {t('lang_category_name')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className={`w-full px-3 py-2 border main-border-color rounded-[6px]  focus:outline-none`}
                type='text'
                id='category_name'
                name='category_name'
                value={Catdata}
                ref={catRef}
                onChange={(e) => setCatData(e.target.value)}
                placeholder='e.g. Tech'
                required
              />
            </div>
            <label className='mb-4'>
              <label htmlFor='image'>{t('lang_category_image')}</label>
              <ImageUploader
                alreadyImage={catimage}
                handleSetImage={handleSetImage}
                folder='category'
                format='image'
                multiple='category'
              />
            </label>
            {!Catdata ? (
              <button
                className='w-full block bg-btn opacity-50 cursor-not-allowed text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300'
                type='button'
                disabled
              >
                {t('lang_add_category')}
              </button>
            ) : (
              <button
                className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300'
                type='button'
                onClick={(e) => HandleCatSubmit(e)}
              >
                {t('lang_add_category')}
              </button>
            )}
          </form>
        </div>
      </Dialog>
      <Dialog
        open={html?.htmlPP}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHtml({ ...html, htmlPP: false })}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-full md:w-[35rem]  p-5 rounded-[6px]  secondary-bg-color shadow dark:bg-gray-700'>
          <div>
            <label
              className='flex items-center justify-between  text-primary text-md font-medium mb-2'
              htmlFor='product_name'
            >
              {t('lang_source_code')}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    window.confirm(
                      'Are You Sure to Leave?. Your recent changes will not be included.',
                    )
                  ) {
                    setHtml({ ...html, htmlPP: false });
                    setDescType();
                  }
                }}
                className='close-btn'
              >
                <X className='w-4 h-4' />
              </button>
            </label>
            <hr className='my-4 main-border-color' />
            <textarea
              className='w-full block px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
              type='text'
              rows={16}
              value={html?.code}
              onChange={(e) => setHtml({ ...html, code: e.target.value })}
              placeholder='<p>Code here<p/>'
            />
          </div>
          <button
            className='btn font-medium text-sm mt-4 w-full text-white px-3 py-2 rounded-[6px] mx-auto'
            onClick={() => saveHTML(html?.code)}
          >
            {t('lang_Edit_Source_Code')}
          </button>
        </div>
      </Dialog>
      {openCategoryOptions && (
        <SelectCategories
          openCategoryOptions={openCategoryOptions}
          closeCategoryModal={closeCategoryModal}
          api={`/admin/category/GetCategoriesWithAllParent`}
          alreadySelectedCategories={selatedCategory}
          getSelectedCategory={getSelectedCategory}
        />
      )}
      <SelectLocation
        openLocation={openLocation}
        closeLocationModal={closeLocationModal}
        alreadySelected={selectedLocation}
        locationData={locationData}
        getSelectedLocation={getSelectedLocation}
        isEdit={true}
      />
      <SelectVariantQuantity
        openVariantQty={openVariantQty}
        locationData={locationData}
        closeVariantQtyModal={closeVariantQtyModal}
        groupVariantData={groupVariantData}
        setGroupVariantData={setGroupVariantData}
        openVariantQtyindex={openVariantQtyindex}
        alreadySelected={groupVariantData}
      />
      {/* {reGenerateProcessing &&
        <Dialog
          open={reGenerateProcessing}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='w-full secondary-bg-color sm:w-[600px] p-6 rounded-[6px]'>
            <div className='flex text-primary items-center justify-between'>
              <p className='text-base table-text'>{t('lang_Please_wait_a_minitues_while_image_re-generating')}</p>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <LinearWithValueLabel progress={reGenerateProcess} />
            <p className='text-base table-text'>{t('lang_Do_not_leave_the_window_while_processing')}</p>
          </div>
        </Dialog>
      } */}
    </>
  );
};
export default UpdateProduct;
