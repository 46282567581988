import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import DynamicInputField from '../../controllers/DynamicInputField';

function AddForms() {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [description, setDescription] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [formData, setFormData] = useState({
    title: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [err, setErr] = useState(false);
  const strippedContent = description?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        title: formData ? formData.title : '',
        form: JSON.stringify(fieldArray),
      });
      await API.post('/admin/form/addform', payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/forms',
        title: 'add forms',
      });
      navigate('/forms');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    if (description?.length > 0 || formData.title?.length > 0) {
      setSaveChanges({
        backLink: '/forms',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add forms',
      });
    } else {
      setSaveChanges({
        backLink: '/forms',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add forms',
      });
    }
  }, [description, formData]);
  const checkChanges = () => {
    if (description?.length > 0 || formData.title?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/forms',
        title: 'add forms',
      });
    } else {
      navigate('/forms');
    }
  };

  const [activeTab, setActiveTab] = useState('Text');
  const [fieldArray, setFieldArray] = useState([]);

  const handleTabClick = (e, tabName) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveTab(tabName);
  };
  console.log('Field values:', fieldArray);

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center pb-[30px] space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dynamic_forms'), path: '/forms' },
                { name: t('lang_Add_New_dynamic_forms'), path: '/forms/add-form' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_dynamic_forms')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color shadow  rounded-[6px] p-5 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='title' className='capitalize'>
                  {t('lang_title')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={
                    err && formData?.title?.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-md focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-md focus:outline-none`
                  }
                  type='text'
                  name='title'
                  placeholder='Enter form title'
                  value={formData.title}
                  onChange={(e) => handleChange(e)}
                />
                {err && formData?.title?.length === 0 ? (
                  <p className='text-red-500 text-sm font-medium'>
                    {t('lang_Please_Enter_form_Title')}
                  </p>
                ) : (
                  <></>
                )}
              </div>

              <div className='text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700'>
                <DynamicInputField
                  activeTab={activeTab}
                  handleTabClick={handleTabClick}
                  setFieldArray={setFieldArray}
                />
              </div>

              <div>
                {fieldArray?.map((formField, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className='mb-5 mt-5'>
                          <label
                            htmlFor='fieldType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                          >
                            {formField?.labelValue}
                          </label>
                          {formField?.type === 'textarea' ? (
                            <textarea
                              type={formField?.type ? formField?.type : ''}
                              name={formField?.name ? formField?.name : ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                              required={!!formField?.required}
                              maxLength={formField?.maxLength ? formField?.maxLength : ''}
                              minLength={formField?.minLength ? formField?.minLength : ''}
                              placeholder={formField?.placeholder ? formField?.defaultValue : ''}
                              autoComplete={formField?.autoComplete ? 'on' : 'off'}
                              max={formField?.max ? formField?.max : ''}
                              min={formField?.min ? formField?.min : ''}
                            ></textarea>
                          ) : formField?.type === 'select' ? (
                            <>
                              <div className='mb-5 mt-2'>
                                <select
                                  name={formField?.name ? formField.name : ''}
                                  type={formField?.type ? formField?.type : ''}
                                  required={!!formField?.required}
                                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                >
                                  {formField?.options?.map((val, index) => {
                                    return (
                                      <option key={index} value={val}>
                                        {val}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </>
                          ) : formField?.type === 'checkbox' || formField?.type === 'radio' ? (
                            <>
                              <div className='mb-5 mt-2'>
                                {formField?.options?.map((val, index) => {
                                  return (
                                    <div className='flex items-center mb-4' key={index}>
                                      <input
                                        type={formField?.type ? formField?.type : ''}
                                        name={formField?.name ? formField.name : ''}
                                        required={!!formField?.required}
                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                      />
                                      <label
                                        htmlFor='default-checkbox'
                                        className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                      >
                                        {val}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : formField?.type === 'acceptence' ? (
                            <>
                              <div className='mb-5 mt-2'>
                                <div className='flex items-center mb-4'>
                                  <input
                                    type='checkbox'
                                    name={formField?.name ? formField?.name : ''}
                                    required={!!formField?.required}
                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                  />
                                  <label
                                    htmlFor='default-checkbox'
                                    className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                  >
                                    {formField?.condition}
                                  </label>
                                </div>
                              </div>
                            </>
                          ) : formField?.type === 'quiz' ? (
                            <>
                              <div className='mb-5 mt-2'>
                                <label
                                  htmlFor='fieldType'
                                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                >
                                  {formField?.question}
                                </label>
                                {formField?.options?.map((val, index) => {
                                  return (
                                    <div className='flex items-center mb-4' key={index}>
                                      <input
                                        type='radio'
                                        name={formField?.name ? formField?.name : ''}
                                        required={!!formField?.required}
                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                      />
                                      <label
                                        htmlFor='default-checkbox'
                                        className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                      >
                                        {val}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : formField?.type === 'submit' ? (
                            <>
                              <div className='mb-5 mt-2'>
                                <button
                                  type={formField?.type ? formField?.type : 'button'}
                                  name={formField?.name ? formField?.name : ''}
                                  className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                >
                                  {formField?.buttonName}
                                </button>
                              </div>
                            </>
                          ) : formField?.type === 'date' ? (
                            <>
                              <label className='block mt-4 mb-1 text-sm font-medium text-gray-700 dark:text-gray-300'>
                                Select Min Date
                              </label>
                              <input
                                type='date'
                                name={`${formField?.name}-min`}
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                required={!!formField?.required}
                                defaultValue={
                                  formField?.defaultValue ? formField?.defaultValue : formField?.min
                                }
                                min={formField?.min ? formField?.min : ''}
                              />

                              <label className='block mt-4 mb-1 text-sm font-medium text-gray-700 dark:text-gray-300'>
                                Select Max Date
                              </label>
                              <input
                                type='date'
                                name={`${formField?.name}-max`}
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                required={!!formField?.required}
                                defaultValue={formField?.max ? formField?.max : ''}
                                max={formField?.max ? formField?.max : ''}
                              />
                            </>
                          ) : (
                            <input
                              type={formField?.type ? formField?.type : ''}
                              name={formField?.name ? formField?.name : ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                              required={!!formField?.required}
                              maxLength={formField?.maxLength ? formField?.maxLength : ''}
                              minLength={formField?.minLength ? formField?.minLength : ''}
                              defaultValue={
                                formField?.placeholder === false ? formField.defaultValue : ''
                              }
                              placeholder={formField?.placeholder ? formField?.defaultValue : ''}
                              autoComplete={formField?.autoComplete ? 'on' : 'off'}
                              max={formField?.max ? formField?.max : ''}
                              min={formField?.min ? formField?.min : ''}
                              accept={
                                formField?.fileTypes ? formField?.fileTypes.replace(/\|/g, ',') : ''
                              }
                              limit={formField?.limit ? formField?.limit : ''}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_go_to_dynamic_forms')}
            </Link>
            {formData?.title === '' || trimmedContent === '' ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_dynamic_forms')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[150px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_dynamic_forms')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddForms;
