import React, { useState, useEffect } from 'react';
import SvgSection from './SvgSection';
import { mapPositionToCSS } from './DynamicEditorSwitchCase';

const StarRating = ({ initialRate, totalRate, svgData, obj }) => {
  const [rating, setRating] = useState(initialRate || 0);
  const totalStars = totalRate || 5;

  useEffect(() => {
    setRating(initialRate);
  }, [initialRate]);

  return (
    <div
      className='flex space-x-1'
      style={{
        gap: `${obj?.style?.icon_spacing}px`,
      }}
    >
      {Array.from({ length: totalStars }, (_, index) => (
        <div key={index} className='cursor-pointer'>
          {svgData &&
            (React.isValidElement(svgData) ? (
              React.cloneElement(svgData, {
                width: obj?.style?.icon_size ? obj?.style?.icon_size : '24px',
                height: obj?.style?.icon_size ? obj?.style?.icon_size : '24px',
                fill:
                  index < rating
                    ? obj?.style?.icon_color
                      ? obj?.style?.icon_color
                      : '#f0ad4e'
                    : obj?.style?.unmarked_color
                    ? obj?.style?.unmarked_color
                    : '#ccd6df',
              })
            ) : (
              <SvgSection
                svgContent={svgData}
                width={obj?.style?.icon_size ? obj?.style?.icon_size : '24px'}
                height={obj?.style?.icon_size ? obj?.style?.icon_size : '24px'}
                fill={
                  index < rating
                    ? obj?.style?.icon_color
                      ? obj?.style?.icon_color
                      : '#f0ad4e'
                    : obj?.style?.unmarked_color
                    ? obj?.style?.unmarked_color
                    : '#ccd6df'
                }
                stroke={
                  index < rating
                    ? obj?.style?.icon_color
                      ? obj?.style?.icon_color
                      : '#f0ad4e'
                    : obj?.style?.unmarked_color
                    ? obj?.style?.unmarked_color
                    : '#ccd6df'
                }
              />
            ))}
        </div>
      ))}
    </div>
  );
};

export default StarRating;
