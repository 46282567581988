import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import GenerateLiveEditiorTitle from '../../../../controllers/GenerateLiveEditiorTitle';

const EditProductBrand = ({ productPageData, handleEditProductPageData, handleUpdateSetting,section_type }) => {
  const getAiGeneratedTitle = (value) => {
    handleEditProductPageData({ ...productPageData, categoryTitleText: value })
  }
  const getAiGeneratedTitleBrand = (value) => {
    handleEditProductPageData({ ...productPageData, brandTitleText: value })
  }
  return (
    <div className='grid grid-cols-1 gap-5 w-full mb-4'>
      <label className='select-none cursor-pointer items-start text-sm table-text flex'>
        <Checkbox
          value={productPageData?.showProductCategory}
          checked={productPageData?.showProductCategory}
          onChange={() =>
            handleEditProductPageData({
              ...productPageData,
              showProductCategory: Boolean(!productPageData?.showProductCategory),
            })
          }
        />
        <div className='mt-2'>
          <span className='font-medium'>{t('lang_Show_Category_Section')}</span>
          <p className='text-xs table-text'>{t('lang_Show_Category_Section_Details')}</p>
        </div>
      </label>
      <div>
        <label htmlFor='group title' className='flex justify-between'>
        <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
          {t('lang_Category_Text')}
        </label>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <input
          type='text'
          value={productPageData?.categoryTitleText}
          onChange={(e) =>
            handleEditProductPageData({ ...productPageData, categoryTitleText: e.target.value })
          }
          className='w-full border main-border-color outline-none mt-2'
        />
      </div>
      <hr className='main-border-color' />
      <label className='select-none cursor-pointer items-start text-sm table-text flex'>
        <Checkbox
          value={productPageData?.showProductBrand}
          checked={productPageData?.showProductBrand}
          onChange={() =>
            handleEditProductPageData({
              ...productPageData,
              showProductBrand: Boolean(!productPageData?.showProductBrand),
            })
          }
        />
        <div className='mt-2'>
          <span className='font-medium'>{t('lang_Show_Brand_Section')}</span>
          <p className='text-xs table-text'>{t('lang_Show_Brand_Section_Details')}</p>
        </div>
      </label>
      <div>
        <label htmlFor='group title' className='flex justify-between'>
        <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
          {t('lang_Brand_Text')}
        </label>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitleBrand}
            section_type={section_type}
          />
        </label>
        <input
          type='text'
          value={productPageData?.brandTitleText}
          onChange={(e) =>
            handleEditProductPageData({ ...productPageData, brandTitleText: e.target.value })
          }
          className='w-full border main-border-color outline-none mt-2'
        />
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          onClick={handleUpdateSetting}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductBrand;
