import { Tooltip } from '@mui/material';
import { ChevronDown, Link } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import ColorPicker from '../../../../Color/ColorPicker';

const EditFirstStatisticStyleContainer = ({
  firstStatisticDesign,
  setFirstStatisticDesign,
  section_id,
  updateSectionStyle,
  previewImage,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  const [openState, setOpenState] = useState('');
  const [showContent, setShowContent] = useState('');

  const dropDownValue = [
    { value: { width: '150', height: '150' }, label: 'Thumbnail - 150 X 150' },
    { value: { width: '300', height: '300' }, label: 'Medium - 300 X 300' },
    { value: { width: '768', height: '0' }, label: 'Medium Large - 768 X 0' },
    { value: { width: '1024', height: '1024' }, label: 'Large - 1024 X 1024' },
  ];

  const positionValue = [
    { value: 'default', label: 'Default' },
    { value: 'center-center', label: 'Center Center' },
    { value: 'center-left', label: 'Center Left' },
    { value: 'center-right', label: 'Center Right' },
    { value: 'top-center', label: 'Top Center' },
    { value: 'top-left', label: 'Top Left' },
    { value: 'top-right', label: 'Top Right' },
    { value: 'bottom-center', label: 'Bottom Center' },
    { value: 'bottom-left', label: 'Bottom Left' },
    { value: 'bottom-right', label: 'Bottom Right' },
  ];

  const handleSizeChange = (e) => {
    const selectedSize = JSON.parse(e.target.value);
    setFirstStatisticDesign({ ...firstStatisticDesign, background_image_size: selectedSize });
    updateSectionStyle(section_id, { background_image_size: selectedSize });
  };

  const handlePositionChange = (e) => {
    const selectedSize = JSON.parse(e.target.value);
    setFirstStatisticDesign({ ...firstStatisticDesign, background_position: selectedSize });
    updateSectionStyle(section_id, { background_position: selectedSize });
  };

  const currentImageSizeString = JSON.stringify(firstStatisticDesign?.background_image_size);
  const currentPositionString = JSON.stringify(firstStatisticDesign?.background_position);

  const handleImageUpload = async(event, section_id) => {
    try {
      const newImage = await handleBackgroundImageUpload(event);
      setFirstStatisticDesign({ ...firstStatisticDesign, background_image: newImage });
      updateSectionStyle(section_id, { background_image: newImage });
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleMarginChange = (value) => {
    if (firstStatisticDesign?.margin_all === 'block') {
      setFirstStatisticDesign({
        ...firstStatisticDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
      updateSectionStyle(section_id, {
        ...firstStatisticDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
    } else {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handlePaddingChange = (value) => {
    if (firstStatisticDesign?.padding_all === 'block') {
      setFirstStatisticDesign({
        ...firstStatisticDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
      updateSectionStyle(section_id, {
        ...firstStatisticDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
    } else {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleBorderChange = (value) => {
    if (firstStatisticDesign?.border_all === 'block') {
      setFirstStatisticDesign({
        ...firstStatisticDesign,
        border_top: value,
        border_right: value,
        border_bottom: value,
        border_left: value,
      });
      updateSectionStyle(section_id, {
        ...firstStatisticDesign,
        border_top: value,
        border_right: value,
        border_bottom: value,
        border_left: value,
      });
    } else {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleBorderRadiusChange = (value) => {
    if (firstStatisticDesign?.border_radius_all === 'block') {
      setFirstStatisticDesign({
        ...firstStatisticDesign,
        border_radius_top: value,
        border_radius_right: value,
        border_radius_bottom: value,
        border_radius_left: value,
      });
      updateSectionStyle(section_id, {
        ...firstStatisticDesign,
        border_radius_top: value,
        border_radius_right: value,
        border_radius_bottom: value,
        border_radius_left: value,
      });
    } else {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleColorChange = (value) => {
    if (openState === 'border_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          border_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'background') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          background: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'box_shadow_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          box_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'gradient_color_start') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          gradient_color_start: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'gradient_color_end') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          gradient_color_end: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'title_font_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          title_font_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'title_stroke_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          title_stroke_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'title_text_shadow_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          title_text_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'description_font_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          description_font_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'description_stroke_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          description_stroke_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'description_text_shadow_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          description_text_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'role_font_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          role_font_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'role_stroke_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          role_stroke_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'role_text_shadow_color') {
      setFirstStatisticDesign((prevState) => {
        const updateValue = {
          ...prevState,
          role_text_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleCloseColor = () => {
    setOpenState('');
  };

  const handleHeadingBorderStyleChange = (value) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        border_style: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBgRepeatChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_repeat: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBgAttachmentChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_attachment: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBgFitChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_fit: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleShowContent = (value) => {
    setShowContent(value);
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_gradient: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowBlurChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_blur: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowHorizontalChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_horizontal: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowVerticalChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_vertical: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowSpreadChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_spread: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleStartPositionChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        gradient_start_position: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleEndPositionChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        gradient_end_position: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleGradientAngleChange = (e) => {
    setFirstStatisticDesign((prevState) => {
      const updateValue = {
        ...prevState,
        gradient_angle: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  return (
    <div className='relative'>
      <div className='editor-details py-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_spacing')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='2xl:flex justify-between mt-2 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_margin')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.margin_top}
                  onChange={(e) =>
                    handleMarginChange(
                      firstStatisticDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Top</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.margin_right}
                  onChange={(e) =>
                    handleMarginChange(
                      firstStatisticDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Right</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.margin_bottom}
                  onChange={(e) =>
                    handleMarginChange(
                      firstStatisticDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Bottom</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.margin_left}
                  onChange={(e) =>
                    handleMarginChange(
                      firstStatisticDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Left</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    firstStatisticDesign?.margin_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setFirstStatisticDesign((prevState) => ({
                      ...prevState,
                      margin_all: prevState.margin_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
          <div className='2xl:flex justify-between mt-3 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>Padding</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.padding_top}
                  onChange={(e) =>
                    handlePaddingChange(
                      firstStatisticDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.padding_right}
                  onChange={(e) =>
                    handlePaddingChange(
                      firstStatisticDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.padding_bottom}
                  onChange={(e) =>
                    handlePaddingChange(
                      firstStatisticDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={firstStatisticDesign?.padding_left}
                  onChange={(e) =>
                    handlePaddingChange(
                      firstStatisticDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    firstStatisticDesign?.padding_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setFirstStatisticDesign((prevState) => ({
                      ...prevState,
                      padding_all: prevState.padding_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_background')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_background_type')}
              </p>
              <ul className='grid grid-cols-2 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <Tooltip title='Classic' arrow>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        showContent === 'classic'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleShowContent('classic')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-brush-fill'
                        viewBox='0 0 16 16'
                      >
                        <path d='M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Gradient' arrow>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        showContent === 'gradient'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleShowContent('gradient')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-shadows'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8 7a.5.5 0 0 1 0-1h3.5q.048 0 .093.009A7 7 0 0 0 12.9 13H8a.5.5 0 0 1 0-1h5.745q.331-.474.581-1H8a.5.5 0 0 1 0-1h6.71a7 7 0 0 0 .22-1H8a.5.5 0 0 1 0-1h7q0-.51-.07-1H8a.5.5 0 0 1 0-1h6.71a7 7 0 0 0-.384-1H8a.5.5 0 0 1 0-1h5.745a7 7 0 0 0-.846-1H8a.5.5 0 0 1 0-1h3.608A7 7 0 1 0 8 15' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            {showContent === 'classic' ? (
              <div>
                <div className='w-full flex mt-2'>
                  <p className='text-[#555555] font-medium text-base table-text editor-title'>
                    {t('lang_Color')}
                  </p>
                  <div className='relative w-full'>
                    <div
                      onClick={() => setOpenState('background')}
                      className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                    >
                      <div
                        className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                        style={{
                          backgroundColor: firstStatisticDesign?.background,
                          height: '18px',
                          width: '29px',
                        }}
                      ></div>
                      <span className='text-sm font-medium table-text'>
                        {firstStatisticDesign?.background}
                      </span>
                    </div>
                    {openState === 'background' && (
                      <ColorPicker
                        handleChange={handleColorChange}
                        setting={firstStatisticDesign?.background}
                        handleCloseColor={handleCloseColor}
                      />
                    )}
                  </div>
                </div>
                <div>
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt='Uploaded Preview'
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                  )}

                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleImageUpload(e, section_id)}
                    style={{ display: 'block', marginBottom: '10px' }}
                  />

                  <div className='w-full'>
                    <label>Image Resolution</label>
                    <div className='relative'>
                      <select
                        className='w-full mt-2'
                        onChange={handleSizeChange}
                        value={currentImageSizeString}
                      >
                        {dropDownValue?.map((obj) => (
                          <option key={obj?.label} value={JSON.stringify(obj?.value)}>
                            {obj?.label}
                          </option>
                        ))}
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>

                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium text- w-[60%] table-text editor-title'>
                      {t('lang_repeat')}
                    </p>
                    <div className='relative w-full primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={firstStatisticDesign?.background_repeat}
                        onChange={handleBgRepeatChange}
                      >
                        <option value={'no-repeat'}>{t('lang_no_repeat')}</option>
                        <option value={'repeat'}>{t('lang_repeat')}</option>
                        <option value={'repeat-x'}>{t('lang_repeat_x')}</option>
                        <option value={'repeat-y'}>{t('lang_repeat_y')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>

                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium text- w-[60%] table-text editor-title'>
                      {t('lang_attachment')}
                    </p>
                    <div className='relative w-full primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={firstStatisticDesign?.background_attachment}
                        onChange={handleBgAttachmentChange}
                      >
                        <option value={'local'}>{t('lang_default')}</option>
                        <option value={'fixed'}>{t('lang_fixed')}</option>
                        <option value={'scroll'}>{t('lang_scroll')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>

                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium w-[45%] table-text editor-title'>
                      {t('lang_fit')}
                    </p>
                    <div className='relative w-[100%] primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={firstStatisticDesign?.background_fit}
                        onChange={handleBgFitChange}
                      >
                        <option>{t('lang_fill')}</option>
                        <option>{t('lang_contain')}</option>
                        <option>{t('lang_cover')}</option>
                        <option>{t('lang_none')}</option>
                        <option>{t('lang_scale_down')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium w-[45%] table-text editor-title'>
                      {t('lang_position')}
                    </p>
                    <div className='relative w-[100%] primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={currentPositionString}
                        onChange={handlePositionChange}
                      >
                        {positionValue?.map((obj) => (
                          <option key={obj?.label} value={JSON.stringify(obj?.value)}>
                            {obj?.label}
                          </option>
                        ))}
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : showContent === 'gradient' ? (
              <div>
                <div className='w-full flex mt-2'>
                  <p className='text-[#555555] font-medium text-base table-text editor-title'>
                    {t('lang_Color')}
                  </p>
                  <div className='relative w-full'>
                    <div
                      onClick={() => setOpenState('gradient_color_start')}
                      className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                    >
                      <div
                        className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                        style={{
                          backgroundColor: firstStatisticDesign?.gradient_color_start,
                          height: '18px',
                          width: '29px',
                        }}
                      ></div>
                      <span className='text-sm font-medium table-text'>
                        {firstStatisticDesign?.gradient_color_start}
                      </span>
                    </div>
                    {openState === 'gradient_color_start' && (
                      <ColorPicker
                        handleChange={handleColorChange}
                        setting={firstStatisticDesign?.gradient_color_start}
                        handleCloseColor={handleCloseColor}
                      />
                    )}
                  </div>
                </div>

                <div className='flex p-1 items-center'>
                  <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                    {t('lang_Location')}
                  </p>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                    value={setFirstStatisticDesign?.gradient_start_position}
                    onChange={handleStartPositionChange}
                  />
                </div>

                <div className='w-full flex mt-2'>
                  <p className='text-[#555555] font-medium text-base table-text editor-title'>
                    {t('lang_second_color')}
                  </p>
                  <div className='relative w-full'>
                    <div
                      onClick={() => setOpenState('gradient_color_end')}
                      className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                    >
                      <div
                        className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                        style={{
                          backgroundColor: firstStatisticDesign?.gradient_color_end,
                          height: '18px',
                          width: '29px',
                        }}
                      ></div>
                      <span className='text-sm font-medium table-text'>
                        {firstStatisticDesign?.gradient_color_end}
                      </span>
                    </div>
                    {openState === 'gradient_color_end' && (
                      <ColorPicker
                        handleChange={handleColorChange}
                        setting={firstStatisticDesign?.gradient_color_end}
                        handleCloseColor={handleCloseColor}
                      />
                    )}
                  </div>
                </div>

                <div className='flex p-1 items-center'>
                  <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                    {t('lang_Location')}
                  </p>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                    value={setFirstStatisticDesign?.gradient_end_position}
                    onChange={handleEndPositionChange}
                  />
                </div>

                <div className='flex p-1 items-center'>
                  <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                    {t('lang_gradient_angle')}
                  </p>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.gradient_angle}
                    onChange={handleGradientAngleChange}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_border')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            {/* <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
                {t('lang_width')}
              </p>
              <div className='relative w-full'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-left'
                  value={firstStatisticDesign?.border_width}
                  onChange={handleHeadingBorderChange}
                />
              </div>
            </div> */}

            <div className='2xl:flex justify-between mt-3 items-center'>
              <p className='text-[#555555] font-medium text-[12px] table-text'>
                {t('lang_border')}
              </p>
              <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_top}
                    onChange={(e) =>
                      handleBorderChange(
                        firstStatisticDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_top: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_right}
                    onChange={(e) =>
                      handleBorderChange(
                        firstStatisticDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_right: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_bottom}
                    onChange={(e) =>
                      handleBorderChange(
                        firstStatisticDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_bottom: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_left}
                    onChange={(e) =>
                      handleBorderChange(
                        firstStatisticDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_left: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
                </div>
                <div className='w-full -mt-2.5'>
                  <button
                    className={`unit-input border focus:outline-none sm:text-sm text-center ${
                      firstStatisticDesign?.border_all === 'block'
                        ? 'bg-[#555555] text-white'
                        : 'primary-bg-color'
                    }`}
                    onClick={() =>
                      setFirstStatisticDesign((prevState) => ({
                        ...prevState,
                        border_all: prevState.border_all === 'block' ? '' : 'block',
                      }))
                    }
                  >
                    <Link className='w-4 h-4 mx-auto' />
                  </button>
                </div>
              </div>
            </div>
            <div className='2xl:flex justify-between mt-3 items-center'>
              <p className='text-[#555555] font-medium text-[12px] table-text'>
                {t('lang_border_radius')}
              </p>
              <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_radius_top}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        firstStatisticDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_top: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_radius_right}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        firstStatisticDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_right: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_radius_bottom}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        firstStatisticDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_bottom: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={firstStatisticDesign?.border_radius_left}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        firstStatisticDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_left: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
                </div>
                <div className='w-full -mt-2.5'>
                  <button
                    className={`unit-input border focus:outline-none sm:text-sm text-center ${
                      firstStatisticDesign?.border_radius_all === 'block'
                        ? 'bg-[#555555] text-white'
                        : 'primary-bg-color'
                    }`}
                    onClick={() =>
                      setFirstStatisticDesign((prevState) => ({
                        ...prevState,
                        border_radius_all: prevState.border_radius_all === 'block' ? '' : 'block',
                      }))
                    }
                  >
                    <Link className='w-4 h-4 mx-auto' />
                  </button>
                </div>
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      firstStatisticDesign?.border_style === 'none'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('none')}
                  >
                    {t('lang_none')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      firstStatisticDesign?.border_style === 'solid'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('solid')}
                  >
                    {t('lang_solid')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      firstStatisticDesign?.border_style === 'dashed'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('dashed')}
                  >
                    {t('lang_dashed')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      firstStatisticDesign?.border_style === 'dotted'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('dotted')}
                  >
                    {t('lang_dotted')}
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('border_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: firstStatisticDesign?.border_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {firstStatisticDesign?.border_color}
                  </span>
                </div>
                {openState === 'border_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={firstStatisticDesign?.border_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_box_shadow')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full'>
                <div
                  onClick={() => setOpenState('box_shadow_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                >
                  <div
                    className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: firstStatisticDesign?.box_shadow_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {firstStatisticDesign?.box_shadow_color}
                  </span>
                </div>
                {openState === 'box_shadow_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={firstStatisticDesign?.box_shadow_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_blur')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={firstStatisticDesign?.box_shadow_blur}
                onChange={handleBoxShadowBlurChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_horizontal')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={firstStatisticDesign?.box_shadow_horizontal}
                onChange={handleBoxShadowHorizontalChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_vertical')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={firstStatisticDesign?.box_shadow_vertical}
                onChange={handleBoxShadowVerticalChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_spread')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={firstStatisticDesign?.box_shadow_spread}
                onChange={handleBoxShadowSpreadChange}
              />
            </div>
          </div>
        </details>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default EditFirstStatisticStyleContainer;
