import { Backdrop, CircularProgress, Dialog, Menu, MenuItem, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { PrivacyPolicy, RefundPolicy, Termspolicy } from '../../Assets/StaticData/policypages';
import AddCustomMetaField from '../../controllers/AddCustomMetaField';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
const Pages = () => {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const location = useLocation();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [open, setOpen] = useState(
    location?.state?.pageName === 'Terms & Conditions'
      ? 'Terms & Conditions'
      : location?.state?.pageName === 'Privacy Policy'
        ? 'Privacy Policy'
        : 'custom',
  );
  const [pageTitle, setPageTitle] = useState(
    location?.state?.pageName ? location?.state?.pageName : '',
  );
  {
    open === 'custom' ? pageTitle === pageTitle?.custom_title : '';
  }
  function generateSlug() {
    let slug = pageTitle?.toLowerCase(RefundPolicy, Termspolicy, PrivacyPolicy);
    slug = slug?.replace(/[^a-z0-9-]+/g, '-');
    slug = slug?.replace(/^-+|-+$/g, '');
    setSlugName(slug);
    return slug;
  }
  const [slugField, setSlugField] = useState(false);
  const [slugName, setSlugName] = useState();
  const [value, setValue] = useState();
  const [metaData, setMetaData] = useState({
    meta_tag_title: '',
    meta_tag_description: '',
  });
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const profile = clientData;

  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    tone: 'Expert',
    write_style: 'Descriptive',
    features: '',
  });

  const [pageData, setPageData] = useState({
    rpolicy: RefundPolicy,
    tpolicy: Termspolicy,
    ppolicy: PrivacyPolicy,
  });

  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/pages`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }

  useEffect(() => {
    getMetaField();
  }, [])

  const generateDesc = async (gerenateAiData) => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: pageTitle?.custom_title ?? pageTitle,
        features: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      })
      let ApiCall = await API.post('/admin/ai/long_description', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setValue(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };

  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };

  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateDesc(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      tone: '',
      write_style: '',
      features: '',
    });
  };

  useEffect(() => {
    const StoreLink = profile?.custom_domain
      ? `${profile.custom_domain}`
      : `${profile?.customer_domain}`;

    // refund policy
    let rpolicy = pageData?.rpolicy;
    rpolicy = rpolicy?.replaceAll('[store_name]', profile?.business_name);
    rpolicy = rpolicy?.replaceAll('[date]', new Date().toISOString().split('T')[0]);
    rpolicy = rpolicy?.replaceAll('[customer_domain]', StoreLink);
    rpolicy = rpolicy?.replaceAll('[email]', profile?.business_email);
    rpolicy = rpolicy?.replaceAll(
      '[include_the_return_address_here]',
      profile?.business_address?.addressLine1 +
      ',' +
      profile?.business_address?.addressLine2 +
      ',' +
      profile?.business_address?.city +
      ',' +
      profile?.business_address?.state,
    );
    rpolicy = rpolicy?.replaceAll('[email]', profile?.business_email);
    rpolicy = rpolicy?.replaceAll('[company_name]', profile?.business_phone);

    // terms & conditions
    let tpolicy = pageData?.tpolicy;
    tpolicy = tpolicy?.replaceAll('[company_name]', profile?.business_name);
    tpolicy = tpolicy?.replaceAll('[email]', profile?.business_email);
    tpolicy = tpolicy?.replaceAll('[date]', new Date().toISOString().split('T')[0]);

    // Privacy policy
    let ppolicy = pageData?.ppolicy;
    ppolicy = ppolicy?.replaceAll('[date]', new Date().toISOString().split('T')[0]);
    ppolicy = ppolicy?.replaceAll('[store_name]', profile?.business_name);
    ppolicy = ppolicy?.replaceAll('[customer_domain]', StoreLink);
    ppolicy = ppolicy?.replaceAll('[store_mail]', profile?.business_email);
    ppolicy = ppolicy?.replaceAll('[company_name]', profile?.business_phone);
    ppolicy = ppolicy?.replaceAll(
      '[your_company_address]',
      profile?.business_address?.addressLine1 +
      ',' +
      profile?.business_address?.addressLine2 +
      ',' +
      profile?.business_address?.city +
      ',' +
      profile?.business_address?.state,
    );
    ppolicy = ppolicy?.replaceAll('[country]', profile?.business_address?.country);

    setPageData({
      ...pageData,
      rpolicy: rpolicy,
      tpolicy: tpolicy,
      ppolicy: ppolicy,
    });
  }, [pageTitle]);

  useEffect(() => {
    if (location?.state?.pageName) {
      location?.state?.pageName === 'Refund & Cancellation Policy'
        ? setValue(pageData?.rpolicy)
        : location?.state?.pageName === 'Terms & Conditions'
          ? setValue(pageData?.tpolicy)
          : location?.state?.pageName === 'Privacy Policy'
            ? setValue(pageData?.ppolicy)
            : setValue('');
    }
  }, [location?.state?.pageName, pageData]);
  const handleSlug = async (slug) => {
    setSlugField(true);
    setSlugName(await GenerateSlugName(slug));
  };

  const [err, setErr] = useState(false);
  // Quill Emmpty check
  const strippedContent = value?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const [enableSlug, setEnableSlug] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e, isDraft) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        pages_name: pageTitle,
        page_value: value,
        page_slug_name: slugName,
        meta_tag_description: metaData?.meta_tag_description,
        meta_tag_title: metaData?.meta_tag_title,
        custom_fields: meta_fields,
        is_draft: isDraft === 'draft' ? true : false
      })
      const HSubmit = await API.post('/admin/client/addpage', payload);
      if (HSubmit.status === 200 || HSubmit.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/pages',
          title: 'pages',
        });
        navigate('/pages');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    generateSlug();
  }, [open]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (pageTitle?.length > 0 || value?.length > 0 || slugName?.length > 0) {
      setSaveChanges({
        backLink: '/pages',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add pages',
      });
    } else {
      setSaveChanges({
        backLink: '/pages',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add pages',
      });
    }
  }, [pageTitle, value, slugName]);
  const checkChanges = () => {
    if (pageTitle?.length > 0 || value?.length > 0 || slugName?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/pages',
        title: 'add pages',
      });
    } else {
      navigate('/pages');
    }
  };
  const [AiLoader, setAiloader] = useState(false);
  const generateMetaData = async (data, key) => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: data,
      })
      if (key === 'description') {
        let ApiCall = await API.post('/admin/ai/meta_description', payload);
        const result = decryptData(ApiCall?.data)
        setMetaData({ ...metaData, meta_tag_description: result?.data });
      } else {
        let ApiCall = await API.post('/admin/ai/meta_title', payload);
        const result = decryptData(ApiCall?.data)
        setMetaData({ ...metaData, meta_tag_title: result?.data });
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);
  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center pb-[30px] space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_pages'), path: '/pages' },
                { name: t('lang_Add_New_Pages'), path: '/pages/add-page' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Add_New_Pages')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/page-management'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'
        >
          <>
            <div className='secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
              <div className='relative mb-4'>
                <label htmlFor='title' className='flex items-center justify-between'>
                  <p className='text-[13px]'>
                    {t('lang_Page_Type')}
                  </p>
                </label>
                <select
                  className='w-full outline-none rounded-[6px] border main-border-color '
                  name='limit'
                  value={open}
                  disabled={location?.state?.pageName}
                  onChange={(e) => {
                    setOpen(e.target.value);
                    e.target.value === 'Refund & Cancellation Policy'
                      ? setPageTitle('Refund & Cancellation Policy')
                      : e.target.value === 'Terms & Conditions'
                        ? setPageTitle('Terms & Conditions')
                        : e.target.value === 'Privacy Policy'
                          ? setPageTitle('Privacy Policy')
                          : setPageTitle(e.target.value);
                    e.target.value === 'Refund & Cancellation Policy'
                      ? setValue(pageData?.rpolicy)
                      : e.target.value === 'Terms & Conditions'
                        ? setValue(pageData?.tpolicy)
                        : e.target.value === 'Privacy Policy'
                          ? setValue(pageData?.ppolicy)
                          : setValue('');
                  }}
                  onBlur={() => {
                    setMetaData({
                      meta_tag_title: open,
                      meta_tag_description:
                        value
                          ?.replace(/<[^>]*>/g, ' ')
                          ?.split(' ')
                          .slice(0, 50)
                          .join(' ') || '',
                    });
                  }}
                >
                  <option
                    value='custom'
                    className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                  >
                    {t('lang_custom')}
                  </option>
                  <option
                    value='Refund & Cancellation Policy'
                    className='w-full block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                  >
                    {t('lang_Refund_Cancellation_Policy')}
                  </option>
                  <option
                    value='Terms & Conditions'
                    className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                  >
                    {t('lang_Term_Conditions')}
                  </option>
                  <option
                    value='Privacy Policy'
                    className='w-full block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                  >
                    {t('lang_Privacy_Policy')}
                  </option>
                </select>
                <div className='select-arrow'></div>
              </div>
              <div className='mb-4'>
                <label htmlFor='title' className='flex items-center justify-between'>
                  <p className='text-[13px]'>
                    {t('lang_Page_Title')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <input
                  className={
                    err && pageTitle?.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                  }
                  type='text'
                  name='pagename'
                  id='pagename'
                  maxLength={60}
                  disabled={location?.state?.pageName}
                  value={pageTitle}
                  placeholder='Enter page name'
                  onChange={(e) => setPageTitle(e.target.value)}
                  onBlur={() => {
                    handleSlug(pageTitle);
                    setMetaData({ ...metaData, meta_tag_title: pageTitle });
                  }}
                />
                <div className='w-full flex justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>
                    {t('lang_recommend_character')} {`${pageTitle?.length} ${t('lang_characters')}`}
                  </p>
                </div>
                {err && pageTitle?.length === 0 ? (
                  <p className='text-red-500 text-xs '>{t('lang_Enter_Page_Title')}</p>
                ) : (
                  ''
                )}
              </div>
              {slugField && (
                <div className='mb-4'>
                  <label htmlFor='category_name'>{t('lang_Page_Slug')}<span className='text-red-500 ms-1'>*</span></label>
                  <div
                    className={`relative flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'main-border-color'
                      } rounded-[4px]`}
                  >
                    <input
                      className='w-full  focus:outline-none'
                      type='text'
                      name='page_slug_name'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => setSlugName(e.target.value)}
                    // }
                    // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                    />
                    <Tooltip
                      title={`${enableSlug ? t('lang_save') : t('lang_edit')}`}
                      arrow
                      placement='bottom'
                      className='ml-2 -tracking-tighter capitalize'
                    >
                      <button type='button' className='slug-edit w-8 h-8'>
                        {enableSlug ? (
                          <Check
                            onClick={async () => {
                              if (slugName?.length > 0) {
                                setSlugName(await GenerateSlugName(slugName));
                                setEnableSlug(!enableSlug);
                                setErr(false);
                              } else {
                                setIsNotification(true);
                                setNotificationMsg('Slug can not be blank');
                                setSeverity('warning');
                              }
                            }}
                            className='text-green-500 p-1 h-8 w-8'
                          />
                        ) : (
                          <Edit
                            onClick={() => setEnableSlug(!enableSlug)}
                            className='text-black-500 p-1 h-7 w-7'
                          />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                  {err && enableSlug && (
                    <p className='text-xsm text-red-500'>{t('lang_save_slug_changes')}</p>
                  )}
                </div>
              )}
              <div className='relative mb-4'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='title'>
                    <p className='text-[13px]'>
                      {t('lang_Page_Content')}
                      <span className='text-red-500 ms-1'>*</span>
                    </p>
                  </label>
                  <div className='flex justify-between items-center'>
                    {open === 'custom' && <>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={() => {
                          if (open === 'custom' && !pageTitle?.custom_title) {
                            setGenareteAiPopup(true);
                          }
                        }}
                      >
                        <BardSvg /> {t('lang_get_description')}
                      </span>
                    </>}
                    <section
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </section>
                  </div>
                </div>
                <div
                  className={
                    err && trimmedContent === '' ? 'border rounded-[6px] border-red-500' : 'w-full h-auto'
                  }
                >
                  <ReactQuill
                    ref={quillRef}
                    modules={module}
                    theme='snow'
                    value={value}
                    onChange={handleQuillChange}
                    onBlur={() => {
                      setMetaData({
                        ...metaData,
                        meta_tag_description:
                          value
                            ?.replace(/<[^>]*>/g, ' ')
                            ?.split(' ')
                            .slice(0, 50)
                            .join(' ') || '',
                      });
                    }}
                  />
                </div>
                {err && trimmedContent === '' && value?.length === 0 ? (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Page_Value')}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <details className='secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <summary
                htmlFor='Meta Tag Title'
                className='text-base w-full flex flex-col items-center font-medium '
              >
                <div className='flex w-full items-center justify-between'>
                  <div>
                    <span>{t('lang_search_engine_listing')}</span>
                    <p className='text-xs table-text font-normal'>
                      {t('lang_search_engine_listing')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </div>
                {metaData?.meta_tag_title?.length > 0 && (
                  <div className='text-left font-normal mt-2 w-full'>
                    <p className='text-blue-700 text-base'>{metaData?.meta_tag_title}</p>
                    <p className='flex text-xs text-green-700'>
                      {`${profile?.custom_domain ? profile?.custom_domain : profile?.customer_domain
                        }/page/${slugName}`}{' '}
                    </p>
                    <p
                      className='table-text text-sm'
                      dangerouslySetInnerHTML={{ __html: metaData?.meta_tag_description }}
                    ></p>
                  </div>
                )}
              </summary>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label className='flex items-center justify-between' htmlFor='Meta Tag Title'>
                    <div className='flex items-center'>
                      {t('lang_meta_title')}
                      <Tooltip
                        title={t('lang_meta_tooltip')}
                        arrow
                        placement='right'
                        className='ml-2 -tracking-tighter'
                      >
                        <QuestionCircleFill className='info-link cursor-pointer' />
                      </Tooltip>
                    </div>
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(value, 'title');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_title')}
                  </span>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='Meta Tag Title'
                  name='meta_tag_title'
                  value={metaData?.meta_tag_title}
                  onChange={(e) => setMetaData({ ...metaData, meta_tag_title: e.target.value })}
                  placeholder='Enter meta title'
                />
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label
                    htmlFor='Meta Tag Description'
                    className='flex items-center justify-between'
                  >
                    <div className='flex items-center'>
                      {t('lang_meta_description')}
                      <Tooltip
                        title={t('lang_meta_tooltip')}
                        arrow
                        placement='right'
                        className='ml-2 -tracking-tighter'
                      >
                        <QuestionCircleFill className='info-link cursor-pointer' />
                      </Tooltip>
                    </div>
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(value, 'description');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_description')}
                  </span>
                </div>
                <textarea
                  className='w-full h-24 block px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='Meta Tag Description'
                  name='meta_tag_description'
                  value={metaData?.meta_tag_description}
                  onChange={(e) =>
                    setMetaData({ ...metaData, meta_tag_description: e.target.value })
                  }
                  placeholder='Enter meta description'
                />
              </div>
            </details>
            {isCustomField && <AddCustomMetaField
              setmeta_fields={setmeta_fields}
              meta_fields={meta_fields}
              heading='pages'
            />}
          </>
        </form>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Pages')}
            </Link>
            <div>
              {pageTitle === '' || trimmedContent === '' || enableSlug ? (
                <div className='flex opacity-50 cursor-not-allowed items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                  <button
                    className='flex cursor-not-allowed bg-btn px-5 py-2 rounded-s text-sm items-center text-white transition duration-300'
                    disabled
                  >
                    {t('lang_Add_New_Page')}
                  </button>
                  <button
                    className='flex cursor-not-allowed bg-btn px-5 py-2 border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                    disabled
                  >
                    <ChevronDown className='w-[18px] h-[18px] stroke-white' />
                  </button>
                </div>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[124px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span> {t('lang_loading')}...</span>
                </button>
              ) : (
                <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                  <button
                    className='flex btn rounded-s px-5 py-2.5 text-sm items-center text-white transition duration-300'
                    onClick={(e) => HandleSubmit(e, 'publish')}
                  >
                    {t('lang_Add_Blog_Post')}
                  </button>
                  <button
                    type='button'
                    className='flex btn border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                    onClick={handleClick}
                  >
                    <ChevronDown className='w-[18px] h-[18px] stroke-white' />
                  </button>
                  <div>
                    <Menu
                      id='basic-menu'
                      anchorEl={menuAnchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      MenuListProps={'aria-labelledby'}
                      className='text-sm'
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem
                        className='text-sm block'
                        onClick={(e) => HandleSubmit(e, 'draft')}
                      >
                        <svg
                          fill='currentcolor'
                          version='1.1'
                          id='Capa_1'
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          width='18px'
                          height='18px'
                          viewBox='0 0 31.854 31.854'
                          xmlSpace='preserve'
                        >
                          <g>
                            <path
                              d='M15.94,11.735c0,0.588-0.476,1.064-1.065,1.064H5.298c-0.588,0-1.065-0.476-1.065-1.064c0-0.587,0.477-1.063,1.065-1.063
		h9.577C15.465,10.672,15.94,11.148,15.94,11.735z M21.52,11.087l5.342,4.791L15.535,28.505l-5.34-4.791L21.52,11.087z
		 M20.008,16.188l-0.033-0.029c-0.127-0.114-0.293-0.172-0.461-0.163c-0.168,0.009-0.328,0.085-0.439,0.211l-6.079,6.776
		c-0.253,0.281-0.229,0.713,0.052,0.965c0.126,0.115,0.291,0.174,0.461,0.164c0.168-0.01,0.327-0.086,0.44-0.213l6.108-6.81
		C20.293,16.828,20.27,16.423,20.008,16.188z M21.27,13.751l-0.301,0.336c-0.111,0.126-0.17,0.292-0.162,0.461
		c0.01,0.169,0.086,0.328,0.211,0.441l0.035,0.031c0.127,0.113,0.293,0.171,0.461,0.162c0.17-0.009,0.328-0.085,0.44-0.211
		l0.728-0.811l-0.51-0.457C21.91,13.467,21.506,13.489,21.27,13.751z M22.035,10.51l5.341,4.791l1.312-1.462l-5.34-4.791
		L22.035,10.51z M31.047,11.21l-1.877,2.093l-5.341-4.79l1.88-2.093c0.247-0.28,0.6-0.448,0.975-0.468
		c0.375-0.021,0.742,0.109,1.021,0.359l3.234,2.903C31.52,9.735,31.569,10.629,31.047,11.21z M29.996,10.264l-3.233-2.902
		l-0.937,1.042l3.233,2.903L29.996,10.264z M9.557,24.428l5.339,4.791l-6.31,2.586c-0.243,0.098-0.521,0.051-0.716-0.125
		c-0.196-0.177-0.273-0.447-0.201-0.699L9.557,24.428z M10.045,26.13l-0.722,2.507l1.411,1.268l2.412-0.986L10.045,26.13z
		 M8.215,24.285l-0.623,2.162H2.859c-1.332,0-2.413-1.08-2.413-2.412V6.493c0-1.332,1.08-2.413,2.413-2.413h2.94V3.906
		c0-0.492,0.399-0.89,0.89-0.89h0.74C7.414,2.909,7.406,2.781,7.406,2.661C7.406,1.194,8.599,0,10.067,0
		c1.469,0,2.661,1.194,2.661,2.661c0,0.12-0.01,0.248-0.023,0.354h0.749c0.492,0,0.89,0.399,0.89,0.89v0.174h2.87
		c1.332,0,2.412,1.081,2.412,2.413v4.63l-2.128,2.372V7.506c0-0.699-0.565-1.265-1.264-1.265h-1.891v1.201
		c0,0.491-0.399,0.89-0.89,0.89H6.69c-0.492,0-0.89-0.399-0.89-0.89V6.241H3.874c-0.699,0-1.265,0.567-1.265,1.265V23.02
		c0,0.701,0.567,1.266,1.265,1.266H8.215z M9.003,2.661c0,0.124,0.023,0.248,0.061,0.355h2.005c0.04-0.107,0.062-0.23,0.062-0.355
		c0-0.587-0.477-1.065-1.064-1.065C9.479,1.596,9.003,2.074,9.003,2.661z M14.949,16.341l0.991-1.105
		c-0.014-0.576-0.484-1.054-1.064-1.054H5.298c-0.588,0-1.065,0.493-1.065,1.082c0,0.587,0.477,1.082,1.065,1.082h9.577
		C14.9,16.344,14.925,16.342,14.949,16.341z M4.233,18.791c0,0.588,0.477,1.062,1.065,1.062H11.8l1.907-2.127H5.298
		C4.71,17.727,4.233,18.203,4.233,18.791z'
                            />
                          </g>
                        </svg>
                        <span className='ms-2 text-sm'> {t('lang_Save_As_Draf')}</span>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium  text-primary'>
                {t('lang_generate_product_description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[6px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_tone_of_voice')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.tone}
                    onChange={handleChangeAiData}
                    name='tone'
                    className='w-full border main-border-color rounded-[6px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Expert'>{t('lang_expert')}</option>
                    <option value='Custom'>{t('lang_custom')}</option>
                    <option value='Daring'>{t('lang_daring')}</option>
                    <option value='Playful'>{t('lang_playful')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Supportive'>{t('lang_supportive')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_writing_style')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.write_style}
                    onChange={handleChangeAiData}
                    name='write_style'
                    className='w-full border main-border-color rounded-[6px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Descriptive'>{t('lang_descriptive')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Conversational'>{t('lang_conversational')}</option>
                    <option value='Technical'>{t('lang_technical')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Storytelling'>{t('lang_storytelling')}</option>
                    <option value='SEO-Optimized'>{t('lang_seo_optimized')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <button type='submit' className='btn px-5 py-2 text-white text-sm rounded-[6px]'>
                {t('lang_generate_description')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Pages;
