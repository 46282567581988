import { Tooltip } from '@mui/material';
import { Edit, Move, Trash } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loader from '../Loader/Loader';
const AddSpecification = ({ handleStoreSpecification, allspecification }) => {
  const [specification, setSpecification] = useState({
    specification_field: '',
    specification_value: '',
  });
  const [storeSpecifications, setStoreSpecifications] = useState(
    allspecification?.length > 0 ? allspecification : [],
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isEditIndex, setIsEditIndex] = useState();
  const HandleSpecificationChange = (e) => {
    setSpecification({ ...specification, [e.target.name]: e.target.value });
  };
  const HandleSpecification = (e) => {
    e.preventDefault();
    if (specification?.specification_field !== '' || specification?.specification_value !== '') {
      if (isEdit) {
        const newData = storeSpecifications.map((item, index) => {
          if (index === isEditIndex) {
            return {
              specification_field: specification?.specification_field,
              specification_value: specification?.specification_value,
            };
          }
          return item;
        });
        setSpecification({ specification_field: '', specification_value: '' });
        setStoreSpecifications(newData);
        handleStoreSpecification(newData);
        setIsEdit(false);
        setIsEditIndex();
      } else {
        setStoreSpecifications([...storeSpecifications, specification]);
        setSpecification({ specification_field: '', specification_value: '' });
        handleStoreSpecification([...storeSpecifications, specification]);
      }
    }
  };
  const editSpecification = (row) => {
    setIsEdit(true);
    setSpecification({
      specification_field: row?.specification_field,
      specification_value: row?.specification_value,
    });
  };
  const deleteSpecification = (index) => {
    const filterSpecification = storeSpecifications?.filter((x, id) => id !== index);
    setStoreSpecifications(filterSpecification);
    handleStoreSpecification(filterSpecification);
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...storeSpecifications];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = _sort.map((list, index) => {
      return { ...list, sort_order: index };
    });
    setStoreSpecifications(FinalArray);
    handleStoreSpecification(FinalArray);
  };
  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'field',
      minWidth: '150px',
      cell: (row) => <p className='line-clamp-2'>{row.specification_field || '-'}</p>,
    },
    {
      name: 'value',
      minWidth: '200px',
      cell: (row) => <p className='line-clamp-2'>{row.specification_value || '-'}</p>,
    },
    {
      name: <span className='w-full block text-right capitalize'>{t('lang_action')}</span>,
      minWidth: '150px',
      cell: (row, index) => (
        <div className='space-x-3 w-full flex justify-end items-center'>
          <button href='#' className='text-xs font-thin text-black-500 hover:text-black-600'>
            <div>
              <Tooltip title='Edit' arrow>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    editSpecification(row);
                    setIsEditIndex(index);
                  }}
                >
                  <Edit className='w-[17px] h-[17px]' />
                </button>
              </Tooltip>
            </div>
          </button>
          <button
            href='#'
            className='text-xs font-thin text-red-500 hover:text-red-600'
            onClick={(e) => {
              e.preventDefault();
              deleteSpecification(index);
            }}
          >
            <div>
              <Tooltip title='Delete' arrow>
                <button className=''>
                  <Trash className='w-[17px] h-[17px]' />
                </button>
              </Tooltip>
            </div>
          </button>
        </div>
      ),
    },
  ];
  return (
    <>
      <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
        <div className='w-full mb-4'>
          <label>{t('lang_specification_field')}</label>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none mr-2'
            type='text'
            id='specification_field'
            name='specification_field'
            value={specification.specification_field}
            onChange={HandleSpecificationChange}
            placeholder='e.g. battery'
            required
          />
        </div>
        <div className='w-full'>
          <label>{t('lang_specification_value')}</label>
          <textarea
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none mr-2 '
            type='text'
            id='specification_value'
            name='specification_value'
            value={specification.specification_value}
            onChange={HandleSpecificationChange}
            placeholder='e.g. 5000mh'
            required
          />
        </div>
        {specification?.specification_field === '' || specification?.specification_value === '' ? (
          <button
            type='button'
            className='inline-block cursor-not-allowed opacity-50 border border-secondary text-link text-sm font-medium mt-2 py-2 px-5 rounded-[6px] transition duration-300'
          >
            {t('lang_Add_specification')}
          </button>
        ) : (
          <button
            type='button'
            onClick={(e) => HandleSpecification(e)}
            className='inline-block secondary-btn text-white text-sm font-medium mt-2 py-2 px-8 rounded-[6px] transition duration-300'
          >
            {t('lang_Add_specification')}
          </button>
        )}
      </form>
      {storeSpecifications?.length > 0 && (
        <>
          <hr className='my-4' />
          <div className='w-full transition-all duration-100 inline-block overflow-auto border main-border-color rounded-[6px]'>
            <DataTable
              columns={columns}
              data={storeSpecifications}
              selectableRowsHighlight
              highlightOnHover
              progressComponent={<Loader />}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AddSpecification;
