import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const SecondStaticStepSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.secondStepComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_step-2',
  );

  const [selectedTab, setSelectedTab] = useState(() => {
    const lastSection = filteredSections?.[filteredSections?.length - 1];
    const lastItemIndex = lastSection?.content?.item?.length - 1;
    return lastItemIndex >= 0 ? lastItemIndex : 0;
  });

  useEffect(() => {
    const index = filteredSections?.find(
      (section) => section?.content?.item?.some((itemObj) => itemObj?.isEdit) !== -1,
    );
    const itemIndex = index?.content?.item?.findIndex((itemObj) => itemObj?.isEdit);
    console.log('lastItemIndex', itemIndex);
    if (itemIndex !== -1) {
      setSelectedTab(itemIndex);
    } else {
      const lastItemIndex =
        filteredSections?.reduce((maxLength, section) => {
          return Math.max(maxLength, section?.content?.item?.length || 0);
        }, 0) - 1;
      console.log('lastItemIndex', lastItemIndex);
      setSelectedTab(lastItemIndex >= 0 ? lastItemIndex : 0);
    }
  }, [filteredSections, sectionIndex]);

  const handleTabClick = (e, tabIndex) => {
    e.stopPropagation();
    console.log('tabIndex', tabIndex);
    const updatedSections = [...filteredSections];
    const section = updatedSections?.find((section) => section?.content?.item?.length > 0);

    if (section && section?.content && section?.content?.item) {
      section.content.item = section.content.item.map((item, idx) => ({
        ...item,
        isEdit: idx === tabIndex,
      }));
      setSelectedTab(tabIndex);
    }
  };

  console.log('filteredSections', filteredSections, selectedTab);
  return (
    <div className='w-full'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        const activeItem =
          obj?.content?.item?.find((item) => item?.isEdit) ??
          obj?.content?.item?.[obj?.content?.item?.length - 1];
        return (
          <ReactDragListView {...dragProps}>
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_step-2', 'setting');
              }}
            >
              <div
                class='container mx-auto flex flex-wrap flex-col'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex mx-auto flex-wrap mb-20'>
                  {obj?.content?.item?.map((value, index) => {
                    return (
                      <a
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation(), handleTabClick(e, index);
                        }}
                        className={`sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t gap-x-1 ${
                          index === selectedTab
                            ? 'bg-gray-100 border-indigo-500 text-indigo-500'
                            : 'border-gray-200 text-gray-600 hover:bg-gray-100'
                        }`}
                      >
                        {value?.icon && React.isValidElement(value?.icon) ? (
                          React.cloneElement(value?.icon, {
                            width: '50px',
                            height: '50px',
                            color: '#6366f1',
                          })
                        ) : (
                          <SvgSection
                            svgContent={value?.icon}
                            width={'24px'}
                            height={'24px'}
                            fill={
                              value?.isFill ? (index === selectedTab ? '#6366F1' : '#000000') : ''
                            }
                            stroke={
                              value?.isFill ? (index === selectedTab ? '#6366F1' : '#000000') : ''
                            }
                          />
                        )}
                        {value?.iconTitle}
                      </a>
                    );
                  })}
                </div>
                <img
                  className='xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded'
                  alt='hero'
                  src={activeItem?.image}
                  style={{
                    objectFit: activeItem?.objectFit,
                  }}
                />
                <div className='flex flex-col text-center w-full'>
                  <h1 className='text-xl font-medium title-font mb-4 text-gray-900'>
                    {activeItem?.title}
                  </h1>
                  <p className='lg:w-2/3 mx-auto leading-relaxed text-base'>
                    {activeItem?.description}
                  </p>
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
    </div>
  );
};

export default SecondStaticStepSection;
