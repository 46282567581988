import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  applyFontSizeToTags,
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const FifthStaticHeroSection = ({
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.fiveHeroComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_hero-5',
  );
  return (
    <div className='w-full'>
      <ReactDragListView {...dragProps}>
        {filteredSections?.map((obj, itemIndex) => {
          const isActive =
            editSection?.section_type === obj?.blockName &&
            editSection?.section_id === obj?.id &&
            editSection?.section_index === itemIndex;

          const description = obj?.content?.description || '-';
          const fontSize = obj?.style?.description_font_size;
          const lineHeight = obj?.style?.description_font_height;
          const styledContent = applyFontSizeToTags(description, fontSize, lineHeight);

          return (
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_hero-5', 'setting');
              }}
            >
              <div
                class='container mx-auto flex md:flex-row flex-col items-center'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0'>
                  <img
                    class='h-40 rounded w-full object-cover object-center mb-6'
                    src={obj?.content?.image}
                    alt='content'
                    style={{
                      objectFit: obj?.content?.objectFit,
                    }}
                  />
                </div>
                <div class='lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center'>
                  {obj?.content?.htmlTag === 'h1' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '40px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '45px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h2' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '36px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '41px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h3' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '32px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '37px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h4' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '26px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '33px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h5' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '22px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '29px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h6' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '18px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '25px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'div' ? (
                    <div
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'p' ? (
                    <p
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </p>
                  ) : obj?.content?.htmlTag === 'span' ? (
                    <span
                      class='mb-4 w-full'
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </span>
                  ) : (
                    ''
                  )}
                  <div
                    style={{
                      fontWeight: obj?.style?.description_font_weight,
                      fontSize: `${obj?.style?.description_font_size}px`,
                      lineHeight: `${obj?.style?.description_font_height}px`,
                      color: obj?.style?.description_font_color,
                      textAlign: obj?.style?.description_font_align,
                      fontStyle: obj?.style?.description_font_style,
                      textDecoration: obj?.style?.description_font_decoration,
                      textTransform: obj?.style?.description_font_capitalize,
                      wordBreak: obj?.style?.description_font_break,
                      textWrap: obj?.style?.description_font_wrap,
                      letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.description_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                    }}
                    className='line-clamp-2 sm:pr-12 mb-4 w-full'
                    dangerouslySetInnerHTML={{ __html: styledContent }}
                  ></div>
                  <div class='flex w-full md:justify-start justify-center items-end'>
                    <div className='flex w-full justify-between space-x-3'>
                      {obj?.content?.form?.map((formField, index) => {
                        return (
                          <>
                            <div key={index}>
                              <div className='mb-5 mt-5'>
                                <label
                                  htmlFor='fieldType'
                                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                >
                                  {formField?.labelValue}
                                </label>
                                {formField?.type === 'textarea' ? (
                                  <textarea
                                    type={formField?.type ? formField?.type : ''}
                                    name={formField?.name ? formField?.name : ''}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    required={!!formField?.required}
                                    maxLength={formField?.maxLength ? formField?.maxLength : ''}
                                    minLength={formField?.minLength ? formField?.minLength : ''}
                                    placeholder={
                                      formField?.placeholder ? formField?.defaultValue : ''
                                    }
                                    autoComplete={formField?.autoComplete ? 'on' : 'off'}
                                    max={formField?.max ? formField?.max : ''}
                                    min={formField?.min ? formField?.min : ''}
                                  ></textarea>
                                ) : formField?.type === 'select' ? (
                                  <>
                                    <div className='mb-5 mt-2'>
                                      <select
                                        name={formField?.name ? formField.name : ''}
                                        type={formField?.type ? formField?.type : ''}
                                        required={!!formField?.required}
                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                      >
                                        {formField?.options?.map((val, index) => {
                                          return (
                                            <option key={index} value={val}>
                                              {val}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </>
                                ) : formField?.type === 'checkbox' ||
                                  formField?.type === 'radio' ? (
                                  <>
                                    <div className='mb-5 mt-2'>
                                      {formField?.options?.map((val, index) => {
                                        return (
                                          <div className='flex items-center mb-4' key={index}>
                                            <input
                                              type={formField?.type ? formField?.type : ''}
                                              name={formField?.name ? formField.name : ''}
                                              required={!!formField?.required}
                                              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                            />
                                            <label
                                              htmlFor='default-checkbox'
                                              className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                            >
                                              {val}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </>
                                ) : formField?.type === 'acceptence' ? (
                                  <>
                                    <div className='mb-5 mt-2'>
                                      <div className='flex items-center mb-4'>
                                        <input
                                          type='checkbox'
                                          name={formField?.name ? formField?.name : ''}
                                          required={!!formField?.required}
                                          className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                        />
                                        <label
                                          htmlFor='default-checkbox'
                                          className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                        >
                                          {formField?.condition}
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                ) : formField?.type === 'quiz' ? (
                                  <>
                                    <div className='mb-5 mt-2'>
                                      <label
                                        htmlFor='fieldType'
                                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                      >
                                        {formField?.question}
                                      </label>
                                      {formField?.options?.map((val, index) => {
                                        return (
                                          <div className='flex items-center mb-4' key={index}>
                                            <input
                                              type='radio'
                                              name={formField?.name ? formField?.name : ''}
                                              required={!!formField?.required}
                                              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                            />
                                            <label
                                              htmlFor='default-checkbox'
                                              className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                            >
                                              {val}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </>
                                ) : formField?.type === 'submit' ? (
                                  <>
                                    <div className='mb-5 mt-2'>
                                      <button
                                        type={formField?.type ? formField?.type : 'button'}
                                        name={formField?.name ? formField?.name : ''}
                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                      >
                                        {formField?.buttonName}
                                      </button>
                                    </div>
                                  </>
                                ) : formField?.type === 'date' ? (
                                  <>
                                    <label className='block mt-4 mb-1 text-sm font-medium text-gray-700 dark:text-gray-300'>
                                      Select Min Date
                                    </label>
                                    <input
                                      type='date'
                                      name={`${formField?.name}-min`}
                                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                      required={!!formField?.required}
                                      defaultValue={
                                        formField?.defaultValue
                                          ? formField?.defaultValue
                                          : formField?.min
                                      }
                                      min={formField?.min ? formField?.min : ''}
                                    />

                                    <label className='block mt-4 mb-1 text-sm font-medium text-gray-700 dark:text-gray-300'>
                                      Select Max Date
                                    </label>
                                    <input
                                      type='date'
                                      name={`${formField?.name}-max`}
                                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                      required={!!formField?.required}
                                      defaultValue={formField?.max ? formField?.max : ''}
                                      max={formField?.max ? formField?.max : ''}
                                    />
                                  </>
                                ) : (
                                  <input
                                    type={formField?.type ? formField?.type : ''}
                                    name={formField?.name ? formField?.name : ''}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    required={!!formField?.required}
                                    maxLength={formField?.maxLength ? formField?.maxLength : ''}
                                    minLength={formField?.minLength ? formField?.minLength : ''}
                                    defaultValue={
                                      formField?.placeholder === false ? formField.defaultValue : ''
                                    }
                                    placeholder={
                                      formField?.placeholder ? formField?.defaultValue : ''
                                    }
                                    autoComplete={formField?.autoComplete ? 'on' : 'off'}
                                    max={formField?.max ? formField?.max : ''}
                                    min={formField?.min ? formField?.min : ''}
                                    accept={
                                      formField?.fileTypes
                                        ? formField?.fileTypes.replace(/\|/g, ',')
                                        : ''
                                    }
                                    limit={formField?.limit ? formField?.limit : ''}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    class='mt-2 mb-3 w-full'
                    style={{
                      fontWeight: obj?.style?.note_font_weight,
                      fontSize: obj?.style?.note_font_size
                        ? `${obj?.style?.note_font_size}px`
                        : '12px',
                      lineHeight: `${obj?.style?.note_font_height}px`,
                      color: obj?.style?.note_font_color,
                      textAlign: obj?.style?.note_font_align,
                      fontStyle: obj?.style?.note_font_style,
                      textDecoration: obj?.style?.note_font_decoration,
                      textTransform: obj?.style?.note_font_capitalize,
                      wordBreak: obj?.style?.note_font_break,
                      textWrap: obj?.style?.note_font_wrap,
                      letterSpacing: `${obj?.style?.note_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.note_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.note_text_stroke}px ${obj?.style?.note_stroke_color}`,
                      textStroke: `${obj?.style?.note_text_stroke}px ${obj?.style?.note_stroke_color}`,
                      textShadow: `${obj?.style?.note_text_shadow_horizontal}px ${obj?.style?.note_text_shadow_vertical}px ${obj?.style?.note_text_shadow_blur}px ${obj?.style?.note_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.note_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.note}
                  </div>
                  <div class='flex lg:flex-row md:flex-col'>
                    <button class='bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        class='w-6 h-6'
                        viewBox='0 0 512 512'
                      >
                        <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
                      </svg>
                      <span class='ml-4 flex items-start flex-col leading-none'>
                        <span class='text-xs text-gray-600 mb-1'>
                          {obj?.content?.first_btn_title}
                        </span>
                        <span class='title-font font-medium'>
                          {obj?.content?.first_btn_sub_title}
                        </span>
                      </span>
                    </button>
                    <button class='bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        class='w-6 h-6'
                        viewBox='0 0 305 305'
                      >
                        <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
                        <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
                      </svg>
                      <span class='ml-4 flex items-start flex-col leading-none'>
                        <span class='text-xs text-gray-600 mb-1'>
                          {obj?.content?.second_btn_title}
                        </span>
                        <span class='title-font font-medium'>
                          {obj?.content?.second_btn_sub_title}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </ReactDragListView>
    </div>
  );
};

export default FifthStaticHeroSection;
