import { Checkbox, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

function AllVariants() {
  const location = useLocation();
  const navigate = useNavigate();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [Page, setPage] = useState(getInitialPage());
  const [allVarientGroup, setallVarientGroup] = useState();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [clearRow, setClearRow] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  var startRecord = (allVarientGroup?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    allVarientGroup?.data?.currentPage * limit,
    allVarientGroup?.data?.totalRecords,
  );
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    getVariantsGroup();
  };

  useEffect(() => {
    if (search?.length > 0 || search?.length > 2) {
      getVariantsGroup();
    }
  }, [search]);

  const getVariantsGroup = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/product/getProductVariantsGroup?page=${Page}&limit=${initialLimit}&searchkey=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setallVarientGroup(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'variant' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  // Drag And Drop Functionality
  const DragItem = useRef();
  const DragOverItem = useRef();
  const sorVarient = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index,
      })
      const apiCall = await API.put(`/admin/product/updateProductVariantsGroup/${id}`, payload);
      if (apiCall.status === 200) {
        getVariantsGroup();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleRemove = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`admin/product/deleteProductVariantsGroup`, payload);
      if (data.status === 200 || data.status === 304) {
        getVariantsGroup();
        setSelectedRowsId([]);
        setClearRow(!clearRow);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  useEffect(() => {
    getVariantsGroup();
    setClearRow(!clearRow);
  }, [limit, Page]);
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = allVarientGroup?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = allVarientGroup?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = allVarientGroup?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(allVarientGroup?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading={t('lang_delete_module', { module: 'Group Variant' })}
        subheading={t('lang_delete_confirmation', { module: 'group variant' })}
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleRemove}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_group_variants'), path: '/group-variants' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_group_variants')}
              </h4>
            </div>
          </div>
          <div></div>
          <div className='flex items-center '>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/group-variants/add-group-variants')}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              {t('lang_add_new', { module: 'Group Variant' })}
            </button>
          </div>
        </div>
        <div className='flex justify-between w-full space-x-2'>
          <div className='w-full space-y-2'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>{t('lang_delete_selection')}</span>
                    </button>
                  </>
                )}
              </div>
                {allVarientGroup?.data?.totalRecords > 0 && <Tooltip title={t('lang_filter')} arrow>
                  <button className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                  <button className=''>
                    <Filter className='w-4 h-4 sm:w-[18px] sm:h-[18px]' />
                  </button>
                  </button>
                </Tooltip>}
            </div>
            <div
              className={'flex w-full h-full overflow-hidden relative'}
            >
              <div className='w-full'>
                {dataLoading && !allVarientGroup ? (
                  <TableSkeleton forTable='order' />
                ) : allVarientGroup?.data?.records?.length > 0 ? (
                  <div className='w-full overflow-hidden relative variant-table sticky-table transition-all duration-100 inline-block mb-1 border main-border-color rounded-[6px] '>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={
                                selectedRowsId?.length === allVarientGroup?.data?.records?.length
                              }
                              indeterminate={
                                selectedRowsId?.length !== allVarientGroup?.data?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='min-w-[250px] max-w-[250px] px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase inline-flex ${iconVisible && sortBy === 'group_name' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('group_name');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('group_name')}
                              >
                                {t('lang_group_name')}
                              </button>
                              {iconVisible && sortBy === 'group_name' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                          <td className='w-full px-3'>{t('lang_group_value')}</td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {allVarientGroup?.data?.records?.map((row, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                                }`}
                              onClick={() => navigate(`/group-variants/${row._id}`)}
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === row?._id)
                                  }
                                  onChange={(e) => handleSelectRow(e, row?._id)}
                                />
                              </td>
                              <td className='min-w-[250px] line-clamp-2 max-w-[250px] px-3'>
                                {row.group_name || '-'}
                              </td>
                              <td className='px-3 w-full'>
                                <div className='flex overflow-auto flex-wrap line-clamp-1'>
                                  {row?.group_value?.map((value, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className='primary-bg-color me-1 my-0.5 inline-block whitespace-nowrap  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                                      >
                                        {value}
                                      </span>
                                    );
                                  })}
                                </div>
                              </td>
                              <td className='min-w-[120px] max-w-[120px] text-right px-3' onClick={(e) => e.stopPropagation()}>
                                <div className='space-x-2 w-full flex items-center justify-end'>
                                  <button
                                    href='#'
                                    onClick={() => navigate(`/group-variants/${row._id}`)}
                                    className='text-xs font-thin text-black-500 hover:text-black-600'
                                  >
                                    <div>
                                      <Tooltip title={t('lang_edit')} arrow>
                                        <button className=''>
                                          <Edit className='w-[17px] h-[17px]' />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </button>
                                  <button
                                    href='#'
                                    className='text-xs font-thin text-red-500 hover:text-red-600'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setConfirmationPopup(true);
                                      setSelectedRowsId([row?._id]);
                                    }}
                                  >
                                    <Tooltip title={t('lang_delete')} arrow>
                                      <button className=''>
                                        <Trash className='w-[17px] h-[17px]' />
                                      </button>
                                    </Tooltip>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {allVarientGroup?.data ? (
                  <div className='secondary-bg-color border main-border-color hidden sm:flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${allVarientGroup?.data?.totalRecords}` + ' ' + `${t('lang_records')}`
                        : `${startRecord} - ${endRecord} of ${allVarientGroup?.data?.totalRecords
                        } ${t('lang_records')}`}
                    </span>
                    <div>
                      {allVarientGroup?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={allVarientGroup?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px]  filter'>
                  <p className='flex table-text font-semibold uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5'>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_search')}
                      </span>
                      <label className='relative block w-full'>
                        <input
                          className='w-full  placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                          placeholder='Search Variants'
                          type='text'
                          name='search'
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(1);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          {allVarientGroup?.data?.totalRecords > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {allVarientGroup?.data?.totalRecords > 20 ? (
                            <option value='20'>20</option>
                          ) : (
                            <></>
                          )}
                          {allVarientGroup?.data?.totalRecords > 50 ? (
                            <option value='50'>50</option>
                          ) : (
                            <></>
                          )}
                          <option value='all'>{t('lang_all')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllVariants;
