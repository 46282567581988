import Breadcrumbs from '../../controllers/breadCrumbs';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
const Workspace = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className='h-auto overflow-y-auto pb-[64px]'>
                <div className='flex items-center space-x-2 pb-[30px]'>
                    <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
                    <div>
                        <Breadcrumbs crumbs={[
                            { name: t('lang_dashboard'), path: `/dashboard` },
                            { name: t('lang_workspace'), path: '/workspaces' },
                        ]} />
                        <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t("lang_workspace")}</h4>
                    </div>
                </div>
                <section className="secondary-bg-color w-full text-center h-full dark:bg-gray-900">
                    <div className="w-full px-4 py-8">
                        <div className="mr-auto place-self-center">
                            <h6 className="mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl dark:text-white">
                                {t("lang_learn_how_to_use_shopeasy")}
                            </h6>
                            <p className="mb-6 font-normal table-text lg:mb-8 text-base dark:text-gray-400">
                                {t("lang_take_a_product_tour")}<br></br>{t("lang_simplify_your_planning_process")}
                            </p>
                            {/* <a
                            href="#"
                            className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                        >
                            Get started
                            <svg
                                className="w-5 h-5 ml-2 -mr-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            </a> */}
                            <button
                                type='button'
                                className='inline-block btn rounded-[6px] text-white py-3 px-8 text-sm font-medium'
                                onClick={() => Storylane.Play({ type: 'popup', demo_type: 'image', width: 1920, height: 963, scale: '0.95', demo_url: 'https://app.storylane.io/demo/ivhaugn4of49', padding_bottom: 'calc(50.16% + 27px)' })}
                            >
                                <p className='relative z-40'>{t("lang_click_here")}</p>
                            </button>
                        </div>
                        {/* {popUp&&
                        <div>
                            <div className="h-48 md:h-56 lg:h-[24rem] w-full bg-red-500 border-2 border-white flex items-center justify-center text-white text-base mb-3 md:mb-5 overflow-hidden relative">
                                <img
                                src="https://pixahive.com/wp-content/uploads/2020/10/Gym-shoes-153180-pixahive.jpg"
                                className="object-cover w-full h-full scale-100 group-hover:scale-110 transition-all duration-400"
                                alt=""
                                />
                                <div className="absolute z-10 border-4 border-primary w-[95%] h-[95%] invisible group-hover:visible opacity-0 group-hover:opacity-100 group-hover:scale-90 transition-all duration-500"></div>
                            </div>
                        </div>
                    } */}

                        {/* <div> 
                        <script src="https://js.storylane.io/js/v1/storylane.js"></script> 
                        <div class="sl-embed" style="position:relative;padding-bottom:calc(50.16% + 27px);width:100%;height:0;transform:scale(1)"> 
                            <iframe class="sl-demo" src="https://app.storylane.io/demo/ivhaugn4of49" name="sl-embed" allow="fullscreen; camera; microphone" style="position:absolute;top:0;left:0;width:100%;height:100%;border:none;"></iframe> 
                        </div> 
                    </div> */}

                    </div>
                </section>
            </div>
        </>
    );
};

export default Workspace;