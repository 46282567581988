import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { default as Chart, default as ReactApexChart } from 'react-apexcharts';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import GoogleCustomePageViewReport from './GooglePageViewReport';
const GoogleCustomeReport = () => {
  const originalDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(originalDate.getFullYear(), originalDate.getMonth() - 1, originalDate.getDate()),
  );
  const [endDate, setEndDate] = useState(new Date());
  const [countryname, setCountryName] = useState([]);
  const [activeuser, setActiveUser] = useState([]);
  const [channelCountryname, setChannelCountryName] = useState([]);
  const [channelActiveuser, setChannelActiveUser] = useState([]);
  const [locationCountryname, setLocationCountryName] = useState([]);
  const [locationActiveuser, setLocationActiveUser] = useState([]);
  const [deviceCountryname, setDeviceCountryName] = useState([]);
  const [deviceActiveuser, setDeviceActiveUser] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();

  const GetCustomeChart = async () => {
    try {
      const ApiCall = await API.post(
        `/admin/googleanalytics/customReport?startDate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&endDate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setCountryName(result?.responcedata?.map((row) => row?.dimensionValues[0].value));
        setActiveUser(result?.responcedata?.map((row) => row?.metricValues[0].value));
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const datesplit = countryname.map((dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}-${month}-${day}`;
  });

  const GetCustomePageViewChart = async () => {
    try {
      const ApiCall = await API.post(
        `/admin/googleanalytics/customSessionDefaultReport?startDate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&endDate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        const countryValuePairs = result?.responcedata?.map((item) => ({
          country: item.dimensionValues[0].value,
          value: parseInt(item.metricValues[0].value),
        }));

        setChannelCountryName(countryValuePairs.map((pair) => pair.country));
        setChannelActiveUser(countryValuePairs.map((pair) => pair.value));
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const GetCustomLocationChart = async () => {
    try {
      const ApiCall = await API.post(
        `/admin/googleanalytics/Customlocationreport?startDate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&endDate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        const countryValuePairs = result?.responcedata?.map((item) => ({
          country: item.dimensionValues[0].value,
          value: parseInt(item.metricValues[0].value),
        }));

        setLocationCountryName(countryValuePairs.map((pair) => pair.country));
        setLocationActiveUser(countryValuePairs.map((pair) => pair.value));
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const GetCustomeDeviceChart = async () => {
    try {
      const ApiCall = await API.post(
        `/admin/googleanalytics/customDevicesReport?startDate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&endDate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        const countryValuePairs = result?.responcedata?.map((item) => ({
          country: item.dimensionValues[0].value,
          value: parseInt(item.metricValues[0].value),
        }));

        setDeviceCountryName(countryValuePairs.map((pair) => pair.country));
        setDeviceActiveUser(countryValuePairs.map((pair) => pair.value));
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetCustomeChart();
    GetCustomePageViewChart();
    GetCustomLocationChart();
    GetCustomeDeviceChart();
    window.dispatchEvent(new Event('resize'));
  }, [startDate, endDate]);

  const options = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: datesplit,
    },
    dataLabels: {
      formatter: function (val) {
        if (val === 1.0) {
          return '1';
        }
        return val;
      },
    },
    chart: {
      width: '100%',
      type: 'area',
    },
    stroke: {
      curve: 'smooth',
      width: [3],
    },
    colors: ['#35404c'],
    tooltip: {
      x: {
        format: 'number ',
      },
    },
  };
  const series = [
    {
      name: 'activeUsers',
      data: activeuser,
    },
  ];
  const pieoptions = {
    chart: {
      type: 'pie',
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const channelseries = channelActiveuser;
  const locationseries = locationActiveuser;
  const deviceseries = deviceActiveuser;

  const channellable = channelCountryname;
  const locationlable = locationCountryname;
  const devicelable = deviceCountryname;

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Google_Analytics_Custom_Report'), path: '/googlesnapshotreport' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Google_Analytics_Custom_Report')}{' '}
            </h4>
          </div>
        </div>
        <div className='grid grid-cols-1'>
          <div className='secondary-bg-color rounded-[6px] mb-4 shadow overflow-x-auto overflow-y-hidden'>
            <h6 className='border-b main-border-color py-3 px-4 text-base font-medium'>
              {t('lang_Find_Out_How_Your_Audience_Is_Growing')}
            </h6>
            <div className='grid grid-cols-1 lg:grid-cols-2 min-w-full'>
              <div className='p-4'>
                <div className='display-grid py-3'>
                  <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <div className='w-full'>
                      <span className='block text-xs mb-1 font-medium table-text'>{t('lang_from')}</span>
                      <label className='relative w-full block cursor-pointer'>
                        <DatePicker
                          className={`${startDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          dateFormat='dd-MM-yyyy'
                        />
                        <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                          <CalendarFill className='table-text w-3 h-3' />
                        </span>
                      </label>
                    </div>
                    <div className='w-full'>
                      <span className='block text-xs mb-1 font-medium table-text'>{t('lang_to')}</span>
                      <label className='relative block w-full cursor-pointer'>
                        <DatePicker
                          className={`${endDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          dateFormat='dd-MM-yyyy'
                        />
                        <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                          <CalendarFill className='table-text w-3 h-3' />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='py-5 max-w-full overflow-x-auto'>
                  <Chart options={options} series={series} type='line' height={300} />
                </div>
              </div>
              <div className='p-4 mx-auto secondary-bg-color'>
                <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1">
                    <a
                      className={`tab ${(openTab === 1? 'focus-tab': 'bg-transparent')}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle='tab'
                      href='#link1'
                    >
                      {t('lang_Channnel')}
                    </a>
                    <a
                      className={`tab ${(openTab === 2? 'focus-tab': 'bg-transparent')}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle='tab'
                      href='#link1'
                    >
                      {t('lang_Location')}
                    </a>
                    <a
                     className={`tab ${(openTab === 3? 'focus-tab': 'bg-transparent')}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle='tab'
                      href='#link1'
                    >
                      {t('lang_Devices')}
                    </a>
                </div>
                <div className='relative flex flex-col min-w-0 break-words secondary-bg-color w-full'>
                  <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
                    {/* <GoogleCustomePageViewReport/> */}
                    <div id='chart' className='text-center'>
                      <ReactApexChart
                        options={pieoptions}
                        series={channelseries}
                        label={channellable}
                        type='pie'
                        width={400}
                        height={400}
                      />
                    </div>
                  </div>
                  <div className={openTab === 2 ? 'block' : 'hidden'} id='link1'>
                    {/* <GoogleCustomePageViewReport/> */}
                    <div id='chart' className='text-center'>
                      <ReactApexChart
                        options={pieoptions}
                        series={locationseries}
                        label={locationlable}
                        type='pie'
                        width={400}
                        height={400}
                      />
                    </div>
                  </div>
                  <div className={openTab === 3 ? 'block' : 'hidden'} id='link1'>
                    {/* <GoogleCustomePageViewReport/> */}
                    <div id='chart' className='text-center'>
                      <ReactApexChart
                        options={pieoptions}
                        series={deviceseries}
                        label={devicelable}
                        type='pie'
                        width={400}
                        height={400}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GoogleCustomePageViewReport />
        </div>
      </div>
    </>
  );
};

export default GoogleCustomeReport;
