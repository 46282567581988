import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
const UpdateStaff = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [staffinfo, setStaffinfo] = useState({
    email: '',
    role: '',
  });
  const [validEmail, setValidEmail] = useState(false);
  const [validEmailErr, setValidEmailErr] = useState(false);
  const [formSpinner, setFormSpinner] = useState(true);
  const GetStaffInfo = async () => {
    setFormSpinner(true);
    let ApiCall = await API.get(`/admin/client/getParticularStaff/${params?.staff_id}`);
    const result = decryptData(ApiCall?.data)
    let Staffdata = result?.data;
    setStaffinfo({ email: Staffdata.email, role: Staffdata.role });
    setFormSpinner(false);
  };
  const { email, role } = staffinfo;

  const HandleInput = (e) => {
    if(e.target.name === 'email') {
      setValidEmailErr(false);
    }
    setIsChange(true);
    setStaffinfo({ ...staffinfo, [e.target.name]: e.target.value });
  };
  const emailVerify = async () => {
    if (email?.length >= 3) {
      try {
        const storeApiData = await API.get(`/super/admin/tenant/validemail/${email}`);
        if (storeApiData.status === 200 || storeApiData.status === 304) {
          setValidEmail(true);
          setValidEmailErr(false);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Email_is_invalid'));
        setSeverity('error');
        setValidEmail(false);
        setValidEmailErr(true);
      }
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const UpdateStaff = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData(staffinfo)
      let ApiCall = await API.put(`/admin/client/updateStaff/${params?.staff_id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/team',
          title: 'edit team',
        });
        navigate('/team');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [roles, setRoles] = useState();

  // Get Role Names
  const GetRoleNames = async () => {
    try {
      let ApiCall = await API.get(`/admin/role/getRoleName`);
      const result = decryptData(ApiCall?.data)
      setRoles(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetStaffInfo();
    GetRoleNames();
  }, [params?.staff_id]);
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/team',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit team',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/team',
        title: 'edit team',
      });
    } else {
      navigate('/team');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_team'), path: '/team' },
                { name: t('lang_Update_Team'), path: '/team/update-staff' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_Team')} - {email}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                </div>
              </div>
            ) : (
              <>
                <div className='secondary-bg-color border main-border-color  rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='mb-4'>
                    <label htmlFor='email'>{t('lang_Email_Adddres')}</label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='email'
                      name='email'
                      disabled
                      value={email}
                      onChange={HandleInput}
                      onBlur={emailVerify}
                      placeholder='Email addres'
                    />
                    {validEmailErr ?
                      <p className='text-red-500 text-sm font-medium'>{t('lang_Email_is_invalid')}</p>
                      : <></>
                    }
                  </div>
                  <div>
                    <label htmlFor='role' className='capitalize'>
                      {t('lang_role')}
                    </label>
                    <Autocomplete
                      className='autocomplete'
                      options={roles?.data?.length > 0 ? roles?.data : []}
                      getOptionLabel={(role) => role.name}
                      onChange={(e, v) =>
                        v ? (setStaffinfo({ ...staffinfo, role: v.name }), setIsChange(true)) : null
                      }
                      value={roles?.data?.find((rrole) => rrole.name === role) || null}
                      renderInput={(category) => (
                        <TextField
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          placeholder='Select role'
                          className='cursor-pointer '
                          {...category}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Staff')}
            </Link>
            {role === '' || email === '' || !validEmail ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span> {t('lang_Update_Team')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[125px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_Saving')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => UpdateStaff(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span> {t('lang_Update_Team')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStaff;
