import React from 'react';
import EditUpSellProductPageStyleContainer from './EditUpSellProductPageStyleContainer';

const EditUpSellProductPageStyleSection = ({
  upSellProductDesign,
  setUpSellProductDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditUpSellProductPageStyleContainer
        upSellProductDesign={upSellProductDesign}
        setUpSellProductDesign={setUpSellProductDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditUpSellProductPageStyleSection;
