import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { EyeOff, Eye } from 'feather-icons-react/build/IconComponents';
import Recaptcha from 'react-recaptcha';
import API from '../../API';
import Notification from '../Notification';
import { SITE_LOGO } from '../../Assets/StaticData/StaticImage';
import { t } from 'i18next';

const Register = () => {
  const navigate = useNavigate();
  const [err, seterr] = useState(false);
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  const [success, setsuccess] = useState(false);
  const [captchaVarify, setCatptcha] = useState(false);
  const [RegData, setRegData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: ''
  });
  const {
    email,
    password,
    first_name,
    last_name
  } = RegData;

  //Show And Hide Password
  const [eyeopen, setEyeopen] = useState(false);
  const [loader, setLoader] = useState(false);
  // get All Entered  Input Data In our UseState
  let name, value;
  const HandleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setRegData({ ...RegData, [name]: value });
  };
  const [validEmail, setValidEmail] = useState(false);
  const [validEmailErr, setValidEmailErr] = useState(false);
  const emailVerify = async () => {
    if (email?.length >= 3) {
      try {
        const storeApiData = await API.get(`/super/admin/tenant/validemail/${email}`);
        if (storeApiData.status === 200 || storeApiData.status === 304) {
          setValidEmail(true);
          setValidEmailErr(false);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Email_is_invalid'));
        setSeverity('error');
        setValidEmail(false);
        setValidEmailErr(true);
      }
    }
  };
  // Api Call for Register
  const HandleRegister = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const register = await API.post('/admin/user/register', RegData);

      if (register.status === 200 || register.status === 304) {
        setRegData({
          email: '',
          password: '',
          first_name: '',
          last_name: ''
        })
        setIsNotification(true)
        setNotificationMsg('Registration successfully!')
        setSeverity('success')
      } else {
        setIsNotification(true)
        setNotificationMsg('Please Enter The Field Values Properly')
        setSeverity('warn')
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setLoader(false);
    }
  };

  //captcha
  const RecaptchaLoaded = () => {
  };
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='bg flex h-screen w-screen items-center justify-center'>
        <div className='w-full sm:w-8/12 xl:w-2/5 mx-auto my-10 bg-white p-5 rounded-[6px] border border-gray-300'>
          <div>
            <img className='mx-auto h-8 w-auto' src={SITE_LOGO} alt='Your Company' />
            <h4 className='mt-4 text-center text-xl font-bold tracking-tight text-gray-900'>
              {t('lang_Register_To_Your_Account')}
            </h4>
          </div>
          <div>
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='rounded-[6px]  space-y-4 shadow-sm'>
              <div className='grid grid-cols-2 gap-5'>
                <div>
                  <label
                    htmlFor='first_name'
                    className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                  >
                    {t('lang_firstname')}<span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    id='first_name'
                    name='first_name'
                    autoFocus
                    tabIndex={1}
                    type='text'
                    value={first_name}
                    required
                    onChange={HandleInput}
                    className={`relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]`}
                    placeholder='Enter your first name'
                  />
                </div>
                <div>
                  <label
                    htmlFor='last_name'
                    className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                  >
                    {t('lang_lastname')}<span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    id='last_name'
                    name='last_name'
                    tabIndex={2}
                    type='text'
                    value={last_name}
                    required
                    onChange={HandleInput}
                    className={`relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]`}
                    placeholder='Enter your last name'
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='email-address'
                  className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                >
                  {t('lang_email_address')}<span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  tabIndex={3}
                  value={email}
                  required
                  onChange={HandleInput}
                  className={`relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]`}
                  placeholder='Enter your email address'
                  onBlur={emailVerify}
                />
                {validEmailErr && (
                  <p className='text-sm text-red-500 ps-1'>{t('lang_Email_is_invalid')}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor='password'
                  className='capitalize text-gray-600 text-[13px] font-medium'
                >
                  {t('lang_password')}<span className='text-red-500 ms-1'>*</span>
                </label>
                <div className='relative flex items-center justify-between w-full'>
                  <input
                    id='password'
                    name='password'
                    type={eyeopen ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      HandleInput(e);
                    }}
                    required
                    className='border w-full outline-none rounded-[6px] border-gray-300 px-4 py-2 text-sm'
                    placeholder='Password'
                  />
                  <div className='absolute right-3'>
                    {!eyeopen ? (
                      <Eye
                        className='w-4 h-4 text-slate-400 z-10'
                        onClick={() => {
                          setEyeopen(!eyeopen);
                        }}
                      />
                    ) : (
                      <EyeOff
                        className='w-4 h-4 text-slate-400 z-10'
                        onClick={() => {
                          setEyeopen(!eyeopen);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-3'>
              <Recaptcha
                sitekey={process.env.REACT_APP_SITE_KEY}
                render='explicit'
                onloadCallback={RecaptchaLoaded}
                verifyCallback={(response) => (response ? setCatptcha(true) : setCatptcha(false))}
              />
              {validEmailErr || !validEmail || !captchaVarify || !email || !password || !first_name || !last_name ? (
                <>
                  <button className='mt-4 w-full py-2 text-white opacity-50 cursor-not-allowed rounded-[6px] bg-btn text-[14px]'>
                    {t('lang_Register')}
                  </button>
                </>
              ) : loader ? (
                <>
                  <button className='mt-4 w-full py-2 text-white btn text-[14px] space-x-2'>
                  <div class='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-yellow-1000 rounded-full'></div>
                    {t('lang_loading')}...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className='mt-4 w-full py-2 text-white btn text-[14px] space-x-2'
                    onClick={HandleRegister}
                  >
                    {t('lang_Register')}
                  </button>
                </>
              )}
            </div>
            <div className='flex flex-wrap justify-center w-full items-center mt-2 space-x-1'>
              {t('lang_Already_Registered')}?
              <Link
                className='cursor-pointer text-link mt-0 inline-block hover:underline transition-all duration-150 text-sm font-medium'
                to='/'
              >
                {t('lang_Login')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
