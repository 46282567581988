import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import {
  ArrowLeft,
  Code,
  Edit,
  PlusCircle,
  Trash,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import ColorPicker from '../Color/ColorPicker';
import NoDataFound from '../Main/NoDataFound';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import ReactDragListView from 'react-drag-listview';

const EditSliderGroup = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const [status, setstatus] = useState(true);
  const [nodata, setNodata] = useState(false);
  const [groupTitle, setGroupTitle] = useState();
  const [image, setimage] = useState();
  const [value, setValue] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [addSlide, setAddSlide] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [slideData, setSlideData] = useState({
    slider_button_color: '#818181',
    slider_button_text: '',
    slider_button_text_color: '#000000',
    slider_button_hover_color: '#000000',
    slider_button_text_hover_color: '#818181',
    slider_button_link: '',
    slider_button_redius: '',
    text_position: 'left',
  });
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [editSliderId, setEditSliderId] = useState(null);

  useEffect(() => {
    handleGetPerticularSliderGroup();
  }, []);
  const handleGetPerticularSliderGroup = async () => {
    setDataLoading(true);
    try {
      const apiCall = await API.get(`/admin/slider/getSliderGroup/${params?.groupId}`);
      const result = decryptData(apiCall?.data);
      setGroupTitle(result?.data?.slider_title);
      setstatus(result?.data?.status);
      setStoreSlides(result?.data?.sliders);
    } catch (error) {
      setNodata(true);
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };
  const [dataErr, setDataErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [storeSlides, setStoreSlides] = useState([]);
  const handleAddSlide = async () => {
    if (!image) {
      setDataErr(true);
    } else {
      setDataErr(false);
      let sliderBody;
      if (isVisibleButton) {
        sliderBody = {
          slider_text: value,
          slider_img: image,
          slider_button_color: slideData?.slider_button_color,
          slider_button_text: slideData?.slider_button_text,
          slider_button_text_color: slideData?.slider_button_text_color,
          slider_button_link: slideData?.slider_button_link,
          slider_button_hover_color: slideData?.slider_button_hover_color,
          slider_button_text_hover_color: slideData?.slider_button_text_hover_color,
          slider_button_redius: slideData?.slider_button_redius,
          text_position: slideData?.text_position,
          is_button_visible: isVisibleButton,
        };
      } else {
        sliderBody = {
          slider_text: value,
          slider_img: image,
          text_position: slideData?.text_position,
          is_button_visible: isVisibleButton,
        };
      }
      if (!isEdit) {
        try {
          const payload = encryptData({
            slider_group_id: params?.groupId,
            slider: sliderBody,
          });
          await API.post(`/admin/slider/addSlider`, payload);
          setSlideData({
            slider_button_color: '#818181',
            slider_button_text: '',
            slider_button_text_color: '#000000',
            slider_button_link: '',
            slider_button_hover_color: '#000000',
            slider_button_text_hover_color: '#818181',
            slider_button_redius: '',
            text_position: 'left',
          });
          setIsVisibleButton(false);
          setValue();
          setimage();
          setAddSlide(false);
          handleGetPerticularSliderGroup();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const payload = encryptData(sliderBody);
          await API.put(`/admin/slider/updateSlider/${editId}`, payload);
          setIsEdit(false);
          setAddSlide(false);
          setIsVisibleButton(false);
          setSlideData({
            slider_button_color: '#818181',
            slider_button_text: '',
            slider_button_text_color: '#000000',
            slider_button_link: '',
            slider_button_hover_color: '#000000',
            slider_button_text_hover_color: '#818181',
            slider_button_redius: '',
            text_position: 'left',
          });
          setValue();
          setimage();
          handleGetPerticularSliderGroup();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleEditSlider = async (e, elem, key) => {
    e.preventDefault();
    if (key === 'add') {
      setEditId();
      setValue('');
      setimage('');
      setSlideData({
        slider_button_color: '#818181',
        slider_button_text: '',
        slider_button_text_color: '#000000',
        slider_button_hover_color: '#000000',
        slider_button_text_hover_color: '#818181',
        slider_button_link: '',
        slider_button_redius: '',
        text_position: 'left',
      });
      setIsVisibleButton(false);
      setAddSlide(true);
      setIsEdit(false);
    } else {
      setEditId(elem?._id);
      setValue(elem?.slider_text);
      setimage(elem?.slider_img);
      setSlideData({
        slider_button_color: elem?.slider_button_color || '#818181',
        slider_button_text: elem?.slider_button_text,
        slider_button_link: elem?.slider_button_link,
        slider_button_redius: elem?.slider_button_redius,
        text_position: elem?.text_position,
        slider_button_hover_color: elem?.slider_button_hover_color || '#000000',
        slider_button_text_hover_color: elem?.slider_button_text_hover_color || '#818181',
        slider_button_text_color: elem?.slider_button_text_color || '#000000',
      });
      setIsVisibleButton(elem?.is_button_visible);
      setAddSlide(true);
      setIsEdit(true);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditSliderGroup = async (e, value, key) => {
    e.preventDefault();
    if (!key) {
      setBtnLoading(true);
    }
    try {
      const payload = encryptData({
        slider_title: groupTitle,
        status: value,
      });
      await API.put(`/admin/slider/updateSliderGroup/${params?.groupId}`, payload);
      handleGetPerticularSliderGroup();
      if (!key) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/module-setting/slider-group',
          title: 'edit slider group',
        });
        navigate('/module-setting/slider-group');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/slider/deleteSlider`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Slider Removed SuccesFully!');
        setSeverity('success');
        handleGetPerticularSliderGroup();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const UpdateSliderStatus = async (id, value) => {
    try {
      const payload = encryptData({
        slider_status: value,
      });
      const data = await API.put(`/admin/slider/updateSlider/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        handleGetPerticularSliderGroup();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortSlider = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/slider/updateSlider/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(storeSlides);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setStoreSlides(items);
  //   sortSlider(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...storeSlides];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setStoreSlides(newItems);
      sortSlider(toIndex, editSliderId);
      setEditSliderId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeSlides?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/slider-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit slider group',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/slider-group',
        title: 'edit slider group',
      });
    } else {
      navigate('/module-setting/slider-group');
    }
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'slider_button_color') {
      setSlideData({ ...slideData, slider_button_color: value });
    } else if (openState === 'slider_button_text_color') {
      setSlideData({ ...slideData, slider_button_text_color: value });
    } else if (openState === 'slider_button_hover_color') {
      setSlideData({ ...slideData, slider_button_hover_color: value });
    } else if (openState === 'slider_button_text_hover_color') {
      setSlideData({ ...slideData, slider_button_text_hover_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Slider'
        subheading='Do you really want to delete this slider from this group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[130px]'>
          <div className='flex items-center space-x-2 pb-[30px]'>
            <button
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_Module_Setting'), path: '/module-setting' },
                  { name: t('lang_Slider_Group'), path: '/module-setting/slider-group' },
                  {
                    name: t('lang_Edit_slider_group'),
                    path: '/module-setting/slider-group/edit-slider-group',
                  },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Edit_slider_group')}
              </h4>
            </div>
          </div>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='w-full sm:w-[80%] xl:w-[70%] mx-auto'
          >
            <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='image'>
                  Slider Group Title<span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  type='text'
                  className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm'
                  placeholder='Slider group title'
                  value={groupTitle}
                  onChange={(e) => {
                    setGroupTitle(e.target.value);
                    setIsChange(true);
                  }}
                />
                {dataErr && (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
                )}
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label htmlFor='image'>{t('lang_status')} :</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={status === true}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      handleEditSliderGroup(e, !status, 'status');
                    }}
                  />
                </label>
              </div>
              <hr className='border-t main-border-color my-4'></hr>
              <div className='flex items-end justify-between'>
                <div className='flex items-center justify-between w-full text-sm'>
                  {storeSlides?.length === 0 ? (
                    <div>
                      {t('lang_Add_sliders_for_show_in_this_group')}
                      <span className='text-red-500 ms-1'>*</span>
                    </div>
                  ) : (
                    <div className='flex items-center'>
                      {selectedRowsId && selectedRowsId?.length > 0 && (
                        <>
                          <span className='text-sm px-3'>
                            {selectedRowsId?.length} {t('lang_selected')}
                          </span>
                          |
                          <button
                            className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmationPopup(true);
                            }}
                          >
                            <Trash2 className='w-3.5 h-3.5' />
                            <span>{t('lang_delete_selection')}</span>
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditSlider(e, '', 'add');
                      setAddSlide(true);
                    }}
                    className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                  >
                    <PlusCircle className='w-4 me-2 h-4' />
                    {t('lang_Add_Slider')}
                  </span>
                </div>
              </div>
              {dataLoading && !storeSlides ? (
                <TableSkeleton forTable='category' />
              ) : storeSlides?.length > 0 ? (
                <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                  <table className='w-full'>
                    <thead>
                      <tr className='w-full'>
                        <td className='px-3 table-head-checkbox'>
                          <Checkbox
                            checked={selectedRowsId?.length === storeSlides?.length}
                            indeterminate={
                              selectedRowsId?.length !== storeSlides?.length &&
                              selectedRowsId?.length > 0
                            }
                            onChange={() => {
                              handleSelectRow('all');
                            }}
                          />
                        </td>
                        <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                        <td className='w-full px-3'>{t('lang_Slider_Text')}</td>
                        <td className='min-w-[200px] max-w-[200px] px-3'>
                          {t('lang_Button_Text')}
                        </td>
                        <td className='min-w-[200px] max-w-[200px] px-3'>{t('lang_link')}</td>
                        <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                          {t('lang_status')}
                        </td>
                        <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                          {t('lang_action')}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <ReactDragListView {...dragProps}>
                        {storeSlides?.map((row, index) => (
                          <tr
                            style={{
                              // boxShadow: snapshot.isDragging
                              //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                              //   : 'none',
                              background: 'white',
                            }}
                            className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                              } select-none flex w-full`}
                            key={index}
                            onDragStart={() => setEditSliderId(row?._id)}
                          >
                            <td className='px-3 table-body-checkbox'>
                              <Checkbox
                                checked={
                                  selectedRowsId?.length > 0 &&
                                  selectedRowsId?.find((x) => x === row?._id)
                                }
                                className='inline-block'
                                onChange={() => handleSelectRow(row?._id)}
                              />
                            </td>
                            <td className='table-image px-3 min-w-[80px]'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                width={80}
                                height={50}
                                src={row.slider_img || NOPHOTO_IMAGE}
                                className='p-1 max-w-full max-h-full'
                                alt='slider image'
                              />
                            </td>
                            <td className='w-full px-3'>
                              <span className='line-clamp-2'>
                                {row?.slider_text ? row?.slider_text.replace(/<[^>]*>/g, ' ') : ''}
                              </span>
                            </td>
                            <td className='min-w-[200px] max-w-[200px] px-3'>
                              <span className='line-clamp-2'>{row.slider_button_text || '-'}</span>
                            </td>
                            <td className='min-w-[200px] max-w-[200px] px-3'>
                              <span
                                className='line-clamp-1'
                                dangerouslySetInnerHTML={{
                                  __html: row.slider_button_link || '-',
                                }}
                              ></span>
                            </td>
                            <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                              <label className='relative flex justify-end w-full'>
                                <FormControlLabel
                                  checked={row?.slider_status}
                                  control={<MuiSwitch />}
                                  onChange={() => {
                                    {
                                      UpdateSliderStatus(row?._id, !row.slider_status);
                                    }
                                  }}
                                />
                              </label>
                            </td>
                            <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                              <div className='space-x-2 w-full text-right'>
                                <button onClick={(e) => handleEditSlider(e, row, 'edit')}>
                                  <Edit className='w-[17px] h-[17px] text-black-500' />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setConfirmationPopup(true);
                                    setSelectedRowsId([row?._id]);
                                  }}
                                >
                                  <Trash className='w-[17px] h-[17px] text-red-500' />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </ReactDragListView>
                    </tbody>
                  </table>
                </div>
              ) : (
                <NoRecords />
              )}

            </div>
          </form>
          {!html?.htmlPP ? (
            <Dialog
              open={addSlide}
              TransitionComponent={Transition}
              keepMounted
              onClose={(e) => {
                e.preventDefault();
                setAddSlide(false);
                setSlideData({
                  slider_button_color: '#818181',
                  slider_button_text: '',
                  slider_button_text_color: '#000000',
                  slider_button_link: '',
                  slider_button_hover_color: '#000000',
                  slider_button_text_hover_color: '#818181',
                  slider_button_redius: '',
                  text_position: 'left',
                });
              }}
              aria-describedby='alert-dialog-slide-description'
            >
              <form
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5'
              >
                <div className='flex text-primary border-b main-border-color pb-3 items-center justify-between'>
                  <h6 className='font-medium text-xl'>{isEdit ? 'Edit slider' : 'Add slider'}</h6>
                  <button
                    className='close-btn'
                    onClick={(e) => {
                      e.preventDefault();
                      setAddSlide(false);
                      setSlideData({
                        slider_button_color: '#818181',
                        slider_button_text: '',
                        slider_button_text_color: '#000000',
                        slider_button_link: '',
                        slider_button_redius: '',
                        slider_button_hover_color: '#000000',
                        slider_button_text_hover_color: '#818181',
                        text_position: 'left',
                      });
                    }}
                  >
                    <X className='w-4 h-4' />
                  </button>
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>{t('lang_Slider_Image')}</label>
                  <ImageUploader
                    alreadyImage={image}
                    handleSetImage={handleSetImage}
                    folder='slider'
                    format='image'
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex justify-between items-center'>
                    <label htmlFor='image'>{t('lang_Slider_Text')}</label>
                    <section
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </section>
                  </div>

                  <ReactQuill
                    modules={module}
                    ref={quillRef}
                    className='w-full h-auto'
                    theme='snow'
                    value={value}
                    onChange={handleQuillChange}
                  />
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full mb-4'>
                  <div className='border w-full main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-1 text-sm'>
                    <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
                      {t('lang_Text_Position')}
                    </label>
                    <div className='flex w-full table-text items-center text-sm justify-between px-4'>
                      <span
                        className={`${slideData?.text_position === 'left'
                            ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setSlideData({ ...slideData, text_position: 'left' })}
                      >
                        {t('lang_Left')}
                      </span>
                      <span
                        className={`${slideData?.text_position === 'center'
                            ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setSlideData({ ...slideData, text_position: 'center' })}
                      >
                        {t('lang_Center')}
                      </span>
                      <span
                        className={`${slideData?.text_position === 'right'
                            ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setSlideData({ ...slideData, text_position: 'right' })}
                      >
                        {t('lang_Right')}
                      </span>
                    </div>
                  </div>
                  <div className='w-full border main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-2 text-sm justify-between'>
                    <p className='text-[13px] font-[500] table-text capitalize'>
                      {t('lang_Visible_Slider_Button')} -
                    </p>
                    <label className='relative flex ms-2 items-center cursor-pointer'>
                      <FormControlLabel
                        checked={isVisibleButton === true}
                        control={<MuiSwitch />}
                        onChange={() => setIsVisibleButton(!isVisibleButton)}
                      />
                    </label>
                  </div>
                </div>
                {isVisibleButton ? (
                  <>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Text')}</label>
                        <input
                          type='text'
                          name='slider_button_text'
                          value={slideData?.slider_button_text}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                          onChange={(e) =>
                            setSlideData({ ...slideData, slider_button_text: e.target.value })
                          }
                          placeholder='e.g. Click here'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Link')}</label>
                        <input
                          type='text'
                          name='slider_button_link'
                          value={slideData?.slider_button_link}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                          placeholder='Add link'
                          onChange={(e) =>
                            setSlideData({ ...slideData, slider_button_link: e.target.value })
                          }
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Border_Radius')}</label>
                        <input
                          type='number'
                          name='slider_button_link'
                          value={slideData?.slider_button_redius}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                          placeholder='e.g. 4'
                          onChange={(e) =>
                            setSlideData({ ...slideData, slider_button_redius: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_color}
                            </span>
                          </div>
                          {openState === 'slider_button_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_text_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_text_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_text_color}
                            </span>
                          </div>
                          {openState === 'slider_button_text_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_text_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Hover_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_hover_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_hover_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_hover_color}
                            </span>
                          </div>
                          {openState === 'slider_button_hover_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_hover_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Hover_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_text_hover_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_text_hover_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_text_hover_color}
                            </span>
                          </div>
                          {openState === 'slider_button_text_hover_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_text_hover_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <hr className='border-t main-border-color mt-3'></hr>
                <button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddSlide();
                  }}
                  className='btn text-sm text-white'
                >
                  {isEdit ? 'Edit slider' : 'Add slider'}
                </button>
              </form>
            </Dialog>
          ) : (
            html?.htmlPP && (
              <SourceCode
                open={html?.htmlPP}
                htmlCode={html?.code}
                closeCode={closeCode}
                saveHTML={saveHTML}
              />
            )
          )}
          <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
            <div className='w-[90%] flex items-center justify-end lg:w-[70%] xl:w-[68%] mx-auto'>
              <div>
                {groupTitle === '' || !groupTitle || storeSlides?.length === 0 ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Edit_slider_group')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[151px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_Saving')}</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleEditSliderGroup(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Edit_slider_group')}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditSliderGroup;
