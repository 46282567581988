import {
  Backdrop,
  CircularProgress,
  Dialog,
  Tooltip
} from '@mui/material';
import { ChevronDown, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import module from '../../controllers/QuillToolBar';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import { BardSvg } from '../../Assets/StaticData/BardSvg';

function SendMail({ openSendMail, setOpenSendMail }) {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [severity, setSeverity] = useState();
  const [mailValue, SetMailValue] = useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleSendMail = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        description: mailValue,
        subject: mailSubject,
      })
      const data = await API.post('/admin/newsletter/sendemails', payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/newsletter',
          title: 'send mail',
        });
        setNotificationMsg('Mail send SuccessFully');
        setSeverity('success');
        setMailSubject('');
        SetMailValue('');
        setErr(false);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [AiLoader, setAiloader] = useState(false);
  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    features: '',
  });
  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };
  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateDesc(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      features: '',
    });
  };
  const generateDesc = async () => {
    setAiloader(true);
    try {
      const payload = encryptData({
        keypoints: gerenateAiData?.features,
      })
      let ApiCall = await API.post('/admin/ai/generate_mail', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        SetMailValue(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };
  useEffect(() => {
    if (mailValue?.length > 0 || mailSubject?.length > 0) {
      setSaveChanges({
        backLink: '/dashboard',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'send mail',
      });
    } else {
      setSaveChanges({
        backLink: '/dashboard',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'send mail',
      });
    }
  }, [mailValue, mailSubject]);
  const checkChanges = () => {
    if (mailValue?.length > 0 || mailSubject?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/dashboard',
        title: 'send mail',
      });
    } else {
      navigate('/dashboard');
    }
  };
  return (
    <>      
      <Dialog
        open={openSendMail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenSendMail(false);
        }}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'>
          {AiLoader && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={AiLoader}
              className='flex flex-col'
            >
              <CircularProgress color='inherit' />
              <p>{t('lang_Please_Wait')}</p>
            </Backdrop>
          )}
          {isNotification && notificationMsg && (
            <Notification message={notificationMsg} close={handleClose} severity={severity} />
          )}
          <div className='w-full flex items-center justify-between'>
            <div className='text-base font-medium text-primary'>
              <span className='font-medium text-lg'>{t('lang_Send_Mail')}</span>
              <p className='text-xs table-text font-normal'>{t('lang_Send_Mail_Details')}</p>
            </div>
            <div>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  ; setOpenSendMail(false)
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
          </div>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='w-full mx-auto rounded-[6px]'
          >
            <div className='mb-4'>
              <label htmlFor='description'>
                {t('lang_Subject')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className={`w-full px-3 py-2 border rounded-[4px] focus:outline-none ${err && mailSubject === '' ? 'border-red-500' : 'main-border-color'
                  }`}
                type='text'
                id='description'
                name='subject'
                value={mailSubject}
                onChange={(e) => {
                  setMailSubject(e.target.value);
                }}
                placeholder='Mail subject'
                required
              />
              {err && mailSubject === '' && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Subject')}</p>
              )}
            </div>
            <div className='mb-4'>
              <div className='flex space-x-3 justify-between items-center mb-1'>
                <label htmlFor='Description' className='capitalize'>
                  {t('lang_description')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <span
                  className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                  onClick={() => {
                    setGenareteAiPopup(true);
                  }}
                >
                  <BardSvg /> {t('lang_get_description')}
                </span>
              </div>
              <ReactQuill
                modules={module}
                className={`w-full rounded-[4px] ${err && mailValue === '' ? 'border border-red-500' : ''
                  }`}
                theme='snow'
                // value={mailValue}
                value={mailValue?.length && mailValue?.split(' ').slice(0, 5000).join(' ')}
                onChange={SetMailValue}
                required
              />
              <div className='flex items-center justify-between'>
                {err && mailValue === '' && (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Subject')}</p>
                )}
                <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Words_Allowed')}</p>
              </div>
            </div>
            <div>
              {mailValue === '' || mailSubject === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Send_Mail_To_All')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[120px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleSendMail(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Send_Mail_To_All')}</span>
                </button>
              )}
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center text-primary justify-between secondary-bg-color border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='flex items-center justify-betweencapitalize font-medium gap-1'>
                <BardSvg />  {t('lang_Generate_Mail_Description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[6px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>

              <button type='submit' className='btn px-5 py-2 text-white rounded-[6px] capitalize'>
                {t('lang_done')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SendMail;
