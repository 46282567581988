import React from 'react';
import EditProductPriceStyleSection from './EditProductPriceStyleContainer';

const EditProductPriceStyleContainer = ({
    productPriceDesign,
    setProductPriceDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductPriceStyleSection
        productPriceDesign={productPriceDesign}
        setProductPriceDesign={setProductPriceDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductPriceStyleContainer;
