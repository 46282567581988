import { Pagination, Popover, Tooltip } from '@mui/material';
import { ArrowLeft, Eye, Filter, PlusCircle } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import AddPortfolioComments from './AddPortfolioComments';

const PortfolioComments = () => {
  const [limit, setLimit] = useState(10);
  const [Page, setPage] = useState(getInitialPage());
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [comments, setComments] = useState();
  const [sortBy, setSortBy] = useState('');
  const { setPlanPermission } = useContext(PlanPermission);
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetPortfolioComments();
  };
  const [search, setSearch] = useState('');
  var startRecord = (comments?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(comments?.data?.currentPage * limit, comments?.data?.totalRecords);
  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetPortfolioComments();
    }
  }, [search]);
  // get comments
  const GetPortfolioComments = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/portfolio/GetPortfolioComments?search=${search?.length > 2 ? search : ''
        }&page=${Page}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setComments(result);
      }
      if (Number(startRecord) > Number(data.data?.data?.totalRecords)) {
        setPage(Page - 1);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'portfolio' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetPortfolioComments();
  }, [Page, limit]);
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [isAddComment, setIsAddComment] = useState(false);
  const commentRef = useRef();
  const closeAddComment = (value) => {
    if (value === 'call api') {
      GetPortfolioComments();
      setIsAddComment(false);
    } else {
      setIsAddComment(false);
    }
  };
  useEffect(() => {
    if (isAddComment) {
      commentRef.current.focus();
    }
  }, [isAddComment])
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between z-40 w-full overflow-auto pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Portfolio_Comments'), path: '/portfolio-comments' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_Portfolio_Comments')}{' '}
                </h4>
              </div>
            </div>
          </div>
          <div className='flex items-center'>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsAddComment(true);
              }}
              className='btn flex items-center text-white text-sm me-2'
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />{' '}
              {t('lang_Add_Portfolio_Comments')}
            </button>
          </div>
        </div>
        <div className='flex items-center justify-end mb-2'>
            {comments?.data?.totalRecords > 0 && <Tooltip title='Filter' arrow>
              <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                <button className=''>
                  <Filter className='h-5 w-5' />
                </button>
              </div>
            </Tooltip>}
        </div>
        <div
          className={'flex w-full h-full overflow-hidden relative'}
        >
          <div className='w-full space-y-1'>
            {dataLoading && !comments ? (
              <TableSkeleton forTable='portfolio' />
            ) : comments?.data?.records?.length > 0 ? (
              <div className='w-full  transition-all duration-100 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <td className='thead-image px-3'>{t('lang_image')}</td>
                      <td className='min-w-[300px] max-w-[300px] px-3'>
                        <div className='flex items-center'>
                          <button
                            className={`uppercase flex ${iconVisible && sortBy === 'title' ? 'text-gray-700' : ''
                              }`}
                            onMouseEnter={() => {
                              setIconVisible(true);
                              setSortBy('title');
                            }}
                            onMouseLeave={() => {
                              setIconVisible(false);
                              setSortBy('');
                            }}
                            onClick={() => SortData('title')}
                          >
                            {t('lang_title')}
                            {iconVisible && sortBy === 'title' ? (
                              !orderBy.isDecending ? (
                                <CaretUpFill className='ms-2' />
                              ) : (
                                <CaretDownFill className='ms-2' />
                              )
                            ) : (
                              <></>
                            )}
                          </button>
                        </div>
                      </td>
                      <td className='w-full px-3'>
                        <div className='flex items-center w-full justify-end'>
                          <button
                            className={`uppercase flex ${iconVisible && sortBy === 'comment_count' ? 'text-gray-700' : ''
                              }`}
                            onMouseEnter={() => {
                              setIconVisible(true);
                              setSortBy('comment_count');
                            }}
                            onMouseLeave={() => {
                              setIconVisible(false);
                              setSortBy('');
                            }}
                            onClick={() => SortData('comment_count')}
                          >
                            {iconVisible && sortBy === 'comment_count' ? (
                              !orderBy.isDecending ? (
                                <CaretUpFill className='me-2' />
                              ) : (
                                <CaretDownFill className='me-2' />
                              )
                            ) : (
                              <></>
                            )}
                            {t('lang_Comment_Count')}
                          </button>
                        </div>
                      </td>
                      <td className='min-w-[100px] max-w-[100px] px-3 text-right uppercase'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {comments?.data?.records?.map((elem, index) => {
                      return (
                        <tr key={index} onClick={() => navigate(`/portfolio-comments/${elem?._id}`)}>
                          <td className='table-image px-3 min-w-[80px]'>
                            <img
                              width={40}
                              height={50}
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              className='p-1 max-h-full'
                              alt={elem?.title}
                            />
                          </td>
                          <td className='min-w-[300px] max-w-[300px] px-3'>
                            <p className='line-clamp-2'>{elem?.title || '-'}</p>
                          </td>
                          <td className='w-full px-3'>
                            <p className='line-clamp-2 w-full text-right'>
                              {elem?.comment_count || '-'}
                            </p>
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                            <Link
                              to={`/portfolio-comments/${elem?._id}`}
                              className='w-full text-center'
                            >
                              <Tooltip title='View' arrow>
                                {' '}
                                <button>
                                  <Eye className='w-[17px] h-[17px]' />
                                </button>
                              </Tooltip>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
            {/* Paginator */}
            {comments?.data ? (
              <div className='secondary-bg-color flex flex-1 border main-border-color p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                <span className='text-sm font-medium'>
                  {limit === 'all'
                    ? `${comments?.data?.totalRecords}` + ' ' + 'Records'
                    : `${startRecord} - ${endRecord} of ${comments?.data?.totalRecords} Records`}
                </span>
                <div>
                  {comments?.data && comments?.data?.totalPages !== 1 ? (
                    <Pagination
                      count={comments?.data?.totalPages}
                      page={Page}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Popover
            open={filter}
            anchorEl={anchorEl}
            onClose={() => { setFilter(false); setAnchorEl(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
            }}
            className='rounded-[6px]'
          >
            <div className='p-[20px] filter'>
              <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                <Filter className='me-1 w-4 h-4' />
                {t('lang_filter')}
              </p>
              <hr className='main-border-color my-3'></hr>
              <div className='grid gap-y-5'>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                    {t('lang_search')}
                  </span>
                  <label className='relative block w-full'>
                    <input
                      className='w-full placeholder block secondary-bg-color border main-border-color rounded-[6px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                      placeholder='Search Portfolio Comments'
                      type='text'
                      name='search'
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(1);
                      }}
                    />
                  </label>
                </div>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_set_items_limit')}
                  </span>
                  <div className='relative'>
                    <select
                      className='outline-none rounded-[6px] w-full text-slate-500 text-sm border main-border-color px-3 py-1.5'
                      name='limit'
                      value={limit}
                      onChange={(e) => {
                        setPage(1);
                        e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                      }}
                    >
                      {comments?.data?.totalRecords > 10 ? (
                        <>
                          <option value='10'>10</option>
                        </>
                      ) : (
                        <></>
                      )}
                      {comments?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                      {comments?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                      <option value='all'>All</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <AddPortfolioComments
        commentRef={commentRef}
        isAddComment={isAddComment}
        closeAddComment={closeAddComment}
        type='add'
      />
    </>
  );
};

export default PortfolioComments;
