import { FormControlLabel } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill, QuestionCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Loader from '../Loader/Loader';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import ReactDragListView from 'react-drag-listview';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const AddShipping = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [shippingId, setShippingId] = useState(null);

  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isActive, setIsActive] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  const shippingInfo = [
    {
      image: "https://pub-032c6d43db934ca6b027735e13fb15f5.r2.dev/1/product/free-shipping.webp",
      ShippingName: 'Free Shipping',
      charge: '0',
    },
    // { ShippingName: 'Flat Shipping', charge: '10' },
    // { ShippingName: 'Self Shipping', charge: '0' },
    {
      image: "https://pub-032c6d43db934ca6b027735e13fb15f5.r2.dev/1/product/shipprockets.webp",
      ShippingName: 'Shiprocket',
      charge: 'As Per Package',
    },
  ];
  // G}et Shipping Methods
  const [myShippings, setmyShippings] = useState([]);
  const [iconVisible, setIconVisible] = useState(false);
  const [orderByIsDecending, setOrderByisDecending] = useState(false);
  const handlePaymentMethodSort = () => {
    myShippings?.sort((a, b) => {
      const nameA = a?.shipping_method_name.toLowerCase();
      const nameB = b?.shipping_method_name.toLowerCase();
      return orderByIsDecending ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
    });
  };
  const handlePaymentChargeSort = () => {
    orderByIsDecending
      ? myShippings?.sort((a, b) => b.shipping_charge - a.shipping_charge)
      : myShippings?.sort((a, b) => a.shipping_charge - b.shipping_charge);
    setOrderByisDecending(!orderByIsDecending);
  };
  const GetAllShippingMethod = async () => {
    try {
      let Apicall = await API.get('/admin/shippingmethod/getShippingMethods?&order_by=ASC');
      if (Apicall.status === 200 || Apicall.status == 304) {
        const result = decryptData(Apicall?.data);
        setmyShippings(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'shipping' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetAllShippingMethod();
  }, []);

  // selectMethod from radio
  const SelectMethod = async (index) => {
    try {
      if (!myShippings) {
        const payload = encryptData({
          shipping_method_name: shippingInfo[index].ShippingName,
          shipping_charge: shippingInfo[index].charge,
          shipping_logo: shippingInfo[index].image,
        });
        const SP = await API.post('/admin/shippingmethod/addShippingMethod', payload);
      } else {
        const data =
          myShippings &&
          myShippings?.find((x) => x.shipping_method_name === shippingInfo[index].ShippingName);
        if (!data) {
          const payload = encryptData({
            shipping_method_name: shippingInfo[index].ShippingName,
            shipping_charge: shippingInfo[index].charge,
            shipping_logo: shippingInfo[index].image,
          });
          const SP = await API.post('/admin/shippingmethod/addShippingMethod', payload);
        } else {
          setIsNotification(true);
          setNotificationMsg('this method is Already Added in Your Payments');
          setSeverity('warn');
        }
      }
      GetAllShippingMethod();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // UpdateShippingMethod from radio
  const UpdateShippingMethod = async (id, status, shipping_method_name) => {
    let body;

    if (status) {
      body = {
        status: status,
      };
    } else {
      body = {
        default: true,
      };
    }
    try {
      setIsActive(true);
      const payload = encryptData(body);
      let apiCall = await API.put(`/admin/shippingmethod/updateShippingMethod/${id}`, payload);
      if (apiCall.status === 200 || apiCall.status === 304) {
        GetAllShippingMethod();
        if (!status?.length) {
          setIsNotification(true);
          setNotificationMsg(`${shipping_method_name} is set to default`);
          setSeverity('success');
        } else {
          setIsNotification(true);
          setNotificationMsg(
            status === 'enabled'
              ? `${shipping_method_name} is enabled`
              : `${shipping_method_name} is disabled`,
          );
          setSeverity('success');
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  const enabledShippingMethods = myShippings?.map((record) => record?.shipping_method_name);
  const sortedShippingInfo = shippingInfo?.sort((a, b) => {
    const indexA = enabledShippingMethods?.indexOf(a.ShippingName);
    const indexB = enabledShippingMethods?.indexOf(b.ShippingName);
    return indexB - indexA;
  });
  const addshippingcolumns = [
    {
      name: t('lang_Shipping_Name'),
      width: '300px',
      cell: (row) => (
        <>
          {row.image && <span><img src={row.image ?? NOPHOTO_IMAGE} height={30} width={30} /></span>}
          <span className='ml-2'>{row.ShippingName || '-'}</span>
        </>
      ),
      sortable: true,
    },
    {
      name: t('lang_Shipping_Charge'),
      cell: (row) => <span className=''>{row.charge || '-'}</span>,
      sortable: true,
    },
    {
      name: <span className='w-full text-right block pe-2'>{t('lang_action')}</span>,
      width: '150px',
      cell: (row, index) => (
        <div className='flex w-full justify-end'>
          {!myShippings?.find((x) => x.shipping_method_name === row.ShippingName) ? (
            <>
              <button
                href='#'
                className='btn px-4 py-2 font-medium rounded-[6px] text-white text-xs'
                onClick={() => SelectMethod(index)}
              >
                {t('lang_Install')}
              </button>
            </>
          ) : (
            <>
              <button disabled className='bulk-btn cursor-not-allowed'>
                {t('lang_Installed')}
              </button>
            </>
          )}
        </div>
      ),
    },
  ];
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/shippingmethod/updateShippingMethod/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(myShippings);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setmyShippings(items);
  //   sortProduct(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...myShippings];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setmyShippings(newItems);
      sortProduct(toIndex, shippingId);
      setShippingId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto overflow-x-hidden pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Shipping_Methods'), path: '/shipping-methods' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Shipping_Methods')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/settings-and-options/shipping-configuration'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='w-full'>
          {myShippings?.length > 0 ? (
            <div className='w-full mb-5 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <td className='w-full px-3'>
                      <div className='flex items-center'>
                        <button
                          className={`uppercase flex ${
                            iconVisible === 'shipping_method_name' ? 'text-gray-700' : ''
                          }`}
                          onMouseEnter={() => {
                            setIconVisible('shipping_method_name');
                          }}
                          onMouseLeave={() => {
                            setIconVisible('');
                          }}
                          onClick={() => {
                            setOrderByisDecending(!orderByIsDecending);
                            handlePaymentMethodSort();
                          }}
                        >
                          {t('lang_Payment_Method')}
                          {iconVisible === 'shipping_method_name' ? (
                            !orderByIsDecending ? (
                              <CaretUpFill className='ms-2' />
                            ) : (
                              <CaretDownFill className='ms-2' />
                            )
                          ) : (
                            <></>
                          )}
                        </button>
                      </div>
                    </td>
                    <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                      <div className='flex items-center justify-end'>
                        <button
                          className={`uppercase flex ${
                            iconVisible === 'shipping_charge' ? 'text-gray-700' : ''
                          }`}
                          onMouseEnter={() => {
                            setIconVisible('shipping_charge');
                          }}
                          onMouseLeave={() => {
                            setIconVisible('');
                          }}
                          onClick={() => {
                            setOrderByisDecending(!orderByIsDecending);
                            handlePaymentChargeSort();
                          }}
                        >
                          {iconVisible === 'shipping_charge' ? (
                            !orderByIsDecending ? (
                              <CaretUpFill className='me-2' />
                            ) : (
                              <CaretDownFill className='me-2' />
                            )
                          ) : (
                            <></>
                          )}
                          {t('lang_Shipping_Charge')}
                        </button>
                      </div>
                    </td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                      {t('lang_status')}
                    </td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                      {t('lang_default')}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <ReactDragListView {...dragProps}>
                    {myShippings?.map((elem, index) => (
                      <tr
                        style={{
                          // boxShadow: snapshot.isDragging
                          //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                          //   : 'none',
                          background: 'white',
                        }}
                        key={index}
                        onDragStart={() => setShippingId(elem?._id)}
                      >
                        <td className='px-3 w-full flex'>  
                            <div className='flex items-center'>
                              <span><img src={elem?.shipping_logo ?? NOPHOTO_IMAGE} height={30} width={30} /></span>
                              <span className='ml-2'>{elem.shipping_method_name || '-'}</span>
                            </div>
                        </td>
                        <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                          <span className=''>{elem.shipping_charge || '0'}</span>
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                          {elem?.default ? (
                            <label className='relative flex justify-end w-full'>
                              <FormControlLabel
                                checked={elem.status === 'enabled'}
                                control={<MuiSwitch className={`${isActive || elem.default && 'cursor-not-allowed'}`} />}
                                disabled={isActive || elem.default}
                                onChange={() => {
                                  setIsNotification(true);
                                  setNotificationMsg('You can not disabled default method');
                                  setSeverity('warning');
                                }}
                              />
                            </label>
                          ) : (
                            <label className='relative flex justify-end w-full'>
                              <FormControlLabel
                                checked={elem.status === 'enabled'}
                                control={<MuiSwitch className={`${isActive || elem.default && 'cursor-not-allowed'}`} />}
                                disabled={isActive || elem.default}
                                onChange={() => {
                                  UpdateShippingMethod(
                                    elem._id,
                                    elem.status === 'enabled' ? 'disabled' : 'enabled',
                                    elem?.shipping_method_name,
                                  );
                                }}
                              />
                            </label>
                          )}
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-center'>
                          {elem.status === 'disabled' ? (
                            <></>
                          ) : (
                            <>
                              <input
                                id='default-radio-1'
                                type='radio'
                                name='default-radio'
                                checked={elem.default}
                                className='w-4 h-4 text-black-600 primary-bg-color main-border-color'
                                onChange={() =>
                                  UpdateShippingMethod(elem._id, '', elem?.shipping_method_name)
                                }
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </ReactDragListView>
                </tbody>
              </table>
            </div>
          ) : (
            <NoRecords />
          )}
          <h6 className='text-xl mb-2'>{t('lang_Recommended_Shipping_Methods')}</h6>
          <div className='w-full inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              className='order-table'
              columns={addshippingcolumns}
              data={sortedShippingInfo}
              selectableRowsHighlight
              highlightOnHover
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShipping;
