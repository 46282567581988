import React from 'react';
import EditProductBrandStyleContainer from './EditProductBrandStyleContainer';

const EditProductBrandStyleSection = ({
    productBrandDesign,
    setProductBrandDesign,
  handleUpdateCss,
  visualData
}) => {
  return (
    <>
      <EditProductBrandStyleContainer
        productBrandDesign={productBrandDesign}
        setProductBrandDesign={setProductBrandDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductBrandStyleSection;
