import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import API from '../../API';
import { Dialog } from '@mui/material';
import { Transition } from '../../controllers/Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import ReplyForEnquiry from './ReplyForEnquiry';
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';

function ShowEnquiry({ showEnquiry, showEnquiryData, setOpenReplyEnquiry, closeShowEnquiry }) {
  const params = useParams();
  const [nodata, setNodata] = useState(false);
  const [enquiry, setEnquiry] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const GetenquirysById = async () => {
    try {
      const ApiCall = await API.get(`/admin/contactus/GetenquirysById/${params?.enquiryid}`);
      const result = decryptData(ApiCall?.data)
      setEnquiry(result?.data);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  useEffect(() => {
    GetenquirysById();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Dialog
        open={showEnquiry}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeShowEnquiry(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='bg-white w-full lg:w-[600px] border border-gray-300  space-y-4 rounded-[6px] p-5'>
          <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
            <h6 className='font-medium text-xl'>{t('lang_Enquiry_Details')}</h6>
            <button
              className='close-btn'
              onClick={(e) => {
                e.preventDefault();
                closeShowEnquiry(false);
              }}
            >
              <X className='w-4 h-4' />
            </button>
          </div>
          <div className='space-y-3'>
            <p className='font-medium'>
              {t('lang_Customer_Name')} :
              <span className='ms-1 font-normal capitalize'>{showEnquiryData?.customer_name}</span>
            </p>
            <p className='font-medium'>
              {t('lang_email')} :
              <a
                //  href={`mailto:${showEnquiryData?.email}?subject=Re: ${showEnquiryData?.enquiry}`}
                href={
                  'mailto:recipient@example.com?cc=another@example.com&bcc=hidden@example.com&subject=Hello&body=This%20is%20a%20test%20email.'
                }
                className='ms-1 font-normal hover:text-black-600'
              >
                {showEnquiryData?.email}
              </a>
            </p>
            <p className='font-medium'>
              {t('lang_mobile_number')} :
              <span className='ms-1 font-normal'>{showEnquiryData?.mobile_number}</span>
            </p>
            <p className='font-medium'>
              {t('lang_enquiry')} :
              <span className='ms-1 font-normal'>{showEnquiryData?.enquiry}</span>
            </p>
          </div>
          {showEnquiryData !== undefined && !showEnquiryData?.reply && (
            <button
              type='button'
              className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              onClick={() => {
                setOpenReplyEnquiry(true);
                setSearchParams(showEnquiryData?._id);
                closeShowEnquiry(false);
              }}
            >
              {t('lang_Reply')}
            </button>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default ShowEnquiry;
