import { FormControlLabel } from '@mui/material';
import React from 'react';
import MuiSwitch from '../../../../controllers/MuiSwitch';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const VideoSettingSection = ({ section_id, videoValue, handleVideoChange,saveElementorData,
  btnLoading, }) => {
  const sourceValue = [
    { value: 'youtube', label: 'Youtube' },
    { value: 'vimeo', label: 'Vimeo' },
    // { value: 'dailymotion', label: 'Dailymotion' },
    // { value: 'videopress', label: 'VideoPress' },
    // { value: 'selfhosted', label: 'Self Hosted' },
  ];

  //   const suggestedValue = [
  //     { value: 'anyVideo', label: 'Any Video' },
  //     { value: 'currentChannel', label: 'Current Channel Video' },
  //   ];

  const handleVideoData = (e) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      handleVideoChange(section_id, e.target.checked, name);
    } else {
      handleVideoChange(section_id, value, name);
    }
  };
  return (
    <>
      <div className='secondary-bg-color w-full space-y-4'>
        <div className='w-full mb-4'>
          <label>Source</label>
          <div className='relative'>
            <select
              className='w-full mt-2 border border-gray-300 p-2 rounded-lg'
              onChange={handleVideoData}
              name='source'
              value={videoValue?.source}
            >
              {sourceValue.map((obj) => (
                <option key={obj.label} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </select>
            <div className='select-arrow'></div>
          </div>
        </div>

        <div className='w-full mb-4'>
          <label>Link</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='group title'
            name='link'
            placeholder='Enter Link here...'
            value={videoValue?.link}
            onChange={handleVideoData}
          />
        </div>

        <div className='w-full mb-4'>
          <div className='flex items-center'>
            <span className='w-full text-sm font-medium text-gray-900 dark:text-gray-300'>
              Start Time
            </span>
            <input
              type='number'
              name='startTime'
              className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
              value={videoValue.startTime}
              onChange={handleVideoData}
            />
          </div>
        </div>
        {videoValue?.source === 'youtube' && (
          <div className='w-full mb-4'>
            <div className='flex items-center'>
              <span className='w-full text-sm font-medium text-gray-900 dark:text-gray-300'>
                End Time
              </span>
              <input
                type='number'
                name='endTime'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={videoValue.endTime}
                onChange={handleVideoData}
              />
            </div>
          </div>
        )}

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>AutoPlay</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.autoPlay || false}
                  onChange={handleVideoData}
                  name='autoPlay'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>Mute</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.mute || false}
                  onChange={handleVideoData}
                  name='mute'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>Loop</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.loop || false}
                  onChange={handleVideoData}
                  name='loop'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        {videoValue?.source === 'youtube' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Player Control
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.playerControl || false}
                    onChange={handleVideoData}
                    name='playerControl'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        {videoValue?.source === 'youtube' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Modest Branding
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.modestBranding || false}
                    onChange={handleVideoData}
                    name='modestBranding'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
              Privacy Mode
            </span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.privacyMode || false}
                  onChange={handleVideoData}
                  name='privacyMode'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>Lazy Load</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.lazyLoad || false}
                  onChange={handleVideoData}
                  name='lazyLoad'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        {videoValue?.source === 'vimeo' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Intro Title
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.introTitle || false}
                    onChange={handleVideoData}
                    name='introTitle'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        {videoValue?.source === 'vimeo' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Intro Portrait
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.introPortrait || false}
                    onChange={handleVideoData}
                    name='introPortrait'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        {videoValue?.source === 'vimeo' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Intro Byine
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.introByLine || false}
                    onChange={handleVideoData}
                    name='introByLine'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}
        <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
      </div>
    </>
  );
};

export default VideoSettingSection;
