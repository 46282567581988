import React, { useEffect, useState } from 'react';
import API from '../../API';
import { Edit, Eye, EyeOff, PlusCircle } from 'feather-icons-react/build/IconComponents';
import Trash2 from 'feather-icons-react/build/IconComponents/Trash2';
import { Dialog, Skeleton } from '@mui/material';
import { Transition } from '../../controllers/Transition';
import AddHeaderGroup from '../Header/AddHeaderGroup';
import EditHeaderGroup from '../Header/EditHeaderGroup';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ReactDragListView from 'react-drag-listview';

const HeaderSection = ({ isChangeHeader }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [groupHeaderData, setGroupHeaderData] = useState();
  const [pending, setPending] = useState(false);
  const [isAddBlock, setIsAddBlock] = useState(false);
  const [isEditBlock, setIsEditBlock] = useState(false);
  const [editId, setEditId] = useState();
  const [editHeaderSectionId, setEditHeaderSectionId] = useState(null);
  const [hoverId, setHoverId] = useState(null);

  const getHeaderData = async () => {
    setPending(true);
    try {
      const data = await API.get(`/admin/header/getHeaderGroups`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setGroupHeaderData(result?.data);
        isChangeHeader(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    getHeaderData();
  }, []);
  const UpdateFooterStatus = async (row, value) => {
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/header/updateHeaderGroup/${row._id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getHeaderData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const closeAddBlock = (value) => {
    setIsAddBlock(value);
  };
  const closeEditBlock = (value) => {
    setIsEditBlock(value);
  };
  const handleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: [id],
      });
      const data = await API.post(`admin/header/deleteHeaderGroup`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed SuccesFully!');
        setSeverity('success');
        getHeaderData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleSort = (result) => {
    const items = Array.from(groupHeaderData);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setGroupHeaderData(items);
    isChangeHeader(items);
    sortHeaderData(result?.destination?.index, result?.draggableId);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...groupHeaderData];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setGroupHeaderData(newItems);
      isChangeHeader(newItems);
      sortHeaderData(toIndex, editHeaderSectionId);
      setEditHeaderSectionId(null);
      setHoverId(null);
    },
    nodeSelector: '.draggable-item',
    handleSelector: '.draggable-item',
  };

  const sortHeaderData = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/header/updateHeaderGroup/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  return (
    <>
      {pending && !groupHeaderData ? (
        <div className='space-y-1.5'>
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
        </div>
      ) : (
        <>
          <div className='space-y-0.5  mt-2'>
            <ReactDragListView {...dragProps}>
              {groupHeaderData?.map((elem, index) => {
                return (
                  <div
                    style={{
                      boxShadow: hoverId === index ? '6px 6px 35px 7px rgba(0,0,0,0.2)' : 'none',
                    }}
                    className={`draggable-item rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center justify-between ${
                      hoverId === index ? 'primary-bg-color' : 'bg-transparent'
                    }`}
                    key={index}
                    onDragStart={() => setEditHeaderSectionId(elem?._id)}
                    onDragOver={() => setHoverId(index)}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setHoverId(null);
                    }}
                  >
                    <div className='flex w-[75%] pe-2 items-center'>
                      <span className='text-[13px] table-text w-full block leading-4 capitalize font-normal'>
                        {elem?.header_section_title}
                      </span>
                    </div>
                    <div className='relative headerMenuBtn flex w-[25%] items-center justify-end cursor-pointer'>
                      {!elem?.cart && !elem?.notification && !elem?.wish_list ? (
                        <>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEditBlock(true);
                              setEditId(elem?._id);
                            }}
                          >
                            <Edit className='w-4 h-4 me-1 table-text hover:text-black transition-all duration-150' />
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(elem?._id);
                            }}
                          >
                            <Trash2 className='w-4 h-4 me-1 table-text hover:text-black transition-all duration-150' />
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                      <div
                        onClick={() => {
                          UpdateFooterStatus(elem, !elem?.status);
                        }}
                      >
                        {elem.status ? (
                          <Eye className='w-4 h-4 table-text hover:text-black transition-all duration-150' />
                        ) : (
                          <EyeOff className='w-4 h-4 table-text hover:text-black transition-all duration-150' />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </ReactDragListView>
          </div>

          <div className='text-center mt-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsAddBlock(true);
              }}
              className='inline-flex items-center mx-auto text-link hover:underline transition-all duration-150 text-sm'
            >
              <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Block')}
            </button>
          </div>
          <Dialog
            open={isAddBlock}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsAddBlock(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            {isAddBlock && (
              <AddHeaderGroup
                closeAddBlock={closeAddBlock}
                getHeaderData={getHeaderData}
                isChangeHeader={isChangeHeader}
              />
            )}
          </Dialog>
          <Dialog
            open={isEditBlock}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsEditBlock(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            {isEditBlock && (
              <EditHeaderGroup
                editBlockId={editId}
                closeEditBlock={closeEditBlock}
                getHeaderData={getHeaderData}
                isChangeHeader={isChangeHeader}
              />
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default HeaderSection;
