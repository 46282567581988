import React from 'react';
import EditProductStockStyleContainer from './EditProductStockStyleContainer';

const EditProductStockSection = ({
  productStockDesign,
  setProductStockDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductStockStyleContainer
        productStockDesign={productStockDesign}
        setProductStockDesign={setProductStockDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductStockSection;
