import { Checkbox, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const CategoryTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [Page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { planPermission, setPlanPermission } = useContext(PlanPermission);
  const [categoryWithParent, setAllCategoryWithParent] = useState();
  var startRecord = (categoryWithParent?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    categoryWithParent?.data?.currentPage * limit,
    categoryWithParent?.data?.totalRecords,
  );
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // fetch All Categories
  useEffect(() => {
    if (search?.length > 0 || search?.length > 2) {
      GetCategoriesWithAllParent();
      setPage(1);
    }
  }, [search]);

  const GetCategoriesWithAllParent = async () => {
    setDataLoading(true);
    try {
      const GetCategories = await API.get(
        `/admin/category/GetCategoriesWithAllParent?page=${Page}&limit=${limit}&searchkey=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (GetCategories.status === 200 || GetCategories.status === 304) {
        const result = decryptData(GetCategories?.data)
        setAllCategoryWithParent(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'category' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetCategoriesWithAllParent();
  }, [Page, limit, sortBy, orderBy]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);

  // Sorting Data
  const SortData = () => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
  };
  // Api call For delete  Product
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleMultipleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      let Delete = await API.post(`/admin/category/deleteCategory`, payload);
      if (Delete.status === 200) {
        GetCategoriesWithAllParent();
        setIsNotification(true);
        setNotificationMsg('Category Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = categoryWithParent?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = categoryWithParent?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = categoryWithParent?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => (!item.default ? item._id : ''))]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(
          categoryWithParent?.data?.records?.map((x) => (!x?.default ? x?._id : '')),
        );
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading={`${t('lang_delete_module', { module: 'category' })}`}
        subheading={t('lang_delete_confirmation', { module: 'category' })}
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleMultipleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-x-hidden overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div>
            <div className='flex items-center space-x-2'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/dashboard');
                }}
                className='back-arrow'
              >
                <ArrowLeft />
              </button>
              <div>
                <Breadcrumbs
                  crumbs={[
                    { name: t('lang_dashboard'), path: `/dashboard` },
                    { name: t('lang_categories'), path: '/categories' },
                  ]}
                />
                <h4 className='text-xl capitalize md:text-2xl font-semibold text-primary'>
                  {t('lang_categories')}
                </h4>
              </div>
            </div>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/categories/add-category')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_add_new', { module: 'Category' })}
            </button>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-2'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>
                        {t('lang_delete')} {t('lang_selection')}
                      </span>
                    </button>
                  </>
                )}
              </div>
                {categoryWithParent?.data?.totalRecords > 0 && <Tooltip title={t('lang_filter')} arrow>
                  <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                    <button>
                      <Filter className='h-5 w-5' />
                    </button>
                  </div>
                </Tooltip>}
            </div>
            <div
              className={'flex w-full h-full overflow-hidden relative'}
            >
              <div className='w-full'>
                {dataLoading && !categoryWithParent ? (
                  <TableSkeleton forTable='category' />
                ) : categoryWithParent?.data?.records?.length > 0 ? (
                  <div className='w-full transition-all duration-100 border category-table mb-1 main-border-color category-table inline-block overflow-x-auto overflow-y-hidden rounded-[6px]'>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={
                                selectedRowsId?.length === categoryWithParent?.data?.records?.length
                              }
                              indeterminate={
                                selectedRowsId?.length !==
                                categoryWithParent?.data?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='thead-image'>{t('lang_image')}</td>
                          <td className='min-w-[300px] max-w-[300px] px-3'>
                            <div className='flex items-center'>
                              <button
                                type='button'
                                className={`uppercase flex ${iconVisible && sortBy === 'lowercase_category'
                                  ? 'text-gray-700'
                                  : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                }}
                                onClick={() => {
                                  setSortBy('lowercase_category');
                                  SortData('lowercase_category');
                                }}
                              >
                                {t('lang_category_name')}
                              </button>
                              {iconVisible && sortBy === 'lowercase_category' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                          <td className='w-full px-3'>{t('lang_description')}</td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                            <span className='w-full text-right'>{t('lang_action')}</span>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryWithParent?.data?.records?.map((elem, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                                }`}
                              onClick={() =>
                                navigate(`/categories/${elem?.category_slug_name}`)
                              }
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  disabled={elem?.default}
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === elem?._id)
                                  }
                                  onChange={(e) => handleSelectRow(e, elem?._id)}
                                />
                              </td>
                              <td className='table-image min-w-[80px]'>
                                <img
                                  width={50}
                                  height={50}
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem.image || NOPHOTO_IMAGE}
                                  alt='category image'
                                />
                              </td>
                              <td className='min-w-[300px] max-w-[300px] px-3'>
                                <span className='line-clamp-2 capitalize'>
                                  {elem.parent_category || '-'}
                                </span>
                              </td>
                              <td className='w-full px-3'>
                                <p className='line-clamp-2 text-xs'>
                                  {elem?.description
                                    ? elem?.description?.replace(/<[^>]*>/g, ' ')
                                    : '-'}
                                </p>
                              </td>
                              <td className='px-3 min-w-[120px] max-w-[120px]' onClick={(e) => e.stopPropagation()}>
                                <div className='space-x-3 w-full flex justify-end items-center'>
                                  <Tooltip title='Edit' arrow>
                                    <button
                                      className='inline-block'
                                      onClick={() =>
                                        navigate(`/categories/${elem?.category_slug_name}`)
                                      }
                                    >
                                      <Edit className='w-[17px] h-[17px] text-black-500 hover:text-black-600' />
                                    </button>
                                  </Tooltip>
                                  {elem?.default !== true ? (
                                    <Tooltip title='Delete' arrow>
                                      <button
                                        className='inline-block'
                                        onClick={() => {
                                          setSelectedRowsId([elem?._id]);
                                          setConfirmationPopup(true);
                                        }}
                                      >
                                        <Trash className='w-[17px] h-[17px] text-red-500' />
                                      </button>
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {/* Paginator */}
                {categoryWithParent?.data ? (
                  <div className='bg-white border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${categoryWithParent?.data?.totalRecords}` + ' ' + `${t('lang_records')}`
                        : `${startRecord} - ${endRecord} of ${categoryWithParent?.data?.totalRecords
                        } ${t('lang_records')}`}
                    </span>
                    <div>
                      {categoryWithParent?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={categoryWithParent?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                  <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5 overflow-hidden'>
                    <div>
                      <span className='text-sm capitalize table-text font-medium mb-1 inline-block'>
                        {t('lang_search')}
                      </span>
                      <label className='relative block w-full'>
                        <input
                          className='w-full font-normal placeholder placeholder:text-slate-400 block bg-transparent border main-border-color rounded-[6px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                          placeholder={`${t('lang_search')} ${t('lang_categories')}`}
                          type='text'
                          name='search'
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(1);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none capitalize rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color px-3 py-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          {categoryWithParent?.data?.totalRecords > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {categoryWithParent?.data?.totalRecords > 20 ? (
                            <option value='20'>20</option>
                          ) : (
                            <></>
                          )}
                          {categoryWithParent?.data?.totalRecords > 50 ? (
                            <option value='50'>50</option>
                          ) : (
                            <></>
                          )}
                          <option value='all'>{t('lang_all')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryTable;
