import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
const CmsOffer = ({ colors, cmsOfferData, cmsOfferDesign }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    cmsOfferData?.status && (
      <div
        // style={{
        //   borderColor: colors?.border_color,
        //   width: '90%',
        //   display: 'block',
        //   margin: '0 auto',
        // }}
        style={{
          marginTop: `${cmsOfferDesign?.margin_top}px`,
          marginBottom: `${cmsOfferDesign?.margin_bottom}px`,
          marginLeft: `${cmsOfferDesign?.margin_left}px`,
          marginRight: `${cmsOfferDesign?.margin_right}px`,
          paddingTop: `${cmsOfferDesign?.padding_top}px`,
          paddingBottom: `${cmsOfferDesign?.padding_bottom}px`,
          paddingLeft: `${cmsOfferDesign?.padding_left}px`,
          paddingRight: `${cmsOfferDesign?.padding_right}px`,
          height: `${cmsOfferDesign?.height}${cmsOfferDesign?.height_unit}`,
          width: `${cmsOfferDesign?.width}${cmsOfferDesign?.width_unit}`,
          borderStyle: cmsOfferDesign?.border_style,
          borderWidth: `${cmsOfferDesign?.border_width}px`,
          borderColor: cmsOfferDesign?.border_color,
          float: cmsOfferDesign?.float,
          maxHeight: `${cmsOfferDesign?.max_height}${cmsOfferDesign?.max_height_unit}`,
          maxWidth: `${cmsOfferDesign?.max_width}${cmsOfferDesign?.max_width_unit}`,
          minHeight: `${cmsOfferDesign?.min_height}${cmsOfferDesign?.min_height_unit}`,
          minWidth: `${cmsOfferDesign?.min_width}${cmsOfferDesign?.min_width_unit}`,
        }}
        className={`mx-auto border-[20px] flex items-center ${
          cmsOfferData?.section?.cms_offer?.text_position === 'left'
            ? 'justify-start'
            : cmsOfferData?.section?.cms_offer?.text_position === 'right'
            ? 'justify-end'
            : 'justify-center'
        }`}
      >
        <div className='w-[100%] my-[50px]'>
          <div
            className={`w-full px-5 ${
              cmsOfferData?.section?.cms_offer?.text_position === 'left'
                ? 'text-left'
                : cmsOfferData?.section?.cms_offer?.text_position === 'right'
                ? 'text-right'
                : 'text-center'
            }`}
            dangerouslySetInnerHTML={{ __html: cmsOfferData?.section?.cms_offer?.cms_offer_text }}
          ></div>
          <div
            className={`flex mx-5 mt-5 items-center ${
              cmsOfferData?.section?.cms_offer?.text_position === 'left'
                ? 'justify-start'
                : cmsOfferData?.section?.cms_offer?.text_position === 'right'
                ? 'justify-end'
                : 'justify-center'
            }`}
          >
            {cmsOfferData?.section?.cms_offer?.cms_offer_button_text &&
            cmsOfferData?.section?.cms_offer?.is_button_visible ? (
              <Link
                aria-label='cmsoffer'
                style={{
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                  borderRadius: cmsOfferData?.section?.cms_offer?.cms_offer_button_redius,
                  backgroundColor: isHovered
                    ? cmsOfferData?.section?.cms_offer?.cms_offer_button_hover_color || ''
                    : cmsOfferData?.section?.cms_offer?.cms_offer_button_color,
                  color: isHovered
                    ? cmsOfferData?.section?.cms_offer?.cms_offer_button_hover_text_color || ''
                    : cmsOfferData?.section?.cms_offer?.cms_offer_button_text_color,
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`px-5 md:px-9 md:py-3 lg:text-sm`}
              >
                {cmsOfferData?.section?.cms_offer?.cms_offer_button_text}
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default CmsOffer;
