import { Skeleton } from '@mui/material';
import { Camera, Video, X } from 'feather-icons-react/build/IconComponents';
import { useEffect, useState } from 'react';
import API from '../API';
import { NOPHOTO_IMAGE } from '../Assets/StaticData/StaticImage';
import Notification from '../Component/Notification';
import { decryptData } from './encryptionUtils';
import GenerateUniqueFilename from './GenerateUniqueFilename';
import { CloudUpload } from 'react-bootstrap-icons';
import { t } from 'i18next';
const ImageUploader = ({ alreadyImage, handleSetImage, folder, format, multiple }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [image, setimage] = useState('');
  useEffect(() => {
    setimage(alreadyImage ? alreadyImage : '')
  }, [alreadyImage])
  const [loader, setLoader] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const HandleImage = async (file) => {
    if (file) {
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      let parts = file?.name?.split('.');
      let Ext = parts ? parts[parts?.length - 1] : 'jpg';
      if (
        Ext === 'jpg' ||
        Ext === 'png' ||
        Ext === 'jpeg' ||
        Ext === 'webp' ||
        Ext === 'svg'
      ) {
        try {
          setLoader(true);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('folder', folder);
          try {
            const res = await API.post('/admin/fileupload/upload', formData);
            const result = decryptData(res?.data)
            setimage(result?.data[0])
            handleSetImage(result?.data[0], multiple ? multiple : '');
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          setImgErr(false);
          setLoader(false);
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
          setImgErr(true);
          setLoader(false);
        }
      } else {
        setImgErr(true);
      }
      setLoader(false);
    }
  };
  const HandleVideo = async (file) => {
    if (file && file.size <= 100 * 1024 * 1024) {
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      let parts = file?.name?.split('.');
      let Ext = parts ? parts[parts?.length - 1] : 'jpg';
      if (
        Ext === 'mp4' ||
        Ext === 'avi' ||
        Ext === 'mov' ||
        Ext === 'mkv' ||
        Ext === 'webm' ||
        Ext === 'mpeg' ||
        Ext === 'flv' ||
        Ext === 'wmv'
      ) {
        try {
          setLoader(true)
          const formData = new FormData();
          formData.append('file', file);
          formData.append('folder', folder);
          try {
            const res = await API.post('/admin/fileupload/upload', formData);
            const result = decryptData(res?.data)
            setimage(result?.data[0])
            handleSetImage(result?.data[0], multiple ? multiple : '');
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          } finally {
            setImgErr(false);
            setLoader(false);
          }
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error.message || `An error occurred while compressing the ${format}`);
          setSeverity('error');
          setLoader(false);
        }
      }
      setLoader(false);
    } else {
      setLoader(false);
      if (file) {
        setIsNotification(true);
        setNotificationMsg('File size exceeds 100 MB');
        setSeverity('error');
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div
        htmlFor='dropzone-file'
        className={`${!image && !loader && 'file-tag-hover'} flex items-center h-[200px] min-w-full rounded px-4`}
      >
        {
          !image && !loader ?
            <label className='text-center flex-col gap-2 relative w-full h-full flex items-center justify-center cursor-pointer'>
              <input
                id='dropzone-file'
                type='file'
                name='image'
                accept={format === 'image' ? `image/jpeg, image/png, image/jpg, image/webp, image/svg` : 'video/*'}
                onChange={(e) => {
                  format === 'image' ? HandleImage(e.target.files[0]) : HandleVideo(e.target.files[0]);
                }}
                disabled={loader}
                className='hidden'
              />
              {format === "image" ? <CloudUpload className="w-9 h-9" /> : <Video className="w-8 h-8" />}

              <span className='text-sm block font-medium'>Click To Upload {format === "image" ? "Image" : "Video"}.</span>
              {
                format === "image" &&
                <div className='flex items-center flex-wrap gap-1'>
                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_JPEG')}</span>
                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_JPG')}</span>
                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_PNG')}</span>
                  <span className='tab focus-tab inline-block rounded-full'>{t('lang_AVIF')}</span>
                </div>
              }
            </label> :
            loader === true ?
              <Skeleton className='min-w-full min-h-[200px] max-w-full max-h-[200px]' variant='rounded-[6px]' /> :
              <div className='flex relative items-center h-[200px] w-[200px] justify-center border main-border-color rounded-[6px]'>
                {
                  format === 'image' ?
                    <img onError={(e) => { e.target.src = NOPHOTO_IMAGE }} src={image || NOPHOTO_IMAGE} className='max-w-[180px] max-h-[180px]' alt='image' /> :
                    <video width="400" src={image} type="video/mp4"></video>
                }
                <button
                  onClick={(e) => { e.preventDefault(); handleSetImage(''); setimage('') }}
                  className='absolute top-0 rounded-tr right-0 z-30 w-[25px] h-[25px] flex items-center justify-center text-red-600 primary-bg-color border-s border-b main-border-color text-xs'>
                  <X className="w-4 h-4" />
                </button>
              </div>
        }
      </div>
      {imgErr && (
        <span className='text-xs bg-yellow-100 mt-1 inline-block px-3 py-1 rounded-[6px]'>
          <span className='font-semibold'>NOTE :</span> File Should be in jpg, jpeg, png or webp format Max Size 10 MB
        </span>
      )}
    </>
  )
};
export default ImageUploader
