import React from 'react';
import EditManufactureStyleContainer from './EditManufactureStyleContainer';
import { t } from 'i18next';

const EditManufactureStyleSection = ({
  manufactureDesign,
  setManufactureDesign,
  manufactureImageSelect,
  setManufactureImageSelect,
  handleUpdateCss,
  visualData
}) => {
  return (
    <>
      <EditManufactureStyleContainer
        manufactureDesign={manufactureDesign}
        setManufactureDesign={setManufactureDesign}
        handleUpdateCss={handleUpdateCss}
        setManufactureImageSelect={setManufactureImageSelect}
        manufactureImageSelect={manufactureImageSelect}
        visualData={visualData}
      />
    </>
  );
};

export default EditManufactureStyleSection;
