import React from 'react';
import EditTestiMonialStyleContainer from './EditTestiMonialStyleContainer';

const EditTestiMonialStyleSection = ({
  previewImage,
  testiMonialDesign,
  setTestiMonialDesign,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditTestiMonialStyleContainer
        testiMonialDesign={testiMonialDesign}
        setTestiMonialDesign={setTestiMonialDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditTestiMonialStyleSection;
