import React from 'react';
import EditCartPageOtherDetailsSection from './EditCartPageOtherDetailsSection';

const EditCartPageOtherDetailsContainer = ({
  cartOtherDetailsDesign,
  setCartOtherDetailsDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditCartPageOtherDetailsSection
        cartOtherDetailsDesign={cartOtherDetailsDesign}
        setCartOtherDetailsDesign={setCartOtherDetailsDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditCartPageOtherDetailsContainer;
