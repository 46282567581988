import React from 'react';
import EditProductPageStyleContainer from './EditProductPageStyleContainer';

const EditProductPageStyleSection = ({
  productSectionDesign,
  setProductSectionDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditProductPageStyleContainer
        productSectionDesign={productSectionDesign}
        setProductSectionDesign={setProductSectionDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditProductPageStyleSection;
