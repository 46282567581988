import { Dialog, Pagination } from '@mui/material';
import { ArrowLeft, ChevronDown, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import { GetClient, clientData } from '../../controllers/GetClient';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const AbandoneCart = () => {
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const { setPlanPermission } = useContext(PlanPermission);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [cartsInfo, setCartInfo] = useState();
  const [page, setPage] = useState(getInitialPage());
  const [sortTime, setSortTime] = useState("All");
  const [recovery, setRecovery] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  const handleCloseNotification = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const [recoveryData, setRecoveryData] = useState({
    cart_reminder_24: false,
    cart_reminder_48: false
  })
  useEffect(() => {
    replacePageInUrl(page)
  }, [page])
  var startRecord = (cartsInfo?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    cartsInfo?.currentPage * limit,
    cartsInfo?.totalRecords,
  );
  const getAbandoneCarts = async () => {
    setDataLoading(true)
    try {
      let ApiCall = await API.get(`/admin/product/getAbandonedCart?page=${page}&limit=10&sort=${sortTime}`);
      const result = decryptData(ApiCall?.data)
      setCartInfo(result?.data);
      if (Number(startRecord) > Number(result?.data?.totalRecords)) {
        setPage(page - 1)
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'orders' })
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    setDataLoading(false);
  };
  const rowData = cartsInfo?.records;
  const [showProduct, setShowProduct] = useState(false);
  const [selectedId, setselectedId] = useState();

  const handleClose = () => {
    setShowProduct(false)
  }
  const clientInfo = clientData;
  const columns = [
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex`}>
            {t("lang_customer")}
          </button>
        </div>
      ),
      cell: (row) => <span className='capitalize'>{row.user?.name || row?.guest_user?.name || '-'}</span>,
    },
    {
      name: t('lang_price'),
      right: true,
      selector: (row) => row?.grand_total,
      sortable: true,
      width: '150px',
      cell: (row) => (
        <span className='w-full text-right block'>
          {FormatAmount(row.grand_total, clientInfo?.currency_format)}
        </span>
      ),
    },
    {
      name: <div className='w-full text-right block pe-2'>{t("lang_items")}</div>,
      cell: (row, index) => (
        <div
          className='flex items-center justify-end w-full cursor-pointer select-none'
        >
          <button aria-describedby={row?._id} variant="contained" className='flex items-center' onClick={() => { setShowProduct(!showProduct); setselectedId(row._id) }}>
            {`${row?.products?.length} ${t("lang_items")}`} <ChevronDown className='w-3 h-3 ms-1 mt-1' />
          </button>
          {
            row?._id === selectedId ?
              <Dialog
                open={showProduct}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowProduct(false)}
                aria-describedby="alert-dialog-slide-description"
              >
                <div className='px-5 pb-5 secondary-bg-color pt-2.5 w-[350px] sm:w-[500px]'>
                  <div className='flex font-medium  text-primary items-center justify-between'>
                    <h6 className='capitalize'>{`${row.user?.name || row?.guest_user?.name || ''} - `}{`${t("lang_cart")} ${t("lang_items")}`}</h6>
                    <button className='close-btn' onClick={() => setShowProduct(false)} ><X className="w-4 h-4" /></button>
                  </div>
                  <hr className='my-3 main-border-color' />
                  <div className='divide-y space-y-2'>
                    {row?.products?.map((elem) => {
                      return (
                        <div className='flex w-full pt-2 max-h-20'>
                          <div className='min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] overflow-hidden flex items-center justify-center border main-border-color'>
                            <img
                              onError={(e) => { e.target.src = NOPHOTO_IMAGE }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              className='max-w-full mx-auto block max-h-full object-contain'
                              alt={elem?.name}
                            />
                          </div>
                          <div className='flex items-start ps-2 w-full justify-between'>
                            <div className=''>
                              <p className='text-md line-clamp-1 table-text'>{elem?.name}</p>
                              <p className='text-xs mt-0.5 table-text'>{`Total: ${FormatAmount(elem?.product_total, clientInfo?.currency_format)}`}</p>
                            </div>
                            <p className='flex px-2 table-text items-center justify-end text-xs min-w-[55px]'>
                              {FormatAmount(elem?.selling_price, clientInfo?.currency_format)}
                              <X className='w-3 h-3 mx-0.5' />
                              {elem?.quantity}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Dialog> : <></>
          }
        </div>
      ),
    },
    {
      width: '200px',
      name: `${t("lang_cart")} ${t("lang_total")}`,
      cell: (row) => <span className='capitalize w-full text-right'>{FormatAmount(row?.grand_total, clientInfo?.currency_format)}</span>,
      right: true,
      selector: (row) => row?.grand_total,
      sortable: true,
    },
    {
      width: '200px',
      name: (
        <div className='flex items-center w-full justify-end'>
          <button className='uppercase'>
            {t("lang_date")}
          </button>
        </div>
      ),
      cell: (row) => <span className='capitalize w-full text-right'>{formatDate(row?.updatedAt)}</span>,
    },
  ];
  useEffect(() => {
    if (clientInfo) {
      setRecoveryData({
        cart_reminder_24: clientInfo?.cart_reminder_24,
        cart_reminder_48: clientInfo?.cart_reminder_48
      })
    }
  }, [clientInfo])
  useEffect(() => {
    getAbandoneCarts();
  }, [page, sortTime]);
  const navigate = useNavigate();
  const updateClient = async () => {
    try {
      const payload = encryptData({
        clientInfo: recoveryData
      })
      const ApiCall = await API.put(`/admin/client/updateClient`, payload);
      GetClient();
      setRecovery(false)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleCloseNotification} severity={severity} />
      )}
      <div className='w-full h-auto pb-[64px] overflow-y-auto'>
        <div className='flex items-center space-x-2'>
          <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t('lang_dashboard'), path: `/dashboard` },
              { name: `${t("lang_abandone")} ${t("lang_cart")}`, path: '/abandone-cart' },
            ]} />
            <h4 className='text-xl md:text-2xl font-semibold text-primary capitalize'>
              {`${t("abandone")} ${t("lang_cart")}`}
            </h4>
          </div>
        </div>
        <div className='overflow-x-hidden'>
          <div className='flex justify-between space-x-3 w-full relative'>
            <div className='w-full relative rounded-[6px]'>
              <div className='flex items-end justify-end my-3'>
                <div className='relative me-2'>
                  <select value={sortTime} onChange={(e) => setSortTime(e.target.value)} className='w-[150px]'>
                    <option value="All" className='capitalize'>{t("lang_all")}</option>
                    <option value="Today" className='capitalize'>{t("lang_today")}</option>
                    <option value="Yesterday" className='capitalize'>{t("lang_yesterday")}</option>
                    <option value="This Week" className='capitalize'>{`${t("lang_this")} ${t("lang_week")}`}</option>
                    <option value="Last Week" className='capitalize'>{`${t("lang_last")} ${t("lang_week")}`}</option>
                    <option value="This Month" className='capitalize'>{`${t("lang_this")} ${t("lang_month")}`}</option>
                    <option value="Last Month" className='capitalize'>{`${t("lang_last")} ${t("lang_month")}`}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
                <button onClick={(e) => { e.preventDefault(); setRecovery(true) }} className='btn text-sm transition-all duration-150 text-white'>{`${t("lang_send")} ${t("lang_recovery")} ${t("lang_mail")}`}</button>
              </div>
              <div className='w-full space-y-1.5 '>
                {
                  dataLoading && !cartsInfo ?
                    <TableSkeleton forTable="order" /> :
                    <div className='w-full inline-block border main-border-color rounded-[6px] overflow-y-hidden '>
                      <DataTable
                        className='order-table'
                        columns={columns}
                        data={rowData}
                        selectableRowsHighlight
                        highlightOnHover
                      />
                    </div>
                }
                {/* Paginator */}
                {cartsInfo ? (
                  <div className='bg-white border main-border-color flex p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {
                        limit === "all" ? `${cartsInfo?.totalRecords}` + " " + t("lang_records") :
                          `${startRecord} - ${endRecord} of ${cartsInfo?.totalRecords} ${t("lang_records")}`
                      }
                    </span>
                    <div>
                      {cartsInfo?.totalPages !== 1 && (
                        <Pagination
                          count={cartsInfo?.totalPages}
                          page={page}
                          onChange={(e, v) => setPage(v)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={recovery}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setRecovery(false);
          // setRecoveryData({
          //   cart_reminder_24: false,
          //   cart_reminder_48: false
          // })
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='p-5 secondary-bg-color w-full sm:w-[600px]'>
          <div className='flex items-center justify-between'>
            <h6 className='font-medium text-primary text-xl'>{`${t("lang_send")} ${t("lang_recovery")} ${t("lang_mail")}`}</h6>
            <button onClick={(e) => {
              e.preventDefault();
              setRecovery(false);
              // setRecoveryData({
              //   cart_reminder_24: false,
              //   cart_reminder_48: false
              // })
            }} className="close-btn">
              <X className="w-4 h-4" />
            </button>
          </div>
          <hr className='my-4 main-border-color'></hr>
          <p className='table-text'>{t("lang_cart_recovery_sentence")}</p>
          <div className='flex space-x-4 my-4'>
            <label className='flex cursor-pointer w-full border main-border-color p-3 rounded-[6px] items-center'>
              <input type='checkbox' value={recoveryData?.cart_reminder_24} onChange={(e) => { setRecoveryData({ ...recoveryData, cart_reminder_24: !recoveryData?.cart_reminder_24 }) }} checked={recoveryData?.cart_reminder_24 === true} className='me-2 w-4 h-4' label="cart_reminder_24" />
              <span className='text-sm table-text'>{t("lang_cart_reminder", { hours: 24 })}</span>
            </label>
            <label className='flex cursor-pointer w-full border main-border-color p-3 rounded-[6px] items-center'>
              <input type='checkbox' value={recoveryData?.cart_reminder_48} onChange={(e) => { setRecoveryData({ ...recoveryData, cart_reminder_48: !recoveryData?.cart_reminder_48 }) }} checked={recoveryData?.cart_reminder_48 === true} className='me-2 w-4 h-4' label="cart_reminder_48" />
              <span className='text-sm table-text'>{t("lang_cart_reminder", { hours: 48 })}</span>
            </label>
          </div>
          <button onClick={(e) => { e.preventDefault(); updateClient() }} className='btn text-sm text-white transition-all duration-150'>{`${t("lang_send")} ${t("lang_recovery")} ${t("lang_mail")}`}</button>
        </div>
      </Dialog>
    </>
  );
};

export default AbandoneCart;
