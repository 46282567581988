import React from 'react';
import EditIconBoxStyleContainer from './EditIconBoxStyleContainer';

const EditIconBoxStyleSection = ({
  previewImage,
  iconBoxDesign,
  setIconBoxDesign,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditIconBoxStyleContainer
        iconBoxDesign={iconBoxDesign}
        setIconBoxDesign={setIconBoxDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditIconBoxStyleSection;
