import React from 'react';
import EditBannerStyleContainer from './EditBannerStyleContainer';

const EditBannerStyleSection = ({ bannerDesign, setBannerDesign, handleUpdateCss }) => {
  return (
    <>
      <EditBannerStyleContainer
        bannerDesign={bannerDesign}
        setBannerDesign={setBannerDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditBannerStyleSection;
