import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { FormControlLabel } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Notification from '../Notification';
function ViewGdpr() {
  const [allGdpr, setAllGdpr] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const [message, setmessage] = useState({
    newslatter: '',
    contactus: '',
    register_login: '',
    checkout: '',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const GetGdpr = async () => {
    try {
      let ApiCall = await API.get(`/admin/gdpr/getGdpr`);
      const result = decryptData(ApiCall?.data)
      setAllGdpr(result);
      setmessage({
        newslatter: result?.data?.newsletter?.message,
        contactus: result?.data?.contactus?.message,
        checkout: result?.data?.checkout?.message,
        register_login: result?.data?.register_login?.message,
      });
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'gdpr' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetGdpr();
  }, []);

  //Status Update
  const HandleSubmit = async (value, check) => {
    let body;
    if (check === 'newsletter') {
      body = {
        newsletter: {
          message: message.newslatter || '',
          status: value,
        },
        contactus: {
          message: message?.contactus,
          status: allGdpr?.data?.contactus?.status,
        },
        register_login: {
          message: message?.register_login,
          status: allGdpr?.data?.register_login?.status,
        },
        checkout: {
          message: message?.checkout,
          status: allGdpr?.data?.checkout?.status,
        },
      };
    } else if (check === 'register_login') {
      body = {
        newsletter: {
          message: message?.newslatter,
          status: allGdpr?.data?.newsletter?.status,
        },
        contactus: {
          message: message?.contactus,
          status: allGdpr?.data?.contactus?.status,
        },
        register_login: {
          message: message?.register_login || '',
          status: value,
        },
        checkout: {
          message: message?.checkout,
          status: allGdpr?.data?.checkout?.status,
        },
      };
    } else if (check === 'checkout') {
      body = {
        newsletter: {
          message: message?.newslatter,
          status: allGdpr?.data?.newsletter?.status,
        },
        contactus: {
          message: message?.contactus,
          status: allGdpr?.data?.contactus?.status,
        },
        checkout: {
          message: message?.checkout || '',
          status: value,
        },
        register_login: {
          message: message?.register_login,
          status: allGdpr?.data?.register_login?.status,
        },
      };
    } else {
      body = {
        newsletter: {
          message: message?.newslatter,
          status: allGdpr?.data?.newsletter?.status,
        },
        contactus: {
          message: message?.contactus || '',
          status: value,
        },
        register_login: {
          message: message?.register_login,
          status: allGdpr?.data?.register_login?.status,
        },
        checkout: {
          message: message?.checkout,
          status: allGdpr?.data?.checkout?.status,
        },
      };
    }
    try {
      await API.put(`/admin/gdpr/updateGdpr/${allGdpr?.data?._id}`, body);
      GetGdpr();
      setIsNotification(true);
      setNotificationMsg(
        value
          ? `${check?.replace('_', ' ')} is set to active`
          : `${check?.replace('_', ' ')} is set to hidden`,
      );
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const HandleChange = (e) => {
    e.preventDefault();
    setmessage({ ...message, [e.target.name]: e.target.value });
  };
  const HandleUpdate = async (e) => {
    e.preventDefault();
    try {
      await API.put(`/admin/gdpr/updateGdpr/${allGdpr.data._id}`, {
        newsletter: {
          message: message.newslatter ? message.newslatter : '',
          status: allGdpr?.data?.newsletter?.status,
        },
        contactus: {
          message: message.contactus ? message.contactus : '',
          status: allGdpr?.data?.contactus?.status,
        },
        register_login: {
          message: message.register_login ? message.register_login : '',
          status: allGdpr?.data?.register_login?.status,
        },
        checkout: {
          message: message.checkout ? message.checkout : '',
          status: allGdpr?.data?.checkout?.status,
        },
      });
      setIsNotification(true);
      setNotificationMsg('GDPR updated successfully!');
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  var statusString = allGdpr && allGdpr.data;
  const navigate = useNavigate();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_gdpr'), path: '/gdpr' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_GDPR_Compliance')}{' '}
                </h4>
                <a target='blank' href='https://docs.shopeasy.ai/gdpr' className='how-it-works'>
                  <QuestionCircleFill className='me-2' />
                  {t('lang_how_to_use')}
                </a>
              </div>
            </div>
          </div>
          <div className='flex items-center'>
            {!statusString ? (
              <button
                className='inline-block btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
                onClick={() => navigate('/add-gdpr')}
              >
                {t('lang_Add_GDPR')}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <form className='data-form w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'>
          {allGdpr && allGdpr.data ? (
            <>
              <div className='secondary-bg-color mb-6'>
                <div className='flex mb-1 items-center justify-between'>
                  <p className='font-medium table-text'>{t('lang_newsletter')}</p>
                  <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                    <FormControlLabel
                      checked={allGdpr?.data?.newsletter?.status === true}
                      control={<MuiSwitch />}
                      onChange={() => {
                        HandleSubmit(!allGdpr?.data?.newsletter?.status, 'newsletter');
                      }}
                    />
                  </label>
                </div>
                <textarea
                  className='w-full h-20 block px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='newslatter'
                  name='newslatter'
                  value={message.newslatter}
                  onChange={(e) => HandleChange(e)}
                  placeholder='Type your message'
                  rows='4'
                  cols='50'
                />
              </div>
              <div className='secondary-bg-color mb-6'>
                <div className='flex mb-1 items-center justify-between'>
                  <p className='font-medium table-text'>{t('lang_Contact_Us')}</p>
                  <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                    <FormControlLabel
                      checked={allGdpr?.data?.contactus?.status === true}
                      control={<MuiSwitch />}
                      onChange={() => {
                        HandleSubmit(!allGdpr?.data?.contactus?.status, 'contactus');
                      }}
                    />
                  </label>
                </div>
                <textarea
                  className='w-full h-20 px-3 block py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='contactus'
                  name='contactus'
                  value={message.contactus}
                  onChange={(e) => HandleChange(e)}
                  placeholder='Type your message'
                  rows='4'
                  cols='50'
                />
              </div>
              <div className='secondary-bg-color mb-6'>
                <div className='flex mb-1 items-center justify-between'>
                  <p className='font-medium table-text'>{t('lang_Register')}</p>
                  <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                    <FormControlLabel
                      checked={allGdpr?.data?.register_login?.status === true}
                      control={<MuiSwitch />}
                      onChange={() => {
                        HandleSubmit(!allGdpr?.data?.register_login?.status, 'register_login');
                      }}
                    />
                  </label>
                </div>
                <textarea
                  className='w-full h-20 px-3 py-3 block border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='auth'
                  name='register_login'
                  value={message.register_login}
                  onChange={(e) => HandleChange(e)}
                  placeholder='Type your message'
                  rows='4'
                  cols='50'
                />
              </div>
              <div className='secondary-bg-color mb-6'>
                <div className='flex mb-1 items-center justify-between'>
                  <p className='font-medium table-text'>{t('lang_Checkout')}</p>
                  <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                    <FormControlLabel
                      checked={allGdpr?.data?.checkout?.status === true}
                      control={<MuiSwitch />}
                      onChange={() => {
                        HandleSubmit(!allGdpr?.data?.checkout?.status, 'checkout');
                      }}
                    />
                  </label>
                </div>
                <textarea
                  className='w-full h-20 px-3 py-2 block border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='checkout'
                  name='checkout'
                  value={message.checkout}
                  onChange={(e) => HandleChange(e)}
                  placeholder='Type your message'
                  rows='4'
                  cols='50'
                />
              </div>
            </>
          ) : (
            <span>{t('lang_No_Data_Found')}!</span>
          )}
        </form>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-end lg:w-[70%] xl:w-[60%] mx-auto'>
            {allGdpr?.data &&
              (message?.checkout?.length > 0 ||
                message?.contactus?.length > 0 ||
                message?.register_login?.length > 0 ||
                message?.newslatter?.length > 0) ? (
              <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
                type='submit'
                onClick={HandleUpdate}
              >
                {t('lang_Update_GDPR')}
              </button>
            ) : (
              allGdpr?.data && (
                <button
                  className='inline-block bg-btn text-white text-[13px] opacity-50 cursor-not-allowed font-medium py-2 px-5 rounded-[6px] transition duration-300'
                  disabled
                  type='button'
                >
                  {t('lang_Update_GDPR')}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewGdpr;
