import { Autocomplete, Box, Pagination, Popover, TextField, Tooltip } from '@mui/material';
import { ArrowLeft, Eye, Filter } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import {
  CaretDownFill,
  CaretUpFill,
  PlusCircle,
  QuestionCircleFill,
  StarFill,
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
function AllReviews({ permissionCheck }) {
  const navigate = useNavigate();
  const [allReviews, setReviews] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [id, setId] = useState('');
  const { setPlanPermission } = useContext(PlanPermission);
  const [Page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [sortBy, setSortBy] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  var startRecord = (allReviews?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(allReviews?.data?.currentPage * limit, allReviews?.data?.totalRecords);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    getAllReviews();
  };

  // All reviews
  const getAllReviews = async () => {
    setDataLoading(true);
    try {
      let Apicall = await API.get(
        `/admin/review/getAllReviews?page=${Page}&limit=${limit}&product=${id}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (Apicall.status === 200 || Apicall.status === 304) {
        const result = decryptData(Apicall?.data)
        setReviews(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(Page - 1);
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'reviews' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const [allProducts, setAllProducts] = useState([]);
  // product
  const getProducts = async () => {
    try {
      const Apicall = await API.get(`/admin/product/GetProductsName`);
      if (Apicall.status === 200 || Apicall.status === 304) {
        const result = decryptData(Apicall?.data)
        setAllProducts(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getProducts();
  }, []);
  useEffect(() => {
    getProducts();
    getAllReviews();
  }, [Page, limit, id]);

  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // MUI Autocomplete Filter options
  const filterOptionsForProduct = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const [selectOption, setSelectOption] = useState();

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Reviews'), path: '/reviews' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_Reviews')}{' '}
                </h4>
                <a
                  target='blank'
                  href='https://docs.shopeasy.ai/review-management'
                  className='how-it-works'
                >
                  <QuestionCircleFill className='me-2' />
                  {t('lang_how_to_use')}
                </a>
              </div>
            </div>
          </div>
          <div className='flex space-x-3 items-center'>
            {permissionCheck && (
              <button
                className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
                onClick={() => navigate('/reviews/add-review')}
              >
                <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
                {t('lang_Add_Review')}
              </button>
            )}
          </div>
        </div>
        <div className='flex items-center mb-2 justify-end'>
          <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
            <Tooltip title='Filter' arrow>
              <button className=''>
                <Filter className='h-5 w-5' />
              </button>
            </Tooltip>
          </div>
        </div>
        <div
          className={'flex w-full h-full overflow-hidden relative'}
        >
          <div className='w-full space-y-4'>
            {dataLoading && !allReviews ? (
              <TableSkeleton forTable='review' />
            ) : allReviews?.data?.records?.length > 0 ? (
              <div className='w-full transition-all duration-100 border main-border-color inline-block overflow-x-auto overflow-y-hidden rounded-[6px]'>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <td className='thead-image px-3'>{t('lang_image')}</td>
                      <td className='px-3 w-full'>
                        <div className='flex items-center'>
                          <button
                            className={`uppercase flex ${iconVisible && sortBy === 'product_name' ? 'text-gray-700' : ''
                              }`}
                            onMouseEnter={() => {
                              setIconVisible(true);
                              setSortBy('product_name');
                            }}
                            onMouseLeave={() => {
                              setIconVisible(false);
                              setSortBy('');
                            }}
                            onClick={() => SortData('product_name')}
                          >
                            {t('lang_Product_Name')}
                            {iconVisible && sortBy === 'product_name' ? (
                              !orderBy.isDecending ? (
                                <CaretUpFill className='ms-2' />
                              ) : (
                                <CaretDownFill className='ms-2' />
                              )
                            ) : (
                              <></>
                            )}
                          </button>
                        </div>
                      </td>
                      <td className='px-3 min-w-[150px] max-w-[150px]'>
                        <div className='flex items-center w-full'>
                          <button
                            className={`uppercase flex ${iconVisible && sortBy === 'average_review' ? 'text-gray-700' : ''
                              }`}
                            onMouseEnter={() => {
                              setIconVisible(true);
                              setSortBy('average_review');
                            }}
                            onMouseLeave={() => {
                              setIconVisible(false);
                              setSortBy('');
                            }}
                            onClick={() => SortData('average_review')}
                          >
                            {t('lang_Rating')}
                            {iconVisible && sortBy === 'average_review' ? (
                              !orderBy.isDecending ? (
                                <CaretUpFill className='ms-2' />
                              ) : (
                                <CaretDownFill className='ms-2' />
                              )
                            ) : (
                              <></>
                            )}
                          </button>
                        </div>
                      </td>
                      <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                        <div className='flex items-center w-full justify-end'>
                          <button
                            className={`uppercase flex ${iconVisible && sortBy === 'review_count' ? 'text-gray-700' : ''
                              }`}
                            onMouseEnter={() => {
                              setIconVisible(true);
                              setSortBy('review_count');
                            }}
                            onMouseLeave={() => {
                              setIconVisible(false);
                              setSortBy('');
                            }}
                            onClick={() => SortData('review_count')}
                          >
                            {iconVisible && sortBy === 'review_count' ? (
                              !orderBy.isDecending ? (
                                <CaretUpFill className='me-2' />
                              ) : (
                                <CaretDownFill className='me-2' />
                              )
                            ) : (
                              <></>
                            )}
                            {t('lang_Review_Count')}
                          </button>
                        </div>
                      </td>
                      <td className='px-3 min-w-[120px] text-right'>
                        <span className='text-right w-full capitalize'>{t('lang_action')}</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {allReviews?.data?.records?.map((elem, index) => {
                      return (
                        <tr key={index}>
                          <td className='table-image px-3 min-w-[80px]'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              width={40}
                              height={50}
                              className='p-1 max-h-full'
                              alt={elem?.product_name}
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span className='line-clamp-2'>{elem?.product_name || '-'}</span>
                          </td>
                          <td className='px-3 min-w-[150px] max-w-[150px]'>
                            {elem?.average_review >= 5 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#00b67a] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.average_review}</span>
                              </div>
                            ) : elem?.average_review >= 4 && elem?.average_review < 5 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#73cf11] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.average_review}</span>
                              </div>
                            ) : elem?.average_review >= 3 && elem?.average_review < 4 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#ffce00] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.average_review}</span>
                              </div>
                            ) : elem?.average_review >= 2 && elem?.average_review < 3 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#ff8622] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.average_review}</span>
                              </div>
                            ) : elem?.average_review < 2 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#ff3722] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.average_review}</span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                            <span className='line-clamp-2'>{elem?.review_count || '-'}</span>
                          </td>
                          <td className='px-3 min-w-[120px] text-right'>
                            <div className='w-full text-right'>
                              <Tooltip title='View' arrow>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/reviews/${elem?.product_id}`);
                                  }}
                                >
                                  <Eye className='w-[17px] h-[17px]' />
                                </button>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
            {/*================paginator================ */}
            {allReviews?.data ? (
              <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                <span className='text-sm font-medium'>
                  {limit === 'all'
                    ? `${allReviews?.data?.totalRecords}` + ' ' + 'Records'
                    : `${startRecord} - ${endRecord} of ${allReviews?.data?.totalRecords} Records`}
                </span>
                <div>
                  {allReviews?.data?.totalPages !== 1 ? (
                    <Pagination
                      count={allReviews?.data?.totalPages}
                      page={Page}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Popover
            open={filter}
            anchorEl={anchorEl}
            onClose={() => { setFilter(false); setAnchorEl(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
            }}
            className='rounded-[6px]'
          >
            <div className='p-[20px] filter'>
              <div>
                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t('lang_filter')}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_Sort_By_Product')}
                    </span>
                    {
                      <Autocomplete
                        className='autocomplete'
                        filterOptions={filterOptionsForProduct}
                        options={allProducts?.records ? allProducts.records : []}
                        onHighlightChange={(event, option) =>
                          option ? setSelectOption(option?._id) : setSelectOption()
                        }
                        renderOption={(props, option) => (
                          <Box
                            component='li'
                            {...props}
                            className={`flex w-full space-x-2 border-b p-2 items-center cursor-pointer ${option?._id === selectOption
                              ? 'primary-bg-color'
                              : 'hover:bg-[#F0F0F0]'
                              }`}
                          >
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={option?.image || NOPHOTO_IMAGE}
                              className='object-contain border p-0.5'
                              style={{ width: '50px', height: '50px' }}
                              alt='product image'
                            />
                            <div>
                              <p>{option?.product_name}</p>
                              <p className='text-xs'>({option?.product_slug_name})</p>
                            </div>
                          </Box>
                        )}
                        getOptionLabel={(products) => products.product_name}
                        noOptionsText={
                          allProducts?.records?.length > 0 && !autocompleteSearch
                            ? 'Please enter at least 3 characters'
                            : 'No option found'
                        }
                        onChange={(e, v) => {
                          setPage(1);
                          v ? setId(v?._id) : setId('');
                        }}
                        renderInput={(products) => (
                          <TextField
                            value={products?.inputValue}
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            onChange={(e) => {
                              const newInputValue = e.target.value;
                              if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                                setAutocompleteSearch(newInputValue);
                              } else {
                                setAutocompleteSearch();
                              }
                            }}
                            placeholder='Select product'
                            {...products}
                          />
                        )}
                      />
                    }
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_set_items_limit')}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          setPage(1);
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                        }}
                      >
                        {allReviews?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {allReviews?.data?.totalRecords > 20 ? (
                          <option value='20'>20</option>
                        ) : (
                          <></>
                        )}
                        {allReviews?.data?.totalRecords > 50 ? (
                          <option value='50'>50</option>
                        ) : (
                          <></>
                        )}
                        <option value='all' className='capitalize'>
                          {t('lang_all')}
                        </option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </>
  );
}

export default AllReviews;
