import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { FormatAmount } from '../../../controllers/FormatAmount';
import { t } from 'i18next';

const CrossSellProducts = ({
  product,
  colors,
  clientInfo,
  productPageData,
  selectProductSection,
  productSectionOrderObject,
  crossProductDesign,
  handleRatingClick
}) => {
  const [crossSell, setCrossSell] = useState();
  const [isHovered, setIsHovered] = useState();
  let total = product?.selling_price;
  const cProduct = crossSell?.length > 0 && crossSell?.filter((x) => x.isSelected === true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mrptotalPrice, setMrpTotalPrice] = useState(0);
  useEffect(() => {
    setPrice();
  }, [product, cProduct]);

  const setPrice = async () => {
    const sum =
      (await cProduct?.length) > 0 &&
      cProduct?.reduce((acc, obj) => acc + obj.selling_price * obj.defaultCount, 0);
    const mrpSum =
      (await cProduct?.length) > 0 &&
      cProduct?.reduce((acc, obj) => acc + obj.mrp * obj.defaultCount, 0);
    const cartTotal = sum + product?.selling_price;
    const mrpTotal = mrpSum + product?.mrp;
    setTotalPrice(cartTotal);
    setMrpTotalPrice(mrpTotal);
  };

  useEffect(() => {
    setCrossSell(
      product?.cross_sell.map((el) => {
        return {
          ...el,
          isSelected: true,
          defaultCount: el?.min_order_quantity || 1,
        };
      }),
    );
  }, [product]);

  return (
    <div
      className='bg-transparent cursor-pointer w-full'
      style={{
        display: productPageData?.showCrossSellProducts ? 'block' : 'none',
        borderColor: colors?.border_color,
        order: productSectionOrderObject?.rating,
        overflow: crossProductDesign?.overflow,
      }}
      // onClick={() => selectProductSection('Cross Sell Products')}
      onClick={() => {
        handleRatingClick('Cross Sell Products');
      }}
    >
      <div
        className='mx-auto'
        style={{
          display: crossProductDesign?.display,
          flexDirection: crossProductDesign?.direction,
          alignItems: crossProductDesign?.align_item,
          gap: crossProductDesign?.gap,
          marginTop: `${crossProductDesign?.margin_top}px`,
          marginBottom: `${crossProductDesign?.margin_bottom}px`,
          marginLeft: `${crossProductDesign?.margin_left}px`,
          marginRight: `${crossProductDesign?.margin_right}px`,
          paddingTop: `${crossProductDesign?.padding_top}px`,
          paddingBottom: `${crossProductDesign?.padding_bottom}px`,
          paddingLeft: `${crossProductDesign?.padding_left}px`,
          paddingRight: `${crossProductDesign?.padding_right}px`,
          height: `${crossProductDesign?.height}${crossProductDesign?.height_unit}`,
          width: `${crossProductDesign?.width}${crossProductDesign?.width_unit}`,
          borderStyle: crossProductDesign?.border_style,
          borderWidth: `${crossProductDesign?.border_width}px`,
          borderColor: crossProductDesign?.border_color,
          float: crossProductDesign?.float,
          maxHeight: `${crossProductDesign?.max_height}${crossProductDesign?.max_height_unit}`,
          maxWidth: `${crossProductDesign?.max_width}${crossProductDesign?.max_width_unit}`,
          minHeight: `${crossProductDesign?.min_height}${crossProductDesign?.min_height_unit}`,
          minWidth: `${crossProductDesign?.min_width}${crossProductDesign?.min_width_unit}`,
        }}
      >
        <div
          // className='flex items-center justify-between mb-4'
          style={{
            textAlign: crossProductDesign?.font_align ? crossProductDesign?.font_align : 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <span
            // style={{ color: colors?.main_title_color }}
            style={{
              fontSize: crossProductDesign?.font_size ? `${crossProductDesign?.font_size}px` : '32px',
              lineHeight: `${crossProductDesign?.font_height}px`,
              fontWeight: crossProductDesign?.font_weight ? crossProductDesign?.font_weight : 500,
              fontStyle: crossProductDesign?.font_style,
              color: crossProductDesign?.font_color ? crossProductDesign?.font_color : '#111111',
              textDecoration: crossProductDesign?.font_decoration,
              textTransform: crossProductDesign?.font_capitalize,
              wordBreak: crossProductDesign?.font_break,
              textWrap: crossProductDesign?.font_wrap,
            }}
            // className='font-medium break-words overflow-hidden'
          >
            {productPageData?.crossSellProductTitle}
          </span>
        </div>
        <div className='mx-auto product-slider'>
          <div className='pb-20'>
            <Slider
              slidesToShow={
                window?.innerWidth > 1200
                  ? 4
                  : window?.innerWidth > 991 && window?.innerWidth <= 1200
                  ? 3
                  : window?.innerWidth > 768 && window?.innerWidth <= 991
                  ? 2
                  : window?.innerWidth > 575 && window?.innerWidth <= 768
                  ? 2
                  : window?.innerWidth > 100 && window?.innerWidth <= 640
                  ? 1
                  : 1
              }
              centerPadding='0'
              autoplay={true}
              autoplaySpeed={3000}
              pauseOnHover={true}
              swipeToSlide={true}
              slidesToScroll={1}
              infinite={crossSell?.length > 1 ? true : false}
              prevArrow={
                <div className='slick-prev'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={12}
                    height={20}
                    viewBox='0 0 12 20'
                    id='left'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <g
                        fill={`${colors?.primary_button_color}`}
                        fillRule='nonzero'
                        transform='translate(-14 -8)'
                      >
                        <g transform='translate(14 8)'>
                          <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              }
              nextArrow={
                <div className='slick-next'>
                  <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
                    <path
                      fill={`${colors?.primary_button_color}`}
                      d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
                    />
                  </svg>
                </div>
              }
            >
              <div>
                <div className='flex items-center justify-center'>
                  <div className='block px-2 productHover relative'>
                    {product?.stock_status === 'out_of_stock' ? (
                      <div className='cursor-pointer h-[416px] image-div hover:grayscale'>
                        <img
                          className='w-full h-full max-h-full min-w-full object-cover object-top hover:grayscale'
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={product?.image || NOPHOTO_IMAGE}
                          alt={product?.product_name}
                          width={300}
                          height={300}
                          style={{ objectFit: crossProductDesign?.fit }}
                        />
                        <div className='outofstock absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-base w-[100px] h-[100px] flex items-center justify-center p-5 bg-secondary-color bg-opacity-80 rounded-full text-white'>
                          {t('lang_Sold_Out')}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className='absolute top-2 left-3 z-20'>
                          <input type='checkbox' defaultChecked={true} />
                        </div>
                        <div className='cursor-pointer h-[416px]'>
                          <Link aria-label={product.product_slug_name}>
                            <img
                              className='w-full h-full max-h-full min-w-full object-cover object-top'
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={product?.image || NOPHOTO_IMAGE}
                              alt={product?.product_name}
                              width={300}
                              height={400}
                              style={{ objectFit: crossProductDesign?.fit }}
                            />
                          </Link>
                        </div>
                      </>
                    )}
                    <div className='pt-2'>
                      <div>
                        <p
                          style={{ color: colors?.product_title_color }}
                          className='text-[16px] font-normal leading-tight capitalize mb-1 line-clamp-2'
                        >
                          {product?.product_name}
                        </p>
                        {product?.average_rating && (
                          <Rating
                            name='read-only'
                            size='small'
                            style={{ color: colors?.primary_body_text_color }}
                            precision={0.5}
                            value={product?.average_rating}
                            readOnly
                          />
                        )}
                        {product?.selling_price !== product?.mrp ? (
                          <span
                            style={{ color: colors?.price_color }}
                            className='text-base font-medium flex items-center'
                          >
                            <p>{FormatAmount(product?.selling_price)}</p>
                            <del
                              style={{ color: colors?.compare_price_color }}
                              className='text-base ms-2 font-[400]'
                            >
                              {FormatAmount(product?.mrp)}
                            </del>
                          </span>
                        ) : (
                          <p
                            style={{ color: colors?.price_color }}
                            className='text-base font-medium'
                          >
                            {FormatAmount(product?.mrp)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {product?.cross_sell?.length > 0 ? (
                    <p style={{ color: colors?.primary_body_text_color }} className='text-2xl mx-2'>
                      +
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {crossSell?.length > 0 &&
                crossSell?.map((elem, index) => {
                  let discount = Math.round(elem.discount_percentage);
                  const date = new Date();
                  const todayDate = date.toISOString();
                  total = total += elem.mrp;
                  return (
                    elem.stock_status !== 'out_of_stock' && (
                      <div key={index}>
                        <div className='flex items-center justify-around'>
                          <div className='block productHover relative'>
                            {elem.stock_status == 'out_of_stock' ? (
                              <div className='cursor-pointer h-[416px] grayscale'>
                                <Link aria-label={elem.product_slug_name}>
                                  <img
                                    className='w-full h-full object-cover object-top grayscale lg:hover:grayscale'
                                    onError={(e) => {
                                      e.target.src = NOPHOTO_IMAGE;
                                    }}
                                    src={elem?.image || NOPHOTO_IMAGE}
                                    alt={elem.product_name}
                                    width={300}
                                    height={400}
                                    style={{ objectFit: crossProductDesign?.fit }}
                                  />
                                </Link>
                                <Link className='image-div h-[416px]'>
                                  {elem.stock_status == 'out_of_stock' && (
                                    <div className='outofstock absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-base w-[100px] h-[100px] flex items-center justify-center p-5 bg-black bg-opacity-80 rounded-full text-white'>
                                      {t('lang_Sold_Out')}
                                    </div>
                                  )}
                                  {!elem?.is_dummy && token && !clientInfo?.catalog_mode && (
                                    <button
                                      aria-label='notifyme'
                                      type='button'
                                      onMouseEnter={(e) => setIsHovered(elem?._id)}
                                      onMouseLeave={(e) => setIsHovered('')}
                                      style={{
                                        color:
                                          isHovered === elem?._id
                                            ? secondaryButtonTextColor
                                            : primaryButtonTextcolor,
                                        backgroundColor:
                                          isHovered === elem?._id
                                            ? secondaryButtonColor
                                            : primaryButtoncolor,
                                      }}
                                      className='w-full block py-4 px-3 text-sm'
                                    >
                                      {t('lang_Notify_Me')}
                                    </button>
                                  )}
                                </Link>
                              </div>
                            ) : (
                              <>
                                <div className='absolute top-2 left-8 z-20'>
                                  <input
                                    type='checkbox'
                                    defaultChecked={elem.isSelected === true}
                                  />
                                </div>
                                <div className='cursor-pointer h-[416px]'>
                                  <Link aria-label={elem.product_slug_name}>
                                    <img
                                      className='h-full object-cover object-top w-full'
                                      onError={(e) => {
                                        e.target.src = NOPHOTO_IMAGE;
                                      }}
                                      src={elem.image || NOPHOTO_IMAGE}
                                      alt={elem.product_name}
                                      width={300}
                                      height={400}
                                      style={{ objectFit: crossProductDesign?.fit }}
                                    />
                                  </Link>
                                </div>
                              </>
                            )}
                            <div className='pt-2'>
                              <div className='w-full'>
                                <p
                                  style={{ color: colors?.primary_body_text_color }}
                                  className='text-[16px] font-normal leading-tight capitalize mb-1 line-clamp-2'
                                >
                                  {elem?.product_name}
                                </p>
                                {elem?.average_rating && (
                                  <Rating
                                    style={{ color: colors?.primary_button_color }}
                                    name='read-only'
                                    size='small'
                                    precision={0.5}
                                    value={elem?.average_rating && elem?.average_rating}
                                    readOnly
                                  />
                                )}
                              </div>

                              {elem?.selling_price !== elem?.mrp ? (
                                <span
                                  style={{ color: colors?.price_color }}
                                  className='text-base font-medium flex items-center'
                                >
                                  <p>{FormatAmount(elem?.selling_price)}</p>
                                  <del
                                    style={{ color: colors?.compare_price_color }}
                                    className='text-base ms-2 font-[400]'
                                  >
                                    {FormatAmount(elem?.mrp)}
                                  </del>
                                </span>
                              ) : (
                                <p
                                  style={{ color: colors?.price_color }}
                                  className='text-base font-medium'
                                >
                                  {FormatAmount(elem?.mrp)}
                                </p>
                              )}
                            </div>
                          </div>
                          {index + 1 !== crossSell?.length ? (
                            <p className='text-2xl mx-2'>+</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
            </Slider>
            <div>
              <div className='flex flex-wrap items-center justify-between mt-4'>
                <p
                  className='flex items-center text-lg font-medium'
                  style={{
                    display: productPageData?.showCrossSellProductsTotalPrice ? 'block' : 'none',
                  }}
                >
                  {t('lang_Total_Price')} :
                  <span style={{ color: colors?.price_color }} className='text-lg ml-1'>
                    {FormatAmount(totalPrice)}
                  </span>
                  <span
                    style={{ color: colors?.compare_price_color }}
                    className='text-lg font-normal ml-2'
                  >
                    <del>{FormatAmount(mrptotalPrice)}</del>
                  </span>
                </p>
                {!product?.is_dummy && !clientInfo?.catalog_mode && (
                  <button
                    aria-label='addtocart'
                    type='button'
                    onMouseEnter={(e) => setIsHovered('cross sell atc')}
                    onMouseLeave={(e) => setIsHovered('')}
                    style={{
                      color:
                        isHovered === 'cross sell atc'
                          ? colors?.secondary_button_text_color
                          : colors?.primary_button_text_color,
                      backgroundColor:
                        isHovered === 'cross sell atc'
                          ? colors?.secondary_button_color
                          : colors?.primary_button_color,
                      display: productPageData?.showCrossSellProductsAllToCart ? 'block' : 'none',
                    }}
                    className='px-11 block py-3 transition-all duration-300 text-sm'
                  >
                    {t('lang_Add_All_To_Cart')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrossSellProducts;
