import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';

const StaticForthCtaSection = ({
  section_id,
  section_type,
  saveElementorData,
  btnLoading,
  sectionContent,
  descriptionContent,
  handleForthCtaTitleValueChange,
  handleForthCtaTagChange,
  headingLevel,
  handleForthCtaDescriptionChange,
  handleForthCtaFirstBtnTitleValueChange,
  secondBtnContent,
  handleForthCtaSecondBtnTitleValueChange,
  handleForthCtaFirstBtnSubTitleValueChange,
  firstBtnLinkValue,
  handleForthCtaFirstBtnLinkValueChange,
  handleForthCtaSecondBtnSubTitleValueChange,
  secondBtnLinkValue,
  handleForthCtaSecondBtnLinkValueChange,
  secondBtnSubTitleContent,
  firstBtnSubTitleContent,
  firstBtnContent,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [firstBtnValue, setFirstBtnValue] = useState(firstBtnContent);
  const [firstBtnSubTitleValue, setFirstBtnSubTitleValue] = useState(firstBtnSubTitleContent);
  const [secondBtnSubTitleValue, setSecondBtnSubTitleValue] = useState(secondBtnSubTitleContent);
  const [firstBtnLink, setFirstBtnLink] = useState(firstBtnLinkValue);
  const [secondBtnLink, setSecondBtnLink] = useState(secondBtnLinkValue);
  const [secondBtnValue, setSecondBtnValue] = useState(secondBtnContent);

  const ShortquillRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setGroupTitle(sectionContent);
    setFirstBtnValue(firstBtnContent);
    setShortDescription(descriptionContent);
    setFirstBtnSubTitleValue(firstBtnSubTitleContent);
    setFirstBtnLink(firstBtnLinkValue);
    setSecondBtnLink(secondBtnLinkValue);
    setSecondBtnValue(secondBtnContent);
    setSecondBtnSubTitleValue(secondBtnSubTitleContent);
  }, [
    sectionContent,
    firstBtnContent,
    descriptionContent,
    firstBtnSubTitleContent,
    firstBtnLinkValue,
    secondBtnLinkValue,
    secondBtnContent,
    secondBtnSubTitleContent,
  ]);

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleForthCtaTitleValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleForthCtaTitleValueChange(section_id, value);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleForthCtaTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleForthCtaDescriptionChange(section_id, value);
  };

  const firstBtnContentChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnValue(newValue);
    handleForthCtaFirstBtnTitleValueChange(section_id, newValue);
  };

  const firstBtnContentSubTitleChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnSubTitleValue(newValue);
    handleForthCtaFirstBtnSubTitleValueChange(section_id, newValue);
  };

  const secondBtnContentSubTitleChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnSubTitleValue(newValue);
    handleForthCtaSecondBtnSubTitleValueChange(section_id, newValue);
  };

  const firstBtnLinkValueChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnLink(newValue);
    handleForthCtaFirstBtnLinkValueChange(section_id, newValue);
  };

  const secondBtnLinkValueChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnLink(newValue);
    handleForthCtaSecondBtnLinkValueChange(section_id, newValue);
  };

  const secondBtnContentChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnValue(newValue);
    handleForthCtaSecondBtnTitleValueChange(section_id, newValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div className='w-full mb-4'>
        <label>First Button Title</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnValue}
          onChange={firstBtnContentChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>First Button Sub Title</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnSubTitleValue}
          onChange={firstBtnContentSubTitleChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>First Button Link</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnLink}
          onChange={firstBtnLinkValueChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>Second Button Title</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnValue}
          onChange={secondBtnContentChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>Second Button Sub Title</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnSubTitleValue}
          onChange={secondBtnContentSubTitleChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>Second Button Link</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnLink}
          onChange={secondBtnLinkValueChange}
        />
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticForthCtaSection;
