import { Pagination } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { CheckAll } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import NoRecords from './NoRecords';
import NotificationGroup from './NotificationGroup';
const AllClientNotification = ({ notificationCount, setNotificationCount }) => {
  const [openTab, setOpenTab] = useState(getQueryParams());
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [notificationData, setNotificationData] = useState();
  const [spinner, setSpinner] = useState(true);
  const [page, setPage] = useState(getInitialPage())
  useEffect(() => {
    replacePageInUrl(page)
  }, [page])
  const GetNotifications = async () => {
    setSpinner(true)
    try {
      if (openTab === 'all') {
        let ApiCall = await API.get(`/admin/notifications/getNotification?limit=10&page=${page}`);
        const res = decryptData(ApiCall?.data)
        setNotificationData(res);
        setSpinner(false)
      } else {
        let ApiCall = await API.get(`/admin/notifications/getNotification?read=1&limit=10&page=${page}`);
        const res = decryptData(ApiCall?.data)
        setNotificationData(res);
        setSpinner(false)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const getStartOfPeriod = (date, period) => {
    const d = moment(date);
    if (period === 'day') {
      return d.startOf('day');
    } else if (period === 'week') {
      return d.startOf('isoWeek');
    } else if (period === 'month') {
      return d.startOf('month');
    } else if (period === 'year') {
      return d.startOf('year');
    }
    return d;
  };
  const [storeNotification, setStoreNotification] = useState([
    { today: [] },
    { yesterday: [] },
    { thisWeek: [] },
    { thisMonth: [] },
    { thisYear: [] },
    { older: [] }
  ])
  const groupNotificationsByDate = (records) => {
    const groups = {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      thisYear: [],
      older: []
    };

    const now = moment();
    const today = getStartOfPeriod(now, 'day');
    const yesterday = moment(today).subtract(1, 'days');
    const startOfWeek = getStartOfPeriod(now, 'week');
    const startOfMonth = getStartOfPeriod(now, 'month');
    const startOfYear = getStartOfPeriod(now, 'year');

    records?.forEach(record => {
      const createdAt = moment(record.createdAt);
      if (createdAt.isSameOrAfter(today)) {
        groups.today.push(record);
      } else if (createdAt.isSameOrAfter(yesterday) && createdAt.isBefore(today)) {
        groups.yesterday.push(record);
      } else if (createdAt.isSameOrAfter(startOfWeek)) {
        groups.thisWeek.push(record);
      } else if (createdAt.isSameOrAfter(startOfMonth)) {
        groups.thisMonth.push(record);
      } else if (createdAt.isSameOrAfter(startOfYear)) {
        groups.thisYear.push(record);
      } else {
        groups.older.push(record);
      }
    });

    setStoreNotification(groups);
  };
  useEffect(() => {
    groupNotificationsByDate(notificationData?.notification?.records)
  }, [notificationData?.notification?.records])

  const handleMarkRead = async () => {
    try {
      let ApiCall = await API.post(`/admin/notifications/updateallNotification`);
      GetNotifications();
      setNotificationCount({ ...notificationCount, noti_count: 0 })
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const handlePerticularRead = async (id) => {
    try {
      await API.put(`/admin/notifications/updateNotification/${id}`);
      GetNotifications();
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();

  let startRecord = (notificationData?.notification?.currentPage - 1) * 10 + 1;
  let endRecord = Math.min(
    notificationData?.notification?.currentPage * 10,
    notificationData?.notification?.totalRecords,
  );
  useEffect(() => {
    GetNotifications();
  }, [openTab, page])
  const updateQueryParams = (params) => {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.set('type', params);
    window.history.pushState({}, '', window.location.pathname + '?' + currentUrl.toString());
  };
  function getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const initial = urlParams.get('type');
    return initial ? initial : 'all'; // Adjust for zero-based indexing
  }
  useEffect(() => {
    setPage(1)
    updateQueryParams(openTab)
  }, [openTab])
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto pb-[64px]'>
        <div className='pb-4 w-full'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_notifications'), path: '/notifications' },
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary capitalize'>{t('lang_notifications')}</h4>
            </div>
          </div>
        </div>
        <div className='overflow-y-auto w-full h-full primary-bg-color'>
          <div className='secondary-bg-color h-full px-4 rounded-[6px]'>
            <div className='flex items-center justify-between border-b main-border-color'>
              <div className='flex pb-2 pt-3 items-center w-full justify-between'>
                <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1">
                  <a
                    aria-label="tab1"
                    className={`tab ${(openTab === "all" ? 'focus-tab' : 'bg-transparent')}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('all');
                    }}
                    data-toggle="tab"
                    href="#link1"
                  >
                    {t('lang_all')}
                  </a>
                    <a
                      aria-label="tab1"
                      className={`tab ${(openTab === "unread" ? 'focus-tab': 'bg-transparent')}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab('unread');
                      }}
                      data-toggle="tab"
                      href="#link1"
                    >
                      {t('lang_unread')}
                    </a>
                </div>
                {openTab === 'unread' && notificationData?.notification?.records?.length > 0 &&
                  <button className='flex items-center text-[13px] sm:text-sm text-link' onClick={() => handleMarkRead()}><CheckAll className='me-1 w-4 h-4' />{t('lang_Mark_All_As_Read')}</button>
                }
              </div>
            </div>
            {
              spinner ? <div className='flex items-center justify-center py-3 w-full h-full'>
                <div className='data-loader'></div>
              </div> :
                <div className='pb-4 mt-4 space-y-4'>
                  {
                    notificationData?.notification?.records?.length > 0 ?
                      <>
                        <NotificationGroup title="Today" notifications={storeNotification?.today} handlePerticularRead={handlePerticularRead} />
                        <NotificationGroup title="Yesterday" notifications={storeNotification?.yesterday} handlePerticularRead={handlePerticularRead} />
                        <NotificationGroup title="This Week" notifications={storeNotification?.thisWeek} handlePerticularRead={handlePerticularRead} />
                        <NotificationGroup title="This Month" notifications={storeNotification?.thisMonth} handlePerticularRead={handlePerticularRead} />
                        <NotificationGroup title="This Year" notifications={storeNotification?.thisYear} handlePerticularRead={handlePerticularRead} />
                        <NotificationGroup title="Older" notifications={storeNotification?.older} handlePerticularRead={handlePerticularRead} />
                      </> :
                      <NoRecords />
                  }
                </div>
            }

          </div>
          {/* Paginator */}
          {notificationData?.notification ? (
            <div className='secondary-bg-color border main-border-color mt-4 flex p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
              <div className='hidden sm:block'>
                <p className='flex w-full  space-x-4'>
                  <span className='text-sm font-medium'>
                    {
                      `${startRecord} - ${endRecord} of ${notificationData?.notification?.totalRecords} Records`
                    }
                  </span>
                </p>
              </div>
              <div>
                {notificationData?.notification?.totalPages !== 1 && (
                  <Pagination
                    count={notificationData?.notification?.totalPages}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

export default AllClientNotification