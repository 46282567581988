export const ModuleData = [
    // {
    //     name: "Header menu",
    //     discripion: "Customize website top with links, cart, and wishlist.",
    //     redirect: "/module-setting/header-menu",
    //     section_type:'header'
    // },
    {
        name: "Manufacturer list",
        discripion: "Manage information and listings for different product brands.",
        redirect: "/module-setting/manufacture-group",
        section_type:'manufacture'
    },
    {
        name: "Category list",
        discripion: "Organize your product assortment for easy navigation and browsing.",
        redirect: "/module-setting/category-group",
        section_type:'category'
    },
    {
        name: "Product list",
        discripion: "Configure product display and reviews.",
        redirect: "/module-setting/product-group",
        section_type:'product'
    },
    {
        name: "Image slider",
        discripion: "Showcase featured products, promotions, or brand messages.",
        redirect: "/module-setting/slider-group",
        section_type:'slider'
    },
    {
        name: "Blog list",
        discripion: "Manage website content creation with articles, categories, and SEO.",
        redirect: "/module-setting/blog-group",
        section_type:'blog'
    },
    {
        name: "Banners",
        discripion: "Display eye-catching promotional banners.",
        redirect: "/module-setting/banner-group",
        section_type:'banner'
    },
    {
        name: "Reassurance",
        discripion: "Build trust with testimonials, guarantees, and security seals.",
        redirect: "/module-setting/reassurance-group",
        section_type:'reassurance'
    },
    {
        name: "CMS discount",
        discripion: "Manage promotions and targeted messages.",
        redirect: "/module-setting/cms-offer",
        section_type:'cmsoffer'
    },
    {
        name: "Footer",
        discripion: "Customize website bottom with links, social media, and copyright info.",
        redirect: "/module-setting/footer",
        section_type:'footer'
    },
    {
        name: "Common setting",
        discripion: "Global site configuration like buttons, text colors, background colors etc.",
        redirect: "/module-setting/common-setting",
        section_type:'color setting'
    }
]