import { t } from 'i18next';
import Navbar from '../Main/Navbar';
import Sidebar from '../Main/Sidebar';

const CommingSoon = () => {
  return (
    <div className='py-8 ml-72 mt-28 w-full'>
      <div className='flex items-center justify-center'>
        <div className='max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-black bg-opacity-30'>
          <div className='w-full'>
            <div className='m-8 my-20 mx-auto'>
              <div className='mb-8'>
                <h4 className='text-2xl font-bold mb-6 text-center'>{t('lang_Coming_Soon')}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
