import React from 'react';
import EditReviewPageStyleContainer from './EditReviewPageStyleContainer';

const EditReviewPageStyleSection = ({
  reviewSectionDesign,
  setReviewSectionDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditReviewPageStyleContainer
        reviewSectionDesign={reviewSectionDesign}
        setReviewSectionDesign={setReviewSectionDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditReviewPageStyleSection;
