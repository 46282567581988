import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';
import { FormControlLabel } from '@mui/material';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  handleQuillChange,
  subTabIndex,
  handleSubItemClick,
  handleChangeSubItemTitle,
  handleCopySubItem,
  handleRemoveSubItem,
  isHovered,
  setIsHovered,
  handleIconClick,
  handleSvgClick,
  handleIconFill,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });
  console.log('ListItem', item);
  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Copy'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>Title</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full mb-4'>
            <label>HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='price'>Plan</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='price'
              name='price'
              placeholder='Enter price...'
              value={item?.price || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='time_period'>Time period</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='time_period'
              name='time_period'
              placeholder='Enter time period...'
              value={item?.time_period || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='button'>Button</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='button'
              name='button'
              placeholder='Enter button name...'
              value={item?.button || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>Description</label>
            <ReactQuill
              modules={module}
              ref={ShortquillRef}
              className='w-full h-auto table-text rounded-[6px] mt-2'
              theme='snow'
              id='description'
              name='description'
              value={item?.description || ''}
              onChange={handleQuillChange}
              placeholder='Enter description'
              onBlur={() => {
                setMeta((prevMeta) => ({
                  ...prevMeta,
                  meta_description:
                    shortdescription
                      ?.replace(/<[^>]*>/g, ' ')
                      ?.split(' ')
                      .slice(0, 50)
                      .join(' ') || '',
                }));
              }}
            />
          </div>
          <div className='w-full mb-4'>
            <label>SubItem</label>
            {item?.subItem?.map((subVal, index) => {
              return (
                <div
                  key={index}
                  className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
                    subTabIndex === index ? 'bg-gray-100 divide-slate-400 divide-y' : ''
                  }`}
                >
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                      <span className='font-medium text-xs'>{subVal?.subLink}</span>
                    </div>
                    <div className='flex items-center space-x-3'>
                      <button
                        className='text-gray-400 hover:text-gray-600'
                        title='Edit'
                        onClick={() => handleSubItemClick(index)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-pencil-square'
                          viewBox='0 0 16 16'
                        >
                          <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                          <path
                            fillRule='evenodd'
                            d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
                          />
                        </svg>
                      </button>
                      <button
                        className='text-gray-400 hover:text-gray-600'
                        title='Copy'
                        onClick={() => handleCopySubItem(subVal, index)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-copy'
                          viewBox='0 0 16 16'
                        >
                          <path
                            fillRule='evenodd'
                            d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
                          />
                        </svg>
                      </button>
                      <button
                        className='text-red-400 hover:text-red-600'
                        title='Remove'
                        onClick={() => handleRemoveSubItem(index)}
                      >
                        <svg
                          className='w-5 h-5'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                        >
                          <path d='M18 6l-12 12M6 6l12 12' />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {subTabIndex === index && (
                    <>
                      <div className='mt-2'>
                        <div className='w-full mb-4'>
                          <label htmlFor='title'>Sub Title</label>
                          <input
                            className='w-full  px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
                            type='text'
                            id='subLink'
                            name='subLink'
                            placeholder='Enter title...'
                            value={subVal?.subLink || ''}
                            onChange={handleChangeSubItemTitle}
                          />
                        </div>
                      </div>
                      <div className='mt-2'>
                        <label htmlFor='text'>Icon</label>
                        <div
                          className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
                          onMouseEnter={() => {
                            setIsHovered(true);
                          }}
                          onMouseLeave={() => {
                            setIsHovered(false);
                          }}
                        >
                          {subVal?.icon &&
                            (React.isValidElement(subVal?.icon) ? (
                              React.cloneElement(subVal?.icon, { width: '100px', height: '100px' })
                            ) : (
                              <SvgSection
                                svgContent={subVal?.icon}
                                width='100px'
                                height='100px'
                                fill={''}
                                stroke={''}
                              />
                            ))}

                          {isHovered && (
                            <>
                              <button
                                className='text-xs absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                                onClick={() => handleIconClick('iconList')}
                              >
                                Icon library
                              </button>
                              <button
                                className='text-xs absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                                onClick={() => handleSvgClick()}
                              >
                                SVG Icon
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='w-full mb-2 mt-4'>
                        <label className='flex items-center justify-between cursor-pointer'>
                          <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                            Icon Fill
                          </span>
                          <FormControlLabel
                            control={
                              <MuiSwitch
                                checked={subVal?.isFill || false}
                                onChange={handleIconFill}
                                name='titleIconFill'
                                type='checkbox'
                              />
                            }
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const StaticFirstPricingSection = ({
  section_id,
  firstPricingValue,
  setFirstPricingValue,
  handleFirstPricingValue,
  tabIndex,
  setTabIndex,
  handleAddFirstPricingItem,
  handleCopyFirstPricingItem,
  handleFirstPricingDataRemoveValue,
  saveElementorData,
  btnLoading,
  section_type,
  handleFirstPricingTagChange,
  sectionContent,
  headingLevel,
  handleFirstPricingTitleValueChange,
  handleFirstPricingDescriptionChange,
  sectionGridContent,
  handleFirstPricingGridValueChange,
  descriptionContent,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];
  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [gridValue, setGridValue] = useState(sectionGridContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');

  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [subTabIndex, setSubTabIndex] = useState(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleFirstPricingTitleValueChange(section_id, value);
  };

  useEffect(() => {
    setGroupTitle(sectionContent);
    setShortDescription(descriptionContent);
    setGridValue(sectionGridContent);
    setSelectedHeadingLevel(headingLevel);
  }, [sectionContent, descriptionContent, sectionGridContent, headingLevel]);

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setFirstPricingValue(updatedfirstContentValue);
    handleFirstPricingValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = firstPricingValue?.item?.length
      ? firstPricingValue?.item?.[firstPricingValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Start',
      htmlTag: 'h3',
      price: 'Free',
      button: 'Button',
      time_period: '/mon',
      description: '<p>Literally you probably have not heard of them jean shorts.</p>',
      isEdit: false,
      subItem: [
        {
          subLink: 'Vexillologist pitchfork',
          icon: ` <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2.5'
                    class='w-3 h-3'
                    viewBox='0 0 24 24'
                  >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
          isFill: false,
        },
        {
          subLink: 'Tumeric plaid portland',
          icon: ` <svg
          fill='none'
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2.5'
          class='w-3 h-3'
          viewBox='0 0 24 24'
        >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          isFill: false,
        },
        {
          subLink: 'Mixtape chillwave tumeric',
          icon: ` <svg
          fill='none'
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2.5'
          class='w-3 h-3'
          viewBox='0 0 24 24'
        >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          isFill: false,
        },
      ],
    };
    const updatedItems = firstPricingValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddFirstPricingItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopyFirstPricingItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleFirstPricingDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setFirstPricingValue(updatedfirstContentValue);
    handleFirstPricingValue(section_id, updatedfirstContentValue);
  };

  const handleQuillChange = (content) => {
    const updatedfirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };
    setFirstPricingValue(updatedfirstContentValue);
    handleFirstPricingValue(section_id, updatedfirstContentValue);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleFirstPricingTagChange(section_id, newTag);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleFirstPricingTitleValueChange(section_id, newValue);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    setGridValue(newValue);
    handleFirstPricingGridValueChange(section_id, newValue);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleFirstPricingDescriptionChange(section_id, value);
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    const updatedfirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, objectFit: backgroundFit } : item,
      ),
    };

    setFirstPricingValue(updatedfirstContentValue);
    handleFirstPricingValue(section_id, updatedfirstContentValue);
  };

  const handleSubItemClick = (index) => {
    setSubTabIndex((prev) => (prev === index ? null : index));
  };

  const handleChangeSubItemTitle = (event) => {
    const newSubTitle = event.target.value;
    const updatedFirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) => {
        if (i === tabIndex) {
          return {
            ...item,
            isEdit: true,
            subItem: item?.subItem?.map((sub, subIndex) => {
              if (subIndex === subTabIndex) {
                return {
                  ...sub,
                  subLink: newSubTitle,
                };
              }
              return sub;
            }),
          };
        }
        return {
          ...item,
          isEdit: false,
        };
      }),
    };

    setFirstPricingValue(updatedFirstContentValue);
    handleFirstPricingValue(section_id, updatedFirstContentValue);
  };

  const handleCopySubItem = (item, index) => {
    const updatedEightFeatureValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((currentItem, i) => {
        if (i === tabIndex) {
          const newSubItem = {
            ...item,
            subLink: item?.subLink,
            icon: item?.icon,
          };

          const newSubItemList = [...currentItem?.subItem];
          newSubItemList?.splice(index + 1, 0, newSubItem);

          return {
            ...currentItem,
            isEdit: true,
            subItem: newSubItemList,
          };
        }
        return {
          ...currentItem,
          isEdit: false,
        };
      }),
    };

    setSubTabIndex(null);
    setFirstPricingValue(updatedEightFeatureValue);
    handleFirstPricingValue(section_id, updatedEightFeatureValue);
  };

  const handleRemoveSubItem = (index) => {
    const updatedEightFeatureValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((currentItem, i) => {
        if (i === tabIndex) {
          const newSubItemList = [...currentItem.subItem];
          newSubItemList?.splice(index, 1);
          return {
            ...currentItem,
            isEdit: true,
            subItem: newSubItemList,
          };
        }
        return {
          ...currentItem,
          isEdit: false,
        };
      }),
    };
    setSubTabIndex(null);
    setFirstPricingValue(updatedEightFeatureValue);
    handleFirstPricingValue(section_id, updatedEightFeatureValue);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedFirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) => {
        if (i === tabIndex) {
          return {
            ...item,
            isEdit: true,
            subItem: item?.subItem?.map((sub, subIndex) => {
              if (subIndex === subTabIndex) {
                return {
                  ...sub,
                  icon: iconComponent,
                };
              }
              return sub;
            }),
          };
        }
        return {
          ...item,
          isEdit: false,
        };
      }),
    };

    setFirstPricingValue(updatedFirstContentValue);
    handleFirstPricingValue(section_id, updatedFirstContentValue);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleIconFill = (e) => {
    const { checked, name } = e.target;
    const updatedFirstContentValue = {
      ...firstPricingValue,
      item: firstPricingValue?.item?.map((item, i) => {
        if (i === tabIndex) {
          return {
            ...item,
            isEdit: true,
            subItem: item?.subItem?.map((sub, subIndex) => {
              if (subIndex === subTabIndex) {
                return {
                  ...sub,
                  isFill: checked,
                };
              }
              return sub;
            }),
          };
        }
        return {
          ...item,
          isEdit: false,
        };
      }),
    };

    setFirstPricingValue(updatedFirstContentValue);
    handleFirstPricingValue(section_id, updatedFirstContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>
      <div className='mt-4'>
        <label>Section</label>
        {Array.isArray(firstPricingValue?.item) &&
          firstPricingValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              handleBgFitChange={handleBgFitChange}
              subTabIndex={subTabIndex}
              handleSubItemClick={handleSubItemClick}
              handleChangeSubItemTitle={handleChangeSubItemTitle}
              handleCopySubItem={handleCopySubItem}
              handleRemoveSubItem={handleRemoveSubItem}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleIconClick={handleIconClick}
              handleIconSelect={handleIconSelect}
              handleSvgClick={handleSvgClick}
              handleIconFill={handleIconFill}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>
      <div className='mt-4'>
        <label>Grid</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticFirstPricingSection;
