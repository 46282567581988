import { Dialog, FormControlLabel } from '@mui/material';
import { Move, Trash, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import API from '../../../API';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import MuiSwitch from '../../../controllers/MuiSwitch';
import { Transition } from '../../../controllers/Transition';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';

const EditCategorySection = ({ section_id, getSectionData, section_type }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [storeCategory, setStoreCategory] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [categoryGroupTitle, setCategoryGroupTitle] = useState('');
  const [categoryData, setCategoryData] = useState({
    category_limit: '5',
    carousel_status: true,
    status: true,
    category_name_display: true,
    category_image_width: '',
    category_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
  });
  const handleGetPerticularCategoryeGroup = async () => {
    try {
      const apiCall = await API.get(`/admin/dynamicCategory/getCategoryGroupById/${section_id}`);
      const result = decryptData(apiCall?.data);
      setCategoryData({
        button_text: result?.data[0]?.button_text,
        button_link: result?.data[0]?.button_link,
        category_limit: result?.data[0]?.category_limit,
        carousel_status: result?.data[0]?.carousel_status,
        status: result?.data[0]?.status,
        category_name_display: result?.data[0]?.category_name_display,
        category_image_width: result?.data[0]?.category_image_width,
        category_image_height: result?.data[0]?.category_image_height,
        carousel_speed_ms: result?.data[0]?.carousel_speed_ms,
        loop_forever: result?.data[0]?.loop_forever,
        auto_play: result?.data[0]?.auto_play,
        pause_on_hover: result?.data[0]?.pause_on_hover,
      });
      setCategoryGroupTitle(result?.data[0]?.category_section_title);
      setStoreCategory(result?.data[0]?.categories);
    } catch (error) {
      console.log(error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //     windowWidth > 768 && getSectionData({
  //         'section': {
  //             button_text: categoryData?.button_text,
  //             button_link: categoryData?.button_link,
  //             category_limit: categoryData?.category_limit,
  //             carousel_status: categoryData?.carousel_status,
  //             status: categoryData?.status,
  //             category_name_display: categoryData?.category_name_display,
  //             category_image_width: categoryData?.category_image_width,
  //             category_image_height: categoryData?.category_image_height,
  //             carousel_speed_ms: categoryData?.carousel_speed_ms,
  //             loop_forever: categoryData?.loop_forever,
  //             auto_play: categoryData?.auto_play,
  //             pause_on_hover: categoryData?.pause_on_hover,
  //             category_section_title: categoryGroupTitle,
  //             categories: storeCategory
  //         },
  //         'status': categoryData?.status,
  //     })
  // }, [categoryData, categoryGroupTitle, storeCategory])
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          button_text: categoryData?.button_text,
          button_link: categoryData?.button_link,
          category_limit: categoryData?.category_limit,
          carousel_status: categoryData?.carousel_status,
          status: categoryData?.status,
          category_name_display: categoryData?.category_name_display,
          category_image_width: categoryData?.category_image_width,
          category_image_height: categoryData?.category_image_height,
          carousel_speed_ms: categoryData?.carousel_speed_ms,
          loop_forever: categoryData?.loop_forever,
          auto_play: categoryData?.auto_play,
          pause_on_hover: categoryData?.pause_on_hover,
          category_section_title: categoryGroupTitle,
          categories: storeCategory,
        },
        status: categoryData?.status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [categoryData, categoryGroupTitle, storeCategory]);
  useEffect(() => {
    if (section_id) {
      handleGetPerticularCategoryeGroup();
    }
  }, [section_id]);
  const [addCategory, setAddCategory] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddCategory = async () => {
    try {
      const payload = encryptData({
        category_group_id: section_id,
        categories: selectedCategory,
      });
      await API.post('/admin/dynamicCategory/addCategory', payload);
      setSelectedCategory([]);
      setSearch('');
      setAddCategory(false);
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleSelectCategory = (id) => {
    if (id) {
      const category = selectedCategory.find((x) => x?.category === id);
      if (!category) {
        setSelectedCategory([...selectedCategory, { category: id }]);
        // setStoreCategory([...storeCategory, { id, name, image }])
      } else {
        const removeSelected = selectedCategory.filter((x) => String(x?.category) !== String(id));
        // const removeCategory = storeCategory.filter((x) => x?.id !== id)
        setSelectedCategory(removeSelected);
        // setStoreCategory(removeCategory)
      }
    }
  };
  const [search, setSearch] = useState('');
  const [allCategory, setAllCategory] = useState([]);
  const GetCategories = async () => {
    try {
      const apiCall = await API.get(
        `/admin/category/GetCategoriesWithProductCount?searchkey=${search}&group=${section_id}`,
      );
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data);
        setAllCategory(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addCategory === true && (search?.length === 0 || search?.length >= 3)) {
      GetCategories();
    }
    if (!addCategory) {
      setSearch('');
      setSelectedCategory([]);
    }
  }, [addCategory, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddCategoryGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        category_section_title: categoryGroupTitle,
        button_text: categoryData?.button_text,
        button_link: categoryData?.button_link,
        category_limit: categoryData?.category_limit,
        carousel_status: categoryData?.carousel_status,
        status: categoryData?.status,
        category_image_width: categoryData?.category_image_width,
        category_image_height: categoryData?.category_image_height,
        carousel_speed_ms: categoryData?.carousel_speed_ms,
        loop_forever: categoryData?.loop_forever,
        auto_play: categoryData?.auto_play,
        pause_on_hover: !categoryData?.auto_play ? false : categoryData?.pause_on_hover,
      });
      await API.put(`/admin/dynamicCategory/updateCategoryGroup/${section_id}`, payload);
      getSectionData('call layout');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      await API.post(`/admin/dynamicCategory/deleteCategory`, payload);
      setIsNotification(true);
      setNotificationMsg('Removed SuccesFully!');
      setSeverity('success');
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const sortCategory = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/dynamicCategory/updateCategory/${id}`, payload);
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...storeCategory];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortCategory(DragOverItem.current, dragItemContent._id);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { storeCategory: _sort };
    setStoreCategory(FinalArray);
  };
  const columns = [
    {
      name: '',
      width: '40px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move text-center block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'Image',
      width: '150px',
      cell: (row) => (
        <img
          width={80}
          height={50}
          src={row?.categoryData?.image}
          className='p-1 max-w-full max-h-full'
        />
      ),
    },
    {
      name: 'category name',
      cell: (row) => (
        <span className='line-clamp-2'>{row?.categoryData?.category_name || '-'}</span>
      ),
      minWidth: '250px',
    },
    {
      name: <span className='w-full text-right capitalize'>{t('lang_action')}</span>,
      width: '100px',
      cell: (row) => (
        <div className='space-x-2 w-full text-right'>
          <button
            onClick={(e) => {
              e.preventDefault();
              setConfirmationPopup(true);
              setSelectedRowsId([row?._id]);
            }}
          >
            <Trash className='w-[17px] h-[17px] text-red-500' />
          </button>
        </div>
      ),
    },
  ];

  const getAiGeneratedTitle = (value) => {
    setCategoryGroupTitle(value);
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete Category'
        subheading='Do you really want to delete this category from this category group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='w-full space-y-4'>
        <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='wfull'>
          <div className='secondary-bg-color w-full space-y-4'>
            <div className='w-full mb-4'>
              <label htmlFor='group title' className='flex justify-between'>
                <label htmlFor='group title'>{t('lang_Category_Group_Title')}</label>
                <GenerateLiveEditiorTitle
                  getAiGeneratedTitle={getAiGeneratedTitle}
                  section_type={section_type}
                />
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={categoryGroupTitle}
                onChange={(e) => {
                  setCategoryGroupTitle(e.target.value);
                  // handleChange();
                }}
                placeholder='Enter title....'
              />
              {dataErr && categoryGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 gap-5 w-full'>
              <div className='w-full'>
                <label>{t('lang_Category_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={categoryData?.category_limit}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, category_limit: e.target.value });
                      // handleChange();
                    }}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>

              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.carousel_speed_ms}
                    disabled={categoryData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, carousel_speed_ms: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>

              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={categoryData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => {
                    setCategoryData({ ...categoryData, button_text: e.target.value });
                    // handleChange();
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={categoryData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => {
                    setCategoryData({ ...categoryData, button_link: e.target.value });
                    // handleChange();
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Category_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.category_image_width}
                    onChange={(e) => {
                      setCategoryData({
                        ...categoryData,
                        category_image_width: Number(e.target.value),
                      });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 100'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {'lang_Category_Image_Height'}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.category_image_height}
                    onChange={(e) => {
                      setCategoryData({
                        ...categoryData,
                        category_image_height: Number(e.target.value),
                      });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 150'
                  />
                </div>
              </div>

              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                <p className='table-text inline-block font-medium text-[13px]'>
                  {t('lang_Category_Group_Status')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.status === true}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      {
                        setCategoryData({
                          ...categoryData,
                          status: Boolean(!categoryData?.status),
                        });
                        // handleChange();
                      }
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                <p className='table-text inline-block font-medium text-[13px]'>
                  {t('lang_Carousel_Status')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      {
                        setCategoryData({
                          ...categoryData,
                          carousel_status: !categoryData?.carousel_status,
                        });
                        // handleChange();
                      }
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                <p className='table-text inline-block font-medium text-[13px]'>
                  {t('lang_Carousel_Loop')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      {
                        setCategoryData({
                          ...categoryData,
                          loop_forever: !categoryData?.loop_forever,
                        });
                        // handleChange();
                      }
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                <p className='table-text inline-block font-medium text-[13px]'>
                  {t('lang_Autoplay')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, auto_play: !categoryData?.auto_play });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              {categoryData?.auto_play && (
                <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                  <p className='table-text inline-block font-medium text-[13px]'>
                    {t('lang_Pause_On_Hover')}
                  </p>
                  <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                    <FormControlLabel
                      checked={categoryData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          pause_on_hover: !categoryData?.pause_on_hover,
                        });
                        // handleChange();
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <div>
              {!categoryGroupTitle || categoryGroupTitle === '' ? (
                <button className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'>
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddCategoryGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
        <Dialog
          open={addCategory}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddCategory(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl'>{t('lang_Select_Categories')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddCategory(false);
                  setSearch('');
                  setSelectedCategory([]);
                  handleGetPerticularCategoryeGroup();
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm'
              placeholder='Search categories..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allCategory?.map((elem) => {
                return (
                  <label className='flex py-2 items-start justify-between'>
                    <div className='flex'>
                      <input
                        type='checkbox'
                        checked={selectedCategory?.find((x) => x?.category === elem?._id)}
                        value={elem?._id}
                        onChange={(e) => {
                          handleSelectCategory(elem?._id);
                        }}
                        className='block mt-1 w-4 h-4 me-4'
                      />
                      <div className='w-[60px] h-[60px] p-1 border me-2 border-gray-200 flex items-center justify-center'>
                        <img
                          src={elem?.image}
                          className='max-w-full max-h-full'
                          alt={elem?.category_name}
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.category_name}</p>
                    </div>
                    <p className='me-2 text-base '>({elem?.product_count})</p>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddCategory()}>
              {t('lang_add_category')}
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default EditCategorySection;
