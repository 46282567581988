import React, { useState } from 'react';
import FirstSecondSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/FirstSecondSectionAdd';
import FiveSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/FiveSectionAdd';
import ForthSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/ForthSectionAdd';
import ThirdSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/ThirdSectionAdd';
import SixSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/SixSectionAdd';
import SevenSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/SevenSectionAdd';
import EightSecionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/EightSecionAdd';
import NineSecionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/NineSectionAdd';
import TenSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/TenSectionAdd';
import ElevenSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/ElevenSectionAdd';
import TwelveSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/TwelveSectionAdd';
import SecondSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/SecondSectionAdd';
import GridTwoRows from '../Component/Layout/EditSection/DynamicSectionEditor/GridTwoRows';
import GridTwoCols from '../Component/Layout/EditSection/DynamicSectionEditor/GridTwoCols';
import GridThreeCols from '../Component/Layout/EditSection/DynamicSectionEditor/GridThreeCols';
import GridThreeRows from '../Component/Layout/EditSection/DynamicSectionEditor/GridThreeRows';
import GridTwoForTwo from '../Component/Layout/EditSection/DynamicSectionEditor/GridTwoForTwo';
import GridThreeForThree from '../Component/Layout/EditSection/DynamicSectionEditor/GridThreeForThree';
import FirstStaticBlogSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticBlogSection/FirstStaticBlogSection';
import FirstStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticContentSection';
import SecondStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticContentSection';
import { SelectSection } from '../App';
import ReactDragListView from 'react-drag-listview';
import Manufacture from '../Component/Layout/VisualLayout/Manufacture';
import Banner from '../Component/Layout/VisualLayout/Banner';
import ImageSlider from '../Component/Layout/VisualLayout/ImageSlider';
import CmsOffer from '../Component/Layout/VisualLayout/CmsOffer';
import Reassurance from '../Component/Layout/VisualLayout/Reassurance';
import Category from '../Component/Layout/VisualLayout/Category';
import Blog from '../Component/Layout/VisualLayout/Blog';
import Product from '../Component/Layout/VisualLayout/Product';
import ContextMenuItem from './ContextMenuItem';
import ThirdStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticContentSection';
import ForthStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticContentSection';
import FifthStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FifthStaticContentSection';
import SixStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SixStaticContentSection';
import SevenStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SevenStaticContentSection';
import EightStaticContentSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/EightStaticContentSection';
import FirstStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticFeatureSection';
import SecondStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticFeatureSection';
import ThirdStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticFeatureSection';
import ForthStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticFeatureSection';
import FifthStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FifthStaticFeatureSection';
import SixthStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SixthStaticFeatureSection';
import SevenStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SevenStaticFeatureSection';
import EightStaticFeatureSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/EightStaticFeatureSection';
import FirstStaticStepSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticStepSection';
import ThirdStaticStepSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticStepSection';
import FirstStaticTeamSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticTeamSection';
import SecondStaticStepSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticStepSection';
import SecondStaticTeamSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticTeamSection';
import ThirdStaticTeamSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticTeamSection';
import FirstStaticTestimonialSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticTestimonialSection';
import SecondStaticTestimonialSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticTestimonialSection';
import ThirdStaticTestimonialSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticTestimonialSection';
import FirstStaticStatisticSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticStatisticSection';
import SecondStaticStatisticSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticStatisticSection';
import ThirdStaticStatisticSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticStatisticSection';
import FirstStaticContactSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticContactSection';
import SecondStaticContactSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticContactSection';
import ThirdStaticContactSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticContactSection';
import FirstStaticCtaSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticCtaSection';
import SecondStaticCtaSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticCtaSection';
import ThirdStaticCtaSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticCtaSection';
import ForthStaticCtaSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticCtaSection';
import FirstStaticHeroSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticHeroSection';
import SecondStaticHeroSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticHeroSection';
import ThirdStaticHeroSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticHeroSection';
import ForthStaticHeroSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticHeroSection';
import FifthStaticHeroSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FifthStaticHeroSection';
import SixthStaticHeroSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SixthStaticHeroSection';
import FirstStaticPricingSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticPricingSection';
import SecondStaticPricingSection from '../Component/Layout/EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticPricingSection';

const SelectedStructureSection = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  gridValue,
  handleHeadingClick,
  activeHeadingIndex,
  activeHeading,
  handleHeadingClickLocal,
  setSectionAdd,
  handleSubDivClick,
  selectedIndex,
  removedIndices,
  handleImageClick,
  activeSelection,
  selectSection,
  setEditSection,
  dragProps,
  dropContainerRef,
  destinationId,
  setDestinationId,
  gridDestinationId,
  setGridDestinationId,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  setElementorId,
  commonSetting,
  getLayout,
  visualData,
  handleSort,
  manufactureDesign,
  categoryDesign,
  productDesign,
  reassuranceDesign,
  bannerDesign,
  sliderDesign,
  cmsOfferDesign,
  blogDesign,
  setEditProductOtherSection,
  hoverId,
  setHoverId,
  setFilterId,
  dropPosition,
  setDropPosition,
  getContextMenuItem,
}) => {
  const [selectSectionName, setSelectSectionName] = useState();
  const localItems = flexBoxValue?.map((item) => ({ ...item, isLocal: true }));
  // const databaseItems = getLayout?.map((item) => ({ ...item, isLocal: false }));
  const databaseItems = Array.isArray(getLayout)
    ? getLayout?.map((item) => ({ ...item, isLocal: false }))
    : [];
  console.log('getLayout', databaseItems);

  const {
    menuVisible,
    setMenuVisible,
    menuPosition,
    setMenuPosition,
    selectedSingleItem,
    handleRightClick,
    handleMenuItemClick,
    newItem,
    cutItem,
    setCopiedItem,
    handleCopyItem,
    copyItem,
    isEdit,
    setEditItem,
    setCutItem,
  } = ContextMenuItem();
  console.log('copyItem', copyItem);

  return (
    <>
      <SelectSection.Provider value={{ selectSectionName, setSelectSectionName }}>
        <ReactDragListView {...handleSort}>
          <div
            style={{ gap: commonSetting?.section_space }}
            // className='w-full live-editor-component h-auto mx-auto grid grid-cols-1'
            className='w-full h-auto mx-auto grid grid-cols-1 draggable-main-item'
          >
            {[...databaseItems, ...localItems]
              ?.filter((elem) => elem?.isLocal === false)
              ?.map((elem, index) => {
                const isActive =
                  elem?.section_id === editSection?.section_id &&
                  elem?.section_type === editSection?.section_type;
                return (
                  elem?.status && (
                    <div
                      // style={{
                      //   boxShadow: hoverId === index ? '6px 9px 35px 7px rgba(0,0,0,0.2)' : 'none',
                      //   background: commonSetting?.primary_body_bg_color,
                      // }}
                      key={index}
                      onDragStart={() => setFilterId(elem?._id)}
                      onDragOver={() => setHoverId(index)}
                      onDragLeave={(e) => {
                        e.preventDefault();
                        setHoverId(null);
                      }}
                      className='draggable-main-item'
                    >
                      {elem?.section_type === 'product' && elem?.status ? (
                        <div
                          style={{
                            overflow: productDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <Product
                            colors={commonSetting}
                            productData={elem}
                            productDesign={productDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'blog' && elem?.status ? (
                        <div
                          style={{
                            overflow: blogDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          // onClick={(e) =>
                          //   selectSection(e, elem?.section_id, elem?.section_type)
                          // }
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <Blog colors={commonSetting} blogData={elem} blogDesign={blogDesign} />
                        </div>
                      ) : elem?.section_type === 'category' && elem?.status ? (
                        <div
                          style={{
                            overflow: categoryDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <Category
                            colors={commonSetting}
                            categoryData={elem}
                            categoryDesign={categoryDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'manufacture' && elem?.status ? (
                        <div
                          style={{
                            overflow: manufactureDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <Manufacture
                            section_type={elem?.section_type}
                            colors={commonSetting}
                            brandData={elem}
                            manufactureDesign={manufactureDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'reassurance' && elem?.status ? (
                        <div
                          style={{
                            overflow: reassuranceDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <Reassurance
                            colors={commonSetting}
                            reassuranceData={elem}
                            reassuranceDesign={reassuranceDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'cmsoffer' && elem?.status ? (
                        <div
                          style={{
                            overflow: cmsOfferDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <CmsOffer
                            colors={commonSetting}
                            cmsOfferData={elem}
                            cmsOfferDesign={cmsOfferDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'slider' && elem?.status ? (
                        <div
                          style={{
                            overflow: sliderDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <ImageSlider
                            colors={commonSetting}
                            sliderData={elem}
                            sliderDesign={sliderDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'banner' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                            });
                            setEditProductOtherSection('');
                          }}
                        >
                          <Banner
                            colors={commonSetting}
                            // bannerData={
                            //   elem?.section_id === editSection?.section_id && visualData?.banner
                            //     ? visualData?.banner
                            //     : elem
                            // }
                            bannerData={elem}
                            bannerDesign={bannerDesign}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_cols' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstSecondSectionAdd
                            flexBoxValue={flexBoxValue}
                            setSectionAdd={setSectionAdd}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleSubDivClick={handleSubDivClick}
                            selectedIndex={selectedIndex}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_rows' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondSectionAdd
                            flexBoxValue={flexBoxValue}
                            setSectionAdd={setSectionAdd}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleSubDivClick={handleSubDivClick}
                            selectedIndex={selectedIndex}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_two_grid' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            activeHeading={activeHeading}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            setElementorId={setElementorId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_grid_two' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ForthSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_four_grid' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FiveSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_three_grid_three' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SixSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_four_grid_four' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SevenSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_two_grid_one' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <EightSecionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_one_grid_two' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <NineSecionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_six_grid_six' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <TenSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_five_grid' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ElevenSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'flex_two_grid_two' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <TwelveSectionAdd
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'grid_two_rows' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <GridTwoRows
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'grid_two_cols' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <GridTwoCols
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'grid_three_cols' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <GridThreeCols
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'grid_three_rows' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <GridThreeRows
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'grid_two_for_two' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <GridTwoForTwo
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'grid_three_for_three' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <GridThreeForThree
                            flexBoxValue={flexBoxValue}
                            removeSection={removeSection}
                            gridValue={gridValue}
                            handleFlexBoxClick={handleFlexBoxClick}
                            openAddSection={openAddSection}
                            activeIndex={activeIndex}
                            handleSectionClick={handleSectionClick}
                            sectionAdd={sectionAdd}
                            editSection={editSection}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            handleHeadingClick={handleHeadingClick}
                            activeHeadingIndex={activeHeadingIndex}
                            handleHeadingClickLocal={handleHeadingClickLocal}
                            activeHeading={activeHeading}
                            setSectionAdd={setSectionAdd}
                            handleSubDivClick={handleSubDivClick}
                            handleImageClick={handleImageClick}
                            activeSelection={activeSelection}
                            selectSection={selectSection}
                            setEditSection={setEditSection}
                            dragProps={dragProps}
                            dropContainerRef={dropContainerRef}
                            destinationId={destinationId}
                            setDestinationId={setDestinationId}
                            gridDestinationId={gridDestinationId}
                            setGridDestinationId={setGridDestinationId}
                            setElementorId={setElementorId}
                            dropPosition={dropPosition}
                            setDropPosition={setDropPosition}
                            handleRemoveSingleItem={handleRemoveSingleItem}
                            handleDuplicateSingleItem={handleDuplicateSingleItem}
                            handleCopySingleItem={handleCopySingleItem}
                            handleCutSingleItem={handleCutSingleItem}
                            getPrevSecId={getPrevSecId}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            menuPosition={menuPosition}
                            setMenuPosition={setMenuPosition}
                            selectedSingleItem={selectedSingleItem}
                            handleRightClick={handleRightClick}
                            handleMenuItemClick={handleMenuItemClick}
                            newItem={newItem}
                            cutItem={cutItem}
                            setCutItem={setCutItem}
                            setCopiedItem={setCopiedItem}
                            handleCopyItem={handleCopyItem}
                            copyItem={copyItem}
                            isEdit={isEdit}
                            setEditItem={setEditItem}
                            getContextMenuItem={getContextMenuItem}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-4' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ForthStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-5' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FifthStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-6' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SixStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-7' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SevenStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_content-8' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <EightStaticContentSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                            border:
                              elem?.section_id === editSection?.section_id &&
                              elem?.section_type === editSection?.section_type
                                ? '2px solid #2c6ecb'
                                : '',
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                            border:
                              elem?.section_id === editSection?.section_id &&
                              elem?.section_type === editSection?.section_type
                                ? '2px solid #2c6ecb'
                                : '',
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-4' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                            border:
                              elem?.section_id === editSection?.section_id &&
                              elem?.section_type === editSection?.section_type
                                ? '2px solid #2c6ecb'
                                : '',
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ForthStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-5' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FifthStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-6' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SixthStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-7' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SevenStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_feature-8' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <EightStaticFeatureSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_step-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticStepSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_step-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticStepSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_step-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticStepSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_team-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticTeamSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_team-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticTeamSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_team-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticTeamSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_testimonial-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticTestimonialSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_testimonial-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticTestimonialSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_testimonial-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticTestimonialSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_statistic-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticStatisticSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_statistic-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticStatisticSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_statistic-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticStatisticSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_contact-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticContactSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_contact-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticContactSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_contact-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticContactSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_cta-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticCtaSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_cta-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticCtaSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_cta-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticCtaSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_cta-4' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ForthStaticCtaSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_hero-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticHeroSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_hero-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticHeroSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_hero-3' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ThirdStaticHeroSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_hero-4' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <ForthStaticHeroSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_hero-5' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FifthStaticHeroSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_hero-6' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SixthStaticHeroSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_pricing-1' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <FirstStaticPricingSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : elem?.section_type === 'static_pricing-2' && elem?.status ? (
                        <div
                          style={{
                            overflow: bannerDesign?.overflow,
                          }}
                          className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                            isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                          }`}
                          onClick={(e) => {
                            selectSection(e, elem?.section_id, elem?.section_type);
                            setEditSection({
                              section_id: elem?.section_id,
                              section_type: elem?.section_type,
                              section_index: elem?.flexBoxIndex,
                            });
                          }}
                        >
                          <SecondStaticPricingSection
                            handleSectionClick={handleSectionClick}
                            openAddSection={openAddSection}
                            selectSection={selectSection}
                            setElementorId={setElementorId}
                            sectionIndex={elem?.flexBoxIndex}
                            section={elem}
                            dropContainerRef={dropContainerRef}
                            sectionAdd={sectionAdd}
                            dragProps={dragProps}
                            editSection={editSection}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                );
              })}
          </div>
        </ReactDragListView>
      </SelectSection.Provider>
      {/* {flexBoxValue?.map((section, sectionIndex) => {
        const shouldRender = !removedIndices.has(sectionIndex);
        console.log(section?.type, 'section?.type=-=-=>>');
        console.log(editSection, 'editSection=-==-=-=>>');
        if (!shouldRender) return null;
        return (
          <>
            <div
              key={sectionIndex}
              // className={`hover-border mt-8 group ${activeHeading?.itemIndex === sectionIndex &&
              //   activeHeading?.sectionType === section?.type &&
              //   activeHeading?.sectionIndex === sectionIndex
              //   ? 'active-border' : ''
              //   }`}
              className={`hover-border mt-8 group ${
                editSection?.section_type === section?.type &&
                section?.id === editSection?.section_id
                  ? 'active-border'
                  : ''
              }`}
              // onClick={(e) => handleSectionClick(e, sectionIndex, section?.type)}
            >
              {section?.type === 'flex_cols' ? (
                <FirstSecondSectionAdd
                  flexBoxValue={flexBoxValue}
                  setSectionAdd={setSectionAdd}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  activeHeading={activeHeading}
                  handleSubDivClick={handleSubDivClick}
                  selectedIndex={selectedIndex}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                  setElementorId={setElementorId}
                />
              ) : section?.type === 'flex_rows' ? (
                <SecondSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  activeHeading={activeHeading}
                  selectedIndex={selectedIndex}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                  setElementorId={setElementorId}
                />
              ) : section?.type === 'flex_two_grid' ? (
                <ThirdSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  activeHeading={activeHeading}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                  handleRemoveSingleItem={handleRemoveSingleItem}
                  handleDuplicateSingleItem={handleDuplicateSingleItem}
                  handleCopySingleItem={handleCopySingleItem}
                  handleCutSingleItem={handleCutSingleItem}
                  getPrevSecId={getPrevSecId}
                  setElementorId={setElementorId}
                />
              ) : section?.type === 'flex_grid_two' ? (
                <ForthSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_four_grid' ? (
                <FiveSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_three_grid_three' ? (
                <SixSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_four_grid_four' ? (
                <SevenSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_two_grid_one' ? (
                <EightSecionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_one_grid_two' ? (
                <NineSecionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_six_grid_six' ? (
                <TenSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_five_grid' ? (
                <ElevenSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'flex_two_grid_two' ? (
                <TwelveSectionAdd
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'grid_two_rows' ? (
                <GridTwoRows
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'grid_two_cols' ? (
                <GridTwoCols
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'grid_three_cols' ? (
                <GridThreeCols
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'grid_three_rows' ? (
                <GridThreeRows
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'grid_two_for_two' ? (
                <GridTwoForTwo
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'grid_three_for_three' ? (
                <GridThreeForThree
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  gridValue={gridValue}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  activeIndex={activeIndex}
                  handleSectionClick={handleSectionClick}
                  sectionAdd={sectionAdd}
                  editSection={editSection}
                  sectionIndex={sectionIndex}
                  section={section}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  activeHeading={activeHeading}
                  setSectionAdd={setSectionAdd}
                  handleSubDivClick={handleSubDivClick}
                  handleImageClick={handleImageClick}
                  activeSelection={activeSelection}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                />
              ) : section?.type === 'static_content-1' ? (
                <FirstStaticContentSection
                  handleSectionClick={handleSectionClick}
                  openAddSection={openAddSection}
                  selectSection={selectSection}
                  setElementorId={setElementorId}
                  sectionIndex={sectionIndex}
                  section={section}
                  dropContainerRef={dropContainerRef}
                  sectionAdd={sectionAdd}
                  dragProps={dragProps}
                  editSection={editSection}
                />
              ) : section?.type === 'static_content-2' ? (
                <SecondStaticContentSection
                  handleSectionClick={handleSectionClick}
                  openAddSection={openAddSection}
                  selectSection={selectSection}
                  setElementorId={setElementorId}
                  sectionIndex={sectionIndex}
                  section={section}
                  dropContainerRef={dropContainerRef}
                  sectionAdd={sectionAdd}
                  dragProps={dragProps}
                  editSection={editSection}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        );
      })} */}
    </>
  );
};

export default SelectedStructureSection;
