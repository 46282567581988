import { Pagination, Popover, Tooltip } from '@mui/material';
import { ArrowLeft, Eye, Filter } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { formatDate } from '../../controllers/FormatAmount';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const ReturnOrders = () => {
  const navigate = useNavigate();
  const [ReturnOrders, setReturnOrders] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [returnstatus, setReturnStatus] = useState('')
  const [page, setPage] = useState(getInitialPage());
  const [sortBy, setSortBy] = useState('');
  const [OrderType, setOrderType] = useState('')
  const [iconVisible, setIconVisible] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'DESC', isDecending: false });
  var startRecord = (ReturnOrders?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(ReturnOrders?.data?.currentPage * limit, ReturnOrders?.data?.totalRecords);
  useEffect(() => {
    replacePageInUrl(page)
  }, [page])
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetReturnOrders();
  };
  const GetReturnOrders = async () => {
    setDataLoading(true)
    try {
      let ApiCall = await API.get(`/admin/order/getReturnOrders?page=${page}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}&order_type=${OrderType}&status=${returnstatus}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setReturnOrders(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(page - 1)
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Orders' })
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setDataLoading(false)
    }
  };
  useEffect(() => {
    GetReturnOrders();
  }, [page, limit, OrderType, returnstatus]);
  const tableData = (ReturnOrders?.data?.records);
  const columns = [
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'order_id' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('order_id') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('order_id')}>
            {t("lang_order")}
            {
              iconVisible && sortBy === 'order_id' ?
                !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
            }
          </button>
        </div>
      ),
      width: '100px',
      cell: row => <span>{row?.order_id || '-'}</span>,
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'user.name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user.name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('user.name')}>
            {t("lang_customer")}
            {
              iconVisible && sortBy === 'user.name' ?
                !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
            }
          </button>
        </div>

      ),
      cell: row => <span className='capitalize'>{row?.user?.name || '-'}</span>,
      minWidth: '150px'
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'product_name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('product_name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('product_name')}>
            {t("lang_product")}
            {
              iconVisible && sortBy === 'product_name' ?
                !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
            }
          </button>
        </div>
      ),
      cell: row => <span className='line-clamp-2'>{row?.product?.product_name || '-'}</span>,
      minWidth: '200px'
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'model' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('model') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('model')}>
            {t("lang_model")}
            {
              iconVisible && sortBy === 'model' ?
                !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
            }
          </button>
        </div>
      ),
      cell: row => <span className=''>{row?.product?.model || '-'}</span>,
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'status' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('status') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('status')}>
            {t("status")}
          </button>
          {
            iconVisible && sortBy === 'status' ?
              !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
          }
        </div>

      ),
      cell: row => <div className='text-xs whitespace-nowrap'>
        {
          row.status === 'refund failed' || row?.status === 'return declined'
            ? (
              row?.status === 'canceled' ?
                <span className='status-bedge text-red-500 bg-red-100'>
                  {t("lang_cancelled")}
                </span> : <span className='status-bedge text-red-500 bg-red-100'>
                  {row?.status}
                </span>
            ) : row.status === 'return approved' ||
              row.status === 'return received' ||
              row.status === 'refunded' ? (
              <>
                <span className='status-bedge text-green-500 bg-green-100'>
                  {row.status}
                </span>
              </>
            ) : row.status === 'return requested' ||
              row.status === 'return shipped' ||
              row.status === 'refund pending' ?
              <>
                <span className='status-bedge text-yellow-500 bg-yellow-100'>
                  {row.status}
                </span>
              </> : row.status === 'refund processing' || row.status === 'processing' ?
                <>
                  <span className='status-bedge text-black-500 bg-blue-100'>
                    {row.status}
                  </span>
                </> :
                <>
                  <span className='status-bedge text-yellow-500 bg-yellow-100'>
                    {row.status}
                  </span>
                </>
        }
      </div>
    },
    {
      name: (
        <div className='flex items-center justify-end w-full'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'return_date' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('return_date') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('return_date')}>
            {
              iconVisible && sortBy === 'return_date' ?
                !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
            }
            {t("lang_date")}
          </button>
        </div>
      ),
      minWidth: '150px',
      cell: row => <div className='flex items-center justify-end w-full'>{formatDate(row?.return_date)}</div>,
    },
    {
      name: (
        <div className='text-right w-full'>
          {t("lang_action")}
        </div>
      ),
      cell: row => <div className='flex items-center justify-end w-full'>
        <Tooltip arrow title="View">
          <button className='transition-all duration-150' onClick={(e) => { e.preventDefault(); navigate(`/return-order/${row?._id}`) }}>
            <Eye className="w-[18px] h-[18px]" />
          </button>
        </Tooltip>
      </div>,
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/return-order/${row._id}`);
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: `${t("lang_return")} ${t("lang_orders")}`, path: '/return-orders' },
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{`${t("lang_return")} ${t("lang_orders")}`}</h4>
            </div>
          </div>
          {ReturnOrders?.data?.totalRecords > 0 && <Tooltip title={t('lang_filter')} arrow>
            <div
              className='filter-btn'
              onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}
            >
              <button className=''>
                <Filter className='h-5 w-5' />
              </button>
            </div>
          </Tooltip>}
        </div>
        <div className='overflow-x-hidden'>
          <div className={'flex w-full h-full overflow-hidden relative'}>
            <div className='w-full'>
              {
                dataLoading && !ReturnOrders ?
                  <TableSkeleton forTable="order" /> :
                  <div className='w-full  transition-all duration-100 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                    <DataTable
                      className='order-table'
                      columns={columns}
                      data={tableData}
                      onRowClicked={handleRowClick}
                      selectableRowsHighlight
                      highlightOnHover
                    />
                  </div>
              }
              {
                ReturnOrders?.data ?
                  <>
                    <div className='bg-white mt-1 border border-gray-300 min-w-full flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                      <span className='text-sm font-medium'>
                        {
                          limit === "all" ? `${ReturnOrders?.data?.totalRecords}` + " " + t("lang_records") :
                            `${startRecord} - ${endRecord} ${t("lang_of")} ${ReturnOrders?.data?.totalRecords} ${t("lang_records")}`
                        }
                      </span>
                      <div>
                        {
                          ReturnOrders?.data?.totalPages !== 1 &&
                          <Pagination
                            count={ReturnOrders?.data?.totalPages}
                            page={page}
                            onChange={(e, v) => setPage(v)}
                          />
                        }
                      </div>
                    </div>
                  </> : <></>
              }
            </div>
            <Popover
              open={filter}
              anchorEl={anchorEl}
              onClose={() => { setFilter(false); setAnchorEl(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { marginRight: '50px',marginTop:'10px' }, // Adjust padding and margin
              }}
              className='rounded-[6px]'
            >
              <div className='p-[20px] filter'>
                <p className='flex font-semibold uppercase text-xs m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t("lang_filter")}
                </p>
                <hr className=' main-border-color my-3'></hr>
                <div className='grid gap-y-5 max-w-full overflow-hidden'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t("lang_set_items_limit")}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full table-text font-medium text-sm border  main-border-color p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => { e.target.value === "all" ? setLimit('all') : setLimit(e.target.value); setPage(1) }}
                      >
                        {ReturnOrders?.data?.totalRecords > 10 ? <><option value='10'>10</option></> : <></>}
                        {ReturnOrders?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                        {ReturnOrders?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                        <option value='all' className='capitalize'>{t("lang_all")}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {`${t("lang_orders")} ${t("lang_type")}`}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full table-text font-medium text-sm border  main-border-color p-1.5'
                        name='limit'
                        value={OrderType}
                        onChange={(e) => { setOrderType(e.target.value); setPage(1) }}
                      >
                        <option value='' className='capitalize'>{t("lang_all")}</option>
                        <option value='1' className='capitalize'>{`${t("lang_registered")} ${t("lang_users")}`}</option>
                        <option value='2' className='capitalize'>{`${t("lang_guest")} ${t("lang_users")}`}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                      {`${t("lang_sort_by")} ${t("lang_status")}`}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full table-text font-medium text-sm border 
                      -gray-300 p-1.5'
                        name='returnstatus'
                        value={returnstatus}
                        onChange={(e) => { setReturnStatus(e.target.value); setPage(1) }}
                      >
                        <option value="" className='capitalize'>{t("lang_all")}</option>
                        <option value="return requested" className='capitalize'>{`${t("lang_return")} ${t("lang_requested")}`}</option>
                        <option value="cancelled" className='capitalize'>{t("lang_cancelled")}</option>
                        <option value="return declined" className='capitalize'>{`${t("lang_return")} ${t("lang_declined")}`}</option>
                        <option value="refunded" className='capitalize'>{("lang_refunded")}</option>

                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnOrders;
