import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Transition } from '../../controllers/Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import { FormatAmount } from '../../controllers/FormatAmount'
import API from '../../API';
import Notification from '../Notification';
import AppPlanRazorPay from './AppPlanRazorPay';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import AppCredential from './AppCredential';
import { t } from 'i18next';
const PurchaseAppPlan = ({ planPopup, closePlanPopup, selectApp, GetModules, callExtention }) => {
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [isCredential, setIsCredential] = useState(false)
    const [credentialsFields, setCredentialsFields] = useState()
    const [credentialsValues, setCredentialsValues] = useState()
    const handleGetCredentialValue = (value) => {
        setCredentialsValues(value)
    }
    const [selectedPlan, setSelectedPlan] = useState()
    const handlePrchasePlan = async (e, elem, credentials) => {
        e.preventDefault()
        setSelectedPlan(elem)
        if (credentials && credentials?.length > 0) {
            setIsCredential(true)
            setCredentialsFields(credentials)
        } 
        else {
            if (elem?.plan_type === 'Free') {
                try {
                    const payload = encryptData({
                        extension: selectApp?._id,
                        transaction_amount: elem?.price,
                        plan: elem?._id,
                        super_extension_id: selectApp?._id,
                        free_plan: true,
                        extension_name: selectApp?.extension_name
                    })
                    const apicall = await API.post(`/admin/extension/AddExtension`, payload)
                    if (apicall?.data) {
                        closePlanPopup();
                        GetModules();
                        callExtention()
                        setIsNotification(true)
                        setNotificationMsg("App Installed Successfully!");
                        setSeverity('success')
                    }
                } catch (error) {
                    setIsNotification(true)
                    setNotificationMsg(error);
                    setSeverity('error')
                }
            } else {
                AppPlanRazorPay(selectApp, elem?._id, elem?.price, closePlanPopup, GetModules, callExtention,"", redirectUrl)
            }
        }
    }
    const redirectUrl = (url) => {
        window.open(url, '_blank');
    }
    const handleAddExtention = async (credentialParams) => {
        if (selectedPlan?.plan_type === 'Free') {
            try {
                const payload = encryptData({
                    extension: selectApp?._id,
                    transaction_amount: selectedPlan?.price,
                    plan: selectedPlan?._id,
                    credentials: credentialParams,
                    super_extension_id: selectApp?._id,
                    free_plan: true,
                    extension_name: selectApp?.extension_name
                })
                const apicall = await API.post(`/admin/extension/AddExtension`, payload)
                if (apicall?.data) {
                    if (selectApp?.extension_name === "Google Merchant Store") {
                        try {
                            const googleOAuthPayload = credentialParams.reduce((acc, item) => {
                                acc[item.secret_key] = item.secret_value;
                                return acc;
                            }, {})
                            const payload = encryptData({ credentials: googleOAuthPayload })
                            const apicall = await API.post(`/admin/extension/GoogleOAuth2`, payload)
                            const result = decryptData(apicall?.data)
                            if (result?.data) {
                                redirectUrl(result?.data);
                                closePlanPopup();
                                GetModules();
                                callExtention()
                                setIsNotification(true)
                                setNotificationMsg("App Installed Successfully!");
                                setSeverity('success')
                            }
                        } catch (error) {
                            setIsNotification(true)
                            setNotificationMsg(error);
                            setSeverity('error')
                        }
                    } else {
                        closePlanPopup();
                        GetModules();
                        callExtention()
                        setIsNotification(true)
                        setNotificationMsg("App Installed Successfully!");
                        setSeverity('success')
                    }
                }
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error);
                setSeverity('error')
                closePlanPopup();
                GetModules();
                callExtention()
            }
        } else {
            AppPlanRazorPay(selectApp, selectedPlan?._id, selectedPlan?.price, closePlanPopup, GetModules, callExtention, credentialParams, redirectUrl)
        }
    }
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg();
        setSeverity()
    }
    const closeCredentialPopup = () => {
        setIsCredential(false)
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <Dialog
                open={planPopup && !isCredential}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => closePlanPopup()}
                aria-describedby='alert-dialog-slide-description'
            >
                <div className='relative min-w-[320px] sm:min-w-[500px] secondary-bg-color shadow dark:bg-gray-700 p-5'>
                    <div className='flex justify-between items-center'>
                        <h6 className='text-primary font-medium'>{t('lang_Purchase_plan')}</h6>
                        <button
                            type='button'
                            className='close-btn'
                            onClick={() => { closePlanPopup() }}
                        >
                            <X className='h-4 w-4' aria-hidden='true' />
                        </button>
                    </div><hr className='main-border-color my-4' />
                    <div className='space-y-4'>
                        {
                            selectApp?.available_plan?.map((elem, index) => {
                                return (
                                    elem?.status &&
                                    <div key={index} className='border space-y-1.5 main-border-color p-5 rounded-[6px]'>
                                        <div className='flex items-center justify-between'>
                                            <p className='text-sm text-primary font-medium'>
                                                {elem?.name}
                                                <span className='px-3 ms-1 py-[3px] text-xs rounded-full primary-bg-color'>{elem?.plan_type}</span>
                                            </p>
                                            <button onClick={(e) => handlePrchasePlan(e, elem, selectApp?.credentials)} className='text-xs btn text-white'>{t('lang_Purchase_plan')}</button>

                                        </div>
                                        {elem?.plan_type !== 'Free' && <p className='text-sm text-primary font-medium'>{FormatAmount(elem?.price)}</p>}
                                        {elem?.plan_type === 'Free' && <p className='text-sm text-primary'>{t('lang_Free_for')} <b>{elem?.duration}</b> {t('lang_days')}</p>}
                                        <p className='text-[13px] table-text font-medium'>{elem?.description}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Dialog>
            <AppCredential isCredential={isCredential} closeCredentialPopup={closeCredentialPopup} credentialsFields={credentialsFields} appName={selectApp?.extension_name} handleGetCredentialValue={handleGetCredentialValue} handleAddExtention={handleAddExtention} />
        </>
    )
}

export default PurchaseAppPlan