import { AlignCenter, AlignLeft, AlignRight } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useRef } from 'react';
import { Bandaid, Fonts, Upload } from 'react-bootstrap-icons';

const DividerSettingSection = ({
  section_id,
  handleDividerStyleChange,
  dividerValue,
  handleDividerWidthChange,
  handleAlignmentChange,
  handleAddElementChange,
  handleAddElementValue,
  saveElementorData,
  btnLoading,
}) => {
  const dropDownValue = [
    { value: 'solid', label: 'Solid' },
    { value: 'dashed', label: 'Dashed' },
    { value: 'dotted', label: 'Dotted' },
  ];

  const fileInputRef = useRef(null);

  const handleStyleChange = (e) => {
    const selectedStyle = JSON.parse(e.target.value);
    handleDividerStyleChange(section_id, selectedStyle);
  };

  const handleWidthChange = (e) => {
    const selectedWidth = e.target.value;
    handleDividerWidthChange(section_id, selectedWidth);
  };

  const handleElementChange = (value) => {
    handleAddElementChange(section_id, value);
  };

  const handleTextChange = (e) => {
    const selectedValue = e.target.value;
    handleAddElementValue(section_id, selectedValue);
  };

  return (
    <div className='p-4 border rounded-lg'>
      <div className='w-full mb-4'>
        <label>Style</label>
        <div className='relative'>
          <select
            className='w-full mt-2 border border-gray-300 p-2 rounded-lg'
            onChange={handleStyleChange}
            value={dividerValue?.style}
          >
            {dropDownValue.map((obj) => (
              <option key={obj.label} value={JSON.stringify(obj.value)}>
                {obj.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='w-full mb-4'>
        <label>Width (0-100%)</label>
        <input
          type='range'
          min='0'
          max='100'
          value={dividerValue?.width || 100}
          onChange={handleWidthChange}
          className='w-full mt-2'
        />
        <div>{dividerValue?.width}%</div>
      </div>

      <div className='w-full'>
        <label className='block mb-2'>Alignment</label>
        <div className='flex items-center space-x-2'>
          <button
            className={`p-2 border rounded-lg ${
              dividerValue?.align === 'left' ? 'bg-gray-300' : 'bg-white'
            }`}
            onClick={() => handleAlignmentChange(section_id, 'left')}
          >
            <AlignLeft />
          </button>
          <button
            className={`p-2 border rounded-lg ${
              dividerValue?.align === 'center' ? 'bg-gray-300' : 'bg-white'
            }`}
            onClick={() => handleAlignmentChange(section_id, 'center')}
          >
            <AlignCenter />
          </button>
          <button
            className={`p-2 border rounded-lg ${
              dividerValue?.align === 'end' ? 'bg-gray-300' : 'bg-white'
            }`}
            onClick={() => handleAlignmentChange(section_id, 'end')}
          >
            <AlignRight />
          </button>
        </div>
      </div>

      <div className='w-full'>
        <label className='block mb-2'>Add Element</label>
        <div className='flex items-center space-x-2'>
          <button
            className={`p-2 border rounded-lg ${
              dividerValue?.element === 'none' ? 'bg-gray-300' : 'bg-white'
            }`}
            onClick={() => handleElementChange('none')}
          >
            <Bandaid />
          </button>
          <button
            className={`p-2 border rounded-lg ${
              dividerValue?.element === 'text' ? 'bg-gray-300' : 'bg-white'
            }`}
            onClick={() => handleElementChange('text')}
          >
            <Fonts />
          </button>
          <button
            className={`p-2 border rounded-lg ${
              dividerValue?.element === 'upload' ? 'bg-gray-300' : 'bg-white'
            }`}
            onClick={() => handleElementChange('upload')}
          >
            <Upload />
          </button>
        </div>
      </div>

      {dividerValue?.element === 'text' && (
        <div className='w-full mb-4 mt-2'>
          <label htmlFor='group title'>Text</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='group title'
            name='group title'
            placeholder='Enter text here...'
            onChange={handleTextChange}
            value={dividerValue?.text}
          />
        </div>
      )}
      <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DividerSettingSection;
