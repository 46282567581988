import { ArrowLeft, Check } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  ArrowUpCircleFill,
  ClipboardPulse,
  ExclamationTriangleFill,
  Hammer,
  LightbulbFill,
} from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
const MyAnnouncements = () => {
  const location = useLocation();
  const [announcements, setAnnouncements] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  //   Get Announcements
  const GetAnnouncements = async () => {
    try {
      const payload = encryptData({
        id: location?.state,
      })
      let ApiCall = await API.post('/admin/announcement/UserReadAnnouncement',payload );
      const result = decryptData(ApiCall?.data)
      setAnnouncements(result?.data);
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    GetAnnouncements();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate()
  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='flex items-center space-x-2 pb-[30px]'>
        <button onClick={(e) => { e.preventDefault(); navigate('/announcement') }} className='back-arrow'><ArrowLeft /></button>
        <div>
          <Breadcrumbs crumbs={[
            { name: t('lang_dashboard'), path: `/dashboard` },
            { name: t('lang_announcements'), path: '/announcement' },
            { name: `${t("lang_view")} ${t("lang_announcements")}`, path: '/announcement/viewannouncement' }
          ]} />
          <h4 className='text-xl md:text-2xl font-medium text-start capitalize'>{`${t("lang_view")} ${t("lang_announcements")}`}</h4>
        </div>
      </div>
      <div className='overflow-x-hidden  h-full w-full'>
        <div className='secondary-bg-color p-5 rounded-[6px] border main-border-color'>
          <div className='flex items-center space-x-3 my-2'>
            {announcements?.tag === 'Warning' ? (
              <div className='border p-2 rounded-full bg-yellow-100'>
                <ExclamationTriangleFill className='w-7 h-7 text-yellow-500 ' />
              </div>
            ) : announcements?.tag === 'Tips' ? (
              <div className='border p-2 rounded-full bg-yellow-100'>
                <LightbulbFill className='w-7 h-7 text-yellow-500 ' />
              </div>
            ) : announcements?.tag === 'New Version' ? (
              <div className='border p-2 rounded-full bg-green-100'>
                <ArrowUpCircleFill className='w-7 h-7 text-green-500 ' />
              </div>
            ) : announcements?.tag === 'New Feature' ? (
              <div className='border p-2 rounded-full bg-green-100'>
                <ClipboardPulse className='w-7 h-7 text-green-500 ' />
              </div>
            ) : announcements?.tag === 'Success' ? (
              <div className='border p-2 rounded-full bg-green-100'>
                <Check className='w-7 h-7 text-green-500 ' />
              </div>
            ) : announcements?.tag === 'Improvement' ? (
              <div className='border p-2 rounded-full bg-green-100'>
                <Hammer className='w-7 h-7 text-green-500 ' />
              </div>
            ) : (
              <div className='border p-2 rounded-full bg-green-100'>
                <LightbulbFill className='w-7 h-7 text-green-500 ' />
              </div>
            )}

            <span className='text-2xl font-medium text-gray-600'>
              {announcements?.announcement_title}
            </span>
            {/* <span className='text-sm mt-2 font-medium text-gray-600'>{timeValue}</span> */}
          </div>
          {/* <div className='w-full'>
              <p
                className={`w-full  py-2 border text-xl rounded-[6px] focus:outline-none`}
                name='short_description'
                value={announcements?.short_description}
              >
                {announcements?.short_description}
              </p>
            </div> */}


          <div className='mb-4'>
            <div className={`w-full px-3 py-2 text-[15px] secondary-bg-color rounded-[6px] focus:outline-none`}
              dangerouslySetInnerHTML={{ __html: announcements?.description }}></div>
          </div>
          {announcements?.link && (
            <button
              onClick={() => window.open(`${announcements?.link}`, `_blank`)}
              className='btn inline-block text-white px-8 py-2 rounded-[6px] mx-auto capitalize'
            >
              {`${t("lang_know")} ${t("lang_more")}`}
            </button>
          )}
        </div>
        <Link
          to={`/announcement`}
          className='w-fit mt-2 flex text-link font-medium hover:underline text-sm items-center capitalize'
        >
          <ArrowLeft className='w-3 mr-1' />
          {`${t("lang_go_to")} ${t("lang_announcements")}`}
        </Link>
      </div>
    </div>
  );
};

export default MyAnnouncements;
