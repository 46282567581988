import React from 'react';
import EditCategoryStyleContainer from './EditCategortStyleContainer';

const EditCategoryStyleSection = ({
  categoryDesign,
  setCategoryDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditCategoryStyleContainer
        categoryDesign={categoryDesign}
        setCategoryDesign={setCategoryDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
        // setManufactureImageSelect={setManufactureImageSelect}
        // manufactureImageSelect={manufactureImageSelect}
      />
    </>
  );
};

export default EditCategoryStyleSection;
