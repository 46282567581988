import { Tooltip } from '@mui/material';
import { ChevronRight, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormatAmount } from '../../../controllers/FormatAmount';

const CartPageSection = ({
  colors,
  cartData,
  cartPageData,
  isHovered,
  setIsHovered,
  customFaq,
  handleSectionSelect,
  sectionSelect,
  cartSection,
  cartPageDetails,
  cartTitleSectionDesign,
  cartTableSectionDesign,
  cartOtherDetailsDesign,
}) => {

  return (
    <div className='mx-auto py-10 px-5  min-h-[415px]'>
      <div
      className={`mb-4 ${sectionSelect?.section_type === 'cart title' ? 'active-border' : ''} hover-border`}
      style={{ overflow: cartTitleSectionDesign?.overflow }}
    >
        {cartPageDetails?.showCartTitle ? (
          <p
            className={`text-secondary-color text-3xl font-semibold cursor-pointer`}
            onClick={(e) => handleSectionSelect(e,'cart title', cartSection[0]?.section_name)}
            style={{
              marginLeft: `${cartTitleSectionDesign?.margin_left}px`,
              marginRight: `${cartTitleSectionDesign?.margin_right}px`,
              marginTop: `${cartTitleSectionDesign?.margin_top}px`,
              marginBottom: `${cartTitleSectionDesign?.margin_bottom}px`,
              paddingLeft: `${cartTitleSectionDesign?.padding_left}px`,
              paddingRight: `${cartTitleSectionDesign?.padding_right}px`,
              paddingTop: `${cartTitleSectionDesign?.padding_top}px`,
              paddingBottom: `${cartTitleSectionDesign?.padding_bottom}px`,
              width: `${cartTitleSectionDesign?.width}${cartTitleSectionDesign?.width_unit}`,
              height: `${cartTitleSectionDesign?.height}${cartTitleSectionDesign?.height_unit}`,
              minWidth: `${cartTitleSectionDesign?.min_width}${cartTitleSectionDesign?.min_width_unit}`,
              minHeight: `${cartTitleSectionDesign?.min_height}${cartTitleSectionDesign?.min_height_unit}`,
              maxWidth: `${cartTitleSectionDesign?.max_width}${cartTitleSectionDesign?.max_width_unit}`,
              maxHeight: `${cartTitleSectionDesign?.max_height}${cartTitleSectionDesign?.max_height_unit}`,
              borderStyle: cartTitleSectionDesign?.border_style,
              borderWidth: `${cartTitleSectionDesign?.border_width}px`,
              borderColor: cartTitleSectionDesign?.border_color,
              fontSize: `${cartTitleSectionDesign?.font_size}px`,
              lineHeight: `${cartTitleSectionDesign?.font_height}px`,
              fontWeight: cartTitleSectionDesign?.font_weight,
              fontStyle: cartTitleSectionDesign?.font_style,
              color: cartTitleSectionDesign?.font_color,
              textAlign: cartTitleSectionDesign?.font_align,
              textDecoration: cartTitleSectionDesign?.font_decoration,
              textTransform: cartTitleSectionDesign?.font_capitalize,
              wordBreak: cartTitleSectionDesign?.font_break,
              textWrap: cartTitleSectionDesign?.font_wrap,
            }}
          >
            {cartPageDetails?.showCartName}
          </p>
        ) : (
          ''
        )}
      </div>
      {cartPageDetails?.showCartTable ? (
        <>
          <div
            className={`w-full ${sectionSelect?.section_type === 'cart table' ? 'active-border' : ''} hover-border`}
            onClick={(e) => handleSectionSelect(e,'cart table', cartSection[1]?.section_name)}
          >
            <div
              className='max-w-full overflow-x-auto md:overflow-x-auto md:w-full'
              style={{
                marginLeft: `${cartTableSectionDesign?.margin_left}px`,
                marginRight: `${cartTableSectionDesign?.margin_right}px`,
                marginTop: `${cartTableSectionDesign?.margin_top}px`,
                marginBottom: `${cartTableSectionDesign?.margin_bottom}px`,
                paddingLeft: `${cartTableSectionDesign?.padding_left}px`,
                paddingRight: `${cartTableSectionDesign?.padding_right}px`,
                paddingTop: `${cartTableSectionDesign?.padding_top}px`,
                paddingBottom: `${cartTableSectionDesign?.padding_bottom}px`,
                borderStyle: cartTableSectionDesign?.border_style,
                borderWidth: `${cartTableSectionDesign?.border_width}px`,
                borderColor: cartTableSectionDesign?.border_color,
                overflow: cartTableSectionDesign?.overflow,
              }}
            >
              <table
                style={{
                  borderColor: colors?.border_color,
                  width: `${cartTableSectionDesign?.width}${cartTableSectionDesign?.width_unit}`,
                  height: `${cartTableSectionDesign?.height}${cartTableSectionDesign?.height_unit}`,
                  minWidth: `${cartTableSectionDesign?.min_width}${cartTableSectionDesign?.min_width_unit}`,
                  minHeight: `${cartTableSectionDesign?.min_height}${cartTableSectionDesign?.min_height_unit}`,
                  maxWidth: `${cartTableSectionDesign?.max_width}${cartTableSectionDesign?.max_width_unit}`,
                  maxHeight: `${cartTableSectionDesign?.max_height}${cartTableSectionDesign?.max_height_unit}`,
                }}
                className='border overflow-x-auto'
              >
                <thead>
                  <tr
                    style={{
                      backgroundColor: colors?.secondary_body_bg_color,
                      color: colors?.secondary_body_text_color,
                      borderColor: colors?.border_color,
                    }}
                    className='border-b'
                  >
                    <th className='p-3 !min-w-[100px] !lg:min-w-[100px] text-xs font-semibold uppercase'>
                      {t('lang_action')}
                    </th>
                    <th className='p-3 !min-w-[70px] !max-w-[70px] !lg:min-w-[70px] text-xs font-semibold uppercase'>
                      {t('lang_image')}
                    </th>
                    <th className='p-3 min-w-[200px] lg:min-w-[300px] text-xs font-semibold uppercase'>
                      {t('lang_Product_Name')}
                    </th>
                    <th className='p-3 min-w-[100px] lg:min-w-[100px] text-xs font-semibold uppercase'>
                      {t('lang_price')}
                    </th>
                    <th className='p-3 min-w-[100px] lg:min-w-[180px] text-xs font-semibold uppercase'>
                      {t('lang_variants')}
                    </th>
                    <th className='p-3 min-w-[100px] lg:min-w-[150px] text-xs font-semibold uppercase'>
                      {t('lang_quantity')}
                    </th>
                    <th className='p-3 min-w-[100px] lg:min-w-[150px] text-xs font-semibold uppercase'>
                      {t('lang_discount')}
                    </th>
                    <th className='p-3 min-w-[100px] lg:min-w-[150px] text-xs font-semibold uppercase'>
                      {t('lang_total')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cartData?.products?.length > 0 ? (
                    cartData?.products?.map((elem, index) => {
                      return (
                        <tr
                          key={index}
                          style={{ borderColor: colors?.border_color }}
                          className='text-center border-b !h-auto !bg-transparent'
                        >
                          <td className='p-3 !min-w-[100px] !lg:min-w-[100px] text-xs font-semibold uppercase'>
                            <Tooltip title='Remove' arrow>
                              <button className='font-medium hover:text-red-500 transition-all duration-300 hover:underline'>
                                <X className='w-6 h-6' />
                              </button>
                            </Tooltip>
                          </td>
                          <td className='p-3 !min-w-[70px] !max-w-[70px] !lg:min-w-[70px] text-xs font-semibold uppercase'>
                            <Link aria-label='cartimage'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={elem?.image || NOPHOTO_IMAGE}
                                alt={elem.product_name}
                                width={70}
                                height={70}
                                style={{ borderColor: colors?.border_color }}
                                className='h-full w-16 object-cover object-center mx-auto border p-0.5'
                              />
                            </Link>
                          </td>
                          <td className='p-3 min-w-[200px] lg:min-w-[300px] text-xs font-semibold uppercase cursor-pointer'>
                            <Link
                              aria-label='cartname'
                              className='text-sm font-medium line-clamp-2 capitalize'
                            >
                              {elem?.name}
                            </Link>
                          </td>
                          <td className='p-3 min-w-[100px] lg:min-w-[100px] text-xs font-semibold uppercase'>
                            <p className='font-medium'>{FormatAmount(elem?.selling_price)}</p>
                          </td>
                          <td className='p-3 min-w-[100px] lg:min-w-[180px] text-xs font-semibold uppercase'>
                            <p className=' text-sm font-medium'>
                              {elem?.variant ? (
                                elem?.variant?.group?.map((x, index) => (
                                  <span key={index}>
                                    {x.group_value}{' '}
                                    {elem?.variant?.group?.length - 1 === index ? '' : '/'}
                                  </span>
                                ))
                              ) : (
                                <>-</>
                              )}
                            </p>
                          </td>
                          <td className='p-3 min-w-[100px] lg:min-w-[150px] text-xs font-semibold uppercase'>
                            <div className='flex justify-center text-base font-medium items-center'>
                              <div
                                style={{ borderColor: colors?.border_color }}
                                className='counter border my-2 flex'
                              >
                                <button
                                  aria-label='minus'
                                  style={{
                                    backgroundColor: colors?.secondary_body_bg_color,
                                    color: colors?.secondary_body_text_color,
                                  }}
                                  className='text-lg font-bold inline-block transition-all duration-200 w-8 h-8'
                                >
                                  <span className='m-auto text-md font-semibold'>−</span>
                                </button>
                                <input
                                  type='number'
                                  className='text-center w-10 transition-all duration-300 bg-white font-semibold text-sm md:text-base cursor-default flex items-center outline-none'
                                  name='quantity'
                                  value={elem?.quantity}
                                  readOnly
                                ></input>
                                <button
                                  aria-label='increse'
                                  style={{
                                    backgroundColor: colors?.secondary_body_bg_color,
                                    color: colors?.secondary_body_text_color,
                                  }}
                                  className='text-lg font-bold inline-block transition-all duration-200 w-8 h-8'
                                >
                                  <span className='m-auto text-md font-semibold'>+</span>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className='p-3 min-w-[100px] lg:min-w-[150px] text-xs font-semibold uppercase'>
                            <p className='text-sm font-medium'>
                              {elem?.product_total_discount > 0 ? (
                                <>{FormatAmount(elem?.product_total_discount || 0)}</>
                              ) : (
                                '-'
                              )}
                            </p>
                          </td>
                          <td className='p-3 min-w-[100px] lg:min-w-[150px] text-xs font-semibold uppercase'>
                            <p className='font-medium'>{FormatAmount(elem?.product_total || 0)}</p>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className='p-3'>
                      <p className='block w-[180px]'>{t('lang_Your_Cart_Is_Empty')} !</p>
                      {!clientInfo?.catalog_mode && (
                        <div>
                          <Link
                            aria-label='shopping'
                            onMouseEnter={(e) => setIsHovered('Start shopping')}
                            onMouseLeave={(e) => setIsHovered('')}
                            style={{
                              color:
                                isHovered === 'Start shopping'
                                  ? colors?.secondary_button_color
                                  : colors?.primary_button_color,
                            }}
                            type='button'
                            className='hover:underline transition-all duration-300 text-sm font-medium'
                          >
                            {t('lang_Start_Shopping')}
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </tbody>
                <tfoot className='p-3 '>
                  <tr className='text-center !bg-transparent'>
                    <td
                      style={{ borderColor: colors?.border_color }}
                      colSpan={6}
                      className='text-left border-t'
                    >
                      <button
                        aria-label='clearcart'
                        href='#'
                        className='hover:text-red-500 px-6 -mt-6 transition-all duration-150 text-sm font-medium hover:underline'
                      >
                        {t('lang_Clear_Cart')}
                      </button>
                      <hr style={{ borderColor: colors?.border_color }} className='my-2' />
                      <p className='h-[9px]'></p>
                    </td>
                    <td style={{ borderColor: colors?.border_color }} className='py-3 border-t'>
                      <p className='text-sm'>{t('lang_Cart_Total')}</p>
                      <p className='text-sm'>{t('lang_discounts')}</p>
                      <hr style={{ borderColor: colors?.border_color }} className='my-2' />
                      <p className='font-medium'>{t('lang_Grand_Total')}</p>
                    </td>
                    <td style={{ borderColor: colors?.border_color }} className='py-3 border-t'>
                      <p className='text-sm'>
                        {FormatAmount(
                          cartData?.products?.reduce(
                            (prev, curr) => prev + curr.selling_price * curr.quantity,
                            0,
                          ),
                        )}
                      </p>
                      <p className='text-sm'>- {FormatAmount(cartData?.total_discount)}</p>
                      <hr style={{ borderColor: colors?.border_color }} className='my-2' />
                      <p style={{ color: colors?.price_color }} className='font-medium'>
                        {FormatAmount(cartData?.grand_total)}
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div
            className=' mt-4 w-full text-center lg:w-[50%] mx-auto'
            style={{ display: cartPageData?.showDiscountSection ? 'block' : 'none' }}
            onClick={(e) => handleSectionSelect(e,'cart other section', cartSection[2]?.section_name)}
          >
            <div className='flex justify-center w-full'>
              <label htmlFor='discount_code' className='sr-only'>
                {t('lang_Discount_Code')}
              </label>
              <input
                id='discount_code'
                name='discount_code'
                type='discount_code'
                value={''}
                required
                style={{ borderColor: colors?.border_color }}
                className='relative block text-sm w-[55%] sm:w-[70%] border py-1.5 pl-4 outline-none'
                placeholder='Discount Coupon Code'
              />
              <button
                aria-label='apply'
                //   onMouseEnter={(e) => setIsHovered('apply discount')}
                //   onMouseLeave={(e) => setIsHovered('')}
                style={{
                  borderStartStartRadius: 0,
                  borderEndStartRadius: 0,
                  color:
                    isHovered === 'apply discount'
                      ? colors?.secondary_button_text_color
                      : colors?.primary_button_text_color,
                  backgroundColor:
                    isHovered === 'apply discount'
                      ? colors?.secondary_button_color
                      : colors?.primary_button_color,
                }}
                className='px-10 inline-block py-3 text-sm capitalize'
              >
                {t('lang_apply')}
              </button>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {cartPageDetails?.showCartOtherDetails ? (
        <div
          className={`text-center cursor-pointer ${
            sectionSelect?.section_type === 'cart other section' ? 'active-border' : ''
          } hover-border`}
          style={{
            overflow: cartOtherDetailsDesign?.overflow,
          }}
        >
          <div
            style={{
              marginLeft: `${cartOtherDetailsDesign?.margin_left}px`,
              marginRight: `${cartOtherDetailsDesign?.margin_right}px`,
              marginTop: `${cartOtherDetailsDesign?.margin_top}px`,
              marginBottom: `${cartOtherDetailsDesign?.margin_bottom}px`,
              paddingLeft: `${cartOtherDetailsDesign?.padding_left}px`,
              paddingRight: `${cartOtherDetailsDesign?.padding_right}px`,
              paddingTop: `${cartOtherDetailsDesign?.padding_top}px`,
              paddingBottom: `${cartOtherDetailsDesign?.padding_bottom}px`,
              borderStyle: cartOtherDetailsDesign?.border_style,
              borderWidth: `${cartOtherDetailsDesign?.border_width}px`,
              borderColor: cartOtherDetailsDesign?.border_color,
              width: `${cartOtherDetailsDesign?.width}${cartOtherDetailsDesign?.width_unit}`,
              height: `${cartOtherDetailsDesign?.height}${cartOtherDetailsDesign?.height_unit}`,
              minWidth: `${cartOtherDetailsDesign?.min_width}${cartOtherDetailsDesign?.min_width_unit}`,
              minHeight: `${cartOtherDetailsDesign?.min_height}${cartOtherDetailsDesign?.min_height_unit}`,
              maxWidth: `${cartOtherDetailsDesign?.max_width}${cartOtherDetailsDesign?.max_width_unit}`,
              maxHeight: `${cartOtherDetailsDesign?.max_height}${cartOtherDetailsDesign?.max_height_unit}`,
            }}
            onClick={(e) => handleSectionSelect(e,'cart other section', cartSection[2]?.section_name)}
          >
            <Link
              aria-label='checkout'
              // onMouseEnter={(e) => setIsHovered('/checkout')}
              // onMouseLeave={(e) => setIsHovered('')}
              style={{
                display: cartPageData?.showCheckoutButton ? 'inline-block' : '',
                borderRadius: 0,
                color:
                  isHovered === '/checkout'
                    ? colors?.secondary_button_text_color
                    : colors?.primary_button_text_color,
                backgroundColor:
                  isHovered === '/checkout'
                    ? colors?.secondary_button_color
                    : colors?.primary_button_color,
              }}
              className='inline-block px-14 text-sm mt-4 mx-auto py-3'
            >
              {t('lang_Checkout')}
            </Link>
            <div className='flex justify-center text-center font-semibold text-sm my-2'>
              <p>
                <span
                  className={
                    cartPageData?.showContinueShopping && cartPageData?.showCheckoutButton
                      ? 'block'
                      : ''
                  }
                >
                  {t('lang_OR')}
                </span>
                <Link
                  aria-label='continue'
                  // onMouseEnter={(e) => setIsHovered('Continue Shopping')}
                  // onMouseLeave={(e) => setIsHovered('')}
                  style={{
                    display: cartPageData?.showContinueShopping ? 'block' : '',
                    color:
                      isHovered === 'Continue Shopping'
                        ? colors?.secondary_button_color
                        : colors?.primary_button_color,
                  }}
                  className='font-medium block hover:underline mt-2 transition-all duration-300'
                >
                  {t('lang_Continue_Shopping')}
                </Link>
              </p>
            </div>
          </div>
          {customFaq && (
            <div
              className='w-full py-5 cursor-pointer'
              style={{
                display: cartPageData?.showCartCustomFaq ? 'block' : 'none',
                borderColor: colors?.border_color,
              }}
            >
              <div className='flex flex-col items-left'>
                <h3
                  style={{ color: colors?.main_title_color }}
                  className='font-medium break-words overflow-hidden'
                >
                  {t('lang_faqs')}
                </h3>
                <p color={{ color: colors?.primary_body_text_color }} className='text-base'>
                  {t('lang_Frequenty_Asked_Questions')}
                </p>
              </div>
              {customFaq?.length > 0 ? (
                <div style={{ borderColor: colors?.border_color }} className='grid mt-4 border'>
                  {customFaq?.map((elem, ind) => {
                    return (
                      <div
                        key={ind}
                        className='border-b'
                        style={{ borderColor: colors?.border_color }}
                      >
                        <div key={ind}>
                          <details className='group !bg-transparent'>
                            <summary className='flex outline-none p-5 transition-all duration-200 group-open:bg-[#F0F0F0]  items-start cursor-pointer list-none'>
                              <span className='min-w-[24px] max-w-[24px]'>
                                <ChevronRight className='me-2 w-4 h-4 mt-1 transition group-open:rotate-90' />
                              </span>
                              <span> {elem.question}</span>
                            </summary>
                            <p
                              dangerouslySetInnerHTML={{ __html: elem.answer }}
                              className='text-neutral-600 break-all px-5 pb-5 mt-3 group-open:animate-fadeIn'
                            ></p>
                          </details>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className='text-black text-lg'>{t('lang_No_Faqs_Found')}</p>
              )}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CartPageSection;
