import { Checkbox, FormControlLabel, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import ReactDragListView from 'react-drag-listview';

function ViewTab() {
  const navigate = useNavigate();
  const [clearRow, setClearRow] = useState(false);
  const [Page, setPage] = useState(getInitialPage());
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [allTab, setAllTab] = useState();
  const [sortBy, setSortBy] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const [viewTabId, setViewTabId] = useState(null);
  const [filter, setFilter] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [search, setSearch] = useState('');
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(Number(initialLimit) || 10);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  var startRecord = (allTab?.data?.currentPage - 1) * 20 + 1;
  var endRecord = Math.min(allTab?.data?.currentPage * 20, allTab?.data?.totalRecords);
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });

  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetAllTabs();
  };

  const GetAllTabs = async () => {
    setDataLoading(true);
    try {
      const gat = await API.get(
        `/admin/extratab/getExtratabs?&page=${Page}&searchkey=${search}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );

      if (gat.status === 200 || gat.status === 304) {
        const result = decryptData(gat?.data);
        setAllTab(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(Page - 1);
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'tab' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetAllTabs();
  }, [Page, limit, orderBy.orderByValue, search]);

  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleRemove = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/extratab/deleteExtratab`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Tab Removed Successfully!');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        GetAllTabs();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  // Update tabs Status
  const UpdateTabStatus = async (row, value) => {
    try {
      const payload = encryptData({
        tab_title: row.tab_title,
        tab_description: row.tab_description,
        tab_status: value,
      });
      await API.put(`/admin/extratab/updateExtratab/${row._id}`, payload);
      GetAllTabs();
      setIsNotification(true);
      setNotificationMsg(value ? 'Tab is set to active' : 'Tab is set to hidden');
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortCategory = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/extratab/updateExtratab/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // Drag And Drop Functionality
  // sorting DnD
  // const HandleSort = (result) => {
  //   const items = Array.from(allTab?.data?.records);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setAllTab({ data: { ...allTab?.data, records: items } });
  //   sortCategory(result?.destination?.index, result?.draggableId);
  // };
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...allTab?.data?.records];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setAllTab({ data: { ...allTab?.data, records: newItems } });
      sortCategory(toIndex, viewTabId);
      setViewTabId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = allTab?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = allTab?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = allTab?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(allTab?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Tab'
        subheading='Do you really want to delete this tab from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleRemove}
      />
      <div className='flex items-end justify-between z-40 w-full overflow-auto pb-[30px]'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_custom_tabs'), path: '/tabs' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_custom_tabs')}{' '}
            </h4>
          </div>
        </div>
        <div className='flex items-center'>
          <button
            className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-md transition duration-300'
            onClick={() => navigate('/tabs/add-tab')}
          >
            <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
            {t('lang_Add_New_Custom_Tab')}
          </button>
        </div>
      </div>
      <div className='w-full space-y-2'>
        <div className='flex items-center justify-between'>
          {selectedRowsId && selectedRowsId?.length > 0 ? (
            <div>
              <span className='text-sm px-3'>
                {selectedRowsId?.length} {t('lang_selected')}
              </span>
              |
              <button
                className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmationPopup(true);
                }}
              >
                <Trash2 className='w-3.5 h-3.5' />
                <span>{t('lang_delete_selection')}</span>
              </button>
            </div>
          ) : <div></div>}
            {allTab?.data?.totalRecords > 0 && <Tooltip title={t('lang_filter')} arrow>
              <div className='flex filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl2(e.currentTarget) }}>
                <button className=''>
                  <Filter className='h-5 w-5' />
                </button>
              </div>
            </Tooltip>}
        </div>
        {dataLoading && !allTab ? (
          <TableSkeleton forTable='order' />
        ) : allTab?.data?.records?.length > 0 ? (
          <div className='w-full inline-block sticky-table overflow-x-auto border border-gray-300 overflow-y-hidden rounded-[6px]'>
            <table className='w-full'>
              <thead>
                <tr>
                  <td className='table-head-checkbox px-3'>
                    <Checkbox
                      checked={selectedRowsId?.length === allTab?.data?.records?.length}
                      indeterminate={
                        selectedRowsId?.length !== allTab?.data?.records?.length &&
                        selectedRowsId?.length > 0
                      }
                      onChange={(e) => {
                        handleSelectRow(e, 'all');
                      }}
                    />
                  </td>
                  <td className='min-w-[300px] max-w-[300px] px-3'>
                    <div className='flex items-center'>
                      <button
                        className={`uppercase flex ${iconVisible && sortBy === 'tab_title' ? 'text-gray-700' : ''
                          }`}
                        onMouseEnter={() => {
                          setIconVisible(true);
                          setSortBy('tab_title');
                        }}
                        onMouseLeave={() => {
                          setIconVisible(false);
                          setSortBy('');
                        }}
                        onClick={() => SortData('tab_title')}
                      >
                        Title
                        {iconVisible ? (
                          !orderBy.isDecending ? (
                            <CaretUpFill className='ms-2' />
                          ) : (
                            <CaretDownFill className='ms-2' />
                          )
                        ) : (
                          <></>
                        )}
                      </button>
                    </div>
                  </td>
                  <td className='w-full px-3'>{t('lang_description')}</td>
                  <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                    {t('lang_status')}
                  </td>
                  <td className='min-w-[100px] max-w-[100px] px-3 text-right uppercase'>
                    {t('lang_action')}
                  </td>
                </tr>
              </thead>
              <tbody>
                <ReactDragListView {...dragProps}>
                  {allTab?.data?.records?.map((elem, index) => (
                    <tr
                      className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                        }`}
                      style={{
                        // boxShadow: snapshot.isDragging
                        //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                        //   : 'none',
                        background: 'white',
                      }}
                      key={index}
                      onClick={() => {
                        navigate(`/tabs/${elem._id}`);
                      }}
                      onDragStart={() => setViewTabId(elem?._id)}
                    >
                      <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          checked={
                            selectedRowsId?.length > 0 &&
                            selectedRowsId?.find((x) => x === elem?._id)
                          }
                          onChange={(e) => handleSelectRow(e, elem?._id)}
                        />
                      </td>
                      <td className='min-w-[300px] max-w-[300px] px-3'>
                        <span className='capitalize'>{elem.tab_title || '-'}</span>
                      </td>
                      <td className='w-full px-3'>
                        <span className='line-clamp-1'>
                          {elem?.tab_description
                            ? elem?.tab_description?.replace(/<[^>]*>/g, ' ')
                            : '-'}
                        </span>
                      </td>
                      <td className='min-w-[100px] max-w-[100px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                        <label className='relative flex justify-end w-full'>
                          <FormControlLabel
                            checked={elem.tab_status === true}
                            control={<MuiSwitch />}
                            onChange={() => {
                              UpdateTabStatus(elem, !elem.tab_status);
                            }}
                          />
                        </label>
                      </td>
                      <td className='min-w-[100px] max-w-[100px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                        <div className='w-full space-x-2 text-right'>
                          <Tooltip title='Edit' arrow>
                            <button
                              className=' text-black-500 hover:text-black-600'
                              onClick={() => {
                                navigate(`/tabs/${elem._id}`);
                              }}
                            >
                              <Edit className='w-[17px] h-[17px]' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              className='text-red-500 hover:text-red-600'
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirmationPopup(true);
                                setSelectedRowsId([elem?._id]);
                              }}
                            >
                              <Trash className=' w-[17px] h-[17px]' />
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </ReactDragListView>
              </tbody>
            </table>
          </div>
        ) : (
          <NoRecords />
        )}
        {/* Paginator */}
        {allTab?.data ? (
          <div className='bg-white border border-gray-300 rounded-[6px] flex flex-1 p-5 items-center justify-between pagination overflow-hidden'>
            <span className='text-sm font-medium'>
              {`${startRecord} - ${endRecord} of ${allTab?.data?.totalRecords} Records`}
            </span>
            <div>
              {allTab?.data?.totalPages !== 1 ? (
                <Pagination
                  count={allTab?.data?.totalPages}
                  page={Page}
                  onChange={(e, v) => setPage(v)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Popover
        open={filter}
        anchorEl={anchorEl2}
        onClose={() => { setFilter(false); setAnchorEl2(null) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
        }}
        className='rounded-[6px]'
      >
        <div className='p-[20px] filter'>
          <p className='flex font-semibold uppercase text-sm m-0'>
            <Filter className='me-1 w-4 h-4' />
            {t('lang_filter')}
          </p>
          <hr className='main-border-color my-3'></hr>
          <div className='grid gap-y-5 table-text'>
            <div>
              <span className='text-sm capitalize font-medium mb-1 inline-block'>
                {t('lang_search')}
              </span>
              <label className='relative block w-full'>
                <input
                  className='w-full  block border secondary-bg-color main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                  placeholder='Search Tab'
                  type='text'
                  name='search'
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                />
              </label>
            </div>
            <div>
              <span className='text-sm table-text font-medium mb-1 inline-block'>
                {t('lang_set_items_limit')}
              </span>
              <div className='relative'>
                <select
                  className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                  name='limit'
                  value={limit}
                  onChange={(e) => {
                    e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                    setPage(1);
                  }}
                >
                  {allTab?.data?.totalRecords > 10 ? (
                    <>
                      <option value='10' className='table-text'>
                        10
                      </option>
                    </>
                  ) : (
                    <></>
                  )}
                  {allTab?.data?.totalRecords > 20 ? (
                    <option value='20' className='table-text'>
                      20
                    </option>
                  ) : (
                    <></>
                  )}
                  {allTab?.data?.totalRecords > 50 ? (
                    <option value='50' className='table-text'>
                      50
                    </option>
                  ) : (
                    <></>
                  )}
                  <option value='all' className='table-text capitalize'>
                    {t('lang_all')}
                  </option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default ViewTab;
