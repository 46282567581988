import React from 'react';
import { Skeleton } from '@mui/material';
const TableSkeleton = ({ forTable }) => {
  return (
    <div className='h-[600px] w-full mb-2.5 secondary-bg-color rounded-[6px] border main-border-color'>
      <Skeleton className='w-full min-h-[42px]' variant='rectangular' />
      <hr className='main-border-color' />
      <div className='divide-y overflow-x-auto main-border-color'>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
        <div className='flex items-center pe-3 h-[55px] main-border-color'>
          <div className='w-[4%] flex items-center justify-center'>
            <Skeleton className='min-w-[20px] min-h-[20px] max-w-[20px]' variant='rounded-[6px]' />
          </div>
          {forTable !== 'order' && (
            <div className='w-[6%] flex items-center h-[50px]'>
              <Skeleton
                className='min-w-[45px] min-h-[45px] max-h-[45px] max-w-[45px]'
                variant='rounded-[6px]'
              />
            </div>
          )}
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-[95%] min-h-[20px] max-w-[95%]' variant='text' />
          </div>
          <div className='w-[20%]'>
            <Skeleton className='min-w-full min-h-[20px] max-w-full' variant='text' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSkeleton;
