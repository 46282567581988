import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import module, { formatCode } from '../../../../controllers/QuillToolBar';
import { t } from 'i18next';

const TextEditorSettingSection = ({ handleTextEditorChange, section_id, textEditorValue,saveElementorData,
  btnLoading, }) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });


  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleTextEditorChange(section_id, value);
  };

  return (
    <div>
      <ReactQuill
        modules={module}
        ref={ShortquillRef}
        className='w-full h-auto table-text rounded-[6px]'
        theme='snow'
        name='description'
        // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
        value={textEditorValue}
        onChange={handleShortDescQuillChange}
        placeholder='Enter description'
        onBlur={() => {
          setMeta((prevMeta) => ({
            ...prevMeta,
            meta_description:
              shortdescription
                ?.replace(/<[^>]*>/g, ' ')
                ?.split(' ')
                .slice(0, 50)
                .join(' ') || '',
          }));
        }}
      />
      <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default TextEditorSettingSection;
