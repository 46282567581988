import React from 'react'

function Loader() {
  return (
    <div className='flex items-center justify-center w-full h-full'>
        <div className='data-loader my-4'></div>
    </div>
  )
}

export default Loader