import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import Loader from '../Loader/Loader';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';

const PayoutDetails = () => {
  const [payoutDetails, setPayoutDetails] = useState();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [nodata, setNodata] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const location = useLocation();
  const getPayoutDetails = async () => {
    try {
      let ApiCall = await API.get(`/admin/settlement/getSattlement/${location.state}`);
      const result = decryptData(ApiCall?.data)
      setPayoutDetails(result?.data);
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // Table Data
  const columns = [
    {
      name: 'Order',
      cell: (row) => <p className='line-clamp-2 text-center'>{row?.order_id || '-'}</p>,
    },
    {
      name: 'Order Total',
      cell: (row) => <p className='line-clamp-2 text-center'>{FormatAmount(row?.total_price)}</p>,
    },
    {
      name: 'Shipping charge',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>{FormatAmount(row?.shipping_charge)}</p>
      ),
    },
  ];

  useEffect(() => {
    getPayoutDetails();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className=' h-auto overflow-y-auto pb-[64px]'>
          <div className='flex items-center space-x-2 pb-[30px]'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/payouts/payout-history');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_payouts'), path: `/payouts/payout-history` },
                  { name: t('lang_View_Payouts'), path: '/payouts' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_payouts')}{' '}
              </h4>
            </div>
          </div>
          {/* Payouts history */}
          <div className='w-full border border-gray-300 category-table inline-block overflow-x-auto overflow-y-hidden rounded-[6px]'>
            <DataTable
              className='order-table'
              columns={columns}
              data={payoutDetails?.orders_processed}
              highlightOnHover
              progressComponent={<Loader />}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PayoutDetails;
