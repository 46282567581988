import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Check2 } from 'react-bootstrap-icons';
import { ArrowRight } from 'feather-icons-react/build/IconComponents';
import API from '../../API';
import Notification from '../Notification';
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';

const Wizard = ({ handleSkipWizard }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isComplete, setIsComplete] = useState();
  const [spinner, setSpinner] = useState(false);
  const StoreComplete = async () => {
    setSpinner(true);
    try {
      const storecom = await API.get(`/admin/client/storeComplete`);
      if (storecom.status === 200 || storecom.status === 304) {
        const result = decryptData(storecom?.data)
        setIsComplete(result);
        setSpinner(false);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    StoreComplete();
  }, []);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto pb-[64px]'>
        <div className='pb-4 w-full'>
          <Breadcrumbs crumbs={[{ name: t('lang_Welcome'), path: `/wizard` }]} />
          <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Welcome')}</h4>
        </div>
        <div className='overflow-y-auto w-full h-full primary-bg-color'>
          <div className='w-[80%] sm:w-[70%] xl:w-[60%] mx-auto h-auto rounded-[6px]'>
            <div className='secondary-bg-color border main-border-color dark:bg-gray-700 rounded-[6px] shadow-sm'>
              <div className='flex rounded-[6px] items-start justify-between primary-bg-color py-3 px-5 border-b main-border-color dark:border-gray-600'>
                <div>
                  <p className='capitalize font-medium text-xl'>
                    {t('lang_Complete_your_store_setup')}
                  </p>
                </div>
              </div>

              <div className='flex flex-wrap'>
                <div className='p-10 w-full'>
                  <div className='border-l main-border-color'>
                    {
                      <div className=' pl-8 relative'>
                        {!isComplete?.data?.business_profile ? (
                          <>
                            <div className='absolute top-0 left-[-22px] secondary-bg-color w-10 h-10 flex items-center justify-center border border-secondary rounded-full font-medium text-lg'>
                              1
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Complete_your_bussiness_profile')}
                            </p>
                            <p className='table-text text-sm'>
                              {t('lang_Add_name_email_mobile_number_and_address_properly')}
                            </p>
                            <Link
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                              to={`/store-profile`}
                            >
                              {t('lang_Add_bussiness_profile')}
                            </Link>
                          </>
                        ) : (
                          <>
                            <div className='absolute top-0 bg-[#28282B] text-white left-[-22px]  w-10 h-10 flex items-center justify-center rounded-full font-medium text-lg'>
                              <Check2 />
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Bussiness_profile_is_completed')}
                            </p>
                            <p className='table-text text-sm'>
                              {t('lang_Show_your_name_email_mobile_number_and_address')}
                            </p>
                            <NavLink
                              to='/store-profile'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_Edit_bussiness_profile')}
                            </NavLink>
                          </>
                        )}
                      </div>
                    }
                    {
                      <div className='pl-8 mt-8 relative'>
                        {!isComplete?.data?.product ? (
                          <>
                            <div className='absolute top-0 left-[-22px] secondary-bg-color w-10 h-10 flex items-center justify-center border border-secondary rounded-full font-medium text-lg'>
                              2
                            </div>
                            <p className='block font-medium text-base'>{t('lang_Add_Product')}</p>
                            <p className='table-text text-sm'>
                              {t('lang_Add_product_name_image_description_price_properly')}
                            </p>
                            <NavLink
                              to='/products/add-products'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_Add_Products')}
                            </NavLink>
                          </>
                        ) : (
                          <>
                            <div className='absolute top-0 bg-[#28282B] text-white left-[-22px]  w-10 h-10 flex items-center justify-center rounded-full font-medium text-lg'>
                              <Check2 />
                            </div>
                            <p className='block font-medium text-base'>{t('lang_Product_added')}</p>
                            <p className='table-text text-sm'>{t('lang_View_your_products')}</p>
                            <NavLink
                              to='/products'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_View_Products')}
                            </NavLink>
                          </>
                        )}
                      </div>
                    }
                    {
                      <div className='pl-8 mt-8 relative'>
                        {!isComplete?.data?.payment_method ? (
                          <>
                            <div className='absolute top-0 left-[-22px] secondary-bg-color w-10 h-10 flex items-center justify-center border border-secondary rounded-full font-medium text-lg'>
                              3
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Add_payment_method')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_Select_payment_method')}</p>
                            <NavLink
                              to='/payment-methods'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_Add_payment_method')}
                            </NavLink>
                          </>
                        ) : (
                          <>
                            <div className='absolute top-0 bg-[#28282B] text-white left-[-22px]  w-10 h-10 flex items-center justify-center rounded-full font-medium text-lg'>
                              <Check2 />
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Payment_method_is_added')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_View_payment_methods')}</p>
                            <NavLink
                              to='/payment-methods'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_View_payment_methods')}
                            </NavLink>
                          </>
                        )}
                      </div>
                    }
                    {
                      <div className='pl-8 mt-8 relative'>
                        {!isComplete?.data?.shipping_method ? (
                          <>
                            <div className='absolute top-0 left-[-22px] secondary-bg-color w-10 h-10 flex items-center justify-center border border-secondary rounded-full font-medium text-lg'>
                              4
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Add_shipping_method')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_Select_shipping_method')}</p>
                            <NavLink
                              to='/shipping-methods'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_Add_shipping_method')}
                            </NavLink>
                          </>
                        ) : (
                          <>
                            <div className='absolute top-0 bg-[#28282B] text-white left-[-22px]  w-10 h-10 flex items-center justify-center rounded-full font-medium text-lg'>
                              <Check2 />
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Shipping_method_is_added')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_View_shipping_methods')}</p>
                            <NavLink
                              to='/shipping-methods'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_View_shipping_methods')}
                            </NavLink>
                          </>
                        )}
                      </div>
                    }
                    {
                      <div className='pl-8 mt-8 relative'>
                        {!isComplete?.data?.terms_and_conditions ? (
                          <>
                            <div className='absolute top-0 left-[-22px] secondary-bg-color w-10 h-10 flex items-center justify-center border border-secondary rounded-full font-medium text-lg'>
                              5
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Add_terms_conditions')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_Add_terms_conditions')}</p>
                            <NavLink
                              to='/pages/add-page'
                              state={{ pageName: 'Terms & Conditions' }}
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_Add_terms_conditions')}
                            </NavLink>
                          </>
                        ) : (
                          <>
                            <div className='absolute top-0 bg-[#28282B] text-white left-[-22px]  w-10 h-10 flex items-center justify-center rounded-full font-medium text-lg'>
                              <Check2 />
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Terms_conditions_is_added')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_View_terms_conditions')}</p>
                            <NavLink
                              to='/pages'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_View_terms_conditions')}
                            </NavLink>
                          </>
                        )}
                      </div>
                    }
                    {
                      <div className='pl-8 mt-8 relative'>
                        {!isComplete?.data?.privacy_policy ? (
                          <>
                            <div className='absolute top-0 left-[-22px] secondary-bg-color w-10 h-10 flex items-center justify-center border border-secondary rounded-full font-medium text-lg'>
                              6
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Add_privacy_policy')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_Add_privacy_policy')}</p>
                            <NavLink
                              to='/pages/add-page'
                              state={{ pageName: 'Privacy Policy' }}
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_Add_privacy_policy')}
                            </NavLink>
                          </>
                        ) : (
                          <>
                            <div className='absolute top-0 bg-[#28282B] text-white left-[-22px]  w-10 h-10 flex items-center justify-center rounded-full font-medium text-lg'>
                              <Check2 />
                            </div>
                            <p className='block font-medium text-base'>
                              {t('lang_Privacy_policy_is_added')}
                            </p>
                            <p className='table-text text-sm'>{t('lang_View_privacy_policy')}</p>
                            <NavLink
                              to='/pages'
                              className='px-5 py-2 secondary-btn text-sm  mt-4 inline-block cursor-pointer'
                            >
                              {t('lang_View_privacy_policy')}
                            </NavLink>
                          </>
                        )}
                      </div>
                    }
                  </div>
                  <p className='text-right'>
                    <button
                      onClick={() => {
                        handleSkipWizard(true);
                        sessionStorage.setItem('skipWizard', true);
                      }}
                      className='mt-6 inline-flex items-center text-link text-sm font-medium underline'
                    >
                      {t('lang_Skip_all')} <ArrowRight className='w-4 h-4 ms-1' />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wizard;
