import { FormControlLabel } from '@mui/material';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { encryptData } from '../../controllers/encryptionUtils';
import { GetClient, clientData } from '../../controllers/GetClient';
import MuiSwitch from '../../controllers/MuiSwitch';
import Notification from '../Notification';

const ManageMode = () => {
  const [isMaintenanceModeOn, setisMaintenanceModeOn] = useState(false);
  const [isCatalogModeOn, setisCatalogModeOn] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  let clientInfo = clientData;
  useEffect(() => {
    setisMaintenanceModeOn(clientInfo?.isMaintenanceModeOn);
    setisCatalogModeOn(clientInfo?.catalog_mode);
  }, [clientInfo]);
  const [isChange, setIsChange] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleSubmit = async () => {
    try {
      const payload = encryptData({
        clientInfo: {
          isMaintenanceModeOn:
            isChange === 'maintenance' ? !isMaintenanceModeOn : isMaintenanceModeOn,
          catalog_mode: isChange === 'catalog' ? !isCatalogModeOn : isCatalogModeOn,
        },
      })
      await API.put('/admin/client/updateClient', payload);
      if (isChange === 'maintenance') {
        setisMaintenanceModeOn(!isMaintenanceModeOn);
      } else {
        setisCatalogModeOn(!isCatalogModeOn);
      }
      // if (isChange === 'catalog') {
      // }
      setIsNotification(true);
      setNotificationMsg('Store mode change successFully!');
      setSeverity('success');
      setIsChange();
      GetClient();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Change Store Mode'
        subheading='Do you really want to change mode of your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleSubmit}
      />
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div>
            {t('lang_Manage_Store_Mode')}
            <p className='text-xs table-text font-normal'>{t('lang_Manage_mode_of_your_store')}</p>
          </div>
        </div>
          <>
            <div>
            <table className='w-full bg-gray-200 border main-border-color rounded-[6px] overflow-hidden'>
                <thead>
                  <tr className='h-[45px] align-middle bg-[#f1f1f1] border-b main-border-color text-xs uppercase font-medium'>
                    <td className='px-4 w-full'>{t('lang_Mode')}</td>
                    <td className='px-4 min-w-[80px] max-w-[80px] text-right'>{t('lang_status')}</td>
                  </tr>
                </thead>
                <tbody className='secondary-bg-color'>
                  <tr className='!h-[150px] border-b main-border-color secondary-bg-color'>
                    <td className='px-3 py-2 w-full'>
                      <h5>{t('lang_Maintenance_Mode')}</h5>
                      {t('lang_Maintenance_Mode_Sentence')}
                    </td>
                    <td className='px-3 py-2 min-w-[80px] max-w-[80px]'>
                      <label className='relative flex justify-end w-full'>
                        <FormControlLabel
                          checked={isMaintenanceModeOn === true}
                          control={<MuiSwitch />}
                          onChange={() => {
                            setIsChange('maintenance');
                            setConfirmationPopup(true);
                          }}
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='!h-[150px] border-b main-border-color secondary-bg-color'>
                    <td className='px-3 py-2 w-full'>
                      <h5>{t('lang_Catalog_Mode')}</h5>
                      {t('lang_Catalog_Mode_Sentence')}
                    </td>
                    <td className='px-3 py-2 min-w-[80px] max-w-[80px]'>
                      <label className='relative flex justify-end w-full'>
                        <FormControlLabel
                          checked={isCatalogModeOn === true}
                          control={<MuiSwitch />}
                          onChange={() => {
                            setIsChange('catalog');
                            setConfirmationPopup(true);
                          }}
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
      </div>
    </>
  );
};

export default ManageMode;
