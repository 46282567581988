import { Backdrop, CircularProgress, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import AddCustomMetaField from '../../controllers/AddCustomMetaField';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
const AddManufacturer = () => {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [warn, setwarn] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [enableSlug, setEnableSlug] = useState(false);
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);

  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/manufacture`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }

  useEffect(() => {
    getMetaField();
  }, [])
  // image upload logic
  const [mData, setmData] = useState({
    manufacturerName: '',
    meta_title: '',
    meta_description: '',
  });
  const clientInfo = clientData;
  const [description, setDescription] = useState('');
  const [image, setimage] = useState();
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [selectedProduct, setSelectedProduct] = useState([]);
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };
  const [slugField, setSlugField] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (slug?.length) {
      setSlugField(true);
      setSlugName(await GenerateSlugName(slug));
    }
  };
  const HandleInput = (e) => {
    setmData({ ...mData, [e.target.name]: e.target.value });
  };
  const [err, setErr] = useState(false);
  const { manufacturerName, meta_title, meta_description } = mData;
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        brand_name: manufacturerName,
        brand_logo: image,
        meta_title,
        products: selectedProduct?.map((x) => x.product),
        meta_description,
        description: description,
        brand_slug_name: slugName,
        custom_fields: meta_fields,
      })
      const data = await API.post('/admin/manufactures/addManufacturer', payload);
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/manufacturers',
          title: 'add manufacture',
        });
        navigate('/manufacturers');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [AiLoader, setAiloader] = useState(false);

  const generateDesc = async () => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: manufacturerName,
      })
      let ApiCall = await API.post('/admin/ai/description_generator', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setDescription(result?.data);
      }
    } catch (error) {
      if(error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };
  const [desErr, setDesErr] = useState(false);
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setDesErr(true);
    } else {
      setDesErr(false);
      setAiloader(true);
      try {
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/manufacture_meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setmData({ ...mData, meta_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/manufacture_meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setmData({ ...mData, meta_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (
      manufacturerName?.length > 0 ||
      image?.length > 0 ||
      meta_title?.length > 0 ||
      selectedProduct?.length > 0 ||
      meta_description?.length > 0 ||
      description?.length > 0 ||
      slugName?.length > 0
    ) {
      setSaveChanges({
        backLink: '/manufacturers',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add manufacture',
      });
    } else {
      setSaveChanges({
        backLink: '/manufacturers',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add manufacture',
      });
    }
  }, [
    manufacturerName,
    image,
    meta_title,
    selectedProduct,
    meta_description,
    description,
    slugName,
  ]);
  const checkChanges = () => {
    if (
      manufacturerName?.length > 0 ||
      image?.length > 0 ||
      meta_title?.length > 0 ||
      selectedProduct?.length > 0 ||
      meta_description?.length > 0 ||
      description?.length > 0 ||
      slugName?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/manufacturers',
        title: 'add manufacture',
      });
    } else {
      navigate('/manufacturers');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/manufactures/GetProductsForBrand?brand_exist=2`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_Please_Wait')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Manufactures'), path: '/manufacturers' },
                { name: t('lang_Add_New_Manufactures'), path: '/manufacturers/add-manufacture' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Add_New_Manufactures')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/manufacture'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full space-y-4 lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className=' secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
              <div className='mb-4'>
                <label htmlFor='manufacturerName' className='flex items-center justify-between'>
                  <p className='text-[13px]'>
                    {t('lang_manufacture_name')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <input
                  className={
                    (err && manufacturerName?.length === 0) || warn
                      ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                  }
                  type='text'
                  id='manufacturerName'
                  name='manufacturerName'
                  value={mData.manufacturerName}
                  onChange={(e) => {
                    HandleInput(e);
                    manufacturerName?.length > 0 && setwarn(false);
                    setErr(false);
                  }}
                  placeholder='Enter manufacturer name'
                  onBlur={() => {
                    handleSlug(mData?.manufacturerName);
                    setmData({ ...mData, meta_title: mData?.manufacturerName });
                  }}
                />
                <div className='w-full flex justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>
                    {t('lang_recommend_character')} {`${mData.manufacturerName?.length} ${t('lang_characters')}`}
                  </p>
                </div>
                {(err && manufacturerName?.length === 0) || warn ? (
                  <p className='text-xs text-red-500'>{t('lang_Enter_Manufactures_Name')}</p>
                ) : (
                  <></>
                )}
              </div>
              {slugField && (
                <div className='mb-4'>
                  <label htmlFor='category_name'>{t('lang_Manufactures_Slug')}</label>
                  <div
                    className={` relative flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'main-border-color'
                      } rounded-[4px]`}
                  >
                    <input
                      className='w-full focus:outline-none'
                      type='text'
                      name='manufacture_slug_name'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => {
                        setSlugName(e.target.value);
                      }}
                    />
                    <Tooltip
                      title={`${enableSlug ? t('lang_save') : t('lang_edit') }`}
                      arrow
                      placement='bottom'
                      className='ml-2 -tracking-tighter capitalize'
                    >
                      <button type='button' className='slug-edit w-8 h-8'>
                        {enableSlug ? (
                          <Check
                            onClick={async () => {
                              if(slugName?.length > 0) {
                              setSlugName(await GenerateSlugName(slugName));
                              setEnableSlug(!enableSlug);
                              setErr(false);
                            } else {
                              setIsNotification(true);
                              setNotificationMsg('Slug can not be blank');
                              setSeverity('warning');
                            }
                            }}
                            className='text-green-500 p-1 h-8 w-8'
                          />
                        ) : (
                          <Edit
                            onClick={() => setEnableSlug(!enableSlug)}
                            className='text-black-500 p-1 h-7 w-7'
                          />
                        )}
                      </button>
                  </Tooltip>
                  </div>
                  {err && enableSlug && (
                    <p className='text-xsm text-red-500'>{t('lang_save_slug_changes')}</p>
                  )}
                </div>
              )}
              <div className='w-full mb-4'>
                <label htmlFor='image'>
                  <p className='text-[13px]'>
                    {t('lang_manufacture_image')}
                  </p>
                </label>
                <ImageUploader
                  alreadyImage={image}
                  handleSetImage={handleSetImage}
                  folder='manufacture'
                  format='image'
                />
              </div>
              <div className='mb-4'>
                <div className='flex space-x-3 justify-between items-center mb-1'>
                  <label htmlFor='Description'>{t('lang_description')}</label>
                  <div className='flex items-center'>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={() => {
                        manufacturerName === '' ? setwarn(true) : (generateDesc(), setwarn(false));
                      }}
                    >
                      <BardSvg /> {t('lang_get_description')}
                    </span>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </span>
                  </div>
                </div>
                <ReactQuill
                  modules={module}
                  ref={quillRef}
                  className={desErr ? 'border rounded-[6px] border-red-500' : 'w-full h-auto'}
                  theme='snow'
                  name='description'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                  onBlur={() => {
                    setmData({
                      ...mData,
                      meta_description:
                        description
                          ?.replace(/<[^>]*>/g, ' ')
                          ?.split(' ')
                          .slice(0, 50)
                          .join(' ') || '',
                    });
                  }}
                  placeholder='Enter description'
                />
                <div className='flex items-center justify-between'>
                  {desErr ? (
                    <p className='text-red-500 text-xs '>
                      {t('lang_Please_Enter_Manufacture_Description')}
                    </p>
                  ) : (
                    ''
                  )}
                  <div className='w-full flex justify-between'>
                    <p className='text-xs mt-0 text-gray-400'>{t('lang_Only_Words_allowed', {words: `${description?.split(/\s+/)?.filter(Boolean)?.length || 0}/280`})}</p>
                  </div>
                </div>
              </div>
            </div>
            <details className=' secondary-bg-color cursor-pointer group shadow rounded-[6px] p-5 sm:p-[25px]'>
              <summary className='text-base flex items-center justify-between font-medium '>
                <div>
                  {t('lang_products')}
                  <p className='text-xs text-gray-500 font-normal'>
                    {t('lang_Select_Product_For_Add_In_This_Manufacture')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <hr className='mt-4 main-border-color' />
              <div className='w-full text-center'>
                {selectedProduct?.length > 0 ? (
                  <div className='max-h-[450px] overflow-y-auto divide-y'>
                    {selectedProduct?.map((elem) => {
                      return (
                        <div className='flex items-center main-border-color py-2 space-x-3'>
                          <button
                            onClick={(e) => removeSelectProduct(e, elem?.product)}
                            className='close-btn'
                          >
                            <X className='w-[14px] h-[14px] text-red-600' />
                          </button>
                          <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              className='max-w-full max-h-full'
                              alt={elem?.name}
                            />
                          </div>
                          <p className='text-sm table-text'>{elem?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <button
                    className='bulk-btn mt-4'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSelectProduct(true);
                    }}
                  >
                    {t('lang_Select_Product')}
                  </button>
                )}
                {selectedProduct?.length > 0 && (
                  <>
                    <div className='flex items-center border-t main-border-color justify-between pt-2'>
                      <button
                        className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSelectProduct(true);
                        }}
                      >
                        + {t('lang_select_more_product')}
                      </button>
                      <button
                        className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedProduct([]);
                        }}
                      >
                        <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                        {t('lang_remove_all_product')}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </details>
            <details className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <summary
                htmlFor='Meta Tag Title'
                className='text-base w-full flex flex-col items-center font-medium '
              >
                <div className='flex w-full items-center justify-between'>
                  <div>
                    <span>{t('lang_search_engine_listing')}</span>
                    <p className='text-xs text-gray-500 font-normal'>
                      {t('lang_search_engine_listing_detail')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </div>
                {mData?.meta_title?.length > 0 && (
                  <div className='text-left font-normal mt-2 w-full'>
                    <p className='text-blue-700 text-base'>{mData?.meta_title}</p>
                    <p className='flex text-xs text-green-700'>
                      {`${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                        }/manufacturers/${slugName}`}{' '}
                    </p>
                    <p
                      className='table-text text-sm'
                      dangerouslySetInnerHTML={{ __html: mData?.meta_description }}
                    ></p>
                  </div>
                )}
              </summary>
              <div className='w-full'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='meta_title' className='flex items-center justify-between'>
                    {t('lang_meta_title')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(description, 'title');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_title')}
                  </span>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='meta_title'
                  name='meta_title'
                  value={mData?.meta_title}
                  onChange={HandleInput}
                  placeholder='Enter meta title'
                />
              </div>
              <div className='w-full'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='meta_description' className='flex items-center justify-between'>
                    {t('lang_meta_description')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(description, 'description');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_description')}
                  </span>
                </div>
                <textarea
                  className='w-full h-24 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='meta_description'
                  name='meta_description'
                  value={mData?.meta_description?.replace(/<[^>]+>/g, '')}
                  onChange={HandleInput}
                  placeholder='Enter meta description'
                />
              </div>
            </details>
            {isCustomField && <AddCustomMetaField
              setmeta_fields={setmeta_fields}
              meta_fields={meta_fields}
              heading='manufacture'
            />}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
           <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_To_Manufacturer')}
              </Link>
              <div>
                {manufacturerName === '' || enableSlug ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_Manufactures')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[189px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_Manufactures')}</span>
                  </button>
                )}
              </div>
           </div>
           </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default AddManufacturer;
