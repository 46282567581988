import React from "react";
import SvgSection from "../../controllers/SvgSection";
import { mapPositionToCSS } from "../../controllers/DynamicEditorSwitchCase";

const SocialIconSection = ({ obj }) => {
  return (
    <>
      <div
        key={obj?.id}
        className="flex justify-center space-x-4"
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.icon_border_top}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
          borderBottom: `${obj?.style?.icon_border_bottom}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
          borderLeft: `${obj?.style?.icon_border_left}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
          borderRight: `${obj?.style?.icon_border_right}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
          borderTopLeftRadius: `${obj?.style?.icon_border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.icon_border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.icon_border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.icon_border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
          background:
            obj?.style?.background_gradient === "gradient"
              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
              : obj?.style?.background_image
              ? `url(${obj?.style?.background_image})`
              : obj?.style?.background,
          transform: `rotate(${obj?.style?.text_rotate}deg)`,
          backgroundRepeat: obj?.style?.background_repeat,
          backgroundAttachment: obj?.style?.background_attachment,
          objectFit: obj?.style?.background_fit,
          backgroundSize:
            obj?.style?.background_fit === "cover" ||
            obj?.style?.background_fit === "contain"
              ? obj?.style?.background_fit
              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
          gap: `${obj?.style?.icon_spacing}px`,
          justifyContent:
            obj?.style?.icon_align === "right"
              ? "flex-end"
              : obj?.style?.icon_align === "left"
              ? "flex-start"
              : obj?.style?.icon_align === "center"
              ? "center"
              : undefined,
        }}
      >
        {obj?.content?.item?.map((data, idx) => (
          // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
          <div
            style={{
              padding: `${obj?.style?.icon_padding}px`,
            }}
          >
            {data?.icon && React.isValidElement(data?.icon) ? (
              React.cloneElement(data?.icon, {
                width: obj?.style?.icon_width
                  ? `${obj.style?.icon_width}px`
                  : "50px",
                height: obj?.style?.icon_width
                  ? `${obj.style?.icon_width}px`
                  : "50px",
                fill: obj?.style?.icon_color
                  ? `${obj.style?.icon_color}`
                  : data?.icon?.props?.fill,

                stroke: obj?.style?.icon_color
                  ? `${obj.style?.icon_color}`
                  : data?.icon?.props?.stroke,
              })
            ) : (
              <SvgSection
                svgContent={data?.icon}
                width={
                  obj?.style?.icon_width ? `${obj?.style.icon_width}px` : "50px"
                }
                height={
                  obj?.style?.icon_width
                    ? `${obj?.style?.icon_width}px`
                    : "50px"
                }
                fill={obj?.style?.icon_color}
                stroke={obj?.style?.icon_color}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SocialIconSection;
